/* import __COLOCATED_TEMPLATE__ from './metric-definition.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { AppPricing5PriceModels, PRICING_METRIC_METADATA } from 'embercom/lib/billing';
import type IntlService from 'embercom/services/intl';

interface Args {
  pricingMetric: string;
  hasDarkIcon?: boolean;
  shouldShowInMessenger?: boolean;
}

export default class MetricDefinition extends Component<Args> {
  @service declare intl: IntlService;
  @service customerService: any;
  @service appService: any;

  get data() {
    return PRICING_METRIC_METADATA[this.args.pricingMetric];
  }

  get shouldShowInMessenger() {
    if (this.args.shouldShowInMessenger === undefined) {
      return true;
    }
    return this.args.shouldShowInMessenger;
  }

  get hasContent() {
    return this.shouldUseBillingCycleContent || this.data?.descriptionTranslationKey;
  }

  get shouldUseBillingCycleContent() {
    return (
      this.customerService.customer.showBillingCycleMetricsInBillingSummary &&
      this.data?.billingCycleDescriptionTranslationKey
    );
  }

  get description() {
    let useRealBillingCycle = this.appService.app?.useRealBillingCycle || 'false';
    if (this.shouldUseBillingCycleContent) {
      if (this.customerService.onPricing5_X && this.data.pricing5?.descriptionTranslationKey) {
        return this.intl.t(this.data.pricing5.descriptionTranslationKey, {
          htmlSafe: true,
          useRealBillingCycle,
        });
      }

      return this.intl.t(this.data.billingCycleDescriptionTranslationKey, {
        htmlSafe: true,
        useRealBillingCycle,
      });
    }
    return this.intl.t(this.data.descriptionTranslationKey, {
      htmlSafe: true,
      useRealBillingCycle,
    });
  }

  get articleLinkText() {
    if (this.customerService.onPricing5_X && this.data.pricing5?.helpLinkTextTranslationKey) {
      return this.intl.t(this.data.pricing5.helpLinkTextTranslationKey);
    }

    if (this.data.helpLinkTextTranslationKey) {
      return this.intl.t(this.data.helpLinkTextTranslationKey);
    }

    return;
  }

  get articleLink() {
    if (this.customerService.onPricing5_X && this.data.pricing5?.helpCenterLinkUrl) {
      if (this.appService.app.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1) {
        return (
          this.data.pricing5?.helpCenterLinkUrlPricing5_1 ?? this.data.pricing5?.helpCenterLinkUrl
        );
      }
      return this.data.pricing5?.helpCenterLinkUrl;
    }

    return this.data.helpLinkUrl;
  }

  get messengerArticleLink() {
    if (!this.customerService.onPricing5_X || !this.data.pricing5?.articleId) {
      return {
        articleId: this.data.articleId,
        articleHelpCenterSelector: this.data.articleHelpCenterSelector,
      };
    }

    if (this.appService.app.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1) {
      return {
        articleId: this.data.pricing5?.articleIdPricing5_1 ?? this.data.pricing5?.articleId,
        articleHelpCenterSelector:
          this.data.pricing5?.articleHelpCenterSelectorPricing5_1 ??
          this.data.pricing5?.articleHelpCenterSelector,
      };
    }

    return {
      articleId: this.data.pricing5?.articleId,
      articleHelpCenterSelector: this.data.pricing5?.articleHelpCenterSelector,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::Tooltips::MetricDefinition': typeof MetricDefinition;
  }
}
