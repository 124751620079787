/* import __COLOCATED_TEMPLATE__ from './total-cost-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Charge from 'embercom/models/billing/price/charge';
import type Contract from 'embercom/models/billing/contract';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { formatPriceFromCents } from 'embercom/components/billing/usage/tooltips/format-price';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips/tooltip-item';

interface Args {
  testIdentifier: string;
  charges: Charge[];
  contract?: Contract;
  displayInYourSubscriptionRow: boolean;
  includedUsage: number;
}

export default class TotalCostDetails extends Component<Args> {
  @service declare intl: IntlService;
  @service declare customerService: any;

  get charge() {
    return this.args.charges[0];
  }

  get hasMultipleCharges() {
    return this.args.charges.length > 1;
  }

  get tooltipRows(): TooltipRow[] {
    return [
      {
        label: this.totalCostTitle,
        muted: true,
        noPadding: true,
      },
      ...this.totalCostInfo,
    ];
  }

  get totalCostTitle() {
    if (this.charge.isSeatMetric || this.customerService.onPricing5_X) {
      return this.intl.t('billing.summary.price-usage-breakdown-card.overage-tooltip.total-cost');
    } else {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.total-cost-of-overage',
      );
    }
  }

  get totalCostInfo() {
    let totalPrice = this.totalDiscountedPrice;

    return [
      ...(this.args.displayInYourSubscriptionRow && this.isUsageLessThanIncludedUsage
        ? [
            ...(this.hasMultipleCharges && this.charge.isSeatMetric
              ? [
                  {
                    label: this.formattedTotalFormula,
                    value: formatPriceFromCents(totalPrice, this.intl),
                  },
                ]
              : [
                  {
                    label: formatPriceFromCents(
                      this.inYourSubscriptionPrice(this.charge) -
                        this.chargeDiscount(this.charge) -
                        this.chargeOverageDiscount(this.charge),
                      this.intl,
                    ),
                  },
                ]),
          ]
        : [
            {
              label: this.formattedOverageFormula(this.charge.totalCostOverageBreakdown),
              value: formatPriceFromCents(totalPrice + this.charge.overagePrice, this.intl),
            },
          ]),
    ];
  }

  get isUsageLessThanIncludedUsage() {
    let { contract } = this.args;

    if (contract?.isSecondarySubscription) {
      return this.getUsageForWorkspace() <= this.args.includedUsage;
    }

    return this.charge.actualUsage <= this.args.includedUsage;
  }

  private getUsage(workspace: any): number {
    return workspace.usage && workspace.usage[this.charge.pricing_metric] !== undefined
      ? workspace.usage[this.charge.pricing_metric]
      : 0;
  }

  private getUsageForWorkspace(): number {
    let { contract } = this.args;

    let workspace = contract?.perWorkspaceUsage.find(
      (workspace) => workspace.app_id === this.customerService.app.id,
    );

    return workspace ? this.getUsage(workspace) : 0;
  }

  get totalDiscountedPrice() {
    let { inYourSubscriptionPrice, discount } = this.args.charges.reduce(
      (acc, charge) => {
        acc.inYourSubscriptionPrice += this.args.displayInYourSubscriptionRow
          ? this.inYourSubscriptionPrice(charge)
          : 0;
        acc.discount += this.chargeDiscount(charge);
        acc.discount += this.chargeOverageDiscount(charge);
        return acc;
      },
      { inYourSubscriptionPrice: 0, discount: 0 },
    );

    return inYourSubscriptionPrice - discount;
  }

  inYourSubscriptionPrice(charge: Charge = this.charge): number {
    if (
      charge.pricing_metric === Metric.messages_sent &&
      charge.first_tier_price !== null &&
      charge.first_tier_price !== undefined
    ) {
      return charge.base_price - charge.first_tier_price * 100;
    }
    return charge.base_price;
  }

  get formattedTotalFormula() {
    let formula = '';

    this.args.charges.forEach((charge, index) => {
      let price = formatPriceFromCents(this.inYourSubscriptionPrice(charge), this.intl);
      let discount = this.chargeDiscount(charge);
      let overage_discount = this.chargeOverageDiscount(charge);

      if (index !== 0) {
        formula += ' + ';
      }

      formula += price;

      if (discount > 0) {
        formula += ` - ${formatPriceFromCents(discount, this.intl)}`;
      }

      if (overage_discount > 0) {
        formula += ` - ${formatPriceFromCents(overage_discount, this.intl)}`;
      }
    });
    return formula;
  }

  formattedOverageFormula(formula: string[]): string {
    let finalText = '';

    if (this.args.displayInYourSubscriptionRow) {
      finalText = finalText.concat(formatPriceFromCents(this.inYourSubscriptionPrice(), this.intl));
      finalText = finalText.concat(' + ');
    } else if (formula.length === 1 && !this.displayDiscountRow()) {
      return formula[0];
    }

    formula.forEach((textPart, index) => {
      finalText = finalText.concat(`(${textPart})`);
      if (index < formula.length - 1) {
        finalText = finalText.concat(' + ');
      }
    });
    if (this.displayDiscountRow()) {
      finalText = finalText.concat(' - ');
      finalText = finalText.concat(
        formatPriceFromCents(this.charge.discount_item?.amount_in_cents || 0, this.intl),
      );
    }
    if (this.displayOverageDiscountRow()) {
      finalText = finalText.concat(' - ');
      finalText = finalText.concat(
        formatPriceFromCents(this.charge.overage_discount_item?.amount_in_cents || 0, this.intl),
      );
    }

    return finalText;
  }

  chargeDiscount(charge: Charge) {
    return this.displayDiscountRow(charge) ? charge.discount_item?.amount_in_cents || 0 : 0;
  }

  chargeOverageDiscount(charge: Charge) {
    return this.displayOverageDiscountRow(charge)
      ? charge.overage_discount_item?.amount_in_cents || 0
      : 0;
  }

  displayDiscountRow(charge: Charge = this.charge) {
    return charge.discount_item && charge.discount_item.amount_in_cents > 0;
  }

  displayOverageDiscountRow(charge: Charge = this.charge) {
    return charge.overage_discount_item && charge.overage_discount_item.amount_in_cents > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::Tooltips::TotalCostDetails': typeof TotalCostDetails;
  }
}
