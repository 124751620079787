/* import __COLOCATED_TEMPLATE__ from './unpaid-invoice-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class UnpaidInvoiceModal extends Component {
  @service router;

  @action
  updateCardDetails() {
    return this.router.transitionTo('apps.app.settings.subscription.billing.settings');
  }
}
