/* RESPONSIBLE TEAM: team-messenger */

import Model, { attr } from '@ember-data/model';
import TrustedListUrlValidator from 'embercom/lib/trusted-list-url-validator';

export default class Security extends Model {
  @attr('array') declare parsedAllowedDomains: string[];

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  get allowedDomains() {
    return this.parsedAllowedDomains.join(',');
  }

  get domainValidator() {
    return TrustedListUrlValidator.create({ urlString: this.allowedDomains });
  }

  beforeSave() {
    // @ts-expect-error TypeScript thinks this is a function because of hasDirtyAttributes being a computed property.
    if (this.hasUnsavedChanges) {
      this.updateParsedAllowedDomains();
    }
  }

  updateParsedAllowedDomains() {
    this.parsedAllowedDomains = this.domainValidator.domainHostNames;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
