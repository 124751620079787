/* import __COLOCATED_TEMPLATE__ from './get-started-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  importArticles: Function;
  showCleanupModal: Function;
  showSyncCleanupModal: Function;
  syncArticles: Function;
  importButtonDisabled: boolean;
  synchronizationEnabled: boolean;
  route: string;
}

export default class GetStartedButton extends Component<Args> {
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare appService: $TSFixMe;

  get label() {
    return this.intl.t('new-settings.learn-label');
  }

  get items() {
    let defaultItems = [
      {
        text: this.intl.t('articles.submenu.onboarding.title'),
        icon: 'rocket-ship',
        onSelectItem: () => this.openOnboarding(),
      },
      {
        text: this.intl.t('articles.list.dropdown.migrate'),
        component: 'articles/list/get-started-dropdown-items/migrate-from-zendesk',
        componentAttrs: {
          importArticles: this.args.importArticles,
          showCleanupModal: this.args.showCleanupModal,
          importButtonDisabled: this.args.importButtonDisabled,
          synchronizationEnabled: this.args.synchronizationEnabled,
        },
      },
      {
        text: this.intl.t('articles.list.dropdown.sync'),
        component: 'articles/list/get-started-dropdown-items/sync-with-zendesk',
        componentAttrs: {
          syncArticles: this.args.syncArticles,
          showSyncCleanupModal: this.args.showSyncCleanupModal,
          importButtonDisabled: this.args.importButtonDisabled,
          synchronizationEnabled: this.args.synchronizationEnabled,
        },
      },
      {
        text: this.intl.t('articles.collections.description_panel.help_link_of_sync'),
        component: 'articles/list/get-started-dropdown-items/link',
        componentAttrs: {
          href: 'https://www.intercom.com/help/en/articles/6717804-sync-articles-with-zendesk',
          title: this.intl.t('articles.collections.description_panel.help_link_of_sync'),
        },
      },
      {
        text: this.intl.t('articles.collections.multi_help_center.link_title'),
        component: 'articles/list/get-started-dropdown-items/link',
        componentAttrs: {
          href: 'https://www.intercom.com/help/en/articles/8170953-create-and-manage-multiple-help-centers',
          title: this.intl.t('articles.collections.multi_help_center.link_title'),
        },
      },
    ];

    if (this.args.route === 'collections') {
      return [
        {
          items: [
            ...defaultItems,
            {
              text: this.intl.t('articles.collections.description_panel.help_link_of_messenger'),
              component: 'articles/list/get-started-dropdown-items/link',
              componentAttrs: {
                href: 'https://www.intercom.com/help/en/articles/5241719-let-customers-search-for-articles-in-the-messenger',
                title: this.intl.t('articles.collections.description_panel.help_link_of_messenger'),
              },
            },
            {
              text: this.intl.t('articles.collections.how_to_banner.heading'),
              component: 'articles/list/get-started-dropdown-items/link',
              componentAttrs: {
                href: 'https://www.intercom.com/help/en/articles/56647-create-useful-collections-for-customers-to-explore',
                title: this.intl.t('articles.collections.how_to_banner.heading'),
              },
            },
          ],
        },
      ];
    } else {
      let onboardingTour = [
        {
          text: this.intl.t('articles.list.dropdown.tour'),
          component: 'articles/list/get-started-dropdown-items/tour',
          componentAttrs: {
            tourId: '51309',
            title: this.intl.t('articles.list.dropdown.tour'),
          },
        },
      ];
      return [
        {
          items: [
            ...onboardingTour,
            ...defaultItems,
            {
              text: this.intl.t('articles.list.description_panel.help_link_of_import'),
              component: 'articles/list/get-started-dropdown-items/link',
              componentAttrs: {
                href: 'https://www.intercom.com/help/en/articles/56643-import-your-articles-from-zendesk',
                title: this.intl.t('articles.list.description_panel.help_link_of_import'),
              },
            },
          ],
        },
      ];
    }
  }

  @action openOnboarding() {
    let url = this.router.urlFor('apps.app.articles.onboarding');
    safeWindowOpen(url, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::List::GetStartedButton': typeof GetStartedButton;
    'articles/list/get-started-button': typeof GetStartedButton;
  }
}
