/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class ApiConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('string') declare url: string;
  @attr('string') declare accessToken: string;
  @attr('string') declare externalUrl: string;
  @attr('string') declare workflowRulesetId: string;
}
