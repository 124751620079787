/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { setOwner } from '@ember/application';
import Service, { inject as service } from '@ember/service';
import { getContainer } from 'embercom/lib/container-lookup';
import { AssignableEntity, AssigningEntity } from 'embercom/models/data/inbox/assignment-enums';
import { StateChangeReason, StateChangeType } from 'embercom/models/data/inbox/state-change-enums';
import AdminSummary, { UNASSIGNED_ID } from 'embercom/objects/inbox/admin-summary';
import Conversation, {
  ConversationState,
  type TicketSystemState,
} from 'embercom/objects/inbox/conversation';
import ConversationSummary from 'embercom/objects/inbox/conversation-summary';
// import ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import { type DurationObject } from 'embercom/objects/inbox/duration';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { createRenderablePart } from 'embercom/objects/inbox/renderable-part';
import Assignment from 'embercom/objects/inbox/renderable/assignment';
import PriorityChanged, {
  PRIORITY_FALSE,
  PRIORITY_TRUE,
} from 'embercom/objects/inbox/renderable/priority-changed';
import TeammateStateChange from 'embercom/objects/inbox/renderable/state-changes/teammate-state-change';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type Session from 'embercom/services/session';
import { captureException } from 'embercom/lib/sentry';
import type Tag from 'embercom/objects/inbox/tag';
import { assertTaggablePart } from 'embercom/objects/inbox/taggable-part';
import { tracked } from '@glimmer/tracking';
import ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import TicketStateUpdatedByAdmin from 'embercom/objects/inbox/renderable/ticket-state-updated-by-admin';
import { type TicketType } from 'embercom/objects/inbox/ticket';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { EntityType } from 'embercom/models/data/entity-types';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import ConversationAttributeUpdated, {
  ConversationAttributeSummary as CASummary,
} from 'embercom/objects/inbox/renderable/conversation-attribute-updated';
import ConversationTagsUpdated from 'embercom/objects/inbox/renderable/conversation-tags-updated';
import TagSummary from 'embercom/objects/inbox/tag-summary';

// ConversationUpdates implements an API for local updates to conversations.
//
// Its usage looks something like this:
//
// ```
// this.conversationUpdates.do(conversation, async (updates) => {
//   updates.add('state-change', { state: ConversationState.Closed });
//   try {
//     <…>
//   } catch {
//     updates.rollback()
//   }
// })
// ```
// These updates are held in memory until you call `commitUpdates()` with a
// conversation that has committed those parts.

// You can subscribe to this service. It publishes updates to a conversation's
// subscribers when any updates are available. Something like:

// ```
// this.conversationUpdates.subscribe(conversation.id, ({ added, removed }) => {
//   added.forEach(update => update.apply(conversation)
//   removed.forEach(update => update.rollback(conversation)
// });
// ```

function assertExists<T>(value: T): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`expected ${value} to exist`);
  }
}

// An Update holds state for an atomic change to a conversation. It can be used
// to apply the change or to roll it back, and it should leave the conversation
// in a consistent state.

// It should also not create duplicates — if an update has been applied already,
// applying it again shouldn't do anything.
export abstract class Update {
  abstract part: RenderablePart;

  abstract apply(conversation: ConversationRecord): void;
  abstract rollback(conversation: ConversationRecord): void;
  abstract isCommitted(conversation: ConversationRecord): boolean;
  abstract markAsFailed(): void;
  abstract isFailed: boolean;

  /**
    Syncs locally created part with the server created version, if any.

    Local parts initially have a client generated timestamp, which isn't
    reliable. Syncing with remote parts makes it possible to compare
    more-accurate timestamps later.
  */
  abstract commit(parts?: RenderablePart[]): void;
}

class BaseUpdate {
  declare part: RenderablePart;

  protected isManuallyCommitted = false;
  @tracked isFailed = false;

  constructor() {
    setOwner(this, getContainer());
  }

  isCommitted(conversation: Conversation): boolean {
    if (this.isManuallyCommitted) {
      return true;
    }

    let committedUuids = conversation.committedParts.map((part) => part.clientAssignedUuid);
    return this.part.clientAssignedUuid
      ? committedUuids.includes(this.part.clientAssignedUuid)
      : false;
  }

  commit(parts: RenderablePart[] = []) {
    let part = parts.find(
      (part) => part.clientAssignedUuid && part.clientAssignedUuid === this.part.clientAssignedUuid,
    );
    if (!part) {
      return;
    }

    this.part = part;
  }

  markAsFailed() {
    this.isFailed = true;
  }
}

class StateChange extends BaseUpdate implements Update {
  @service declare session: Session;

  #previousState?: ConversationState;
  #data: { state: ConversationState; duration?: DurationObject };

  part: RenderablePart;

  constructor(data: { state: ConversationState; duration?: DurationObject }) {
    super();

    this.#data = data;
    this.part = createRenderablePart(
      new TeammateStateChange(
        this.getStateChange(data.state),
        StateChangeReason.StandardStateChange,
        this.session.teammate,
        'duration' in data ? { duration: data.duration } : undefined,
      ),
    );
  }

  apply(conversation: ConversationRecord) {
    this.#previousState = conversation.state;
    if (conversation instanceof Conversation) {
      if (conversation.hasPartWithUUID(this.part.clientAssignedUuid)) {
        return;
      }

      conversation.pushPendingPart(this.part);
      conversation.state = this.#data.state;
    } else {
      conversation.state = this.#data.state;
    }
  }

  rollback(conversation: ConversationRecord) {
    assertExists(this.#previousState);

    if (conversation instanceof Conversation) {
      conversation.state = this.#previousState;
      conversation.removePendingPart(this.part);
    } else {
      conversation.state = this.#previousState;
    }
  }

  private getStateChange(state: ConversationState) {
    return {
      [ConversationState.Open]: StateChangeType.Opened,
      [ConversationState.Closed]: StateChangeType.Closed,
      [ConversationState.Snoozed]: StateChangeType.Snoozed,
    }[state];
  }
}

class TicketStateChange extends BaseUpdate implements Update {
  @service declare session: Session;
  @service declare ticketStateService: TicketStateService;

  #previousTicketState?: TicketSystemState;
  #previousTicketCustomStateId?: TicketCustomState['id'];
  #data: {
    ticketState: TicketSystemState;
    visibleToUser?: boolean;
    conversationId: Conversation['id'];
    ticketTypeId?: TicketType['id'];
    ticketCustomStateId?: TicketCustomState['id'];
  };

  part: RenderablePart;

  constructor(data: {
    ticketState: TicketSystemState;
    visibleToUser?: boolean;
    conversationId: Conversation['id'];
    ticketTypeId?: TicketType['id'];
    ticketCustomStateId?: TicketCustomState['id'];
  }) {
    super();

    this.#data = data;
    let ticketStateLabel = undefined;
    if (Number(data.ticketCustomStateId)) {
      ticketStateLabel = this.ticketStateService.getTicketCustomStateById(
        Number(data.ticketCustomStateId),
      )?.adminLabel;
    }
    this.part = createRenderablePart(
      new TicketStateUpdatedByAdmin(
        this.session.teammate,
        data.ticketState,
        !data.visibleToUser,
        data.conversationId,
        data.ticketTypeId,
        data.visibleToUser,
        undefined,
        undefined,
        ticketStateLabel,
      ),
    );
  }

  apply(conversation: ConversationRecord) {
    this.#previousTicketState = conversation.ticketState;
    this.#previousTicketCustomStateId = conversation.ticketCustomStateId;

    if (conversation instanceof Conversation) {
      if (conversation.hasPartWithUUID(this.part.clientAssignedUuid)) {
        return;
      }

      conversation.pushPendingPart(this.part);
      conversation.ticketState = this.#data.ticketState;
    } else {
      conversation.ticketState = this.#data.ticketState;
      if (this.#data.ticketCustomStateId) {
        conversation.ticketCustomStateId = Number(this.#data.ticketCustomStateId);
      }
    }
  }

  rollback(conversation: ConversationRecord) {
    assertExists(this.#previousTicketState);

    if (conversation instanceof Conversation) {
      conversation.ticketState = this.#previousTicketState;
      conversation.removePendingPart(this.part);
    } else {
      conversation.ticketState = this.#previousTicketState;
      if (this.#previousTicketCustomStateId) {
        conversation.ticketCustomStateId = Number(this.#previousTicketCustomStateId);
      }
    }
  }
}

class PriorityChange extends BaseUpdate implements Update {
  @service declare session: Session;

  #priority: boolean;
  #previousPriority?: boolean;

  part: RenderablePart;

  constructor(data: { priority: boolean }) {
    super();

    this.#priority = data.priority;

    this.part = createRenderablePart(
      new PriorityChanged(
        this.session.teammate,
        data.priority ? PRIORITY_TRUE : PRIORITY_FALSE,
        data.priority ? PRIORITY_FALSE : PRIORITY_TRUE,
      ),
    );
  }

  apply(conversation: ConversationRecord) {
    this.#previousPriority = conversation.priority;
    if (conversation instanceof Conversation) {
      if (conversation.hasPartWithUUID(this.part.clientAssignedUuid)) {
        return;
      }

      conversation.priority = this.#priority;
      conversation.pushPendingPart(this.part);
    } else {
      conversation.priority = this.#priority;
    }
  }

  rollback(conversation: ConversationRecord) {
    assertExists(this.#previousPriority);

    if (conversation instanceof Conversation) {
      conversation.priority = this.#previousPriority;
      conversation.removePendingPart(this.part);
    } else {
      conversation.priority = this.#previousPriority;
    }
  }
}

class Assign extends BaseUpdate implements Update {
  @service declare session: Session;

  #data:
    | { type: 'admin'; admin: AdminSummary; currentAdmin?: AdminSummary }
    | { type: 'team'; team: TeamSummary; currentTeam?: TeamSummary; currentAdmin?: AdminSummary };

  part: RenderablePart;

  constructor(
    data:
      | { type: 'admin'; admin: AdminSummary; currentAdmin?: AdminSummary }
      | { type: 'team'; team: TeamSummary; currentTeam?: TeamSummary; currentAdmin?: AdminSummary },
  ) {
    super();

    this.#data = data;

    this.part = createRenderablePart(
      data.type === 'team'
        ? this.createTeamAssignmentData(data.team, data.currentTeam, data.currentAdmin)
        : this.createAdminAssignmentData(data.admin, data.currentAdmin),
    );
  }

  apply(conversation: ConversationRecord) {
    if (conversation instanceof Conversation) {
      if (conversation.hasPartWithUUID(this.part.clientAssignedUuid)) {
        return;
      }

      this.setAssignee(conversation);
      conversation.pushPendingPart(this.part);
    } else if (isLocallyUpdatableSummary(conversation)) {
      this.setAssignee(conversation);
    }
  }

  rollback(conversation: ConversationRecord) {
    if (conversation instanceof Conversation) {
      if (this.#data.type === 'team') {
        conversation.teamAssignee = this.#data.currentTeam;
        conversation.adminAssignee = this.#data.currentAdmin;
      } else {
        conversation.adminAssignee = this.#data.currentAdmin;
      }

      conversation.removePendingPart(this.part);
    } else if (isLocallyUpdatableSummary(conversation)) {
      if (this.#data.type === 'team') {
        conversation.teamAssignee = this.#data.currentTeam;
        conversation.adminAssignee = this.#data.currentAdmin;
      } else {
        conversation.adminAssignee = this.#data.currentAdmin;
      }
    }
  }

  private setAssignee(conversation: ConversationRecord) {
    if (this.#data.type === 'team') {
      conversation.teamAssignee = this.#data.team;
      conversation.adminAssignee = AdminSummary.unassigned;
    } else {
      conversation.adminAssignee = this.#data.admin;
    }
  }

  private createTeamAssignmentData(
    team: TeamSummary,
    currentTeam?: TeamSummary,
    currentAdmin?: AdminSummary,
  ) {
    let unassigned = AdminSummary.unassigned;
    return new Assignment(
      AssigningEntity.Teammate,
      this.session.teammate,
      [
        { entityType: AssignableEntity.Team, entity: currentTeam },
        { entityType: AssignableEntity.Teammate, entity: currentAdmin },
      ],
      [
        { entityType: AssignableEntity.Team, entity: team },
        { entityType: AssignableEntity.UnassignedTeammate, entity: unassigned },
      ],
      undefined,
    );
  }

  private createAdminAssignmentData(admin: AdminSummary, currentAdmin?: AdminSummary) {
    let fromType =
      currentAdmin?.id !== UNASSIGNED_ID
        ? AssignableEntity.Teammate
        : AssignableEntity.UnassignedTeammate;

    let toType =
      admin.id === UNASSIGNED_ID ? AssignableEntity.UnassignedTeammate : AssignableEntity.Teammate;

    return new Assignment(
      AssigningEntity.Teammate,
      this.session.teammate,
      [{ entityType: fromType, entity: currentAdmin }],
      [{ entityType: toType, entity: admin }],
      undefined,
    );
  }
}
class AddPart extends BaseUpdate implements Update {
  @service declare session: Session;

  part: RenderablePart;

  #previousLastPart?: RenderablePart;

  constructor(data: { part: RenderablePart }) {
    super();
    this.part = data.part;
  }

  apply(conversation: ConversationRecord) {
    if (conversation instanceof Conversation) {
      if (conversation.hasPartWithUUID(this.part.clientAssignedUuid)) {
        return;
      }

      conversation.pushPendingPart(this.part);
    } else if (isLocallyUpdatableSummary(conversation)) {
      this.#previousLastPart = conversation.lastRenderableSummaryPart;
      conversation.lastRenderableSummaryPart = this.part;
      conversation.lastUpdated = this.part.createdAt;
    } else {
      captureException(new Error(`Not yet implemented for ${conversation?.constructor.name}`), {
        fingerprint: ['conversation-updates', 'conversation-updates-apply'],
      });
      return;
    }
  }

  rollback(conversation: ConversationRecord) {
    if (conversation instanceof Conversation) {
      conversation.removePendingPart(this.part);
    } else if (isLocallyUpdatableSummary(conversation)) {
      assertExists(this.#previousLastPart);

      conversation.lastRenderableSummaryPart = this.#previousLastPart;
      conversation.lastUpdated = this.#previousLastPart.createdAt;
    } else {
      captureException(new Error(`Not yet implemented for ${conversation?.constructor.name}`), {
        fingerprint: ['conversation-updates', 'conversation-updates-rollback'],
      });
      return;
    }
  }
}

class UpdateTags extends BaseUpdate implements Update {
  @service declare session: Session;

  part: RenderablePart;
  tags: Tag[];

  #previousTags?: Tag[];

  constructor(data: { part: RenderablePart; tags: Tag[] }) {
    super();
    this.part = data.part;
    this.tags = data.tags;
  }

  commit() {
    // We need to manually commit updates based on tags, because we cannot tell
    // when tags were persisted based on any other data that we have.
    this.isManuallyCommitted = true;
  }

  apply(conversation: ConversationRecord) {
    // Tags apply to renderable parts, and summaries etc. don't have those yet.
    if (!(conversation instanceof Conversation)) {
      return;
    }
    assertTaggablePart(this.part);
    this.#previousTags = [...this.part.renderableData.tags];
    conversation.updateTagsForRenderablePart(this.part.entityId, this.tags);
  }

  rollback(conversation: ConversationRecord) {
    if (!(conversation instanceof Conversation) || !this.#previousTags) {
      return;
    }

    conversation.updateTagsForRenderablePart(this.part.entityId, this.#previousTags);
  }
}

class AddTag extends BaseUpdate implements Update {
  @service declare session: Session;

  partToTag: RenderablePart;
  tagToAdd: Tag;

  constructor(data: { partToTag: RenderablePart; tagToAdd: Tag }) {
    super();

    this.partToTag = data.partToTag;
    this.tagToAdd = data.tagToAdd;

    this.part = createRenderablePart(
      new ConversationTagsUpdated(
        this.session.teammate,
        [new TagSummary(Number(this.tagToAdd.id), this.tagToAdd.name)],
        [],
      ),
    );
  }

  apply(conversation: ConversationRecord) {
    // Tags apply to renderable parts, summaries for example don't have those
    if (!(conversation instanceof Conversation)) {
      return;
    }

    assertTaggablePart(this.partToTag);

    // Add tag to conversation part
    conversation.addTagToRenderablePart(this.partToTag.entityId, this.tagToAdd);

    // Create event part
    conversation.pushPendingPart(this.part);
  }

  rollback(conversation: ConversationRecord) {
    // Tags apply to renderable parts, summaries for example don't have those
    if (!(conversation instanceof Conversation)) {
      return;
    }

    // Rollback tags for part
    conversation.removeTagFromRenderablePart(this.partToTag.entityId, this.tagToAdd);

    // Rollback event part
    conversation.removePendingPart(this.part);
  }
}

class RemoveTag extends BaseUpdate implements Update {
  @service declare session: Session;

  partToTag: RenderablePart;
  tagToRemove: Tag;

  constructor(data: { partToTag: RenderablePart; tagToRemove: Tag }) {
    super();

    this.partToTag = data.partToTag;
    this.tagToRemove = data.tagToRemove;

    this.part = createRenderablePart(
      new ConversationTagsUpdated(
        this.session.teammate,
        [],
        [new TagSummary(Number(this.tagToRemove.id), this.tagToRemove.name)],
      ),
    );
  }

  apply(conversation: ConversationRecord) {
    // Tags apply to renderable parts, summaries for example don't have those
    if (!(conversation instanceof Conversation)) {
      return;
    }

    assertTaggablePart(this.partToTag);

    // Remove tag from conversation part
    conversation.removeTagFromRenderablePart(this.partToTag.entityId, this.tagToRemove);

    // Create event part
    conversation.pushPendingPart(this.part);
  }

  rollback(conversation: ConversationRecord) {
    // Tags apply to renderable parts, summaries for example don't have those
    if (!(conversation instanceof Conversation)) {
      return;
    }

    // Rollback tags for part
    conversation.addTagToRenderablePart(this.partToTag.entityId, this.tagToRemove);
    // Rollback event part
    conversation.removePendingPart(this.part);
  }
}

class ConversationAttributeUpdate extends BaseUpdate implements Update {
  @service declare session: Session;

  #attribute: ConversationAttributeSummary;

  part: RenderablePart;

  constructor(data: { attribute: ConversationAttributeSummary; displayValue: string | undefined }) {
    super();
    this.#attribute = data.attribute;
    let id = Number(this.#attribute.descriptor.id) || 0;
    this.part = createRenderablePart(
      new ConversationAttributeUpdated(
        this.session.teammate,
        EntityType.Admin,
        new CASummary(id, this.#attribute.descriptor.name, false),
        data.displayValue || data.attribute.value,
      ),
    );
  }

  apply(conversation: ConversationRecord): void {
    if (conversation instanceof Conversation) {
      if (conversation.hasPartWithUUID(this.part.clientAssignedUuid)) {
        return;
      }
      this.setAttribute(conversation);
      conversation.pushPendingPart(this.part);
    } else if (isLocallyUpdatableSummary(conversation)) {
      this.setAttribute(conversation);
    }
  }

  rollback(conversation: ConversationRecord): void {
    this.#attribute.rollback();
    if (conversation instanceof Conversation) {
      conversation.removePendingPart(this.part);
    }
  }

  private setAttribute(conversation: ConversationRecord) {
    if (!conversation.hasAttributeForDescriptor(this.#attribute.descriptor.id)) {
      conversation.addAttribute(this.#attribute);
      return;
    }

    // if conversation has the attribute we should update the value with #attribute.value
    let attribute = conversation.attributes?.find(
      (attr) => attr.descriptor.id === this.#attribute.descriptor.id,
    );

    if (attribute && attribute.value !== this.#attribute.value) {
      attribute.update(this.#attribute.value);
    }
  }
}

const UPDATES = {
  'state-change': StateChange,
  'priority-change': PriorityChange,
  assign: Assign,
  'add-part': AddPart,
  'update-tags': UpdateTags,
  'add-tag': AddTag,
  'remove-tag': RemoveTag,
  'ticket-state-change': TicketStateChange,
  'conversation-attribute-update': ConversationAttributeUpdate,
};

type UpdateType = keyof typeof UPDATES;
type ParametersForUpdate<T extends UpdateType> = ConstructorParameters<(typeof UPDATES)[T]>[0];

export type UpdatesApi = {
  add: <T extends UpdateType>(type: T, data: ParametersForUpdate<T>) => Update;
  rollback: () => unknown;
  markAsFailed: () => unknown;
};

export type UpdateMessage = {
  type: 'added' | 'removed';
  conversationId: string | number;
  entries: Update[];
};

type Subscriber = (updates: UpdateMessage[]) => unknown;

export default class ConversationUpdates extends Service {
  #pendingUpdates: Map<Conversation['id'], Update[]> = new Map();
  #subscribers: Subscriber[] = [];

  updatesFor(id: number) {
    return this.#pendingUpdates.get(id) ?? [];
  }

  updatesAfter(id: number, after: Date) {
    return this.updatesFor(id).filter((update) => update.part.createdAt > after);
  }

  get subscribers() {
    return this.#subscribers;
  }

  async do<R extends ConversationRecord>(
    record: R,
    callback: (updates: UpdatesApi) => Promise<void>,
  ) {
    if (typeof record.id === 'undefined') {
      captureException(new Error('Conversation id is undefined'));
      return;
    }

    let currentUpdates: Update[] = [];

    return await callback({
      add: <T extends UpdateType>(type: T, data: ParametersForUpdate<T>) => {
        let update = this.addUpdate(record.id, type, data);
        currentUpdates.push(update);
        return update;
      },
      markAsFailed: () => {
        currentUpdates.forEach((update) => update.markAsFailed?.());
      },
      rollback: () => {
        this.rollbackUpdates(record.id, currentUpdates);
      },
    });
  }

  dropCommittedUpdates(conversation: Conversation) {
    this.#pendingUpdates.set(
      conversation.id,
      this.updatesFor(conversation.id).filter((update) => !update.isCommitted(conversation)),
    );
  }

  subscribe(callback: Subscriber) {
    let existing = this.#subscribers ?? [];
    this.#subscribers = [...existing, callback];
  }

  unsubscribe(callback: Subscriber) {
    this.#subscribers = this.#subscribers.without(callback);
  }

  addUpdate<T extends UpdateType>(id: number, type: T, data: ParametersForUpdate<T>): Update {
    let updates = this.addUpdateForIds([id], type, data);
    return updates[id];
  }

  addUpdates<T extends UpdateType>(ids: number[], type: T, data: ParametersForUpdate<T>) {
    return this.addUpdateForIds(ids, type, data);
  }

  rollbackUpdates(id: number, updates: Update[]) {
    let updatesForRecord = this.updatesFor(id);
    let nextUpdates = updatesForRecord.reject((update) => updates.includes(update));
    this.#pendingUpdates.set(id, nextUpdates);

    this.publish([
      {
        type: 'removed',
        conversationId: id,
        entries: updates,
      },
    ]);
  }

  private publish(updates: UpdateMessage[]) {
    this.#subscribers.forEach((subscriber) => subscriber(updates));
  }

  private addUpdateForIds<T extends UpdateType>(
    ids: number[],
    type: T,
    data: ParametersForUpdate<T>,
  ) {
    let updatesById: Record<number, Update> = {};

    for (let id of ids) {
      // @ts-ignore TS seems to not recognise that data is the correct shape.
      // It enforces it in other places, i.e. when calling this method, but
      // it's union-ing all possible constructor parameters here. Ignoring for
      // now.
      let update = new UPDATES[type](data);
      updatesById[id] = update;

      let updatesForRecord = this.updatesFor(id);
      updatesForRecord.push(update);
      this.#pendingUpdates.set(id, updatesForRecord);
    }

    this.publish(
      ids.map((id) => ({
        type: 'added',
        conversationId: id,
        entries: [updatesById[id]],
      })),
    );

    return updatesById;
  }

  existingUpdate(part: RenderablePart, conversation: Conversation) {
    return this.updatesFor(conversation.id).find(
      (update) => update.part.clientAssignedUuid === part.clientAssignedUuid,
    );
  }
}

function isLocallyUpdatableSummary(
  conversation: ConversationRecord,
): conversation is ConversationSummary | ConversationTableEntry {
  return (
    conversation instanceof ConversationTableEntry || conversation instanceof ConversationSummary
  );
}

declare module '@ember/service' {
  interface Registry {
    conversationUpdates: ConversationUpdates;
    'conversation-updates': ConversationUpdates;
  }
}
