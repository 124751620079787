/* import __COLOCATED_TEMPLATE__ from './fin-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import Component from '@glimmer/component';
import { CustomerAnswerRating, Status, type PlaygroundQuestion } from 'embercom/lib/fin-playground';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from 'embercom/lib/knowledge-hub/constants';

interface FinAnswerComponentArgs {
  isRunning: boolean;
  appId: string;
  question?: PlaygroundQuestion;
  generateAnswer: () => void;
  playgroundIsLoading: boolean;
}

type FinAnswerComponentSignature = {
  Args: FinAnswerComponentArgs;
  Element: HTMLDivElement;
};

export default class AiAgentPlaygroundFinAnswer extends Component<FinAnswerComponentSignature> {
  CustomerAnswerRating = CustomerAnswerRating;
  Status = Status;

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  get answer() {
    return this.args.question?.responseText;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer': typeof AiAgentPlaygroundFinAnswer;
  }
}
