/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { regularExpressions } from 'ember-validators/format';
import ENV from 'embercom/config/environment';

const httpsRegex = /^https:\/\//;

const isHttps = (value) => {
  return ENV.environment === 'development' || httpsRegex.test(value);
};

const isWithinLengthLimit = (value) => {
  return value.length <= 250;
};
export default BaseValidator.extend({
  validate(value) {
    let urlRegex = regularExpressions.url;
    let localhostRegex = /^http:\/\/localhost/;
    if (!isWithinLengthLimit(value)) {
      return 'This field must be less than 250 characters';
    }
    if (urlRegex.test(value) && isHttps(value)) {
      return true;
    } else if (localhostRegex.test(value)) {
      return true;
    } else {
      return 'This field must be a valid url';
    }
  },
});
