/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { readOnly, map } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';
import { isAny } from '@intercom/pulse/lib/computed-properties';

const Validations = buildValidations(
  {
    url: [
      validator('presence', true),
      validator('format', { type: 'url' }),
      validator('https-except-in-development'),
    ],
  },
  {
    disabled: readOnly('model.validationsDisabled'),
  },
);

export default Fragment.extend(Validations, {
  url: attr('string'),
  topics: attr('array', {
    defaultValue() {
      return [];
    },
  }),
  suspended: attr('boolean'),
  validationsDisabled: true,

  urlErrors: readOnly('validations.attrs.url.errors'),
  missingRequiredScopes: isAny('topicObjects', 'missingRequiredScopes', true),

  topicObjects: map('topics', function (topic) {
    return this.store.peekRecord('developer-hub/webhook-topic', topic);
  }),

  validate() {
    this.set('validationsDisabled', false);
    return this._super(...arguments);
  },

  unavaliableTopics(apiVersion) {
    let topicsIds = apiVersion.get('webhookTopics').map((x) => {
      return x.get('id');
    });
    return this.topics.filter((x) => !topicsIds.includes(x));
  },

  rollbackAttributes() {
    this.set('validationsDisabled', true);
    return this._super(...arguments);
  },
  setlive() {
    this.set('suspended', false);
    return this._super(...arguments);
  },
  rollbackSetlive() {
    this.set('suspended', true);
    return this._super(...arguments);
  },
});
