/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-phone */
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { Call, Device } from '@twilio/voice-sdk';
import { type TwilioError } from '@twilio/voice-sdk/es5/twilio/errors';
import Ember from 'ember';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import ENV from 'embercom/config/environment';
import { ajaxDelete, get, post } from 'embercom/lib/ajax';
import type PhoneNumber from 'embercom/models/calling-phone-number';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import Metrics from 'embercom/models/metrics';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import CallParticipantSummary from 'embercom/objects/inbox/callings/call-participant-summary';
import Conversation, { type ConversationWireFormat } from 'embercom/objects/inbox/conversation';
import type User from 'embercom/objects/inbox/user';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import PhoneCall from 'embercom/objects/phone/phone-call';
import type AdminAwayService from 'embercom/services/admin-away-service';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type LogService from 'embercom/services/log-service';
import type Session from 'embercom/services/session';
import type Snackbar from 'embercom/services/snackbar';
import { type Notification } from 'embercom/services/snackbar';
import moment from 'moment-timezone';
import {
  type InboundCallbackRequestEvent,
  type ConferenceParticipantAddedEvent,
  type ConferenceParticipantRemovedEvent,
  type ConferenceParticipantFailedToJoinEvent,
  type WarmTransferAddedEvent,
  type WarmTransferFailedEvent,
  type WarmTransferCompletedEvent,
} from './nexus';
import type Inbox2AssigneeSearch from './inbox2-assignee-search';
import type InboxSearchSuggestionsService from './inbox-search-suggestions-service';
import type ParticipantUserSummary from 'embercom/objects/inbox/participant-user-summary';

const PHONE_NOTIFICATION_ROUTE_PREFIXES = [
  'inbox.workspace.inbox',
  'inbox.workspace.search',
  'inbox.workspace.dashboard',
];

const ONE_MINUTE = ENV.APP._1M;
const PHONE_HELP_DESK_ONLY_NOTIFICATION_DISMISSED = 'phone-help-desk-only-notification-dismissed';
const NOTIFICATION_TIMEOUT = 30000;
const WARM_TRANSFER_NOTIFICATION_TIMEOUT = 6000;
const MANUAL_ANSWER_NOTIFICATION_TIMEOUT = 6000;

export default class TwilioService extends Service {
  @tracked shouldNotifyTeammate = false;
  @tracked conversation?: Conversation;
  @tracked userId?: string;
  @tracked oldAdminId?: string;
  @tracked oldTeamId: string | undefined = undefined;
  @tracked currentCallAction?:
    | 'transferring-to-teammate'
    | 'warm-transferring-to-teammate'
    | 'transferring-externally'
    | 'warm-transferring-externally'
    | 'adding-participant';
  @tracked addingCallParticipantInfo?: { identifier: string | number; label: string };
  @tracked isOnHold = false;
  @tracked disableHold = false;
  @tracked isRecordingEnabled = false;
  @tracked isRecording = false;
  @tracked isInitialized = false;
  @tracked workspacePhoneNumber: string | undefined = undefined;
  @tracked calledNumberCountryCode: string | undefined = undefined;
  @tracked unregisteredNotification?: Notification;
  @tracked noPermissionNotification?: Notification;
  @tracked hasActivePhoneNumbers = false;
  @tracked isListening = false;
  @tracked incomingCallType?:
    | 'callback'
    | 'conference'
    | 'transfer'
    | 'warm-transfer'
    | 'phone-call';
  @tracked acceptedCallback = true;
  @tracked callbackAcceptanceTimer: NodeJS.Timeout | null = null;
  @tracked addingParticipantTimer: NodeJS.Timeout | null = null;
  @tracked deviceStatusPoller: ReturnType<typeof setTimeout> | null = null;
  @tracked adminLacksMicrophonePermissions = false;
  @tracked participants: CallParticipantSummary[] = [];
  @tracked windowFocusOnlineAbortController?: AbortController;
  @tracked deviceDestroyReason?: 'navigation';
  @tracked warmTransferState?: 'ringing' | 'talking' | 'failed' | 'background-hold';
  @tracked warmTransferStartTime?: number;
  @tracked warmTransferUser?: UserSummary;
  @service declare snackbar: Snackbar;
  @service declare customerService: any;
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @service declare intercomCallService: IntercomCallService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare adminAwayService: AdminAwayService;
  @service declare logService: LogService;
  @service declare notificationsService: $TSFixMe;

  @tracked activeCall?: PhoneCall;
  incomingCall: Call | null = null;
  device: Device | null = null;
  callbackAudio: HTMLAudioElement = new window.Audio(assetUrl('/assets/audio/incoming.mp3'));
  isTabManuallyAnswering = false;

  get isTransferToTeammate() {
    return this.currentCallAction === 'transferring-to-teammate';
  }

  get isWarmTransferToTeammate() {
    return this.currentCallAction === 'warm-transferring-to-teammate';
  }

  get isWarmTransferToExternalNumber() {
    return this.currentCallAction === 'warm-transferring-externally';
  }

  get isWarmTransfer() {
    return this.isWarmTransferToTeammate || this.isWarmTransferToExternalNumber;
  }

  get isTransferToExternalNumber() {
    return this.currentCallAction === 'transferring-externally';
  }

  get isAddingParticipant() {
    return this.currentCallAction === 'adding-participant';
  }

  get isIncomingCallback() {
    return this.incomingCallType === 'callback';
  }

  get isIncomingCallConference() {
    return this.incomingCallType === 'conference';
  }

  get isIncomingCallTransfer() {
    return this.incomingCallType === 'transfer';
  }

  get isIncomingCallWarmTransfer() {
    return this.incomingCallType === 'warm-transfer';
  }

  get userSummary() {
    let user = this.conversation?.userSummary;

    if (this.userId) {
      return this.conversation?.participantSummaries.findBy('id', this.userId) ?? user;
    }

    return user;
  }

  get isPhoneRoute() {
    return PHONE_NOTIFICATION_ROUTE_PREFIXES.some((routePrefix) =>
      this.router.currentRouteName?.startsWith(routePrefix),
    );
  }

  async initialize() {
    try {
      this.deviceDestroyReason = undefined;

      this.setupWindowListeners();

      let callingSettings = await get(`/ember/inbox/calling_settings`, {
        app_id: this.session.workspace.id,
      });
      this.isRecordingEnabled = callingSettings?.recording_enabled;
      this.isRecording = this.isRecordingEnabled;
      this.hasActivePhoneNumbers =
        callingSettings.phone_numbers.filter((number: PhoneNumber) =>
          ['active', 'missing_bundle'].includes(number.status),
        ).length > 0;

      if (!this.hasActivePhoneNumbers) {
        return; // If there are no active phone numbers, we don't need to initialize the Twilio services
      }

      await this.checkAdminMicrophonePermissions();

      if (this.adminLacksMicrophonePermissions) {
        this.noPermissionNotification = this.snackbar.notify(
          this.intl.t('calling.incoming-phone-call-modal.no-permissions-set'),
          {
            type: 'error',
            persistent: true,
            clearable: true,
          },
        );
      }

      await this.setupDevice();
      this.isInitialized = true;
    } catch (error) {
      this.logTwilioEvent('failed-to-initialize-device', { error });
      Metrics.capture({ increment: ['twilio.device.initialize-failure'] });

      if (this.hasActivePhoneNumbers) {
        this.showPhoneUnregisteredError();
      }
    }
  }

  async unregisterDevice() {
    if (this.isInitialized) {
      this.deviceDestroyReason = 'navigation';
      this.windowFocusOnlineAbortController && this.windowFocusOnlineAbortController.abort();
      this.device?.destroy();
      this.isInitialized = false;
    }
  }

  setupWindowListeners() {
    // Clean up any existing listeners to make sure we don't have duplicates
    this.windowFocusOnlineAbortController && this.windowFocusOnlineAbortController.abort();
    this.windowFocusOnlineAbortController = new AbortController();

    let windowFocusOnlineListener = async (eventType: string) => {
      if (!this.isInitialized) {
        return;
      }

      if (!this.device || this.device.state === 'destroyed') {
        this.logTwilioEvent(`${eventType}-listener-setting-up-device`, {});
        await this.setupDevice();
      }
    };

    let signal = this.windowFocusOnlineAbortController.signal;

    window.addEventListener('focus', () => windowFocusOnlineListener('focus'), { signal });
    window.addEventListener('online', () => windowFocusOnlineListener('online'), { signal });
    window.addEventListener('beforeunload', () => (this.deviceDestroyReason = 'navigation'), {
      signal,
    });
  }

  async setupDevice() {
    let tokenData = await this.getToken();
    let options: Device.Options = {
      closeProtection: true,
      enableImprovedSignalingErrorPrecision: true,
      tokenRefreshMs: 60000,
      maxCallSignalingTimeoutMs: 5000,
    };
    this.device = new Device(tokenData.token, options);

    this.device.on('tokenWillExpire', async () => {
      this.logTwilioEvent('tokenWillExpire', {});
      let newTokenData = await this.getToken();
      if (this.device) {
        this.device.updateToken(newTokenData.token);
      }
    });

    this.device.on('incoming', async (call: Call) => await this.handleIncomingCall(call));

    this.device.on(
      'error',
      async (twilioError: TwilioError) => await this.handleDeviceError(twilioError),
    );

    this.device.on('registered', () => {
      this.deviceDestroyReason = undefined;

      // If we are registering but the service is not initialized, it means we are connecting for the first time.
      let isReconnect = this.isInitialized;

      Metrics.capture({
        increment: ['twilio.device.registered'],
        tags: {
          isReconnect,
        },
      });

      Metrics.capture({ increment: ['twilio.device.count'] });

      this.startSendingDevicePresence();
      this.logTwilioEvent('device-registered', { isReconnect });
      if (this.unregisteredNotification) {
        this.snackbar.clearNotification(this.unregisteredNotification);
        this.unregisteredNotification = undefined;
      }

      if (this.hasActivePhoneNumbers) {
        this.snackbar.notify(this.intl.t('calling.incoming-phone-call-modal.ready-to-receive'), {
          persistent: false,
          clearable: true,
        });
      }
    });

    this.device.on('unregistered', () => this.handleDeviceUnregistered());

    this.device.on('destroyed', () => {
      this.logTwilioEvent('device-destroyed', { reason: this.deviceDestroyReason });

      Metrics.capture({
        increment: ['twilio.device.destroyed'],
        tags: {
          reason: this.deviceDestroyReason,
        },
      });
    });

    await this.device.register();
  }

  addCallListeners(call: Call, direction: 'inbound' | 'outbound') {
    call.on('reconnecting', (twilioError: TwilioError) =>
      this.logTwilioEvent(`${direction}-call-reconnecting`, {}, twilioError),
    );

    call.on('reconnected', () =>
      this.logTwilioEvent(`${direction}-call-reconnected`, call?.parameters),
    );

    call.on('disconnect', (call: Call) =>
      this.logTwilioEvent(`${direction}-call-disconnect`, call.parameters),
    );

    call.on('accept', (call: Call) =>
      this.logTwilioEvent(`${direction}-call-accept`, call.parameters),
    );

    call.on('error', (twilioError: TwilioError) => {
      Metrics.capture({
        increment: ['twilio.call.error'],
        tags: {
          error_code: twilioError.code,
        },
      });

      this.logTwilioEvent(`${direction}-call-error`, {}, twilioError);
    });

    call.on('warning', (warningName: string, warningData) => {
      Metrics.capture({
        increment: ['call.quality.warning'],
        tags: {
          warning_name: warningName,
        },
      });

      this.logTwilioEvent(`${direction}-call-warning`, { warningName, warningData });
    });

    call.on('reject', () => this.logTwilioEvent(`${direction}-call-reject`, {}));
  }

  async handleIncomingCall(call: Call) {
    this.logTwilioEvent('call-incoming', call.parameters);

    this.adminAwayService.setAdminAsAvailable();
    this.incomingCall = call;

    let isManualAnswer = call.customParameters.get('manualAnswer') === 'true';

    if (call.customParameters.get('isTransfer') === 'true') {
      this.incomingCallType = 'transfer';
    } else if (call.customParameters.get('isWarmTransfer') === 'true') {
      this.incomingCallType = 'warm-transfer';
    } else if (call.customParameters.get('isConference') === 'true') {
      this.incomingCallType = 'conference';
    } else {
      this.incomingCallType = 'phone-call';
    }

    this.oldAdminId = call.customParameters.get('oldAdminId');
    this.oldTeamId = call.customParameters.get('oldTeamId');
    this.workspacePhoneNumber = `+${call.customParameters.get('workspaceNumber')}`;
    this.calledNumberCountryCode = call.customParameters.get('countryCode');
    this.isListening = call.customParameters.get('listening') === 'true';
    this.conversation = await this.inboxApi.fetchConversation(
      Number(call.customParameters.get('conversationId')),
    );
    this.userId = this.conversation.firstParticipant.id;

    let participants = [];

    if (this.isIncomingCallConference) {
      let adminParticipantId = call.customParameters.get('admin_participant');
      let userParticipantId = call.customParameters.get('user_participant');

      participants = await this.loadParticipants(adminParticipantId, userParticipantId);
    } else {
      participants.push(new CallParticipantSummary(this.userSummary!));
      participants.push(new CallParticipantSummary(this.conversation.adminAssignee!));
    }

    this.participants = participants;

    await this.recordEvent('receive_phone_call', this.conversation.id);

    if (isManualAnswer) {
      // The call should only be automatically answered on the tab that pressed manual answer
      // This prevents bad behavior when multiple tabs are open during manual answer
      if (!this.isTabManuallyAnswering) {
        return;
      }
      this.shouldNotifyTeammate = false;
      await this.acceptCall();
      // Re-enable the incoming ringer for the next call
      this.device?.audio?.incoming(true);
      this.isTabManuallyAnswering = false;
    } else {
      this.shouldNotifyTeammate = true;
    }

    this.incomingCall.on('cancel', () => {
      this.incomingCall = null;
      this.shouldNotifyTeammate = false;
      this.resetCallAttributes();
      this.logTwilioEvent('call-cancel', {});
    });

    this.addCallListeners(call, 'inbound');
  }

  async handleDeviceUnregistered() {
    this.stopSendingDevicePresence();
    this.logTwilioEvent('device-unregistered', {});

    Metrics.capture({ increment: ['twilio.device.unregistered'] });
    Metrics.capture({ decrement: ['twilio.device.count'] });

    if (!this.hasActivePhoneNumbers) {
      return;
    }
    // If we are in the inbox, show the unregistered toast so they know their phone is broken,
    // otherwise we have left the inbox, so we should show the warning that they won't be getting any calls.
    if (this.isPhoneRoute) {
      this.showPhoneUnregisteredError();
    } else if (localStorage.getItem(PHONE_HELP_DESK_ONLY_NOTIFICATION_DISMISSED) !== 'true') {
      // Teammate has moved away from the help desk
      this.notificationsService.notifyWarning(
        this.intl.t('calling.incoming-phone-call-modal.unregistered-away-from-help-desk', {
          url: '#',
          htmlSafe: true,
        }),
        NOTIFICATION_TIMEOUT,
      );
      localStorage.setItem(PHONE_HELP_DESK_ONLY_NOTIFICATION_DISMISSED, 'true');
    }
  }

  async handleDeviceError(twilioError: TwilioError) {
    this.logTwilioEvent('device-error', {}, twilioError);

    Metrics.capture({
      increment: ['twilio.device.error'],
      tags: {
        error_code: twilioError.code,
      },
    });

    if (twilioError.code === 20104 && this.device) {
      let newTokenData = await this.getToken();
      this.device.updateToken(newTokenData.token);
    }
  }

  showPhoneUnregisteredError() {
    if (!this.unregisteredNotification) {
      this.unregisteredNotification = this.snackbar.notify(
        this.intl.t('calling.incoming-phone-call-modal.unregistered'),
        {
          type: 'error',
          persistent: true,
          clearable: true,
          contentComponent: 'inbox2/left-nav/notification-nexus-error',
        },
      );
    }
  }

  async loadParticipants(
    adminParticipantId?: string,
    userParticipantId?: string,
  ): Promise<CallParticipantSummary[]> {
    if (!this.conversation) {
      return [];
    }

    let participants: CallParticipantSummary[] = [];

    if (adminParticipantId) {
      let foundAdmin = this.inbox2AssigneeSearch.findAdminById(Number(adminParticipantId));

      if (foundAdmin) {
        participants.push(new CallParticipantSummary(foundAdmin));
      }
    }

    if (userParticipantId) {
      // Lets try and load from the conversation first before doing an API call
      let foundUser: ParticipantUserSummary | null | undefined =
        this.conversation.participantSummaries.findBy('id', userParticipantId);

      if (foundUser) {
        participants.push(new CallParticipantSummary(foundUser));
      } else {
        foundUser = await this.inboxSearchSuggestionsService.loadUserFromId(userParticipantId);

        if (foundUser) {
          participants.push(new CallParticipantSummary(foundUser));
        }
      }
    }

    return participants;
  }

  get oldAdmin() {
    if (this.oldAdminId) {
      return this.inbox2AssigneeSearch.findAdminById(Number(this.oldAdminId));
    }

    return undefined;
  }

  async handleConferenceParticipantAdded(event: ConferenceParticipantAddedEvent) {
    if (!this.conversation || this.conversation.id !== event.eventData.conversationId) {
      return;
    }

    if (!this.isActiveCall) {
      return;
    }

    await this.updateParticipants(event.eventData);
    this.recordEvent('conference_participant_added', this.conversation.id);

    if (!this.addingParticipantTimer) {
      return;
    }

    if (!this.addingCallParticipantInfo) {
      return;
    }

    let participantAdded = this.participants.find((p) => {
      if (p.id === this.addingCallParticipantInfo?.identifier) {
        return true;
      }

      return p.phone && p.phone === this.addingCallParticipantInfo?.identifier;
    });

    if (participantAdded) {
      this.tearDownAddingParticipantTimer();
    }
  }

  async handleConferenceParticipantRemoved(event: ConferenceParticipantRemovedEvent) {
    if (!this.conversation || this.conversation.id !== event.eventData.conversationId) {
      return;
    }

    if (!this.isActiveCall) {
      return;
    }

    await this.updateParticipants(event.eventData);
    this.recordEvent('conference_participant_removed', this.conversation.id);
  }

  async handleConferenceParticipantFailedToJoin(event: ConferenceParticipantFailedToJoinEvent) {
    if (!this.conversation || this.conversation.id !== event.eventData.conversationId) {
      return;
    }

    if (!this.isActiveCall) {
      return;
    }

    await this.updateParticipants(event.eventData);
    this.recordEvent('conference_participant_failed_to_join', this.conversation.id);

    if (!this.addingParticipantTimer) {
      return;
    }

    this.tearDownAddingParticipantTimer();
  }

  async handleWarmTransferAdded(_: WarmTransferAddedEvent) {
    this.warmTransferState = 'talking';
    this.warmTransferStartTime = moment().valueOf();
  }

  async handleWarmTransferCompleted(event: WarmTransferCompletedEvent) {
    let admin = this.inbox2AssigneeSearch.findAdminById(event.eventData.adminId);
    this.notifyTransferSuccess(admin?.name);
    if (this.isIncomingCallWarmTransfer) {
      this.incomingCallType = 'phone-call';
      this.activeCall?.handleAccepted();
    }
  }

  async handleWarmTransferFailed(event: WarmTransferFailedEvent) {
    if (!this.isWarmTransfer) {
      return;
    }

    this.notifyTransferFailure(event.eventData.status, this.addingCallParticipantInfo?.label);
    this.currentCallAction = undefined;
    this.warmTransferState = undefined;
    this.warmTransferUser = undefined;
    this.addingCallParticipantInfo = undefined;
    this.isOnHold = true;
  }

  async updateParticipants(
    eventData:
      | ConferenceParticipantAddedEvent['eventData']
      | ConferenceParticipantRemovedEvent['eventData']
      | ConferenceParticipantFailedToJoinEvent['eventData'],
  ) {
    let participants: CallParticipantSummary[] = [];
    let userPromises: Promise<UserSummary | null>[] = [];

    eventData.userParticipants.forEach((id) => {
      let foundUser = this.participants.findBy('id', id);

      if (foundUser) {
        participants.push(foundUser);
        return;
      }

      userPromises.push(this.inboxSearchSuggestionsService.loadUserFromId(id));
    });

    eventData.adminParticipants.forEach((id) => {
      let foundAdmin = this.participants.findBy('id', id);

      if (foundAdmin) {
        participants.push(foundAdmin);
        return;
      }

      let loadedAdmin = this.inbox2AssigneeSearch.findAdminById(id);
      if (loadedAdmin) {
        participants.push(new CallParticipantSummary(loadedAdmin));
      }
    });

    let loadedUsers = await Promise.all(userPromises);

    loadedUsers.forEach((user) => {
      if (user) {
        participants.push(new CallParticipantSummary(user));
      }
    });

    this.participants = participants;
  }

  async acceptListeningCall(conversationId?: number | undefined) {
    this.shouldNotifyTeammate = false;
    if (conversationId && !this.conversation) {
      this.conversation = await this.inboxApi.fetchConversation(conversationId);
    }
    if (!this.conversation || !this.incomingCall) {
      return;
    }
    this.incomingCall.accept();
    this.intercomCallService.setAdminAsCoaching();
    this.activeCall = new PhoneCall(this.incomingCall, this.conversation);
    this.recordEvent('start_listening_to_call', this.conversation.id);

    this.activeCall.onStateChange(async (event: CustomEvent) => {
      if (this.activeCall) {
        this.activeCall.callState = event.detail.type;
      }

      if (event.detail.type === 'disconnect') {
        if (this.conversation) {
          this.recordEvent('end_listening_to_call', this.conversation.id);
        }

        this.activeCall = undefined;
        this.incomingCall = null;

        this.adminAwayService.setAdminAsAvailable();
        this.isListening = false;
        this.shouldNotifyTeammate = false;
      }
    });
  }

  async acceptCall() {
    this.shouldNotifyTeammate = false;
    if (!this.conversation || !this.incomingCall) {
      return;
    }

    if (this.isIncomingCallTransfer) {
      await this.removeAdminFromCall();
    } else if (this.isIncomingCallConference) {
      this.participants = [...this.participants, new CallParticipantSummary(this.session.teammate)];
    }

    this.incomingCall.accept();
    this.intercomCallService.setAdminOnCall();
    this.activeCall = new PhoneCall(this.incomingCall, this.conversation);
    this.recordEvent('accept_inbound_phone_call', this.conversation.id);

    this.activeCall.onStateChange(async (event: CustomEvent) => {
      if (this.activeCall) {
        this.activeCall.callState = event.detail.type;
      }
      let callConversation = this.activeCall?.conversation;
      let hangedUpByAdmin = this.activeCall?.hangedUpByAdmin;

      if (event.detail.type === 'disconnect') {
        let duration = this.activeCall?.duration;
        if (this.conversation) {
          this.recordEvent('end_inbound_phone_call', this.conversation.id, duration);
        }

        if (this.isColdTransfer && !hangedUpByAdmin) {
          this.notifyTransferSuccess();
        }

        this.incomingCall = null;
        this.resetCallAttributes();

        if (event.detail.oldType === 'accept' && callConversation instanceof Conversation) {
          await this.intercomCallService.setEndCallAdminState();
        }
      }
    });

    this.navigateToConversation();
  }

  get isColdTransfer() {
    return this.isTransferToTeammate || this.isTransferToExternalNumber;
  }

  async rejectCall() {
    if (!this.incomingCall) {
      return;
    }

    this.shouldNotifyTeammate = false;
    this.incomingCall.reject();
    this.resetCallAttributes();
  }

  async callNumber(
    phoneNumber: string,
    conversation: Conversation,
    user: User | UserSummary,
    workspaceNumber: string | null = null,
  ) {
    if (!this.isInitialized) {
      await this.initialize();
    }

    this.intercomCallService.setAdminOnCall();

    this.conversation = conversation;
    this.userId = user.id.toString();

    let outgoingCallParams: any = {
      To: phoneNumber,
      AppId: this.session.workspace.id,
      ConversationId: conversation.id.toString(),
      AdminId: this.session.teammate.id.toString(),
      UserId: this.userId,
    };

    outgoingCallParams = {
      ...outgoingCallParams,
      WorkspacePhoneNumber: workspaceNumber,
    };

    if (this.intercomCallService.nextCallIsTestCall) {
      this.intercomCallService.nextCallIsTestCall = false;
      outgoingCallParams.IsFreeUsageTestCall = true;
    }

    let outgoingCall = await this.device?.connect({
      params: outgoingCallParams,
    });

    if (!(outgoingCall instanceof Call)) {
      return null;
    }

    this.activeCall = new PhoneCall(outgoingCall, conversation);
    this.recordEvent('initiate_phone_call', conversation.id);

    this.participants = [
      new CallParticipantSummary(this.userSummary!),
      new CallParticipantSummary(this.session.teammate),
    ];

    this.workspacePhoneNumber = workspaceNumber || undefined;

    this.activeCall.onStateChange(async (event: CustomEvent) => {
      if (this.activeCall) {
        this.activeCall.callState = event.detail.type;
      }
      let callConversation = this.activeCall?.conversation;

      if (event.detail.type === 'disconnect') {
        let duration = this.activeCall?.duration;
        let hangedUpByAdmin = this.activeCall?.hangedUpByAdmin;
        this.recordEvent('end_phone_call', conversation.id, duration);

        if (this.isColdTransfer && !hangedUpByAdmin) {
          this.notifyTransferSuccess();
        }

        this.resetCallAttributes();

        if (event.detail.oldType === 'accept' && callConversation instanceof Conversation) {
          await this.intercomCallService.setEndCallAdminState();
        }
        if (
          ['ringing', 'error'].includes(event.detail.oldType) &&
          callConversation instanceof Conversation
        ) {
          if (hangedUpByAdmin) {
            this.adminAwayService.setAdminAsAvailable();
          } else {
            await this.intercomCallService.endCall(callConversation.id, duration);
          }
        }
      } else if (event.detail.type === 'accept' && callConversation instanceof Conversation) {
        await this.intercomCallService.startEscalationCall(
          callConversation.id,
          outgoingCall?.parameters.CallSid,
        );

        this.recordEvent('phone_call_accepted', conversation.id);
      }
    });

    this.addCallListeners(outgoingCall, 'outbound');

    return this.activeCall;
  }

  notifyTransferSuccess(transferee = this.addingCallParticipantInfo?.label) {
    this.snackbar.notify(
      this.intl.t('calling.incoming-phone-call-modal.successful-transfer', {
        name: transferee,
      }),
      { timeout: WARM_TRANSFER_NOTIFICATION_TIMEOUT },
    );
  }

  notifyTransferFailure(status: string, transferee?: string) {
    this.snackbar.notify(
      this.intl.t(`calling.incoming-phone-call-modal.failed-transfer-${status}`, {
        name: transferee,
      }),
      { type: 'error', timeout: WARM_TRANSFER_NOTIFICATION_TIMEOUT },
    );
  }

  @task({ drop: true })
  *toggleOnHold(): TaskGenerator<void> {
    if (this.disableHold) {
      return;
    }

    if (!this.conversation) {
      return;
    }

    let url = this.isOnHold ? '/ember/phone_call/take_off_hold' : '/ember/phone_call/place_on_hold';
    yield post(url, {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
    });
    this.isOnHold = !this.isOnHold;
  }

  @task({ drop: true })
  *toggleTransferOnHold(): TaskGenerator<void> {
    if (!this.conversation) {
      return;
    }

    let url = this.isOnHold
      ? '/ember/phone_call/take_transfer_off_hold'
      : '/ember/phone_call/place_transfer_on_hold';
    yield post(url, {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
      admin_id: this.addingCallParticipantInfo?.identifier,
      external_number: this.warmTransferUser?.phone,
    });
    this.isOnHold = !this.isOnHold;
  }

  @task
  *toggleRecording() {
    if (!this.conversation) {
      return;
    }

    if (this.isRecording) {
      yield this.intercomCallService.stopRecording(this.conversation.id);
      this.isRecording = false;
    } else {
      yield this.intercomCallService.startRecording(this.conversation.id);
      this.isRecording = true;
    }
  }

  @task({ drop: true })
  *transferToTeam(teamId: number): TaskGenerator<void> {
    if (!this.conversation || !this.incomingCall || !this.isActiveCall) {
      return;
    }

    yield post('/ember/phone_call/transfer_to_team', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
      team_id: teamId,
    });
  }

  @task({ drop: true })
  *transferToAdmin(admin: AdminSummary): TaskGenerator<void> {
    if (!this.conversation || !this.isActiveCall) {
      return;
    }
    try {
      this.currentCallAction = 'transferring-to-teammate';
      this.addingCallParticipantInfo = { identifier: admin.id, label: admin.name };
      this.isOnHold = true;
      this.disableHold = true;
      yield post('/ember/phone_call/transfer_to_admin', {
        app_id: this.session.workspace.id,
        conversation_id: this.conversation.id,
        admin_id: admin.id,
      });
      // TODO: See if there isn't a better way to handle this
      yield timeout(10000);
      this.disableHold = false;
    } catch (e) {
      this.currentCallAction = undefined;
      this.addingCallParticipantInfo = undefined;
      this.disableHold = false;
      this.isOnHold = false;

      if (e.jqXHR.status === 400) {
        this.notificationsService.notifyError(
          this.intl.t('calling.errors.calling-transfer-admin-unavailable'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('calling.errors.calling-transfer-failure'),
        );
      }
    }
  }

  @task({ drop: true })
  *warmTransferToAdmin(admin: AdminSummary): TaskGenerator<void> {
    if (!this.conversation || !this.isActiveCall) {
      return;
    }

    try {
      this.currentCallAction = 'warm-transferring-to-teammate';
      this.warmTransferState = 'ringing';
      this.addingCallParticipantInfo = { identifier: admin.id, label: admin.name };
      this.isOnHold = false;
      yield post('/ember/phone_call/start_warm_transfer_to_admin', {
        app_id: this.session.workspace.id,
        conversation_id: this.conversation.id,
        admin_id: admin.id,
      });
    } catch (e) {
      this.currentCallAction = undefined;
      this.warmTransferState = undefined;
      this.addingCallParticipantInfo = undefined;
      if (e.jqXHR.status === 400) {
        this.notificationsService.notifyError(
          this.intl.t('calling.errors.calling-transfer-admin-unavailable'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('calling.errors.calling-transfer-failure'),
        );
      }
    }
  }

  @task({ drop: true })
  *transferToExternalNumber(externalNumber: string): TaskGenerator<void> {
    this.addingCallParticipantInfo = { identifier: externalNumber, label: externalNumber };

    if (!this.conversation || !this.isActiveCall) {
      return;
    }

    try {
      this.currentCallAction = 'transferring-externally';
      this.isOnHold = true;
      this.disableHold = true;
      yield post('/ember/phone_call/transfer_to_external_number', {
        app_id: this.session.workspace.id,
        conversation_id: this.conversation.id,
        external_number: externalNumber,
      });
      if (!Ember.testing) {
        yield timeout(10000);
      }
      this.disableHold = false;
    } catch (e) {
      this.currentCallAction = undefined;
      this.disableHold = false;
      this.isOnHold = false;
      this.notificationsService.notifyError(this.intl.t('calling.errors.calling-transfer-failure'));
    }
  }

  @task({ drop: true })
  *warmTransferToExternalNumber(externalNumber: string) {
    if (!this.conversation || !this.isActiveCall) {
      return;
    }

    try {
      this.currentCallAction = 'warm-transferring-externally';
      this.warmTransferState = 'ringing';
      let { id } = yield this.intercomCallService.findOrCreateContact(externalNumber);
      this.warmTransferUser =
        (yield this.inboxSearchSuggestionsService.loadUserFromId(id)) || undefined;
      this.addingCallParticipantInfo = {
        identifier: id,
        label: this.warmTransferUser?.name || externalNumber,
      };
      this.isOnHold = false;
      yield post('/ember/phone_call/start_warm_transfer_to_external_number', {
        app_id: this.session.workspace.id,
        conversation_id: this.conversation.id,
        external_number: externalNumber,
      });
    } catch (e) {
      this.currentCallAction = undefined;
      this.warmTransferState = undefined;
      this.addingCallParticipantInfo = undefined;
      this.warmTransferUser = undefined;
      this.notificationsService.notifyError(this.intl.t('calling.errors.calling-transfer-failure'));
    }
  }

  @task({ drop: true })
  *addTeammateToCall(participant: AdminSummary) {
    if (!this.conversation || !this.isActiveCall) {
      return;
    }

    try {
      this.addingCallParticipantInfo = { identifier: participant.id, label: participant.name };
      this.currentCallAction = 'adding-participant';

      yield post('/ember/conference_calls/add_admin_participant', {
        app_id: this.session.workspace.id,
        conversation_id: this.conversation.id,
        admin_id: participant.id,
      });

      this.setupAddingParticipantTimer();
      this.recordEvent('add_teammate_to_call', this.conversation.id);
    } catch (e) {
      this.currentCallAction = undefined;
      this.addingCallParticipantInfo = undefined;

      if (e.jqXHR.status === 400) {
        this.notificationsService.notifyError(
          this.intl.t('calling.errors.calling-transfer-admin-unavailable'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('calling.errors.calling-add-to-conference-failure'),
        );
      }
    }
  }

  @task({ drop: true })
  *addExternalParticipantToCall(externalNumber: string): TaskGenerator<void> {
    if (!this.conversation || !this.isActiveCall) {
      return;
    }

    try {
      this.addingCallParticipantInfo = { identifier: externalNumber, label: externalNumber };
      this.currentCallAction = 'adding-participant';

      yield post('/ember/conference_calls/add_external_number_participant', {
        app_id: this.session.workspace.id,
        conversation_id: this.conversation.id,
        phone_number: externalNumber,
      });

      this.setupAddingParticipantTimer();
      this.recordEvent('add_external_participant_to_call', this.conversation.id);
    } catch (e) {
      this.currentCallAction = undefined;
      this.addingCallParticipantInfo = undefined;

      this.notificationsService.notifyError(
        this.intl.t('calling.errors.calling-add-to-conference-failure'),
      );
    }
  }

  async notifyInboundCallback(event: InboundCallbackRequestEvent) {
    this.callbackAudio.loop = true;
    this.callbackAudio.play();
    this.incomingCallType = 'callback';
    this.acceptedCallback = false;
    this.calledNumberCountryCode = event.eventData.countryCode;
    this.conversation = await this.inboxApi.fetchConversation(event.eventData.conversationId);
    this.userId = this.conversation.firstParticipant.id;
    this.shouldNotifyTeammate = true;
    this.callbackAcceptanceTimer = setTimeout(() => {
      if (!this.acceptedCallback) {
        this.ignoreCallback();
        this.conversation = undefined;
        this.userId = undefined;
      }
    }, NOTIFICATION_TIMEOUT);
  }

  async teammateHangUp() {
    if (!this.conversation || !this.incomingCall) {
      return;
    }

    await post('/ember/phone_call/teammate_hangup', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
      is_warm_transfer: this.isIncomingCallWarmTransfer,
      inviting_admin_id: this.oldAdminId,
    });
  }

  async cancelWarmTransfer() {
    if (!this.conversation) {
      return;
    }

    let url = this.isWarmTransferToExternalNumber
      ? '/ember/phone_call/cancel_warm_transfer_to_external_number'
      : '/ember/phone_call/cancel_warm_transfer_to_admin';
    await post(url, {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
      admin_id: this.addingCallParticipantInfo?.identifier,
      external_number: this.warmTransferUser?.phone,
    });
    this.warmTransferState = undefined;
    this.warmTransferUser = undefined;
    this.addingCallParticipantInfo = undefined;
    this.currentCallAction = undefined;
    this.isOnHold = true;
  }

  async completeWarmTransfer() {
    if (!this.conversation) {
      return;
    }
    let conversationId = this.conversation.id;

    let url = this.isWarmTransferToExternalNumber
      ? '/ember/phone_call/complete_warm_transfer_to_external_number'
      : '/ember/phone_call/complete_warm_transfer_to_admin';
    post(url, {
      app_id: this.session.workspace.id,
      conversation_id: conversationId,
      admin_id: this.addingCallParticipantInfo?.identifier,
      external_number: this.warmTransferUser?.phone,
    });
    this.notifyTransferSuccess(this.addingCallParticipantInfo?.label);
    this.warmTransferState = undefined;
    this.currentCallAction = undefined;
    this.addingCallParticipantInfo = undefined;
    this.warmTransferUser = undefined;
    this.isOnHold = false;
  }

  async teammateLeaveConference() {
    if (!this.conversation) {
      return;
    }

    await post('/ember/conference_calls/leave', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
    });
  }

  async teammateEndConferenceCall() {
    if (!this.conversation) {
      return;
    }

    await post('/ember/conference_calls/end_conference_call', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
    });
  }

  async removeAdminFromCall() {
    if (!this.conversation || !this.incomingCall) {
      return;
    }

    await post('/ember/phone_call/remove_admin_from_call', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
    });
  }

  tearDownCallbackModal() {
    this.shouldNotifyTeammate = false;
    this.callbackAudio.pause();
    this.callbackAudio.currentTime = 0;
  }

  async acceptCallback(conversationId?: number | undefined) {
    let data = await get('/ember/phone_call/callback', {
      app_id: this.session.workspace.id,
      conversation_id: conversationId || this.conversation?.id,
    });

    let json = (await data.conversation) as ConversationWireFormat;
    this.conversation = Conversation.deserialize(json);

    this.userId = this.conversation?.firstParticipant.id;

    if (!this.conversation) {
      return;
    }

    this.tearDownCallbackIgnoreTimer();
    let number = this.conversation.user?.phone as string;
    this.tearDownCallbackModal();

    this.intercomCallService.getWorkspacePhoneNumbers();
    let dialingNumber =
      this.intercomCallService.workspacePhoneNumbers.findBy(
        'phoneNumber',
        data.call.workspace_phone_number,
      )?.phoneNumber || this.intercomCallService.workspacePhoneNumbers.firstObject?.phoneNumber;

    await this.callNumber(number, this.conversation, this.userSummary!, dialingNumber);
    this.acceptedCallback = true;
  }

  async closeCallback(conversationId: number) {
    await post('/ember/phone_call/close_callback', {
      app_id: this.session.workspace.id,
      conversation_id: conversationId,
    });
  }

  async ignoreCallback() {
    this.tearDownCallbackIgnoreTimer();
    this.tearDownCallbackModal();
    await post('/ember/phone_call/ignore_callback', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation!.id,
    });
  }

  async checkAdminMicrophonePermissions() {
    try {
      // Mozilla does not support querying generically for microphone permissions. Instead Firefox has an expectation that
      // an end user should have more granular control over which device is accessed https://github.com/mozilla/standards-positions/issues/19
      // Therefor if this block of code throws we should just skip the check for now.
      let microphonePermission = 'microphone' as PermissionName;
      let permission = await navigator.permissions.query({ name: microphonePermission });
      this.adminLacksMicrophonePermissions = permission.state === 'denied';
      this.handlePermissionChangedEvent(permission);
    } catch (error) {
      this.logTwilioEvent('permissions-exception', { error });
    }
  }

  handlePermissionChangedEvent(permission: PermissionStatus) {
    permission.onchange = ({ target }: Event) => {
      let updatedPermission = target as PermissionStatus;
      this.adminLacksMicrophonePermissions = updatedPermission.state !== 'granted';

      if (this.noPermissionNotification) {
        this.snackbar.clearNotification(this.noPermissionNotification);
      }
    };
  }

  navigateToConversation() {
    if (!this.conversation) {
      return;
    }

    let inbox = this.inboxState.activeInbox || {
      id: InboxType.All,
      category: InboxCategory.Shared,
    };

    this.router.transitionTo(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      inbox.category,
      inbox.id,
      this.conversation.id,
    );
  }

  async recordEvent(action: string, conversationId: number, duration?: number | null) {
    let customer = this.customerService.customer;

    let payload: any = {
      action,
      object: 'conversation',
      place: 'inbox2',
      conversation_id: conversationId,
      is_trial: customer?.hasActiveTrials,
    };

    if (!isNone(duration)) {
      payload.duration = duration;
    }

    this.intercomEventService.trackAnalyticsEvent(payload);
  }

  tearDownCallbackIgnoreTimer() {
    if (this.callbackAcceptanceTimer !== null) {
      clearInterval(this.callbackAcceptanceTimer);
    }
  }

  setupAddingParticipantTimer() {
    this.addingParticipantTimer = setTimeout(() => {
      this.currentCallAction = undefined;
      this.addingCallParticipantInfo = undefined;
    }, NOTIFICATION_TIMEOUT);
  }

  tearDownAddingParticipantTimer() {
    if (!this.addingParticipantTimer) {
      return;
    }

    clearInterval(this.addingParticipantTimer);
    this.currentCallAction = undefined;
    this.addingCallParticipantInfo = undefined;
  }

  resetCallAttributes() {
    this.participants = [];
    this.conversation = undefined;
    this.userId = undefined;
    this.activeCall = undefined;
    this.addingCallParticipantInfo = undefined;
    this.currentCallAction = undefined;
    this.isOnHold = false;
    this.incomingCallType = undefined;
    this.warmTransferState = undefined;
    this.warmTransferUser = undefined;
  }

  getToken() {
    return get('/ember/twilio/token', {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
    });
  }

  logTwilioEvent(eventType: string, eventData: any, error?: TwilioError) {
    this.logService.log({
      timestamp: moment().format(),
      event: 'twilio_event',
      event_type: eventType,
      event_data: eventData,
      error,
      errorCode: error?.code,
    });
  }

  get isCallRinging() {
    if (this.isWarmTransfer) {
      return this.warmTransferState === 'ringing';
    }

    return ['connecting', 'ringing'].includes(this.callState);
  }

  get isWarmTransferCallActive() {
    return this.isWarmTransfer && this.warmTransferState !== 'background-hold';
  }

  get isCallEnded() {
    return this.activeCall?.callState === 'closed';
  }

  startSendingDevicePresence() {
    if (this.session.workspace.isFeatureEnabled('phone-twilio-device-health-check')) {
      this.sendDevicePresencePeriodically();
      this.deviceStatusPoller = setInterval(
        () => this.sendDevicePresencePeriodically(),
        ONE_MINUTE,
      );
    }
  }

  stopSendingDevicePresence() {
    if (this.session.workspace.isFeatureEnabled('phone-twilio-device-health-check')) {
      this.deviceStatusPoller && clearTimeout(this.deviceStatusPoller);
      this.destroyDevicePresence();
    }
  }

  async sendDevicePresencePeriodically() {
    try {
      if (this.device && this.device.state === 'registered') {
        await this.sendDevicePresence();
      } else {
        this.logTwilioEvent('device-not-registered', {
          device: this.device,
          state: this.device?.state,
        });
      }
    } catch (error) {
      this.logTwilioEvent('send-device-presence-error', { error });
    }
  }

  sendDevicePresence() {
    return post('/ember/twilio_device_presence', {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
    }).catch((err: any) => {
      this.logTwilioEvent('heartbeat-error', { err });
      throw err;
    });
  }

  destroyDevicePresence() {
    ajaxDelete('/ember/twilio_device_presence', {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
    }).catch((err: any) => {
      this.logTwilioEvent('heartbeat-error', { err });
      throw err;
    });
  }

  willDestroy() {
    this.tearDownCallbackIgnoreTimer();
    this.tearDownAddingParticipantTimer();

    this.deviceStatusPoller && clearInterval(this.deviceStatusPoller);
    this.windowFocusOnlineAbortController && this.windowFocusOnlineAbortController.abort();
  }

  async manualAnswer(callId: string) {
    // Disable the incoming call ringer
    this.device?.audio?.incoming(false);
    await this.intercomCallService.setAdminOnCall();
    this.isTabManuallyAnswering = true;

    try {
      await post('/ember/phone_call/manual_answer', {
        app_id: this.session.workspace.id,
        admin_id: this.session.teammate.id,
        call_id: callId,
      });
    } catch (response) {
      switch (response.jqXHR.status) {
        case 409:
        case 423:
        case 422:
          this.snackbar.notifyError(this.intl.t('calling.manual-answer.error.call-in-progress'), {
            timeout: MANUAL_ANSWER_NOTIFICATION_TIMEOUT,
          });
          break;
        default:
          this.snackbar.notifyError(this.intl.t('calling.manual-answer.error.catch-all'), {
            timeout: MANUAL_ANSWER_NOTIFICATION_TIMEOUT,
          });
          break;
      }
      this.adminAwayService.setAdminAsAvailable();
      this.device?.audio?.incoming(true);
      this.isTabManuallyAnswering = false;
    }
  }

  get isActiveCall() {
    return this.activeCall !== undefined;
  }

  get callState() {
    return this.activeCall?.callState || 'connecting';
  }
}

declare module '@ember/service' {
  interface Registry {
    twilioService: TwilioService;
    'twilio-service': TwilioService;
  }
}
