/* import __COLOCATED_TEMPLATE__ from './configuration-pane.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';
import { type PlatformId } from 'embercom/components/standalone/intercom-messenger/setup/messenger-settings/platform-selector';

interface Args {
  messengerSettings: $TSFixMe;
  operatorIdentity: $TSFixMe;
  platform: PlatformId;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

let ConfigurationPane = templateOnlyComponent<Signature>();
export default ConfigurationPane;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup::ConfigurationPane': typeof ConfigurationPane;
    'standalone/intercom-messenger/setup/configuration-pane': typeof ConfigurationPane;
  }
}
