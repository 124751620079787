/* import __COLOCATED_TEMPLATE__ from './rails.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  selectedProgrammingLanguage: string;
  apiSecret: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class Rails extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  @action
  onCopyCode() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'copy_code_snippet',
      metadata: {
        is_idv: true,
        app_type: 'rails',
        programming_language: this.args.selectedProgrammingLanguage,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::GenerateHmac::Rails': typeof Rails;
    'installation-new/generate-hmac/rails': typeof Rails;
  }
}
