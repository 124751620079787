/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { get } from 'embercom/lib/ajax';
import containerLookup, {
  getIntlService,
  getSelfServeTrialsService,
  getCardlessTrialService,
  getCustomerService,
  getIntercomConfirmService,
} from 'embercom/lib/container-lookup';
import currencyFormatter from 'embercom/lib/currency-formatter';
import {
  availableUiSeatTypes,
  VBP2_SEAT_LABELS,
  COLLABORATOR_SEAT_TYPE as COLLABORATOR,
  COPILOT_SEAT_TYPE as COPILOT,
  PRICING_5_X_LITE_SEAT_TYPE as LITE,
  PRICING_5_X_FULL_SEAT_TYPE as FULL,
} from 'embercom/lib/settings/seats/constants';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { isEqual } from 'underscore';

function createSeatUsedLabel(
  workspaceSeatUsageAndPrice,
  currentSeatUsage,
  updatedSeatUsage = { used: 0, pending: 0 },
  app,
  seatType,
  isInvite,
) {
  let used = _usedSeatsByType(workspaceSeatUsageAndPrice, currentSeatUsage, updatedSeatUsage);
  let pending = _pendingSeatsByType(workspaceSeatUsageAndPrice, currentSeatUsage, updatedSeatUsage);
  let contracted = workspaceSeatUsageAndPrice.contractedLimit;

  let cardlessTrialService = getCardlessTrialService();
  let intlService = getIntlService();
  let customerService = getCustomerService();
  let label = pending > 0 ? 'used-seat-label-with-pending' : 'used-seat-label';
  if (app.onPricing5) {
    if (seatType === LITE) {
      return intlService.t('components.settings.teammates.seats-editor.usage-lite-seat', {
        remaining: Math.max(
          workspaceSeatUsageAndPrice.contractedLimit - workspaceSeatUsageAndPrice.used,
          0,
        ),
        htmlSafe: true,
      });
    }
    if (customerService.isP5SelfServeAnnualCustomer) {
      return _selfServeAnnualScenarioForCoreSeat(used, pending, contracted, intlService, isInvite);
    } else if (
      app.isSalesforceContracted ||
      customerService.isSelfServeAnnualCustomer ||
      customerService.customer?.currentlyOnEarlyStage
    ) {
      return _salesLedScenarioForCoreSeat(
        used,
        pending,
        contracted,
        updatedSeatUsage,
        workspaceSeatUsageAndPrice,
        intlService,
        customerService,
      );
    }

    let { price } = workspaceSeatUsageAndPrice;

    let seatPrice = currencyFormatter(price, _calculatePrecision(price));

    if (cardlessTrialService.isInSelfServeTrial) {
      let translationKey = 'components.settings.teammates.seats-editor.usage-label-trial';
      return intlService.t(
        `${translationKey}${app.canUseFinAiCopilotAddon ? '' : '-pre-copilot'}`,
        {
          htmlSafe: true,
          seatPrice,
        },
      );
    }

    let translationKey = 'components.settings.teammates.seats-editor.usage-label-self-serve';
    return intlService.t(`${translationKey}${app.canUseFinAiCopilotAddon ? '' : '-pre-copilot'}`, {
      htmlSafe: true,
      seatPrice,
    });
  }

  return intlService.t(`components.settings.teammates.seats-editor.${label}`, {
    contracted,
    used,
    pending,
  });
}

// Allows us to check if, when all of the teammates are given a seat, the customer will go into overages.
async function seatOverageInfo(
  workspaceSeatUsageAndPrice,
  currentSeatUsage,
  updatedSeatUsage = { used: 0, pending: 0 },
  numberOfTeammates,
  seatType,
  teammateAlreadyAssignedSeatType,
) {
  if (seatType === COLLABORATOR) {
    if (teammateAlreadyAssignedSeatType) {
      return {
        canAddSeatWithoutGoingIntoOverages: true,
        hasSeatsAvailable: true,
      };
    }
    let customerService = containerLookup('service:customerService');
    await customerService.ensureDataIsLoaded.perform({ fetchPrices: false });
    let collaboratorSeatCount = await get(
      '/ember/settings/seats/available_collaborator_seat_count',
      {
        app_id: customerService.app.id,
      },
    );
    let canAddSeatWithoutGoingIntoOverages =
      numberOfTeammates <= collaboratorSeatCount.available_collaborator_seat_count;
    let hasSeatsAvailable = collaboratorSeatCount.available_collaborator_seat_count > 0;

    return {
      canAddSeatWithoutGoingIntoOverages,
      hasSeatsAvailable,
    };
  } else {
    let used = _usedSeatsByType(workspaceSeatUsageAndPrice, currentSeatUsage, updatedSeatUsage);
    let pending = _pendingSeatsByType(
      workspaceSeatUsageAndPrice,
      currentSeatUsage,
      updatedSeatUsage,
    );
    let contracted = workspaceSeatUsageAndPrice.contractedLimit;

    let canAddSeatWithoutGoingIntoOverages = used + pending + numberOfTeammates <= contracted;
    let hasSeatsAvailable = used + pending <= contracted;

    return {
      canAddSeatWithoutGoingIntoOverages,
      hasSeatsAvailable,
    };
  }
}

async function getWorkspaceSeatUsageAndPrices() {
  let customerService = containerLookup('service:customerService');
  await customerService.ensureDataIsLoaded.perform({ fetchPrices: false });
  let customer = customerService.customer;
  let seatsUsed = await get('/ember/settings/seats/used_and_pending_seat_count', {
    app_id: customerService.app.id,
  });
  return _combineSeatDataBySeatType(
    customer.includedSeatCountPerType,
    seatsUsed,
    customer.pricePerSeatTypes,
    !!customerService.app.onPricing5,
  );
}

function calculateSeatOveragesCountForMultipleSeatTypes(
  workspaceSeatUsage = {},
  currentSeatUsage = { used: 0, pending: 0 },
  updatedSeatUsage = { used: 0, pending: 0 },
) {
  if (
    _.isEmpty(workspaceSeatUsage) ||
    (updatedSeatUsage.used === 0 && updatedSeatUsage.pending === 0)
  ) {
    return 0;
  }

  let contractedSeatLimit = workspaceSeatUsage.contractedLimit;
  if (contractedSeatLimit === null) {
    return 0;
  }

  let totalSeatCountRequired = _totalSeatCountRequired(
    workspaceSeatUsage,
    currentSeatUsage,
    updatedSeatUsage,
  );

  let contractOverage = totalSeatCountRequired - contractedSeatLimit;
  if (contractOverage > 0) {
    let additionalUsedSeats = updatedSeatUsage.used - currentSeatUsage.used;
    let additionalPendingSeats = updatedSeatUsage.pending - currentSeatUsage.pending;
    let updatedSeatTotal = additionalUsedSeats + additionalPendingSeats;
    return Math.max(Math.min(updatedSeatTotal, contractOverage), 0);
  }

  return 0;
}

async function isAnySeatOverage(seatTypes, initialUsage, updatedUsage) {
  let workspaceSeatUsagesAndPrices = await getWorkspaceSeatUsageAndPrices();
  for (let [seatType, workspaceUsage] of Object.entries(workspaceSeatUsagesAndPrices)) {
    if (seatTypes.includes(seatType)) {
      let seatOverage = calculateSeatOveragesCountForMultipleSeatTypes(
        workspaceUsage,
        initialUsage[seatType],
        updatedUsage[seatType],
      );
      if (seatOverage > 0) {
        return true;
      }
    }
  }
  return false;
}

async function confirmMultipleSeatOveragesIncrease(
  app,
  {
    seatTypes,
    initialUsage,
    updatedUsage,
    isInvite,
    teammateCount = 1,
    haveSeatsChanged,
    copilotSeatChanged = false,
    isOnlyChangeCopilot = false,
  },
  showConfirmModal = () => {},
) {
  let workspaceSeatUsagesAndPrices = await getWorkspaceSeatUsageAndPrices();
  let isBlockingSeatOverage = false;
  let isCopilotOverage = false;
  let totalPriceChange = 0;
  let pricePerSeatLabels = [];
  let changedSeatCount = 0;
  let intlService = getIntlService();
  let selfServeTrialsService = getSelfServeTrialsService();
  let cardlessTrialService = getCardlessTrialService();
  let customerService = getCustomerService();
  let hasFullSeat = seatTypes.includes(FULL) && haveSeatsChanged;
  let isOnlyChangeLiteSeat = haveSeatsChanged && isEqual(seatTypes, [LITE]);

  let onSelfServeMonthlyPricing5FullSeat =
    app.onPricing5 &&
    !app.isSalesforceContracted &&
    !customerService.isSelfServeAnnualCustomer &&
    !customerService.customer?.currentlyOnEarlyStage &&
    hasFullSeat;

  if (
    selfServeTrialsService.isTriallingCorePlan ||
    cardlessTrialService.hasActiveTrialSubscriptionOrIsInSelfServeTrial
  ) {
    return true;
  }

  for (let [seatType, workspaceUsage] of Object.entries(workspaceSeatUsagesAndPrices)) {
    if (seatTypes.includes(seatType)) {
      let seatOverage = calculateSeatOveragesCountForMultipleSeatTypes(
        workspaceUsage,
        initialUsage[seatType],
        updatedUsage[seatType],
      );

      if (seatType === COPILOT && seatOverage > 0) {
        isCopilotOverage = true;
      }

      if (seatOverage > 0) {
        isBlockingSeatOverage = true;
        changedSeatCount += seatOverage;

        let { price } = workspaceSeatUsagesAndPrices[seatType];

        totalPriceChange += price * seatOverage;
        let pricePerSeat = currencyFormatter(price, _calculatePrecision(price));

        pricePerSeatLabels.push(
          intlService.t(
            'settings.teammates.permissions.confirm-multiple-seat-overages.price-per-seat-label',
            { pricePerSeat, seatType: VBP2_SEAT_LABELS[seatType] },
          ),
        );
      }
    }
  }

  if ((!isBlockingSeatOverage && !onSelfServeMonthlyPricing5FullSeat) || isOnlyChangeLiteSeat) {
    return true;
  }

  let totalPriceChangeFormatted = currencyFormatter(
    totalPriceChange,
    _calculatePrecision(totalPriceChange),
  );
  let pricePerSeatLabelsFormatted = pricePerSeatLabels.join(', ');

  let body;

  // at this point we know that we either have overage
  // or we are self serve with a full seat assigned

  if (app.onPricing5) {
    let fullSeatPrice = workspaceSeatUsagesAndPrices[FULL].price;
    let fullSeatFormattedPrice = currencyFormatter(
      fullSeatPrice,
      _calculatePrecision(fullSeatPrice),
    );

    if (
      app.isSalesforceContracted ||
      customerService.isSelfServeAnnualCustomer ||
      customerService.customer?.currentlyOnEarlyStage
    ) {
      if (app.canUseFinAiCopilotAddon && isCopilotOverage) {
        body = _contractedCopilotOverageInviteBody(workspaceSeatUsagesAndPrices, teammateCount);
      } else {
        body = intlService.t(
          isInvite
            ? 'settings.teammates.permissions.confirm-pricing5-seat-overages.sales-led-invite.message-body'
            : 'settings.teammates.permissions.confirm-pricing5-seat-overages.sales-led.message-body',
          { teammateCount, fullSeatFormattedPrice, htmlSafe: true },
        );
      }
    } else if (hasFullSeat) {
      if (app.canUseFinAiCopilotAddon) {
        let seatWithCopilotFormattedPrice = _fullSeatWithCopilotPrice(
          workspaceSeatUsagesAndPrices,
          fullSeatPrice,
        );
        if (copilotSeatChanged) {
          if (isInvite) {
            body = _copilotSeatOverageModalBody(seatWithCopilotFormattedPrice);
          } else {
            body = intlService.t(
              'settings.teammates.permissions.confirm-pricing5-seat-overages.full-seat-with-copilot.message-body',
              { seatWithCopilotFormattedPrice, htmlSafe: true },
            );
          }
        } else {
          body = _fullSeatOverageConfirmBody(isInvite, teammateCount, fullSeatFormattedPrice);
        }
      } else {
        body = _fullSeatOverageConfirmBody(isInvite, teammateCount, fullSeatFormattedPrice);
      }
    } else if (isOnlyChangeCopilot) {
      return await confirmAddCopilotSeat(
        workspaceSeatUsagesAndPrices[COPILOT].price,
        showConfirmModal,
      );
    } else {
      body = intlService.t(
        'settings.teammates.permissions.confirm-multiple-seat-overages.invite-edit-message-body',
        { teammateCount, totalPriceChangeFormatted, pricePerSeatLabelsFormatted },
      );
    }
  } else if (isInvite) {
    body = intlService.t(
      'settings.teammates.permissions.confirm-multiple-seat-overages.invite-edit-message-body',
      { teammateCount, totalPriceChangeFormatted, pricePerSeatLabelsFormatted },
    );
  } else {
    body = intlService.t(
      'settings.teammates.permissions.confirm-multiple-seat-overages.teammate-edit-message-body',
      { changedSeatCount, teammateCount, totalPriceChangeFormatted, pricePerSeatLabelsFormatted },
    );
  }

  return await showConfirmModal({
    title: app.onPricing5
      ? intlService.t('settings.teammates.permissions.confirm-pricing5-seat-overages.title', {
          teammateCount,
        })
      : intlService.t('settings.teammates.permissions.confirm-multiple-seat-overages.title'),
    confirmButtonText: intlService.t(
      'settings.teammates.permissions.confirm-multiple-seat-overages.confirm',
    ),
    body,
  });
}

async function confirmAddCopilotSeat(copilotSeatPrice, showConfirmModal = () => {}) {
  let intlService = getIntlService();
  return await showConfirmModal({
    title: intlService.t('settings.teammates.edit.add-copilot-seat-modal.title'),
    confirmButtonText: intlService.t(
      'settings.teammates.edit.add-copilot-seat-modal.confirm-button-text',
    ),
    body: sanitizeHtml(
      intlService.t('settings.teammates.edit.add-copilot-seat-modal.body', {
        seatPrice: currencyFormatter(copilotSeatPrice),
      }),
    ),
  });
}

function _fullSeatOverageConfirmBody(isInvite, teammateCount, fullSeatFormattedPrice) {
  let intlService = getIntlService();
  return isInvite
    ? intlService.t(
        'settings.teammates.permissions.confirm-pricing5-seat-overages.self-serve.message-body',
        { teammateCount, fullSeatFormattedPrice, htmlSafe: true },
      )
    : intlService.t(
        'settings.teammates.permissions.confirm-pricing5-seat-overages.full-seat.message-body',
        { fullSeatFormattedPrice, htmlSafe: true },
      );
}

function _formattedPrice(price) {
  return currencyFormatter(price, _calculatePrecision(price));
}

function _contractedCopilotOverageInviteBody(workspaceSeatUsagesAndPrices, teammateCount) {
  let intlService = getIntlService();
  let copilotSeatUsagesAndPrices = workspaceSeatUsagesAndPrices[COPILOT];
  let fullSeatUsagesAndPrices = workspaceSeatUsagesAndPrices[FULL];
  let translationKey =
    'settings.teammates.permissions.confirm-pricing5-seat-overages.fin-copilot-overages.';
  let {
    used: copilotUsed,
    pending: copilotPending,
    contractedLimit: copilotContract,
    price: copilotPrice,
  } = copilotSeatUsagesAndPrices;
  let {
    used: fullSeatUsed,
    pending: fullSeatPending,
    contractedLimit: fullSeatContract,
    price: fullSeatPrice,
  } = fullSeatUsagesAndPrices;

  let availableCopilotSeats = copilotContract - copilotUsed;

  if (availableCopilotSeats <= 0) {
    translationKey += 'all-copilot-seats-in-use';
  } else if (
    teammateCount + copilotUsed + copilotPending > copilotContract ||
    (copilotPending === 0 && teammateCount + copilotUsed > copilotContract)
  ) {
    translationKey += 'copilot-remaining';
  }

  if (fullSeatUsed + fullSeatPending > fullSeatContract) {
    translationKey += '-exceeds-core-seats';
  }

  return intlService.t(translationKey, {
    htmlSafe: true,
    formattedCopilotSeatPrice: _formattedPrice(copilotPrice),
    formattedFullSeatPrice: _formattedPrice(fullSeatPrice),
  });
}

function _fullSeatWithCopilotPrice(workspaceSeatUsagesAndPrices, fullSeatPrice) {
  let copilotSeatPrice = workspaceSeatUsagesAndPrices[COPILOT].price;
  let seatWithCopilotPrice = fullSeatPrice + copilotSeatPrice;
  return currencyFormatter(seatWithCopilotPrice, _calculatePrecision(seatWithCopilotPrice));
}

function _copilotSeatOverageModalBody(seatWithCopilotFormattedPrice) {
  let intlService = getIntlService();
  return intlService.t(
    'settings.teammates.permissions.confirm-pricing5-seat-overages.fin-copilot.message-body',
    { seatWithCopilotFormattedPrice, htmlSafe: true },
  );
}

function confirmOptionsForSelfServeAnnualSeatIncrease({
  overageCoreSeatsCount,
  fullSeatPriceForBillingPeriod,
}) {
  let title;
  let body;
  let confirmButtonText;
  let intlService = getIntlService();
  let monthlyPriceInCents = fullSeatPriceForBillingPeriod.monthly * 100;
  let annualPriceInCents = fullSeatPriceForBillingPeriod.annual * 100;

  let originalPrice = monthlyPriceInCents * overageCoreSeatsCount;
  let discountedPrice = annualPriceInCents * overageCoreSeatsCount;
  let originalPriceFormatted = currencyFormatter(originalPrice, _calculatePrecision(originalPrice));
  let discountedPriceFormatted = currencyFormatter(
    discountedPrice,
    _calculatePrecision(discountedPrice),
  );
  title = intlService.t(
    'settings.teammates.permissions.confirm-pricing5-seat-overages.purchase-annual-seats.title',
  );
  confirmButtonText = intlService.t(
    'settings.teammates.permissions.confirm-pricing5-seat-overages.purchase-annual-seats.purchase-seat',
  );
  body = intlService.t(
    'settings.teammates.permissions.confirm-pricing5-seat-overages.purchase-annual-seats.message-body',
    {
      seatsCount: overageCoreSeatsCount,
      originalPrice: originalPriceFormatted,
      discountedPrice: discountedPriceFormatted,
      htmlSafe: true,
    },
  );

  return {
    title,
    confirmButtonText,
    body,
  };
}

// Helper function to combine all the seat usage data into one object
// contractedLimits = { support: 5, engage: 5, ... }
// seatUsages = { support: { used: 1, pending: 3 }, engage: { used: 0, pending: 5 }, ... }
// seatPrices = { support: 1900, engage: 4900, ... }
// returns { support: { used: 1, pending: 3, contractedLimit: 5, price: 1900 }, ... }
function _combineSeatDataBySeatType(
  contractedLimits = {},
  seatUsages = {},
  seatPrices = {},
  onPricing5 = {},
) {
  let combinedSeatUsageInformation = {};
  let seatTypes = availableUiSeatTypes(onPricing5);
  seatTypes.forEach((seatType) => {
    combinedSeatUsageInformation[seatType] = {
      contractedLimit: contractedLimits[seatType],
      used: seatUsages[seatType]?.used || 0,
      pending: seatUsages[seatType]?.pending || 0,
      price: seatPrices[seatType] || 0,
    };
  });

  return combinedSeatUsageInformation;
}

function _usedSeatsByType(workspaceSeatUsage, currentSeatUsage, updatedSeatUsage) {
  return workspaceSeatUsage.used - currentSeatUsage.used + updatedSeatUsage.used;
}

function _pendingSeatsByType(workspaceSeatUsage, currentSeatUsage, updatedSeatUsage) {
  return workspaceSeatUsage.pending - currentSeatUsage.pending + updatedSeatUsage.pending;
}

function _totalSeatCountRequired(workspaceSeatUsage, currentSeatUsage, updatedSeatUsage) {
  let totalUsedSeats = _usedSeatsByType(workspaceSeatUsage, currentSeatUsage, updatedSeatUsage);
  let totalPendingSeats = _pendingSeatsByType(
    workspaceSeatUsage,
    currentSeatUsage,
    updatedSeatUsage,
  );
  return totalUsedSeats + totalPendingSeats;
}

function _calculatePrecision(amountInCent) {
  return amountInCent % 100 === 0 ? 0 : 2;
}

/**
 *
 * @param {number} used the number of user seats
 * @param {number} pending the total number of pending invites. This includes existing pending invites in the workspace, as well as the new invites
 * @param {number} contracted number of contract seats in the workspace
 * @param {boolean} isInvite
 * @returns
 */
function _selfServeAnnualScenarioForCoreSeat(used, pending, contracted, intlService, isInvite) {
  let remaining = contracted - used;
  let remainingToDisplay = Math.max(remaining, 0);

  if (!isInvite) {
    return intlService.t(
      'components.settings.teammates.seats-editor.self-serve-annual-scenario-2',
      {
        remaining: remainingToDisplay,
        htmlSafe: true,
      },
    );
  }

  return intlService.t(
    pending > remaining
      ? 'components.settings.teammates.seats-editor.self-serve-annual-scenario-1'
      : 'components.settings.teammates.seats-editor.self-serve-annual-scenario-2',
    {
      remaining: remainingToDisplay,
      htmlSafe: true,
    },
  );
}

/**
 * @param {number} used the number of user seats
 * @param {number} pending the total number of pending invites. This includes existing pending invites in the workspace, as well as the new invites
 * @param {number} contracted number of contract seats in the workspace
 * @param {number} updatedSeatUsage ?
 * @param {object} workspaceSeatUsageAndPrice number of contracted/used/pending seats in the workspace, and their prices
 * @param {*} intlService
 * @param {*} customerService
 * @returns
 */
function _salesLedScenarioForCoreSeat(
  used,
  pending,
  contracted,
  updatedSeatUsage,
  workspaceSeatUsageAndPrice,
  intlService,
  customerService,
) {
  let remaining = contracted - used;
  //this only counting the new teammates that will be invited, doesn't include existing pending invites
  let countNewSeatsNeeded = updatedSeatUsage.pending + updatedSeatUsage.used;
  let remainingToDisplay = Math.max(
    workspaceSeatUsageAndPrice.contractedLimit - workspaceSeatUsageAndPrice.used,
    0,
  );

  if (used < contracted && countNewSeatsNeeded + pending <= remaining) {
    return intlService.t('components.settings.teammates.seats-editor.sales-led-scenario-1', {
      remaining: remainingToDisplay,
      htmlSafe: true,
    });
  }

  if (
    used < contracted &&
    countNewSeatsNeeded < remaining &&
    pending > remaining - countNewSeatsNeeded
  ) {
    return intlService.t('components.settings.teammates.seats-editor.sales-led-scenario-2', {
      remaining: remainingToDisplay,
      pending: workspaceSeatUsageAndPrice.pending,
      htmlSafe: true,
    });
  }

  let { price } = workspaceSeatUsageAndPrice;
  let seatPrice = currencyFormatter(price, _calculatePrecision(price));

  return intlService.t(
    used >= contracted
      ? 'components.settings.teammates.seats-editor.sales-led-scenario-3'
      : 'components.settings.teammates.seats-editor.sales-led-scenario-4',
    {
      remaining: remainingToDisplay,
      htmlSafe: true,
      seatPrice,
    },
  );
}

async function confirmSeatOveragesForSelfServeAnnualInvite(
  initialUsage,
  updatedUsage,
  fullSeatPriceForBillingPeriod,
  router,
  appId,
) {
  let workspaceSeatUsageAndPrices = await getWorkspaceSeatUsageAndPrices();
  // This does NOT include pending invites
  let overageSeatsCount = calculateSeatOveragesCountForMultipleSeatTypes(
    workspaceSeatUsageAndPrices[FULL],
    initialUsage[FULL],
    updatedUsage[FULL],
  );
  // only customers on pricing5 self-serve annual plan can set to buy yearly seats, so no need to check again here.
  let confirmOptions = confirmOptionsForSelfServeAnnualSeatIncrease({
    overageCoreSeatsCount: overageSeatsCount,
    fullSeatPriceForBillingPeriod,
  });
  let onConfirm = async () => {
    router.transitionTo('apps.app.settings.subscription.billing.manage-subscription', appId, {
      queryParams: { section: 'seats', additionalCoreSeatsNumber: overageSeatsCount },
    });
  };
  let intercomConfirmService = getIntercomConfirmService();
  await intercomConfirmService.openConfirm(onConfirm, () => {}, confirmOptions);
}

export {
  confirmMultipleSeatOveragesIncrease,
  getWorkspaceSeatUsageAndPrices,
  calculateSeatOveragesCountForMultipleSeatTypes,
  createSeatUsedLabel,
  isAnySeatOverage,
  seatOverageInfo,
  confirmOptionsForSelfServeAnnualSeatIncrease,
  confirmSeatOveragesForSelfServeAnnualInvite,
};
