/* import __COLOCATED_TEMPLATE__ from './objective-c.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {}
interface Signature {
  Args: Args;
  Element: any;
}

const ObjectiveC = templateOnlyComponent<Signature>();
export default ObjectiveC;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::IdvSetup::Ios::CodeSnippets::ObjectiveC': typeof ObjectiveC;
    'installation-new/mobile/idv-setup/ios/code-snippets/objective-c': typeof ObjectiveC;
  }
}
