/* import __COLOCATED_TEMPLATE__ from './tags-list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';

import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { tracked } from '@glimmer/tracking';
import type Tag from 'embercom/models/tag';
import {
  humanReadableObjectNames,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import { EntityType } from 'embercom/models/data/entity-types';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export interface Args {
  content: LocalizedKnowledgeContent & Model;
}
export default class TagsList extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare intl: any;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;

  @tracked tagModalOpen = false;

  get tags() {
    let tags = this.args.content.taggable?.tags.map((tag: Tag) => {
      return this.store.peekRecord('tag', tag.id);
    });
    return tags;
  }

  get type() {
    return objectNames[this.args.content.entityType];
  }

  get humanReadableName() {
    return humanReadableObjectNames[this.args.content.entityType];
  }

  get modalTitle() {
    let contentName = this.humanReadableName.toLowerCase();
    if (this.args.content.entityType === EntityType.ExternalContent) {
      contentName = this.intl.t('knowledge-hub.content-editor.shared.tags.modal.webpage');
    }
    return this.intl.t('knowledge-hub.content-editor.shared.tags.modal.title', {
      contentType: contentName,
    });
  }

  @action async removeTag(tagId: string) {
    let tag = this.store.peekRecord('tag', tagId);
    let currentAdmin = this.appService.app.currentAdmin;
    let taggingToRemove = this.args.content.taggable?.taggings.find(
      (tagging) => tagging.tag === tag,
    );
    // Remove the taggings explicitly to avoid having to reload the content
    this.args.content.taggable?.tags.removeObject(tag);
    this.args.content.taggable?.taggings.removeObject(taggingToRemove);

    await this.args.content.taggable?.updateTaggings(currentAdmin, [], [tag]);

    this.knowledgeHubEditorService.onContentUpdateCallback?.({
      type: 'edit',
      content: this.knowledgeHubEditorService.activeContentModel,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Tags::TagsList': typeof TagsList;
    'knowledge-hub/content-editor/shared/tags/tags-list': typeof TagsList;
  }
}
