/* RESPONSIBLE TEAM: team-app-security */
export const ADMIN_EVENT_TYPES = [
  {
    // this isn't a real admin event type
    value: 'AppAdminEvents::All',
    text: 'admins.app-admin-events-constants.all',
  },
  {
    value: 'AppAdminEvents::AdminDeletion',
    text: 'admins.app-admin-events-constants.admin-deletion',
  },
  {
    value: 'AppAdminEvents::AdminDeprovisioned',
    text: 'admins.app-admin-events-constants.admin-deprovisioned',
  },
  {
    value: 'AppAdminEvents::AdminImpersonationEnd',
    text: 'admins.app-admin-events-constants.admin-impersonation-end',
  },
  {
    value: 'AppAdminEvents::AdminImpersonationStart',
    text: 'admins.app-admin-events-constants.admin-impersonation-start',
  },
  {
    value: 'AppAdminEvents::AdminInviteChange',
    text: 'admins.app-admin-events-constants.admin-invite-change',
  },
  {
    value: 'AppAdminEvents::AdminInviteCreation',
    text: 'admins.app-admin-events-constants.admin-invite-creation',
  },
  {
    value: 'AppAdminEvents::AdminInviteDeletion',
    text: 'admins.app-admin-events-constants.admin-invite-deletion',
  },
  {
    value: 'AppAdminEvents::AdminLoginFailure',
    text: 'admins.app-admin-events-constants.admin-login-failure',
  },
  {
    value: 'AppAdminEvents::AdminLoginSuccess',
    text: 'admins.app-admin-events-constants.admin-login-success',
  },
  {
    value: 'AppAdminEvents::AdminLogout',
    text: 'admins.app-admin-events-constants.admin-logout',
  },
  {
    value: 'AppAdminEvents::AdminPermissionChange',
    text: 'admins.app-admin-events-constants.admin-permission-change',
  },
  {
    value: 'AppAdminEvents::RoleCreation',
    text: 'admins.app-admin-events-constants.role-creation',
  },
  {
    value: 'AppAdminEvents::RoleChange',
    text: 'admins.app-admin-events-constants.role-change',
  },
  {
    value: 'AppAdminEvents::RoleDeletion',
    text: 'admins.app-admin-events-constants.role-deletion',
  },
  {
    value: 'AppAdminEvents::SeatChange',
    text: 'admins.app-admin-events-constants.seat-change',
  },
  {
    value: 'AppAdminEvents::InboxAccessChange',
    text: 'admins.app-admin-events-constants.inbox-access-change',
  },
  {
    value: 'AppAdminEvents::AppNameChange',
    text: 'admins.app-admin-events-constants.app-name-change',
  },
  {
    value: 'AppAdminEvents::AdminAwayModeChange',
    text: 'admins.app-admin-events-constants.admin-away-mode-change',
  },
  {
    value: 'AppAdminEvents::AppTimezoneChange',
    text: 'admins.app-admin-events-constants.app-timezone-change',
  },
  {
    value: 'AppAdminEvents::BulkExport',
    text: 'admins.app-admin-events-constants.bulk-export',
  },
  {
    value: 'AppAdminEvents::MessageDeletion',
    text: 'admins.app-admin-events-constants.message-deletion',
  },
  {
    value: 'AppAdminEvents::RulesetActivation',
    text: 'admins.app-admin-events-constants.ruleset-activation',
  },
  {
    value: 'AppAdminEvents::RulesetCreation',
    text: 'admins.app-admin-events-constants.ruleset-creation',
  },
  {
    value: 'AppAdminEvents::RulesetDeletion',
    text: 'admins.app-admin-events-constants.ruleset-deletion',
  },
  {
    value: 'AppAdminEvents::MessageStateChange',
    text: 'admins.app-admin-events-constants.message-state-change',
  },
  {
    value: 'AppAdminEvents::CampaignDeletion',
    text: 'admins.app-admin-events-constants.campaign-deletion',
  },
  {
    value: 'AppAdminEvents::AppAdminJoin',
    text: 'admins.app-admin-events-constants.app-admin-join',
  },
  {
    value: 'AppAdminEvents::AdminProvisioned',
    text: 'admins.app-admin-events-constants.admin-provisioned',
  },
  {
    value: 'AppAdminEvents::AppAuthenticationMethodChange',
    text: 'admins.app-admin-events-constants.app-authentication-method-change',
  },
  {
    value: 'AppAdminEvents::AppDataDeletion',
    text: 'admins.app-admin-events-constants.app-data-deletion',
  },
  {
    value: 'AppAdminEvents::AppIdentityVerificationChange',
    text: 'admins.app-admin-events-constants.app-identity-verification-change',
  },
  {
    value: 'AppAdminEvents::AppOutboundAddressChange',
    text: 'admins.app-admin-events-constants.app-outbound-address-change',
  },
  {
    value: 'AppAdminEvents::AppPackageTokenRegeneration',
    text: 'admins.app-admin-events-constants.app-package-token-regeneration',
  },
  {
    value: 'AppAdminEvents::AppWebhookCreation',
    text: 'admins.app-admin-events-constants.app-webhook-creation',
  },
  {
    value: 'AppAdminEvents::AppWebhookDeletion',
    text: 'admins.app-admin-events-constants.app-webhook-deletion',
  },
  {
    value: 'AppAdminEvents::AppGoogleSsoDomainChange',
    text: 'admins.app-admin-events-constants.app-google-sso-domain-change',
  },
  {
    value: 'AppAdminEvents::CampaignStateChange',
    text: 'admins.app-admin-events-constants.campaign-state-change',
  },
  {
    value: 'AppAdminEvents::AppTeamMembershipModification',
    text: 'admins.app-admin-events-constants.app-team-membership-modification',
  },
  {
    value: 'AppAdminEvents::AppTeamCreation',
    text: 'admins.app-admin-events-constants.app-team-creation',
  },
  {
    value: 'AppAdminEvents::AppTeamDeletion',
    text: 'admins.app-admin-events-constants.app-team-deletion',
  },
  {
    value: 'AppAdminEvents::AppPackageInstallation',
    text: 'admins.app-admin-events-constants.app-package-installation',
  },
  {
    value: 'AppAdminEvents::AppPackageUninstallation',
    text: 'admins.app-admin-events-constants.app-package-uninstallation',
  },
  {
    value: 'AppAdminEvents::AppDataExport',
    text: 'admins.app-admin-events-constants.app-data-export',
  },
  {
    value: 'AppAdminEvents::HelpCenterSettingsChange',
    text: 'admins.app-admin-events-constants.help-center-settings-change',
  },
  {
    value: 'AppAdminEvents::OfficeHoursChange',
    text: 'admins.app-admin-events-constants.office-hours-change',
  },
  {
    value: 'AppAdminEvents::ConversationTopicCreation',
    text: 'admins.app-admin-events-constants.conversation-topic-creation',
  },
  {
    value: 'AppAdminEvents::ConversationTopicChange',
    text: 'admins.app-admin-events-constants.conversation-topic-change',
  },
  {
    value: 'AppAdminEvents::ConversationTopicDeletion',
    text: 'admins.app-admin-events-constants.conversation-topic-deletion',
  },
  {
    value: 'AppAdminEvents::ConversationPartDeletion',
    text: 'admins.app-admin-events-constants.conversation-part-deletion',
  },
  {
    value: 'AppAdminEvents::InboundConversationsChange',
    text: 'admins.app-admin-events-constants.inbound-conversations-change',
  },
  {
    value: 'AppAdminEvents::WelcomeMessageChange',
    text: 'admins.app-admin-events-constants.welcome-message-change',
  },
  {
    value: 'AppAdminEvents::PrivacyPolicyNoticeChange',
    text: 'admins.app-admin-events-constants.privacy-policy-notice-change',
  },
  {
    value: 'AppAdminEvents::PrivacyPolicyNoticeContentsChange',
    text: 'admins.app-admin-events-constants.privacy-policy-notice-contents-change',
  },
  {
    value: 'AppAdminEvents::MessengerLookAndFeelChange',
    text: 'admins.app-admin-events-constants.messenger-look-and-feel-change',
  },
  {
    value: 'AppAdminEvents::SearchBrowseEnabledChange',
    text: 'admins.app-admin-events-constants.search-browse-enabled-change',
  },
  {
    value: 'AppAdminEvents::ArticlesInMessengerEnabledChange',
    text: 'admins.app-admin-events-constants.articles-in-messenger-enabled-change',
  },
  {
    value: 'AppAdminEvents::SearchBrowseRequiredChange',
    text: 'admins.app-admin-events-constants.search-browse-required-change',
  },
  {
    value: 'AppAdminEvents::MessengerSearchRequiredChange',
    text: 'admins.app-admin-events-constants.messenger-search-required-change',
  },
  {
    value: 'AppAdminEvents::UpfrontEmailCollectionChange',
    text: 'admins.app-admin-events-constants.upfront-email-collection-change',
  },
  {
    value: 'AppAdminEvents::TemporaryExpectationChange',
    text: 'admins.app-admin-events-constants.temporary-expectation-change',
  },
  {
    value: 'AppAdminEvents::TemporaryExpectationMessagesChange',
    text: 'admins.app-admin-events-constants.temporary-expectation-messages-change',
  },
  {
    value: 'AppAdminEvents::SecuritySettingsChange',
    text: 'admins.app-admin-events-constants.security-settings-change',
  },
  {
    value: 'AppAdminEvents::AdminTwoFactorAuthChange',
    text: 'admins.app-admin-events-constants.admin-two-factor-auth-change',
  },
  {
    value: 'AppAdminEvents::MessengerSpacesChange',
    text: 'admins.app-admin-events-constants.messenger-spaces-change',
  },
  {
    value: 'AppAdminEvents::ArticleCreation',
    text: 'admins.app-admin-events-constants.article-creation',
  },
  {
    value: 'AppAdminEvents::ArticleDeletion',
    text: 'admins.app-admin-events-constants.article-deletion',
  },
  {
    value: 'AppAdminEvents::ArticleContentCreation',
    text: 'admins.app-admin-events-constants.article-content-creation',
  },
  {
    value: 'AppAdminEvents::ArticleContentUpdate',
    text: 'admins.app-admin-events-constants.article-content-update',
  },
  {
    value: 'AppAdminEvents::ArticleContentDeletion',
    text: 'admins.app-admin-events-constants.article-content-deletion',
  },
  {
    value: 'AppAdminEvents::ArticleContentPublished',
    text: 'admins.app-admin-events-constants.article-content-published',
  },
  {
    value: 'AppAdminEvents::ArticleContentUnpublished',
    text: 'admins.app-admin-events-constants.article-content-unpublished',
  },
  {
    value: 'AppAdminEvents::EmailSignatureChange',
    text: 'admins.app-admin-events-constants.email-signature-change',
  },
  {
    value: 'AppAdminEvents::BulkDataExportCreation',
    text: 'admins.app-admin-events-constants.bulk-data-export-creation',
  },
  {
    value: 'AppAdminEvents::BulkDataExportRun',
    text: 'admins.app-admin-events-constants.bulk-data-export-run',
  },
  {
    value: 'AppAdminEvents::RecoveryCodeSent',
    text: 'admins.app-admin-events-constants.recovery-code-sent',
  },
  {
    value: 'AppAdminEvents::BillingAddresseesChange',
    text: 'admins.app-admin-events-constants.billing-addressees-change',
  },
  {
    value: 'AppAdminEvents::BillingAddresseesBouncedAndRemoved',
    text: 'admins.app-admin-events-constants.bounced-email-address',
  },
  {
    value: 'AppAdminEvents::AdminUnauthorizedSignInMethod',
    text: 'admins.app-admin-events-constants.admin-unauthorized-sign-in-method',
  },
  {
    value: 'AppAdminEvents::AdminPasswordResetRequest',
    text: 'admins.app-admin-events-constants.admin-password-reset-request',
  },
  {
    value: 'AppAdminEvents::AdminPasswordResetSuccess',
    text: 'admins.app-admin-events-constants.admin-password-reset-success',
  },
  {
    value: 'AppAdminEvents::BulkDelete',
    text: 'admins.app-admin-events-constants.bulk-delete',
  },
  {
    value: 'AppAdminEvents::AdminAssignmentLimitChange',
    text: 'admins.app-admin-events-constants.admin-assignment-limit-change',
  },
  {
    value: 'AppAdminEvents::FinOptIn',
    text: 'admins.app-admin-events-constants.fin-opt-in',
  },
  {
    value: 'AppAdminEvents::AppIdentityVerificationReminderDisabled',
    text: 'admins.app-admin-events-constants.idv-reminder-disabled',
  },
  {
    value: 'AppAdminEvents::MessengerPlatformAvailabilityChange',
    text: 'admins.app-admin-events-constants.messenger-platform-availability-change',
  },
  {
    value: 'AppAdminEvents::MessengerPlatformInactivityDisableEvent',
    text: 'admins.app-admin-events-constants.messenger-platform-inactivity-disable-event',
  },
  {
    value: 'AppAdminEvents::IpAllowlistSettingChange',
    text: 'admins.app-admin-events-constants.ip-allowlist-setting-change',
  },
  {
    value: 'AppAdminEvents::AdminMobileLoginFailure',
    text: 'admins.app-admin-events-constants.admin-mobile-login-failure',
  },
  {
    value: 'AppAdminEvents::AdminMobileLoginSuccess',
    text: 'admins.app-admin-events-constants.admin-mobile-login-success',
  },
  {
    value: 'AppAdminEvents::AdminNameChange',
    text: 'admins.app-admin-events-constants.admin-name-change',
  },
  {
    value: 'AppAdminEvents::AdminPasswordChange',
    text: 'admins.app-admin-events-constants.admin-password-change',
  },
  {
    value: 'AppAdminEvents::AdminEmailChange',
    text: 'admins.app-admin-events-constants.admin-email-change',
  },
  {
    value: 'AppAdminEvents::CustomReportCreate',
    text: 'admins.app-admin-events-constants.report-create',
  },
  {
    value: 'AppAdminEvents::CustomReportUpdate',
    text: 'admins.app-admin-events-constants.report-update',
  },
  {
    value: 'AppAdminEvents::CustomReportDelete',
    text: 'admins.app-admin-events-constants.report-delete',
  },
  {
    value: 'AppAdminEvents::ReportingFolderCreate',
    text: 'admins.app-admin-events-constants.reporting-folder-create',
  },
  {
    value: 'AppAdminEvents::ReportingFolderDelete',
    text: 'admins.app-admin-events-constants.reporting-folder-delete',
  },
  {
    value: 'AppAdminEvents::ReportingFolderNameChange',
    text: 'admins.app-admin-events-constants.reporting-folder-name-change',
  },
  {
    value: 'AppAdminEvents::ReportingFolderPositionChange',
    text: 'admins.app-admin-events-constants.reporting-folder-position-change',
  },
  {
    value: 'AppAdminEvents::ReportingFolderReportsChange',
    text: 'admins.app-admin-events-constants.reporting-folder-reports-change',
  },
  {
    value: 'AppAdminEvents::ReportingFolderPinChange',
    text: 'admins.app-admin-events-constants.reporting-folder-pin-change',
  },
  {
    value: 'AppAdminEvents::ReportingCsvExport',
    text: 'admins.app-admin-events-constants.reporting-csv-export',
  },
  {
    value: 'AppAdminEvents::ReportAccessCreate',
    text: 'admins.app-admin-events-constants.report-access-create',
  },
  {
    value: 'AppAdminEvents::ReportAccessDelete',
    text: 'admins.app-admin-events-constants.report-access-delete',
  },
  {
    value: 'AppAdminEvents::ReportAccessUpdate',
    text: 'admins.app-admin-events-constants.report-access-update',
  },
  {
    value: 'AppAdminEvents::SeatRevoke',
    text: 'admins.app-admin-events-constants.seat-revoke',
  },
  {
    value: 'AppAdminEvents::AdminChannelChange',
    text: 'admins.app-admin-events-constants.admin-channel-change',
  },
  {
    value: 'AppAdminEvents::FinPersistedDataContextAttributeChange',
    text: 'admins.app-admin-events-constants.fin-persisted-data-context-attribute-change',
  },
  {
    value: 'AppAdminEvents::AiAgentCustomizationSettingsChange',
    text: 'admins.app-admin-events-constants.ai-agent-customization-settings-change',
  },
  {
    value: 'AppAdminEvents::AiAgentIdentitySettingsChange',
    text: 'admins.app-admin-events-constants.ai-agent-identity-settings-change',
  },
  {
    value: 'AppAdminEvents::AiAgentMultilingualSettingsChange',
    text: 'admins.app-admin-events-constants.ai-agent-multilingual-settings-change',
  },
  {
    value: 'AppAdminEvents::AiAgentGuidanceChange',
    text: 'admins.app-admin-events-constants.ai-agent-guidance-change',
  },
  {
    value: 'AppAdminEvents::MessengerLanguageChange',
    text: 'admins.app-admin-events-constants.messenger-language-change',
  },
  {
    value: 'AppAdminEvents::BrandCreation',
    text: 'admins.app-admin-events-constants.brand-creation',
  },
  {
    value: 'AppAdminEvents::BrandUpdate',
    text: 'admins.app-admin-events-constants.brand-update',
  },
  {
    value: 'AppAdminEvents::BrandDeletion',
    text: 'admins.app-admin-events-constants.brand-deletion',
  },
];
