/* RESPONSIBLE TEAM: team-actions */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AppstoreRoute from 'embercom/routes/base/appstore';

export default class AppStoreRoute extends AppstoreRoute {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;

  analytics = {
    section: 'app_store',
  };

  queryParams = {
    category: {
      refreshModel: true,
    },
    search: {
      refreshModel: true,
    },
    installed: {
      refreshModel: true,
    },
    capability: {
      refreshModel: true,
    },
  };

  get app() {
    return this.appService.app;
  }

  willTransition() {
    this.refresh();
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      object: 'app_store',
      context: 'new_settings',
    });

    if (!this.permissionsService.currentAdminCan('can_manage_apps_and_integrations')) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'visited_without_install_permission',
        object: 'app_store',
        context: 'new_settings',
      });
    }
  }
}
