/* import __COLOCATED_TEMPLATE__ from './basic-javascript.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  appIdCode: string;
  createdAt: string;
  email: string;
  highlightLanguage: string;
  name: string;
  selectedProgrammingLanguage: string;
  selectedUserIdentifier: string;
  userHash: string;
  userID: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class BasicJavascript extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  @action
  onCopyCode() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'copy_code_snippet',
      metadata: {
        is_idv: true,
        identifier: this.args.selectedUserIdentifier,
        app_type: 'basic_javascript',
        programming_language: this.args.selectedProgrammingLanguage,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::IdvSetup::CodeSnippets::UpdateSite::BasicJavascript': typeof BasicJavascript;
    'installation-new/web/idv-setup/code-snippets/update-site/basic-javascript': typeof BasicJavascript;
  }
}
