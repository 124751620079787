/* import __COLOCATED_TEMPLATE__ from './conversation-page-command-k-actions.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { isEmpty } from '@ember/utils';
import { type DurationObject, DurationType } from 'embercom/objects/inbox/duration';
import { isPartTaggedWith } from 'embercom/objects/inbox/taggable-part';
import Tag, { type TagActionType } from 'embercom/objects/inbox/tag';
import type WorkflowConnectorAction from 'embercom/objects/inbox/workflow-connector-action';
import type WorkflowDetails from 'embercom/objects/inbox/workflow-details';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { taskFor } from 'ember-concurrency-ts';
import type UserComment from 'embercom/objects/inbox/renderable/user-comment';
import { type ConversationResource } from './conversation-resource';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { AsyncData } from 'embercom/resources/utils/async-data';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import type InboxApi from 'embercom/services/inbox-api';
import { use } from 'ember-resources/util/function-resource';

interface Args {
  conversationResource: ConversationResource;
  setTicketTypeToLinkToConversation: (ticketTypeId?: number) => void;
  setTicketTypeToConvertToTicket: (ticketTypeId?: number) => void;
  closeTicketCreationPanel?: () => void;
  closeConversation: () => void;
}

interface Signature {
  Args: Args;
}

export default class ConversationPageCommandKActionsComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare inboxApi: InboxApi;

  @tracked tagActionType: TagActionType = null;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get conversation(): Conversation {
    return this.args.conversationResource.conversation;
  }

  get lastUserCommentContext() {
    return {
      conversationPart: this.conversation.lastUserComment,
      tags: (this.conversation.lastUserComment?.renderableData as UserComment)?.tags ?? [],
    };
  }

  get firstUserCommentContext() {
    return {
      conversationPart: this.conversation.firstUserComment,
      tags: (this.conversation.firstUserComment?.renderableData as UserComment)?.tags ?? [],
    };
  }

  @action updateTagsForConversationPart(tag: Tag, context: any) {
    this.optimisticUpdateTagsForConversationPart(tag, context);
  }

  @action legacyUpdateTagsForConversationPart(tag: Tag, context: any) {
    let conversationPart = context.conversationPart;
    let partIsTagged = isPartTaggedWith(conversationPart, tag);
    let newTags;
    let { tags: existingTags }: { tags: Array<Tag> } = conversationPart.renderableData;

    if (partIsTagged) {
      newTags = existingTags.filter((t) => t.id !== tag.id);
    } else {
      newTags = [...existingTags, new Tag(tag.id, tag.name, this.session.teammate)];
    }

    this.setTagActionType(partIsTagged ? 'removed' : 'added');
    taskFor(this.inboxState.updateTags).perform(this.conversation, conversationPart, newTags);
  }

  @action optimisticUpdateTagsForConversationPart(tag: Tag, context: any) {
    let conversationPart = context.conversationPart;
    let partIsTagged = isPartTaggedWith(conversationPart, tag);

    this.setTagActionType(partIsTagged ? 'removed' : 'added');

    if (partIsTagged) {
      this.inboxState.removeTag(this.conversation, conversationPart, tag);
    } else {
      this.inboxState.addTag(this.conversation, conversationPart, tag);
    }
  }

  @action triggerWorkflowConnectorAction(action: WorkflowConnectorAction) {
    taskFor(this.inboxState.triggerWorkflowConnectorAction).perform(
      this.conversation,
      null,
      action,
    );
  }

  @action triggerWorkflow(workflowDetails: WorkflowDetails) {
    taskFor(this.inboxState.triggerWorkflow).perform(this.conversation, workflowDetails);
  }

  @action triggerFinAiAgent() {
    taskFor(this.inboxState.triggerFinAiAgent).perform(this.conversation);
  }

  get descriptors() {
    return this.session.workspace.conversationAttributeDescriptors ?? [];
  }

  get hasSharedTaskTickets() {
    if (!this.linkedTickets.value) {
      return false;
    }

    return this.linkedTickets.value
      .filter((ticket) => ticket.category === TicketCategory.Task)
      .some((taskTickets) => taskTickets.visibleToUser);
  }

  get canConvertToTicket() {
    return !this.conversation.isTicket && !this.hasSharedTaskTickets;
  }

  get convertToTicket() {
    return this.args.setTicketTypeToConvertToTicket;
  }

  commandKSnoozeTrackingDataFromSelectValue(duration: DurationObject) {
    return {
      snoozed_until: DurationType[duration.type],
    };
  }

  @action jumpToAppDetails(param: SidebarSection) {
    let sectionId = (
      !isEmpty(param.intercomModuleIdentifier) ? param.intercomModuleIdentifier : param.inboxAppId
    ) as SupportedSidebarSection;
    if (sectionId) {
      let waitBeforeScroll = false;
      if (!this.inboxSidebarService.openSidebarSections.includes(sectionId)) {
        this.inboxSidebarService.onOpenSectionChange(sectionId)(sectionId);
        waitBeforeScroll = true;
      }
      taskFor(this.inboxSidebarService.scrollToSection).perform(sectionId, waitBeforeScroll);
    }
  }

  @action createSideConversation() {
    this.inboxSidebarService.openNewSideConversationPanel();
  }

  @action setTicketTypeToLink({ id }: { id: number }) {
    this.args.setTicketTypeToLinkToConversation(id);
  }

  @action setTicketTypeToConvertToTicket({ id }: { id: number }) {
    this.args.setTicketTypeToConvertToTicket(id);
  }

  @action setTagActionType(type: TagActionType) {
    this.tagActionType = type;
  }

  @action togglePriority() {
    this.inboxState.changePriority(this.conversation, !this.conversation.priority);
  }

  @action onSnoozeSelect(duration: DurationObject) {
    this.args.conversationResource.snoozeConversation(duration);

    this.args.closeTicketCreationPanel && this.args.closeTicketCreationPanel();
  }

  @action openCopilot() {
    this.inboxSidebarService.openCopilot();
  }

  @use linkedTickets = AsyncData<LinkedTicketSummary[]>(async () => {
    if (!this.conversation.id) {
      return false;
    }
    let { tickets } = await this.inboxApi.getLinkedTickets(this.conversation.id);
    return tickets;
  });

  get togglePriorityTrackingData() {
    return {
      action: 'changed',
      object: 'priority',
      section: 'conversation_details',
      marked_as_priority: this.conversation.priority,
      inbox_type: this.inboxState.activeInbox?.type,
      layout_type: this.inboxState.activeConversationsView,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPageCommandKActions': typeof ConversationPageCommandKActionsComponent;
    'inbox2/conversation-page-command-k-actions': typeof ConversationPageCommandKActionsComponent;
  }
}
