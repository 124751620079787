/* import __COLOCATED_TEMPLATE__ from './pending-suggestions-banner.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { type ButtonType } from '@intercom/pulse/template-registry';

interface Args {
  numberOfPendingItemsToReview: number;
  pendingItemsLimit?: string;
  buttonType: ButtonType;
  justifyValue: string;
  showDismissButton?: boolean;
}

export default class PendingSuggestionsBanner extends Component<Args> {
  @service declare router: Router;
  @service declare intercomEventService: any;

  @tracked isHidden = false;

  get pendingItemsLimit() {
    return this.args.pendingItemsLimit ? Number(this.args.pendingItemsLimit) : 0;
  }

  @action reviewSuggestions(): void {
    this.router.transitionTo('apps.app.fin-ai-agent.content-suggestions', {
      queryParams: { actionType: 'open_content_modal' },
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'review_suggestions_button',
      section: 'operator',
      context: 'review_suggestions_notification_banner',
      place: 'fin_content_page',
    });
  }

  @action dismissBanner(): void {
    this.isHidden = true;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'dismiss_review_suggestions_banner_button',
      section: 'operator',
      context: 'review_suggestions_notification_banner',
      place: 'fin_content_page',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Banners::PendingSuggestionsBanner': typeof PendingSuggestionsBanner;
  }
}
