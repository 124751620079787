/* import __COLOCATED_TEMPLATE__ from './email-forwarding-verification-button.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type DomainService from 'embercom/services/domain-service';
import { type DomainEmailAddress } from 'embercom/services/domain-service';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  emailAddress: DomainEmailAddress | undefined;
}

interface Signature {
  Args: Args;
}

export default class EmailForwardingVerificationButton extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare mailForwardingValidationService: any;
  @service declare domainService: DomainService;

  @tracked verificationTaskResult: 'success' | 'failed' | null = null;

  get verificationTask() {
    return taskFor(this.mailForwardingValidationService.verifyEmailForwarding);
  }

  get verification() {
    return this.mailForwardingValidationService.verification;
  }

  @action
  async verifyEmailForwarding() {
    if (!this.args.emailAddress) {
      return;
    }

    await this.mailForwardingValidationService.verifyEmailForwarding.perform(
      this.args.emailAddress.email,
      'domains-and-addresses_edit-address',
    );
    if (this.verification) {
      await this.domainService.load();
      this.verificationTaskResult = 'success';
    } else {
      this.verificationTaskResult = 'failed';
    }
    this.mailForwardingValidationService.unset();
  }

  @action
  requestForwardingVerification() {
    this.verifyEmailForwarding();
    this.trackClickEvent('check_email_forwarding');
  }

  @action
  trackClickEvent(event: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: event,
      place: 'check-email-forwarding',
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::EmailForwardingVerificationButton': typeof EmailForwardingVerificationButton;
  }
}
