/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-empty-glimmer-component-classes */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ApiConfiguration from 'embercom/models/standalone/api-configuration';

interface Args {
  apiConfig: ApiConfiguration;
}

export default class StandaloneApiSetup extends Component<Args> {
  @tracked openSectionId?: string;

  @action setOpenSectionId(id: string | undefined) {
    this.openSectionId = id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Api::Setup': typeof StandaloneApiSetup;
    'standalone/api/setup': typeof StandaloneApiSetup;
  }
}
