/* RESPONSIBLE TEAM: team-knowledge-interop */
/* eslint-disable @intercom/intercom/no-bare-strings */

export const COLLECTION_PICKER_MODAL_OPTIONS =
  // IMPORTANT: labels and descriptions are NOT translated, willingly out of choice
  // See https://github.com/intercom/intercom/issues/366317#issuecomment-2493954708
  [
    {
      label: 'General',
      description: 'Handy information to general topics.',
      icon: 'ff-folder',
      id: 'general',
    },
    {
      label: 'FAQs',
      description: 'Answers to frequently asked questions.',
      icon: 'arr-sym-question-mark-circle',
      id: 'faqs',
    },
    {
      label: 'Integrations',
      description: 'Connect and manage third-party tools.',
      icon: 'sft-wrench-screwdriver',
      id: 'integrations',
    },
    {
      label: 'Getting Started',
      description: 'Everything you need to get going.',
      icon: 'arr-sym-play-circle',
      id: 'getting_started',
    },
    {
      label: 'Troubleshooting',
      description: 'Solutions to common problems.',
      icon: 'other-lifebuoy',
      id: 'troubleshooting',
    },
    {
      label: 'Reports',
      description: 'Insights and performance analytics.',
      icon: 'charts-presentation-chart-bar',
      id: 'reports',
    },
    {
      label: 'Billing',
      description: 'Manage payments and subscriptions.',
      icon: 'bizz-fin-banknotes',
      id: 'billing',
    },
    {
      label: 'Payments',
      description: 'Processing payments and refunds.',
      icon: 'bizz-fin-credit-card',
      id: 'payments',
    },
    {
      label: 'Settings',
      description: 'Help with system preferences.',
      icon: 'sft-cog-8-tooth',
      id: 'settings',
    },
    {
      label: 'Account',
      description: 'Managing personal account details.',
      icon: 'people-chat-gets-user-circle',
      id: 'account',
    },
    {
      label: 'Security',
      description: 'Protecting your account and data.',
      icon: 'sft-lock-closed',
      id: 'security',
    },
    {
      label: 'Release notes',
      description: 'Updates and new feature releases.',
      icon: 'sft-megaphone',
      id: 'release_notes',
    },
  ];
