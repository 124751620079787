/* import __COLOCATED_TEMPLATE__ from './reconnect-shopify.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import FormForDownload from 'embercom/lib/form-for-download';

export default class Order extends Component {
  @service intercomEventService;

  @action
  openShopifyScopeRequestUrl() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'reconnect-shopify-to-intercom-button',
      context: this.args.context,
    });
    FormForDownload.postNewTab('https://iis.intercom.io/login');
  }
}
