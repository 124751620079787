/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { notEmpty, equal } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import {
  applyFunction,
  selectFromObject,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import moment from 'moment-timezone';

let formatDate = function (date) {
  if (isEmpty(date)) {
    return null;
  }
  if (moment().diff(date) <= 0) {
    return 'a few seconds ago';
  }
  return moment(date).fromNow();
};

export default Model.extend({
  learnMoreUrl: attr(),
  longDescription: attr(),
  name: attr(),
  code: attr(),
  setupFlowType: attr(),
  shortDescription: attr(),
  feature: attr(),
  appId: attr(),
  publiclyAvailable: attr(),
  paywallFlag: attr('string'),
  builtByIntercom: attr('boolean'),
  logoUrl: attr('string'),
  client_id: attr('string'),
  installed: attr('boolean'),

  connectedIntegration: belongsTo('connected-integration'),

  hasIntegrated: notEmpty('connectedIntegration.content'),
  addedDate: applyFunction(formatDate, 'connectedIntegration.content.createdAt'),
  isCustom: equal('setupFlowType', 'custom'),
  isModal: equal('setupFlowType', 'modal'),
  isExternalLink: equal('setupFlowType', 'external-link'),

  buttonComponentPath: computed('code', 'setupFlowType', function () {
    let componentName = 'transition-connect-button';
    if (this.isCustom) {
      componentName = `${this.code}-connect-button`;
    } else if (this.isModal) {
      componentName = 'modal-connect-button';
    } else if (this.isExternalLink) {
      componentName = 'external-link-button';
    }

    return `integrations-hub/${componentName}`;
  }),

  connectedPagePath: computed('code', function () {
    return ['apps.app.integrations-hub.integration', this.code];
  }),
  transitionParams: ternaryToProperty(
    'currentCustomTransition',
    'currentCustomTransition',
    'connectedPagePath',
  ),
  currentCustomTransition: selectFromObject('customTransitions', 'code'),
  customDescription: selectFromObject('customDescriptions', 'code'),
}).reopen({
  integrationsWithCustomTransition: ['segment'],
  customTransitions: {
    segment: ['apps.app.guide.step-group.step', 'segment_integration', 'segment_integration'],
  },
  customDescriptions: {
    facebook: 'Forward private messages sent to your Facebook page.',
    twitter: 'Forward direct messages sent to your Twitter account.',
  },
});
