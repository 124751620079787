/* RESPONSIBLE TEAM: team-workflows */
import {
  type BlockList,
  BlockListParser,
  DocumentHtmlEntityDecoder,
} from '@intercom/interblocks.ts';
import { type Node, type Program } from '@intercom/interblocks.ts/dist/types/ast';
import { NODE_TYPE } from '@intercom/interblocks.ts/src/ast/node';

export function extractTemplateVariables(blocks: BlockList): string[] {
  let parsed: Program;
  try {
    parsed = BlockListParser.parse(
      blocks,
      new DocumentHtmlEntityDecoder(document),
      true, // enable template parsing
    );
  } catch (e) {
    return [];
  }

  if (!parsed.body) {
    return [];
  }

  let findTemplateValues = (nodes: Node[]): string[] => {
    let values: string[] = [];
    for (let node of nodes) {
      if (node) {
        if (node.type === NODE_TYPE.TEMPLATE && node.value) {
          values.push(node.value);
        }

        if ('children' in node && Array.isArray(node.children) && node.children) {
          values = values.concat(findTemplateValues(node.children));
        }
      }
    }

    return values;
  };

  return [...new Set(findTemplateValues(parsed.body))];
}
