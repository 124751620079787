/* import __COLOCATED_TEMPLATE__ from './order.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Order extends Component {
  @tracked isExpanded = false;

  @action
  collapseOrExpand(order) {
    this.isExpanded = !this.isExpanded;
  }
}
