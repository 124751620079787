/* import __COLOCATED_TEMPLATE__ from './phone-call-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { PRICING_5_X_CORE_ESSENTIAL_ID, PRICING_5_X_CORE_ID } from 'embercom/lib/billing';
import type Product from 'embercom/models/product';
import type Plan from 'embercom/models/plan';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type TwilioService from 'embercom/services/twilio-service';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';

interface Args {}

export default class PhoneCallModal extends Component<Args> {
  @service declare twilioService: TwilioService;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @tracked isMuted = false;

  @action leaveCall() {
    return this.twilioService.teammateLeaveConference();
  }

  @action endConferenceCall() {
    return this.twilioService.teammateEndConferenceCall();
  }

  @task({ drop: true })
  *hangUp(): TaskGenerator<void> {
    if (this.isConferenceCallActive) {
      yield this.endConferenceCall();
    } else if (this.twilioService.isWarmTransferCallActive) {
      yield this.twilioService.cancelWarmTransfer();
    } else if (this.twilioService.isWarmTransfer) {
      if (this.twilioService.activeCall) {
        this.twilioService.activeCall.hangedUpByAdmin = true;
      }
      yield this.twilioService.teammateEndConferenceCall();
    } else {
      if (this.twilioService.activeCall && this.twilioService.incomingCall) {
        this.twilioService.activeCall.hangedUpByAdmin = true;
        return yield this.twilioService.teammateHangUp();
      }
      yield this.twilioService.activeCall?.hangUp();
    }
  }

  @action pressDigit(digit: string) {
    this.twilioService.activeCall?.pressDigit(digit);
  }

  @action toggleOnHold() {
    if (this.twilioService.isWarmTransferCallActive) {
      taskFor(this.twilioService.toggleTransferOnHold).perform();
    } else {
      taskFor(this.twilioService.toggleOnHold).perform();
    }
  }

  @action mute() {
    if (this.twilioService.activeCall) {
      this.isMuted = this.twilioService.activeCall.mute();
    }
  }

  @action
  toggleRecording() {
    taskFor(this.twilioService.toggleRecording).perform();
  }

  @task({ drop: true })
  *completeWarmTransfer(): TaskGenerator<void> {
    yield this.twilioService.completeWarmTransfer();
  }

  @task({ drop: true })
  *toggleWarmTransferCall() {
    if (this.twilioService.isWarmTransferCallActive) {
      if (!this.twilioService.isOnHold) {
        yield taskFor(this.twilioService.toggleTransferOnHold).perform();
      }
      this.twilioService.warmTransferState = 'background-hold';
      this.twilioService.isOnHold = true;
    } else {
      if (!this.twilioService.isOnHold) {
        yield taskFor(this.twilioService.toggleOnHold).perform();
      }
      this.twilioService.warmTransferState = 'talking';
      this.twilioService.isOnHold = true;
    }
  }

  get addingParticipantLabel() {
    switch (this.twilioService.currentCallAction) {
      case 'adding-participant':
        return this.intl.t('calling.modal.conference-call.ringing-participant', {
          name: this.twilioService.addingCallParticipantInfo?.label,
        });
      case 'transferring-to-teammate':
      case 'transferring-externally':
        return this.intl.t('calling.modal.transfer.transferring-to', {
          name: this.twilioService.addingCallParticipantInfo?.label,
        });
      default:
        return undefined;
    }
  }

  get warmTransferTeammate() {
    if (this.twilioService.warmTransferState && this.twilioService.addingCallParticipantInfo) {
      return this.inbox2AssigneeSearch.findAdminById(
        Number(this.twilioService.addingCallParticipantInfo.identifier),
      );
    }

    return undefined;
  }

  get participants() {
    return this.twilioService.participants;
  }

  get userSummary() {
    return this.twilioService.userSummary;
  }

  get isTimerVisible() {
    if (this.twilioService.isWarmTransferCallActive) {
      return this.twilioService.warmTransferState !== 'ringing';
    }

    return this.twilioService.callState === 'accept';
  }

  get isConferenceCallActive() {
    if (!this.session.workspace.isFeatureEnabled('phone-conference-calls')) {
      return false;
    }

    return this.participants.length > 2;
  }

  get renderOnHold() {
    return !this.isConferenceCallActive && this.twilioService.callState === 'accept';
  }

  get isOnHold() {
    return this.twilioService.isOnHold;
  }

  get canToggleHold() {
    return (
      !this.twilioService.disableHold &&
      !taskFor(this.twilioService.toggleOnHold).isRunning &&
      !taskFor(this.twilioService.toggleTransferOnHold).isRunning
    );
  }

  get canToggleRecording() {
    return !taskFor(this.twilioService.toggleRecording).isRunning;
  }

  get renderTransfer() {
    return (
      !this.isConferenceCallActive &&
      !this.twilioService.isTransferToTeammate &&
      !this.twilioService.isWarmTransfer &&
      this.twilioService.callState === 'accept' &&
      (this.twilioService.incomingCall !== null ||
        this.session.workspace.isFeatureEnabled('team-phone-outbound-transfers'))
    );
  }

  get renderWarmTransfer() {
    return (
      !this.isConferenceCallActive &&
      !this.twilioService.isTransferToTeammate &&
      !this.twilioService.isWarmTransfer &&
      this.twilioService.callState === 'accept'
    );
  }

  get canTransfer() {
    return !this.isTransferTaskRunning && !this.twilioService.isAddingParticipant;
  }

  get isTransferTaskRunning() {
    return (
      taskFor(this.twilioService.transferToTeam).isRunning ||
      taskFor(this.twilioService.transferToAdmin).isRunning ||
      taskFor(this.twilioService.transferToExternalNumber).isRunning
    );
  }

  get renderAddParticipant() {
    return (
      !this.isConferenceCallActive &&
      !this.twilioService.isWarmTransfer &&
      this.twilioService.callState === 'accept' &&
      this.session.workspace.isFeatureEnabled('phone-conference-calls') &&
      this.customerIsNotOnEssentialPlan
    );
  }

  get customerIsNotOnEssentialPlan(): boolean {
    let products = this.session.workspace.products;
    let coreProduct = products?.find((product: Product) => product.id === PRICING_5_X_CORE_ID);
    let essentialPlan = coreProduct?.plans?.find(
      (plan: Plan) => String(plan.id) === PRICING_5_X_CORE_ESSENTIAL_ID,
    );

    return !essentialPlan?.active;
  }

  get canAddParticipant() {
    return !this.twilioService.currentCallAction;
  }

  get startTime() {
    return this.twilioService.activeCall?.startTime;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneCallModal': typeof PhoneCallModal;
    'inbox2/phone-call-modal': typeof PhoneCallModal;
  }
}
