/* import __COLOCATED_TEMPLATE__ from './dependency-warning-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type IntlService from 'embercom/services/intl';
import type { reasonData } from '../channels/email/addresses/address-sidebar';

interface Args {
  reasons: reasonData[];
  entityName: string;
  updateDependencyCheckStatus: (status: boolean) => void;
}

interface Signature {
  Args: Args;
}

type reasonKey = 'brands' | 'proactive_support';

export default class DependencyWarningModal extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  additionalDataArray: Array<string> = [];

  reasonUrlMap = new Map<reasonKey, string>([
    ['brands', '/a/apps/_/settings/workspace/brands'],
    ['proactive_support', '/a/apps/_/outbound/all'],
  ]);

  getDynamicUrl(reason: reasonKey): string {
    return this.reasonUrlMap.get(reason)!;
  }

  // TO DO - remove when team-growth-opportunities-new-email-setup is globally enabled
  get tab() {
    return this.appService.app.canUseNewEmailSetupExperience ? 'email-settings' : 'advanced';
  }

  reasonIsDefaultWorkspaceAddress(reason: any): boolean {
    return reason.brands && reason.brands.is_default_notification_address;
  }

  getDynamicTranslation(reason: any, type: 'title' | 'description') {
    let key = Object.keys(reason)[0];
    let additionalData;

    if (reason.proactive_support) {
      key = 'proactive-support';
      additionalData = reason.proactive_support!.total;
    } else if (reason.brands) {
      additionalData = reason.brands!.brand_name;
    }

    if (type === 'title') {
      return [
        `new-settings.common.dependency-warning-modal.email-addresses.reasons.${key}-${type}`,
      ];
    } else {
      let blockingDeleteKey =
        reason.proactive_support && additionalData === 1
          ? `new-settings.common.dependency-warning-modal.email-addresses.reasons.${key}-${type}-blocking-delete-singular`
          : `new-settings.common.dependency-warning-modal.email-addresses.reasons.${key}-${type}-blocking-delete`;
      return [
        `new-settings.common.dependency-warning-modal.email-addresses.reasons.${key}-${type}-opener`,
        blockingDeleteKey,
        additionalData,
        `new-settings.common.dependency-warning-modal.email-addresses.reasons.${key}-${type}-closer`,
      ];
    }
  }

  getAdditionalData(index: number) {
    return this.additionalDataArray[index];
  }

  @action
  openDynamicLink(reason: reasonData) {
    let key = Object.keys(reason)[0];
    let url = this.getDynamicUrl(key as reasonKey);
    safeWindowOpen(url);
  }

  @action
  resetState() {
    this.args.reasons.setObjects([]);
    this.args.updateDependencyCheckStatus(true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Common::DependencyWarningModal': typeof DependencyWarningModal;
    'new-settings/common/dependency-warning-modal': typeof DependencyWarningModal;
  }
}
