/* RESPONSIBLE TEAM: team-frontend-tech */

import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type NavbarCollapsingService from 'embercom/services/navbar-collapsing-service';
import type ThemeService from 'embercom/services/theme-service';
import type Theme from 'embercom/objects/inbox/types/theme';
import { isReactRoute } from 'embercom/lib/react-route';

interface AppState {
  navbarCollapsed: boolean;
  locale: string;
  theme: Theme;
}

const MODAL_COMPONENT_BRIDGE_MAP = {
  'web-push-notifications-permission-denied': 'account/notifications-denied-modal',
  'web-push-notifications-permission-prompt': 'account/notifications-ask-modal',
};

export default class ReactTeammateAppIslandService extends Service {
  @tracked reactTeammateApp?: typeof window.ReactTeammateApp;

  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare modalService: $TSFixMe;
  @service declare navbarCollapsingService: NavbarCollapsingService;
  @service declare themeService: ThemeService;
  isAppBooted = false;
  eventQueue: { type: string; payload: any }[] = [];

  get appState(): AppState {
    return {
      navbarCollapsed: this.navbarCollapsingService.collapsed,
      locale: this.intl.primaryLocale,
      theme: this.themeService.computedTheme,
    };
  }

  get bridge() {
    return window.ReactTeammateApp?.bridge;
  }

  setupBridge() {
    this.bridge?.addEventListener('ember:bridge', (event: CustomEvent) => {
      let { type, payload } = event.detail;

      switch (type) {
        case 'route:boot':
          this.routerBootEventHandler();
          break;
        case 'route:change':
          this.router.transitionTo(payload.pathname);
          break;
        case 'navbar:toggle':
          this.navbarCollapsingService.toggleNavbarCollapsed();
          break;
        case 'notification:default':
          this.notificationsService.notify(payload.message, payload.duration);
          break;
        case 'notification:confirmation':
          this.notificationsService.notifyConfirmation(payload.message, payload.duration);
          break;
        case 'notification:help':
          this.notificationsService.notifyHelp(payload.message, payload.duration);
          break;
        case 'notification:error':
          this.notificationsService.notifyError(payload.message, payload.duration);
          break;
        case 'notification:warning':
          this.notificationsService.notifyWarning(payload.message, payload.duration);
          break;
        case 'notification:loading':
          this.notificationsService.notifyLoading(payload.message, payload.duration);
          break;
        case 'cmd+k:toggle':
          this.dispatchCommandK();
          break;
        case 'modal:open': {
          let modalComponent =
            MODAL_COMPONENT_BRIDGE_MAP[payload.name as keyof typeof MODAL_COMPONENT_BRIDGE_MAP];
          this.modalService.openModal(modalComponent, payload.model, payload.options);
          break;
        }
        case 'modal:close':
          this.modalService.closeModal(payload?.results);
          break;
      }
    });
  }

  dispatchBridgeEvent(type: string, payload: any) {
    if (this.isAppBooted) {
      this.bridge?.dispatchEvent(
        new CustomEvent('react:bridge', {
          detail: { type, payload },
        }),
      );
    } else {
      this.eventQueue.push({ type, payload });
    }
  }

  dispatchRouteNavigationRequestEvent(to: string) {
    this.dispatchBridgeEvent('route:navigation-request', { to, appState: this.appState });
  }

  dispatchAppStateChangeEvent(state: Partial<AppState>) {
    this.dispatchBridgeEvent('app:state-change', state);
  }

  dispatchCommandK() {
    let event = new KeyboardEvent('keydown', {
      key: 'KeyK',
      keyCode: 75,
      which: 75,
      metaKey: true,
    });

    document.dispatchEvent(event);
  }

  @action
  onAppReady() {
    if (!this.reactTeammateApp) {
      this.reactTeammateApp = window.ReactTeammateApp;
      this.setupBridge();
    }
  }

  routerBootEventHandler() {
    this.isAppBooted = true;
    this.eventQueue.forEach((event) => {
      this.dispatchBridgeEvent(event.type, event.payload);
    });
    this.eventQueue = [];

    if (isReactRoute(this.router.currentRoute)) {
      this.dispatchBridgeEvent('route:boot', {
        to: this.router.currentURL,
        appState: this.appState,
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    reactTeammateAppIslandService: ReactTeammateAppIslandService;
    'react-teammate-app-island-service': ReactTeammateAppIslandService;
  }
}
