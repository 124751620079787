/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { sendIntercomUpdate } from 'embercom/lib/intercom-widget-helper';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Signature {
  Args: {
    model: {
      settings: any;
    };
    title: string;
  };
}

export default class Header extends Component<Signature> {
  @service declare notificationsService: any;
  @service declare intl: any;
  @service declare appService: any;
  @service declare iamService: any;
  @service declare router: any;
  @service declare messengerSettingsService: MessengerSettingsService;
  @service declare guideLibraryService: GuideLibraryService;

  get activeBrand() {
    return this.args.model.settings.lookAndFeel.activeBrand;
  }

  get homeSettings() {
    return this.args.model.settings.home;
  }

  get isSaveDisabled() {
    return this.isSaveRunning || this.messengerIdentityIsInvalid;
  }

  get lookAndFeelSettings() {
    return this.args.model.settings.lookAndFeel;
  }

  get messengerIdentityIsInvalid() {
    return (
      this.lookAndFeelSettings.conversationalMessengerIdentity === 'brand' &&
      !this.lookAndFeelSettings.brandIdentityLogoUrl
    );
  }

  get saveButtonTooltipMessage() {
    if (this.messengerIdentityIsInvalid) {
      return this.intl.t(
        'messenger.settings-layout.header.save-button-disabled-tooltip.messenger-identity',
      );
    }
    return '';
  }

  @action
  async submit() {
    await taskFor(this.save).perform();
    if (this.guideLibraryService.canUseGuideLibraryService) {
      await this.guideLibraryService.markStepCompleted(
        'guide_library_foundational_steps_customise_messenger',
      );
    }
  }

  @task({ drop: true })
  *save(): TaskGenerator<void> {
    yield this.beforeSave();
    try {
      yield taskFor(this.saveSettings).perform();
      this.afterSave();
    } catch (e) {
      //Do nothing
    }
  }

  get isSaveRunning(): boolean {
    return taskFor(this.save).isRunning;
  }

  @task({ drop: true })
  *saveSettings(): TaskGenerator<void> {
    try {
      yield this.args.model.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('messenger.settings-layout.header.success-notification'),
      );
    } catch (error) {
      if (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('messenger.settings-layout.header.failure-notification'),
        });
      }
      throw error;
    }
  }

  @action
  async beforeSave() {
    this.args.model.settings.languages.isConversationalSettingsUpdate = true;
    this.args.model.settings.security.beforeSave();
    await this.args.model.settings.lookAndFeel.beforeSave();
  }

  @action
  afterSave() {
    this.args.model.settings.languages.isConversationalSettingsUpdate = false;
    let spaces = this.args.model.settings.spaces;
    this.args.model.settings.notifyPropertyChange('hasM5UnsavedChanges');
    if (this.iamService.companyId) {
      sendIntercomUpdate({
        can_manage_messenger_settings: true,
        company: {
          id: this.iamService.companyId,
          widget_version: 5,
          messenger_spaces_enabled_for_users: spaces.userEnabledSpacesForCda,
          messenger_spaces_enabled_for_visitors: spaces.visitorEnabledSpacesForCda,
          messenger_spaces_enabled_for_mobile_users: spaces.mobileUserEnabledSpacesForCda,
        },
      });
    }
  }

  @task({ drop: true })
  *saveActiveBrand() {
    yield this.activeBrand.save();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Header': typeof Header;
    'messenger-settings/conversational/header': typeof Header;
  }
}
