/* import __COLOCATED_TEMPLATE__ from './tour-styles.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';

import type IntlService from 'ember-intl/services/intl';

export default class TourStyles extends Component {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare contentEditorService: $TSFixMe;

  colorOptions = DEFAULT_MESSENGER_COLORS;

  get app(): $TSFixMe {
    return this.appService.app;
  }

  get tour(): $TSFixMe {
    return this.contentEditorService.activeObject;
  }

  @action
  setTourButtonColor(buttonColor: string | null) {
    this.tour.buttonColor = buttonColor;
  }

  @action
  toggleHasCustomButtonColor() {
    if (isEmpty(this.tour.buttonColor)) {
      this.setTourButtonColor(this.app.base_color);
    } else {
      this.setTourButtonColor(null);
    }
  }

  @action
  setTourBackgroundColor(backgroundColor: string | null) {
    this.tour.backgroundColor = backgroundColor;
  }

  @action
  toggleHasCustomBackgroundColor() {
    if (isEmpty(this.tour.backgroundColor)) {
      this.setTourBackgroundColor(this.app.base_color);
    } else {
      this.setTourBackgroundColor(null);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tours::Editor::ContentPanel::TourStyles': typeof TourStyles;
    'tours/editor/content-panel/tour-styles': typeof TourStyles;
  }
}
