/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { isBlank } from '@ember/utils';
import { pluralize } from 'ember-inflector';
import { titleCase } from 'embercom/helpers/title-case';
import {
  permissionsMap,
  objectFeatures,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

// The "visual" query param is used for targetting urls in monitoring
// services, e.g. Honeycomb. When visual builder is released to all
// customers we can remove this query param
export const VISUAL_BUILDER_QP_VALUES = {
  disabled: 'disabled',
  enabled: 'enabled',
  viewOnlyEnabled: 'viewonly',
};

export default class EditController extends Controller {
  @service permissionsService;
  @service contentEditorService;
  @service appService;
  @service store;
  @service router;
  @service intl;

  queryParams = ['filter', 'mode', 'test', 'view', 'visual', 'returnPath', 'fin'];

  @tracked filter = stats.receipt;
  @tracked mode = 'view';
  @tracked view = 'content';
  @tracked visual = VISUAL_BUILDER_QP_VALUES.disabled;
  @tracked phoneNumberToBeVerified;
  @tracked returnPath;

  get hasSmsEnabled() {
    return this.requiredFeature === objectFeatures[objectTypes.sms];
  }

  get requiredFeature() {
    return this.contentEditorService?.editorConfiguration?.requiredFeature;
  }

  get paywallOverrideForSmsBeta() {
    if (this.requiredFeature !== objectFeatures[objectTypes.sms]) {
      return;
    }
    return (
      this.appService.app.canUseSMS &&
      !this.appService.app.canUseSmsBeta &&
      !this.appService.app?.canUseFeature('sms')
    );
  }

  get paywallOverride() {
    return this.paywallOverrideForSmsBeta;
  }

  get rulesetLink() {
    if (this.test) {
      return this.model.rulesetLinks.findBy('id', this.test);
    } else {
      return this.model.rulesetLinks.firstObject;
    }
  }

  get permissionKey() {
    return permissionsMap[this.contentEditorService.activeRulesetLink?.objectType];
  }

  get doesNotHavePermission() {
    if (isBlank(this.permissionKey)) {
      return false;
    } else {
      return !this.permissionsService.currentAdminCan(this.permissionKey);
    }
  }

  @action
  changeView(view) {
    this.view = view;
  }

  @action
  showSurveyOnboardingVideo() {
    return this.router.transitionTo({
      queryParams: { onboardingVideoDisplayed: true },
    });
  }

  @action
  changeMode(mode) {
    if (this.doesNotHavePermission && mode === 'edit') {
      this.contentEditorService.changeToViewMode();
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(this.permissionKey);
    } else {
      this.mode = mode;
    }
  }

  @action
  changeFilter(filter) {
    this.filter = filter;
  }

  @action
  changeActiveRulesetLink(test) {
    this.test = test;
  }

  changeVisualBuilderQueryParam(workflowEntity) {
    if (workflowEntity?.visualBuilderObject) {
      this.visual = workflowEntity.canUseVisualBuilderEditor
        ? VISUAL_BUILDER_QP_VALUES.enabled
        : VISUAL_BUILDER_QP_VALUES.viewOnlyEnabled;
    } else {
      this.visual = VISUAL_BUILDER_QP_VALUES.disabled;
    }
  }

  get canShowFullScreenEditor() {
    return this.model.rulesetLinks.any((r) => r?.object?.canUseVisualBuilderEditor);
  }

  get disablePaywallFeatureHighlight() {
    return this.requiredFeature === 'product_tours';
  }

  get paywallContent() {
    if (this.requiredFeature === 'surveys') {
      return this.intl.t('app.templates.apps.app.content.surveys-paywall-banner', {
        product: titleCase(
          pluralize(this.contentEditorService.configRulesetLink.humanReadableObjectName),
        ),
      });
    }
    if (this.requiredFeature === objectFeatures[objectTypes.sms]) {
      return this.intl.t('app.templates.apps.app.content.general-paywall-banner', {
        product: pluralize(this.contentEditorService.configRulesetLink.humanReadableObjectName),
      });
    }
    return this.intl.t('app.templates.apps.app.content.general-paywall-banner', {
      product: titleCase(
        pluralize(this.contentEditorService.configRulesetLink.humanReadableObjectName),
      ),
    });
  }

  @task
  *fetchPhoneNumberToBeVerified() {
    let phoneNumbers = yield this.store.findAll('sms/phone-number');
    this.phoneNumberToBeVerified = phoneNumbers.filter(
      (phoneNumber) => phoneNumber.tollFreeVerificationRequired,
    ).firstObject;
  }
}
