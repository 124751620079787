/* RESPONSIBLE TEAM: team-standalone */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type Model from '@ember-data/model';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';

export default class HandoffToSalesforceOwnerIdExists extends BaseValidator {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;

  async validate(
    _value: unknown,
    options: ValidationOptions & { messageKey: string },
    model: Model & { handoffToSalesforceOwnerId: string | undefined },
  ) {
    if (model.handoffToSalesforceOwnerId) {
      await this.waitForSalesforceSetupData();
      let { admins = [], queues = [] } = this.finStandaloneService.salesforceSetupData;
      let handOffOptions = [...admins.mapBy('id'), ...queues.mapBy('id')];
      if (handOffOptions.includes(model.handoffToSalesforceOwnerId)) {
        return true;
      }
    }

    return this.intl.t(options.messageKey);
  }

  static getDependentsFor() {
    return ['model.handoffToSalesforceOwnerId'];
  }

  async waitForSalesforceSetupData() {
    // This works under the assumption that the salesforce setup data is loaded or loading.
    // As part of initializing the finStandaloneService.
    if (!taskFor(this.finStandaloneService.loadSalesforceSetupData).isRunning) {
      return;
    }

    return taskFor(this.finStandaloneService.loadSalesforceSetupData).last!;
  }
}
