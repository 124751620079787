/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class IntegrationRoute extends Route {
  @service declare finStandaloneService: FinStandaloneService;

  async beforeModel() {
    let promises = [];

    if (this.finStandaloneService.isLoadingZendeskConfig) {
      promises.push(taskFor(this.finStandaloneService.loadZendeskConfig).lastRunning!);
    } else {
      promises.push(taskFor(this.finStandaloneService.loadZendeskConfig).perform());
    }

    if (this.finStandaloneService.isLoadingSalesforceConfig) {
      promises.push(taskFor(this.finStandaloneService.loadSalesforceConfig).lastRunning!);
    } else {
      promises.push(taskFor(this.finStandaloneService.loadSalesforceConfig).perform());
    }

    if (this.finStandaloneService.isLoadingSalesforceLiveChatConfig) {
      promises.push(taskFor(this.finStandaloneService.loadSalesforceLiveChatConfig).lastRunning!);
    } else {
      promises.push(taskFor(this.finStandaloneService.loadSalesforceLiveChatConfig).perform());
    }

    if (this.finStandaloneService.isLoadingSalesforceLiveChatSetupData) {
      promises.push(
        taskFor(this.finStandaloneService.loadSalesforceLiveChatSetupData).lastRunning!,
      );
    } else {
      promises.push(taskFor(this.finStandaloneService.loadSalesforceLiveChatSetupData).perform());
    }

    await Promise.all(promises);
  }
}
