/* import __COLOCATED_TEMPLATE__ from './admin-email-alias-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { put } from 'embercom/lib/ajax';
import { getAdminProfile } from 'embercom/lib/admins/admin-profiles';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'ember-intl/services/intl';
import type SenderEmailAddressSummary from 'embercom/objects/inbox/sender-email-address-summary';
import type Store from '@ember-data/store';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  aliasSenderEmail: string;
  aliasName: string;
}

interface SenderEmailAddressDetail extends SenderEmailAddressSummary {
  value: string;
}

export default class AdminEmailAliasSideDrawer extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare inboxApi: InboxApi;
  @service intercomEventService: $TSFixMe;
  @service intl!: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare store: Store;

  @tracked selectedAliasEmail: string | null = null;
  @tracked showAddCustomSenderModal = false;
  @tracked senderEmailAddressEntities!: SenderEmailAddressDetail[];

  declare model: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadSenderEmailAddresses).perform();
  }

  @task({ drop: true })
  *loadSenderEmailAddresses(): TaskGenerator<void> {
    try {
      let senderEmailAddresses: SenderEmailAddressSummary[] =
        yield this.inboxApi.fetchSenderEmailAddresses();
      this.senderEmailAddressEntities = this._senderEmailAddressEntities(senderEmailAddresses);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('components.admin.about-component.alias.email.not-found'),
      );
      this.args.onClose();
    }
  }

  get app() {
    return this.appService.app;
  }

  get aliasEmail() {
    return this.args.aliasSenderEmail;
  }

  get selectedEmail(): string | undefined {
    return this.selectedAliasEmail ?? this.args.aliasSenderEmail ?? undefined;
  }

  set selectedEmail(value: string | null) {
    this.selectedAliasEmail = value;
  }

  get hasPermissionForCustomAddresses() {
    return this.app.currentAdmin.currentAppPermissions.can_send_from_custom_addresses;
  }

  _senderEmailAddressEntities(senderEmailAddresses: SenderEmailAddressSummary[]) {
    return senderEmailAddresses.map((senderEmailAddress) => {
      let senderEmailAddressData: any = {
        text: senderEmailAddress.name,
        value: senderEmailAddress.email,
        component: 'content-editor/panels/content/assignee-sender-item',
        componentAttrs: {
          model: senderEmailAddress,
          modelDisplayName: senderEmailAddress.name,
          modelDisplayEmail: senderEmailAddress.email,
          avatarSize: 'xs',
        },
      };
      senderEmailAddressData.isDisabled = !senderEmailAddress.verified;

      return senderEmailAddressData;
    });
  }

  @action
  updateSelectedEmail(value: string | null) {
    this.selectedEmail = value;
  }

  @action
  async saveAliasEmail() {
    try {
      let data = {
        app_id: this.app.id,
        id: this.app.currentAdmin.id,
        alias_sender_email_address: this.selectedEmail,
        alias_enabled: true,
        alias_name: this.args.aliasName,
      };

      await put(`/ember/admins/${this.app.currentAdmin.id}`, data);

      let adminProfile = await getAdminProfile(this.store, this.app.id, this.app.currentAdmin.id);
      this.app.currentAdmin.set('profile', adminProfile);
      this.args.onClose();
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.admin.about-component.alias.email.changes-saved'),
        'profileSaveSuccess',
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('components.admin.about-component.alias.email.not-updated'),
      );
    }
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_admin_about_profile',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Admin::AdminEmailAliasSideDrawer': typeof AdminEmailAliasSideDrawer;
    'admin/admin-email-alias-side-drawer': typeof AdminEmailAliasSideDrawer;
  }
}
