/* import __COLOCATED_TEMPLATE__ from './tier-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Charge from 'embercom/models/billing/price/charge';
import type Contract from 'embercom/models/billing/contract';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { capitalize } from '@ember/string';
import { formatPriceFromCents } from 'embercom/components/billing/usage/tooltips/format-price';
import { formatPrice } from 'embercom/components/billing/usage/tooltips/format-price';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips/tooltip-item';

interface Args {
  testIdentifier: string;
  charge: Charge;
  contract?: Contract;
  pricingMetric: PricingMetric;
  includedUsage: number;
  isUsageLessThanIncludedUsage: boolean;
}

export default class TierDetails extends Component<Args> {
  @service declare intl: IntlService;
  @service declare customerService: any;

  get tooltipRows(): TooltipRow[] {
    return [...this.tierTitle, ...this.tierInfo, ...this.overageDiscountRow];
  }

  get tierTitle() {
    return [
      {
        label: this.costHeading,
        muted: true,
        noPadding: true,
      },
    ];
  }

  get costHeading() {
    let { pricingMetric } = this.args;
    if (this.args.charge.charge_model === 'Volume') {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.current-tier',
      );
    }

    if (this.args.charge.isSalesforceContracted) {
      if (this.args.charge.isSeatMetric || pricingMetric.isResolutionsMetric) {
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.additional-usage',
        );
      }
    }

    if (this.args.charge.isSeatMetric) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.additional-seat',
      );
    }
    if (pricingMetric.isResolutionsMetric) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.cost-per-additional-unit',
        { unitName: capitalize(pricingMetric.pluralize(1)) },
      );
    }

    if (
      this.customerService.onPricing5_X &&
      pricingMetric.metric === 'messages_sent' &&
      this.args.isUsageLessThanIncludedUsage
    ) {
      return '';
    }

    if (
      pricingMetric.allHigherTiersPerUnit(
        this.args.charge.tierIdBasedOnUsage(this.args.includedUsage + 1),
      )
    ) {
      if (this.customerService.onPricing5_X) {
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.pricing-5-additional-block',
          { metric: capitalize(pricingMetric.pricing5PerAdditionalCostText()) },
        );
      } else {
        // We only want to show 'Cost per additional x' if every tier above the included is per unit
        // otherwise it does not make sense
        // https://github.com/intercom/embercom/pull/52969
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.additional-block',
          { blockSize: this.intl.formatNumber(this.args.charge.block_size) },
        );
      }
    }

    return this.intl.t(
      'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.pricing-metric',
      { pricingMetric: capitalize(pricingMetric.pluralize(2)) },
    );
  }

  get tierInfo() {
    let { pricingMetric, contract } = this.args;
    if (pricingMetric.tiers?.length > 0) {
      let highestTierId = pricingMetric.currentTier.id;
      let lowestTierId;
      if (this.args.charge.charge_model === 'Volume') {
        lowestTierId = highestTierId;
      } else {
        let lowestTierIdWithoutBaseUsageTier = this.args.charge.lowestTierWithoutBaseUsage;
        let includedUsageLimitTierId = contract
          ? this.args.charge.tierIdBasedOnUsage(this.args.includedUsage)
          : lowestTierIdWithoutBaseUsageTier;
        lowestTierId = Math.max(includedUsageLimitTierId, lowestTierIdWithoutBaseUsageTier);
      }

      let tierRows = this.tierRows(pricingMetric, lowestTierId, highestTierId);
      if (tierRows.length === 1 && tierRows[0].value && !this.customerService.onPricing5_X) {
        return [
          {
            label: `${tierRows[0].label} (${tierRows[0].value})`,
            muted: false,
            noPadding: true,
          },
        ];
      }
      return tierRows.filter(Boolean);
    } else if (this.args.charge.isSalesforceContracted) {
      let additionalUsage = this.args.charge.overageUsage;
      return [
        {
          label: additionalUsage.toString(),
          value: formatPriceFromCents(this.args.charge.overagePrice || 0, this.intl),
          muted: false,
          noPadding: true,
        },
      ];
    } else {
      return [{ label: formatPriceFromCents(pricingMetric.perUnitPrice, this.intl) }];
    }
  }

  tierRows(pricingMetric: PricingMetric, lowestTierId: string, highestTierId: string) {
    let filteredTiers = pricingMetric.tiers
      ?.filter(({ id }: any) => id <= highestTierId && id >= lowestTierId)
      ?.filter(
        ({ starting_unit, ending_unit, price }: any) =>
          !(price === 0 && starting_unit === 0 && ending_unit === 0),
      )
      ?.filter(({ starting_unit, ending_unit }) => {
        return (
          this.args.charge.calculateTierOverage(
            this.args.includedUsage,
            starting_unit,
            ending_unit,
          ) > 0
        );
      });

    return filteredTiers.map(
      ({ id, starting_unit, ending_unit, price, price_format }: any, index: number) => {
        if (pricingMetric.isSeatMetric) {
          return { label: formatPriceFromCents(pricingMetric.perUnitPrice, this.intl) };
        }
        let value;
        if (price_format === 'PerUnit') {
          if (this.customerService.onPricing5_X && pricingMetric.blockSize === 1) {
            value = formatPrice(price, this.intl);
          } else {
            value = this.intl.t('billing.summary.price-usage-breakdown-card.tier-price-per-block', {
              price: formatPrice(price * pricingMetric.blockSize, this.intl),
              blockSize: this.intl.formatNumber(pricingMetric.blockSize),
            });
          }
        } else if (price_format === 'FlatFee') {
          value = formatPrice(price, this.intl);
        }

        let label = this.intl.t(
          'billing.summary.price-usage-breakdown-card.tier-price-per-range-label',
          {
            startingUnit: this.intl.formatNumber(starting_unit),
            endingUnit: this.intl.formatNumber(ending_unit),
          },
        );
        if (pricingMetric.tiers.lastObject && id === pricingMetric.tiers.lastObject.id) {
          // Don't show upper bound for top tier because the upper bounds in Zuora are arbitrary
          label = this.intl.t('billing.summary.price-usage-breakdown-card.top-tier-price-label', {
            topTierStart: this.intl.formatNumber(starting_unit - 1),
          });
        }

        return {
          label,
          value,
          noPadding: filteredTiers.length - index > 1,
        };
      },
    );
  }

  get overageDiscountRow() {
    if (this.displayOverageDiscountRow()) {
      return [
        {
          label: this.intl.t('billing.summary.price-usage-breakdown-card.overage-tooltip.discount'),
          value: '-'.concat(
            formatPriceFromCents(
              this.args.charge.overage_discount_item?.amount_in_cents || 0,
              this.intl,
            ),
          ),
          muted: true,
          noPadding: true,
          small: true,
        },
      ];
    } else {
      return [];
    }
  }

  displayOverageDiscountRow() {
    return (
      this.args.contract &&
      this.args.charge.overage_discount_item &&
      this.args.charge.overage_discount_item.amount_in_cents > 0
    );
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::Tooltips::TierDetails': typeof TierDetails;
  }
}
