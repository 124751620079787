/* RESPONSIBLE TEAM: team-standalone */
// eslint-disable-next-line no-restricted-imports
import { getFinStandaloneService } from 'embercom/lib/container-lookup';
import EditorConfig, {
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { StandaloneMessengerConversationStartedPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class StandaloneMessengerConversationStartedBotConfig extends EditorConfig {
  private finStandaloneService: FinStandaloneService;

  constructor({ areStepPaywallsActive }: { areStepPaywallsActive: boolean }) {
    super({ areStepPaywallsActive });
    this.finStandaloneService = getFinStandaloneService();
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new StandaloneMessengerConversationStartedPathConfig({
      path,
      editorState,
      isZendeskConfigured: this.isZendeskConfigured,
      isSalesforceConfigured: this.isSalesforceConfigured,
    });
  }

  get isZendeskConfigured(): boolean {
    return this.finStandaloneService.zendeskConfig?.isConnected;
  }

  get isSalesforceConfigured(): boolean {
    return this.finStandaloneService.salesforceConfig?.isConnected;
  }

  get supportsConversationChannelTargeting(): boolean {
    return false;
  }

  get supportsSideSheet(): boolean {
    return false;
  }
}
