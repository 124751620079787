/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, { attr } from '@ember-data/model';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import {
  IMPORT_SOURCE_STATUSES,
  IMPORT_SOURCE_IN_PROGRESS_STATUSES,
} from 'embercom/lib/knowledge-hub/constants';
import { fragment } from 'ember-data-model-fragments/attributes';
import type ImportSourceContext from './import-source-context';

export enum SourceType {
  Confluence = 0,
  Guru = 1,
  Notion = 2,
  Web = 3,
  Zendesk = 4,
  Salesforce = 6,
}

export const SourceTypeName = {
  [SourceType.Confluence]: 'confluence',
  [SourceType.Guru]: 'guru',
  [SourceType.Notion]: 'notion',
  [SourceType.Web]: 'web',
  [SourceType.Zendesk]: 'zendesk',
  [SourceType.Salesforce]: 'salesforce',
};

export enum SyncBehaviorType {
  Sync = 0,
  Import = 1,
}

export default class ImportSource extends Model {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @attr('number') declare sourceType: SourceType;
  @attr('number') declare lastSuccessfulJobId: number;
  @attr('string') declare lastSuccessfulJobDate: string;
  @attr('number') declare importedEntityCount: number;
  @attr('string') declare status: string;
  @attr('number') declare syncBehavior: SyncBehaviorType;
  @fragment('import-service/import-source-context') declare context: ImportSourceContext;
  @attr('boolean') declare hasCredentials: boolean;

  // TODO: Move the attributes below to the context fragment
  @attr('string') declare baseUrl: string;
  @attr('string') declare spaceId: string;
  @attr('array', { defaultValue: () => [] }) declare denyFolderIds: string[];

  // Confluence properties
  @attr('string') declare spaceName?: string;
  @attr('string') declare siteId?: string;

  // Guru properties
  @attr('string') declare collectionId?: string;
  @attr('string') declare collectionName?: string;

  // Salesforce properties
  @attr('string') declare connectionId?: string;
  @attr('string') declare connectionName?: string;

  @attr('string') declare email: string;
  @attr('string') declare authToken: string;

  get isInProgress(): boolean {
    return IMPORT_SOURCE_IN_PROGRESS_STATUSES.includes(this.status);
  }

  get isFailed(): boolean {
    return this.status === IMPORT_SOURCE_STATUSES.failed;
  }

  get isUnauthorized(): boolean {
    return this.context?.unauthorized || false;
  }

  get isImport(): boolean {
    return this.syncBehavior === SyncBehaviorType.Import;
  }

  get isSync(): boolean {
    return this.syncBehavior === SyncBehaviorType.Sync;
  }

  get emptySource(): boolean {
    if (!!this.lastSuccessfulJobId || this.importedEntityCount > 0) {
      return false;
    }

    switch (this.sourceType) {
      case SourceType.Confluence:
        return !this.siteId && !this.spaceId;
      case SourceType.Guru:
        return !this.context?.collectionName;
      case SourceType.Notion:
        return !this.context?.workspaceName;
    }
    return true;
  }

  get sourceName(): string {
    switch (this.sourceType) {
      case SourceType.Confluence:
        return 'Confluence space'; // eslint-disable-line @intercom/intercom/no-bare-strings
      case SourceType.Guru:
        return 'Guru collection'; // eslint-disable-line @intercom/intercom/no-bare-strings
      case SourceType.Notion:
        return 'Notion space'; // eslint-disable-line @intercom/intercom/no-bare-strings
    }
    return '';
  }

  // TODO: Move this to the context fragment after moving the attributes there
  get name(): string {
    return this.spaceName || this.collectionName || this.context?.workspaceName;
  }

  @action async triggerResync() {
    try {
      await this.knowledgeHubService.startImport(this.id);
      this.notificationsService.notifyConfirmation(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-start'),
      );
    } catch (error) {
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyWarning(
          this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-already-running'),
        );
        return;
      }
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-error'),
      );
    }
  }

  @action async triggerImport({ notify = true } = {}) {
    try {
      await this.knowledgeHubService.import(this.id);
      if (notify) {
        this.knowledgeHubService.notifySourceStateChangeConfirmation(
          this.intl.t('knowledge-hub.filterable-list.sources.actions.import-source-processing'),
          `${this.id}`,
        );
      }
    } catch (error) {
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyWarning(
          this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-already-running'),
        );
        return;
      }
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.import-source-error'),
      );
    }
  }

  @action async remove({ notify = true } = {}) {
    try {
      await this.knowledgeHubService.removeImportSource(this);
      if (notify) {
        this.knowledgeHubService.notifySourceStateChangeConfirmation(
          this.intl.t('knowledge-hub.filterable-list.sources.actions.remove-done'),
          `${this.id}`,
        );
      }
    } catch (error) {
      if (notify) {
        let errorMessage =
          error.jqXHR.status === 409
            ? this.intl.t('knowledge-hub.filterable-list.sources.actions.delete-conflict-error')
            : this.intl.t('knowledge-hub.filterable-list.sources.actions.remove-error');
        this.notificationsService.notifyError(errorMessage);
      }
    }
  }
}
