/* import __COLOCATED_TEMPLATE__ from './accordion-content.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import type {
  InstallationMethod,
  InstallationMethodSelection,
} from 'embercom/components/installation-new/constants';
import type SdkAppService from 'embercom/services/sdk-app-service';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  installationMethods: InstallationMethodSelection[];
  selectedInstallationMethod: InstallationMethod;
  onSelectInstallationMethod: (installationMethod: InstallationMethod) => void;
}

export default class AccordionContent extends Component<Args> {
  @service declare sdkAppService: SdkAppService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::PushNotifications::AccordionContent': typeof AccordionContent;
    'installation-new/mobile/push-notifications/accordion-content': typeof AccordionContent;
  }
}
