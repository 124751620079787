/* import __COLOCATED_TEMPLATE__ from './wait-for-callback.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type WaitForCallback from 'embercom/models/operator/visual-builder/step/wait-for-callback';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { post } from 'embercom/lib/ajax';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type ActionOutputParameterDescriptor from 'embercom/models/workflow-connector/action-output-parameter-descriptor';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Type } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { iconByType } from '../../helpers/template-helpers';

interface Arguments {
  step: WaitForCallback;
  readOnly: boolean;
  editorState: EditorState;
}

export type AsyncCallbackRequestAttribute = {
  name: string;
  sourcePath: string;
  dataType: string;
  exampleValue: string;
};

export default class WaitForCallbackComponent extends Component<Arguments> {
  @service intl!: IntlService;
  @service appService: any;
  @service store: any;

  @tracked asyncCallbackRequestAttributesAsArray: AsyncCallbackRequestAttribute[] = [];
  @tracked savedExampleRequest: string;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    this.savedExampleRequest = this.args.step.exampleRequest;
    if (this.args.step.asyncCallbackOutputParameterDescriptor) {
      this.generateDisplayableExampleRequests(
        this.args.step.asyncCallbackOutputParameterDescriptor,
      );
    }
  }

  get title() {
    return this.args.step.name
      ? this.intl.t('operator.workflows.visual-builder.wait-for-callback.title-with-name', {
          name: this.args.step.name,
          htmlSafe: true,
        })
      : this.intl.t('operator.workflows.visual-builder.wait-for-callback.title', {
          htmlSafe: true,
        });
  }

  get hasExampleRequest() {
    return this.asyncCallbackRequestAttributesAsArray.length > 0;
  }

  get exampleRequestAttributes() {
    return this.asyncCallbackRequestAttributesAsArray.map((attribute: any) => {
      return {
        name: attribute.name,
        icon: iconByType(attribute.dataType),
      };
    });
  }

  get shouldUpdateDescriptor() {
    return this.args.step.exampleRequest !== this.savedExampleRequest;
  }

  get isFetchingExampleRequests() {
    return taskFor(this.getExampleRequestsAsDescriptor).isRunning;
  }

  setDefaultNameForStep() {
    if (!this.args.step.name) {
      this.args.step.name = this.intl.t(
        'operator.workflows.visual-builder.wait-for-callback.default-name',
      );
    }
  }

  generateDisplayableExampleRequests(
    descriptor: ActionOutputParameterDescriptor,
    asyncCallbackRequestAttributes: AsyncCallbackRequestAttribute[] = [],
  ) {
    if (descriptor.type !== Type.object && descriptor.type !== Type.array) {
      let requestAttribute = {
        name: descriptor.name,
        exampleValue: descriptor.exampleValue,
        dataType: descriptor.type,
        sourcePath: descriptor.sourcePath,
      } as AsyncCallbackRequestAttribute;
      asyncCallbackRequestAttributes.push(requestAttribute);
    }

    descriptor.childDescriptors?.forEach((child: any) => {
      this.generateDisplayableExampleRequests(child, asyncCallbackRequestAttributes);
    });
    this.asyncCallbackRequestAttributesAsArray = asyncCallbackRequestAttributes;
  }

  createApplicableLocalVariablesForStep(descriptor: ActionOutputParameterDescriptor) {
    let identifier = this.args.step.asyncCallbackAttributeDescriptorIdentifier;
    if (identifier) {
      let savedRootLocalVariable = this.store.peekRecord(
        'operator/visual-builder/attribute-descriptor',
        identifier,
      );
      this.args.editorState.deleteLocalVariable(savedRootLocalVariable);
    }
    // Set the name of the root local variable to the step name
    this.args.step.asyncCallbackOutputParameterDescriptor.name = this.args.step.name;
    let rootLocalVariable = this.args.editorState.copyActionLocalVariable(
      descriptor,
      this.args.step.name,
    ) as AttributeDescriptor;
    this.args.step.asyncCallbackAttributeDescriptorIdentifier = rootLocalVariable.id;
  }

  @dropTask
  *getExampleRequestsAsDescriptor(): TaskGenerator<void> {
    try {
      yield post(`/ember/operator_workflows/generate_mappable_object_for_request_body`, {
        app_id: this.appService.app.id,
        example_request: this.args.step.exampleRequest,
      }).then((response: any) => {
        let normalizedResponse = this.store.normalize(
          'workflow-connector/action-output-parameter-descriptor',
          response,
        ).data.attributes;
        let descriptor = this.store.createFragment(
          'workflow-connector/action-output-parameter-descriptor',
          normalizedResponse,
        );
        this.args.step.asyncCallbackOutputParameterDescriptor = descriptor;
      });

      this.asyncCallbackRequestAttributesAsArray = [];
      if (!this.args.step.asyncCallbackOutputParameterDescriptor) {
        return;
      }
      this.generateDisplayableExampleRequests(
        this.args.step.asyncCallbackOutputParameterDescriptor,
      );

      this.setDefaultNameForStep();
      this.createApplicableLocalVariablesForStep(
        this.args.step.asyncCallbackOutputParameterDescriptor,
      );
      this.savedExampleRequest = this.args.step.exampleRequest;
    } catch (exception) {
      console.error('Error fetching response fields', exception);
    }
  }

  @action
  async fetchExampleRequestsAsDescriptor(): Promise<void> {
    if (
      (!this.args.step.asyncCallbackAttributeDescriptorIdentifier &&
        this.args.step.exampleRequest) ||
      this.shouldUpdateDescriptor
    ) {
      await taskFor(this.getExampleRequestsAsDescriptor).perform();
    }
  }

  @action
  onSideSheetClose() {
    this.fetchExampleRequestsAsDescriptor();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::WaitForCallback': typeof WaitForCallbackComponent;
    'workflows/graph-editor/node-items/steps/wait-for-callback': typeof WaitForCallbackComponent;
  }
}
