/* import __COLOCATED_TEMPLATE__ from './hmac-generation.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Clipboard from 'clipboard';
import type IntlService from 'ember-intl/services/intl';
import { type InstallationMethod, PROGRAMMING_LANGUAGES } from './constants';
import { PROGRAMMING_LANGUAGE_LIST } from './web/idv-setup/accordion-content';
import type ClipboardService from 'embercom/services/clipboard-service';

interface Args {
  selectedAppType: HMAC_APP_TYPES;
  onSelectAppType: (appType: HMAC_APP_TYPES) => void;
  hideAppTypes: boolean;
  selectedUserIdentifier: USER_IDENTIFIER_VALUES;
  onSelectUserIdentifier: (identifier: USER_IDENTIFIER_VALUES) => void;
  selectedProgrammingLanguage: PROGRAMMING_LANGUAGES;
  onSelectProgrammingLanguage: (language: PROGRAMMING_LANGUAGES) => void;
  selectedInstallationMethod: InstallationMethod;
  apiSecret: string;
}
interface Signature {
  Args: Args;
  Element: any;
  Blocks: any;
}

export enum USER_IDENTIFIER_VALUES {
  ID = 'id',
  EMAIL = 'email',
}

export enum HMAC_APP_TYPES {
  BASIC_JAVASCRIPT = 'basic-javascript',
  SINGLE_PAGE_APP = 'spa',
  RAILS_GEM = 'rails-gem',
}

export default class HmacGeneration extends Component<Signature> {
  @tracked showSecret = false;

  @service declare clipboardService: ClipboardService;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  clipboard?: Clipboard;
  programmingLanguages = PROGRAMMING_LANGUAGE_LIST;

  get app() {
    return this.appService.app;
  }

  get isOtherProgrammingLanguage() {
    let otherLanguages: string[] = [PROGRAMMING_LANGUAGES.GO, PROGRAMMING_LANGUAGES.JAVA];
    return otherLanguages.includes(this.args.selectedProgrammingLanguage);
  }

  get maskedSecret() {
    if (this.args.apiSecret) {
      // Leave up to the last 4 characters visible
      return this.args.apiSecret.replace(/.{4}(?=.)/g, '•');
    } else {
      return '';
    }
  }

  get highlightLanguage() {
    if (this.args.selectedProgrammingLanguage === PROGRAMMING_LANGUAGES.RAILS) {
      return 'ruby';
    } else if (this.args.selectedProgrammingLanguage === PROGRAMMING_LANGUAGES.DJANGO) {
      return 'python';
    } else if (this.args.selectedProgrammingLanguage === PROGRAMMING_LANGUAGES.NODE) {
      return 'javascript';
    } else {
      return this.args.selectedProgrammingLanguage;
    }
  }

  get userIdentifiers() {
    return [
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.identification-formats.user-id',
        ),
        value: USER_IDENTIFIER_VALUES.ID,
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.identification-formats.user-email',
        ),
        value: USER_IDENTIFIER_VALUES.EMAIL,
      },
    ];
  }

  get appTypes() {
    return [
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.basic-javascript',
        ),
        value: HMAC_APP_TYPES.BASIC_JAVASCRIPT,
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.single-page-app',
        ),
        value: HMAC_APP_TYPES.SINGLE_PAGE_APP,
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.rails-gem',
        ),
        value: HMAC_APP_TYPES.RAILS_GEM,
      },
    ];
  }

  get hideProgrammingLanguages() {
    return this.args.selectedAppType === 'rails-gem';
  }

  @action
  onCopySecret() {
    let onCopySuccess = () => {
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.installation.installation.new.secret-copied'),
      );
      this.clipboard?.destroy();
    };

    let onCopyError = () => {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.settings.installation.installation.new.code-copying-error'),
      );
      this.clipboard?.destroy();
    };

    this.clipboard = this.clipboardService.createClipboard(
      '[data-intercom-target="copy-secret-button"]',
      this.args.apiSecret,
      onCopySuccess,
      onCopyError,
    );
  }

  @action toggleSecret(value: boolean) {
    this.showSecret = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::HmacGeneration': typeof HmacGeneration;
    'installation-new/hmac-generation': typeof HmacGeneration;
  }
}
