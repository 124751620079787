/* import __COLOCATED_TEMPLATE__ from './installation-platform-selector.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';

type PlatformSelectorIds = 'web' | 'mobile';

interface Args {
  installationPlatforms: InstallationPlatformSelectorOptions[];
  selectedInstallationPlatform: PlatformSelectorIds;
  onSelectInstallationPlatform: (platform: PlatformSelectorIds) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export type InstallationPlatformSelectorOptions = {
  id: PlatformSelectorIds;
  title: string;
  icon: InterfaceIconName;
  installationStatuses: InstallationPlatformStatus[];
};

export type InstallationPlatformStatus = {
  color: string;
  text: string;
  tooltipText?: string;
};

export default class InstallationPlatformSelector extends Component<Signature> {
  @service declare intl: IntlService;
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::InstallationPlatformSelector': typeof InstallationPlatformSelector;
    'installation-new/installation-platform-selector': typeof InstallationPlatformSelector;
  }
}
