/* RESPONSIBLE TEAM: team-purchase-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class BillingManageSubsription extends Route {
  @service intercomEventService: $TSFixMe;
  @service appService: $TSFixMe;
  @service router: $TSFixMe;
  @service customerService: $TSFixMe;
  @service store: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  beforeModel() {
    // Only accessible for Pricing 5 Self Serve Annual Customers
    if (
      !this.app.onPricing5 ||
      !this.app.hasActiveSubscription ||
      !this.customerService.isSelfServeAnnualCustomer
    ) {
      return this.router.transitionTo('apps.app.settings.subscription.billing.index');
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'billing_manage_subscription_page',
    });
  }

  model(_: any, transition: any) {
    return hash({
      section: transition.to.queryParams.section,
      planId: transition.to.queryParams.planid,
      featureName: transition.to.queryParams.featurename,
      contract: this.store.findRecord('billing/contract', this.app.id),
      additionalCoreSeatsNumber: transition.to.queryParams.additionalCoreSeatsNumber
        ? parseInt(transition.to.queryParams.additionalCoreSeatsNumber, 10)
        : null,
    });
  }
}
