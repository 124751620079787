/* RESPONSIBLE TEAM: team-actions */
import ajax from 'embercom/lib/ajax';

export function needsAcceptance(appId) {
  return ajax({
    url: `/ember/developer_hub/terms_of_service_acceptance?app_id=${appId}`,
    type: 'GET',
  });
}

export function acceptGuidelines(appId) {
  return ajax({
    url: '/ember/developer_hub/terms_of_service_acceptance',
    type: 'post',
    contentType: 'application/json',
    data: JSON.stringify({
      app_id: appId,
    }),
  });
}
