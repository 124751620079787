/* import __COLOCATED_TEMPLATE__ from './social-channels.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { type TabItem } from '../common/standard-base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface Args {
  model: any;
  tab: string;
  changeTab: (tab: string) => void;
}

export default class SocialChannels extends Component<Args> {
  @service declare intercomEventService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.args.changeTab(this.args.tab ? this.args.tab : 'facebook');
  }

  get selectedTab() {
    return this.args.tab;
  }

  get tabs(): Array<TabItem & { canAccess: boolean }> {
    return [
      {
        label: 'new-settings.channels.social-channels.tabs.facebook',
        value: 'facebook',
        canAccess: true,
      },
      {
        label: 'new-settings.channels.social-channels.tabs.instagram',
        value: 'instagram',
        canAccess: true,
      },
    ];
  }

  @action
  changeTab(tab: string) {
    this.args.changeTab(tab);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'tab_clicked',
      place: 'settings_channels_social_channels',
      section: 'tab_clicked',
      tab,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::SocialChannels': typeof SocialChannels;
  }
}
