/* RESPONSIBLE TEAM: team-workflows */
import type Note from 'embercom/models/operator/visual-builder/step/note';
import StepConfig, { type StepConfigParams } from '../step';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
// eslint-disable-next-line no-restricted-imports
import containerLookup from 'embercom/lib/container-lookup';
import type AttributeDescriptor from 'embercom/models/workflow-connector/attribute-descriptor';

export default class NoteConfiguration extends StepConfig {
  declare step: Note;
  protected attributeService: any;
  contentEditorService: any;

  constructor(
    params: Omit<StepConfigParams, 'component'> & { component?: string; attributeService: any },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/note',
      ...params,
    });
    this.attributeService = params.attributeService;
    this.contentEditorService = containerLookup('service:content-editor-service');
  }

  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
      selectedEvents: this.selectedEvents,
      localAttributes: this.step.editorConfig?.localAttributeGroupList,
      allLocalAttributes: this.step.editorConfig?.allLocalAttributeGroupList,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }
}

export class TargetContextAwareNoteConfiguration extends NoteConfiguration {
  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributeAllowlist: this.step.editorConfig?.runtimeMatchingTargetingAttributes,
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
      includeCustomActionAttributes: true,
      selectedEvents: this.selectedEvents,
    });
  }
}

export class WebhookReceivedNoteConfiguration extends NoteConfiguration {
  protected webhookReceivedAttributes: Array<AttributeDescriptor>;

  constructor(
    params: Omit<StepConfigParams, 'component'> & {
      component?: string;
      attributeService: any;
      webhookReceivedAttributes: Array<AttributeDescriptor>;
    },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/note',
      ...params,
    });
    this.webhookReceivedAttributes = params.webhookReceivedAttributes;
  }

  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributeAllowlist: this.step.editorConfig?.runtimeMatchingTargetingAttributes,
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
      selectedEvents: this.selectedEvents,
      webhookReceivedAttributes: this.webhookReceivedAttributes,
    });
  }
}
