/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  name: attr('string'),
  idCode: attr('string'),
  connectable: attr('boolean'),
  nonConnectableReason: attr('string'),
});
