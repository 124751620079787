/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RouteRegexes from 'embercom/lib/route-regexes';
import type Router from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';

export default class Billing extends Controller {
  @service declare router: Router;

  queryParams = ['addressModalIsOpen', 'creditCardModalIsOpen'];

  @tracked addressModalIsOpen: boolean | null = null;
  @tracked creditCardModalIsOpen: boolean | null = null;

  get selectedTab(): string | undefined {
    if (
      RouteRegexes.newSettings.billing.summary.test(this.router.currentRouteName) ||
      RouteRegexes.newSettings.billing.details.test(this.router.currentRouteName)
    ) {
      return 'summary';
    } else if (
      RouteRegexes.newSettings.billing.currentBillingPeriodCharges.test(
        this.router.currentRouteName,
      )
    ) {
      return 'current-billing-period-charges';
    } else if (RouteRegexes.newSettings.billing.invoices.test(this.router.currentRouteName)) {
      return 'invoices';
    } else if (RouteRegexes.newSettings.billing.manageUsage.test(this.router.currentRouteName)) {
      return 'manage-usage';
    } else if (
      RouteRegexes.newSettings.billing.manageSubscription.test(this.router.currentRouteName)
    ) {
      return 'manage-subscription';
    } else if (RouteRegexes.newSettings.billing.migration.test(this.router.currentRouteName)) {
      return 'migration';
    } else if (RouteRegexes.newSettings.billing.settings.test(this.router.currentRouteName)) {
      return 'settings';
    } else if (
      RouteRegexes.newSettings.billing.updateSubscription.test(this.router.currentRouteName)
    ) {
      return 'update-subscription';
    } else {
      return undefined;
    }
  }

  @action
  setSelectedTab(tab: string) {
    if (tab === 'invoices') {
      this.router.replaceWith('apps.app.settings.subscription.billing.invoices', {
        queryParams: { addressModalIsOpen: null, creditCardModalIsOpen: null },
      });
    } else if (tab === 'manage-usage') {
      this.router.replaceWith('apps.app.settings.subscription.billing.manage-usage', {
        queryParams: { addressModalIsOpen: null, creditCardModalIsOpen: null },
      });
    } else if (tab === 'migration') {
      this.router.replaceWith('apps.app.settings.subscription.billing.migration', {
        queryParams: { addressModalIsOpen: null, creditCardModalIsOpen: null },
      });
    } else if (tab === 'manage-subscription') {
      this.router.replaceWith('apps.app.settings.subscription.billing.manage-subscription', {
        queryParams: { addressModalIsOpen: null, creditCardModalIsOpen: null },
      });
    } else if (tab === 'settings') {
      this.router.replaceWith('apps.app.settings.subscription.billing.settings', {
        queryParams: { addressModalIsOpen: null, creditCardModalIsOpen: null },
      });
    } else if (tab === 'update-subscription') {
      this.router.replaceWith('apps.app.settings.subscription.billing.update-subscription', {
        queryParams: { addressModalIsOpen: null, creditCardModalIsOpen: null },
      });
    } else if (tab === 'current-billing-period-charges') {
      this.router.replaceWith(
        'apps.app.settings.subscription.billing.current-billing-period-charges',
        {
          queryParams: { addressModalIsOpen: null, creditCardModalIsOpen: null },
        },
      );
    } else {
      this.router.replaceWith('apps.app.settings.subscription.billing.index', {
        queryParams: { addressModalIsOpen: null, creditCardModalIsOpen: null },
      });
    }
  }

  @action
  closeAddressModal() {
    this.addressModalIsOpen = false;
  }

  @action
  closeCreditCardModal() {
    this.creditCardModalIsOpen = false;
  }
}
