/* import __COLOCATED_TEMPLATE__ from './live-chat-connect.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { tracked } from '@glimmer/tracking';
import { restartableTask, dropTask } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import type { TaskGenerator } from 'ember-concurrency';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type NotificationsService from 'embercom/services/notifications-service';
import { taskFor } from 'ember-concurrency-ts';
interface Args {}

export default class StandaloneSalesforceLiveChatConnect extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare intl: IntlService;

  @tracked hostname: string | undefined;
  @tracked deploymentId: string | undefined;
  @tracked buttonId: string | undefined;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.salesforceConfig.isPendingSetup) {
      return;
    }

    let { hostname, deploymentId, buttonId } = this.finStandaloneService.salesforceLiveChatConfig;
    this.hostname = hostname || undefined;
    this.deploymentId = deploymentId || undefined;
    this.buttonId = buttonId || undefined;
  }

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig;
  }

  get deployments(): { text: string; value: string }[] {
    return this.finStandaloneService.salesforceLiveChatSetupData.deployments.map((deployment) => ({
      text: deployment.name,
      value: deployment.id,
    }));
  }

  get buttons(): { text: string; value: string }[] {
    return this.finStandaloneService.salesforceLiveChatSetupData.buttons.map((button) => ({
      text: button.name,
      value: button.id,
    }));
  }

  get isFormValid(): boolean {
    return !!this.hostname && !!this.deploymentId && !!this.buttonId;
  }

  get isSaveRunning(): boolean {
    return taskFor(this.save).isRunning;
  }

  get isRevokeRunning(): boolean {
    return taskFor(this.revoke).isRunning;
  }

  @restartableTask
  *save(): TaskGenerator<void> {
    try {
      Object.assign(this.finStandaloneService.salesforceLiveChatConfig, {
        hostname: this.hostname,
        deploymentId: this.deploymentId,
        buttonId: this.buttonId,
        organizationId: this.finStandaloneService.salesforceConfig.organisationId,
      });
      yield this.finStandaloneService.salesforceLiveChatConfig.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.salesforce.setup.live-chat-connect.save-success'),
      );
    } catch (error) {
      this.finStandaloneService.salesforceLiveChatConfig.rollbackAttributes();
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('standalone.salesforce.setup.live-chat-connect.save-error'),
      });
    }
  }

  @dropTask
  *revoke(): TaskGenerator<void> {
    try {
      yield this.finStandaloneService.salesforceLiveChatConfig.revoke();
      this.hostname = undefined;
      this.deploymentId = undefined;
      this.buttonId = undefined;
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.salesforce.setup.live-chat-connect.revoke-success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('standalone.salesforce.setup.live-chat-connect.revoke-error'),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::LiveChatConnect': typeof StandaloneSalesforceLiveChatConnect;
    'standalone/salesforce/setup/live-chat-connect': typeof StandaloneSalesforceLiveChatConnect;
  }
}
