/* import __COLOCATED_TEMPLATE__ from './start-conversation-button.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type StartConversationButtonSettings from 'embercom/models/messenger-settings/start-conversation-button-text';
import type Intl from 'embercom/services/intl';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';

interface Args {
  value:
    | StartConversationButtonSettings['teammateStartConversationButtonText']
    | StartConversationButtonSettings['finStartConversationButtonText'];
  inboundConversationsDisabled: boolean;
}

export default class StartConversationButton extends Component<Args> {
  @service declare router: any;
  @service declare appService: any;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare intl: Intl;

  get inboundVolumeSettingsUrl() {
    return this.router.urlFor('apps.app.settings.channels.messenger.general', this.appService.app, {
      queryParams: { section: 'inbound-volume' },
    });
  }

  get isDisabled() {
    return this.args.inboundConversationsDisabled;
  }

  get isUserType() {
    return this.messengerSettingsTargetUserProvider.isUserType;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Content::StartConversationButton': typeof StartConversationButton;
  }
}
