/* import __COLOCATED_TEMPLATE__ from './accordion-content.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import {
  INSTALLATION_METHOD,
  type InstallationMethodSelection,
  type InstallationMethod,
} from 'embercom/components/installation-new/constants';
import { inject as service } from '@ember/service';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

interface Args {
  installationMethods: InstallationMethodSelection[];
  selectedInstallationMethod: InstallationMethod;
  onSelectInstallationMethod: (installationMethod: InstallationMethod) => void;
}

interface Signature {
  Args: Args;
  Element: any;
}

export default class AccordionContent extends Component<Signature> {
  @service declare intl: IntlService;

  get installationMethods() {
    return [
      {
        id: INSTALLATION_METHOD.IOS,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.ios-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-code.svg'),
        instructionsTitle: this.intl.t('apps.app.settings.installation.installation.new.ios.title'),
      },
      {
        id: INSTALLATION_METHOD.ANDROID,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.android-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-code.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.android.title',
        ),
      },
    ];
  }

  get selectedInstallationMethodTitle() {
    return this.args.installationMethods.find(
      (installationMethod) => installationMethod.id === this.args.selectedInstallationMethod,
    )?.instructionsTitle;
  }

  get selectedInstallationMethodSubTitle() {
    return this.args.installationMethods.find(
      (installationMethod) => installationMethod.id === this.args.selectedInstallationMethod,
    )?.instructionsSubTitle;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::AccordionContent': typeof AccordionContent;
    'installation-new/mobile/initial-setup/accordion-content': typeof AccordionContent;
  }
}
