/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { resolutionBotBillingFeature } from 'embercom/components/operator/answer-bot/billing-helper';

export default class Editor extends Component {
  @service appService;
  @service store;
  @service outboundHomeService;
  @service intl;

  @tracked operatorQualificationPanelIsEnabled = false;
  @tracked isReplyPopUpOpen = false;

  constructor() {
    super(...arguments);
    this.fetchWhetherLeadQualificationPanelEnabled.perform();
  }

  @task({ drop: true })
  *fetchWhetherLeadQualificationPanelEnabled() {
    let settings = yield this.store.findRecord('operator-settings/task-bots', this.app.get('id'));
    this.operatorQualificationPanelIsEnabled = settings.get('qualifyLeads.enabled');
  }

  get app() {
    return this.appService.app;
  }

  get shouldHideDropdownOpener() {
    return (
      (this.args.isEmailCollectorReply && this.args.followUpActions?.followUpReply) ||
      this.args.isDisabled
    );
  }

  get dropdownActions() {
    let actions = [
      this.followUpReplyFollowUpAction,
      ...(this.shouldShowLeadQualificationAction ? [this.leadQualificationFollowUpAction] : []),
      this.answerBotFollowUpAction,
    ];
    return actions;
  }

  get shouldShowLeadQualificationAction() {
    return !(this.app.canUseWorkflowsNetNewExperience && this.app.canUseFeature('bot_workflows'));
  }

  get answerBotFollowUpAction() {
    return {
      text: this.intl.t('outbound.content-editor.panels.answer-bot.let-resolution-bot-respond'),
      icon: 'fin',
      value: 'answer-bot',
      isDisabled: this.args.isEmailCollectorReply || this.args.followUpActions.answerBotEnabled,
      feature: resolutionBotBillingFeature(this.app),
      component: 'content-editor/panels/content/follow-up-actions/dropdown-item',
    };
  }

  get followUpReplyFollowUpAction() {
    return {
      text: 'Send auto reply',
      icon: 'chat-bubble',
      value: 'send-follow-up-reply',
      isDisabled: !this.args.isEmailCollectorReply || this.args.followUpActions.followUpReply,
    };
  }

  get leadQualificationFollowUpAction() {
    return {
      text: "Trigger Operator's qualification task bot",
      icon: 'operator',
      value: 'lead-qualification',
      isDisabled:
        this.args.isEmailCollectorReply ||
        this.args.followUpActions.leadQualificationEnabled ||
        !this.app.canUseOperatorQualifications ||
        !this.operatorQualificationPanelIsEnabled,
      feature: 'operator_qualification',
      component: 'content-editor/panels/content/follow-up-actions/dropdown-item',
      operatorQualificationPanelIsDisabled: !this.operatorQualificationPanelIsEnabled,
      isEmailCollectorReply: this.args.isEmailCollectorReply,
    };
  }

  @action
  selectFollowUpAction(followUpAction) {
    switch (followUpAction) {
      case 'answer-bot':
        this.args.followUpActions.answerBotEnabled = true;
        break;
      case 'send-follow-up-reply':
        this.args.followUpActions.followUpReply = 'Thanks';
        this.isReplyPopUpOpen = true;
        break;
      case 'lead-qualification':
        this.args.followUpActions.leadQualificationEnabled = true;
        break;
    }
  }
}
