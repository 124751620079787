/* import __COLOCATED_TEMPLATE__ from './move-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import Folder from 'embercom/models/content-service/folder';
import { TreeItem, Tree, type TreeParent, DefaultTreeSettings } from 'embercom/objects/tree-list';
import {
  FolderTreeSettings,
  MAX_DEPTH,
  addTreeItemToParent,
  canDropItem,
  canMoveObject,
  orderedRootFolders,
} from '../tree-helper';
import { type KnowledgeHubItem } from 'embercom/models/content-service/knowledge-hub-item';
import type Model from '@ember-data/model';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { EntityType } from 'embercom/models/data/entity-types';
import type RouterService from '@ember/routing/router-service';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';

interface Args {
  treeItemToMove?: TreeItem<KnowledgeHubItem & Model>;
  objectToMove?: KnowledgeHubItem;
  onClose: Function;
  position: string;
  isBulkAction?: boolean;
  isBulkActionRunning?: boolean;
  performBulkMove?: (destinationFolderId: number | string) => void;
  selectedCount?: number;
}

export default class MoveModal extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare router: RouterService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;

  @tracked tree: Tree;
  @tracked selectedTreeItem?: TreeItem<Folder>;
  @tracked foldersLoaded = false;
  @tracked currentParentFolder?: Folder;
  declare objectToMove: KnowledgeHubItem;
  treeItemToMove?: TreeItem<KnowledgeHubItem & Model>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (args.isBulkAction) {
      this.foldersLoaded = true;
    } else {
      this.objectToMove = this.args.treeItemToMove?.dataObject ?? this.args.objectToMove!;
      if (!this.objectToMove) {
        throw new Error('Must provide either a tree item or an object to move');
      }
      this.ensureFoldersAreLoaded();
      this.treeItemToMove = (this.args.treeItemToMove ??
        this.args.objectToMove?.treeItem) as TreeItem<KnowledgeHubItem & Model>;
    }

    this.tree = new Tree(
      [],
      {
        canDragItem: (_) => false,
        canDropItem: (_) => false,
        canSelectItem: (item) => {
          return this.canSelectItem(item);
        },
        didSelectItem: this.setSelectedTreeItem,
      },
      {
        ...DefaultTreeSettings,
        ...FolderTreeSettings,
      },
    );
    this.loadFolderTree();
  }

  async ensureFoldersAreLoaded() {
    this.currentParentFolder = await this.objectToMove.getParentMaybeFindAll();
    this.foldersLoaded = true;
  }

  @action
  setSelectedTreeItem(item: TreeItem<Folder>) {
    if (this.selectedTreeItem) {
      this.selectedTreeItem.isActive = false;
    }
    this.selectedTreeItem = item;
    this.selectedTreeItem.isActive = true;
  }

  canSelectItem(item: TreeItem<Folder>) {
    return this.args.isBulkAction ? true : this.validateTargetItem(item);
  }

  get targetFolder() {
    return this.selectedTreeItem?.dataObject;
  }

  get app() {
    return this.appService.app;
  }

  get isValid(): boolean {
    if (!this.selectedTreeItem) {
      return false;
    }
    if (this.args.isBulkAction) {
      return true;
    }
    return this.validateTargetItem(this.selectedTreeItem);
  }

  get maxDepth() {
    return MAX_DEPTH;
  }

  get modalHeaderTitle() {
    let title;
    if (this.args.isBulkAction) {
      title = this.intl.t('knowledge-hub.folders.folders-menu.move-count', {
        count: this.intl.formatNumber(this.args.selectedCount),
      });
    } else {
      title = this.intl.t(`knowledge-hub.folders.folders-menu.move-modal.title`, {
        itemName: this.objectToMove.displayName,
      });
    }
    return title;
  }

  validateTargetItem(item?: TreeItem<Folder>) {
    let targetFolder = item?.dataObject;
    let newTreeParent = this.newTreeParentFromItem(item);
    if (targetFolder === this.currentParentFolder || targetFolder === this.objectToMove) {
      return false;
    }
    if (this.treeItemToMove && newTreeParent) {
      return canDropItem(this.treeItemToMove, newTreeParent);
    }
    if (this.objectToMove) {
      return canMoveObject({ draggingObject: this.objectToMove, targetParent: targetFolder });
    }
    return false;
  }

  @action cancel() {
    this.args.onClose();
  }

  @action async loadFolderTree() {
    let rootItem = this.createRootItem();
    let children: TreeItem<Folder>[] = [];
    let orderedFolders = await orderedRootFolders({
      knowledgeHubService: this.knowledgeHubService,
    });
    for (let folder of orderedFolders) {
      if (this.args.isBulkAction) {
        if (!folder.isSynced) {
          children.push(this.createItem(rootItem, folder));
        }
      } else {
        children.push(this.createItem(rootItem, folder));
      }
    }

    rootItem.children = children;
  }

  private createRootItem() {
    let rootItem = this.createTreeItem(this.tree, undefined);
    rootItem.isExpanded = true;
    this.tree.children = [rootItem];
    return rootItem;
  }

  private createItem(parent: TreeParent, folder: Folder): TreeItem<Folder> {
    let treeItem = this.createTreeItem(parent, folder);
    if (this.objectFolderEquals({ otherFolder: folder })) {
      treeItem.isExpanded = true;
    }
    if (folder.hasChildren && folder !== this.objectToMove && folder.isEditable) {
      // Add children except we don't want the user to try to move a folder to its own subfolder or see synced folders
      let children: TreeItem<Folder>[] = [];
      for (let subfolder of folder.orderedSubFolders) {
        children.push(this.createItem(treeItem as TreeParent, subfolder));
      }
      treeItem.addChildren(children);
    }
    if (treeItem.isExpanded && parent instanceof TreeItem) {
      parent.isExpanded = true;
    }
    return treeItem;
  }

  private createTreeItem<T>(parent: TreeParent, folder: T) {
    let treeItem = new TreeItem<T>({
      tree: parent.tree,
      parent,
      children: [],
      isExpanded: false,
      canHaveChildren: true,
      dataObject: folder,
      component: 'knowledge-hub/folders/tree/selection-list-item',
    });
    return treeItem;
  }

  @action
  async moveItem() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    if (!this.isValid) {
      return;
    }

    if (this.args.isBulkAction && this.args.performBulkMove) {
      let newFolderId = this.targetFolder?.id ? Number(this.targetFolder.id) : 'root';
      this.args.performBulkMove(newFolderId);
    } else {
      this.performMoveItem();
      this.knowledgeHubEditorService.onContentUpdateCallback?.({
        type: 'move-folder',
        content: this.knowledgeHubEditorService.activeContentModel,
      });
    }
  }

  private async performMoveItem() {
    try {
      if (this.treeItemToMove && this.objectToMove.entityType === EntityType.ContentLibraryFolder) {
        // some specifics to make the linked list & folder tree work
        this.moveTreeItem();
        this.objectToMove.updateLocationToMatch({ treeItem: this.treeItemToMove });
      }

      await this.objectToMove.moveToFolderAndSave({ folder: this.targetFolder });
      this.knowledgeHubService.updateActiveItem();

      this.intercomEventService.trackAnalyticsEvent({
        action: 'moved',
        object: this.objectToMove.entityName ?? 'unknown',
        place: this.args.position,
      });

      this.args.onClose();
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.folders.folders-menu.move-modal.error'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'error_moving',
        object: this.objectToMove.entityName ?? 'unknown',
        place: this.args.position,
      });
      this.args.onClose();
    }
  }

  private moveTreeItem() {
    if (this.treeItemToMove) {
      let oldParent = this.treeItemToMove.parent;
      let newParent = this.newTreeParent!;
      addTreeItemToParent(this.treeItemToMove, newParent);
      this.treeItemToMove.parent = newParent;
      oldParent.children?.removeObject(this.treeItemToMove);
    }
  }

  private get newTreeParent(): TreeParent | undefined {
    return this.newTreeParentFromItem(this.selectedTreeItem);
  }

  // Returns the tree parent from the main sidebar tree, not the tree displayed in the modal
  private newTreeParentFromItem(item?: TreeItem<Folder>): TreeParent | undefined {
    let targetFolder = item?.dataObject;
    if (targetFolder?.treeItem) {
      // If the folder has an item in the main tree, use that
      return targetFolder?.treeItem;
    }
    if (item) {
      // If the item doesn't have folder or doesn't have an item in the main tree, use the root of the main tree
      return this.treeItemToMove?.tree.children.firstObject;
    }
    return undefined;
  }

  private objectFolderEquals({ otherFolder }: { otherFolder?: Folder }): boolean {
    if (!this.objectToMove) {
      return false;
    }
    if (otherFolder === this.objectToMove) {
      return true;
    }
    if (!(this.objectToMove instanceof Folder)) {
      return this.currentParentFolder === otherFolder;
    }
    return false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::Modals::MoveModal': typeof MoveModal;
    'knowledge-hub/folders/modals/move-modal': typeof MoveModal;
  }
}
