/* RESPONSIBLE TEAM: team-knowledge-interop */
import type Store from '@ember-data/store';
import { type Router } from '@ember/routing';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

export default class View extends Route {
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare appService: $TSFixMe;

  beforeModel(transition: Transition) {
    let params = transition.to.params;

    // Displaying, editing and creating content is now handled by the in-context editor.
    // This route is remaining in place to redirect in case of user bookmarks etc
    if (params.content_id && params.content_type) {
      this.knowledgeHubDrawerEditorService.openViewDrawer({
        routeName: 'apps.app.knowledge-hub.all-content',
        activeContentId: params.content_id,
        activeContentType: params.content_type,
      });
    } else {
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    }
  }
}
