/* import __COLOCATED_TEMPLATE__ from './npm.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { INSTALLATION_METHOD } from 'embercom/components/installation-new/constants';

export interface NpmCodeSnippetArgs {
  forLoggedInUsers?: boolean;
  selectedInstallationMethod?: INSTALLATION_METHOD;
  disabled?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: NpmCodeSnippetArgs;
}

export default class NPM extends Component<Signature> {
  @service appService: any;
  @service regionService: any;

  get region() {
    return this.regionService.region.slice(0, 2);
  }

  get containerId() {
    return `npm-install-${this.args.forLoggedInUsers ? 'logged' : 'visitor'}-bundle-code-component-npm`;
  }

  get app() {
    return this.appService.app;
  }

  get appIdString() {
    return `app_id: '${this.app.id}',`;
  }

  get userIdString() {
    return `user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID`;
  }

  get userNameString() {
    return `name: user.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name`;
  }

  get userEmailString() {
    return `email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email`;
  }

  get createdAtString() {
    return `created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200`;
  }

  get regionString() {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    return `region: '${this.region}'`;
  }

  get reactCodeForUsers() {
    return `
      import React from 'react';
      import Intercom from '@intercom/messenger-js-sdk';

      export default function Component() {
        Intercom({
          ${this.appIdString}${
            this.regionService.isUS
              ? ''
              : `
          ${this.regionString}`
          }
          ${this.userIdString}
          ${this.userNameString}
          ${this.userEmailString}
          ${this.createdAtString}
        });

        return <div>Example App</div>;
      }
    `;
  }

  get reactCodeForVisitors() {
    return `
      import React from 'react';
      import Intercom from '@intercom/messenger-js-sdk';

      export default function Component() {
        Intercom({
          ${this.appIdString}${
            this.regionService.isUS
              ? ''
              : `
          ${this.regionString}`
          }
        });

        return <div>Example App</div>;
      }
    `;
  }

  get angularCodeForUsers() {
    return `
      import { Component } from '@angular/core';
      import Intercom from '@intercom/messenger-js-sdk';

      Intercom({
        ${this.appIdString}${
          this.regionService.isUS
            ? ''
            : `
        ${this.regionString}`
        }
        ${this.userIdString}
        ${this.userNameString}
        ${this.userEmailString}
        ${this.createdAtString}
      });

      @Component({
        selector: 'app-root',
      })

      export class AppComponent {
        title = 'Example App';
      }
    `;
  }

  get angularCodeForVisitors() {
    return `
      import { Component } from '@angular/core';
      import Intercom from '@intercom/messenger-js-sdk';

      Intercom({
        ${this.appIdString}${
          this.regionService.isUS
            ? ''
            : `
        ${this.regionString}`
        }
      });

      @Component({
        selector: 'app-root',
      })

      export class AppComponent {
        title = 'Example App';
      }
    `;
  }

  get vueCodeForUsers() {
    return `
      <script setup>
        import Intercom from '@intercom/messenger-js-sdk';

        Intercom({
          ${this.appIdString}${
            this.regionService.isUS
              ? ''
              : `
          ${this.regionString}`
          }
          ${this.userIdString}
          ${this.userNameString}
          ${this.userEmailString}
          ${this.createdAtString}
        });
      </script>
    `;
  }

  get vueCodeForVisitors() {
    return `
      <script setup>
        import Intercom from '@intercom/messenger-js-sdk';

        Intercom({
          ${this.appIdString}${
            this.regionService.isUS
              ? ''
              : `
          ${this.regionString}`
          }
        });
      </script>
    `;
  }

  get emberCodeForUsers() {
    return `
      import Intercom from '@intercom/messenger-js-sdk';

      export default class App extends Application {
        Intercom = Intercom({
          ${this.appIdString}${
            this.regionService.isUS
              ? ''
              : `
          ${this.regionString}`
          }
          ${this.userIdString}
          ${this.userNameString}
          ${this.userEmailString}
          ${this.createdAtString}
        });
      }
    `;
  }

  get emberCodeForVisitors() {
    return `
      import Intercom from '@intercom/messenger-js-sdk';

      export default class App extends Application {
        Intercom = Intercom({
          ${this.appIdString}${
            this.regionService.isUS
              ? ''
              : `
          ${this.regionString}`
          }
        });
      }
    `;
  }

  get defaultCodeForUsers() {
    return `
      import Intercom from '@intercom/messenger-js-sdk';

      Intercom({
        ${this.appIdString}${
          this.regionService.isUS
            ? ''
            : `
        ${this.regionString}`
        }
        ${this.userIdString}
        ${this.userNameString}
        ${this.userEmailString}
        ${this.createdAtString}
      });
    `;
  }

  get defaultCodeForVisitors() {
    return `
      import Intercom from '@intercom/messenger-js-sdk';

      Intercom({
        ${this.appIdString}${
          this.regionService.isUS
            ? ''
            : `
        ${this.regionString}`
        }
      });
    `;
  }

  get bootCode() {
    switch (this.args.selectedInstallationMethod) {
      case INSTALLATION_METHOD.REACT:
        return this.args.forLoggedInUsers ? this.reactCodeForUsers : this.reactCodeForVisitors;
      case INSTALLATION_METHOD.ANGULAR:
        return this.args.forLoggedInUsers ? this.angularCodeForUsers : this.angularCodeForVisitors;
      case INSTALLATION_METHOD.VUE:
        return this.args.forLoggedInUsers ? this.vueCodeForUsers : this.vueCodeForVisitors;
      case INSTALLATION_METHOD.EMBER:
        return this.args.forLoggedInUsers ? this.emberCodeForUsers : this.emberCodeForVisitors;
      default:
        return this.args.forLoggedInUsers ? this.defaultCodeForUsers : this.defaultCodeForVisitors;
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::CodeSnippets::Npm': typeof NPM;
    'installation-new/web/initial-setup/code-snippets/npm': typeof NPM;
  }
}
