/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-side-effects */
/* eslint-disable ember/no-private-routing-service */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';

export default Controller.extend({
  store: service(),
  notificationsService: service(),
  intl: service(),
  router: service(),
  appstoreService: service(),
  appService: service(),
  intercomConfirmService: service(),
  queryParams: [
    'app_package_code',
    'tab',
    'install_success',
    'error_message',
    'is_onboarding',
    'email_request',
    'redirect',
    { showReinstall: 'show_reinstall' },
  ],
  app_package_code: null,
  tab: null,
  install_success: null,
  error_message: null,
  is_onboarding: null,
  showReinstall: false,

  _fetchAppPackage() {
    if (this.get('appService.app.useAppCardApi')) {
      return this.store.peekRecord('appstore/app-card', this.app_package_code)
        ? this.appstoreService.peekOrFindAppPackage(this.app_package_code)
        : null;
    }
    return this.store.peekRecord('appstore/app-package', this.app_package_code);
  },

  selectedAppPackage: computed('app_package_code', function () {
    if (!this.app_package_code) {
      return null;
    }
    let appPackage = this._fetchAppPackage();
    if (!appPackage) {
      this.notificationsService.notifyError('Sorry but that app does not exist.');
      return null;
    }
    let title = this.appstoreService.generateTitle({ app_package_code: this.app_package_code });
    this.send('setDocumentTitle', title);
    return appPackage;
  }),

  async showDiscardChangeWarning() {
    let confirmOptions = {
      title: this.intl.t('appstore.settings.jira-for-tickets.confirm-close-app-modal.title'),
      body: this.intl.t('appstore.settings.jira-for-tickets.confirm-close-app-modal.description'),
      confirmButtonText: this.intl.t(
        'appstore.settings.jira-for-tickets.confirm-close-app-modal.confirm-button',
      ),
      cancelButtonText: this.intl.t(
        'appstore.settings.jira-for-tickets.confirm-close-app-modal.cancel-button',
      ),
      primaryButtonType: 'primary-destructive',
    };
    let confirm = await this.intercomConfirmService.confirm(confirmOptions);
    if (confirm) {
      this.clearAppPackageCode();
    }
  },

  clearAppPackageCode() {
    this.setProperties({
      app_package_code: null,
      tab: null,
      install_success: null,
      error_message: null,
      is_onboarding: null,
      redirect: null,
      email_request: null,
    });
    // For context on why we need to do this below: https://github.com/intercom/embercom/pull/26246#discussion_r184416210
    let routerMicrolib = getOwner(this).lookup('router:main')._routerMicrolib;
    let listingParams = routerMicrolib.state.fullQueryParams;
    let title = this.appstoreService.generateTitle(listingParams);
    this.send('setDocumentTitle', title);
  },

  actions: {
    closeModal() {
      if (this.app_package_code === 'jira-for-tickets') {
        let settings = this.store.peekAll(
          `appstore/app-package/${this.app_package_code}/app-settings`,
        );
        if (settings.firstObject && settings.firstObject.hasDirtyAttributes) {
          this.showDiscardChangeWarning();
        } else {
          this.clearAppPackageCode();
        }
      } else {
        this.clearAppPackageCode();
      }
    },
    transitionAppPackageTab(tab) {
      this.transitionToRoute({
        queryParams: { tab: tab || null },
      });
    },
  },
});
