/* import __COLOCATED_TEMPLATE__ from './setup-domain.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { captureException } from 'embercom/lib/sentry';
import type DomainService from 'embercom/services/domain-service';
import { type DomainEmailAddress, type Domain } from 'embercom/services/domain-service';
import type EntriConnectService from 'embercom/services/entri-connect-service';
import { type EntriConnectConfig } from 'embercom/services/entri-connect-service';

interface Signature {
  Args: {
    changeStep: (step: string | null, place: string) => void;
    inviteTeammate: () => void;
    domain: Domain | null;
    emailAddress: DomainEmailAddress | undefined;
    previousStepForDomainSetup: string | null;
  };
}

export default class SetupDomain extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare entriConnectService: EntriConnectService;
  @service declare domainService: DomainService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @tracked verificationInProgress = false;
  place = 'setup-domain';

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.handleOnEntriClose = this.handleOnEntriClose.bind(this);
    window.addEventListener('onEntriClose', this.handleOnEntriClose);
    this.generateLink();
  }

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('onEntriClose', this.handleOnEntriClose);
  }

  get app() {
    return this.appService.app;
  }

  get entriLink(): string {
    return this.entriConnectService.link;
  }

  get config(): EntriConnectConfig {
    return {
      applicationId: 'intercom', // this is always the same
      token: this.entriConnectService.token,
      prefilledDomain: this.args.domain?.name ?? '',
      whiteLabel: this.entriConnectService.whitelabelOptions,
      dnsRecords: this.entriConnectService.dnsRecords,
      userId: this.entriConnectService.authenticationAttemptId,
    };
  }

  @action
  showEntriModal(): void {
    let config = this.config;
    window.entri?.showEntri(config);
    this.trackEvent({ action: 'clicked', object: 'connect_automatically' });
  }

  @action
  async generateLink(): Promise<void> {
    await taskFor(this.entriConnectService.generateLink).perform(
      Number(this.args.domain?.dkimSettings?.id),
      Number(this.args.domain?.bounceSettings?.id),
    );
  }

  async handleOnEntriClose(event: any) {
    let success = event.detail.success;
    if (success) {
      this.handleEntriSuccess();
    }

    if (event.detail.error) {
      let entriError = event.detail.error;
      this.trackEvent({ action: 'failed', object: 'entri_modal' });
      this.notificationsService.notifyError(
        this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-step.connect-entri.error-notification',
        ),
      );
      captureException(new Error(entriError.title), {
        extra: {
          details: entriError.details,
          code: entriError.code,
          lastStatus: event.detail?.lastStatus,
          provider: event.detail?.provider,
          domain: event.detail?.domain,
          appId: this.appService.app.id,
        },
        tags: {
          responsibleTeam: 'team-growth-opportunities',
        },
      });
    }
  }

  async handleEntriSuccess() {
    taskFor(this.entriConnectService.recordAppAdminEvent).perform(this.config.prefilledDomain);
    await this.updateAuthenticationAttemptsAndVerifyDomain();
    this.app.reload(); // reload the app in case the domain authentication caused a change on the reply email address
    this.trackEvent({ action: 'success', object: 'entri_modal' });
    if (this.args.emailAddress && this.args.previousStepForDomainSetup === 'overview') {
      this.args.changeStep('overview', this.place);
    } else {
      this.args.changeStep(null, this.place);
    }
  }

  async updateAuthenticationAttemptsAndVerifyDomain() {
    if (!this.args.domain) {
      return;
    }
    this.args.domain.dkimSettings.hasAuthenticationAttempt = true;
    this.args.domain.bounceSettings.hasAuthenticationAttempt = true;

    this.verificationInProgress = true;
    try {
      await this.args.domain.dkimSettings.verify();
    } catch (_error) {
      // do nothing and show next step
    }
    this.verificationInProgress = false;
  }

  trackEvent({ action, object }: { action: string; object: string }) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: this.place,
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::SetupDomain': typeof SetupDomain;
    'new-settings/channels/email/domains-and-addresses/setup-domain': typeof SetupDomain;
  }
}
