/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Step from 'embercom/models/operator/visual-builder/step';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type Group from 'embercom/models/operator/visual-builder/group';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';

export default class WorkflowLocalVariablesExist extends BaseValidator {
  @service declare intl: IntlService;

  validate(
    _value: any,
    _options: any,
    model: Step | ConnectionPoint | Group | Workflow,
  ): string | true {
    let editorConfig = model.editorConfig;
    if (!editorConfig) {
      return true;
    }

    let localVariableIdentifiers = model.localVariableIdentifiersUsed;
    let allLocalVariablesMap = Object.fromEntries(
      editorConfig.localAttributes.map((attribute) => [attribute.identifier, attribute]),
    ) as Record<string, AttributeDescriptor>;

    let invalidVariables = localVariableIdentifiers.filter(
      (identifier) => !allLocalVariablesMap[identifier],
    );

    if (invalidVariables.length > 0) {
      return this.intl.t(
        'operator.workflows.visual-builder.validations.invalid-local-variables-in-use',
      );
    }

    return true;
  }

  static getDependentsFor() {
    return ['model.localVariableIdentifiersUsed', 'model.editorConfig.localAttributes'];
  }
}
