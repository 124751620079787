/* RESPONSIBLE TEAM: team-actions */
import ApplicationAdapter from 'embercom/adapters/application';

export default class EmailSignatureAdapter extends ApplicationAdapter {
  urlForQueryRecord(query: any) {
    let { brand_id, app_id } = query;
    return brand_id
      ? `/ember/email_signatures/${app_id}?brand_id=${brand_id}`
      : `/ember/email_signatures/${app_id}`;
  }
}
