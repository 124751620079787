/* import __COLOCATED_TEMPLATE__ from './inspect-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type PlaygroundQuestionSource, type AppliedGuideline } from 'embercom/lib/fin-playground';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';
import type Session from 'embercom/services/session';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from 'embercom/lib/knowledge-hub/constants';
import { action } from '@ember/object';

interface InspectAnswerComponentArgs {
  aiToneOfVoice?: string;
  aiAnswerLength?: string;
  appliedGuidelines?: AppliedGuideline[];
  sources?: PlaygroundQuestionSource[];
  fallbackSearchLocale?: string;
}

type InspectAnswerComponentSignature = {
  Args: InspectAnswerComponentArgs;
  Element: HTMLDivElement;
};

export default class AiAgentPlaygroundInspectAnswer extends Component<InspectAnswerComponentSignature> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare appService: any;
  @service declare intercomEventService: $TSFixMe;

  @tracked openInspectSectionId = '';
  @tracked openPersonalitySectionId = '';
  @tracked openGuidanceSectionId = '';
  @tracked openContentSectionId = '';

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  instrumentSectionClick(action: string, sectionName: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'inspect_answer',
      context: 'playground',
      section_name: sectionName,
    });
  }

  get toneOfVoiceIcon(): InterfaceIconName {
    switch (this.args.aiToneOfVoice) {
      case 'friendly':
        return 'wave';
      case 'neutral':
        return 'fin';
      case 'plainspoken':
        return 'book';
      case 'professional':
        return 'newspaper';
      case 'playful':
        return 'lwr-happy';
      default:
        return 'lwr-happy';
    }
  }

  get answerLengthIcon() {
    switch (this.args.aiAnswerLength) {
      case 'succinct':
        return 'short-text';
      case 'standard':
        return 'long-text';
      case 'comprehensive':
        return 'extra-long-text';
      default:
        return 'extra-long-text';
    }
  }

  get personalitySettings(): { type: string; title: string; icon: InterfaceIconName }[] {
    return [
      {
        type: 'tone-of-voice',
        title: this.intl.t(`ai-agent.playground.tone-of-voice.${this.args.aiToneOfVoice}`),
        icon: this.toneOfVoiceIcon,
      },
      {
        type: 'answer-length',
        title: this.intl.t(`ai-agent.playground.answer-length.${this.args.aiAnswerLength}`),
        icon: this.answerLengthIcon,
      },
    ];
  }

  get guidanceCategoriesLengthText() {
    return this.args.appliedGuidelines?.length.toString() ?? '0';
  }

  get contentSourcesLengthText() {
    return this.args.sources?.length.toString() ?? '0';
  }

  get contentSources() {
    return this.args.sources?.map((source) => {
      return {
        ...source,
        title: source.entityData.title,
        icon: objectIcons[source.entityType],
      };
    });
  }

  @action toggleInspectSection() {
    let sectionId = 'inspect-answer';
    let isOpen = this.openInspectSectionId === sectionId;
    this.openInspectSectionId = isOpen ? '' : sectionId;
    let action = isOpen ? 'close' : 'open';

    this.instrumentSectionClick(action, sectionId);
  }

  @action togglePersonalitySection() {
    let sectionId = 'personality';
    let isOpen = this.openPersonalitySectionId === sectionId;
    this.openPersonalitySectionId = isOpen ? '' : sectionId;
    let action = isOpen ? 'close' : 'open';

    this.instrumentSectionClick(action, sectionId);
  }

  @action toggleContentSection() {
    let sectionId = 'content';
    let isOpen = this.openContentSectionId === sectionId;
    this.openContentSectionId = isOpen ? '' : sectionId;
    let action = isOpen ? 'close' : 'open';

    this.instrumentSectionClick(action, sectionId);
  }

  @action toggleGuidanceSection() {
    let sectionId = 'guidance';
    let isOpen = this.openGuidanceSectionId === sectionId;
    this.openGuidanceSectionId = isOpen ? '' : sectionId;
    let action = isOpen ? 'close' : 'open';

    this.instrumentSectionClick(action, sectionId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::InspectAnswer': typeof AiAgentPlaygroundInspectAnswer;
  }
}
