/* RESPONSIBLE TEAM: team-proactive-support */
import { helper as buildHelper } from '@ember/component/helper';
import intercomMessengerColors from '@intercom/intercom-messenger-colors';

function validateColorName(color: unknown): boolean {
  if (!color) {
    return false;
  }
  let style = new Option().style;
  style.color = color as string;

  return !!style.color;
}

export function calculateContrastTextColor(backgroundColor: unknown): string | undefined {
  if (!backgroundColor || !validateColorName(backgroundColor)) {
    return undefined;
  }
  let messengerColors = intercomMessengerColors({
    primaryColor: backgroundColor as string,
  });

  return messengerColors?.header_title_color;
}

export default buildHelper(function ([backgroundColor]) {
  return calculateContrastTextColor(backgroundColor);
});
