/* import __COLOCATED_TEMPLATE__ from './no-credit-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import type RouterService from '@ember/routing/router-service';
import type Plan from 'embercom/models/plan';

interface Signature {
  isDowngrade: boolean;
  openedFromBillingSummary: boolean;
  featureLessHeaders: boolean;
  plan: Plan;
  featureName: string;
  closeUpgradeModal: () => void;
  selectedPlanName: string;
}

export default class NoCreditCard extends Component<Signature> {
  @service declare purchaseAnalyticsService: any;
  @service declare customerService: any;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intl: any;

  get canAddCreditCard() {
    return (
      !this.appService.app.isSalesforceContracted &&
      this.appService.app.hasActiveSubscription &&
      !this.customerService.customer.hasCard &&
      !this.customerService.hasActiveTrialSubscription
    );
  }

  get description() {
    if (this.canAddCreditCard) {
      return this.intl.t('paywalls.upgrade-modal.sidebar.no-credit-card.description');
    }
    return this.intl.t('paywalls.upgrade-modal.sidebar.no-credit-card.sales-prospects-description');
  }

  @action
  redirecToBillingDetails() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'add_credit_card',
      place: 'paywall_sidebar',
      context: 'no_credit_card',
      planId: this.args.plan.idAsNumber,
      featureName: this.args.featureName,
    });

    this.router.transitionTo('apps.app.billing.settings', this.appService.app.id, {
      queryParams: { creditCardModalIsOpen: true },
    });
  }

  @action
  trackAnalyticsEventAndClose() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'chat_with_us',
      place: 'paywall_sidebar',
      context: 'no_credit_card',
      planId: this.args.plan.idAsNumber,
      featureName: this.args.featureName,
    });

    this.args.closeUpgradeModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::NoCreditCard': typeof NoCreditCard;
  }
}
