/* import __COLOCATED_TEMPLATE__ from './reply-addresses.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type DomainService from 'embercom/services/domain-service';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
interface Args {}

export default class ReplyAddresses extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: $TSFixMe;
  @service declare intercomEventService: any;
  @service declare domainService: DomainService;

  @tracked senderDomainValue = this.app.use_admin_app_email;
  @tracked replyAddressValue = this.replyAddressValueFromModel;

  get replyAddressValueFromModel() {
    if (this.senderDomainValue) {
      return this.app.admin_reply_default_address_id ? 'inbound' : 'teammate';
    } else {
      return 'workspace';
    }
  }

  get app() {
    return this.appService.app;
  }

  get isReplyAddressInboundDisabled(): boolean {
    return (
      !this.app.admin_reply_default_address_id &&
      this.domainService.verifiedAndAuthenticatedCustomEmailAddresses.length === 0
    );
  }

  @action
  async handleClickWorkspace() {
    this.senderDomainValue = false;
    this.replyAddressValue = 'workspace';
    await this.update();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_sender_domain',
      section: 'settings',
      inbound_address: 'workspace',
    });
  }

  @action
  async handleClickTeammate() {
    this.senderDomainValue = true;
    this.replyAddressValue = 'teammate';
    await this.update();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_sender_domain',
      section: 'settings',
      inbound_address: 'teammate',
    });
  }

  @action
  async handleClickInbound() {
    this.senderDomainValue = true;
    this.replyAddressValue = 'inbound';
    await this.update();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_sender_domain',
      section: 'settings',
      inbound_address: 'inbound',
    });
  }

  private get defaultReplyAddress(): SenderEmailAddressSettings | undefined {
    return (
      this.domainService.defaultSenderEmailAddressSettings ??
      this.domainService.verifiedAndAuthenticatedCustomEmailAddresses[0]?.senderEmailAddressSettings
    );
  }

  async update() {
    try {
      if (this.replyAddressValue === 'inbound') {
        await this.updateWhenInboundIsSelected();
      } else {
        await this.app.updateDefaultEmailSenderSetting(this.senderDomainValue, '');
      }
    } catch (error) {
      console.error('update error', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'new-settings.channels.email.customisation.sender-domain.update-error',
        ),
      });
      return;
    }
    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.channels.email.customisation.sender-domain.update-success'),
    );
  }

  async updateWhenInboundIsSelected() {
    if (!this.domainService.defaultSenderEmailAddressSettings) {
      await this.app.updateDefaultNotificationEmailAddress(this.defaultReplyAddress?.id);
    }
    let senderEmailAddressId =
      this.defaultReplyAddress?.senderEmailAddresses?.firstObject?.id ?? '0';
    await this.app.updateDefaultEmailSenderSetting(this.senderDomainValue, senderEmailAddressId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::ReplyAddresses': typeof ReplyAddresses;
  }
}
