/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import DidChangeAttrs from 'ember-did-change-attrs';
import { CanvasView } from '@intercom/interblocks.ts';
import safeWindowOpen from '../../lib/safe-window-open';
import { inject as service } from '@ember/service';
import { RETRIES, ERROR_COMPONENT } from 'embercom/models/app-framework/canvas';
import ajax from 'embercom/lib/ajax';
import { run } from '@ember/runloop';
import $ from 'jquery';
import Model from '@ember-data/model';
import { captureException } from 'embercom/lib/sentry';

export default Component.extend(DidChangeAttrs, {
  canvasView: null,
  appService: service(),
  session: service(),
  classNames: [
    'conversation__messenger-card app-framework__messenger-apps__inserter__panel__messenger-card',
  ],
  classNameBindings: [
    'stylePrefix',
    'onSubmitAction:o__interactive',
    'isLoading:o__loading',
    'isBorderless:o__borderless',
  ],
  styleContext: 'messenger-apps',
  stylePrefix: computed('styleContext', function () {
    if (this.styleContext === 'inbox-apps') {
      return 'intercom-interblocks-inbox-card';
    }

    return 'intercom-interblocks-messenger-card';
  }),
  isBorderless: false,
  disableInputs: false,
  autoFocus: false,
  confineDropdownToCanvas: true,
  defaultUrlAction: safeWindowOpen.bind(this),

  didChangeAttrsConfig: {
    attrs: ['canvas', 'canvas.content'],
  },

  handleRenderError(err) {
    captureException(err);
    this.renderCanvas({
      content: {
        version: '0.1',
        components: [ERROR_COMPONENT],
      },
    });
  },

  didChangeAttrs() {
    try {
      this.renderCanvas(this.canvas);
    } catch (err) {
      this.handleRenderError(err);
    }
  },

  didInsertElement() {
    this._super(...arguments);
    if (this.canvas) {
      try {
        this.renderCanvas(this.canvas);
      } catch (err) {
        this.handleRenderError(err);
      }
    }
  },

  initCanvasView() {
    let context = {
      conversationId: this.conversationId,
      location: this.location,
    };
    let urlAction = this.onUrlAction ? this.onUrlAction : this.defaultUrlAction;
    let canvasView = new CanvasView(
      this.element,
      this.getCanvasNetworkingClient(context),
      urlAction,
      this.onSubmitAction,
      this.autoFocus,
      this.confineDropdownToCanvas,
    );
    this.set('canvasView', canvasView);
  },

  renderCanvas(canvas) {
    if (canvas instanceof Model) {
      canvas = canvas.serialize();
    }
    let disableInputs = this.disableInputs;
    if (disableInputs && canvas.content && canvas.content.components) {
      canvas.content.components = this.addDisabledAttributeToInputs(canvas.content.components);
    }
    if (!this.canvasView) {
      this.initCanvasView();
    }
    this.canvasView.render(canvas);
  },

  addDisabledAttributeToInputs(components) {
    return components.map((component) => {
      if (component.type === 'input') {
        component.disabled = true;
      }
      return component;
    });
  },

  willDestroyElement() {
    this._super(...arguments);
    if (this.canvasView) {
      this.canvasView.destroy();
    }
  },

  get appId() {
    return this.appService?.app?.id || this.session.workspace.id;
  },

  async fetchContentWithRetry(retries, context) {
    let result;
    let contextQueryParamString = this.buildQueryParamString(context);

    try {
      result = await ajax({
        type: 'GET',
        url: `/ember/messenger_cards/content/${this.canvas.id}.json?app_id=${this.appId}${contextQueryParamString}`,
      });
    } catch (err) {
      if (retries) {
        return this.fetchContentWithRetry(--retries, context);
      }
      console.error(`card with ${this.canvas.id} id failed to load, error:`, err);
      result = {
        content: {
          version: '0.1',
          components: [ERROR_COMPONENT],
        },
      };
    }

    if (!this.isDestroying) {
      run(() => this.set('content', result.content));
    }
    return result.content;
  },

  buildQueryParamString(context) {
    let contextQueryParamString = '';
    if (context.conversationId) {
      contextQueryParamString += `&conversation_id=${context.conversationId}`;
    }
    if (context.location) {
      contextQueryParamString += `&location=${context.location}`;
    }
    return contextQueryParamString;
  },

  getCanvasNetworkingClient(context) {
    return {
      get: (url, done) => {
        this.fetchContentWithRetry(RETRIES, context).then(done);
      },
      post(url, done) {
        $.post(`/ember/${url}`).done(done);
      },
    };
  },
});
