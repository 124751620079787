/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import type WorkflowConnector from 'embercom/models/operator/visual-builder/step/workflow-connector';
import StepConfig, { type StepConfigParams } from '../step';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { CustomActionsAttributeInfoResolver } from 'embercom/lib/actions/action-composer-config';
import type AttributeDescriptor from 'embercom/models/workflow-connector/attribute-descriptor';

export default class WorkflowConnectorConfiguration extends StepConfig {
  declare step: WorkflowConnector;
  protected attributeService: any;
  protected store: any;
  protected app: any;

  constructor(
    params: Omit<StepConfigParams, 'component'> & {
      component?: string;
      attributeService: any;
      store: any;
      app: any;
    },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/workflow-connector',
      ...params,
    });

    this.attributeService = params.attributeService;
    this.store = params.store;
    this.app = params.app;
  }

  get stepMenuConfigOptions() {
    let stepConfigMenu = [...super.stepMenuConfigOptions!];
    if (this.step.action) {
      let isTemplate = this.step.action.isTemplate || !!this.step.action.appPackageCode;
      let editButtonLabel = isTemplate ? 'edit-custom-action-template' : 'edit-custom-action';

      stepConfigMenu.push({
        icon: 'settings',
        text: this.intl.t(
          `operator.workflows.visual-builder.workflow-connector.${editButtonLabel}`,
        ),
        onSelectItem: () => this.editCustomAction(isTemplate),
      });

      if (this.app.canUseActionsInputMapping && this.step.action.hasRequestParameters) {
        stepConfigMenu.push({
          icon: 'transfer',
          text: this.intl.t(
            'operator.workflows.visual-builder.workflow-connector.map-action-inputs',
          ),
          onSelectItem: () => this.showInputMappingDrawer(),
        });
      }
    }
    return stepConfigMenu;
  }

  @action
  editCustomAction(isTemplate: boolean) {
    if (isTemplate) {
      this.step.showDrawer = true;
    } else {
      this.openCustomActionEditor();
    }
  }

  @action
  openCustomActionEditor() {
    let uri = this.step.router.urlFor(
      'apps.app.settings.app-settings.custom-actions.custom-action',
      this.step.action?.id,
    );
    safeWindowOpen(uri);
  }

  @action
  showInputMappingDrawer() {
    this.step.showInputMappingDrawer = true;
  }

  deleteStep() {
    let identifier = this.step.actionOutputAttributeDescriptorIdentifier;
    if (this.app.canUseActionsInputMapping && identifier) {
      let savedRootLocalVariable = this.store.peekRecord(
        'operator/visual-builder/attribute-descriptor',
        identifier,
      );
      this.editorState.deleteLocalVariable(savedRootLocalVariable);
    }
    super.deleteStep();
  }

  get attributeInfoResolver() {
    return new CustomActionsAttributeInfoResolver({
      attributes: this.app.allowedAttributes,
      attributeAllowlist: this.step.editorConfig?.runtimeMatchingTargetingAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      conversationAttributes: this.attributeService.conversationAttributesForCustomAction,
      ticketTypeAttributes: this.attributeService.ticketTypeAttributes,
      localAttributes: this.step.editorConfig?.localAttributeGroupList,
      allLocalAttributes: this.step.editorConfig?.allLocalAttributeGroupList,
      includeCustomObjectAttributes: true,
      includeCustomActionAttributes: true,
      includeAppAttributes: true,
    });
  }
}

export class WebhookReceivedWorkflowConnectorConfiguration extends WorkflowConnectorConfiguration {
  protected webhookReceivedAttributes: Array<AttributeDescriptor>;

  constructor(
    params: Omit<StepConfigParams, 'component'> & {
      component?: string;
      attributeService: any;
      store: any;
      app: any;
      webhookReceivedAttributes: Array<AttributeDescriptor>;
    },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/workflow-connector',
      ...params,
    });
    this.webhookReceivedAttributes = params.webhookReceivedAttributes;
  }

  get attributeInfoResolver() {
    return new CustomActionsAttributeInfoResolver({
      attributes: this.app.allowedAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      conversationAttributes: this.attributeService.conversationAttributesForCustomAction,
      ticketTypeAttributes: this.attributeService.ticketTypeAttributes,
      includeCustomObjectAttributes: true,
      includeCustomActionAttributes: true,
      includeAppAttributes: true,
      webhookReceivedAttributes: this.webhookReceivedAttributes,
    });
  }
}
