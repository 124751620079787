/* RESPONSIBLE TEAM: team-actions */

import Model, { attr, belongsTo } from '@ember-data/model';
import type ZendeskConfiguration from './zendesk/configuration';

export default class Configuration extends Model {
  @attr('string') declare activeTool: string;
  @belongsTo('side-by-side/handover/zendesk/configuration', { async: false })
  declare zendeskConfiguration: ZendeskConfiguration | null;
}
