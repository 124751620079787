/* RESPONSIBLE TEAM: team-knowledge-interop */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ContentImportRun from 'embercom/models/content-service/content-import-run';
import extractResponseError from 'embercom/lib/extract-response-error';

import type Store from '@ember-data/store';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import Failed from 'embercom/components/notifications/content-import/failed';
import Completed from 'embercom/components/notifications/content-import/completed';
import routeRegexes from 'embercom/lib/route-regexes';
import { FAILED, COMPLETE, ERROR_CODES } from 'embercom/lib/content-service/file-source/constants';
import type FileSource from 'embercom/models/content-service/file-source';
import type FinIngestionStates from 'embercom/models/operator/fin-ingestion-states';
import { get } from 'embercom/lib/ajax';
import {
  type ConversationContentSettings,
  type ConversationContentSettingsWireFormat,
} from 'embercom/controllers/apps/app/fin-ai-agent/setup';
import type FinConversationContent from 'embercom/services/fin-conversation-content';
import { buildParams, request } from 'embercom/lib/inbox/requests';
import { Status as JobStatus } from 'embercom/models/data/content-service/ai/ingestion-job-statuses';
import { MAX_LIBRARY_SIZE, WARNING_LIBRARY_SIZE } from 'embercom/lib/content-service/constants';

interface ContentIngestionJobStatusEvent {
  ingestion_job_id: string;
  status: number;
  bytesize: number;
}

export interface ContentImportRunEvent {
  event_type: string;
  timestamp: number;
  url: string;
  trigger_source: string;
}

interface FileSourceRunEvent {
  status: string;
  error_code: string;
  file_source_id: number;
  file_source_content_id: number;
  will_retry: boolean;
}

interface NotificationModel {
  externalContentUrl: string;
  sourceUrl: string;
  ctaUrl: string;
}

export type AiContentLibrarySummary = {
  [key: number]: {
    entity_type: number;
    total_count: number;
    used_by_fin_count: number;
    additional_data: any;
  };
};

type ContentReviewSummary = {
  [key: number]: {
    pending: number;
  };
};

interface IngestionJobStatus {
  status: number | null;
  display: boolean;
}

export const EVENT_SERVICE_ID = 'ContentImportRunStatus';
const FILE_SOURCE_EVENT_SERVICE_ID = 'FileSourceRunStatus';
export const CONTENT_INGESTION_JOB_EVENT_ID = 'AiContentIngestionJobStatusChanged';
let NOTIFICATION_TIME = 10000;

export default class ContentImportService extends Service {
  @service declare store: Store;
  @service declare appService: any;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare intl: { t: Function };
  @service declare router: { urlFor: Function; currentRouteName: string };
  @service declare notificationsService: {
    notifyConfirmationWithModelAndComponent: Function;
    notifyErrorWithModelAndComponent: Function;
    removeNotification: Function;
    notifyConfirmation: Function;
    notifyError: Function;
    notifyResponseError: Function;
  };
  @service declare finConversationContent: FinConversationContent;

  @tracked contentImportRun?: ContentImportRun;
  @tracked contentImportRunId?: string;
  @tracked contentImportSources?: Array<ContentImportSource>;
  @tracked lastReceivedRunStatusTimestamp = 0;
  @tracked completionCallback?: Function;
  @tracked errorCallback?: Function;
  @tracked finIngestionState?: FinIngestionStates;
  @tracked aiContentReviewSummary?: ContentReviewSummary = {};
  @tracked aiContentLibrarySummary: AiContentLibrarySummary = {};
  @tracked fileSourceCompletionCallback?: Function;
  @tracked fileSources?: Array<FileSource>;
  @tracked finConversationContentSettings?: ConversationContentSettings;
  @tracked finIngestionJobStatusBanner: IngestionJobStatus = { status: null, display: false };
  @tracked contentBytesize = 0;

  @tracked isLoadingSources = false;

  async forceFetchContentImportSources(): Promise<Array<ContentImportSource>> {
    return this.store.query('content-service/content-import-source', {
      reload: true,
      app_id: this.appService.app.id,
    }) as unknown as Array<ContentImportSource>;
  }

  async fetchContentImportSources(): Promise<Array<ContentImportSource>> {
    this.isLoadingSources = true;
    this.contentImportSources = (await this.store.query('content-service/content-import-source', {
      app_id: this.appService.app.id,
    })) as unknown as Array<ContentImportSource>;
    this.isLoadingSources = false;
    return this.contentImportSources ?? [];
  }

  async fetchFileSources(): Promise<Array<FileSource>> {
    this.fileSources = (await this.store.query('content-service/file-source', {
      app_id: this.appService.app.id,
    })) as unknown as Array<FileSource>;
    return this.fileSources ?? [];
  }

  async fetchContentIngestionState(): Promise<any> {
    this.finIngestionState = await this.store.findRecord(
      'operator/fin-ingestion-states',
      this.appService.app.id,
    );

    this.contentBytesize = this.finIngestionState?.contentBytesize || 0;
  }

  async fetchAiContentLibrarySummary(): Promise<void> {
    this.aiContentLibrarySummary = await get(
      '/ember/content_service/contents/ai_content_library_summary',
      {
        app_id: this.appService.app.id,
      },
    );
  }

  async fetchFinConversationContentSettings(): Promise<void> {
    let params = buildParams(this.appService.app.id);
    let response = await request(`/ember/inbox/fin_conversation_content?${params}`);
    let json = (await response.json()) as ConversationContentSettingsWireFormat;
    this.finConversationContentSettings = {
      enabled: json.enabled,
      numSnippets: json.num_snippets,
      numSnippetsAvailable: json.num_snippets_available,
      immediatelyAvailableToFin: json.immediately_available_to_fin,
      adminIDs: json.admin_ids || [],
      teamIDs: json.team_ids || [],
      contentFolderId: json.content_folder_id,
    };
  }

  async fetchContentReviewSummary(): Promise<void> {
    this.aiContentReviewSummary = await get(
      '/ember/content_service/content_review_requests/counts',
      {
        app_id: this.appService.app.id,
      },
    );
  }

  get inProgressRunExists(): boolean {
    if (this.contentImportSources) {
      return (
        this.contentImportSources.filter(
          (source: ContentImportSource) => source.latestImportRun?.isInProgress,
        ).length > 0
      );
    } else {
      return false;
    }
  }

  get successfulRuns(): ContentImportSource[] {
    if (this.contentImportSources) {
      return this.contentImportSources.filter(
        (source: ContentImportSource) =>
          source.latestImportRun?.isFullyIngested && !source.latestImportRun?.isFailed,
      );
    } else {
      return [];
    }
  }

  get failedRuns(): ContentImportSource[] {
    if (this.contentImportSources) {
      return this.contentImportSources.filter(
        (source: ContentImportSource) => source.latestImportRun?.isFailed,
      );
    } else {
      return [];
    }
  }

  get contentSizeAboveMaxQuota() {
    return this.contentBytesize > MAX_LIBRARY_SIZE;
  }

  get contentSizeApproachingMaxQuota() {
    return !this.contentSizeAboveMaxQuota && this.contentBytesize > WARNING_LIBRARY_SIZE;
  }

  get currentQuotaUsage() {
    return ((this.contentBytesize / MAX_LIBRARY_SIZE) * 100).toFixed(2);
  }

  subscribeToContentImportRunStatusUpdates(): void {
    this.realTimeEventService.on(EVENT_SERVICE_ID, this, 'showContentImportUpdate');
  }

  subscribeToFileSourceRunStatusUpdates(): void {
    this.realTimeEventService.on(FILE_SOURCE_EVENT_SERVICE_ID, this, 'showFileSourceUpdate');
  }

  subscribeToContentIngestionJobStatusUpdates(): void {
    this.realTimeEventService.subscribeTopics(['ai-content-ingestion-job-status-changed']);
    this.realTimeEventService.on(CONTENT_INGESTION_JOB_EVENT_ID, this, '_processIngestionJobEvent');
  }

  assignCompletionCallback(callback: Function): void {
    this.completionCallback = callback;
  }

  assignErrorCallback(callback: Function): void {
    this.errorCallback = callback;
  }

  async showFileSourceUpdate(event: FileSourceRunEvent) {
    let fileSource = await this.store.findRecord(
      'content-service/file-source',
      event.file_source_id,
    );
    if (Number(fileSource.lastEditedById) !== Number(this.appService.app.currentAdmin.id)) {
      return;
    }
    if (event.status === COMPLETE) {
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.file-content.file-source.successfully-uploaded', {
          filename: fileSource?.fileName,
        }),
      );
      await this.fetchFileSources();
      this.fileSourceCompletionCallback?.();
    } else if (event.status === FAILED) {
      if (ERROR_CODES[event.error_code]) {
        this.notificationsService.notifyError(
          this.intl.t(
            `ai-content-library.file-content.error-codes.${ERROR_CODES[event.error_code]}`,
            {
              filename: fileSource?.fileName,
            },
          ),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('ai-content-library.file-content.file-source.failed-to-upload', {
            filename: fileSource?.fileName,
          }),
        );
      }
    }
  }

  showContentImportUpdate(event: ContentImportRunEvent) {
    if (
      this.lastReceivedRunStatusTimestamp > event.timestamp ||
      !['completed', 'ingested', 'failed'].includes(event?.event_type)
    ) {
      return;
    }
    if (event?.event_type === 'ingested') {
      this.fetchContentIngestionState();
    } else if (event?.event_type === 'completed') {
      this.notificationsService.notifyConfirmationWithModelAndComponent(
        this._notificationModel(event),
        Completed,
        NOTIFICATION_TIME,
      );
      this.completionCallback?.();
    } else {
      this.notificationsService.notifyErrorWithModelAndComponent(
        this._notificationModel(event),
        Failed,
        NOTIFICATION_TIME,
      );
      this.errorCallback?.();
    }
    this.lastReceivedRunStatusTimestamp = event.timestamp;
  }

  showContentIngestionJobUpdate(event: ContentIngestionJobStatusEvent) {
    if (event?.status === JobStatus.InProgress) {
      this.finIngestionJobStatusBanner = {
        status: JobStatus.InProgress,
        display: true,
      };
    } else if (event?.status === JobStatus.Completed) {
      this.finIngestionJobStatusBanner = {
        status: JobStatus.Completed,
        display: true,
      };
    }
  }

  hideFinIngestionJobStatusBanner(): void {
    this.finIngestionJobStatusBanner = {
      status: null,
      display: false,
    };
  }

  showFinIngestionJobStatusBanner(): void {
    if (!this.appService.app.hasConsentedToFinIngestion) {
      return;
    }
    // On creation of new content for Fin, a pending ingestion job is queued
    // and likely to have a bit of delay before the status is changed to in progress banner
    // This allows us to show the banner immediately before the nexus event for job status is fired
    this.finIngestionJobStatusBanner = {
      status: JobStatus.Pending,
      display: true,
    };
  }

  _processIngestionJobEvent(event: ContentIngestionJobStatusEvent) {
    this.showContentIngestionJobUpdate(event);

    if (event?.bytesize && event?.bytesize > 0) {
      this.contentBytesize = event?.bytesize;
    }
  }

  _notificationText(event: ContentImportRunEvent): string {
    return this.intl.t(`ai-agent.profiles.notifications.import-${event?.event_type}`, {
      externalContentUrl: this.router.urlFor('apps.app.fin-ai-agent.content'),
      sourceUrl: event?.url,
      ctaUrl: this.router.currentRouteName.match(routeRegexes.onboardingHome)
        ? this.router.urlFor('apps.app.home')
        : this.router.urlFor('apps.app.fin-ai-agent.profiles'),
      htmlSafe: true,
    });
  }

  _notificationModel(event: ContentImportRunEvent): NotificationModel {
    let ctaUrl: string;
    if (event.trigger_source === 'onboarding_home') {
      ctaUrl = this.router.urlFor('apps.app.wizard', {
        queryParams: {
          wizardId: 'evaluation_wizard_chatbot',
          stepId:
            event.event_type === 'failed'
              ? 'ew_chatbot_try_fin_with_your_content'
              : 'ew_chatbot_set_fin_live',
        },
      });
      return {
        externalContentUrl: ctaUrl,
        sourceUrl: event?.url,
        ctaUrl,
      };
    } else if (this.router.currentRouteName.match(routeRegexes.onboardingHome)) {
      ctaUrl = this.router.urlFor('apps.app.home');
    } else if (this.appService.app.cauUseFinWorkflowSetupExperience) {
      ctaUrl = this.router.urlFor('apps.app.fin-ai-agent.setup');
    } else {
      ctaUrl = this.router.urlFor('apps.app.fin-ai-agent.profiles');
    }
    return {
      externalContentUrl: this.router.urlFor('apps.app.fin-ai-agent.content'),
      sourceUrl: event?.url,
      ctaUrl,
    };
  }

  submissionErrorTranslationKey(error: any): string {
    let submissionErrorMessage = extractResponseError(error)?.message;
    if (submissionErrorMessage) {
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      if (submissionErrorMessage.startsWith('URL is invalid:')) {
        return 'modal.import-external-content.url-form.errors.invalid-url';
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
      } else if (submissionErrorMessage.startsWith('URL is not allowed:')) {
        return 'modal.import-external-content.url-form.errors.block-listed';
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
      } else if (submissionErrorMessage.startsWith('URL has already been imported:')) {
        return 'modal.import-external-content.url-form.errors.already-imported';
      } else if (
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
        submissionErrorMessage.startsWith('Maximum number of external content sources reached')
      ) {
        return 'modal.import-external-content.url-form.errors.max-sources-reached';
      } else if (
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
        submissionErrorMessage.startsWith('Maximum number of concurrent import jobs reached')
      ) {
        return 'modal.import-external-content.url-form.errors.max-concurrency-reached';
      } else if (
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
        submissionErrorMessage.startsWith('This URL is for your Intercom help center.')
      ) {
        return 'modal.import-external-content.url-form.errors.intercom-help-centre';
      }
    }
    return 'modal.import-external-content.url-form.errors.default';
  }
}

declare module '@ember/service' {
  interface Registry {
    contentImportService: ContentImportService;
    'content-import-service': ContentImportService;
  }
}
