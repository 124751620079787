/* import __COLOCATED_TEMPLATE__ from './to-be-empty-team-banner.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

interface Args {
  teamCount: number;
}

export default class ToBeEmptyTeamBanner extends Component<Args> {
  @service declare router: RouterService;

  @action
  openTeamsPage() {
    safeWindowOpen(this.router.urlFor('apps.app.settings.helpdesk.teams'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Reassignment::ToBeEmptyTeamBanner': typeof ToBeEmptyTeamBanner;
    'settings/teammates/reassignment/to-be-empty-team-banner': typeof ToBeEmptyTeamBanner;
  }
}
