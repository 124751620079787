/* RESPONSIBLE TEAM: team-proactive-support */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { PAGE_SIZE } from 'embercom/models/data/outbound/constants';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { isPresent, isNone } from '@ember/utils';
import { later } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { hash } from 'rsvp';

const SORT_BY_PRIORITY = 'priority';
const SORT_DIRECTION_ASC = 'asc';

export default IntercomRoute.extend({
  outboundHomeService: service(),
  realTimeEventService: service(),
  intercomEventService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  tagService: service(),
  store: service(),
  perPage: PAGE_SIZE,

  queryParams: {
    state: {
      replace: true,
    },
    tagIds: {
      replace: true,
    },
    tagOperator: {
      replace: true,
    },
    senderId: {
      replace: true,
    },
    audiences: {
      replace: true,
    },
    subscriptionTypeId: {
      replace: true,
    },
    searchString: {
      replace: true,
    },
    selectedObjectTypes: {
      replace: true,
    },
    hideSeries: {
      replace: true,
    },
  },

  beforeModel() {
    this.outboundHomeService.setActiveList(null);
  },

  afterModel() {
    let queryParams = {
      state: this.get('outboundHomeService.selectedStateValue'),
      senderId: this.get('outboundHomeService.selectedSenderValue'),
    };
    if (isPresent(this.get('outboundHomeService.selectedTagValues'))) {
      queryParams.tagIds = this.get('outboundHomeService.selectedTagValues');
    }
    if (isPresent(this.get('outboundHomeService.selectedTagOperator'))) {
      queryParams.tagOperator = this.get('outboundHomeService.selectedTagOperator');
    }
    if (isPresent(this.get('outboundHomeService.selectedAudienceValues'))) {
      queryParams.audiences = this.get('outboundHomeService.selectedAudienceValues');
    }
    if (isPresent(this.get('outboundHomeService.selectedSubscriptionTypeValue'))) {
      queryParams.subscriptionTypeId = this.get(
        'outboundHomeService.selectedSubscriptionTypeValue',
      );
    }
    if (isPresent(this.get('outboundHomeService.searchString'))) {
      queryParams.searchString = this.get('outboundHomeService.searchString');
    }
    this.transitionTo({ queryParams });
  },

  activate() {
    this._super();
    this.realTimeEventService.on('ContentStatsUpdate', this, '_handleContentStatsUpdateEvent');
  },

  deactivate() {
    this._super();
    this.realTimeEventService.off('ContentStatsUpdate', this, '_handleContentStatsUpdateEvent');
  },

  resetFilters() {
    this.outboundHomeService.resetFilters();
    this.outboundHomeService.resetTableColumns();
  },

  actions: {
    willTransition(transition) {
      if (transition.to.name === 'apps.app.outbound.series.index') {
        this.outboundHomeService.resetFilters();
      }
    },
  },

  async model(params) {
    this.intercomEventService.trackEvent('outbound-list-view', {
      route: this.routeName,
    });
    let app = this.modelFor('apps.app');
    if (this.routeName !== 'apps.app.outbound.all') {
      this.outboundHomeService.selectedObjectTypes = [];
    }
    if (isPresent(this.outboundHomeService.view)) {
      // reset filters on the Messages page when navigating from a view
      this.resetFilters();
    }
    this.outboundHomeService.view = null;
    let searchResponsePromise = this.outboundHomeService.isFirstRequest
      ? this.contentSearchWithParams(params)
      : this.contentSearch();

    let hashParams = {
      messagesTags: this.tagService.getMessagesTags(app),
      templates: this.store.findAll('template'),
      tourTemplates: this.store.findAll('tour-template'),
      seriesTemplates: this.store.findAll('series/template'),
      searchResponse: searchResponsePromise,
    };
    hashParams.subscriptionTypes = this.store.findAll('outbound-subscriptions/subscription-type');

    let results = await hash(hashParams);

    if (this.outboundHomeService.isFirstRequest) {
      this.set('outboundHomeService.isFirstRequest', false);
    }

    let model = {
      contentWrappers: results.searchResponse.contentWrappers,
      pageFrom: results.searchResponse.pageFrom,
      perPage: results.searchResponse.perPage,
      totalCount: results.searchResponse.totalCount,
      totalPages: results.searchResponse.totalPages,
      lastPageHit: results.searchResponse.lastPageHit,
      templates: results.templates,
      tourTemplates: results.tourTemplates,
      seriesTemplates: results.seriesTemplates,
      messagesTags: results.messagesTags,
    };

    // propagate totalCount up
    this.controllerFor('apps.app.outbound').set('totalCount', model.totalCount);

    model.subscriptionTypes = results.subscriptionTypes.filter((subscriptionType) =>
      this.objectTypes.any((objectType) =>
        subscriptionType.permittedContentTypes.includes(objectType),
      ),
    );
    return model;
  },

  async contentSearchWithParams(params) {
    this._setOutboundHomeServiceFromParams(params);
    let response = await this.contentSearch();
    return response;
  },

  _setOutboundHomeServiceFromParams(params) {
    if (params.tagIds) {
      this.outboundHomeService.selectedTagValues = params.tagIds.split(',');
    }
    if (params.tagOperator) {
      this.outboundHomeService.selectedTagOperator = params.tagOperator;
    }
    if (params.subscriptionTypeId) {
      this.outboundHomeService.selectedSubscriptionTypeValue = params.subscriptionTypeId;
    }
    if (params.audiences) {
      this.outboundHomeService.selectedAudienceValues = params.audiences.split(',');
    }
    if (params.state) {
      this.outboundHomeService.selectedStateValue = parseInt(params.state, 10);
    }
    if (params.senderId) {
      this.set('outboundHomeService.selectedSenderValue', params.senderId);
    }
    if (params.searchString) {
      this.set('outboundHomeService.searchString', params.searchString);
    }
    if (params.hideSeries) {
      this.set('outboundHomeService.hideSeries', params.hideSeries === 'true');
    }
    if (params.selectedObjectTypes) {
      this.outboundHomeService.selectedObjectTypes = params.selectedObjectTypes
        .split(',')
        .map((objectType) => (objectNames[objectType] ? objectNames[objectType] : objectType))
        .uniq();
    }
  },

  async contentSearch() {
    let filters = {
      object_types: this.objectTypes,
      app_id: this.app.id,
      per_page: this.perPage,
      statistics: this.statistics,
    };
    if (this.outboundHomeService.isPrioritizable) {
      filters.sort_by = SORT_BY_PRIORITY;
      filters.sort_direction = SORT_DIRECTION_ASC;
    }
    return await this.outboundHomeService.contentSearchWithFilters(filters);
  },
  redirectToAllOutboundSearch() {
    this.intercomEventService.trackEvent('redirect-to-all-outbound', {
      from_route: this.routeName,
    });
    return this.replaceWith('apps.app.outbound.all', {
      queryParams: { selectedObjectTypes: objectNames[this.objectTypes[0]] },
    });
  },
  setupController(controller, model) {
    this._unselectContentWrappers(model.contentWrappers);
    let properties = {
      model: model.contentWrappers,
      pageFrom: model.pageFrom,
      perPage: model.perPage,
      totalCount: model.totalCount,
      totalPages: model.totalPages,
      lastPageHit: model.lastPageHit,
      templates: model.templates,
      tourTemplates: model.tourTemplates,
      seriesTemplates: model.seriesTemplates,
      messagesTags: model.messagesTags,
      objectTypes: this.objectTypes,
      statistics: this.statistics,
    };
    properties.subscriptionTypes = model.subscriptionTypes;
    if (this.outboundHomeService.isPrioritizable) {
      properties.sortBy = SORT_BY_PRIORITY;
      properties.sortDirection = SORT_DIRECTION_ASC;
    }
    controller.setProperties(properties);
  },

  _unselectContentWrappers(contentWrappers) {
    contentWrappers.forEach((contentWrapper) => {
      contentWrapper.set('isSelected', false);
    });
  },

  _updateContentFragmentStat(contentFragmentId, stat) {
    let contentFragment = this.store.peekRecord('outbound/content-fragment', contentFragmentId);
    if (contentFragment) {
      this.store.push(this.store.normalize('outbound/content-statistic', stat));
      let contentStat = this.store.peekRecord('outbound/content-statistic', stat.id);

      // If the content fragment did not previously have this stat, manually push the newly
      // created stat into the stats array
      if (isNone(contentFragment.stats.findBy('key', contentStat.key))) {
        contentFragment.stats.pushObject(contentStat);
      }
    }
  },

  _handleContentStatsUpdateEvent(realTimePayload) {
    realTimePayload.stats.forEach((stat) => {
      let contentFragmentId = stat.id.split('_')[2];
      let contentFragment = this.store.peekRecord('outbound/content-fragment', contentFragmentId);
      // Only update stats locally if the Teammate has loaded this content fragment
      // If we have the fragment already, update immediately. Otherwise wait a few
      // seconds as sometimes the update event from Nexus arrives before we have the models
      // locally
      if (contentFragment) {
        this._updateContentFragmentStat(contentFragmentId, stat);
      } else {
        later(
          this,
          () => this._updateContentFragmentStat(contentFragmentId, stat),
          ENV.APP._3000MS,
        );
      }
    });
  },
});
