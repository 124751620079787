/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import type KnowledgeHubApi from 'embercom/lib/knowledge-hub/list-api';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { type TagOperator, type Predicate } from 'embercom/lib/knowledge-hub/constants';
import { EntityType } from 'embercom/models/data/entity-types';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import {
  SyncIssuesFilterOptions,
  type FolderFilterOption,
} from 'embercom/lib/content-service/search-api';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import type ContentImportService from 'embercom/services/content-import-service';
import { IN_OPERATOR } from 'embercom/lib/outbound/constants';

interface Args {
  listApi: KnowledgeHubApi;
  locale: string | null;
  setLocale: (locale: string | null) => void;
  predicates?: Array<Predicate>;
  setPredicates: (predicates: Array<Predicate>) => void;
  status: string | null;
  setStatus: (status: string | null) => void;
  chatbotState?: string;
  setChatbotState: (chatbotState?: string) => void;
  copilotState?: string;
  setCopilotState: (copilotState?: string) => void;
  createdBy?: string;
  setCreatedBy: (createdBy?: string) => void;
  selectedTypes?: Array<EntityType>;
  setSelectedTypes: (selectedTypes?: Array<EntityType>) => void;
  urlSourceId?: string;
  setUrlSourceId: (urlSourceId?: string) => void;
  selectedHelpCenterIds?: Array<string>;
  setSelectedHelpCenterIds: (selectedHelpCenterIds?: Array<string>) => void;
  lastUpdatedBy?: string;
  setLastUpdatedBy: (lastUpdatedBy?: string) => void;
  displayFolderFilter?: boolean;
  selectedFolderFilterOption?: FolderFilterOption;
  setFolderFilterOption?: (folderFilterOption?: FolderFilterOption) => void;
  selectedSyncIssueOption?: SyncIssuesFilterOptions;
  setSyncIssueFilterOption?: (syncIssueFilterOption?: SyncIssuesFilterOptions) => void;
  activeFilter: string | undefined;
  filtersApplied?: string[];
  setActiveFilter: (filter?: string) => void;
  setFiltersApplied: (filters: string[]) => void;
  resetSelectedFragments: () => void;
  beforeSearch?: () => void;
  tagIds?: string[];
  setTagIds: (tagIds?: string[]) => void;
  tagOperator: TagOperator;
  setTagOperator: (tagOperator: TagOperator) => void;
  collectionIds?: string[];
  setCollectionIds: (collectionIds?: string[]) => void;
  writtenByIds?: string[];
  setWrittenByIds: (writtenByIds?: string[]) => void;
}

export default class Filters extends Component<Args> {
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare attributeService: any;
  @service declare helpCenterService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare contentImportService: ContentImportService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.args.setActiveFilter(undefined);
  }

  get supportedFilters(): Array<{ text: string; icon: string; value: string }> {
    let filters = [
      {
        text: this.intl.t('knowledge-hub.filters.created-by'),
        icon: 'person',
        value: 'createdBy',
      },
      {
        text: this.intl.t('knowledge-hub.filters.date'),
        icon: 'calendar',
        value: 'date',
      },
      {
        text: this.intl.t('knowledge-hub.filters.last-updated-by'),
        icon: 'multiple-people',
        value: 'lastUpdatedBy',
      },
      ...(this.args.displayFolderFilter
        ? [
            {
              text: this.intl.t('knowledge-hub.filters.folder'),
              icon: 'collection',
              value: 'folder',
            },
          ]
        : []),
      ...(this.externalContentSelected && this.urlSources.length > 1
        ? [
            {
              text: this.intl.t('knowledge-hub.filters.url-source'),
              icon: 'link',
              value: 'urlSource',
            },
          ]
        : []),
      {
        text: this.intl.t('knowledge-hub.filters.locale'),
        icon: 'language',
        value: 'locale',
      },
    ];

    if (this.appService.app.canUseExternalAi) {
      filters.push({
        text: this.intl.t('knowledge-hub.filters.chatbot-state'),
        icon: 'fin',
        value: 'chatbotState',
      });

      if (!this.appService.app.canUseStandalone) {
        filters.push({
          text: this.intl.t('knowledge-hub.filters.copilot-state'),
          icon: 'fin',
          value: 'copilotState',
        });
      }
    }

    if (
      this.appService.app.canUseFeature('group-ps-handle-unsupported-elements') &&
      this.internalArticleSelected
    ) {
      filters.push({
        text: this.intl.t('knowledge-hub.filters.sync-issues'),
        icon: 'alert',
        value: 'syncIssues',
      });
    }

    if (!this.appService.app.canUseStandalone) {
      filters.push({
        text: this.intl.t('knowledge-hub.filters.tag'),
        icon: 'tag',
        value: 'tag',
      });
    }

    return filters.sort((a, b) => a.text.localeCompare(b.text));
  }

  get publicArticleFilters() {
    let filters = [
      ...(this.canUseHelpCenterFilter
        ? [
            {
              text: this.intl.t('knowledge-hub.filters.help-center'),
              icon: 'article',
              value: 'helpCenterIds',
            },
          ]
        : []),
      {
        text: this.intl.t('knowledge-hub.filters.status'),
        icon: 'active',
        value: 'status',
      },
    ];

    if (!this.appService.app.canUseStandalone) {
      filters.push({
        text: this.intl.t('knowledge-hub.filters.collection'),
        icon: 'collection',
        value: 'collectionIds',
      });
    }

    filters.push({
      text: this.intl.t('knowledge-hub.filters.written-by'),
      icon: 'person-square-fill',
      value: 'writtenByIds',
    });

    return filters
      .sort((a, b) => a.text.localeCompare(b.text))
      .reject((item) => this.hasAppliedFilter(item.value) || this.args.activeFilter === item.value);
  }

  get selectableFilters(): Array<{
    heading: string;
    items: Array<{ text: string; icon: string; value: string }>;
  }> {
    let items = this.supportedFilters;

    let filters = [
      {
        heading: this.intl.t('knowledge-hub.filters.all-content-types'),
        items: items.reject(
          (item) => this.hasAppliedFilter(item.value) || this.args.activeFilter === item.value,
        ),
      },
    ];

    if (this.publicArticleFilters.length > 0) {
      filters.push({
        heading: this.intl.t('knowledge-hub.filters.public-article'),
        items: this.publicArticleFilters,
      });
    }

    return filters;
  }

  get supportedDateFilters() {
    return this.attributeService._finContentLibraryDateFilterAttributes.map((data: any) =>
      this.store.createRecord('attribute', data),
    );
  }

  get helpCenterSites(): HelpCenterSite[] {
    return this.helpCenterService.allSites;
  }

  get urlSources(): ContentImportSource[] {
    return this.contentImportService.contentImportSources ?? [];
  }

  get isSyncIssuesLoading(): boolean {
    return taskFor(this.searchBySyncIssue).isRunning;
  }

  hasAppliedFilter(filter: string): boolean {
    return !!(this.args.filtersApplied && this.args.filtersApplied.includes(filter));
  }

  get showLocaleFilter(): boolean {
    return (
      this.args.activeFilter === 'locale' || this.hasAppliedFilter('locale') || !!this.args.locale
    );
  }

  get showDateFilter(): boolean {
    return this.args.activeFilter === 'date' || !!this.args.predicates?.length;
  }

  get showHelpCenterFilter(): boolean {
    return (
      this.args.activeFilter === 'helpCenterIds' ||
      this.hasAppliedFilter('helpCenterIds') ||
      !!this.args.selectedHelpCenterIds
    );
  }

  get showStatusFilter(): boolean {
    return (
      this.args.activeFilter === 'status' || this.hasAppliedFilter('status') || !!this.args.status
    );
  }

  get showChatbotStateFilter(): boolean {
    if (!this.appService.app.canUseExternalAi) {
      return false;
    }

    return (
      this.args.activeFilter === 'chatbotState' ||
      this.hasAppliedFilter('chatbotState') ||
      !!this.args.chatbotState
    );
  }

  get showCopilotStateFilter(): boolean {
    if (!this.appService.app.canUseExternalAi) {
      return false;
    }

    return (
      this.args.activeFilter === 'copilotState' ||
      this.hasAppliedFilter('copilotState') ||
      !!this.args.copilotState
    );
  }

  get showCreatedByFilter(): boolean {
    return (
      this.args.activeFilter === 'createdBy' ||
      this.hasAppliedFilter('createdBy') ||
      !!this.args.createdBy
    );
  }

  get showLastUpdatedByFilter(): boolean {
    return (
      this.args.activeFilter === 'lastUpdatedBy' ||
      this.hasAppliedFilter('lastUpdatedBy') ||
      !!this.args.lastUpdatedBy
    );
  }

  get showTagFilter(): boolean {
    return this.args.activeFilter === 'tag' || this.hasAppliedFilter('tag') || !!this.args.tagIds;
  }

  get showCollectionsFilter(): boolean {
    return (
      this.args.activeFilter === 'collectionIds' ||
      this.hasAppliedFilter('collectionIds') ||
      !!this.args.collectionIds
    );
  }

  get showWrittenByFilter(): boolean {
    return (
      this.args.activeFilter === 'writtenByIds' ||
      this.hasAppliedFilter('writtenByIds') ||
      !!this.args.writtenByIds
    );
  }

  get showFolderFilter(): boolean {
    return (
      (this.args.activeFilter === 'folder' ||
        this.hasAppliedFilter('folder') ||
        !!this.args.selectedFolderFilterOption) &&
      !!this.args.setFolderFilterOption
    );
  }

  get showSyncIssueFilter(): boolean {
    return (
      this.internalArticleSelected &&
      (this.args.activeFilter === 'syncIssues' ||
        this.hasAppliedFilter('syncIssues') ||
        !!this.args.selectedSyncIssueOption) &&
      !!this.args.setSyncIssueFilterOption &&
      !this.isSyncIssuesLoading
    );
  }

  get articleContentSelected(): boolean {
    return this.args.selectedTypes?.includes(EntityType.ArticleContent) ?? false;
  }

  get internalArticleSelected(): boolean {
    return this.args.selectedTypes?.includes(EntityType.InternalArticle) ?? false;
  }

  get canUseHelpCenterFilter(): boolean {
    return this.helpCenterSites?.length > 1 && !this.appService.app.canUseStandalone;
  }

  get externalContentSelected(): boolean {
    return this.args.selectedTypes?.includes(EntityType.ExternalContent) ?? false;
  }

  get showUrlSourceFilter(): boolean {
    return (
      this.urlSources.length > 1 &&
      this.externalContentSelected &&
      (this.args.activeFilter === 'urlSource' ||
        this.hasAppliedFilter('urlSource') ||
        !!this.args.urlSourceId)
    );
  }

  @action async addFilter(filterType: string) {
    if (filterType === 'syncIssues') {
      if (this.args.setSyncIssueFilterOption) {
        this.args.setSyncIssueFilterOption(SyncIssuesFilterOptions.HasSyncIssues);
      }
      taskFor(this.searchBySyncIssue).perform(this.args.selectedSyncIssueOption);
    }

    this.args.setActiveFilter(filterType);
    if (this.args.filtersApplied && this.args.filtersApplied.length > 0) {
      this.args.setFiltersApplied([...this.args.filtersApplied, filterType]);
    } else {
      this.args.setFiltersApplied([filterType]);
    }
    this.trackAnalyticsEvent('clicked', 'add_filter_button', { filter_type: filterType });
  }

  @action removeFilter(filterType: string) {
    if (this.args.filtersApplied && this.args.filtersApplied.length > 0) {
      this.args.setFiltersApplied(
        this.args.filtersApplied.reject((filter) => filter === filterType),
      );
    }
    this.args.setActiveFilter(undefined);
  }

  @action
  searchByLocale(locale: string) {
    this.args.setLocale(locale);
    taskFor(this.search).perform({
      locales: locale ? [locale] : undefined,
    });
    this.trackFilterEvent('language_filter');
  }

  @action
  searchByDatePredicates(_field: string, predicates: Array<Predicate>) {
    this.args.setPredicates(predicates);
    taskFor(this.search).perform({ predicates });
    this.trackFilterEvent('date_filter');
  }

  @action
  searchByStatus(status: string) {
    this.args.setStatus(status);
    taskFor(this.search).perform({
      additional_searchable_data: {
        ...this.args.listApi.searchParams?.additional_searchable_data,
        article_published_state: status ? [Number(status)] : undefined,
      },
    });
    this.trackFilterEvent('status_filter');
  }

  @action
  searchByChatbotState(chatbotState: string) {
    this.args.setChatbotState(chatbotState);
    taskFor(this.search).perform({
      chatbot_state: chatbotState ? Number(chatbotState) : undefined,
    });
    this.trackFilterEvent('chatbot_state_filter');
  }

  @action
  searchByCopilotState(copilotState: string) {
    this.args.setCopilotState(copilotState);
    taskFor(this.search).perform({
      copilot_state: copilotState ? Number(copilotState) : undefined,
    });
    this.trackFilterEvent('copilot_state_filter');
  }

  @action
  searchByCreatedBy(createdBy?: string) {
    this.args.setCreatedBy(createdBy);
    taskFor(this.search).perform({
      created_by_ids: createdBy ? [Number(createdBy)] : undefined,
    });
    this.trackFilterEvent('created_by_filter');
  }

  @action
  searchByTypes(objectTypes?: Array<EntityType>) {
    this.args.setSelectedTypes(objectTypes);
    this.updateAssociatedObjectTypeFilters();
    taskFor(this.search).perform({
      object_types:
        objectTypes && objectTypes.length > 0
          ? objectTypes
          : [...this.knowledgeHubService.getSearchObjectTypes()],
      states: undefined,
      additional_searchable_data: {
        article_published_state: this.args.status ? [Number(this.args.status)] : undefined,
        help_center_ids: this.args.selectedHelpCenterIds?.map((id) => Number(id)),
        needs_review: this.args.selectedSyncIssueOption,
        ...this.args.listApi.searchParams?.additional_searchable_data,
      },
    });
    this.trackFilterEvent('type_filter');
  }

  @action
  updateAssociatedObjectTypeFilters() {
    if (!this.internalArticleSelected) {
      this.removeInternalArticleFilters();
    }
  }

  @action
  searchByTagIds(_: string, tagIds: string[]) {
    this.args.setTagIds(tagIds);
    let searchParams = {};
    searchParams = {
      tag_ids: tagIds && tagIds.length > 0 ? tagIds.map((id) => Number(id)) : undefined,
      tag_operator: this.args.tagOperator,
    };
    taskFor(this.search).perform(searchParams);
    this.trackFilterEvent('tag_filter');
  }

  @action
  searchByCollectionIds(collectionIds?: string[]) {
    this.args.setCollectionIds(collectionIds);
    taskFor(this.search).perform({
      additional_searchable_data: {
        ...this.args.listApi.searchParams?.additional_searchable_data,
        collection_ids: collectionIds,
      },
    });
    this.trackFilterEvent('collection_filter');
  }

  @action
  searchByWrittenByIds(writtenByIds?: string[]) {
    this.args.setWrittenByIds(writtenByIds);
    taskFor(this.search).perform({
      sender_ids: writtenByIds,
    });
    this.trackFilterEvent('written_by_filter');
  }

  @action
  clearSelectedTagValues() {
    taskFor(this.search).perform({ tag_ids: undefined, tag_operator: undefined });
    this.removeFilter('tag');
    this.args.setTagIds(undefined);
    this.args.setTagOperator(IN_OPERATOR);
  }

  @action
  removeHelpCenterFilter() {
    this.removeFilter('helpCenterIds');
    this.args.setSelectedHelpCenterIds(undefined);
  }

  removeInternalArticleFilters() {
    if (this.args.selectedSyncIssueOption) {
      this.removeFilter('syncIssues');
      if (this.args.setSyncIssueFilterOption) {
        this.args.setSyncIssueFilterOption(undefined);
      }
    }
  }

  @action removeCollectionsFilter() {
    this.removeFilter('collectionIds');
    this.args.setCollectionIds(undefined);
  }

  @action removeWrittenByFilter() {
    this.removeFilter('writtenByIds');
    this.args.setWrittenByIds(undefined);
  }

  @action
  searchByHelpCenterIds(helpCenterIds?: Array<string>) {
    this.args.setSelectedHelpCenterIds(helpCenterIds);
    let searchParams = {};
    searchParams = {
      additional_searchable_data: {
        ...this.args.listApi.searchParams?.additional_searchable_data,
        help_center_ids:
          helpCenterIds && helpCenterIds.length > 0
            ? helpCenterIds.map((id) => Number(id))
            : undefined,
      },
    };
    taskFor(this.search).perform(searchParams);
    this.trackFilterEvent('help_center_filter');
  }

  @action
  searchByUrlSourceId(urlSourceId?: string) {
    this.args.setUrlSourceId(urlSourceId);
    taskFor(this.search).perform({
      additional_searchable_data: {
        ...this.args.listApi.searchParams?.additional_searchable_data,
        source_id: urlSourceId ? Number(urlSourceId) : undefined,
      },
    });
    this.trackFilterEvent('url_source_filter');
  }

  @action
  searchByLastUpdatedBy(lastUpdatedBy?: string) {
    this.args.setLastUpdatedBy(lastUpdatedBy);
    taskFor(this.search).perform({
      last_updated_by_ids: lastUpdatedBy ? [Number(lastUpdatedBy)] : undefined,
    });
    this.trackFilterEvent('last_updated_by_filter');
  }

  @action
  searchByFolderFilterOption(filterOption?: FolderFilterOption) {
    this.args.setFolderFilterOption!(filterOption);
    taskFor(this.search).perform({
      folder_filter_option: filterOption,
    });
    this.trackFilterEvent('folder_filter');
  }

  @restartableTask
  *searchBySyncIssue(filterOption?: SyncIssuesFilterOptions): TaskGenerator<void> {
    this.args.setSyncIssueFilterOption!(filterOption);
    this.args.resetSelectedFragments();
    yield this.args.listApi.searchByParams({
      additional_searchable_data: {
        ...this.args.listApi.searchParams?.additional_searchable_data,
        needs_review: filterOption,
      },
    });
  }

  @action beforeSearch() {
    this.args.beforeSearch?.();
  }

  @restartableTask
  *search(searchParams: any): TaskGenerator<void> {
    this.beforeSearch();
    this.args.resetSelectedFragments();
    yield this.args.listApi.searchByParams(searchParams);
  }

  private trackFilterEvent(object: string): void {
    this.trackAnalyticsEvent('filter', object, {
      locale: this.args.locale,
      createdBy: this.args.createdBy,
      type: this.args.selectedTypes,
      status: this.args.status,
    });
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'knowledge_hub',
      context: 'all_content',
      place: 'all_content',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Filters': typeof Filters;
    'knowledge-hub/filters': typeof Filters;
  }
}
