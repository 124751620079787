/* RESPONSIBLE TEAM: team-workflows */
import StepConfig, { type StepConfigParams } from '../step';
import type WaitForCallback from 'embercom/models/operator/visual-builder/step/wait-for-callback';

export default class WaitForCallbackConfiguration extends StepConfig {
  declare step: WaitForCallback;
  protected attributeService: any;
  protected store: any;
  protected app: any;

  constructor(
    params: Omit<StepConfigParams, 'component'> & {
      component?: string;
      attributeService: any;
      store: any;
      app: any;
    },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/wait-for-callback',
      ...params,
    });

    this.attributeService = params.attributeService;
    this.store = params.store;
    this.app = params.app;
  }

  deleteStep() {
    if (this.step.asyncCallbackAttributeDescriptorIdentifier) {
      let savedRootLocalVariable = this.store.peekRecord(
        'operator/visual-builder/attribute-descriptor',
        this.step.asyncCallbackAttributeDescriptorIdentifier,
      );
      this.editorState.deleteLocalVariable(savedRootLocalVariable);
    }
    super.deleteStep();
  }
}
