/* RESPONSIBLE TEAM: team-data-foundations */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { getSamlSignInErrorMessage } from 'embercom/lib/saml';

export default class SignInRoute extends Route {
  @service store;
  @service redirectService;
  @service iamService;
  @service notificationsService;

  beforeModel(transition) {
    this.iamService.boot();

    let errorCode = transition.to.queryParams.error_code;
    if (errorCode !== undefined) {
      let errorMessage = getSamlSignInErrorMessage(errorCode);
      this.notificationsService.notifyError(errorMessage, 0);
    }
  }

  model(params) {
    return this.store.findRecord('app-sign-in-configuration', params.app_id);
  }

  afterModel(model) {
    if (model.signedInWithAuthorizedMethod === true) {
      this.transitionTo('apps.app', model.id);
    }
  }
}
