/* import __COLOCATED_TEMPLATE__ from './teammate-role-data-modal.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import RouteRegexes from 'embercom/lib/route-regexes';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

export const DISMISSAL_COUNT = 'teammate_role_data_dismissal_count';
export const LAST_DISMISSED_AT = 'teammate_role_data_last_dismissed_at';
export const MAX_DISMISSALS = 2;
export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
export const ONE_HOUR_IN_MS = 60 * 60 * 1000;
export const USE_CASE_OTHER = 'use_case_other';
export const DEPARTMENT_OTHER = 'dept_other';
export const OTHER_OTHER = 'other_other';
export const DONT_ASK_ME_AGAIN = 'dont_ask_me_again';
export const TOOL_OTHER = 'tool_other';

export default class TeammateRoleDataModal extends Component {
  @service onboardingHomeService;
  @service appService;
  @service notificationsService;
  @service modelDataCacheService;
  @service intercomEventService;
  @service router;
  @service intl;
  @service cardlessTrialService;
  @tracked showModal = true;
  @tracked leadSurveyCompletedByOtherAdmin = false;
  @tracked selectedUseCase;
  @tracked selectedExperience;
  @tracked selectedDepartment;
  @tracked selectedRole;
  @tracked useCaseOther;
  @tracked departmentOther;
  @tracked selectedIntentLevel;
  @tracked selectedTool;
  @tracked selectedToolOther;
  @tracked manageTeam = '';
  @tracked roleOther;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get adminHasNotCompletedTeammateRoleDataSurvey() {
    return !this.admin.teammate_role_data_completed;
  }

  get adminIsNew() {
    return Date.now() - new Date(this.admin.created_at).getTime() < ONE_HOUR_IN_MS;
  }

  get onAllowedRoute() {
    return (
      this.router.currentRouteName.match(/^apps\.app/) &&
      !this.router.currentRouteName.match(RouteRegexes.wizard) &&
      !this.router.currentRouteName.match(RouteRegexes.excludeFromTeammateRoleDataSurvey)
    );
  }

  get onAllowedRouteForProfileSurvey() {
    return (
      this.router.currentRouteName.match(/^apps\.app/) &&
      !this.router.currentRouteName.match(RouteRegexes.excludeFromProfileSurvey)
    );
  }

  get showProfilingSurvey() {
    return (
      this.app.canSeeProfilingSurvey &&
      this.onAllowedRouteForProfileSurvey &&
      !this.leadSurveyCompletedByOtherAdmin &&
      this.cardlessTrialService.hasActiveTrialSubscriptionOrIsInSelfServeTrial &&
      !this.app.canUseStandalone
    );
  }

  get isEngineerInvite() {
    return this.router?.currentRoute?.queryParams.engineerInvite?.toString() === 'true';
  }

  get showTeammateRoleDataModal() {
    if (
      this.adminHasNotCompletedTeammateRoleDataSurvey &&
      this.onAllowedRoute &&
      this.app.isNotImpersonationSession &&
      this.lastDismissedMoreThanADayAgo &&
      this.showModal &&
      this.adminCreatedMoreThanADayAgo &&
      this.shouldShowModalWithLeadProfileSurveyFlag &&
      !this.isEngineerInvite &&
      !this.app.canUseStandalone
    ) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'teammate_role_data_modal',
      });
      return true;
    }
    return false;
  }

  get adminCreatedMoreThanADayAgo() {
    if (this.app.canSeeProfilingSurvey) {
      return true;
    }
    return Date.now() - new Date(this.admin.created_at).getTime() > ONE_DAY_IN_MS;
  }

  get lastDismissedMoreThanADayAgo() {
    let lastDismissedAt = localStorage.getItem(LAST_DISMISSED_AT);
    return lastDismissedAt === null || parseInt(lastDismissedAt, 10) + ONE_DAY_IN_MS < Date.now();
  }

  get surveyCompleted() {
    if (this.app.canSeeProfilingSurvey) {
      return this.departmentCompleted && this.manageTeamCompleted;
    }
    return this.departmentCompleted && this.manageTeamCompleted && this.toolsSelectionCompleted;
  }

  get useCaseCompleted() {
    return (
      (this.selectedUseCase && this.selectedUseCase !== 'use_case_other') ||
      (this.selectedUseCase === 'use_case_other' && this.useCaseOther && this.useCaseOther.trim())
    );
  }

  get experienceCompleted() {
    return this.selectedExperience && this.selectedExperience.trim();
  }

  get intentLevelCompleted() {
    return this.selectedIntentLevel && this.selectedIntentLevel.trim();
  }

  get manageTeamCompleted() {
    return this.manageTeam && this.manageTeam.trim();
  }

  get departmentCompleted() {
    return (
      (this.selectedDepartment && this.selectedDepartment !== 'dept_other') ||
      (this.selectedDepartment === 'dept_other' &&
        this.departmentOther &&
        this.departmentOther.trim())
    );
  }

  get toolsSelectionCompleted() {
    return (
      (this.selectedTool && this.selectedTool !== 'tool_other') ||
      (this.selectedTool === 'tool_other' &&
        this.selectedToolOther &&
        this.selectedToolOther.trim())
    );
  }

  get roleCompleted() {
    return (
      this.selectedRole &&
      (!this.roleIsOther || (this.roleIsOther && this.roleOther && this.roleOther.trim()))
    );
  }

  get roleIsOther() {
    return this.selectedRole && this.selectedRole.endsWith('_other');
  }

  get currentDismissalCount() {
    let dismissalCount = localStorage.getItem(DISMISSAL_COUNT);
    return dismissalCount ? parseInt(dismissalCount, 10) : 0;
  }

  get modalCanBeDismissed() {
    return !this.adminIsNew && this.currentDismissalCount < MAX_DISMISSALS;
  }

  get shouldShowModalWithLeadProfileSurveyFlag() {
    if (this.app.canSeeProfilingSurvey) {
      return this.leadSurveyCompletedByOtherAdmin;
    }
    return true;
  }

  @action setSelectedDepartment(selectedItem) {
    if (this.selectedDepartment !== selectedItem) {
      this.selectedDepartment = selectedItem;
      this.selectedRole = undefined;
    }
  }

  @action updateLeadSurveyCompletionStatus(completedAdminId) {
    this.leadSurveyCompletedByOtherAdmin = parseInt(this.admin.id, 10) !== completedAdminId;
  }

  @action hideTeammateRoleDataModal() {
    this.onboardingHomeService.showContentIngestionToastIfSetInStorage();
    this.showModal = false;
  }

  @action dismissModal() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('components.teammate-role-data.will_ask_in_a_day'),
    );
    this.storeToLocalStorage();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'dismissed',
      object: 'teammate_role_data_modal',
    });

    this.hideTeammateRoleDataModal();
  }

  @action storeToLocalStorage() {
    let dismissals = localStorage.getItem(DISMISSAL_COUNT);
    localStorage.setItem(DISMISSAL_COUNT, parseInt(dismissals || 0, 10) + 1);
    localStorage.setItem(LAST_DISMISSED_AT, Date.now());
  }

  @task *submitTeammateRoleData(dataCollectionConsent) {
    try {
      let dataCollectionPermitted = !(dataCollectionConsent === 'declined');

      yield this.saveTeammateRoleData(dataCollectionPermitted);
      localStorage.removeItem(DISMISSAL_COUNT);
      localStorage.removeItem(LAST_DISMISSED_AT);

      if (dataCollectionPermitted) {
        this.notificationsService.notifyConfirmation(
          this.intl.t('components.teammate-role-data.thanks_for_tailor_your_experience'),
        );
        this.intercomEventService.trackAnalyticsEvent({
          action: 'saved',
          object: 'teammate_role_data_modal',
          role_data: {
            use_case: this.selectedUseCase,
            department: this.selectedDepartment,
            role: this.selectedRole,
            experience: this.selectedExperience,
            intent_level: this.selectedIntentLevel,
            manage_team: this.manageTeam,
            tool: this.selectedTool,
          },
        });
      } else {
        this.notificationsService.notifyConfirmation(
          this.intl.t('components.teammate-role-data.wont_ask_again'),
        );
        this.intercomEventService.trackAnalyticsEvent({
          action: 'declined',
          object: 'teammate_role_data_modal',
        });
      }

      this.hideTeammateRoleDataModal();
      this.admin.set('teammate_role_data_completed', true);
      this.modelDataCacheService.clear();
      if (this.selectedRole === 'support_support_rep' && this.app.admins.length > 1) {
        this.router.transitionTo(
          'inbox.workspace.inbox.inbox',
          this.app.id,
          InboxCategory.Shared,
          InboxType.All,
          {
            queryParams: {
              force: true,
            },
          },
        );
      }
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('components.teammate-role-data.unable_to_save_data'),
      );
      this.hideTeammateRoleDataModal();
    }
  }

  saveTeammateRoleData(dataCollectionPermitted) {
    let tool = null;
    if (!this.app.canSeeProfilingSurvey) {
      tool = dataCollectionPermitted ? this.selectedTool : TOOL_OTHER;
    }

    let params = {
      app_id: this.app.id,
      admin_id: this.admin.id,
      use_case: USE_CASE_OTHER,
      department: dataCollectionPermitted ? this.selectedDepartment : DEPARTMENT_OTHER,
      role: OTHER_OTHER,
      use_case_other: dataCollectionPermitted ? this.useCaseOther : DONT_ASK_ME_AGAIN,
      department_other: dataCollectionPermitted ? this.departmentOther : DONT_ASK_ME_AGAIN,
      role_other: dataCollectionPermitted ? this.roleOther : DONT_ASK_ME_AGAIN,
      experience: dataCollectionPermitted ? this.selectedExperience : DONT_ASK_ME_AGAIN,
      intent_level: dataCollectionPermitted ? this.selectedIntentLevel : DONT_ASK_ME_AGAIN,
      manage_team: dataCollectionPermitted ? this.manageTeam : DONT_ASK_ME_AGAIN,
      tool,
      tool_other: dataCollectionPermitted ? this.selectedToolOther : DONT_ASK_ME_AGAIN,
    };
    return post('/ember/teammate_role_data', params);
  }
}
