/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type IntlService from 'embercom/services/intl';
import type ContentImportService from 'embercom/services/content-import-service';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type RouterService from '@ember/routing/router-service';

export default class KnowledgeHubRoute extends Route {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare helpCenterService: $TSFixMe;
  @service declare contentImportService: ContentImportService;
  @service declare conversationExtractionService: ConversationExtractionService;
  @service declare finStandaloneService: FinStandaloneService;

  get titleToken(): string {
    return this.intl.t('knowledge-hub.title');
  }

  get app() {
    return this.appService.app;
  }

  private async fetchLocales() {
    // when there's inflight request to fetch all sites, we won't force fetch again
    if (!this.helpCenterService.fetchingSite) {
      await this.helpCenterService.forceFetchSites();
    }

    return this.knowledgeHubService.fetchAvailableLocales();
  }

  async beforeModel() {
    if (this.app.optimizeKnowledgePerformance) {
      ConversationAttributeDescriptor.peekAllAndMaybeLoad();

      let promises = [
        this.knowledgeHubService.loadKnowledgeTree(),
        this.knowledgeHubService.getOverviewChecklistAttributes(),
        this.contentImportService.fetchContentImportSources(),
        this.knowledgeHubService.fetchImportSources(),
        this.fetchLocales(),
        this.knowledgeHubService.fetchFoldersOnce(),
        this.knowledgeHubService.fetchArticleCollections(),
        ...(this.appService.app.canUseConversationExtractionExperiment
          ? [this.conversationExtractionService.fetchConversationExtractionSettings()]
          : []),
        ...(this.appService.app.canUseStandalone ? [this.finStandaloneService.initialize()] : []),
      ];

      await Promise.all(promises);
    } else {
      if (!this.helpCenterService.fetchingSite) {
        await this.helpCenterService.forceFetchSite();
      }
      ConversationAttributeDescriptor.peekAllAndMaybeLoad();

      let promises = [
        this.knowledgeHubService.loadKnowledgeTree(),
        this.knowledgeHubService.getOverviewChecklistAttributes(),
        this.knowledgeHubService.fetchKnowledgeUsageSummary(),
        this.contentImportService.fetchContentImportSources(),
        this.knowledgeHubService.fetchImportSources(),
        this.knowledgeHubService.fetchAvailableLocales(),
        this.knowledgeHubService.fetchFoldersOnce(),
        this.knowledgeHubService.fetchArticleCollections(),
      ];

      if (this.appService.app.canUseConversationExtractionExperiment) {
        promises.push(this.conversationExtractionService.fetchConversationExtractionSettings());
      }

      if (this.appService.app.canUseStandalone) {
        promises.push(this.finStandaloneService.initialize());
      }

      await Promise.all(promises);
    }
  }
}
