/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Model, { attr, belongsTo } from '@ember-data/model';
import type Admin from 'embercom/models/admin';
import { EntityType } from 'embercom/models/data/entity-types';
import { type KnowledgeHubContent } from 'embercom/objects/knowledge-hub/knowledge-hub-content';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import {
  ContentAction,
  type LocalizedKnowledgeContent,
  LocalizedKnowledgeContentMixin,
} from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type ArticleMultilingual from '../article-multilingual';
import type Folder from '../content-service/folder';
import type FolderMembership from '../content-service/folder-membership';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { taskFor } from 'ember-concurrency-ts';
import { type AiContentState, FinAvailability } from 'embercom/lib/ai-content-library/constants';
import {
  CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION,
  CAN_MANAGE_ARTICLES_PERMISSION,
  RTL_LANGUAGES,
} from 'embercom/lib/articles/constants';
import { type LocalizedKnowledgeContentVersion } from 'embercom/objects/knowledge-hub/localized-knowledge-content-version';
import { ARTICLES_FEATURE, MULTILINGUAL_ARTICLES_FEATURE } from 'embercom/lib/billing';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type HelpCenterSite from 'embercom/models/help-center-site';
import KnowledgeHubItemMixin from 'embercom/models/content-service/knowledge-hub-item';
import { moveToFolderAndSave } from 'embercom/objects/content-service/folders/membership-child';
import type IntlService from 'ember-intl/services/intl';
import type { Block, Heading, List, Table } from '@intercom/interblocks.ts';
import type { Step } from 'prosemirror-transform';
import md5 from 'blueimp-md5';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import type ContentReviewRequest from '../content-service/content-review-request';

export default class ArticleContent
  extends KnowledgeHubItemMixin(LocalizedKnowledgeContentMixin(Model))
  // TODO This class shouldn't be implementing KnowledgeHubContent, instead ArticleMultilingual should be.
  implements KnowledgeHubContent, LocalizedKnowledgeContent, LocalizedKnowledgeContentVersion
{
  @service declare appService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare knowledgeHubService: KnowledgeHubService;

  @attr('string', { defaultValue: '' }) declare title: string;
  @attr('string') declare authorId: string | undefined;
  @attr('number') declare createdById: number;
  @attr('string') declare locale: string;
  @attr('string', { defaultValue: 'draft' }) declare state: 'draft' | 'published' | undefined;
  @attr('string') declare summary: string;
  @attr('date') declare updatedAt: Date;
  @attr() declare jsonBlocks: Array<Block>;
  @attr() declare jsonBlocksForEditing: Array<Block>;
  @attr('string') declare publicUrl: string;
  @attr('string') declare latestVersionId: string;
  @attr('string') declare liveVersionId: string;
  @attr('string') declare externalUrl: string;

  @attr('boolean') declare needsReview: boolean;
  @attr('string') declare siteProvider: string;
  @attr('boolean') declare autosaving: boolean;

  @attr('number') declare finState: AiContentState;
  @attr('boolean') declare eligibleForChatbot: boolean;
  // @deprecated Use chatbotAvailability or copilotAvailability instead.
  @attr('number') declare finAvailability: FinAvailability;
  @attr('number') declare chatbotAvailability: FinAvailability;
  @attr('number') declare copilotAvailability: FinAvailability;
  @attr('array') declare aiContentSegmentIds: Array<number> | [];

  @attr('string') declare articleId: string;
  @attr() declare stepsSinceLastVersion: Array<Step> | [];
  @attr('boolean') declare isRestoringVersion: boolean;
  @belongsTo('content-service/content-review-request', { async: true })
  declare contentReviewRequest: ContentReviewRequest;

  lastSavedAt = undefined;

  @attr('string') declare translationCreatedById: string;
  @belongsTo('article-multilingual', { inverse: 'articleContents', async: false })
  declare article: ArticleMultilingual;
  @belongsTo('content-service/folder', { async: true })
  declare folder?: Folder;
  @belongsTo('content-service/folder-membership', { async: false })
  declare folderMembership?: FolderMembership;

  readonly entityType = EntityType.ArticleContent;

  get app() {
    return this.appService.app;
  }

  get requiredEditPermission() {
    return CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
  }

  get requiredDeletePermission() {
    if (this.isDraft) {
      return CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
    }

    return CAN_MANAGE_ARTICLES_PERMISSION;
  }

  get createdBy() {
    return this.app.humanAdmins.findBy('id', this.createdById);
  }

  set createdBy(value) {
    this.createdById = value.get('id');
  }

  get isPublished() {
    return this.state === 'published';
  }

  get isNotPublished() {
    return !this.isPublished;
  }

  get isDraft() {
    return this.state === 'draft';
  }

  get hasSummary() {
    if (this.summary) {
      return true;
    }
    return false;
  }

  get titleForDisplay() {
    return this.title ?? this.intl.t('articles.new-article');
  }

  get headingBlocks() {
    return this._extractHeadingBlocks(this.jsonBlocks);
  }

  get hasChanges() {
    return this.hasDirtyAttributes;
  }

  get hash() {
    return md5(JSON.stringify(this.serialize()));
  }

  getAnchorLinkPreview(anchorLinkId: string) {
    let anchorLinkIndex = this.jsonBlocks.findIndex(
      (b) => (b as Heading).idAttribute === anchorLinkId,
    );
    if (anchorLinkIndex === -1) {
      return null;
    }

    for (let block of this.jsonBlocks.slice(anchorLinkIndex + 1)) {
      if (block.type === 'paragraph' && block.text.replace(/^(&nbsp;)*/, '').trim() !== '') {
        let text = block.text.replace(/^(&nbsp;)*/, '').trim();
        return text;
      } else if (block.type === 'unorderedList' || block.type === 'orderedList') {
        let text = (block as List).items
          .filter((i) => i.replace(/^(&nbsp;)*/, '').trim() !== '')
          .join(', ');
        if (text !== '') {
          return text;
        }
      } else if (
        (block.type === 'heading' || block.type === 'subheading') &&
        (block as Heading).idAttribute
      ) {
        break;
      }
    }

    return null;
  }

  // @ts-ignore
  async save(options = { isAutoSave: false, adapterOptions: {} }) {
    // You cannot set @attr values if the record is being deleted. (save is called internally by destroyRecord)
    let isAutoSave = options?.isAutoSave ?? false;

    if (!this.isDeleted) {
      this.autosaving = isAutoSave;
    }
    let originalResult;
    try {
      originalResult = await super.save({ adapterOptions: options.adapterOptions });
    } finally {
      if (!this.isDeleted) {
        this.autosaving = false;
      }
    }

    this.set('lastSavedAt', new Date());
    this.removeSavedStepsOnComplete(this.stepsSinceLastVersion?.length);

    return originalResult;
  }

  removeSavedStepsOnComplete(versionStepsLengthAtSave = 0) {
    if (!this.isDeleted) {
      this.stepsSinceLastVersion = this.stepsSinceLastVersion?.slice(versionStepsLengthAtSave);
    }
  }

  async restoreVersion(version: ArticleContent) {
    this.rollbackAttributes();

    this.set('title', version.title);
    this.set('summary', version.summary);
    this.set('authorId', version.authorId);
    this.set('jsonBlocks', version.jsonBlocks);
    this.set('jsonBlocksForEditing', version.jsonBlocks);
    this.set('stepsSinceLastVersion', []);
    this.set('isRestoringVersion', true);

    await this.save();
    this.rollbackAttributes();
  }

  @task
  *publish(): Generator<$TSFixMe> {
    return yield ajax({
      type: 'POST',
      url: `/ember/article_contents/${this.id}/publish?app_id=${this.app.id}`,
      data: JSON.stringify({
        id: this.id,
        version_id: this.latestVersionId,
      }),
    });
  }

  @task
  *unpublish(): Generator<$TSFixMe> {
    return yield ajax({
      type: 'POST',
      url: `/ember/article_contents/${this.id}/unpublish?app_id=${this.app.id}`,
      data: JSON.stringify({
        id: this.id,
        version_id: this.latestVersionId,
      }),
    });
  }

  _extractHeadingBlocks(blocks: Block[]) {
    let headings: Heading[] = [];

    blocks.forEach((block) => {
      if (
        block.type === 'heading' ||
        block.type === 'subheading' ||
        block.type === 'subheading3' ||
        block.type === 'subheading4'
      ) {
        headings.push(block as Heading);
      } else if (block.type === 'table') {
        (block as Table).rows.forEach((row) => {
          row.cells.forEach((cell) => {
            headings.push(...this._extractHeadingBlocks(cell.content));
          }, this);
        }, this);
      }
    }, this);

    return headings;
  }

  get editorConfig(): KnowledgeHubEditorConfig {
    let sidePanelConfig = [
      { name: 'knowledge-hub/content-editor/article-content/data-section' },
      ...(this.appService.app.canUseExternalAi
        ? [{ name: 'knowledge-hub/content-editor/shared/fin-section' }]
        : []),
    ];

    if (!this.appService.app.canUseStandalone) {
      sidePanelConfig.push(
        {
          name: 'knowledge-hub/content-editor/article-content/side-panel/help-center-section',
        },
        { name: 'knowledge-hub/content-editor/shared/reporting-section' },
        { name: 'knowledge-hub/content-editor/shared/tags-section' },
      );
    }

    sidePanelConfig.push({ name: 'knowledge-hub/content-editor/folder-section' });

    return {
      viewMode: {
        headerConfig: { name: 'knowledge-hub/content-editor/article-content/view/header' },
        editorConfig: { name: 'knowledge-hub/content-editor/article-content/view/editor' },
        sidePanelConfig,
      },
      editMode: {
        headerConfig: { name: 'knowledge-hub/content-editor/article-content/edit/header' },
        editorConfig: { name: 'knowledge-hub/content-editor/article-content/edit/editor' },
        sidePanelConfig,
      },
      paywallConfig: [
        // Paywalls are in order of priority
        {
          featureKey: ARTICLES_FEATURE,
          bannerText: this.intl.t(
            'knowledge-hub.content-editor.article-content.edit.paywall-banner',
          ),
          canShow: true,
        },
        {
          featureKey: MULTILINGUAL_ARTICLES_FEATURE,
          bannerText: this.intl.t(
            'knowledge-hub.content-editor.article-content.edit.paywall-banner-multilingual',
          ),
          canShow: !this.isInDefaultLocale,
        },
      ],
      statsConfig: {
        tabStatisticKeyNames: ['articleContentView', 'replies', 'reactions'],
        bodyConfig: { name: 'knowledge-hub/content-editor/article-content/stats-body' },
      },
    };
  }

  get isInDefaultLocale() {
    return this.locale === this.helpCenterService.defaultSite.defaultLocale.localeId;
  }

  get owner() {
    return this.createdBy;
  }

  get author() {
    return this.app.humanAdmins.findBy('id', this.authorId);
  }

  set author(newAuthor: Admin) {
    this.authorId = newAuthor.id;
  }

  get textDirection() {
    if (RTL_LANGUAGES.includes(this.locale)) {
      return 'rtl';
    }
    return 'ltr';
  }

  get isLive() {
    return this.isPublished;
  }

  get autoSaveEnabled() {
    // only allow auto-save for draft articles
    return !this.isPublished;
  }

  get hasUnsavedChanges() {
    return this.hasChanges;
  }

  get saving() {
    return this.isSaving;
  }

  async publishContent() {
    // Typescript throws an error when directly using the model's computed attribute, so we need to convert it to a boolean
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!this.hasUnsavedChanges) {
      await this.save();
    }
    await taskFor(this.publish).perform();
  }

  async unpublishContent() {
    await taskFor(this.unpublish).perform();
  }

  // we want to load article and the article groups for a given article-content, within the content-editor.
  async loadRelatedContent(action: ContentAction) {
    this.article = await this.store.findRecord('article-multilingual', this.articleId, {
      include: action === ContentAction.EDIT ? 'latest_version' : 'live_version',
    });
    await this.store.findAll('articles/article-group');
  }

  get hasUnpublishedDraftVersion() {
    return !!this.liveVersionId && this.latestVersionId !== this.liveVersionId;
  }

  get isInHelpCenter() {
    return this.article?.helpCenterIds.length > 0;
  }

  get isInCollection() {
    return this.article?.inCollections?.toArray().length > 0;
  }

  get hasLiveHelpCenter() {
    let availableSites = this.shouldSkipWebsideTurnedOn
      ? this.helpCenterService.allSites?.toArray()
      : this.helpCenterService.allSites.filter((site: HelpCenterSite) => site.websiteTurnedOn);
    return availableSites.some((site: HelpCenterSite) =>
      this.article?.helpCenterIds.includes(site.id),
    );
  }

  get shouldSkipWebsideTurnedOn() {
    // we skip this validation for standalone apps there are customer which use their own platform and this function is not turned-on for them
    return (
      this.appService.app.canUseStandalone && this.appService.app.canUseExternalArticlesAsFinContent
    );
  }

  async isPublishedInHelpCenter() {
    if (!this.isPublished || !this.isInHelpCenter || !this.isInCollection) {
      return false;
    }
    return this.hasLiveHelpCenter;
  }

  // start: KnowledgeHubContent / KnowledgeHubItemInterface

  get displayName() {
    return this.title;
  }

  get entityId(): number {
    return Number(this.id);
  }

  get entityIdForMembership() {
    // @ts-ignore - TS doesn't like the 'article' key here for some reason
    let articleRelationship = this.belongsTo('article');
    return Number(articleRelationship.id());
  }

  get entityTypeForMembership() {
    return EntityType.Article;
  }

  get parentIdFromRelationship(): string | undefined {
    // @ts-ignore - TS doesn't like the 'folder' key here for some reason
    let folderRelationship = this.belongsTo('folder');
    return folderRelationship?.id();
  }

  get parent(): Folder | undefined {
    return this.folder;
  }

  set parent(parent: Folder | undefined) {
    this.folder = parent;
    this.onParentChange(parent);
  }

  get isEditable(): boolean {
    return this.article ? !this.article.readOnly : true;
  }

  get chatbotAvailabilityOption(): FinAvailability {
    return this.chatbotAvailability;
  }
  set chatbotAvailabilityOption(availability: FinAvailability) {
    this.chatbotAvailability = availability;
  }

  get copilotAvailabilityOption(): FinAvailability {
    return this.copilotAvailability;
  }
  set copilotAvailabilityOption(availability: FinAvailability) {
    this.copilotAvailability = availability;
  }

  get requiredEditPermissionForKnowledgeHub() {
    return CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
  }

  get requiredDeletePermissionForKnowledgeHub() {
    return this.isLive
      ? CAN_MANAGE_ARTICLES_PERMISSION
      : CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
  }

  get requiredPublishPermissionForKnowledgeHub() {
    return CAN_MANAGE_ARTICLES_PERMISSION;
  }

  async isEligibleForCopilot() {
    if (this.app.canUseUnlistedArticlesForFin) {
      return this.isLive;
    }

    return await this.isPublishedInHelpCenter();
  }

  async isEligibleForChatbot() {
    if (this.app.canUseUnlistedArticlesForFin) {
      return this.isLive;
    }
    return await this.isPublishedInHelpCenter();
  }

  get copilotAvailableValue(): FinAvailability {
    return FinAvailability.DERIVED;
  }

  get chatbotAvailableValue(): FinAvailability {
    return FinAvailability.DERIVED;
  }

  get latestVersion() {
    return this;
  }

  get defaultTitle() {
    return this.intl.t('knowledge-hub.content-editor.article-content.placeholder-title');
  }

  get localization() {
    return this;
  }

  get versionTitle(): string | undefined {
    return this.title;
  }

  set versionTitle(value: string | undefined) {
    this.title = value || '';
  }

  get createdAt() {
    return this.article?.createdAt;
  }

  get creatorId(): number | undefined {
    return Number(this.translationCreatedById);
  }

  get writerId(): string | undefined {
    return this.authorId;
  }

  set writerId(writerId: string | undefined) {
    this.authorId = writerId;
  }

  get updaterId(): number | undefined {
    return this.createdById;
  }

  get publishingStatus(): 'draft' | 'published' | undefined {
    return this.state;
  }

  get taggable() {
    // this breaks on deletion of article without the null check
    return this.article?.taggable;
  }

  // end: KnowledgeHubContent / KnowledgeHubItemInterface

  get isSaveDisabled() {
    return (
      this.knowledgeHubEditorService.isPublishing ||
      !this.validations.isValid ||
      !this.hasUnsavedChanges ||
      this.isReloading
    );
  }

  get isPublishDisabled(): boolean {
    return Boolean(
      !this.validations.isValid ||
        this.saving ||
        (this.isPublished && this.isSaveDisabled) ||
        this.title === this.defaultTitle,
    );
  }

  // TODO Replace this with a method on the KnowledgeHubContent interface
  get hasSiblingTranslations(): boolean {
    return this.article?.isMultilingual ?? false;
  }

  // TODO Move this to the ArticleMultilingual model
  async moveToFolderAndSave({
    folder,
    disableConfirmation,
  }: {
    folder?: Folder;
    disableConfirmation?: boolean;
  }): Promise<void> {
    return moveToFolderAndSave({
      contentItem: this,
      destinationFolder: folder,
      disableConfirmation,
    });
  }

  get parentContent(): KnowledgeHubContent {
    // TODO This should return the ArticleMultilingual model instead but that doesn't implement KnowledgeHubContent yet.
    return this;
  }
}
