/* RESPONSIBLE TEAM: team-help-desk-experience */
export enum RenderableType {
  Unknown = -1,
  // UserMessage = 0,
  UserComment = 1,
  AdminComment = 2,
  AdminNote = 3,
  // OutboundMessage = 4,
  Assignment = 5,
  StateChange = 6,
  BotComment = 7,
  BotNote = 8,
  QuickReplyOptions = 9,
  QuickReplyResponse = 10,
  AttributeCollector = 11,
  UserEmailComment = 12,
  AttributeCollected = 13,
  ConversationAttributeUpdated = 14,
  TitleChanged = 15,
  Chat = 16,
  ConversationSlaAppliedByWorkflow = 17,
  ConversationSlaRemoved = 18,
  PriorityChangedByWorkflow = 19,
  Email = 20,
  PriorityChanged = 21,
  CustomBot = 22,
  ConversationSlaTargetMissed = 23,
  ChannelAndReplyTimeExpectation = 24,
  DuplicateConversationNote = 25,
  ParticipantAdded = 26,
  ConversationRatingChanged = 27,
  UnengagedInboundBotIntroduction = 28,
  ConversationRatingRemarkAdded = 29,
  ParticipantRemoved = 30,
  PriorityChangedByRule = 31,
  ParticipantBlocked = 32,
  Article = 33,
  UnknownParticipantForEmailConversationNote = 34,
  AttachmentsDropped = 35,
  Post = 36,
  ConversationSlaAppliedByRule = 37,
  UserBecameIdle = 38,
  AiAgentFoundNoAnswer = 39,
  ChannelSwitched = 40,
  TicketStateUpdatedByAdmin = 41,
  CustomActionStarted = 42,
  CustomActionFinished = 43,
  TicketStateUpdated = 44,
  Deleted = 45,
  Sms = 46,
  PhoneCall = 47,
  Whatsapp = 48,
  ConversationSummary = 49,
  RatingRequestNotSent = 50,
  TicketShared = 51,
  LinkedTicketShared = 52,
  OperatorWorkflowEvent = 53,
  CallSummary = 54,
  EntityLinked = 55,
  Forwarded = 56,
  CreatedByForwarding = 57,
  FinAnswer = 58,
  EntitiesUnlinked = 59,
  SideConversationStarted = 60,
  AutoSummarizeLimitReached = 61,
  SideConversationReply = 62,
  TransferCall = 63,
  ConversationSlaPaused = 64,
  ConversationSlaUnpaused = 65,
  MergedConversationReply = 66,
  MergedUserComment = 67,
  MergedSecondaryConversation = 68,
  MergedPrimaryConversation = 69,
  AvailableFinActions = 70,
  LanguageDetectionDetails = 71,
  FinGuidanceApplied = 73,
  CallEvent = 74,
  ConversationTagsUpdated = 75,
  GenericHandoff = 76,
  TranscriptionSummary = 77,
}

// Keep this in sync with Inbox::Constants::RenderableTypes::RENDERABLE_IN_SUMMARY_LIST on back-end
export const RENDERABLE_IN_SUMMARY_LIST = [
  // RenderableType.UserMessage,
  RenderableType.UserComment,
  RenderableType.AdminComment,
  RenderableType.AdminNote,
  RenderableType.QuickReplyResponse,
  RenderableType.Chat,
  RenderableType.Email,
  RenderableType.CustomBot,
  RenderableType.DuplicateConversationNote,
  RenderableType.Post,
  RenderableType.UserEmailComment,
  RenderableType.Deleted,
  RenderableType.Sms,
  RenderableType.Whatsapp,
  RenderableType.ConversationSummary,
  RenderableType.SideConversationStarted,
  RenderableType.SideConversationReply,
  RenderableType.MergedConversationReply,
  RenderableType.MergedUserComment,
  RenderableType.MergedSecondaryConversation,
  RenderableType.MergedPrimaryConversation,
] as const;
