/* RESPONSIBLE TEAM: team-channels */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Transition from '@ember/routing/-private/transition';
import { hash } from 'rsvp';

export default class SocialChannelsRoute extends SettingsRoute {
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: $TSFixMe;

  get titleToken() {
    return this.intl.t('new-settings.channels.social-channels.title');
  }

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_product_settings',
      transition,
    );
  }

  async model(): Promise<{
    instagram: {
      appPackage: any;
      integrations: any;
      settings: any;
      teamAvailability: any;
    };
    facebook: {
      appPackage: any;
    };
  }> {
    let instagramModel = hash({
      appPackage: this.store.findRecord('appstore/app-package', 'instagram-pv4'),
      integrations: this.store.findAll('instagram/integration'),
      settings: this.store.findRecord('instagram/settings', this.appService.app.id),
      teamAvailability: this.store.findRecord(
        'operator-settings/team-availability',
        this.appService.app.id,
      ),
    });

    let facebookModel = hash({
      appPackage: this.store.findRecord('appstore/app-package', 'facebook'),
    });

    return hash({
      instagram: instagramModel,
      facebook: facebookModel,
    });
  }
}
