/* RESPONSIBLE TEAM: team-workflows */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({});

export default class ConnectionPointLocalization extends Fragment.extend(Validations) {
  @attr('string') declare locale: string;
  @attr('string') declare label: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'operator/localizable-step/connection-point-localization': ConnectionPointLocalization;
  }
}
