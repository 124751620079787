/* import __COLOCATED_TEMPLATE__ from './code-snippets.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';

const JAVASCRIPT_CODE_SNIPPET = `
Intercom.setUserHash("INSERT_HMAC_VALUE_HERE");
`;

export default class CodeSnippets extends Component {
  get codeSnippet() {
    return JAVASCRIPT_CODE_SNIPPET;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::IdvSetup::ReactNative::CodeSnippets': typeof CodeSnippets;
    'installation-new/mobile/idv-setup/react-native/code-snippets': typeof CodeSnippets;
  }
}
