/* import __COLOCATED_TEMPLATE__ from './switch-on-idv.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import { type InstallationPlatform } from '../../constants';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  platform: InstallationPlatform;
  sdkApp?: $TSFixMe;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class SwitchOnIdv extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare identityVerificationRiskStateService: $TSFixMe;
  @service declare messengerInstallation: MessengerInstallation;
  @service declare helpCenterService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked showIdentityVerificationConfirmationModal = false;
  @tracked showIdentityVerificationDisablingModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.helpCenterService.maybeFetchSite();
  }

  get app() {
    return this.appService.app;
  }

  get isIdvEnabled(): boolean {
    switch (this.args.platform) {
      case 'ios':
        return this.args.sdkApp.identityVerified;
      case 'android':
        return this.args.sdkApp.identityVerified;
      default:
        return this.app.identityVerified;
    }
  }

  @action
  toggleIdentityVerification() {
    if (this.isIdvEnabled) {
      this.showIdentityVerificationDisablingModal = true;
    } else {
      this.showIdentityVerificationConfirmationModal = true;
    }
  }

  @action
  enableIdentityVerification() {
    switch (this.args.platform) {
      case 'ios':
      case 'android':
        this.updateIdentityVerificationStatusForMobile(true);
        break;
      default:
        this.updateIdentityVerificationStatusForWeb(true);
        break;
    }
    this.identityVerificationRiskStateService.start(this.app);

    this.showIdentityVerificationConfirmationModal = false;
  }

  @action
  disableIdentityVerification() {
    switch (this.args.platform) {
      case 'ios':
      case 'android':
        this.updateIdentityVerificationStatusForMobile(false);
        break;
      default:
        this.updateIdentityVerificationStatusForWeb(false);
        break;
    }
    this.identityVerificationRiskStateService.start(this.app);

    this.showIdentityVerificationDisablingModal = false;
  }

  private async updateIdentityVerificationStatusForWeb(isEnabled: boolean) {
    if (
      !isEnabled &&
      this.helpCenterService.allLiveSites.some((site: HelpCenterSite) => site.ticketsPortalTurnedOn)
    ) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.installation.identity-verification.cannot-disable-idv'),
      );
      return;
    }

    post(`/ember/apps/${this.app.id}/update_identity_verification`, {
      identity_verified: isEnabled,
    });

    this.app.identityVerified = isEnabled;
    this.messengerInstallation.isIdvConfiguredForWeb = isEnabled;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'enforce_idv_toggle',
      metadata: {
        enabled: isEnabled,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  private updateIdentityVerificationStatusForMobile(isEnabled: boolean) {
    this.args.sdkApp.identityVerified = isEnabled;
    this.args.sdkApp.save();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::IdvSetup::SwitchOnIdv': typeof SwitchOnIdv;
    'installation-new/web/idv-setup/swich-on-idv': typeof SwitchOnIdv;
  }
}
