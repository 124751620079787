/* import __COLOCATED_TEMPLATE__ from './default-reply-address.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type ApplicationInstance from '@ember/application/instance';
import type { DomainEmailAddress } from 'embercom/services/domain-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type { Domain } from 'embercom/services/domain-service';
import { getDomainName } from 'embercom/lib/email';

interface Args {}

interface Signature {
  Args: Args;
}

interface ReplyAddressItem {
  value: string | number;
  text: string;
  component: string;
  componentAttrs?: {
    verified?: boolean;
    isSelected?: boolean;
    isAuthenticated?: any;
  };
  isDisabled?: boolean;
}

export default class DefaultReplyAddress extends Component<Signature> {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: $TSFixMe;
  @service declare domainService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare brandService: $TSFixMe;
  @tracked selectedSenderEmailAddressSettingsId?: string;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    this.selectedSenderEmailAddressSettingsId =
      this.domainService.defaultSenderEmailAddressSettings?.id;
  }

  get defaultReplyAddressItems(): ReplyAddressItem[] {
    let senderEmailAddressSettings = this.domainService.domains.flatMap(
      (domain: Domain) => domain.addresses,
    );
    let items = senderEmailAddressSettings?.length
      ? senderEmailAddressSettings.map((address: DomainEmailAddress) => ({
          value: address.senderEmailAddressSettings?.id ?? 0,
          text: address.senderEmailAddressSettings?.email ?? '',
          isDisabled: this.isItemDisabled(address),
          component: 'new-settings/workspace/brands/brand-email-dropdown-item',
          componentAttrs: {
            verified: address.verified,
            id: address.senderEmailAddressSettings?.id,
            isSelected:
              this.selectedSenderEmailAddressSettingsId === address.senderEmailAddressSettings?.id,
            isAuthenticated: address.senderEmailAddressSettings
              ? this.isEmailDomainAuthenticated(address.senderEmailAddressSettings.email)
              : false,
          },
        }))
      : [
          {
            value: 0,
            text: '',
            component: 'new-settings/channels/email/advanced/email-dropdown-empty-list',
            isDisabled: true,
          },
        ];
    return items;
  }

  get showBrandsLink() {
    return this.brandService.isAppOnRightMultibrandPlan;
  }

  isItemDisabled(address: DomainEmailAddress) {
    return !this.isEmailDomainAuthenticated(address.email) || !address.verified;
  }

  isEmailDomainAuthenticated(email: string) {
    let emailDomain = getDomainName(email);
    return this.domainService.domains.find((domain: Domain) => domain.name === emailDomain)
      ?.isAuthenticated;
  }

  @action
  openBrandsSettingsPage() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'open_brands_settings_page',
      place: 'default-reply-address',
      context: 'email-settings',
    });
    let url = this.router.urlFor('apps.app.settings.workspace.brands', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }

  @action
  async onSelectSenderEmailAddressSetting(selectedItem: string): Promise<void> {
    this.selectedSenderEmailAddressSettingsId = selectedItem;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'default_reply_address_dropdown',
      place: 'default-reply-address',
      context: 'email-settings',
    });
    await this.update();
  }

  async update() {
    try {
      await this.appService.app.updateDefaultNotificationEmailAddress(
        this.selectedSenderEmailAddressSettingsId,
      );
      await this.store.findAll('sender-email-address-settings');
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.channels.email.advanced.default-reply-address.update-success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'new-settings.channels.email.advanced.default-reply-address.update-error',
        ),
      });
      this.selectedSenderEmailAddressSettingsId =
        this.domainService.defaultSenderEmailAddressSettings?.id;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::DefaultReplyAddress': typeof DefaultReplyAddress;
  }
}
