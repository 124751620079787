/* import __COLOCATED_TEMPLATE__ from './connected-accounts.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnly from '@ember/component/template-only';

interface Signature {
  Args: {
    addInstagramAccount: () => void;
    instagramAccounts: any[];
    showRemoveModal: (data: any) => void;
    confirmInstagramAccount: (pageId: string) => void;
    isConfirmingAccount: boolean;
  };
}

const InstagramConnectedAccounts = templateOnly<Signature>();

export default InstagramConnectedAccounts;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Instagram::ConnectedAccounts': typeof InstagramConnectedAccounts;
  }
}
