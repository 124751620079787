/* RESPONSIBLE TEAM: team-knowledge-interop */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const CONTENT_ICONS = [
  'paint-brush',
  'paper-clip',
  'book-open',
  'pencil-square',
  'bookmark',
];
const contentCommonKeywords = ['content', 'creative', 'design', 'creation'];
export const CONTENT_KEYWORDS = {
  [CONTENT_ICONS[0]]: [...contentCommonKeywords, 'art', 'painting', 'brush'],
  [CONTENT_ICONS[1]]: [...contentCommonKeywords, 'clip', 'office', 'attachment', 'paper'],
  [CONTENT_ICONS[2]]: [...contentCommonKeywords, 'book', 'reading', 'education', 'study', 'open'],
  [CONTENT_ICONS[3]]: [...contentCommonKeywords, 'pencil', 'drawing', 'writing', 'edit'],
  [CONTENT_ICONS[4]]: [...contentCommonKeywords, 'bookmark', 'save'],
};
