/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Model.extend({
  messengerSettingsTargetUserProvider: service(),
  helpCenterService: service(),
  languages: belongsTo('messenger-settings/languages', { async: false }),
  availability: belongsTo('messenger-settings/availability', { async: false }),
  visibility: belongsTo('messenger-settings/visibility', { async: false }),
  home: belongsTo('messenger-settings/home', { async: false }),
  security: belongsTo('messenger-settings/security', { async: false }),
  lookAndFeel: belongsTo('messenger-settings/look-and-feel', { async: false }),
  metadata: fragment('messenger-settings/metadata'),
  upfrontEmailCollection: belongsTo('messenger-settings/upfront-email-collection', {
    async: false,
  }),
  privacyPolicyNotice: belongsTo('messenger-settings/privacy-policy-notice', { async: false }),
  temporaryExpectations: belongsTo('messenger-settings/temporary-expectations', { async: false }),
  replyExpectations: belongsTo('messenger-settings/reply-expectations', { async: false }),
  spaces: belongsTo('messenger-settings/spaces', { async: false }),
  form: belongsTo('messenger-settings/form', { async: false }),
  operatorArticleAutoReaction: belongsTo('messenger-settings/operator-article-auto-reaction', {
    async: false,
  }),
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),

  startConversationButtonText: belongsTo('messenger-settings/start-conversation-button-text', {
    async: false,
  }),

  shouldUpgrade: attr('boolean'),

  hasUnsavedChanges: or(
    'languages.hasUnsavedChanges',
    'availability.hasUnsavedChanges',
    'visibility.hasUnsavedChanges',
    'home.hasUnsavedChanges',
    'security.hasUnsavedChanges',
    'lookAndFeel.hasUnsavedChanges',
    'temporaryExpectations.hasUnsavedChanges',
    'upfrontEmailCollection.hasUnsavedChanges',
    'spaces.hasUnsavedChanges',
    'operatorArticleAutoReaction.hasUnsavedChanges',
    'replyExpectations.hasUnsavedChanges',
  ),

  hasM5UnsavedChanges: computed(
    'hasUnsavedChanges',
    'form.hasUnsavedChanges',
    'lookAndFeel.{showPoweredBy,widgetAudioEnabled}',
    'spaces.{hasUnsavedChanges,spacesForUsers.[],spacesForVisitors.[],spacesForUsers.@each.enabled,spacesForVisitors.@each.enabled}',
    function () {
      return (
        this.hasUnsavedChanges ||
        this.spaces.hasUnsavedChanges ||
        this.form.hasUnsavedChanges ||
        this.lookAndFeel.brands.any((brand) => brand.hasUnsavedChanges)
      );
    },
  ),

  previewConfiguration: computed(
    'lookAndFeel.activeBrand.{brandName,logoUrl,messengerActionColor,messengerBackgroundColor,messengerWallpaper,launcherLogoUrl,launcherAlignment,launcherHorizontalPadding,launcherVerticalPadding,messengerLogoUrl,showAvatars,fadeToWhite,gradientFirstColor,gradientSecondColor,gradientThirdColor,textColor,backgroundType,backgroundImageUrl,showConversationBackground,conversationalBackgroundType,backgroundSolidColor}',
    'lookAndFeel.{showPoweredBy,conversationalMessengerIdentity,brandIdentityLogoUrl}',
    'languages.{defaultGreeting,defaultWelcomeMessage,defaultGreetingSubtitle,conversationalGreeting,conversationalIntroduction}',
    'visibility.{messengerEnabledForUsers,messengerEnabledForVisitors,inboundConversationsEnabledForUsers,inboundConversationsEnabledForVisitors,preventMultipleInboundConversationEnabledForUsers,preventMultipleInboundConversationEnabledForVisitors}',
    'metadata',
    'upfrontEmailCollection.frequency',
    'temporaryExpectations.{enabled,message,defaultTemporaryExpectationsMessage}',
    'replyExpectations.{showOfficeHoursAndReplyTimes,showOfficeHoursAndReplyTimesOnlyWhenAssignedToTeam}',
    'availability.{accessToTeammateEnabled,messengerResponseTimeKey}',
    'home',
    'home.{slotGroups,requireSearchBrowseForUsers,requireSearchBrowseForVisitors}',
    'spaces.{spacesForUsers.[],spacesForVisitors.[]}',
    'startConversationButtonText.{teammateStartConversationButtonTextForUsers,teammateStartConversationButtonTextForVisitors,teammateStartConversationButtonText,finStartConversationButtonTextForUsers,finStartConversationButtonTextForVisitors,finStartConversationButtonText,setExpectationsForUsers,setExpectationsForVisitors,setExpectations}',
    'privacyPolicyNotice.{enabled,defaultPrivacyPolicyNoticeContent}',
    function () {
      return this.store.createFragment('messenger-preview/configuration', {
        isMobileCompact: false,
        brandName: this.lookAndFeel.activeBrand.brandName,
        alignment: this.lookAndFeel.activeBrand.launcherAlignment,
        appPrimaryColor: this.lookAndFeel.activeBrand.messengerActionColor,
        appSecondaryColor: this.lookAndFeel.activeBrand.messengerBackgroundColor,
        horizontalPadding: this.lookAndFeel.activeBrand.launcherHorizontalPadding,
        logoURL: this.lookAndFeel.activeBrand.messengerLogoUrl,
        launcherLogoUrl: this.lookAndFeel.activeBrand.launcherLogoUrl,
        verticalPadding: this.lookAndFeel.activeBrand.launcherVerticalPadding,
        locale: this.languages.defaultLocale,
        greetingText: this.languages.defaultGreeting,
        greetingSubtitleText: this.languages.defaultGreetingSubtitle,
        conversationalGreetingText: this.languages.conversationalGreeting,
        conversationalSubtitleText: this.languages.conversationalIntroduction,
        aboutUsText: this.languages.defaultWelcomeMessage,
        messengerResponseTimeKey: this.availability.messengerResponseTimeKey,
        accessToTeammateEnabled: this.availability.accessToTeammateEnabled,
        backgroundPatternNumber: this.lookAndFeel.backgroundId,
        home: this.home,
        slotGroups: this.home.slotGroups,
        showWeRunOnIntercom: this.lookAndFeel.showPoweredBy,
        inboundConversationsEnabledForUsers: this.visibility.inboundConversationsEnabledForUsers,
        inboundConversationsEnabledForVisitors:
          this.visibility.inboundConversationsEnabledForVisitors,
        inboundConversationsEnabled: this.visibility.inboundConversationsEnabled,
        preventMultipleInboundConversationEnabledForUsers:
          this.visibility.preventMultipleInboundConversationEnabledForUsers,
        preventMultipleInboundConversationEnabledForVisitors:
          this.visibility.preventMultipleInboundConversationEnabledForVisitors,
        preventMultipleInboundConversationEnabled:
          this.visibility.preventMultipleInboundConversationEnabled,
        requireSearchBrowseForUsers: this.home.requireSearchBrowseForUsers,
        requireSearchBrowseForVisitors: this.home.requireSearchBrowseForVisitors,
        requireSearchBrowse: this.home.requireSearchBrowse,
        activeTeammates: this.metadata.activeTeammates,
        launcherVisibleForUsers: this.visibility.messengerEnabledForUsers,
        launcherVisibleForVisitors: this.visibility.messengerEnabledForVisitors,
        launcherVisible: this.visibility.messengerEnabled,
        frequency: this.upfrontEmailCollection.frequency,
        temporaryExpectations: {
          avatar: {
            avatarData: {
              url: 'https://js.intercomcdn.com/images/attention.6a6e4cbc.png',
            },
          },
          enabled: this.temporaryExpectations.enabled,
          message: this.temporaryExpectations.message,
          defaultTemporaryExpectationsMessage:
            this.temporaryExpectations.defaultTemporaryExpectationsMessage,
        },
        replyExpectations: {
          showOfficeHoursAndReplyTimes: this.replyExpectations.showOfficeHoursAndReplyTimes,
          showOfficeHoursAndReplyTimesOnlyWhenAssignedToTeam:
            this.replyExpectations.showOfficeHoursAndReplyTimesOnlyWhenAssignedToTeam,
        },
        spaces: this.spaces,
        showAvatars: this.lookAndFeel.activeBrand.showAvatars,
        fadeToWhite: this.lookAndFeel.activeBrand.fadeToWhite,
        gradientFirstColor: this.lookAndFeel.activeBrand.gradientFirstColor,
        gradientSecondColor: this.lookAndFeel.activeBrand.gradientSecondColor,
        gradientThirdColor: this.lookAndFeel.activeBrand.gradientThirdColor,
        textColor: this.lookAndFeel.activeBrand.textColor,
        backgroundType: this.lookAndFeel.activeBrand.backgroundType,
        backgroundImageUrl: this.lookAndFeel.activeBrand.backgroundImageUrl,
        teammateStartConversationButtonText:
          this.startConversationButtonText.teammateStartConversationButtonText,
        finStartConversationButtonText:
          this.startConversationButtonText.finStartConversationButtonText,
        setExpectations: this.startConversationButtonText.setExpectations,
        showConversationBackground: this.lookAndFeel.activeBrand.showConversationBackground,
        conversationalBackgroundType: this.lookAndFeel.activeBrand.conversationalBackgroundType,
        backgroundSolidColor: this.lookAndFeel.activeBrand.backgroundSolidColor,
        brandIdentityLogoUrl: this.lookAndFeel.brandIdentityLogoUrl,
        conversationalMessengerIdentity: this.lookAndFeel.conversationalMessengerIdentity,
        privacyPolicyNoticeEnabled: this.privacyPolicyNotice.enabled,
        defaultPrivacyPolicyNoticeContent:
          this.privacyPolicyNotice.defaultPrivacyPolicyNoticeContent,
      });
    },
  ),

  mobilePreviewConfiguration: computed(
    'lookAndFeel.activeBrand.{brandName,logoUrl,messengerActionColor,messengerBackgroundColor,messengerWallpaper,launcherLogoUrl,launcherAlignment,launcherHorizontalPadding,launcherVerticalPadding,messengerLogoUrl,showAvatars,fadeToWhite,gradientFirstColor,gradientSecondColor,gradientThirdColor,textColor,backgroundType,backgroundImageUrl,showConversationBackground,conversationalBackgroundType,backgroundSolidColor}',
    'lookAndFeel.{showPoweredBy,conversationalMessengerIdentity,brandIdentityLogoUrl}',
    'languages.{targetedGreeting,targetedGreetingSubtitle,defaultWelcomeMessage,conversationalGreeting,conversationalIntroduction}',
    'visibility.{messengerEnabled,inboundConversationsEnabled,preventMultipleInboundConversationEnabled}',
    'metadata',
    'upfrontEmailCollection.frequency',
    'temporaryExpectations.{enabled,message,defaultTemporaryExpectationsMessage}',
    'replyExpectations.{showOfficeHoursAndReplyTimes,showOfficeHoursAndReplyTimesOnlyWhenAssignedToTeam}',
    'availability.{accessToTeammateEnabled,messengerResponseTimeKey}',
    'home',
    'home.{slotGroups,requireSearchBrowse}',
    'spaces.{spacesForMobileUsers.[]}',
    'startConversationButtonText.{teammateStartConversationButtonTextForUsers,teammateStartConversationButtonTextForVisitors,teammateStartConversationButtonText,finStartConversationButtonTextForUsers,finStartConversationButtonTextForVisitors,finStartConversationButtonText,setExpectationsForUsers,setExpectationsForVisitors,setExpectations}',
    function () {
      return this.store.createFragment('messenger-preview/configuration', {
        isMobileCompact: false,
        alignment: this.lookAndFeel.activeBrand.launcherAlignment,
        appPrimaryColor: this.lookAndFeel.activeBrand.messengerActionColor,
        appSecondaryColor: this.lookAndFeel.activeBrand.messengerBackgroundColor,
        horizontalPadding: this.lookAndFeel.activeBrand.launcherHorizontalPadding,
        logoURL: this.lookAndFeel.activeBrand.messengerLogoUrl,
        launcherLogoUrl: this.lookAndFeel.activeBrand.launcherLogoUrl,
        verticalPadding: this.lookAndFeel.activeBrand.launcherVerticalPadding,
        locale: this.languages.defaultLocale,
        greetingText: this.languages.targetedGreeting,
        greetingSubtitleText: this.languages.targetedGreetingSubtitle,
        conversationalGreetingText: this.languages.conversationalGreeting,
        conversationalSubtitleText: this.languages.conversationalIntroduction,
        aboutUsText: this.languages.defaultWelcomeMessage,
        messengerResponseTimeKey: this.availability.messengerResponseTimeKey,
        accessToTeammateEnabled: this.availability.accessToTeammateEnabled,
        backgroundPatternNumber: this.lookAndFeel.backgroundId,
        home: this.home,
        slotGroups: this.home.slotGroups,
        showWeRunOnIntercom: this.lookAndFeel.showPoweredBy,
        inboundConversationsEnabled: this.visibility.inboundConversationsEnabled,
        preventMultipleInboundConversationEnabled:
          this.visibility.preventMultipleInboundConversationEnabled,
        requireSearchBrowse: this.home.requireSearchBrowse,
        activeTeammates: this.metadata.activeTeammates,
        launcherVisible: this.visibility.messengerEnabled,
        frequency: this.upfrontEmailCollection.frequency,
        temporaryExpectations: {
          avatar: {
            avatarData: {
              url: 'https://js.intercomcdn.com/images/attention.6a6e4cbc.png',
            },
          },
          enabled: this.temporaryExpectations.enabled,
          message: this.temporaryExpectations.message,
          defaultTemporaryExpectationsMessage:
            this.temporaryExpectations.defaultTemporaryExpectationsMessage,
        },
        replyExpectations: {
          showOfficeHoursAndReplyTimes: this.replyExpectations.showOfficeHoursAndReplyTimes,
          showOfficeHoursAndReplyTimesOnlyWhenAssignedToTeam:
            this.replyExpectations.showOfficeHoursAndReplyTimesOnlyWhenAssignedToTeam,
        },
        spaces: this.spaces,
        showAvatars: this.lookAndFeel.activeBrand.showAvatars,
        fadeToWhite: this.lookAndFeel.activeBrand.fadeToWhite,
        gradientFirstColor: this.lookAndFeel.activeBrand.gradientFirstColor,
        gradientSecondColor: this.lookAndFeel.activeBrand.gradientSecondColor,
        gradientThirdColor: this.lookAndFeel.activeBrand.gradientThirdColor,
        textColor: this.lookAndFeel.activeBrand.textColor,
        backgroundType: this.lookAndFeel.activeBrand.backgroundType,
        backgroundImageUrl: this.lookAndFeel.activeBrand.backgroundImageUrl,
        teammateStartConversationButtonText:
          this.startConversationButtonText.teammateStartConversationButtonText,
        finStartConversationButtonText:
          this.startConversationButtonText.finStartConversationButtonText,
        setExpectations: this.startConversationButtonText.setExpectations,
        showConversationBackground: this.lookAndFeel.activeBrand.showConversationBackground,
        conversationalBackgroundType: this.lookAndFeel.activeBrand.conversationalBackgroundType,
        backgroundSolidColor: this.lookAndFeel.activeBrand.backgroundSolidColor,
        brandIdentityLogoUrl: this.lookAndFeel.brandIdentityLogoUrl,
        conversationalMessengerIdentity: this.lookAndFeel.conversationalMessengerIdentity,
      });
    },
  ),

  refresh() {
    this.languages.refresh();
    this.availability.refresh();
    this.visibility.refresh();
    this.home.refresh();
    this.security.refresh();
    this.lookAndFeel.refresh();
    this.upfrontEmailCollection.refresh();
    this.temporaryExpectations.refresh();
    this.spaces.refresh();
    this.form.refresh();
    this.operatorArticleAutoReaction.refresh();
    this.replyExpectations.refresh();
    this.privacyPolicyNotice.refresh();
  },

  initializeForCurrentUserType() {
    if (this.visibility.inboundConversationsEnabled) {
      this.home.slotGroup.addNewConversationCard();
    }
    if (
      this.app.hasHelpCenter &&
      this.helpCenterService.site &&
      this.helpCenterService.site.turnedOn
    ) {
      this.home.slotGroup.addHelpCenterSlot();
      this.spaces.enableHelp();
    }
    if (this.app.canUseNews) {
      this.home.slotGroup.addNewsSlot();
      this.spaces.enableNews();
    }
    this.spaces.enableHub();
  },

  initializeSuggestedSettings() {
    this.messengerSettingsTargetUserProvider.setUserTarget();
    this.initializeForCurrentUserType();
    this.messengerSettingsTargetUserProvider.setVisitorTarget();
    this.initializeForCurrentUserType();
  },
});
