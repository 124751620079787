/* import __COLOCATED_TEMPLATE__ from './segmented-tab-control.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  onSelectionChange: (tab: string) => void;
  selectedTab: string;
}

const SegmentedTabControl = templateOnlyComponent<Args>();
export default SegmentedTabControl;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::SimpleAutomations::SegmentedTabControl': typeof SegmentedTabControl;
    'automation/simple-automations/segmented-tab-control': typeof SegmentedTabControl;
  }
}
