/* import __COLOCATED_TEMPLATE__ from './installation-type-selector.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { INSTALLATION_TYPE } from 'embercom/components/installation-new/constants';

interface Args {
  selectedInstallationType: INSTALLATION_TYPE;
  onSelectInstallationType: (installationType: INSTALLATION_TYPE) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class InstallationTypeSelector extends Component<Signature> {
  @service declare intl: IntlService;

  get installationTypes() {
    return [
      {
        id: INSTALLATION_TYPE.LOGGED_IN_USERS,
        title: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.logged-in-users.title',
        ),
        subTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.logged-in-users.sub-title',
        ),
        recommended: true,
      },
      {
        id: INSTALLATION_TYPE.VISITORS,
        title: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.visitors.title',
        ),
        subTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.visitors.sub-title',
        ),
      },
    ];
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::InstallationTypeSelector': typeof InstallationTypeSelector;
    'installation-new/installation-type-selector': typeof InstallationTypeSelector;
  }
}
