/* RESPONSIBLE TEAM: team-reporting */
import { tracked } from '@glimmer/tracking';

export default class ReportMode {
  @tracked isEditMode;
  @tracked isSidePanelOpen;
  @tracked isShareSideDrawerOpen;
  @tracked isReportNew;

  constructor(
    isEditMode: boolean,
    isSidePanelOpen: boolean,
    isShareSideDrawerOpen: boolean,
    isReportNew: boolean,
  ) {
    this.isEditMode = isEditMode;
    this.isSidePanelOpen = isSidePanelOpen;
    this.isShareSideDrawerOpen = isShareSideDrawerOpen;
    this.isReportNew = isReportNew;
  }
}
