/* import __COLOCATED_TEMPLATE__ from './content-panel.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import EmberObject, { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

export default class ContentPanel extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  // We make a fake variation as a bridge between the tour and the format
  // expected by the sender-dropdown component
  get variation() {
    return EmberObject.create({
      dynamicSender: this.args.tour.senderType === 'owner',
      from: this.args.tour.from,
      showDynamicSender: true,
    });
  }

  get shouldShowSender() {
    if (this.args.tour.senderType === 'no_sender') {
      return false;
    }
    return true;
  }

  get customHeaderTextError() {
    if (
      isPresent(this.args.tour.customHeaderText) &&
      this.args.tour.customHeaderText.length > 100
    ) {
      return this.intl.t('outbound.tour.editor.content-panel.use-custom-header-text.error');
    }
  }

  // used to define proxima-nova @font-face in css
  // we need to provide this regardless of the font is selected or not
  // because it will be used in the font picker dropdown
  get fontProximaNovaCssInfo() {
    if (this.app.canUseTourFontProximaNova && this.args.tour?.fontProximaNova?.value) {
      return {
        fontFamily: this.args.tour.fontProximaNova.value,
        fontUrl: 'https://fonts.intercomcdn.com/messenger-m4/proximanova-regular.woff',
      };
    }
  }

  @action
  setFrom(selected) {
    let { isDynamicSender } = selected.model;

    this.args.tour.senderType = isDynamicSender ? 'owner' : 'standard';

    if (!isDynamicSender) {
      this.setAuthor(selected);
    }
  }

  @action
  setAuthor(selected) {
    let { id } = selected.model;
    this.args.tour.fromId = id;
  }

  @action
  openBuildUrl(url, launcher) {
    launcher.openTourBuilder(url);
    return false;
  }

  @action
  toggleShowSender() {
    if (this.shouldShowSender) {
      this.args.tour.senderType = 'no_sender';
    } else {
      this.args.tour.fromId = this.app.currentAdmin.id;
      this.args.tour.senderType = 'standard';
      this.args.tour.customHeaderText = null;
    }
  }

  @action
  toggleUseCustomHeaderText() {
    if (this.args.tour.customHeaderText === null) {
      this.args.tour.customHeaderText = '';
    } else {
      this.args.tour.customHeaderText = null;
    }
  }
}
