/* import __COLOCATED_TEMPLATE__ from './simple-automations-banner.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { type Action } from 'embercom/components/common/hero-banner';

export default class SimpleAutomationsBanner extends Component {
  get newHeroBannerActions(): Action[] {
    return [
      {
        type: 'link',
        label: 'operator.task-bots.banner.simple-automations.articles.simple-automations',
        icon: 'article',
        analyticsId: 'simple-automations-article',
        onClick: () => {
          window.Intercom('showArticle', 7836462);
        },
      },
      {
        type: 'link',
        label: 'operator.task-bots.banner.simple-automations.articles.leads-vs-users',
        icon: 'book',
        analyticsId: 'leads-vs-users-article',
        onClick: () => {
          window.Intercom('showArticle', 310);
        },
      },
      {
        type: 'wistia_link',
        label: 'operator.task-bots.banner.simple-automations.articles.go-further-with-workflows',
        icon: 'video',
        analyticsId: 'go-further-with-workflowss-wistia',
        wistiaId: '0dsogf6f54',
      },
    ];
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::Basics::SimpleAutomationsBanner': typeof SimpleAutomationsBanner;
  }
}
