/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { computed, observer } from '@ember/object';
import { alias, and, equal, not, notEmpty, or, readOnly, reads } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { includes } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import { getDomainName } from 'embercom/lib/email';
import checkEmailIsPersonal from 'embercom/utils/check-email-domain';

export default Controller.extend({
  notificationsService: service(),
  store: service(),
  intl: service(),
  queryParams: [
    'token',
    {
      enterpriseEmail: 'enterprise_email',
    },
  ],
  token: null,
  enterpriseEmail: null,
  isEnterprise: notEmpty('enterpriseEmail'),
  invitedFromHomeInstallMessenger: equal(
    'invite.onboardingHomeStepIdentifier',
    'install_messenger',
  ),
  untrackedCdas: null,

  email: or('invite.email', 'enterpriseEmail'),

  heading: computed(
    'intl.locale',
    'invite.{active,appName,isEmailInvite}',
    'atMaxTeammates',
    function () {
      let appName = this.get('invite.appName');
      if (this.get('invite.active') && !this.atMaxTeammates) {
        if (this.isEnterprise) {
          return this.intl.t('invite.get-started');
        }
        return this.intl.t('invite.join-on-intercom', { appName });
      }
    },
  ),

  subHeading: computed(
    'intl.locale',
    'invite.{active,appName}',
    'senderFirstNameOrEmail',
    'atMaxTeammates',
    function () {
      if (this.get('invite.active') && !this.atMaxTeammates) {
        if (this.invitedFromHomeInstallMessenger) {
          return this.intl.t('invite.team-ready-to-connect', { htmlSafe: true });
        }
      }
    },
  ),

  atMaxTeammates: readOnly('invite.appAtMaxTeammates'),

  avatarData: computed('invite.senderAvatar', function () {
    return { url: this.invite.senderAvatar, isTeammate: true };
  }),

  blockHeading: computed('intl.locale', 'isInactive', function () {
    return this.isInactive
      ? this.intl.t('invite.invite-expired')
      : this.intl.t('invite.no-more-seats');
  }),

  blockSubHeading: computed(
    'intl.locale',
    'invite.appIsSalesforceContracted',
    'invite.appName',
    'isInactive',
    function () {
      if (this.isInactive) {
        return this.intl.t('invite.new-invite-or-log-in', { appName: this.invite.appName });
      }

      return this.invite.appIsSalesforceContracted
        ? this.intl.t('invite.contact-us')
        : this.intl.t('invite.ask-teammate-to-upgrade', { appName: this.invite.appName });
    },
  ),

  department: 'Unknown',
  companySize: 'Unknown',
  currentAdmin: null,
  adminAlreadyOnApp: null,
  adminNotAlreadyOnApp: not('adminAlreadyOnApp'),
  errors: null,

  invite: reads('model.invite'),
  isEmailInvite: reads('invite.isEmailInvite'),
  hasCustomDataAttributes: reads('invite.hasCustomDataAttributes'),
  isInactive: not('invite.active'),
  isNotRevoked: not('inviteLink.revoked'),
  isTask: readOnly('hasCustomDataAttributes'),

  readyToCreateAccount: and('name', 'email', 'password', 'termsOfServiceAccepted'),
  notReadyToCreateAccount: not('readyToCreateAccount'),
  accountCreationDisabled: or('notReadyToCreateAccount', 'createAccountAndTransition.isRunning'),

  authorizedSignInGoogleOauth2: includes('invite.authorizedSignInMethods', 'google_oauth2'),
  isGoogleSSOOnly: computed(
    'invite.authorizedSignInMethods',
    'authorizedSignInGoogleOauth2',
    function () {
      return (
        this.get('invite.authorizedSignInMethods.length') === 1 && this.authorizedSignInGoogleOauth2
      );
    },
  ),
  emailPasswordAllowed: includes('invite.authorizedSignInMethods', 'email_password'),
  twoFAAuthAllowed: includes('invite.authorizedSignInMethods', 'email_password_otp'),
  authorizedSignInSaml: includes('invite.authorizedSignInMethods', 'saml'),
  authorizedSignInEmailPassword: or('emailPasswordAllowed', 'twoFAAuthAllowed'),
  isSamlOnlyLegacyCustomer: computed(
    'invite.authorizedSignInMethods',
    'authorizedSignInSaml',
    'invite.samlAccountName',
    function () {
      return (
        this.get('invite.authorizedSignInMethods.length') === 1 &&
        this.authorizedSignInSaml &&
        !this.invite.samlAccountName
      );
    },
  ),

  nameError: alias('errors.name'),
  emailError: alias('errors.admin.email'),
  passwordError: alias('errors.password'),
  termsOfServiceError: alias('errors.terms_of_service'),
  isEmailTaken: equal('errors.email', 'Email has already been taken'),
  personalEmailDomain: false,

  signInWithRedirectUrl: computed('token', function () {
    let protocol = window.location.protocol;
    let host = window.location.host;
    let redirectUrl = encodeURIComponent(`${protocol}//${host}/a/invite?token=${this.token}`);

    return `/admins/sign_in?redirect_url=${redirectUrl}`;
  }),

  redirectUrlForSignIn: computed('token', function () {
    return encodeURIComponent(`${window.location.origin}/a/invite?token=${this.token}`);
  }),

  senderFirstNameOrEmail: computed('invite.senderName', function () {
    let senderName = this.get('invite.senderName');
    return senderName ? senderName.split(' ')[0] : this.get('invite.senderEmail');
  }),

  senderFirstNameAndEmail: computed('invite.senderName', 'invite.senderEmail', function () {
    let senderName = this.get('invite.senderName');

    return senderName
      ? `${senderName.split(' ')[0]} (${this.get('invite.senderEmail')})`
      : this.get('invite.senderEmail');
  }),

  senderEmailFirstCharacter: computed('invite.senderEmail', function () {
    let senderEmail = this.get('invite.senderEmail');
    return senderEmail ? senderEmail[0] : '';
  }),

  checkEmailDomainDebounce: observer({
    dependentKeys: ['email'],

    fn() {
      debounce(this, this.checkEmailDomain, 500);
    },

    sync: true,
  }),

  async checkEmailDomain() {
    let personal_email_domain = await checkEmailIsPersonal(getDomainName(this.email));
    this.set('personalEmailDomain', personal_email_domain);
  },

  currentAdminAvatarUrl: reads('currentAdmin.avatar.image_urls.square_128'),

  addAdminToApp() {
    return ajax({
      type: 'PUT',
      url: `/ember/invites/${this.token}/redeem`,
    });
  },

  createAccountAndTransition: task(function* (formData, handleCreationError) {
    let admin = this.store.createRecord('admin', {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      department: formData.department,
      companySize: formData.companySize,
      terms_of_service: true,
      invite_token: this.token,
    });

    try {
      yield admin.save();
    } catch (response) {
      return handleCreationError(response);
    }

    yield this.addAdminToApp();

    return this.send('hardRedirectToGuide', this.invite);
  }),

  addAdminToAppAndRedirect: task(function* () {
    try {
      yield this.addAdminToApp();
    } catch (_) {
      this.notificationsService.notifyError(this.intl.t('invite.unable-to-add-you'));
    }

    this.send('hardRedirectToGuide', this.invite);
  }),
});
