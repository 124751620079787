/* import __COLOCATED_TEMPLATE__ from './top-banner-selector.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import RouteRegexes from 'embercom/lib/route-regexes';

export default class TopBannerSelector extends Component {
  @service router;
  @service cardlessTrialService;

  get app() {
    return this.cardlessTrialService.app;
  }

  get appIsTestApp() {
    return this.cardlessTrialService.isTestApp;
  }

  get currentPath() {
    return this.router.currentRouteName;
  }

  get showBeginTrialBanner() {
    return (
      !this.isOnExcludedRoutesForCardlessTrialBanner &&
      this.cardlessTrialService.isInSelfServeTrial &&
      !this.appIsTestApp
    );
  }

  get isOnExcludedRoutesForCardlessTrialBanner() {
    return this.router.currentRouteName.match(RouteRegexes.excludedForCardlessTrialBanner);
  }
}
