/* import __COLOCATED_TEMPLATE__ from './hero-banner.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type BannerDismissalService from 'embercom/services/banner-dismissal-service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { modifier } from 'ember-modifier';
import { perform } from 'ember-concurrency-ts';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Action {
  type: 'link' | 'button' | 'wistia_link';
  label: string;
  icon: InterfaceIconName;
  analyticsId: string;
  onClick?: () => void;
  buttonType?: 'primary' | 'secondary';
  buttonClass?: string;
  wistiaId?: string;
}

interface Args {
  title: string;
  bannerId: string;
  description: string;
  actions?: Action[];
  mediaType: 'video' | 'image';
  mediaUrl: string;
  wistiaId?: string;
  containerClass?: string;
}

interface Signature {
  Args: Args;
}

export default class HeroBanner extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare bannerDismissalService: BannerDismissalService;

  @tracked isBannerDismissed = true;

  get bannerId() {
    return `hero-banner-${this.args.bannerId}`;
  }

  onLoad = modifier(
    () => {
      perform(this.checkBannerDismissal);
    },
    { eager: false },
  );

  @task({ drop: true })
  *checkBannerDismissal() {
    let hasDismissed: boolean = yield this.bannerDismissalService.hasDismissed(this.bannerId);
    this.isBannerDismissed = hasDismissed;
  }

  @action
  recordAnalyticsEvent(action: string, object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: this.bannerId,
    });
  }

  @action
  dismissBanner() {
    this.bannerDismissalService.dismiss(this.bannerId);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'dismissed',
      object: 'close_button',
      place: this.bannerId,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::HeroBanner': typeof HeroBanner;
    'common/hero-banner': typeof HeroBanner;
  }
}
