/* RESPONSIBLE TEAM: team-actions */

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import type MutableArray from '@ember/array/mutable';
import { tracked } from '@glimmer/tracking';

import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { Type } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import type Step from 'embercom/models/operator/visual-builder/step';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';
import type InsertableActionField from 'embercom/models/workflow-connector/insertable-action-field';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import EditorConfig, {
  type AttributeGroupList,
} from 'embercom/objects/visual-builder/configuration/editor';
import type {
  PathConfigGenerationParams,
  StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { AiAgentWorkflowPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { AiAgentWorkflowAttributeCollectorConfiguration } from 'embercom/objects/visual-builder/configuration/step/attribute-collector';
import type IntlService from 'embercom/services/intl';
import { EntityType } from 'embercom/models/data/entity-types';

const MATCHABLE_LOCAL_ATTRIBUTES_TYPES = [
  Type.string,
  Type.integer,
  Type.float,
  Type.date,
  Type.boolean,
  Type.workflow_attribute_descriptor_list,
];
const COLLECTABLE_LOCAL_ATTRIBUTES_TYPES = [
  Type.string,
  Type.integer,
  Type.float,
  Type.date,
  Type.boolean,
  Type.workflow_attribute_descriptor_list,
  Type.array,
];

export default class AiAgentWorkflowEditorConfig extends EditorConfig {
  @service declare intl: IntlService;
  @tracked public workflow: Workflow;

  public actions: any;
  public targetChannels: MutableArray<string>;

  constructor({
    areStepPaywallsActive,
    actions,
    workflow,
    targetChannels,
  }: {
    areStepPaywallsActive?: boolean;
    actions?: any;
    workflow: Workflow;
    targetChannels: MutableArray<string>;
  }) {
    super({ areStepPaywallsActive });
    this.actions = actions || []; // TODO: Remove when local variables are supported in actions.
    this.workflow = workflow;
    this.targetChannels = targetChannels;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new AiAgentWorkflowPathConfig({ path, editorState });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.attributeCollector:
        return new AiAgentWorkflowAttributeCollectorConfiguration(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get showGoalsPanel(): boolean {
    return false;
  }

  get actionResponseFieldAttributes(): AttributeGroupList {
    // TODO: Remove when local variables are supported in actions.
    if (this.app.canUseActionsInputMapping) {
      return [];
    }
    return this.actions.map((action: InsertableAction) => {
      return {
        heading: action.name,
        attributes: action.responseFields.map((field: InsertableActionField) => {
          return {
            identifier: `operator_workflow_trigger.data.action_id_${action.id}.${field.identifier}`,
            templatable_identifier: `operator_workflow_trigger.data.action_id_${action.id}.${field.identifier}`,
            composer_name: `${action.name} > ${field.name}`,
            name: field.name,
            type: field.type,
            icon: field.icon,
          };
        }),
      };
    });
  }

  @computed('workflow.attributeDescriptors.@each.{type,name,listOptions,archived}')
  get localAttributes(): Array<AttributeDescriptor> {
    return this.workflow.attributeDescriptors.filter((attribute) => !attribute.archived).toArray();
  }

  @computed('workflow.attributeDescriptors.@each.{type,name,listOptions,archived}')
  get allLocalAttributes(): Array<AttributeDescriptor> {
    return this.workflow.attributeDescriptors.toArray();
  }

  get localAttributeGroupList(): AttributeGroupList {
    return this.buildAttributeGroupList(this.localAttributes);
  }

  get allLocalAttributeGroupList(): AttributeGroupList {
    return this.buildAttributeGroupList(this.allLocalAttributes);
  }

  get runtimeMatchingTargetingAttributes(): AttributeGroupList {
    return this.sortGroups([
      ...this.localAttributeGroupList.map((groupList) => {
        return {
          heading: groupList.heading,
          attributes: groupList.attributes
            .filter((attribute) => MATCHABLE_LOCAL_ATTRIBUTES_TYPES.includes(attribute.type))
            .toArray(),
        };
      }),
      ...this.attributeService.visualBotBuilderConditionalAttributesGroupList,
    ]);
  }

  get collectableAttributesGroupList() {
    return this.sortGroups([
      ...this.localAttributeGroupList.map((groupList) => {
        return {
          heading: groupList.heading,
          attributes: groupList.attributes
            .filter((attribute) => COLLECTABLE_LOCAL_ATTRIBUTES_TYPES.includes(attribute.type))
            .toArray(),
        };
      }),
      ...this.attributeService.botAutoMessageCollectableAttributeGroupList,
    ]);
  }
  private buildAttributeGroupList(localAttributes: AttributeDescriptor[]): AttributeGroupList {
    let topLevelAttributes: AttributeDescriptor[] = [];
    let attributesByIdentifier = new Map<string, any>();

    localAttributes.forEach((attr) => {
      if (!attr.referenceOnly) {
        if (!attr.sourceObject) {
          topLevelAttributes.push(attr);
          attributesByIdentifier.set(attr.identifier, [
            attr.name,
            attr.allDescendantDescriptors.filter((a) => !a.referenceOnly),
          ]);
        } else if (attr.sourceObject.type !== EntityType.WorkflowAttributeDescriptor) {
          // TODO: Name should probably come from the source object itself.
          attributesByIdentifier.set(attr.identifier, [
            attr.name,
            [attr, ...attr.allDescendantDescriptors.filter((a) => !a.referenceOnly)],
          ]);
        }
      }
    });

    return [
      {
        heading: this.intl.t(
          'operator.workflows.visual-builder.attribute-collector.local-variables',
        ),
        attributes: topLevelAttributes,
      },
      ...Array.from(attributesByIdentifier.entries()).map(([_identifier, [heading, attrs]]) => ({
        heading,
        attributes: attrs,
      })),
      ...this.actionResponseFieldAttributes,
    ];
  }

  private sortGroups(groups: AttributeGroupList) {
    return groups.sort((a, b) => {
      if (!a.heading && b.heading) {
        return -1;
      }
      if (a.heading && !b.heading) {
        return 1;
      }
      return 0;
    });
  }
}
