/* import __COLOCATED_TEMPLATE__ from './for-users.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type TaskBots from 'embercom/models/operator-settings/task-bots';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  settings: TaskBots;
  updateDelayEnabled: () => void;
  openSectionId: string | null;
  openSectionChange: (sectionId: string) => void;
}

export default class ForUsers extends Component<Args> {
  @service declare appService: any;

  @tracked currentlyOpenSection = 'first-message';

  get app(): any {
    return this.appService.app;
  }

  get hasBotWorkflows(): boolean {
    return this.app?.canUseFeature('bot_workflows');
  }

  @action
  setCurrentlyOpenSection(sectionId: string) {
    this.currentlyOpenSection = sectionId;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::SimpleAutomations::ForUsers': typeof ForUsers;
  }
}
