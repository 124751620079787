/* import __COLOCATED_TEMPLATE__ from './installation-status.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import { tracked } from '@glimmer/tracking';
import {
  INSTALLATION_TYPE,
  type InstallationPlatform,
} from 'embercom/components/installation-new/constants';

export type VerifyAnyInstallApiResponse = {
  anonymous_request_at: string | null;
  identified_request_at: string | null;
  ios_is_activated: boolean;
  android_is_activated: boolean;
};

interface Args {
  selectedInstallationType: INSTALLATION_TYPE;
  profilesForLoggedInUserEnabled: boolean;
  platform: InstallationPlatform;
}

interface Signature {
  Args: Args;
  Element: any;
}

export default class InstallationStatus extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare messengerInstallation: MessengerInstallation;
  @service declare modelDataCacheService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked checkedInstallationAtLeastOnce = false;
  @tracked displayUserApiDisabledError = false;

  get app() {
    return this.appService.app;
  }

  get isMessengerInstalled() {
    switch (this.args.platform) {
      case 'web':
        return this.messengerInstallation.isInstalledForWeb;
      case 'ios':
        return this.messengerInstallation.isInstalledForIos;
      case 'android':
        return this.messengerInstallation.isInstalledForAndroid;
    }
  }

  get showSuccessfulInstallationBanner() {
    return (
      !this.checkInstallationStatusTask.isRunning &&
      this.isMessengerInstalled &&
      this.checkedInstallationAtLeastOnce
    );
  }

  get showFailedInstallationBanner() {
    return (
      !this.checkInstallationStatusTask.isRunning &&
      !this.isMessengerInstalled &&
      this.checkedInstallationAtLeastOnce
    );
  }

  get messengerInstalledSuccessMessage() {
    switch (this.args.platform) {
      case 'web':
        return this.messengerInstallation.isInstalledForUsers &&
          this.args.profilesForLoggedInUserEnabled
          ? this.intl.t(
              'apps.app.settings.installation.installation.new.installation-status.logged-in-success-message',
            )
          : this.intl.t(
              'apps.app.settings.installation.installation.new.installation-status.visitor-success-message',
            );
      case 'ios':
        return this.intl.t(
          'apps.app.settings.installation.installation.new.ios.installation-status.success-message',
        );
      case 'android':
        return this.intl.t(
          'apps.app.settings.installation.installation.new.android.installation-status.success-message',
        );
    }
  }

  get checkInstallationMessage() {
    let message = '';
    switch (this.args.platform) {
      case 'web':
        message = this.intl.t(
          'apps.app.settings.installation.installation.new.installation-status.sub-title',
        );
        break;
      case 'ios':
        message = this.intl.t(
          'apps.app.settings.installation.installation.new.ios.installation-status.sub-title',
        );
        break;
      case 'android':
        message = this.intl.t(
          'apps.app.settings.installation.installation.new.android.installation-status.sub-title',
        );
        break;
    }

    let checkInstallationMessage = !this.isMessengerInstalled
      ? this.intl.t(
          'apps.app.settings.installation.installation.new.installation-status.sub-title-extra',
        )
      : '';
    return `${message} ${checkInstallationMessage}`;
  }

  get mobileTroubleshootingLink() {
    let iosTroubleshootLink =
      'https://developers.intercom.com/installing-intercom/ios/error-codes/';
    let androidTroubleshootLink =
      'https://developers.intercom.com/installing-intercom/android/error-codes/';

    if (this.args.platform === 'ios') {
      return iosTroubleshootLink;
    } else if (this.args.platform === 'android') {
      return androidTroubleshootLink;
    }
    return iosTroubleshootLink;
  }

  @action
  fetchInstallationStatus() {
    if (
      this.args.selectedInstallationType === INSTALLATION_TYPE.LOGGED_IN_USERS &&
      !this.args.profilesForLoggedInUserEnabled
    ) {
      this.displayUserApiDisabledError = true;
      return;
    }

    this.displayUserApiDisabledError = false;
    this.checkInstallationStatusTask.perform();
  }

  get checkInstallationStatusTask() {
    return taskFor(this.checkInstallationStatus);
  }

  @task({ restartable: true })
  *checkInstallationStatus(): TaskGenerator<void> {
    try {
      yield this.messengerInstallation.updateLatestInstallationStatus();

      yield this.app.reload();
      this.app.updateLocalCache(this.modelDataCacheService);
      this.checkedInstallationAtLeastOnce = true;

      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'check_messenger_installation',
        place: 'installation-new',
        section: 'settings',
        platform: this.args.platform,
      });
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t(
          'apps.app.settings.installation.installation.new.installation-status.manual-check-error-message',
        ),
      );
    }
  }

  @action
  async enableUserTrafficApi() {
    await taskFor(this.messengerInstallation.togglePlatformApiAvailability).perform(
      this.args.platform,
      false,
    );

    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.check-installation-step.user-traffic-enabled',
      ),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::InstallationStatus': typeof InstallationStatus;
    'installation-new/installation-status': typeof InstallationStatus;
  }
}
