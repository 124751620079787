/* RESPONSIBLE TEAM: team-product-guidance */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import { Step } from 'embercom/objects/onboarding/checklist/step';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';

export default class GreatGuidanceService extends Service {
  @service declare realTimeEventService: any;

  @tracked isEmailInstalled = false;
  @tracked isWhatsappInstalled = false;
  @tracked isSmsInstalled = false;
  @tracked isMessengerInstalled = false;
  @tracked nextStepId?: string;
  @tracked currentStepId?: string;
  @tracked steps: Step[] = [];
  @tracked isFloatingWidgetExpanded = false;
  @tracked hideFloatingWidget = true;
  @tracked toggleWidgetInvisibilityMap: Map<string, boolean> = new Map();

  constructor() {
    super(...arguments);
    this.addNexusEventListeners();
    registerDestructor(this, () => {
      this.removeNexusEventListeners();
    });
  }

  addNexusEventListeners() {
    this.realTimeEventService.subscribeTopics(['mark-channel-as-installed']);
    this.realTimeEventService.on('MarkChannelAsInstalled', this, 'markChannelAsInstalled');
  }

  removeNexusEventListeners() {
    this.realTimeEventService.unsubscribeTopics(['mark-channel-as-installed']);
    this.realTimeEventService.off('MarkChannelAsInstalled', this, 'markChannelAsInstalled');
  }

  markChannelAsInstalled(event: any): void {
    if (event.channel === 'whatsapp') {
      this.isWhatsappInstalled = true;
    } else if (event.channel === 'sms') {
      this.isSmsInstalled = true;
    } else if (event.channel === 'email') {
      this.isEmailInstalled = true;
    }
  }

  get isAnyChannelInstalled() {
    return (
      this.isEmailInstalled ||
      this.isWhatsappInstalled ||
      this.isSmsInstalled ||
      this.isMessengerInstalled
    );
  }

  get widgetVisibility() {
    return !Array.from(this.toggleWidgetInvisibilityMap.values()).some((value) => value);
  }
  get nextStep(): Step | undefined {
    if (this.nextStepId) {
      let nextStep = this.steps.find((step) => step.identifier === this.nextStepId);
      if (nextStep && nextStep.state === 'available') {
        return nextStep;
      }
    }
    return this.steps.find((step) => step.state === 'available');
  }
  @action
  setWidgetVisibility(component: string, visibility: boolean) {
    if (!this.hideFloatingWidget) {
      this.toggleWidgetInvisibilityMap.set(component, !visibility);
      this.toggleWidgetInvisibilityMap = this.toggleWidgetInvisibilityMap;
    }
  }
  @action
  async fetchSteps(appId: string) {
    let guide: any = await get(`/ember/onboarding/home/guide?app_id=${appId}`);
    this.hideFloatingWidget = !guide.show_widget;
    this.steps = this.flattenSteps(guide);
    this.currentStepId = this.nextStep?.identifier;
  }
  flattenSteps(guide: any) {
    let allSteps: Step[] = [];
    guide.levels.forEach((level: any) => {
      level.steps.forEach((step: any) => {
        let deserialisedStep: Step = Step.deserialize(step);
        allSteps.push(deserialisedStep);
      });
    });
    return allSteps;
  }
}
