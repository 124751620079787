/* import __COLOCATED_TEMPLATE__ from './authenticate.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';

interface Args {
  subdomain: string;
  startOauth: (e: MouseEvent) => void;
  location: 'wizard' | 'onboarding-home';
}

export default class Authenticate extends Component<Args> {
  get isUrlBlank() {
    return isBlank(this.args.subdomain);
  }

  get isLocationWizard(): boolean {
    return this.args.location === 'wizard';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SideBySide::Zendesk::Authenticate': typeof Authenticate;
    'side-by-side/zendesk/authenticate': typeof Authenticate;
  }
}
