/* RESPONSIBLE TEAM: team-channels */
import type UserSummary from 'embercom/objects/inbox/user-summary';
import Conversation, { type NewConversation } from 'embercom/objects/inbox/conversation';
import type UserComment from 'embercom/objects/inbox/renderable/user-comment';
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { Channel } from 'embercom/models/data/inbox/channels';
import { isPresent } from '@ember/utils';
import { uniqBy } from 'embercom/helpers/uniq-by';
import { type EmailHeaderAddress } from 'embercom/objects/inbox/renderable/email-metadata';
import type UserEmailComment from 'embercom/objects/inbox/renderable/user-email-comment';

export type RecipientsWireFormat = {
  to: {
    emails: string[] | [];
    ids: string[] | [];
  };
  cc: {
    emails: string[] | [];
    ids: string[] | [];
  };
};

export class ReplyRecipientsModel {
  to: UserSummary[];
  cc: UserSummary[];

  constructor(toRecipients?: UserSummary[], ccRecipients?: UserSummary[]) {
    this.to = toRecipients || [];
    this.cc = ccRecipients || [];
  }

  get all(): UserSummary[] {
    return uniqBy([...this.to, ...this.cc], (recipient: UserSummary) =>
      isPresent(recipient.email) ? recipient.email : recipient.id,
    );
  }

  serialize(): RecipientsWireFormat {
    let { ids: toIds, emails: toEmails } = this.getIdsAndEmails(this.to);
    let { ids: ccIds, emails: ccEmails } = this.getIdsAndEmails(this.cc);
    return {
      to: {
        emails: toEmails,
        ids: toIds,
      },
      cc: {
        emails: ccEmails,
        ids: ccIds,
      },
    };
  }

  private getIdsAndEmails(recipients: UserSummary[]): { ids: string[]; emails: string[] } {
    let ids = recipients.map((recipient) => recipient.id).filter(Boolean);
    let recipientsWithoutIds = recipients.filter((recipient) => !ids.includes(recipient.id));

    let emails = recipientsWithoutIds
      .map((recipient) => recipient.email)
      .filter((email): email is string => Boolean(email));
    return { ids, emails };
  }
}

function buildDefaultRecipients(conversation: Conversation | NewConversation) {
  return new ReplyRecipientsModel(conversation.participantSummaries, []);
}

function buildEmailReplyRecipients(conversation: Conversation | NewConversation) {
  let toRecipients = conversation.participantSummaries.slice(0, 1);
  let ccRecipients = conversation.participantSummaries.slice(1);
  return new ReplyRecipientsModel(toRecipients, ccRecipients);
}

function buildUserCommentReplyRecipients(conversation: Conversation, lastPart: UserComment) {
  let toRecipients = [lastPart.userSummary];
  let ccRecipients = conversation.participantSummaries.filter(
    (participant) => participant.email !== lastPart.userSummary.email,
  );

  return new ReplyRecipientsModel(toRecipients, ccRecipients);
}

function matchOrder(
  unorderedRecipients: UserSummary[],
  orderedEmails: (string | undefined)[] | undefined,
) {
  if (!orderedEmails || orderedEmails?.find((email) => isPresent(email))?.length === 0) {
    return unorderedRecipients;
  }

  return unorderedRecipients.sort((a, b) => {
    let indexA = orderedEmails.indexOf(a.email) ?? -1;
    let indexB = orderedEmails.indexOf(b.email) ?? -1;
    return indexA - indexB;
  });
}

function getRecipientsFromHeaderAddress(
  conversation: Conversation,
  emailHeaderAddresses?: EmailHeaderAddress[],
) {
  let orderedRecipientEmails = emailHeaderAddresses?.map((address) => address.email);

  let recipients = conversation.participantSummaries.filter((participant) =>
    orderedRecipientEmails?.includes(participant.email),
  );
  return matchOrder(recipients, orderedRecipientEmails);
}

function buildAdminCommentReplyRecipients(conversation: Conversation, lastPart: AdminComment) {
  let toRecipients = getRecipientsFromHeaderAddress(
    conversation,
    lastPart.emailMetadata?.headerAddresses?.to,
  );

  let ccRecipients = getRecipientsFromHeaderAddress(
    conversation,
    lastPart.emailMetadata?.headerAddresses?.cc,
  );

  let nonHeaderParticipants = conversation.participantSummaries.filter(
    (participant) => !toRecipients.includes(participant) && !ccRecipients.includes(participant),
  );

  ccRecipients = ccRecipients.concat(nonHeaderParticipants);

  return new ReplyRecipientsModel(toRecipients, ccRecipients);
}

function partIsUserComment(part: RenderablePart | undefined) {
  return (
    part?.renderableType === RenderableType.UserComment ||
    part?.renderableType === RenderableType.UserEmailComment
  );
}

function isUserCommentFromAParticipant(part: RenderablePart, conversation: Conversation) {
  let userComment = part.renderableData as UserComment;
  let participantEmails = conversation.participantSummaries.map((participant) => participant.email);

  return partIsUserComment(part) && participantEmails.includes(userComment.userSummary.email);
}

function isAdminCommentWithEmailMetadata(part: RenderablePart) {
  return part.renderableType === RenderableType.AdminComment && part.renderableData.emailMetadata;
}

function lastValidPartForReply(conversation: Conversation) {
  let allHumanPartsOrderedLastToFirst = conversation.allHumanCommentParts.slice().reverse();

  return allHumanPartsOrderedLastToFirst.find((part) => {
    return (
      isAdminCommentWithEmailMetadata(part) || isUserCommentFromAParticipant(part, conversation)
    );
  });
}

function isEmailCurrentChannel(conversation: Conversation | NewConversation) {
  return conversation instanceof Conversation && conversation?.channel.current === Channel.Email;
}

function partIsAdminComment(part: RenderablePart | undefined) {
  return part?.renderableType === RenderableType.AdminComment;
}

function createEmailReplyRecipients(conversation: Conversation) {
  let lastPart = lastValidPartForReply(conversation);

  return getRecipientsForPart(conversation, lastPart);
}

export function getRecipientsForPart(conversation: Conversation, part: RenderablePart | undefined) {
  if (partIsUserComment(part)) {
    return buildUserCommentReplyRecipients(conversation, part?.renderableData as UserComment);
  } else if (partIsAdminComment(part)) {
    return buildAdminCommentReplyRecipients(conversation, part?.renderableData as AdminComment);
  } else {
    return buildEmailReplyRecipients(conversation);
  }
}

export default function createReplyRecipients(conversation: Conversation | NewConversation) {
  if (isEmailCurrentChannel(conversation)) {
    return createEmailReplyRecipients(conversation as Conversation);
  } else {
    return buildDefaultRecipients(conversation);
  }
}

export function getRecipientsListFromMetadata(part: RenderablePart, allRecipients: UserSummary[]) {
  let ccPartMetadata = part.renderableData?.emailMetadata?.headerAddresses?.cc;
  let toPartMetadata = part.renderableData?.emailMetadata?.headerAddresses?.to;
  let ccRecipients = allRecipients.filter((recipient) => {
    return ccPartMetadata?.find((cc) => cc.email === recipient.email);
  });
  let toRecipients = allRecipients.filter((recipient) => {
    return toPartMetadata?.find((to) => to.email === recipient.email);
  });

  if (part.renderableType === RenderableType.UserEmailComment) {
    toRecipients = [...toRecipients, (part.renderableData as UserEmailComment).userSummary];
  }

  return new ReplyRecipientsModel(toRecipients, ccRecipients);
}
