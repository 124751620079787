/* RESPONSIBLE TEAM: team-messenger */

export enum INSTALLATION_TYPE {
  LOGGED_IN_USERS = 'logged-in-users',
  VISITORS = 'visitors',
}

export enum INSTALLATION_METHOD {
  CODE_SNIPPET = 'code-snippet',
  WORDPRESS = 'wordpress',
  GOOGLE_TAG_MANAGER = 'google-tag-manager',
  SHOPIFY = 'shopify',
  SEGMENT = 'segment',
  REACT = 'react',
  ANGULAR = 'angular',
  VUE = 'vue',
  EMBER = 'ember',
  IOS = 'ios',
  ANDROID = 'android',
  REACT_NATIVE = 'react-native',
}

export enum INSTALLATION_PLATFORMS {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android',
}

export enum MOBILE_PLATFORM_NAMES {
  IOS = 'iOS',
  ANDROID = 'Android',
}

// Object used in the installation methods selection component
export type InstallationMethodSelection = {
  id: INSTALLATION_METHOD;
  label: string;
  iconUrl: string;
  instructionsTitle: string;
  instructionsSubTitle?: string;
};

export type MobilePlatform = 'ios' | 'android';
export type InstallationPlatform = 'web' | 'ios' | 'android';
export type InstallationMethod =
  | 'code-snippet'
  | 'wordpress'
  | 'google-tag-manager'
  | 'shopify'
  | 'segment'
  | 'react'
  | 'angular'
  | 'vue'
  | 'ember'
  | 'ios'
  | 'android'
  | 'react-native';
export type AndroidInstallationScript = PROGRAMMING_LANGUAGES.GROOVY | PROGRAMMING_LANGUAGES.KOTLIN;

export enum ANDROID_EXAMPLE_FORMATS {
  JAVA = 'java',
  KOTLIN = 'kotlin',
  REACT_NATIVE = 'reactnative',
}

export enum IOS_EXAMPLE_FORMATS {
  SWIFT = 'swift',
  REACT_NATIVE = 'reactnative',
}

export enum IOS_PROGRAMMING_LANGUAGES {
  SWIFT = 'swift',
  SWIFT_UI = 'swift-ui',
  OBJECTIVE_C = 'objectivec', // No "-"" because this is used to populate highlight.js language which expects objectivec instead of objective-c
}

export enum ANDROID_PROGRAMMING_LANGUAGES {
  JAVA = 'java',
  KOTLIN = 'kotlin',
}

export enum PROGRAMMING_LANGUAGES {
  NODE = 'node',
  RAILS = 'rails',
  DJANGO = 'django',
  PHP = 'php',
  GO = 'golang',
  JAVA = 'java',
  JAVASCRIPT = 'javascript',
  HTML = 'html',
  SWIFT = IOS_PROGRAMMING_LANGUAGES.SWIFT,
  SWIFT_UI = IOS_PROGRAMMING_LANGUAGES.SWIFT_UI,
  OBJECTIVE_C = IOS_PROGRAMMING_LANGUAGES.OBJECTIVE_C,
  GROOVY = 'groovy',
  KOTLIN = ANDROID_PROGRAMMING_LANGUAGES.KOTLIN,
}

export enum INSTALLATION_STATUS {
  NOT_INSTALLED = 'not-installed',
  SECURELY_INSTALLED = 'securely-installed',
  INSECURELY_INSTALLED = 'insecurely-installed',
  INSTALLED_FOR_VISITORS_ONLY = 'installed-for-visitors-only',
}

export enum IOS_INSTALLATION_OPTIONS {
  SWIFT_PACKAGE_MANAGER = 'swift-package-manager',
  COCOAPODS = 'cocoapods',
  MANUAL = 'manual',
}
