/* import __COLOCATED_TEMPLATE__ from './comparison-content.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import type Plan from 'embercom/models/plan';
import {
  VBP2_STARTER_UPGRADE_PATH,
  VBP1_DEFAULT_UPGRADE_PATH,
  PRICING_5_X_CORE_PLANS,
} from 'embercom/lib/billing';
import { inject as service } from '@ember/service';
import type BillingFeature from 'embercom/models/billing-feature';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { PLAN_DATA } from 'embercom/lib/billing';

export interface Args {
  openedFromBillingSummary: boolean | undefined;
  feature: BillingFeature | undefined;
  featureLessHeaders: boolean | undefined;
  plan: Plan;
  selectedPlan: Plan;
  setFakePlan: (planId: number) => void;
}

export default class ComparisonContent extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare selfServeTrialsService: any;
  @service declare paywallService: any;
  @tracked selectedPlanId = this.args.selectedPlan.idAsNumber;

  PLAN_IDS_TO_DISPLAY = [
    ...VBP2_STARTER_UPGRADE_PATH,
    ...VBP1_DEFAULT_UPGRADE_PATH,
    ...PRICING_5_X_CORE_PLANS,
  ];

  get featureName() {
    if (this.featureIsOnHideFeatureList) {
      return null;
    }
    return this.intl.t(`paywalls.upgrade-modal.features.${this.args.feature?.key}.feature-name`);
  }

  get allPLansOnPricingModel() {
    return this.appService.app.allPlansOnPricingModel;
  }

  get featureIsOnHideFeatureList() {
    if (!this.args.feature) {
      return false;
    }
    return this.paywallService.featuresToHide().has(this.args.feature.key);
  }

  get planData() {
    return PLAN_DATA[this.args.selectedPlan?.id];
  }

  get hideSubtitle() {
    return this.shouldHideFeatureNamesInCopy || this.planData?.hideSubtitle;
  }

  get hideBadge() {
    return this.planData?.hideBadge;
  }

  get shouldHideFeatureNamesInCopy() {
    return (
      this.args.openedFromBillingSummary ||
      this.args.featureLessHeaders ||
      this.featureIsOnHideFeatureList
    );
  }

  get headerText(): string {
    if (this.planData?.alternativeHeader) {
      return this.intl.t('paywalls.comparison.alternative-header', {
        featureName: this.featureName?.toLowerCase(),
      });
    }

    if (this.shouldHideFeatureNamesInCopy) {
      if (this.args.openedFromBillingSummary || this.args.featureLessHeaders) {
        return this.intl.t('paywalls.comparison.change-your-plan');
      } else {
        return this.intl.t('paywalls.comparison.upgrade-header');
      }
    } else if (this.selectedPlan.product.addon) {
      return this.intl.t('paywalls.comparison.addon-header', { featureName: this.featureName });
    } else {
      return this.intl.t('paywalls.comparison.header', { featureName: this.featureName });
    }
  }

  get plans() {
    if (this.args.openedFromBillingSummary) {
      return this.allPLansOnPricingModel.filter((plan: Plan) =>
        this.PLAN_IDS_TO_DISPLAY.includes(plan.id),
      );
    }
    if (this.selectedPlan?.product.addon || this.appService.app.isFrozenForNonPayment) {
      return [this.selectedPlan];
    } else {
      return [this.currentPlan, this.selectedPlan];
    }
  }

  // if there are no billable customer plans or no active graduating trials assume there is a plan on trial
  // this is cover the scenario where RMs create workspaces that have all plans on trial and are not graduating trials
  get currentPlan() {
    if (this.appService.app.onPricing5) {
      let planInSubscription =
        this.allPLansOnPricingModel.find(
          (plan: Plan) =>
            (plan.billableCustomerPlan || (plan.active && plan.activeTrialIsGraduating)) &&
            !plan.product.addon,
        ) || null;
      if (!planInSubscription) {
        planInSubscription =
          this.allPLansOnPricingModel.find(
            (plan: Plan) =>
              plan.active &&
              plan.activeTrial &&
              !plan.activeTrialIsGraduating &&
              !plan.product.addon,
          ) || null;
      }
      return planInSubscription;
    }
    return (
      this.allPLansOnPricingModel.find(
        (plan: Plan) => plan.billableCustomerPlan && !plan.product.addon,
      ) || null
    );
  }

  get selectedPlan(): Plan {
    return this.allPLansOnPricingModel.find((plan: Plan) => {
      return plan.id === this.args.selectedPlan.id;
    });
  }

  get isSinglePlanPaywall() {
    return this.plans.length === 1;
  }

  @action
  selectPlan(planId: number) {
    this.selectedPlanId = planId;
    this.args.setFakePlan(planId);
  }

  get badges(): Array<any> {
    let badges = this.plans.map((plan: Plan) => {
      if (this.featureName && this.appService.app.isFrozenForNonPayment) {
        return {
          style: 'paywall',
          text: this.intl.t('paywalls.comparison.plan-cards.badge.included', {
            featureName: this.featureName,
          }),
          width: 'w-1/2',
        };
      }
      if (plan.billableCustomerPlan || (plan.active && plan.activeTrialIsGraduating)) {
        return {
          style: 'black',
          text: this.intl.t('paywalls.comparison.plan-cards.badge.your_plan'),
          width: 'w-1/2',
        };
      }
      if (plan.activeTrial && !plan.activeTrialIsGraduating) {
        return {
          style: 'paywall',
          text: this.intl.t('paywalls.comparison.plan-cards.badge.trialling'),
          width: 'w-1/2',
        };
      }
      if (
        !this.args.openedFromBillingSummary &&
        !this.args.featureLessHeaders &&
        this.featureName
      ) {
        return {
          style: 'paywall',
          text: this.intl.t('paywalls.comparison.plan-cards.badge.included', {
            featureName: this.featureName,
          }),
          width: 'w-1/2',
        };
      }
      if (plan.isHigherTierActive) {
        return {
          style: 'grey',
          text: this.intl.t('paywalls.comparison.plan-cards.badge.downgrade'),
          width: 'w-1/2',
        };
      }
      if (plan.selfServe) {
        return {
          style: 'paywall',
          text: this.intl.t('paywalls.comparison.plan-cards.badge.upgrade_now'),
          width: 'w-1/2',
        };
      }
      if (plan.selfServeTrialable) {
        return {
          style: 'paywall',
          text: this.intl.t('paywalls.comparison.plan-cards.badge.trial_now'),
          width: 'w-1/2',
        };
      }
      return {
        style: 'paywall',
        text: this.intl.t('paywalls.comparison.plan-cards.badge.chat_with_sales'),
        width: 'w-1/2',
      };
    });

    return badges.reduce((acc: Array<any>, cur: any) => {
      if (acc.lastObject && acc.lastObject.text === cur.text) {
        acc.lastObject.width = 'w-full';
        return acc;
      } else {
        return [...acc, cur];
      }
    }, []);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::Comparison::ComparisonContent': typeof ComparisonContent;
    'paywalls/comparison/comparison-content': typeof ComparisonContent;
  }
}
