/* import __COLOCATED_TEMPLATE__ from './app-card.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { postRequest } from 'embercom/lib/inbox/requests';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type MessengerAppInsertionService from 'embercom/services/messenger-app-insertion-service';
import { MessengerAppEvents } from 'embercom/services/messenger-app-insertion-service';
import ENV from 'embercom/config/environment';
import { later } from '@ember/runloop';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import { type TaskGenerator } from 'ember-concurrency';
import { type SidebarSectionId } from './sidebar-item-container';

interface Args {
  cardIdentifier: string;
  conversationId: number;
  inboxAppId: number;
  activeUserId: string;
  name: string;
  iconUrl?: string;
  isOpen: boolean;
  onOpenSectionChange: (value: SidebarSectionId) => (value: SidebarSectionId) => unknown;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

interface Canvas {
  id: number;
}

const NIL_USER_ID = '-1';

interface SubmitResponse {
  canvas: Canvas;
  card_creation_options?: Record<string, unknown>;
}

export default class AppCard extends Component<Signature> {
  @service declare session: Session;
  @service declare messengerAppInsertionService: MessengerAppInsertionService;
  @tracked supportUrl = '';
  @tracked canvas?: Canvas;

  canvasLoader = DeduplicatedAsyncData(
    this,
    () => [this.args.conversationId, this.args.activeUserId, this.args.inboxAppId],
    async (conversationId, userId, appId, { signal }) => {
      if (userId === NIL_USER_ID && this.args.cardIdentifier !== 'inbox_app_jira-for-tickets') {
        return;
      }

      try {
        let response = await postRequest(
          '/ember/inbox_apps/initialize',
          {
            conversation_id: conversationId,
            inbox_app_id: appId,
            user_id: userId,
            admin_id: this.session.teammate.id,
            app_id: this.session.workspace.id,
          },
          { signal, priority: 'low' },
        );

        let json = (await response.json()) as { canvas: Canvas };
        return (this.canvas = json.canvas);
      } catch (err) {
        this.setSupportUrl(err);
        throw err;
      }
    },
  );

  @action
  reloadCanvas() {
    this.canvasLoader.reload();
  }

  @action
  urlAction(url: string) {
    safeWindowOpen(url);
  }

  setSupportUrl(err: { jqXHR?: { responseJSON?: { support_url?: string } } }) {
    this.supportUrl = err?.jqXHR?.responseJSON?.support_url || '';
  }

  get submitAppIsRunning() {
    return taskFor(this.submitApp).isRunning;
  }

  @task({ restartable: true })
  *submitApp(actionComponentId: number, canvasInputValues: unknown): TaskGenerator<void> {
    try {
      let response = yield postRequest('/ember/inbox_apps/submit', {
        conversation_id: this.args.conversationId,
        inbox_app_id: this.args.inboxAppId,
        admin_id: this.session.teammate.id,
        app_id: this.session.workspace.id,
        user_id: this.args.activeUserId,
        component_id: actionComponentId,
        input_values: canvasInputValues,
        current_canvas: this.canvas,
      });

      let json = (yield response.json()) as SubmitResponse;

      if (json.card_creation_options) {
        taskFor(this.initializeMessengerApp).perform(json.card_creation_options);
      }

      this.canvas = json.canvas;
    } catch (err) {
      this.setSupportUrl(err);
      throw err;
    }
  }

  @task({ restartable: true })
  *initializeMessengerApp(cardCreationOptions: Record<string, unknown>): TaskGenerator<any> {
    this.messengerAppInsertionService.on(
      MessengerAppEvents.UpdateInboxAppCanvas,
      this,
      'handleMessengerAppInsertionResponse',
    );
    let timeoutPromise = new Promise((_, reject) => {
      later(this, () => reject(new Error('Inserting messenger app timed out')), ENV.APP._5000MS);
    });

    let initializeAppPromise = new Promise((resolve, reject) => {
      this.messengerAppInsertionService.initializeMessengerApp(
        this.args.inboxAppId,
        cardCreationOptions,
        resolve,
        reject,
      );
    });

    yield Promise.race([initializeAppPromise, timeoutPromise]);
    this.messengerAppInsertionService.off(
      MessengerAppEvents.UpdateInboxAppCanvas,
      this,
      'handleMessengerAppInsertionResponse',
    );
  }

  handleMessengerAppInsertionResponse(
    inboxAppId: number,
    error: any,
    resolve: () => void,
    reject: (error: Error) => void,
  ) {
    if (this.args.inboxAppId === inboxAppId) {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::AppCard': typeof AppCard;
    'inbox2/conversation-details-sidebar/app-card': typeof AppCard;
  }
}
