/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

/**
 * Validates value does not contain the word "Intercom"
 *
 */
const TextDoesNotContainIntercom = BaseValidator.extend({
  validate(value) {
    let invalidMessage = 'Text must not contain the word "Intercom"';
    return value.toLowerCase().includes('Intercom'.toLowerCase()) ? invalidMessage : true;
  },
});

export default TextDoesNotContainIntercom;
