/* RESPONSIBLE TEAM: team-actions */
import { isEmpty, isNone } from '@ember/utils';
import { isEqual } from 'underscore';
import { A } from '@ember/array';

const setDifference = (arrayA, arrayB) => {
  let setA = new Set(arrayA);
  let setB = new Set(arrayB);
  return [...setA].filter((valueFromA) => !setB.has(valueFromA));
};

const compareScalars = (scalarA, scalarB) => {
  if (isNone(scalarA) && isNone(scalarB)) {
    return { hasDifference: false };
  }
  let hasDifference = scalarA !== scalarB;
  let isAdded = isEmpty(scalarA) && !isEmpty(scalarB);
  let isRemoved = !isEmpty(scalarA) && isEmpty(scalarB);
  let newValue = scalarB;
  return { hasDifference, isAdded, isRemoved, newValue };
};

const compareArrays = (arrayA, arrayB) => {
  let removedValues = setDifference(arrayA, arrayB);
  let addedValues = setDifference(arrayB, arrayA);
  let isAdded = isEmpty(arrayA) && !isEmpty(addedValues);
  let isRemoved = isEmpty(arrayB) && !isEmpty(removedValues);
  let hasDifference = !(isEmpty(addedValues) && isEmpty(removedValues));
  let newValue = arrayB;
  return { hasDifference, isAdded, isRemoved, newValue, addedValues, removedValues };
};

const compareBlocks = (blocksA, blocksB) => {
  let stringA = Em.isEmpty(blocksA) ? '' : JSON.stringify(blocksA);
  let stringB = Em.isEmpty(blocksB) ? '' : JSON.stringify(blocksB);
  let hasDifference =
    Array.isArray(blocksA) && Array.isArray(blocksB) && blocksA.length === blocksB.length
      ? blocksA.any((elementA, index) => !isEqual(elementA, blocksB[index]))
      : stringA !== stringB;
  let isAdded = Em.isEmpty(stringA) && !Em.isEmpty(stringB);
  let isRemoved = !Em.isEmpty(stringA) && Em.isEmpty(stringB);
  let newValue = blocksB;
  return { hasDifference, isAdded, isRemoved, newValue };
};

const COMPARISON_FUNCTIONS = {
  string: compareScalars,
  boolean: compareScalars,
  array: compareArrays,
};

/**
 * Find the diff between two ember-data models, and
 * return an object to represent the diff result.
 *
 * you can set options on your ember-data model attributes to change
 * the behavior of the diff-calculator per attribute.
 *
 * noDiff: to skip finding diff for a specific attribute.
 * diffFunction: to specify a custom diff comparison function.
 * renderingComponentPath: to provide a component for custom
 *                         attribute diff rendering
 *
 * @return {Object} returns a diff result object
 *
 * E.g.:
 * In case of a scalar type: {
 *       name: 'url',
 *       type: 'string',
 *       renderingComponentPath: 'ember/some-component-path/..',
 *       hasDifference: true,
 *       isAdded: true,
 *       isRemoved: false,
 *       newValue: 'https://some-example.com'
 *       newModel: ...,
 *     }
 *
 * In case of an array type: {
 *       name: 'urls',
 *       type: 'array',
 *       renderingComponentPath: 'ember/some-component-path/..',
 *       hasDifference: true,
 *       isAdded: false,
 *       isRemoved: false,
 *       addedValues: ['new-url'],
 *       removedValues: ['old-url'],
 *       newValue: ['new-url']
 *       newModel: ...,
 *     }
 *
 */
export default function (modelA, modelB) {
  let results = A();
  modelA.eachAttribute((name, meta) => {
    let comparisonFunction = meta.options.diffFunction;
    let humanisedName = meta.options.humanisedName;
    let noDiff = meta.options.noDiff;
    if (noDiff) {
      return;
    }
    if (meta.options.containsBlocks) {
      comparisonFunction = compareBlocks;
    }
    if (isNone(comparisonFunction)) {
      comparisonFunction = COMPARISON_FUNCTIONS[meta.type];
    }
    if (isNone(comparisonFunction)) {
      throw new Error(`Unknown type for comparison [${meta.type}]`);
    }
    let valueA = modelA.get(name);
    let valueB = modelB.get(name);
    let comparisonResult = comparisonFunction(valueA, valueB);
    if (!comparisonResult.hasDifference) {
      return;
    }
    let result = Object.assign(comparisonResult, {
      name,
      humanisedName,
      type: meta.type,
      renderingComponentPath: meta.options.renderingComponentPath,
      oldModel: modelA,
      newModel: modelB, // so it can be accessed inside rendering components
    });
    results.pushObject(result);
  });
  return results;
}

export { COMPARISON_FUNCTIONS, compareBlocks };
