/* import __COLOCATED_TEMPLATE__ from './brand-select.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Brand from 'embercom/models/brand';
import { tracked } from '@glimmer/tracking';

interface Args {
  brands: Brand[];
  selectedBrandId: string | null | undefined;
  onBrandSelect: (brandId: string) => void;
  place: string;
}

interface Signature {
  Args: Args;
}

export default class BrandSelect extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  @tracked initialBrandId: string | null | undefined;

  private DEFAULT_BRAND_ID = '0';

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.initialBrandId = args.selectedBrandId;
  }

  // handle the case where a user has selected a brand, but the list has not been loaded yet
  // and fall back to the default for the moment, as ic-select explodes if you give it a value it doesn't know about
  get selectedBrandIdFromList() {
    let foundBrand = this.brandsList.find((brand) => brand.value === this.args.selectedBrandId);
    if (foundBrand) {
      return foundBrand.value;
    } else {
      return this.DEFAULT_BRAND_ID;
    }
  }

  get brandsList() {
    let defaultBrandOption = {
      text: this.appService.app.name,
      value: this.DEFAULT_BRAND_ID,
    };

    let customBrandOptions = this.customBrands.map((brand: Brand) => ({
      text: brand.name,
      value: brand.id,
    }));

    return [defaultBrandOption, ...customBrandOptions].sort((a, b) => a.text.localeCompare(b.text));
  }

  get customBrands() {
    return this.args.brands.filter((brand) => !brand.isDefault);
  }

  @action
  onBrandSelect(brandId: string) {
    this.args.onBrandSelect(brandId);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'brand',
      place: this.args.place,
      context: 'email-setup-sidebar',
      initialBrandId: this.initialBrandId,
      selectedBrandId: brandId,
    });
  }

  @action
  openBrandsSettingsPage() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'setup_a_brand_link',
      place: this.args.place,
      context: 'email-setup-sidebar',
    });
    let url = this.router.urlFor('apps.app.settings.workspace.brands', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::BrandSelect': typeof BrandSelect;
  }
}
