/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import Admin from 'embercom/models/admin';
import { fragment } from 'ember-data-model-fragments/attributes';
import { isEmpty, isPresent } from '@ember/utils';
import Range from 'embercom/models/reporting/range';
import ajax from 'embercom/lib/ajax';
import { FilterLogicalOperators } from 'embercom/lib/reporting/custom/filter-helpers';
import { isEqual } from 'underscore';
import { tracked } from '@glimmer/tracking';
import { copy } from 'ember-copy';

export default class Report extends Model {
  @service store;
  @service appService;
  @service intl;

  @attr('string') title;
  @attr('string') description;
  @attr('date') updatedAt;
  @attr('string') createdById;
  @attr('string') updatedById;
  @attr({ defaultValue: () => ({ type: 'and', filters: [] }) }) filters;
  @fragment('reporting/custom/date-range') dateRange;

  @hasMany('reporting/custom/chart') charts;
  @attr('string') timezone;

  // used internally - not persisted
  @attr('boolean', { shouldSerialize: false }) isStaticReport;
  @attr({ defaultValue: () => false, shouldSerialize: false }) fromTemplate;
  @attr('string') createdFromTemplateId;

  @tracked isBeingSaved = false;

  get app() {
    return this.appService.app;
  }

  get range() {
    return Range.createFromModel(this.dateRange, this.reportTimezone);
  }

  get reportTimezone() {
    return this.timezone || this.appService.app.timezone;
  }

  get hasCharts() {
    return this.availableCharts?.length > 0;
  }

  get hasNoCharts() {
    return !this.hasCharts;
  }

  applyTimeZone(newTimeZone) {
    this.timezone = newTimeZone;
  }

  applyDateRange(newDateRange) {
    if (isEmpty(newDateRange)) {
      this.dateRange = null;
    } else {
      if (isEmpty(this.dateRange)) {
        this.dateRange = this.store.createFragment('reporting/custom/date-range');
      }

      this.dateRange.selection = newDateRange.selectedRange;

      if (newDateRange.selectedRange === 'custom') {
        this.dateRange.start = newDateRange.startMoment.toDate();
        this.dateRange.end = newDateRange.endMoment.toDate();
      }
    }
  }

  applyFilter(index, attribute, values, operator) {
    let existingFilters = copy(this.filters?.filters, true) || [];
    let existingFilter = existingFilters[index];
    let topLevelFilterType = this.filters?.type || FilterLogicalOperators.and;

    if (operator === null && values.length === 0) {
      this.filters = {
        type: topLevelFilterType,
        filters: existingFilters.reject((f, filterIndex) => index === filterIndex),
      };
      return;
    }

    let newTopLevelFilter = {
      type: topLevelFilterType,
      filters: existingFilters,
    };

    let filter = {
      type: operator,
      data: {
        property: attribute.field,
      },
    };

    if (this.app.canUseAttributesInFilters) {
      filter.data.attribute = attribute.id;
    }

    if (isPresent(values)) {
      filter.data.values = values;
    }
    if (existingFilter) {
      newTopLevelFilter.filters[index] = filter;
    } else {
      newTopLevelFilter.filters.push(filter);
    }

    this.filters = newTopLevelFilter;
  }

  applyLogicalFilterOperator(operator) {
    let filters = this.filters?.filters || [];

    this.filters = {
      type: operator,
      filters,
    };
  }

  async addChartFromTemplate(attributes) {
    let newChart = this.store.createRecord('reporting/custom/chart', attributes);
    await this.saveChart(newChart, { doNotClearTemplateId: true });
    return newChart;
  }

  async duplicateChart(chart) {
    let newChart = this.store.createRecord(
      'reporting/custom/chart',
      chart.deepCopyChartAttributes(),
    );
    await this.saveChart(newChart, { doNotClearTemplateId: true });
    return newChart;
  }

  async saveChart(chart, { doNotClearTemplateId = false } = {}) {
    if (chart.hasDirtyAttributes) {
      if (!doNotClearTemplateId) {
        chart.templateId = null;
      }
      await chart.validateAndSave();
    }

    if (!this.charts.includes(chart)) {
      this.charts.pushObject(chart);
    }
  }

  async removeChart(chart, { save = true }) {
    if (save) {
      await chart.destroyRecord();
    } else {
      chart.deleteRecord();
    }
  }

  get createdByAdmin() {
    if (this.createdById) {
      return Admin.peekAndMaybeLoad(this.store, this.createdById);
    } else {
      return null;
    }
  }

  get updatedByAdmin() {
    if (this.updatedById) {
      return Admin.peekAndMaybeLoad(this.store, this.updatedById);
    } else {
      return null;
    }
  }

  get createdByAdminSortableName() {
    return this.createdByAdmin ? this.createdByAdmin.name : 'Intercom';
  }

  get isIntercomOwnedReport() {
    return this.id && this.createdById === null;
  }

  get titleForDisplay() {
    let title = this.persistedTitle();
    return isEmpty(title) ? this.intl.t('reporting.custom-reports.report.untitled') : title;
  }

  get customCharts() {
    return this.availableCharts.filter((chart) => isEmpty(chart.templateId));
  }

  get hasCustomCharts() {
    return this.customCharts.length > 0;
  }

  persistedTitle() {
    if (this.hasDirtyAttributes) {
      let changes = this.changedAttributes();
      return changes.title ? changes.title[0] : this.title;
    } else {
      return this.title;
    }
  }

  async saveWithoutRecreatingStoreModels() {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);

    let url = adapter.buildURL(modelName, this.id);
    await adapter.ajax(url, 'PUT', { data: this.serialize() });
    this.store.pushPayload({ 'reporting/custom/report': { id: this.id } });
  }

  async duplicateReport(requestBody) {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);

    let baseUrl = adapter.buildURL(modelName);
    let url = `${baseUrl}/duplicate`;
    return await ajax({ url, type: 'POST', data: JSON.stringify(requestBody) });
  }

  get hasTitle() {
    return isPresent(this.title);
  }

  get hasDescription() {
    return isPresent(this.description);
  }

  get isNewAndUnChanged() {
    return this.isNew && !this.hasCharts && !this.hasTitle && !this.hasDescription;
  }

  async save() {
    this.isBeingSaved = true;
    await super.save(...arguments);
    this._clearUnsavedCharts();
    this.isBeingSaved = false;
    return this;
  }

  _clearUnsavedCharts() {
    this.charts.filterBy('isNew').forEach((chart) => this.store.unloadRecord(chart));
  }

  get availableCharts() {
    if (this.app.canSeeR2Beta) {
      let availableCharts = this.charts?.reject((chart) => chart.isDeleted);
      availableCharts = this._sortGrid(availableCharts);
      return availableCharts;
    }
    return this.charts;
  }

  _sortGrid(availableCharts) {
    return availableCharts.sort((a, b) => {
      if (a.gridPositionX === null || a.gridPositionY === null) {
        return 1;
      }
      if (b.gridPositionX === null || b.gridPositionY === null) {
        return -1;
      }

      if (a.gridPositionY < b.gridPositionY) {
        return -1;
      }
      if (a.gridPositionY > b.gridPositionY) {
        return 1;
      }

      if (a.gridPositionX < b.gridPositionX) {
        return -1;
      }
      if (a.gridPositionX > b.gridPositionX) {
        return 1;
      }

      return 0;
    });
  }

  hasSameDateRange(dateRange) {
    if (dateRange.selection === 'custom') {
      return isEqual(
        { start: this.dateRange.start, end: this.dateRange.end },
        { start: dateRange.start, end: dateRange.end },
      );
    }
    return isEqual(this.dateRange.selection, dateRange.selection);
  }

  showDateOverwritten(dateRange) {
    return isPresent(this.dateRange) && !this.hasSameDateRange(dateRange);
  }

  get haveFiltersChanged() {
    if (this.hasDirtyAttributes) {
      let dirtyAttributes = Object.keys(this.changedAttributes());
      return dirtyAttributes.includes('filters') || dirtyAttributes.includes('dateRange');
    }
    return false;
  }
}
