/* import __COLOCATED_TEMPLATE__ from './platform-specific-settings.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { type PlatformId } from './platform-selector';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

interface Args {
  messengerSettings: $TSFixMe;
  onSelectPlatform: (platform: PlatformId) => void;
  selectedPlatform: PlatformId;
}

export default class PlatformSpecificSettings extends Component<Args> {
  @service declare messengerSettingsService: MessengerSettingsService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup::MessengerSettings::PlatformSpecificSettings': typeof PlatformSpecificSettings;
    'standalone/intercom-messenger/setup/messenger-settings/platform-specific-settings': typeof PlatformSpecificSettings;
  }
}
