/* RESPONSIBLE TEAM: team-purchase-experience */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { CompanySize } from 'embercom/components/expired-subscription/expired-subscription';
import { ExpirationReason } from 'embercom/components/expired-subscription/header/header-card';

export default class ExpiredSubscription extends Route {
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare experimentOptInService: $TSFixMe;
  @service declare router: RouterService;

  async beforeModel() {
    await this.appService.app.reload();
    await this.customerService.refreshCustomer();
    await taskFor(this.experimentOptInService.registerForExperiment).perform(
      'TrialExtensionsExperiment',
    );
    if (!this.appService.app.canDisplayExpiredSubscriptionPage) {
      this.router.transitionTo('apps.app');
    }
  }

  async model() {
    return {
      expirationReason: this.expirationReason,
      adminHasBillingPermissions: this.adminHasBillingPermissions,
      companySize: this.companySize,
    };
  }

  get expirationReason() {
    return Object.values(ExpirationReason).find(this.isExpirationReason, this);
  }

  private isExpirationReason(expirationReason: ExpirationReason) {
    return this.expirationReasonCriteria[expirationReason];
  }

  get expirationReasonCriteria() {
    return {
      [ExpirationReason.FreeTrialEnded]: this.showTrialExtensionCTA,
      [ExpirationReason.SubscriptionCancelled]: this.showSubscriptionCancelledScreen,
      [ExpirationReason.NonPaymentSelfServe]: this.isNonPaymentSelfServe,
      [ExpirationReason.NonPaymentSalesLed]: this.isNonPaymentSalesLed,
      [ExpirationReason.NewWorkspace]: this.isNewWorkspace,
    };
  }

  get showTrialExtensionCTA() {
    return !!(
      this.customerService.customer.inExpiredCardlessTrial &&
      this.appService.app.teamPurchaseExperienceTrialExtensionsVariant
    );
  }

  get showSubscriptionCancelledScreen() {
    return !!(
      this.customerService.customer.subscriptionWasCancelled &&
      !this.customerService.customer.inExpiredSelfServeTrial &&
      !this.appService.app.isFrozenForNonPayment
    );
  }

  get isNonPaymentSelfServe() {
    return !!(
      this.appService.app.isFrozenForNonPayment &&
      !this.customerService.customer.subscription.isSalesforceContracted
    );
  }

  get isNonPaymentSalesLed() {
    return !!(
      this.appService.app.isFrozenForNonPayment &&
      this.customerService.customer.subscription.isSalesforceContracted
    );
  }

  get isNewWorkspace() {
    return (
      !this.customerService.customer.hasEverHadSubscription &&
      !this.customerService.customer.everInCardlessTrial
    );
  }

  get adminHasBillingPermissions() {
    return !!this.permissionsService.currentAdminCan('can_access_billing_settings');
  }

  get companySize() {
    switch (this.appService.app.companySize) {
      case '6-10':
      case '11-49':
        return CompanySize.Medium;
      case '50-199':
      case '200-999':
      case '1,000+':
        return CompanySize.Large;
      case '1-5':
        return CompanySize.Small;
      default:
        return CompanySize.Medium;
    }
  }

  get hasActiveSubscription() {
    return !!this.appService.app.hasActiveSubscription;
  }
}
