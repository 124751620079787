/* RESPONSIBLE TEAM: team-help-desk-experience */
import {
  RenderableType,
  RENDERABLE_IN_SUMMARY_LIST,
} from 'embercom/models/data/inbox/renderable-types';
import {
  deserialize as createRenderableData,
  type RenderableData,
  type RenderableDataWireFormat,
} from 'embercom/objects/inbox/renderable-data';
import UnknownPart from 'embercom/objects/inbox/renderable/unknown-part';
import type PartGroup from './conversation-stream/part-group';
import moment from 'moment-timezone';
import { EntityType } from 'embercom/models/data/entity-types';
import type Conversation from './conversation';
import { nanoid } from 'nanoid';
import { captureException } from 'embercom/lib/sentry';
import AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import UserComment from 'embercom/objects/inbox/renderable/user-comment';
import { Channel } from 'embercom/models/data/inbox/channels';

export interface RenderablePartWireFormat {
  id: number;
  renderable_type: RenderableType;
  renderable_data: RenderableDataWireFormat;
  created_at: string;
  client_assigned_uuid?: string;
  entity_id: number;
  entity_type: number;
  highlights?: string[];
}

export default class RenderablePart {
  readonly id: number;
  readonly renderableType: RenderableType;
  readonly renderableData: RenderableData;
  readonly createdAt: Date;
  readonly entityId: number;
  readonly entityType: number;
  readonly clientAssignedUuid?: string;
  readonly highlights?: string[];

  constructor(
    id: number,
    renderableType: RenderableType,
    renderableData: RenderableData,
    createdAt: Date,
    entity_id: number,
    entity_type: number,
    clientAssignedUuid?: string,
    highlights?: string[],
  ) {
    this.id = id;
    this.renderableType = renderableType;
    this.renderableData = renderableData;
    this.createdAt = createdAt;
    this.clientAssignedUuid = clientAssignedUuid;
    this.entityId = entity_id;
    this.entityType = entity_type;
    this.highlights = highlights;
  }

  get uuid(): string {
    return this.clientAssignedUuid || String(this.id);
  }

  get pending(): boolean {
    return this.id === 0;
  }

  get createdByUser(): boolean {
    return this.renderableData.creatingEntity.type === EntityType.User;
  }

  get isGroupable(): boolean {
    if (
      this.renderableData instanceof AdminComment &&
      this.renderableData.channel === Channel.Email
    ) {
      return false;
    }

    // parts that are opted out of visual grouping for design reasons
    return ![RenderableType.UserEmailComment, RenderableType.Email].includes(this.renderableType);
  }

  get isPhoneCallPart(): boolean {
    return (
      this.renderableData instanceof UserComment &&
      this.renderableData.channel === Channel.PhoneCall
    );
  }

  get isFinAnswer() {
    return this.renderableType === RenderableType.FinAnswer;
  }

  get isAiGeneratedConversationSummary() {
    return this.renderableType === RenderableType.ConversationSummary;
  }

  get isTranscriptionSummary() {
    return this.renderableType === RenderableType.TranscriptionSummary;
  }

  get renderableInSummaryList(): boolean {
    return RENDERABLE_IN_SUMMARY_LIST.some(
      (summaryListPartType) => summaryListPartType === this.renderableType,
    );
  }

  get isNewTicketEventPart(): boolean {
    return !!this.renderableData.ticketId;
  }

  createdBySameEntity(otherPart: RenderablePart): boolean {
    return (
      this.renderableData.creatingEntity.type === otherPart.renderableData.creatingEntity.type &&
      this.renderableData.creatingEntity.id === otherPart.renderableData.creatingEntity.id
    );
  }

  createdWithinSameDay(partGroup: PartGroup): boolean {
    let currentTimezone = moment.tz.guess();

    let firstPart = partGroup.allParts[0];

    let currentPartTime = moment(this.createdAt).tz(currentTimezone).startOf('day');
    let firstPartTime = moment(firstPart.createdAt).tz(currentTimezone).startOf('day');

    return currentPartTime.diff(firstPartTime, 'days') === 0;
  }

  createdWithin1Minute(otherPart: RenderablePart): boolean {
    let currentTimezone = moment.tz.guess();

    let currentPartTime = moment(this.createdAt).tz(currentTimezone);
    let otherPartTime = moment(otherPart.createdAt).tz(currentTimezone);

    return currentPartTime.diff(otherPartTime, 'minute') === 0;
  }

  createdWithinSimilarTimeFrame(partGroup: PartGroup, now = moment()): boolean {
    let firstPart = partGroup.allParts[0];

    let currentPartTime = moment(this.createdAt);
    let firstPartTime = moment(firstPart.createdAt);

    // If the current part was created within the last 5 minutes, group them with parts
    // created within 5 minutes. Otherwise do not group.
    if (now.diff(currentPartTime, 'minutes') < 5) {
      if (Math.abs(now.diff(currentPartTime, 'minutes') - now.diff(firstPartTime, 'minutes')) < 5) {
        return true;
      } else {
        return false;
      }
    }

    // If the part was created today, then group with parts created within an hour of the part.
    // Otherwise do not group.
    if (now.diff(currentPartTime, 'days') === 0) {
      if (
        Math.abs(now.diff(currentPartTime, 'minutes') - now.diff(firstPartTime, 'minutes')) < 60
      ) {
        return true;
      } else {
        return false;
      }
    }

    // If the parts were not created today, but they were created on the same day in this month then group them
    if (
      now.diff(currentPartTime, 'days') === now.diff(firstPartTime, 'days') &&
      now.diff(currentPartTime, 'months') === now.diff(firstPartTime, 'months') &&
      now.diff(currentPartTime, 'months') === 0
    ) {
      return true;
    }

    // If the parts were not created this month, but were created on the same previous month then group them
    if (
      now.diff(currentPartTime, 'months') === now.diff(firstPartTime, 'months') &&
      now.diff(currentPartTime, 'months') > 0
    ) {
      return true;
    }

    // Otherwise do not group the parts
    return false;
  }

  generatePermalinkId(conversationId: Conversation['id']): string {
    let partType = this.entityType !== EntityType.ConversationPart ? 'initial-part' : 'comment';
    return `${partType}-${conversationId}-${this.entityId}`;
  }

  static deserialize(json: RenderablePartWireFormat): RenderablePart {
    let renderableData;

    try {
      renderableData = createRenderableData(json.renderable_type, json.renderable_data);
    } catch (e) {
      captureException(e, {
        fingerprint: ['renderable-part-deserialize'],
        extra: {
          part_id: json.id,
          part_type: json.renderable_type,
        },
      });
      console.error(e);
      console.error('Could not deserialize', json.renderable_data);
      renderableData = UnknownPart.deserialize(json.renderable_data);
    }

    return new RenderablePart(
      json.id,
      json.renderable_type,
      renderableData,
      new Date(json.created_at),
      json.entity_id,
      json.entity_type,
      json.client_assigned_uuid,
      json.highlights,
    );
  }
}

export function createRenderablePart(
  content: RenderableData,
  createdAt = new Date(),
  uuid = nanoid(),
): RenderablePart {
  return new RenderablePart(0, content.renderableType, content, createdAt, 0, 0, uuid);
}
