/* import __COLOCATED_TEMPLATE__ from './view-mode-options.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ReportingStandalone from 'embercom/services/reporting-standalone';
import { type TaskGenerator } from 'ember-concurrency';
import type Report from 'embercom/models/reporting/custom/report';

interface Args {
  report: Report;
  duplicateReport: () => TaskGenerator<void>;
  deleteReport: () => TaskGenerator<void>;
  onClickEditReport: () => void;
  editReportDisabled: boolean;
  isStandalone: boolean;
  openShareSideDrawer: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class ReportingCustomReportViewModeOptions extends Component<Signature> {
  @service declare reportingStandalone: ReportingStandalone;

  get shouldShowReportAction() {
    if (this.args.isStandalone) {
      return this.reportingStandalone.supportsFeature('showReportAction');
    }
    return true;
  }

  get shouldShowShareOption() {
    if (this.args.isStandalone) {
      return this.reportingStandalone.supportsFeature('shareReport');
    }
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ViewModeOptions': typeof ReportingCustomReportViewModeOptions;
    'reporting/custom/report/view-mode-options': typeof ReportingCustomReportViewModeOptions;
  }
}
