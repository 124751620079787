/* import __COLOCATED_TEMPLATE__ from './facebook.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import { tracked } from '@glimmer/tracking';
import initFacebookSdk from 'embercom/lib/facebook-sdk';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import FacebookIntegration from 'embercom/lib/facebook';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import type FacebookPage from 'embercom/models/facebook-page';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    model: {
      appPackage: any;
    };
  };
}

export default class Facebook extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare fb: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: $TSFixMe;
  @service declare appstoreService: $TSFixMe;

  @tracked connectedAccounts: FacebookPage[] = [];
  @tracked isConnectedToFacebook = false;
  @tracked nextCursor = '';
  @tracked isAppPackageInstalled = false;
  @tracked showWarning = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.isAppPackageInstalled = this.args.model?.appPackage?.isInstalled || false;
    initFacebookSdk(this.fb, this.app);
    taskFor(this.retrievePagesTask).perform();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'facebook_channel_tab',
      place: 'settings_channels_social_channels',
      section: 'tab_viewed',
    });
  }

  get app() {
    return this.appService.app;
  }

  get loginScope() {
    return 'email,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_messaging,pages_show_list,business_management';
  }

  get isFirefox() {
    return UserAgentDetector.isFirefox();
  }

  get isLoading() {
    return taskFor(this.retrievePagesTask).isRunning;
  }

  get isRemovingPage() {
    return taskFor(this.unsubscribe).isRunning;
  }

  @action
  removePage(page: any) {
    taskFor(this.unsubscribe).perform(page);
  }

  @action
  addFacebookAccount() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_facebook_account_button',
      place: 'settings_channels_facebook',
      section: 'add_facebook_account',
    });
    taskFor(this.login).perform();
  }

  @action
  confirmFacebookAccount(page: any) {
    taskFor(this.subscribe).perform(page);
  }

  @action
  reAuthFacebookAccount() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'reauth_facebook_account_button',
      place: 'settings_channels_facebook',
      section: 'reauth_facebook_account',
    });
    taskFor(this.reAuth).perform();
  }

  @dropTask
  *retrievePagesTask(): TaskGenerator<void> {
    let resp = yield this.fb.getLoginStatus();
    if (resp.status === 'connected') {
      yield taskFor(this.installIntegration).perform();
      let accessToken = resp.authResponse.accessToken;
      try {
        let response = yield FacebookIntegration.fetchPages(accessToken, this.nextCursor);
        this.connectedAccounts.pushObjects(response.get('pages').toArray());
        this.nextCursor = response.get('nextCursor');
        this.isConnectedToFacebook = true;
        this.showWarning = false;
      } catch (e) {
        console.error(e);
        this.showWarning = true;
      }
    }
  }

  @dropTask
  *login(): TaskGenerator<void> {
    this._trackConnectionEvents();
    let response = yield this.fb.login(this.loginScope);
    yield taskFor(this.connectWithFacebook).perform(response);
  }

  @dropTask
  *reAuth(): TaskGenerator<void> {
    this._trackConnectionEvents();
    let response = yield this.fb.login(this.loginScope, { auth_type: 'rerequest' });
    yield taskFor(this.connectWithFacebook).perform(response);
  }

  @dropTask
  *connectWithFacebook(response: any): TaskGenerator<void> {
    if (response.status === 'connected') {
      this.connectedAccounts = [];
      yield taskFor(this.retrievePagesTask).perform();
    }
  }

  @dropTask
  *subscribe(page: any): TaskGenerator<void> {
    let appId = this.app.id;
    let accessToken = page.get('accessToken');
    let pageId = page.get('id');
    try {
      let response = yield FacebookIntegration.subscribe(appId, accessToken, pageId);
      if (response.success === true) {
        this.connectedAccounts = this.connectedAccounts.map((account) => {
          if (account.get('id') === pageId) {
            account.set('status', 'OK');
            account.set('added', true);
          }
          return account;
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('appstore.settings.facebook.main.notification.page-add.success'),
        );
        this.intercomEventService.trackAnalyticsEvent({
          action: 'added',
          object: 'facebook_page',
        });
      } else {
        this.notificationsService.notifyError(
          this.intl.t('appstore.settings.facebook.main.notification.page-add.error'),
        );
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.facebook.main.notification.page-add.error-permissions'),
      );
    }
  }

  @dropTask
  *installIntegration(): TaskGenerator<void> {
    if (this.isAppPackageInstalled) {
      return;
    }
    let params = {
      admin_id: this.appService.app.currentAdmin.id,
      app_id: this.appService.app.id,
      app_package_code: 'facebook',
    };

    let response = yield post('/ember/appstore/app_packages/install', params);
    if (response.ok) {
      this.isAppPackageInstalled = true;
    }
  }

  @dropTask
  *unsubscribe(page: any): TaskGenerator<void> {
    let appId = this.app.id;
    let accessToken = page.get('accessToken');
    let pageId = page.get('id');
    try {
      let response = yield FacebookIntegration.unsubscribe(appId, accessToken, pageId);
      if (response.success === true) {
        this.connectedAccounts = this.connectedAccounts.map((account) => {
          if (account.get('id') === pageId) {
            account.set('status', 'NOT_CONNECTED');
            account.set('added', false);
          }
          return account;
        });
        this.intercomEventService.trackAnalyticsEvent({
          action: 'removed',
          object: 'facebook_page',
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('appstore.settings.facebook.main.notification.page-remove.success'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('appstore.settings.facebook.main.notification.page-remove.error'),
        );
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.facebook.main.notification.page-remove.error'),
      );
    }
  }

  @action
  _trackConnectionEvents() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'connect',
      object: 'facebook',
      app_package_code: 'facebook',
    });
    let { email_request } = this.appstoreService.getRedirectQueryParams();
    if (email_request) {
      let emailRequestAction = `${email_request}_connect`;
      this.intercomEventService.trackAnalyticsEvent({
        action: emailRequestAction,
        object: 'facebook',
        app_package_code: 'facebook',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Facebook': typeof Facebook;
  }
}
