/* RESPONSIBLE TEAM: team-reporting */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { copy } from 'ember-copy';

export default class ReportSerializer extends EmbercomBaseSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    charts: {
      deserialize: 'records',
      serialize: 'ids',
    },
  };

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    json.report = copy(json, true);
    json.charts = snapshot.record.availableCharts.map((chart) => chart.serialize());

    return json;
  }
}
