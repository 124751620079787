/* import __COLOCATED_TEMPLATE__ from './developer-guidelines.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { needsAcceptance, acceptGuidelines } from 'embercom/lib/developer-guidelines';

export default Component.extend({
  notificationsService: service(),
  appService: service(),
  router: service(),
  showModal: false,
  app: readOnly('appService.app'),
  appName: readOnly('app.name'),

  init() {
    this._super(...arguments);
    this.needsAcceptanceTask.perform(task);
  },

  needsAcceptanceTask: task(function* () {
    try {
      yield needsAcceptance(this.get('app.id'));
    } catch (e) {
      if (e.jqXHR.status === 404) {
        this.set('showModal', true);
      }
    }
  }),

  acceptTermsTask: task(function* () {
    yield acceptGuidelines(this.get('app.id'));
    this.set('showModal', false);
  }),

  rejectGuidelines: action(function () {
    this.router.transitionTo('apps.app');
    this.set('showModal', false); // without this line the modal flickers when transitioning
    this.notificationsService.notifyError(
      'You need to accept the Developer Guidelines to continue',
    );
  }),
});
