/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import UserSummary, { type UserSummaryWireFormat } from '../user-summary';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';
import { type WorkflowObject } from '../interfaces/workflow';

interface ConversationAttributeSummaryWireFormat {
  id: number;
  name: string;
  is_built_in: boolean;
}

export class ConversationAttributeSummary {
  readonly id: number | string;
  readonly name: string;
  readonly isBuiltIn: boolean;

  constructor(id: number | string, name: string, isBuiltIn: boolean) {
    this.id = id;
    this.name = name;
    this.isBuiltIn = isBuiltIn;
  }

  static deserialize(json: ConversationAttributeSummaryWireFormat): ConversationAttributeSummary {
    return new ConversationAttributeSummary(json.id, json.name, json.is_built_in);
  }
}

interface ConversationAttributeUpdatedWireFormat {
  renderable_type: RenderableType;
  entity_type: EntityType;
  entity: UserSummaryWireFormat | AdminSummaryWireFormat | WorkflowObject;
  attribute_summary: ConversationAttributeSummaryWireFormat;
  value: any | undefined;
}

export default class ConversationAttributeUpdated implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.ConversationAttributeUpdated;
  readonly attributeSummary: ConversationAttributeSummary;
  readonly value: any | undefined;
  readonly entity: UserSummary | AdminSummary | WorkflowObject | undefined;
  readonly entityType: EntityType;

  get creatingEntity() {
    return { type: this.entityType, id: this.entity?.id || -1, object: this.entity };
  }

  constructor(
    entity: UserSummary | AdminSummary | WorkflowObject | undefined,
    entityType: EntityType,
    attributeSummary: ConversationAttributeSummary,
    value: any,
  ) {
    this.entity = entity;
    this.entityType = entityType;
    this.attributeSummary = attributeSummary;
    this.value = value;
  }

  static deserialize(json: ConversationAttributeUpdatedWireFormat): ConversationAttributeUpdated {
    let entity = undefined;
    switch (json.entity_type) {
      case EntityType.Admin: {
        entity = AdminSummary.deserialize(json.entity as AdminSummaryWireFormat);
        break;
      }
      case EntityType.User: {
        entity = UserSummary.deserialize(json.entity as UserSummary);
        break;
      }
      case EntityType.Workflow: {
        entity = json.entity as WorkflowObject;
        break;
      }
      case EntityType.Bot: {
        entity = AdminSummary.deserialize(json.entity as AdminSummaryWireFormat);
        break;
      }
    }

    return new ConversationAttributeUpdated(
      entity,
      json.entity_type,
      ConversationAttributeSummary.deserialize(json.attribute_summary),
      json.value,
    );
  }
}
