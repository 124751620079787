/* import __COLOCATED_TEMPLATE__ from './api-connection.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { type PulseAccordion } from 'glint/pulse';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneZendeskApiConnection extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::ApiConnection': typeof StandaloneZendeskApiConnection;
    'standalone/zendesk/setup/api-connection': typeof StandaloneZendeskApiConnection;
  }
}
