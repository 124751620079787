/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import { Promise as EmberPromise, hash } from 'rsvp';

function getIntegrationRecord(code, route) {
  return new EmberPromise((resolve) => {
    route.store.query('integration', { code }).then((records) => {
      resolve(records.get('firstObject'));
    });
  });
}

const MODELS = {
  github(route) {
    return hash({
      webhooks: route.store.findAll('github-webhook'),
      integration: getIntegrationRecord('github', route),
      githubIntegration: route.store.queryRecord('github-integration', {}),
    });
  },
  slack(route) {
    return hash({
      integration: getIntegrationRecord('slack', route),
      slackIntegration: route.store.queryRecord('slack-legacy-integration', {
        dest: 'slack',
        retrieve_subscriptions: true,
      }),
    });
  },
  default(code, route) {
    return hash({
      integration: getIntegrationRecord(code, route),
    });
  },
};

export default function fetchIntegrationModels(code, route) {
  if (MODELS[code]) {
    return MODELS[code](route);
  } else {
    return MODELS['default'](code, route);
  }
}
