/* import __COLOCATED_TEMPLATE__ from './add-content.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { EntityType } from 'embercom/models/data/entity-types';
import type ContentImportService from 'embercom/services/content-import-service';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';

interface Args {
  showModal: (syncSourceType: string, entityType: EntityType) => Promise<void>;
}

export default class AddContent extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare contentImportService: ContentImportService;
  @service declare conversationExtractionService: ConversationExtractionService;

  get maxiumuWebsiteSources() {
    return this.appService.app.canUseFeature('psg-increased-import-source-limit') ? 50 : 10;
  }

  get finConversationContentEnabled() {
    return this.contentImportService.finConversationContentSettings?.enabled;
  }

  get conversationExtractionEnabled() {
    return this.conversationExtractionSettings?.enabled;
  }

  get canUseConversationExtractionExperiment() {
    return this.appService.app.canUseConversationExtractionExperiment;
  }

  get conversationExtractionSettings() {
    return this.conversationExtractionService.conversationExtractionSettings;
  }

  @action
  createArticle() {
    this.knowledgeHubService.createContent(EntityType.ArticleContent, {}, false, false);
    this.trackAnalyticsEvent('create', 'public-article');
  }

  @action
  async syncWebsite() {
    await this.args.showModal('external-content', EntityType.ExternalContent);
    this.trackAnalyticsEvent('create', 'website');
  }

  @action
  createSnippet() {
    this.knowledgeHubService.createContent(EntityType.ContentSnippet, {}, false, false);
    this.trackAnalyticsEvent('create', 'snippet');
  }

  @action
  async uploadPdf() {
    await this.args.showModal('file-upload', EntityType.FileSourceContent);
    this.trackAnalyticsEvent('create', 'pdf');
  }

  @action
  async syncZendesk() {
    await this.args.showModal('zendesk-sync', EntityType.ArticleContent);
    this.trackAnalyticsEvent('create', 'zendesk-article');
  }

  @action
  async useConversationExtraction() {
    // awaiting here to check permissions
    await this.args.showModal('conversation-extraction', EntityType.ContentSnippet);
    this.trackAnalyticsEvent('create', 'conversation-extraction');
  }

  @action
  async useConversation() {
    // awaiting here to check permissions
    await this.args.showModal('past-conversation', EntityType.ConversationExcerpt);
    this.trackAnalyticsEvent('create', 'inbox-conversations');
  }

  @action
  createCustomAnswer() {
    this.knowledgeHubService.createContent(EntityType.Answer, {});
    this.trackAnalyticsEvent('create', 'custom_answer');
  }

  get isReachedMaximumWebsiteSources() {
    let count =
      this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.sync_sources.length || 0;
    return count >= this.maxiumuWebsiteSources;
  }

  private trackAnalyticsEvent(action: string, object?: string) {
    this.intercomEventService.trackAnalyticsEvent({
      tab: 'content',
      action,
      object,
      route: 'setup',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::AddContent': typeof AddContent;
  }
}
