/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import { put } from 'embercom/lib/ajax';
import cached from 'embercom/lib/cached-decorator';
import User, { type UserAttributes } from 'embercom/objects/inbox/user';
import type Session from 'embercom/services/session';
import { request } from 'embercom/lib/inbox/requests';
import Tagging, { type TaggingWireFormat } from 'embercom/objects/inbox/tagging';

export default class UserApi extends Service {
  @service declare session: Session;

  @cached({ max: 100, ttl: 5000 })
  async fetchUser(userId: string, init: RequestInit = {}, conversationId?: number) {
    let url = `/ember/users/${userId}?app_id=${this.session.workspace.id}`;
    if (conversationId && this.session.workspace.isFeatureEnabled('consensys-email-masking')) {
      url += `&conversation_id=${conversationId}`;
    }

    let response = await request(url, init);
    let json: { users: UserAttributes[] } = await response.json();

    if (!json.users[0]) {
      return;
    }

    return new User(json.users[0]);
  }

  async addToCompany(userId: string, remoteCompanyId: string): Promise<UserAttributes> {
    let {
      users: [user],
    } = (await put('/ember/users/add_to_company.json', {
      app_id: this.session.workspace.id,
      id: userId,
      remote_company_id: remoteCompanyId,
    })) as { users: UserAttributes[] };

    return user;
  }

  async removeFromCompany(userId: string, companyId: string): Promise<UserAttributes> {
    let {
      users: [user],
    } = (await put('/ember/users/remove_from_company.json', {
      app_id: this.session.workspace.id,
      id: userId,
      qualification_company_id: companyId,
    })) as { users: UserAttributes[] };

    return user;
  }

  async manageUserSubscription(options: {
    id: string;
    unsubscribed_from_emails: boolean;
  }): Promise<UserAttributes> {
    let {
      users: [user],
    } = (await put(
      `/ember/users/update_unsubscribed_from_emails.json?app_id=${this.session.workspace.id}`,
      options,
    )) as { users: UserAttributes[] };

    return user;
  }

  async fetchUserTags(userId: string, init: RequestInit = {}) {
    let response = await request(
      `/ember/inbox/users/${userId}/tags?app_id=${this.session.workspace.id}`,
      init,
    );
    let responseJson = (await response.json()).tags as TaggingWireFormat[];

    return responseJson.map(Tagging.deserialize);
  }
}

declare module '@ember/service' {
  interface Registry {
    userApi: UserApi;
    'user-api': UserApi;
  }
}
