/* import __COLOCATED_TEMPLATE__ from './primary-nav.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
// eslint-disable-next-line @intercom/intercom/max-file-length
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { and, not, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { rejectBy } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import { addEventListener, debounceTask, runTask } from 'ember-lifeline';
import ENV from 'embercom/config/environment';
import { get, post } from 'embercom/lib/ajax';
import showUnderConstruction from 'embercom/lib/show-under-construction';
import { isOnMigratedRoute } from 'embercom/lib/settings/settings-redirect-map';
import routeRegexes from 'embercom/lib/route-regexes';

export default Component.extend({
  router: service(),
  store: service(),
  session: service(),
  onboardingHomeService: service(),
  guideLibraryService: service(),
  intl: service(),
  cardlessTrialService: service(),
  greatGuidanceService: service(),
  navbarCollapsingService: service(),
  classNames: ['flex-none', 'flex', 'flex-col', 'left-nav'],
  classNameBindings: ['showUnderConstructionBanner:o__under-construction'],
  app: null,
  admin: null,
  showModal: null,
  openModal: null,
  navItemInFocus: undefined,

  init() {
    this._super(...arguments);
    if (this.showSimplifiedGuideLibraryNav) {
      return;
    }
    addEventListener(this, window, 'resize', this.debounceResizeHandler);
    runTask(this, this.updateLeftNavBottomContainerHeight, ENV.APP._1000MS);
    runTask(this, this.updateLeftNavBottomContainerHeight, ENV.APP._3000MS);
  },

  didInsertElement() {
    this._super(...arguments);
    if (this.showSimplifiedGuideLibraryNav) {
      return;
    }
    this.updateLeftNavBottomContainerHeight();
    this.fetchUnreadProductChangesCount.perform();
  },

  updateLeftNavBottomContainerHeight() {
    if (this.isDestroying) {
      return;
    }
    let LEFT_NAV_BOTTOM_CONTAINER_SELECTOR = '[data-left-nav-bottom-container]';
    let LEFT_NAV_OVERFLOW_OPENER_SELECTOR = '[data-left-nav-overflow-opener]';
    let container = this.element.querySelector(LEFT_NAV_BOTTOM_CONTAINER_SELECTOR);
    let offset = 0;
    if (this.hasOverflowBottomNavEntries) {
      let opener = this.element.querySelector(LEFT_NAV_OVERFLOW_OPENER_SELECTOR);
      offset = opener.clientHeight;
    }
    this.set('leftNavBottomContainerHeight', container.clientHeight + offset);
  },
  debounceResizeHandler() {
    debounceTask(this, 'updateLeftNavBottomContainerHeight', ENV.APP._100MS);
  },

  products: rejectBy('app.products', 'addon', true),
  messagesIsActive: readOnly('app.messagesIsActive'),
  articlesIsActive: readOnly('app.articlesIsActive'),
  inboxIsActive: readOnly('app.inboxIsActive'),
  hasNoActiveSubscription: not('app.hasActiveSubscription'),
  showFreeBanner: and('hasNoActiveSubscription', 'app.canUseFree'),
  canUseMigratedAppStore: readOnly('app.canUseMigratedAppStore'),

  get platformPageName() {
    return this.intl.t('components.primary-nav.contacts');
  },

  get isChannelSwitcherActive() {
    return this.app.canUseInboundPhoneCall;
  },

  get currentRouteName() {
    return this.router?.currentRouteName;
  },

  currentNavEntryInFocus: computed('navItemInFocus', function () {
    return this.navItemInFocus === undefined;
  }),
  showUnderConstructionBanner: computed(
    'router',
    'app.{isTestApp,isDeveloperWorkspace}',
    function () {
      return showUnderConstruction(this.app, this.router);
    },
  ),
  showUnseenLevelBadge: readOnly('onboardingHomeService.showUnseenLevelBadge'),
  showUnseenLevelBadgeGuideLibrary: computed(
    'onboardingHomeService.foundationalLevel',
    function () {
      return this.onboardingHomeService.foundationalLevel?.state !== 'completed';
    },
  ),
  get showUnseenLevelBadgeGreatGuidance() {
    if (
      this.router.currentRouteName.match(/^apps.app.appstore/) ||
      this.router.currentRouteName.match(routeRegexes.adminProfile)
    ) {
      return true;
    }

    if (this.isOnGettingStartedRoute) {
      return false;
    }

    return this.navbarCollapsingService.collapsed;
  },
  onboardingHomeGuideIdentifier: readOnly('onboardingHomeService.guide.identifier'),
  logoAnalyticsMetadata: computed('showUnseenLevelBadge', function () {
    return {
      saw_unread_badge: this.showUnseenLevelBadge,
    };
  }),
  leftNavBottomContainerHeight: null,
  tooltipAnimationDuration: 0,
  isOnHomeRoute: computed('router.currentRouteName', function () {
    return this.router?.currentRouteName?.includes('home');
  }),
  isOnGuideLibraryRoute: computed('router.currentRouteName', function () {
    return (
      this.router?.currentRouteName?.includes('guide-library') ||
      this.router?.currentRouteName?.includes('wizard')
    );
  }),
  isOnGettingStartedRoute: computed('router.currentRouteName', function () {
    return this.router.currentRouteName.match(routeRegexes.gettingStarted);
  }),
  hasActiveCardlessTrial: computed(
    'cardlessTrialService.isInSelfServeTrial',
    'cardlessTrialService.trialHasEnded',
    function () {
      return (
        this.cardlessTrialService.isInSelfServeTrial && !this.cardlessTrialService.trialHasEnded
      );
    },
  ),
  get automationEntry() {
    return {
      id: '7',
      productName: this.intl.t('components.primary-nav.automation'),
      route: 'apps.app.automation',
      activeRoute: 'apps.app.automation',
      svgId: 'trigger',
      className: 'o__operator',
      active: true,
    };
  },
  get platformEntry() {
    let platformRoutes =
      'apps.app.users apps.app.companies apps.app.feed apps.app.platform apps.app.accounts';
    return {
      id: 'platform',
      productName: this.platformPageName,
      route: 'apps.app.users',
      activeRoute: platformRoutes,
      active: true,
      svgId: 'multiple-people',
      className: 'o__platform',
    };
  },
  get helpCenterEntry() {
    return {
      svgId: 'article',
      productName: this.intl.t('components.primary-nav.help-center'),
      route: 'apps.app.articles',
      activeRoute: 'apps.app.articles',
      id: 'articles',
      active: this.articlesIsActive,
      className: 'o__help-center',
    };
  },
  get knowledgeBaseEntry() {
    return {
      svgId: 'campaign',
      productName: this.intl.t('components.primary-nav.knowledge-base'),
      route: 'apps.app.knowledge-hub',
      activeRoute: 'apps.app.knowledge-hub',
      active: true,
      className: 'o__knowledge-base',
    };
  },

  get channelsEntry() {
    return [];
  },

  get isInSettingsRoute() {
    return (
      this.router.currentRouteName.match(/^apps\.app\.new-settings\.*/) ||
      (this.router.currentRouteName.match(/^apps\.app\.settings\.*/) &&
        isOnMigratedRoute(this.router.currentRouteName))
    );
  },

  get reportsEntry() {
    return {
      id: 'reports',
      svgId: 'bar-charts',
      productName: this.intl.t('components.primary-nav.reports'),
      route: 'apps.app.reports',
      activeRoute: 'apps.app.reports',
      className: 'o__reporting',
      analyticsEventObjectName: 'reporting_side_bar_link',
      active: true,
    };
  },

  get hasAccessToProductIaNav() {
    return this.app.hasOptedInForProductIa || this.app.currentAdmin?.product_ia_opt_in;
  },

  get showExpiredSubscriptionNavBar() {
    return !!(this.app.canUseExpiredSubscriptionPage && !this.app.hasActiveSubscription);
  },

  topNavEntries: computed('intl.locale', 'app.features.[]', function () {
    if (this.showExpiredSubscriptionNavBar) {
      return [];
    }

    let inboxRoutes = 'apps.app.inbox apps.app.welcome-helpdesk';

    let inboxEntry = {
      svgId: 'inbox',
      productName: this.intl.t('components.primary-nav.help-desk'),
      route: 'apps.app.inbox',
      activeRoute: inboxRoutes,
      id: '1',
      active: this.inboxIsActive,
    };

    let finAIAgentEntry = {
      id: 'fin-ai-agent',
      svgId: 'fin',
      productName: this.intl.t('components.primary-nav.ai-chatbot'),
      route: 'apps.app.fin-ai-agent',
      activeRoute: 'apps.app.fin-ai-agent',
      active: true,
    };

    let navEntries = [
      inboxEntry,
      finAIAgentEntry,
      {
        svgId: 'outbound-filled',
        productName: this.intl.t('components.primary-nav.outbound'),
        route: 'apps.app.outbound',
        activeRoute: 'apps.app.outbound',
        id: '2',
        active: this.messagesIsActive,
      },
    ];

    return navEntries;
  }),

  currentlyActiveBottomNavEntryIndex: computed(
    'visibleBottomNavEntries',
    'router.currentRouteName',
    function () {
      let navEntryIndex = this.visibleBottomNavEntries.findIndex((navEntry) => {
        return navEntry?.activeRoute
          ?.split(' ')
          .any((route) => this.router?.currentRouteName?.includes(route));
      });
      return navEntryIndex === -1 ? -999 : navEntryIndex;
    },
  ),

  bottomNavEntries: computed(
    'intl.locale',
    'app.features.[]',
    'app.canAccessIntershop',
    'router.currentRouteName',
    function () {
      if (this.showExpiredSubscriptionNavBar) {
        return [];
      }

      let primaryNavSvgs = {
        channels: 'nav-primary-messenger',
        messenger: 'nav-primary-messenger',
        appStore: 'nav-primary-app-store',
        intershop: 'nav-primary-intershop',
      };

      let currentRoute = this.get('router.currentRouteName');

      Object.keys(primaryNavSvgs).forEach((product) => {
        if (currentRoute?.includes(product.toLowerCase())) {
          primaryNavSvgs[product] += '-active';
        }
      });

      let intershopEntry = {
        productName: this.intl.t('components.primary-nav.discover-more'),
        route: 'apps.app.intershop',
        activeRoute: 'apps.app.intershop',
        className: 'o__intershop',
        analyticsEventObjectName: 'intershop_side_bar_link',
        svgId: 'shopping-cart',
      };

      let appStoreEntry = {
        productName: this.intl.t('components.primary-nav.app-store'),
        route: 'apps.app.appstore',
        activeRoute: 'apps.app.appstore',
        className: 'o__appstore',
        svgId: 'app-store',
        analyticsEventObjectName: 'app_store_side_bar_link',
      };

      let navEntries = [
        ...[this.automationEntry],
        ...[this.knowledgeBaseEntry],
        ...[this.reportsEntry, this.platformEntry],
        ...this.channelsEntry,
        ...[this.helpCenterEntry],
        ...(this.app.canAccessIntershop ? [intershopEntry] : []),
        ...(!this.canUseMigratedAppStore ? [appStoreEntry] : []),
      ];

      return navEntries;
    },
  ),

  currentlyActiveTopNavEntryIndex: computed(
    'router.currentRouteName',
    'topNavEntries',
    function () {
      let productIndex = this.topNavEntries.findIndex((product) => {
        return product?.activeRoute
          ?.split(' ')
          .any((route) => this.router?.currentRouteName?.includes(route));
      });
      return productIndex === -1 ? -999 : productIndex;
    },
  ),

  selectNavItemFocused: action(function (focusItemId) {
    this.set('navItemInFocus', focusItemId);
  }),

  refreshGuide: action(function () {
    this.store.findRecord('onboarding/home/guide', this.app?.id);
  }),

  availableVisibleBottomNavEntrySlots: computed(
    'bottomNavEntries.[]',
    'leftNavBottomContainerHeight',
    function () {
      let BOTTOM_NAV_ENTRY_HEIGHT_PX = 44;
      let availableVisibleBottomNavEntrySlots = Math.floor(
        this.leftNavBottomContainerHeight / BOTTOM_NAV_ENTRY_HEIGHT_PX,
      );

      // if there isn't enough room for all the icons, then
      // remove one from the available slots to take
      // into account the overflow opener
      if (availableVisibleBottomNavEntrySlots < this.bottomNavEntries.length) {
        availableVisibleBottomNavEntrySlots -= 1;
      }
      return availableVisibleBottomNavEntrySlots;
    },
  ),
  hasOverflowBottomNavEntries: computed(
    'bottomNavEntries.[]',
    'availableVisibleBottomNavEntrySlots',
    function () {
      return (
        this.bottomNavEntries.length > this.availableVisibleBottomNavEntrySlots &&
        !this.showExpiredSubscriptionNavBar
      );
    },
  ),
  visibleBottomNavEntries: computed(
    'bottomNavEntries.[]',
    'availableVisibleBottomNavEntrySlots',
    'hasOverflowBottomNavEntries',
    function () {
      if (this.hasOverflowBottomNavEntries) {
        return this.bottomNavEntries.slice(0, this.availableVisibleBottomNavEntrySlots);
      } else {
        return this.bottomNavEntries;
      }
    },
  ),
  overflowBottomNavEntries: computed(
    'bottomNavEntries.[]',
    'availableVisibleBottomNavEntrySlots',
    'hasOverflowBottomNavEntries',
    function () {
      if (!this.hasOverflowBottomNavEntries) {
        return [];
      } else {
        return this.bottomNavEntries.slice(this.availableVisibleBottomNavEntrySlots);
      }
    },
  ),
  overflowMenuHasUnread: computed('overflowBottomNavEntries.[]', function () {
    return this.overflowBottomNavEntries.some((entry) => entry.isUnread);
  }),

  fetchUnreadProductChangesCount: task(function* () {
    let response = yield get('/ember/public/changes/get_admin_unread_count');
    this.set('showUnreadProductChangesIndicator', response.count > 0);
  }).drop(),

  updateAdminLastViewedChanges: task(function* () {
    yield post('/ember/public/changes/update_admin_last_viewed_changes');
  }).restartable(),
  actions: {
    routeToOnboardingHome() {
      this.store.findRecord('onboarding/home/guide', this.app?.id);
      return this.router.transitionTo('apps.app.home', this.session.workspace.id);
    },
    routeToGuideLibrary() {
      this.store.findRecord('onboarding/home/guide', this.app?.id);
      return this.router.transitionTo('apps.app.guide-library', this.session.workspace.id);
    },
    routeToGettingStarted() {
      this.store.findRecord('onboarding/home/guide', this.app?.id);
      return this.router.transitionTo('apps.app.getting-started', this.session.workspace.id);
    },
    productChangesClicked() {
      this.set('showUnreadProductChangesIndicator', false);
      Intercom('showSpace', 'news');
    },
  },
});
