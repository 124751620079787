/* import __COLOCATED_TEMPLATE__ from './instagram.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import { post } from 'embercom/lib/ajax';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    model: {
      appPackage: any;
      integrations: any[];
      settings: any;
      teamAvailability: any;
    };
  };
}

export default class Instagram extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare fb: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare store: $TSFixMe;
  @service declare intl: $TSFixMe;

  @tracked pagesToInstagramAccounts: any = {};
  @tracked accessToken: string | null = null;
  @tracked removingIntegration: any = null;
  @tracked showModal = false;
  @tracked isAppPackageInstalled = false;
  @tracked showNewAccounts = false;
  @tracked private _instagramMigratedAccounts: any[] = [];
  @tracked isRepliesToStoriesEnabled = this.args.model?.settings?.repliesToStories || false;
  @tracked isMentionFromOthersEnabled = this.args.model?.settings?.mentionsInStories || false;
  @tracked isShareReplyTimeEnabled = this.args.model?.settings?.setExpectationsBot || false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.initFacebook();
    this.isAppPackageInstalled = this.args.model?.appPackage?.isInstalled || false;
    this.instagramMigratedAccounts = this.args.model?.integrations;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'instagram_channel_tab',
      place: 'settings_channels_social_channels',
      section: 'tab_viewed',
    });
  }

  get app() {
    return this.appService.app;
  }

  get availabilityEnabled() {
    return this.args.model?.teamAvailability?.enabledForLeads;
  }

  get isDisabled() {
    return this.args.model?.integrations?.length < 1;
  }

  get instagramMigratedAccounts() {
    return this._instagramMigratedAccounts;
  }

  set instagramMigratedAccounts(integrations: any[]) {
    this._instagramMigratedAccounts = integrations?.map?.((integration: any) => ({
      pageId: integration.pageId,
      instagramAccount: {
        username: integration.instagramUsername,
        id: integration.instagramId,
      },
      alreadyConnected: true,
    }));
  }

  get instagramNewAccounts() {
    return Object.entries(this.pagesToInstagramAccounts).map(([pageId, instagramAccount]: any) => {
      let alreadyConnected = this.args.model?.integrations?.some?.(
        (integration: any) => integration.pageId === pageId,
      );
      return {
        pageId,
        instagramAccount,
        alreadyConnected,
      };
    });
  }

  get isConfirmingAccount() {
    return taskFor(this._confirmInstagramAccount).isRunning;
  }

  get instagramAccounts() {
    return this.showNewAccounts ? this.instagramNewAccounts : this.instagramMigratedAccounts;
  }

  @dropTask
  *saveSettings() {
    try {
      yield this.args.model.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.instagram.custom-settings.changes-save-success'),
      );
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('appstore.settings.instagram.custom-settings.changes-save-error'),
      });
    }
  }

  @action
  toggler(toggleType: string) {
    switch (toggleType) {
      case 'replies-to-stories':
        this.trackEvent('replies_to_your_stories_toggle');
        this.isRepliesToStoriesEnabled = !this.isRepliesToStoriesEnabled;
        this.args.model.settings.set('repliesToStories', this.isRepliesToStoriesEnabled);
        taskFor(this.saveSettings).perform();
        break;
      case 'mention-from-others':
        this.trackEvent('mentions_to_your_stories_toggle');
        this.isMentionFromOthersEnabled = !this.isMentionFromOthersEnabled;
        this.args.model.settings.set('mentionsInStories', this.isMentionFromOthersEnabled);
        taskFor(this.saveSettings).perform();
        break;
      case 'share-reply-time':
        this.trackEvent('share_reply_times_toggle');
        this.isShareReplyTimeEnabled = !this.isShareReplyTimeEnabled;
        this.args.model.settings.set('setExpectationsBot', this.isShareReplyTimeEnabled);
        taskFor(this.saveSettings).perform();
        break;
      default:
        break;
    }
  }

  @dropTask
  *removeAccount(): TaskGenerator<void> {
    yield this.removingIntegration.destroyRecord();
    this.removingIntegration = null;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'instagram_integration',
      current_tab: 'settings',
      app_package_code: 'instagram-pv4',
    });
    this.showNewAccounts = false;
    this.instagramMigratedAccounts = yield this.store.findAll('instagram/integration', {
      reload: true,
    }) as unknown as any[];
  }

  @dropTask
  *installIntegration(): TaskGenerator<void> {
    if (this.isAppPackageInstalled) {
      return;
    }
    let params = {
      admin_id: this.appService.app.currentAdmin.id,
      app_id: this.appService.app.id,
      app_package_code: 'instagram-pv4',
    };

    let response = yield post('/ember/appstore/app_packages/install', params);
    if (response.ok) {
      this.isAppPackageInstalled = true;
    }
  }

  @action
  showRemoveModal(instagramIntegration: any) {
    this.removingIntegration = this.args.model?.integrations?.find(
      (integration: any) => integration.pageId === instagramIntegration.pageId,
    );
    this.showModal = true;
  }

  @action
  confirmRemoveAccount() {
    taskFor(this.removeAccount).perform();
    this.showModal = false;
  }

  @action
  addInstagramAccount() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_instagram_account_button',
      place: 'settings_channels_instagram',
      section: 'add_instagram_account',
    });
    taskFor(this.startInstagramOauth).perform();
  }

  @action
  async confirmInstagramAccount(pageId: string) {
    await taskFor(this._confirmInstagramAccount).perform(pageId);
  }

  @dropTask
  *startInstagramOauth(): TaskGenerator<void> {
    this.trackEvent('connect_instagram');
    try {
      let response = yield this.fb.login(
        'instagram_basic,instagram_manage_messages,pages_manage_metadata,pages_show_list,pages_read_engagement,business_management',
      );
      yield taskFor(this.loadInstagramAccounts).perform();
      this.accessToken = response.authResponse?.accessToken;
      yield taskFor(this.installIntegration).perform();
      this.showNewAccounts = true;
    } catch (e) {
      this.errorNotification(
        e,
        this.intl.t('appstore.settings.instagram.connect-instagram.notification.auth-error'),
      );
    }
  }

  @dropTask
  *loadInstagramAccounts(): TaskGenerator<any> {
    let resp = yield this.fb.api('/me/accounts?fields=connected_instagram_account{username}');
    resp.data.forEach((facebookPage: any) => {
      if (facebookPage.connected_instagram_account) {
        this.pagesToInstagramAccounts[facebookPage.id] = {
          instagramAccountId: facebookPage.connected_instagram_account.id,
          username: facebookPage.connected_instagram_account.username,
        };
      }
    });
  }

  @dropTask
  *_confirmInstagramAccount(pageId: string): TaskGenerator<any> {
    try {
      let data = {
        app_id: this.appService.app.id,
        access_token: this.accessToken,
        page_id: pageId,
        instagram_id: this.pagesToInstagramAccounts[pageId]?.instagramAccountId,
        instagram_username: this.pagesToInstagramAccounts[pageId]?.username,
      };
      let response = yield post('/ember/instagram/integrations/initialize', data);
      this.store.push(this.store.normalize('instagram/integration', response));
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.instagram.connect-instagram.notification.connect-success'),
      );
      this.trackEvent('instagram_connected');
      this.showNewAccounts = false;
      this.instagramMigratedAccounts = yield this.store.findAll('instagram/integration', {
        reload: true,
      }) as unknown as any[];
    } catch (e) {
      this.errorNotification(
        e,
        this.intl.t('appstore.settings.instagram.connect-instagram.notification.connect-error'),
      );
    }
  }

  trackEvent(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place: 'settings_channels_instagram',
      section: 'settings',
    });
  }

  async initFacebook() {
    let initSettings = {
      appId: ENV.APP.whatsapp.appId,
      version: this.app.koala_api_version,
      xfbml: true,
    };
    await this.fb.FBInit(initSettings);
    // @ts-ignore
    window.FB.init(initSettings);
  }

  errorNotification(e: any, defaultMessage: string) {
    console.error(e);
    this.notificationsService.notifyResponseError(
      e,
      {
        default: defaultMessage,
      },
      {
        responseProvidedErrors: [403, 422],
        duration: 10000,
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Instagram': typeof Instagram;
  }
}
