/* import __COLOCATED_TEMPLATE__ from './drawer-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import {
  ContentAction,
  type LocalizedKnowledgeContent,
} from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import {
  CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES,
  KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES_FOR_CREATION,
} from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import {
  type ContentVersion,
  type OnContentUpdateCallbackFunction,
} from 'embercom/services/knowledge-hub-editor-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { EntityType } from 'embercom/models/data/entity-types';
import type InternalArticle from 'embercom/models/content-service/internal-article';
import type ArticleContent from 'embercom/models/articles/article-content';
import { next, schedule } from '@ember/runloop';
import { CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import type Folder from 'embercom/models/content-service/folder';
import { type KnowledgeHubItem } from 'embercom/models/content-service/knowledge-hub-item';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type CommonPrimaryNavBarService from 'embercom/services/common-primary-nav-bar-service';
import {
  type ContentVersionResponse,
  type ContentVersionData,
} from 'embercom/services/knowledge-hub-service';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';

export enum EditorMode {
  NEW = 'new',
  EDIT = 'edit',
  VIEW = 'view',
  REVIEW = 'review',
}

interface Signature {
  Args: {
    activeContentId?: number;
    activeContentType?: string;
    editorMode?: EditorMode;
    folderId?: string;
    collectionId?: string;
    locale?: string;
    onContentUpdate?: OnContentUpdateCallbackFunction;
  };
}

export default class DrawerEditor extends Component<Signature> {
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare commonPrimaryNavBarService: CommonPrimaryNavBarService;
  @service declare intercomConfirmService: $TSFixMe;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    taskFor(this.fetchUnchangingKnowledgeHubData).perform();

    if (this.args.onContentUpdate) {
      this.knowledgeHubEditorService.registerOnContentUpdateCallback(this.args.onContentUpdate);
    }

    this.router.on('routeWillChange', this.warnIfUnsavedChanges);
  }

  get editorMode() {
    return this.args.editorMode || EditorMode.VIEW;
  }

  get activeContent() {
    return this.knowledgeHubEditorService.activeContentModel;
  }

  get activeContentSuggestedVersion() {
    return this.knowledgeHubEditorService.activeContentVersions[0];
  }

  get activeContentLatestVersion() {
    return this.knowledgeHubEditorService.activeContentVersions.length > 1
      ? this.knowledgeHubEditorService.activeContentVersions[1]
      : undefined;
  }

  get app() {
    return this.appService.app;
  }

  get showSideDrawer(): boolean {
    // If we are editing or viewing content, we need an active content id and type
    // If we are creating new content, we need an active content type
    return (
      (!!this.args.activeContentId && !!this.args.activeContentType) ||
      (this.editorMode === EditorMode.NEW && !!this.args.activeContentType)
    );
  }

  get isPrimaryNavBarPinned(): boolean {
    return this.commonPrimaryNavBarService.pinned;
  }

  get isFullScreen(): boolean {
    return this.knowledgeHubDrawerEditorService.isFullScreen;
  }

  @task({ drop: true })
  *fetchUnchangingKnowledgeHubData() {
    // This function should only be used to fetch data that doesn't change after it's loaded
    // For example, This won't be re-called on transitions such as when the active content changes.
    // It should not be used to fetch data that relies on the active content id, type or editor mode. Use AsyncData for that.
    yield Promise.all([
      this.knowledgeHubService.fetchAvailableLocales(),
      this.knowledgeHubService.fetchFoldersOnce(),
      this.knowledgeHubService.fetchArticleCollections(),
    ]);
  }

  get loadingUnchangingKnowledgeHubData(): boolean {
    return taskFor(this.fetchUnchangingKnowledgeHubData).isRunning;
  }

  get navbarOffset(): string {
    if (this.app.canUseFinStandalone) {
      return 'ml-standalone-navbar-default';
    }
    if (this.isPrimaryNavBarPinned) {
      return 'ml-navbar-expanded';
    }
    return 'ml-navbar-default';
  }

  private activeContentLoader = DeduplicatedAsyncData(
    this,
    () => [
      this.args.activeContentId,
      this.args.activeContentType,
      this.editorMode,
      this.args.folderId,
      this.args.collectionId,
      this.args.locale,
    ],
    async (activeContentId, activeContentType, editorMode, folderId, collectionId, locale) => {
      if (!activeContentType) {
        return null;
      }

      // If we are not creating new content, we need an active content id
      if (!activeContentId && editorMode !== EditorMode.NEW) {
        return null;
      }

      if (!KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES.includes(activeContentType)) {
        let errorMessage = this.intl.t('knowledge-hub.errors.view.unknown-content-type');
        if (editorMode === EditorMode.NEW) {
          errorMessage = this.intl.t('knowledge-hub.errors.create.unknown-content-type');
        }
        this.notificationsService.notifyError(errorMessage);
        // Schedule the onClose call to run after the current computation
        next(() => this.closeDrawer());
        return null;
      }

      if (this.isHandlingTransition) {
        // If we are handling a transition, we want to keep the content the same
        return true;
      }

      switch (editorMode) {
        case EditorMode.VIEW:
          if (!activeContentId) {
            return null;
          }
          return await this.fetchViewModeActiveContent(activeContentId, activeContentType);
        case EditorMode.EDIT:
          if (!activeContentId) {
            return null;
          }
          return await this.fetchEditModeActiveContent(activeContentId, activeContentType);
        case EditorMode.NEW:
          return schedule('afterRender', () =>
            this.fetchNewModeActiveContent(activeContentType, folderId, collectionId, locale),
          );
        case EditorMode.REVIEW:
          if (!activeContentId) {
            return null;
          }
          return await this.fetchReviewModeActiveContent(activeContentId, activeContentType);
      }
    },
  );

  get loadingActiveContent(): boolean {
    return this.activeContentLoader.isLoading;
  }

  get loadedActiveContentExists(): boolean {
    return this.activeContentLoader.value !== null && this.activeContentLoader.value !== undefined;
  }

  async fetchViewModeActiveContent(
    activeContentId: number,
    activeContentType: string,
  ): Promise<(LocalizedKnowledgeContent & Model) | null> {
    let additionalParams = {};
    // we want to fetch the live article-content version for articles
    if (activeContentType === 'article') {
      additionalParams = {
        include: 'live_version',
      };
    }

    let record: (LocalizedKnowledgeContent & Model) | null = null;
    try {
      record = await this.knowledgeHubService.findContent({
        contentId: activeContentId,
        contentTypeName: activeContentType,
        findRecordParams: additionalParams,
      });
    } catch (error) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    if (!record) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    await record.loadRelatedContent(ContentAction.VIEW);
    this.knowledgeHubEditorService.registerActiveContent(record);
    return record;
  }

  async fetchEditModeActiveContent(
    activeContentId: number,
    activeContentType: string,
  ): Promise<(LocalizedKnowledgeContent & Model) | null> {
    let record: (LocalizedKnowledgeContent & Model) | null = null;
    try {
      record = await this.knowledgeHubService.findContent({
        contentId: activeContentId,
        contentTypeName: activeContentType,
      });
    } catch (error) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    if (!record) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    if (!this.permissionsService.currentAdminCan(record.requiredEditPermissionForKnowledgeHub)) {
      this.notifyErrorAndCloseDrawer(this.intl.t('knowledge-hub.errors.edit.permission-required'));
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        record.requiredEditPermissionForKnowledgeHub,
      );
      return null;
    }

    if (this.modelIsSynced(record)) {
      let errorKey =
        record.entityType === EntityType.InternalArticle
          ? 'synced-internal-article'
          : 'synced-public-article';
      // Notify error and transition to view mode
      this.notificationsService.notifyError(this.intl.t(`knowledge-hub.errors.edit.${errorKey}`));
      this.knowledgeHubDrawerEditorService.openViewDrawer({
        activeContentId,
        activeContentType,
      });
      return null;
    }

    if (record.entityType === EntityType.ArticleContent) {
      record.rollbackAttributes(); // Article content has a weird behaviour with autosave where it ends up having dirty attributes due to the overriding of the `save` method
    }
    await record.loadRelatedContent(ContentAction.EDIT);

    this.knowledgeHubEditorService.registerActiveContent(record);
    return record;
  }

  async fetchNewModeActiveContent(
    activeContentType: string,
    folderId?: string,
    collectionId?: string,
    locale?: string,
  ): Promise<(LocalizedKnowledgeContent & Model) | null> {
    let requiredPermission =
      activeContentType === 'article'
        ? CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION
        : CAN_MANAGE_KNOWLEDGE_BASE_CONTENT;

    if (!this.permissionsService.currentAdminCan(requiredPermission)) {
      this.notifyErrorAndCloseDrawer(
        this.intl.t('knowledge-hub.errors.create.permission-required'),
      );
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(requiredPermission);
      return null;
    }

    let record: any;
    try {
      record = this.store.createRecord(
        KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES_FOR_CREATION[activeContentType],
      );
      record.folderId = folderId;
      record.title = record.defaultTitle;
      await record.save();
    } catch (error) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    if (activeContentType === 'article') {
      if (collectionId) {
        let articleIsInserted = await record.addToCollection(collectionId);
        if (articleIsInserted) {
          await this.knowledgeHubEditorService.updateHelpCenterAndCollection(record, false);
        }
      }

      let articleContent;
      if (locale) {
        articleContent = await record.findOrCreateContentByLocale(locale);
      } else {
        articleContent = record.defaultLocalizedContent;
      }

      record = articleContent;
    }

    this.knowledgeHubEditorService.registerActiveContent(record);

    if (folderId) {
      await this.updateFolder(record, folderId);
    }

    // Redirect to the edit page once the content is created
    next(() => {
      this.knowledgeHubDrawerEditorService.openEditDrawer({
        activeContentId: record.id,
        activeContentType,
      });

      this.knowledgeHubEditorService.onContentUpdateCallback?.({
        type: 'add',
        content: record,
      });

      this.knowledgeHubEditorService.trackAnalyticsEvent('created');
    });
    return record;
  }

  async fetchReviewModeActiveContent(
    activeContentId: number,
    activeContentType: string,
  ): Promise<(LocalizedKnowledgeContent & Model) | null> {
    let versions: ContentVersionResponse | null = null;
    let activeContent: (LocalizedKnowledgeContent & Model) | null = null;
    try {
      activeContent = await this.knowledgeHubService.findContent({
        contentId: activeContentId,
        contentTypeName: activeContentType,
      });
      if (activeContent) {
        await this.fetchContentReviewRequest(activeContent);
        versions = await this.knowledgeHubService.findContentVersions(
          activeContent.entityId,
          activeContent.entityType,
        );
      }
    } catch (error) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    if (!versions || !activeContent) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    await activeContent.loadRelatedContent(ContentAction.VIEW);
    this.knowledgeHubEditorService.registerActiveContent(activeContent);

    let contentVersions: ContentVersion[] = versions.data?.map(
      (rawVersion: ContentVersionData) => ({
        ...rawVersion,
        jsonBlocks: rawVersion.json_blocks,
        authorId: rawVersion.author_id.toString(),
        author: this.store.peekRecord('admin', rawVersion.author_id),
        createdBy: this.store.peekRecord('admin', rawVersion.created_by_id),
        updatedAt: rawVersion.updated_at,
      }),
    );
    this.knowledgeHubEditorService.registerActiveContentVersions(contentVersions);
    return activeContent;
  }

  @action
  updateQueryParams(params: Record<string, any>) {
    this.router.transitionTo({ queryParams: params });
  }

  @action
  async closeDrawer() {
    await this.knowledgeHubDrawerEditorService.closeDrawer();
  }

  @action
  editModeToggle() {
    if (!this.args.activeContentId || !this.args.activeContentType) {
      return;
    }

    if (this.editorMode === EditorMode.VIEW) {
      this.knowledgeHubDrawerEditorService.openEditDrawer({
        activeContentId: this.args.activeContentId,
        activeContentType: this.args.activeContentType,
      });
    } else {
      this.knowledgeHubDrawerEditorService.openViewDrawer({
        activeContentId: this.args.activeContentId,
        activeContentType: this.args.activeContentType,
      });
    }
  }

  private isHandlingTransition = false;
  @action
  async warnIfUnsavedChanges(transition: any) {
    if (
      !this.showSideDrawer ||
      !this.knowledgeHubDrawerEditorService.hasDirtyActiveContent() ||
      transition.isAborted ||
      this.isHandlingTransition
    ) {
      return;
    }

    let transitionToActiveContentId = transition.to?.queryParams.activeContentId;
    let transitionToActiveContentType = transition.to?.queryParams.activeContentType;
    let previousActiveContentId = transition.from?.queryParams.activeContentId;
    let previousActiveContentType = transition.from?.queryParams.activeContentType;
    let previousEditorMode = transition.from?.queryParams.editorMode;

    let isInternalTransition = transition.to?.name === transition.from?.name;

    // We should not warn if transitioning to the same content or whwn transitioning from new content mode to edit content
    if (
      (isInternalTransition &&
        transitionToActiveContentId === previousActiveContentId &&
        transitionToActiveContentType === previousActiveContentType) ||
      previousEditorMode === EditorMode.NEW
    ) {
      return;
    }

    transition.abort();

    this.isHandlingTransition = true;

    try {
      let confirmationResult = await this.knowledgeHubDrawerEditorService.confirmClose();

      if (confirmationResult === true) {
        await this.knowledgeHubEditorService.saveActiveContent({ redirectToView: false });
        this.isHandlingTransition = false;
        this.continueTransition(
          isInternalTransition,
          transitionToActiveContentId,
          transitionToActiveContentType,
          transition,
        );
      } else if (confirmationResult.canceled) {
        this.knowledgeHubEditorService.cancelEditActiveContent({ redirectToView: false });
        this.isHandlingTransition = false;
        this.continueTransition(
          isInternalTransition,
          transitionToActiveContentId,
          transitionToActiveContentType,
          transition,
        );
      } else if (isInternalTransition) {
        this.cancelTransition(previousActiveContentId, previousActiveContentType);
      }
    } catch (error) {
      console.error('Error during transition handling:', error);
    } finally {
      this.isHandlingTransition = false;
    }
  }

  private continueTransition(
    isInternalTransition: boolean,
    transitionToActiveContentId: number,
    transitionToActiveContentType: string,
    transition: any,
  ) {
    // If the transition is changing between content, open and refresh the drawer
    // Otherwise, retry the transition to navigate to another part of the app
    if (isInternalTransition) {
      this.knowledgeHubDrawerEditorService.openViewDrawer({
        activeContentId: transitionToActiveContentId,
        activeContentType: transitionToActiveContentType,
      });
      this.activeContentLoader.reload();
    } else {
      transition.retry();
    }
  }

  private cancelTransition(previousActiveContentId: number, previousActiveContentType: string) {
    // When user cancels a transition, we want to open the previous content in edit mode
    this.knowledgeHubDrawerEditorService.openEditDrawer({
      activeContentId: previousActiveContentId,
      activeContentType: previousActiveContentType,
    });
    this.activeContentLoader.reload();
  }

  private notifyErrorAndCloseDrawer(
    errorMessage = this.intl.t('knowledge-hub.errors.view.unknown-content-type'),
  ) {
    this.notificationsService.notifyError(errorMessage);
    this.closeDrawer();
  }

  private modelIsSynced(record: LocalizedKnowledgeContent & Model): boolean {
    return (
      (record.entityType === EntityType.InternalArticle && (record as InternalArticle).isSynced) ||
      (record.entityType === EntityType.ArticleContent &&
        !(record as unknown as ArticleContent).isEditable)
    );
  }

  private async updateFolder(record: KnowledgeHubItem & Model, folderId: string) {
    let folder: Folder | undefined = this.store.peekRecord('content-service/folder', folderId);
    folder ??= await this.store.findRecord('content-service/folder', folderId);
    if (folder) {
      await record.moveToFolderAndSave({ folder, disableConfirmation: true });
    }
  }

  private async fetchContentReviewRequest(activeContent: LocalizedKnowledgeContent & Model) {
    if (
      activeContent.entityType === EntityType.ArticleContent ||
      activeContent.entityType === EntityType.ContentSnippet
    ) {
      (activeContent as ArticleContent | ContentSnippet).contentReviewRequest;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::DrawerEditor': typeof DrawerEditor;
    'knowledge-hub/content-editor/drawer-editor': typeof DrawerEditor;
  }
}
