/* import __COLOCATED_TEMPLATE__ from './copy-link-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CopyLinkCell extends Component {
  @service intercomEventService;
  @service regionService;
  @service clipboardService;

  @action
  copyLink(showTp) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'copy_invitation',
      place: 'invite_teammate',
      object: 'copy_link_btn',
    });
    this.clipboardService.createClipboard(
      '[data-intercom-copy-link-cell]',
      `https://${this.regionService.appHost}/invites/${this.args.invite.token}`,
      showTp,
    );
  }
}
