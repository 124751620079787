/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Calling from 'embercom/models/settings/calling';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  settings: Calling;
  isCallingSupported: boolean;
  isFromNewSettings?: boolean;
  paywall?: boolean;
}

export default class CallingSettings extends Component<Args> {
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare intercomCallService: IntercomCallService;

  @tracked showPricingPlanPaywall = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.intercomCallService.registerCallEvent({
      action: 'viewed',
      object: 'calling_settings',
      place: 'calling_settings',
      section: 'settings_page',
    });

    if (this.args.settings.legacyCustomerHasFreeUsageTrial) {
      this.showPricingPlanPaywall = this.args.paywall ?? false;
    }
  }

  get trialCreditBalance() {
    return Number(this.args.settings.trialCreditBalance).toFixed(2);
  }

  @action
  openUpgradeModal() {
    this.showPricingPlanPaywall = true;
  }

  @action
  closeUpgradeModal() {
    this.showPricingPlanPaywall = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings': typeof CallingSettings;
    'calling/settings': typeof CallingSettings;
  }
}
