/* import __COLOCATED_TEMPLATE__ from './oauth-editor.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import resolveObjectPath from 'embercom/lib/resolve-object-path';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type Token from 'embercom/models/custom-authentication/token';
import { taskFor } from 'ember-concurrency-ts';
// @ts-ignore
import type NotificationsService from 'embercom/services/notifications-service';

interface Args {
  customAuthenticationToken: Token;
  form: any;
}

export default class OauthEditor extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;

  @tracked customAuthenticationToken = this.args.customAuthenticationToken;
  @tracked declare testResponse: any;
  @tracked bannerText = '';
  @tracked declare bannerType: 'confirmation' | 'error';
  @tracked declare displayableResponseBody: string | null;

  @dropTask
  *testRequest() {
    let params = {
      app_id: this.appService.app.id,
      custom_authentication_token_id: this.customAuthenticationToken.id,
      oauth_token_configuration: {
        url: this.customAuthenticationToken.oauthTokenConfiguration.url,
        client_id: this.customAuthenticationToken.oauthTokenConfiguration.clientId,
        client_secret: this.customAuthenticationToken.oauthTokenConfiguration.clientSecret,
        scope: this.customAuthenticationToken.oauthTokenConfiguration.scope,
      },
    };

    try {
      this._setLatestTestRequestParams();
      this.customAuthenticationToken.oauthTokenConfiguration.mostRecentTestPassed = false; // to invalidate previous result
      // @ts-ignore
      this.testResponse = yield post(
        '/ember/custom_authentication/tokens/test_oauth_token',
        params,
      );
      this.displayableResponseBody = JSON.stringify(this.responseBody, null, 2);
      this.customAuthenticationToken.tokenValue = resolveObjectPath(
        this.responseBody,
        'access_token',
      );
      this._setBannerParams();
    } catch (exception) {
      this.displayableResponseBody = null;
      this._setBannerOnException(exception);
    }
  }

  get isTestRequestRunning() {
    return taskFor(this.testRequest).isRunning;
  }

  _setLatestTestRequestParams() {
    this.customAuthenticationToken.oauthTokenConfiguration.latestTestRequestParams =
      this.store.createFragment('custom-authentication/oauth-token-configuration', {
        url: this.customAuthenticationToken.oauthTokenConfiguration.url,
        clientId: this.customAuthenticationToken.oauthTokenConfiguration.clientId,
        clientSecret: this.customAuthenticationToken.oauthTokenConfiguration.clientSecret,
        scope: this.customAuthenticationToken.oauthTokenConfiguration.scope,
      });
  }

  _setBannerParams() {
    this.bannerText = '';
    if (this.testResponse.refresh_response.success) {
      this.customAuthenticationToken.oauthTokenConfiguration.mostRecentTestPassed = true;
      this.bannerType = 'confirmation';
      this.bannerText += `${this.testResponse.refresh_response.status} ${this.testResponse.refresh_response.return_code.toUpperCase()}`;
    } else {
      this.customAuthenticationToken.oauthTokenConfiguration.mostRecentTestPassed = false;
      this.bannerType = 'error';
      this.bannerText +=
        this.testResponse.refresh_response.human_readable_error ||
        this.intl.t('settings.custom-authentication-tokens.new.response.error');
    }
  }

  _setBannerOnException(exception: any) {
    this.bannerType = 'error';
    this.bannerText = exception.errorThrown;
  }

  get responseBody() {
    return this.testResponse?.refresh_response.body;
  }

  get oauthTokenUrlValidation() {
    return this.customAuthenticationToken.oauthTokenConfiguration.validations.attrs.url;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::CustomAuthentication::OauthEditor': typeof OauthEditor;
    'settings/custom-authentication/oauth-editor': typeof OauthEditor;
  }
}
