/* import __COLOCATED_TEMPLATE__ from './android.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import {
  INSTALLATION_TYPE,
  INSTALLATION_METHOD,
} from 'embercom/components/installation-new/constants';
import { inject as service } from '@ember/service';
import type SdkAppService from 'embercom/services/sdk-app-service';

interface Args {}

export default class Android extends Component<Args> {
  @service declare appService: any;
  @service declare sdkAppService: SdkAppService;

  installationType = INSTALLATION_TYPE.LOGGED_IN_USERS;
  installationPlatforms = INSTALLATION_METHOD;

  get admin() {
    return this.appService.app.currentAdmin;
  }

  get isRegenerateSdkAppRunning() {
    return this.sdkAppService.isRegenerateSdkAppRunning;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Android': typeof Android;
    'installation-new/mobile/initial-setup/android': typeof Android;
  }
}
