/* import __COLOCATED_TEMPLATE__ from './code-snippets.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ANDROID_PROGRAMMING_LANGUAGES } from 'embercom/components/installation-new/constants';

const KOTLIN_CODE_SNIPPET = `
// Intercom.client().setUserHash() must be called before Intercom.initialize()
Intercom.client().setUserHash("INSERT_HMAC_VALUE_HERE")
`;

const JAVA_CODE_SNIPPET = `
// Intercom.client().setUserHash() must be called before Intercom.initialize()
Intercom.client().setUserHash("INSERT_HMAC_VALUE_HERE");
`;

const CODE_SNIPPETS = {
  [ANDROID_PROGRAMMING_LANGUAGES.KOTLIN]: KOTLIN_CODE_SNIPPET,
  [ANDROID_PROGRAMMING_LANGUAGES.JAVA]: JAVA_CODE_SNIPPET,
};

const PROGRAMMING_LANGUAGES = [
  { text: 'Kotlin', value: ANDROID_PROGRAMMING_LANGUAGES.KOTLIN },
  { text: 'Java', value: ANDROID_PROGRAMMING_LANGUAGES.JAVA },
];

export default class CodeSnippets extends Component {
  programmingLanguages = PROGRAMMING_LANGUAGES;

  @tracked selectedProgrammingLanguage: ANDROID_PROGRAMMING_LANGUAGES =
    ANDROID_PROGRAMMING_LANGUAGES.KOTLIN;

  @action onSelectProgrammingLanguage(language: ANDROID_PROGRAMMING_LANGUAGES) {
    this.selectedProgrammingLanguage = language;
  }

  get codeSnippet() {
    return CODE_SNIPPETS[this.selectedProgrammingLanguage];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::IdvSetup::Android::CodeSnippets': typeof CodeSnippets;
    'installation-new/mobile/idv-setup/android/code-snippets': typeof CodeSnippets;
  }
}
