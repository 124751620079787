/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Price from 'embercom/models/price';
import ajax from 'embercom/lib/ajax';
import RSVP from 'rsvp';
import {
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type Transition from '@ember/routing/transition';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';
import { captureException } from 'embercom/lib/sentry';
import type MigrationController from 'embercom/controllers/apps/app/settings/subscription/migration';

export default class BillingMigrationRoute extends Route {
  @service declare store: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare stripeLoaderService: $TSFixMe;

  get titleToken() {
    return this.intl.t('new-settings.submenu.subscription.new-pricing');
  }

  analytics = {
    section: 'billing_settings',
  };

  async beforeModel(transition: Transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_billing_settings',
      transition,
    );

    if (!this.customerService.canSeePricingMigration) {
      this.router.transitionTo('apps.app.settings.subscription.billing.index');
    }

    let app = this.modelFor('apps/app') as $TSFixMe;

    if (this.appService.app.isDeveloperWorkspace || app.isTestApp) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.workspace.billing.dev-workspace-error'),
      );
      this.transitionTo('apps.app.settings');
    }

    let customer = await this.store.findRecord('billing/customer', app.get('customer_id'));
    return taskFor(this.loadData).perform(customer);
  }

  @task *loadData(customer: $TSFixMe) {
    yield Promise.all([
      this.stripeLoaderService.load(),
      this.customerService.loadData({
        customer,
        fetchPrices: false,
      }),
    ]);
  }

  model() {
    return RSVP.hash({
      pricing5Estimates: this.fetchMigrationEstimates(),
      pricing5EstimatesForEditPackage: this.fetchMigrationEstimates(),
      currentPrices: this.fetchCurrentPrices(),
      stripeMigration: this.getStripeMigration(),
      allFeatures: this.fetchAllFeatures(),
      availableFeaturesForApp: this.fetchAvailableFeaturesForApp(),
      availableEssentialFeatures: this.fetchAvailableEssentialFeatures(),
      availableAdvancedFeatures: this.fetchAvailableAdvancedFeatures(),
      availableExpertFeatures: this.fetchAvailableExpertFeatures(),
      availablePSPFeatures: this.fetchAvailablePSPFeatures(),
      migrationSeatConfiguration: this.fetchMigrationSeatConfiguration(),
    });
  }

  setupController(controller: MigrationController, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);

    controller.pricing5Estimates = model.pricing5Estimates;
    controller.pricing5EstimatesForEditPackage = model.pricing5EstimatesForEditPackage;
  }

  private getStripeMigration(): Promise<StripeMigration> {
    return this.customerService.getStripeMigration();
  }

  private async fetchMigrationEstimates(): Promise<Array<Price>> {
    let estimates = await this.store.query('migration-estimate', {
      app_id: this.appService.app.id,
    });

    return estimates.toArray();
  }

  private fetchMigrationSeatConfiguration(): Promise<MigrationSeatConfiguration | undefined> {
    return this.store
      .queryRecord('billing/migration-seat-configuration', {
        app_id: this.appService.app.id,
      })
      .catch((error: any) => {
        if (error?.jqXHR?.status === 404) {
          return;
        } else {
          captureException(error, {
            tags: {
              responsibleTeam: 'team-pricing-and-packaging',
              responsible_team: 'team-pricing-and-packaging',
            },
          });
          console.error(error);
        }
      });
  }

  private async fetchCurrentPrices(): Promise<Array<Price>> {
    let currentPrices = await this.store.query('price', {
      use_latest_invoice_usage: true,
      use_current_seats: true,
      agent_count: this.appService.app.agentCount,
      app_id: this.appService.app.id,
      plan_ids: this.customerService.activePlanIds,
      source: 'pricing-migration',
    });

    return currentPrices.toArray();
  }

  private fetchAllFeatures(): Promise<Array<any>> {
    return ajax({
      url: `/ember/billing_features`,
      type: 'GET',
    });
  }

  private fetchAvailableFeaturesForApp(): Promise<Array<any>> {
    return ajax({
      url: `/ember/billing_features/available_billing_features`,
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    });
  }

  private fetchAvailableFeaturesForPlan(plan_id: string): Promise<Array<any>> {
    return ajax({
      url: `/ember/billing_features/features_on_plan/${plan_id}`,
      type: 'GET',
      data: {
        plan_id,
      },
    });
  }

  private fetchAvailableEssentialFeatures() {
    return this.fetchAvailableFeaturesForPlan(PRICING_5_X_CORE_ESSENTIAL_ID);
  }

  private fetchAvailableAdvancedFeatures() {
    return this.fetchAvailableFeaturesForPlan(PRICING_5_X_CORE_ADVANCED_ID);
  }

  private fetchAvailableExpertFeatures() {
    return this.fetchAvailableFeaturesForPlan(PRICING_5_X_CORE_EXPERT_ID);
  }

  private fetchAvailablePSPFeatures() {
    return this.fetchAvailableFeaturesForPlan(PROACTIVE_SUPPORT_PLUS_BASE_ID);
  }
}
