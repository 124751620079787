/* RESPONSIBLE TEAM: team-knowledge-interop */
import { type Block } from '@intercom/interblocks.ts';
import type IntlService from 'ember-intl/services/intl';
import { post } from 'embercom/lib/ajax';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { MAX_PREVIEW_SIZE } from 'embercom/models/article';
import type ArticleContent from 'embercom/models/articles/article-content';
import { EntityType } from 'embercom/models/data/entity-types';
import type ClipboardService from 'embercom/services/clipboard-service';
export function copyHeadingLinkToClipboard(
  heading: any,
  articleContent: ArticleContent,
  notificationsService: $TSFixMe,
  intl: IntlService,
  clipboardService: ClipboardService,
  intercomEventService: $TSFixMe,
  currentlySelectedLocaleId: string,
) {
  let clipboard: ClipboardJS | undefined;
  let url = `${articleContent.publicUrl}#${heading.idAttribute}`;
  let onSuccess = () => {
    trackCopyLink(heading, true, intercomEventService, currentlySelectedLocaleId);
    let previewText = htmlToTextContent(heading.text);
    previewText =
      previewText.length > MAX_PREVIEW_SIZE
        ? `${previewText.substring(0, MAX_PREVIEW_SIZE)}…`
        : previewText;
    notificationsService.notifyConfirmation(
      intl.t('articles.editor.copied-anchor-link-to-place', { preview_text: previewText }),
    );
    clipboard?.destroy();
  };
  let onError = () => {
    trackCopyLink(heading, false, intercomEventService, currentlySelectedLocaleId);
    notificationsService.notifyWarning(
      intl.t('knowledge-hub.content-editor.article-content.clip-board-error'),
    );
    clipboard?.destroy();
  };
  clipboard = clipboardService.createClipboard(
    '.js__prosemirror-composer-copy-clipboard-element',
    url,
    onSuccess,
    onError,
  );
}
export function trackCopyLink(
  heading: any,
  success: boolean,
  intercomEventService: $TSFixMe,
  currentlySelectedLocaleId: string,
) {
  intercomEventService.trackAnalyticsEvent({
    object: 'anchor_link_button',
    action: 'clicked',
    locale: currentlySelectedLocaleId,
    anchor_link_type: heading.type,
    success,
  });
}
export async function getSelectionForAI(
  selectedBlocks: Block[],
  app: $TSFixMe,
  entity_id: string,
  notificationsService: $TSFixMe,
) {
  let params = {
    entity_id,
    entity_type: EntityType.ArticleContent,
    blocks: selectedBlocks,
    intercom_feature: 'article_composer',
  };
  try {
    let response = await post(
      `/ember/inbox/open_ai_demo/article_generator?app_id=${app.id}`,
      params,
    );
    return response.response_blocks;
  } catch (error) {
    notificationsService.notifyResponseError(error);
    throw error;
  }
}
