/* import __COLOCATED_TEMPLATE__ from './handoff-to-zendesk-agent.hbs'; */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* RESPONSIBLE TEAM: team-standalone */
import type HandoffToZendeskAgentStepType from 'embercom/models/operator/visual-builder/step/handoff-to-zendesk-agent';
import type HandoffToZendeskAgentConfiguration from 'embercom/objects/visual-builder/configuration/step/handoff-to-zendesk-agent';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import FinStandaloneService from 'embercom/services/fin-standalone-service';
import RouterService from '@ember/routing/router-service';
import CustomIntlService from 'embercom/services/intl';

export interface HandoffToZendeskAgentArgs {
  step: HandoffToZendeskAgentStepType;
  stepConfig: HandoffToZendeskAgentConfiguration;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: HandoffToZendeskAgentArgs;
}

export default class HandoffToZendeskAgent extends Component<Signature> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare router: RouterService;
  @service declare intl: CustomIntlService;

  get tooltipContent() {
    if (this.shouldShowError && this.requiresApiConnection) {
      return this.apiConnectionErrorCopy;
    }
    return this.args.stepConfig.tooltipCopy;
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }

  get requiresApiConnection(): boolean {
    return this.args.step.validations.attrs.zendeskConversationsApiIsConnectedForIntercomMessenger
      .isInvalid;
  }

  get apiConnectionErrorCopy() {
    return this.intl.t(
      'operator.workflows.visual-builder.step-menu.handoff-to-zendesk-agent-api-connection-error-tooltip',
      {
        channelPageUrl: this.channelPageUrl,
        htmlSafe: true,
      },
    );
  }

  get channelPageUrl() {
    if (this.args.stepConfig.shouldCheckApiConnectionForIntercomMessenger) {
      return this.router.urlFor('apps.app.standalone.channels.intercom.messenger');
    }
    if (this.args.stepConfig.shouldCheckApiConnectionForZendeskSunshine) {
      return this.router.urlFor('apps.app.standalone.channels.zendesk.messaging');
    }
    if (this.args.stepConfig.shouldCheckApiConnectionForZendeskTicket) {
      return this.router.urlFor('apps.app.standalone.channels.zendesk.tickets');
    }
    return this.router.urlFor('apps.app.standalone.channels');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffToZendeskAgent': typeof HandoffToZendeskAgent;
    'workflows/graph-editor/node-items/steps/handoff-to-zendesk-agent': typeof HandoffToZendeskAgent;
  }
}
