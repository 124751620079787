/* import __COLOCATED_TEMPLATE__ from './message-actions.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import type CommandKService from 'embercom/services/command-k';
import { DisplayContext } from 'embercom/services/command-k';
import { tracked } from '@glimmer/tracking';
import Tag, { type TagActionType, type TagMenuSource } from 'embercom/objects/inbox/tag';
import type WorkflowConnectorAction from 'embercom/objects/inbox/workflow-connector-action';
import type TaggablePart from 'embercom/objects/inbox/taggable-part';
import { isPartTaggedWith, isTaggable } from 'embercom/objects/inbox/taggable-part';
import { taskFor } from 'ember-concurrency-ts';
import type Session from 'embercom/services/session';
import {
  canDeleteInitialPart,
  canDeletePart,
  isDeletedPart,
  isInitialPart,
  isInitialOutboundMessage,
} from 'embercom/objects/inbox/deleted-part';
import type AdminPermissions from 'embercom/services/admin-permissions';
import type PermissionsModal from 'embercom/services/permissions-modal';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type Clipboard from 'clipboard';
import type IntegrationsApi from 'embercom/services/integrations-api';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { captureException } from 'embercom/lib/sentry';
import type Snackbar from 'embercom/services/snackbar';
import { MacroType } from 'embercom/objects/inbox/macro';
import { type Block } from '@intercom/interblocks.ts';
import AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import Chat from 'embercom/objects/inbox/renderable/chat';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';
import Sms from 'embercom/objects/inbox/renderable/sms';
import Post from 'embercom/objects/inbox/renderable/post';
import Email from 'embercom/objects/inbox/renderable/email';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { CommandKTagActionId } from 'embercom/objects/inbox/command-k/tag-item';
import { Channel } from 'embercom/models/data/inbox/channels';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type PublicAPI } from '../../conversation-reply-composer';
import { ChildAction } from 'embercom/objects/inbox/command-k/action';
import type ClipboardService from 'embercom/services/clipboard-service';

interface Args {
  renderablePart: RenderablePart;
  conversation: Conversation;
  displayIcon: boolean;
}

interface Signature {
  Args: Args;
}

export default class MessageActions extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare commandK: CommandKService;
  @service declare session: Session;
  @service declare adminPermissions: AdminPermissions;
  @service declare permissionsModal: PermissionsModal;
  @service declare intl: IntlService;
  @service declare clipboardService: ClipboardService;
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare snackbar: Snackbar;
  @service declare integrationsApi: IntegrationsApi;
  @service declare intercomEventService: any;

  @tracked shouldShowActions = false;
  @tracked shouldShowDeleteModal = false;
  @tracked showCreateMacroModal = false;
  @tracked tagMenuSource: TagMenuSource = null;
  @tracked tagActionType: TagActionType = null;
  @tracked api?: PublicAPI;

  macroTypeReply: MacroType = MacroType.Reply;

  clipboard?: Clipboard;

  get isSideConversation(): boolean {
    return !!this.args.conversation?.isSideConversation;
  }

  get conversation() {
    return this.args.conversation;
  }

  get conversationPart() {
    return this.args.renderablePart;
  }

  get context() {
    return {
      conversationPart: this.conversationPart,
      tags: (this.conversationPart as TaggablePart).renderableData.tags,
    };
  }

  get showDeleteInitialOutboundMessageModal() {
    return isInitialOutboundMessage(this.conversationPart);
  }

  get initialPartRuleSetId() {
    if (this.conversationPart.renderableType === RenderableType.Chat) {
      let chatPart = this.conversationPart.renderableData as Chat;
      return chatPart.rulesetId;
    }

    if (this.conversationPart.renderableType === RenderableType.Email) {
      let emailPart = this.conversationPart.renderableData as Email;
      return emailPart.rulesetId;
    }

    // This code path should never be hit because we check if ruleSetId exists before calling this function
    throw new Error('Unsupported renderable type');
  }

  get canDeleteMessage() {
    if (isInitialPart(this.conversationPart)) {
      return canDeleteInitialPart(this.conversationPart) || canDeletePart(this.conversationPart);
    }
    return canDeletePart(this.conversationPart);
  }

  get canTagMessage() {
    return isTaggable(this.conversationPart) && !this.args.conversation?.isSideConversation;
  }

  get canQuoteReply() {
    if (!this.session.workspace.isFeatureEnabled('quote-reply')) {
      return false;
    }
    // disable quote reply for email conversations
    if (this.conversation.currentChannel === 3) {
      return false;
    }

    return true;
  }

  get hasActions() {
    return !isDeletedPart(this.conversationPart) && (this.canTagMessage || this.canDeleteMessage);
  }

  get canSaveReply() {
    let renderableData = this.conversationPart.renderableData;
    if (
      !(
        renderableData instanceof AdminComment ||
        ((renderableData instanceof Chat ||
          renderableData instanceof Email ||
          renderableData instanceof Post ||
          renderableData instanceof Sms) &&
          renderableData.senderType === OutboundInitialPartSenderType.Admin)
      )
    ) {
      return false;
    }

    if (renderableData.blocks.some((block: Block) => block.type === 'messengerCard')) {
      return false;
    }

    return true;
  }

  get isEmailPart() {
    return (
      this.conversationPart.renderableType !== RenderableType.AdminNote &&
      this.conversation?.channel?.replyChannel === Channel.Email
    );
  }

  @action updateActivationContext() {
    if (this.isSideConversation) {
      if (
        this.commandK.currentActivationContext === DisplayContext.SideConversationMessageActions
      ) {
        this.commandK.setActivationContext(DisplayContext.SideConversation);
      } else {
        this.commandK.setActivationContext(DisplayContext.SideConversationMessageActions);
      }
    } else {
      this.commandK.setActivationContext(DisplayContext.MessageActions);
    }
  }

  @action hideOptions() {
    this.shouldShowActions = false;
    this.commandK.resetActivationContext();
    this.setTagMenuSource(null);
  }

  @action showActions(tagSourceClick: TagMenuSource) {
    this.setTagMenuSource(tagSourceClick);
    this.shouldShowActions = true;
    this.updateActivationContext();
    this.commandK.show(this.hideOptions);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'message_actions',
      place: 'inbox',
      forwardable_message: this.isEmailPart,
    });
  }

  @action handleDeleteMessage() {
    if (this.adminPermissions.canDeleteMessageOrNotes) {
      this.shouldShowDeleteModal = true;
    } else {
      this.permissionsModal.showModal({
        permissionsLabel: this.intl.t('inbox.common.permissions-label.can-delete-replies-notes'),
      });
    }
  }

  @action hideDeleteModal() {
    this.shouldShowDeleteModal = false;
  }

  @action tagMessage(tag: Tag, context: { conversationPart: TaggablePart }) {
    this.optimisticTagMessage(tag, context);
  }

  @action legacyTagMessage(tag: Tag, context: { conversationPart: TaggablePart }) {
    let conversationPart = context.conversationPart;
    let { tags: existingTags }: { tags: Array<Tag> } = conversationPart.renderableData;
    let newTags;
    let partIsTagged = isPartTaggedWith(conversationPart, tag);
    if (partIsTagged) {
      newTags = existingTags.filter((t) => t.id !== tag.id);
    } else {
      newTags = [...existingTags, new Tag(tag.id, tag.name, this.session.teammate)];
    }
    this.setTagActionType(partIsTagged ? 'removed' : 'added');
    taskFor(this.inboxState.updateTags).perform(this.args.conversation, conversationPart, newTags);
  }

  @action optimisticTagMessage(tag: Tag, context: { conversationPart: TaggablePart }) {
    let conversationPart = context.conversationPart;
    let partIsTagged = isPartTaggedWith(conversationPart, tag);

    this.setTagActionType(partIsTagged ? 'removed' : 'added');

    if (partIsTagged) {
      this.inboxState.removeTag(this.args.conversation, conversationPart, tag);
    } else {
      this.inboxState.addTag(this.args.conversation, conversationPart, tag);
    }
  }

  @action showTags() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'tags',
      section: 'command_k',
      shortcut_keys: false,
      source: 'tag_button',
    });

    this.showActions('tag_button_click');
    this.commandK.findAndShow(CommandKTagActionId.TagConversationPart, () => this.hideOptions());
  }

  @action registerComposerApi(composerApi: PublicAPI) {
    this.api = composerApi;
  }

  // This is technically abusing the Command-K API. We need to figure out a more sustainable approach to
  // this problem if we want to productionise this feature. Do not replicate this pattern.
  // The problem: Composer level action that is global, we only have the meta data only at this point
  @action quoteReply() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'quote_reply',
      object: 'message_actions',
      source: 'message_action_button',
    });

    // @ts-ignore: If we want to launch this feature, we can invest time in typing this properly
    let blocks = this.args.renderablePart.renderableData.blocks;

    let action = new ChildAction({
      id: 'quote-reply',
      // @ts-ignore
      parent: {
        id: 'quote-reply',
      },
      value: blocks,
    });

    let metadata = {
      blocks: {
        blocks,
      },
    };

    this.commandK.triggerAction(action, metadata);
  }

  @action setTagMenuSource(source: TagMenuSource) {
    this.tagMenuSource = source;
  }

  @action setTagActionType(type: TagActionType) {
    this.tagActionType = type;
  }

  @action copyLink() {
    // In some parts of the app, like Search Page, we don't have an active inbox so we must provide a default
    let inbox = this.inboxState.activeInbox || {
      id: InboxType.All,
      category: InboxCategory.Shared,
    };

    let baseUrl = window.location.origin;
    let url = this.router.urlFor(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      inbox.category,
      inbox.id,
      this.conversation.id,
    );

    let partId = this.conversationPart.generatePermalinkId(this.conversation.id);

    this.clipboard = this.clipboardService.createClipboard(
      '[data-intercom-target-action-menu-item="copy-conversation-part-link"]',
      `${baseUrl}${url}#part_id=${partId}`,
      this.onCopyLinkSuccess,
      this.onCopyLinkError,
    );
  }

  @action forwardMessage() {
    let partId = this.conversationPart.generatePermalinkId(this.conversation.id);
    this.router.transitionTo('inbox.workspace.inbox.new-conversation', {
      queryParams: { forwardedPartId: partId, ticketTypeId: undefined },
    });
  }

  @action replyToEmailPart() {
    this.inboxState.replyToEmailPart(this.conversationPart, this.conversation.id);
  }

  @action onCopyLinkSuccess() {
    this.notificationsService.notifyConfirmation(this.intl.t('inbox.common.copy-link.success'));
    this.clipboard?.destroy();
  }

  @action onCopyLinkError() {
    this.notificationsService.notifyError(this.intl.t('inbox.common.copy-link.failure'));
    this.clipboard?.destroy();
  }

  @action async deleteMessage() {
    if (isInitialPart(this.conversationPart) && canDeleteInitialPart(this.conversationPart)) {
      taskFor(this.inboxState.deleteInitialPart).perform(
        this.conversation,
        this.conversationPart,
        this.session.teammate,
      );
    } else {
      taskFor(this.inboxState.deleteMessage).perform(
        this.conversation,
        this.conversationPart,
        this.session.teammate,
      );
    }

    this.hideDeleteModal();
  }

  @action async createGithubIssue() {
    let notification = this.snackbar.notify(
      this.intl.t('inbox.command-k.create-github-issue.creating'),
      { persistent: true },
    );

    try {
      let url = await this.integrationsApi.createGithubIssue(
        this.conversation.id.toString(),
        this.conversationPart.generatePermalinkId(this.conversation.id),
        this.conversation.firstParticipant?.id,
      );

      safeWindowOpen(url);
    } catch (err) {
      captureException(err);
      this.snackbar.notifyError(this.intl.t('inbox.command-k.create-github-issue.error'));
    } finally {
      this.snackbar.clearNotification(notification);
    }
  }

  @action async createSalesforceTask() {
    let notification = this.snackbar.notify(
      this.intl.t('inbox.command-k.create-salesforce-task.creating'),
      { persistent: true },
    );

    try {
      let {
        tasks: [task],
      } = await this.integrationsApi.createSalesforceTask(
        this.conversation.id.toString(),
        this.conversationPart.generatePermalinkId(this.conversation.id),
        this.conversation.firstParticipant?.id,
      );

      this.snackbar.notify(
        this.intl.t('inbox.command-k.create-salesforce-task.created', {
          url: task.task_url,
          userName: this.conversation.firstParticipant?.firstName,
        }),
      );
    } catch (err) {
      captureException(err);
      this.snackbar.notifyError(this.intl.t('inbox.command-k.create-salesforce-task.error'));
    } finally {
      this.snackbar.clearNotification(notification);
    }
  }

  @action triggerWorkflowConnectorAction(
    action: WorkflowConnectorAction,
    context: { conversationPart: TaggablePart },
  ) {
    taskFor(this.inboxState.triggerWorkflowConnectorAction).perform(
      this.args.conversation,
      context.conversationPart,
      action,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::MessageActions': typeof MessageActions;
    'inbox2/conversation-stream/part-groups/message-actions': typeof MessageActions;
  }
}
