/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import type Store from '@ember-data/store';
import type UsageLimitsService from 'embercom/services/usage-limits-service';
import { type ConversationContentSettings } from 'embercom/controllers/apps/app/fin-ai-agent/setup';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { get, post } from 'embercom/lib/ajax';
import type SetupController from 'embercom/controllers/apps/app/fin-ai-agent/setup';
import { taskFor } from 'ember-concurrency-ts';
import { adminHasSeenFromPageviews, fetchAdminPageviews } from 'embercom/lib/admin-view-tracking';
import { FIN_LANDING_PAGE_PAGEVIEW_KEY } from 'embercom/lib/operator/resolution-bot/constants';
const LAST_VIEWED_QUESTION_EXTRACTION_RUN_ID = 'last-viewed-question-extraction-run-id';
import { CONVERSATION_STARTED_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';

// Adding a model type as described here:
// https://docs.ember-cli-typescript.com/ember/routes
type Resolved<P> = P extends Promise<infer T> ? T : P;
export type FinAIAgentSetupRouteModel = Resolved<ReturnType<FinAIAgentSetupRoute['model']>>;

export default class FinAIAgentSetupRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare contentImportService: $TSFixMe;
  @service declare outboundHomeService: $TSFixMe;
  @service declare onboardingHomeService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare usageLimitsService: UsageLimitsService;
  @service declare store: Store;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare permissionsService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare conversationExtractionService: ConversationExtractionService;

  analytics = {
    section: 'ai-chatbot',
    place: 'setup',
  };

  liveInboundWorkflowsOverridableByFinSetup: ContentWrapper[] = [];
  liveConversationStartedWorkflowsOverridableByFinSetup: ContentWrapper[] = [];
  hasFinEmailWorkflows = false;
  hasFinChatWorkflows = false;
  liveFinWorkflowsCount = 0;
  liveFinEmailWorkflowsCount = 0;
  liveFinChatWorkflowsCount = 0;
  finSetupRuleset: $TSFixMe;
  emailSetupRuleset: $TSFixMe;
  previewConfiguration: $TSFixMe;
  latestQuestionExtractionRunId: number | undefined;
  shouldShowUpdatedLabel: boolean | undefined;
  hasSeenFinLandingPageWithPreview: boolean | undefined;
  personalityCustomizationSettings: $TSFixMe;

  async beforeModel() {
    this.outboundHomeService.setActiveList(objectTypes.resolutionBotBehavior);
    await taskFor(this.reportingMetrics.setup).perform();
    let adminPageviews = await fetchAdminPageviews(this.store);
    this.hasSeenFinLandingPageWithPreview = adminHasSeenFromPageviews(
      adminPageviews,
      FIN_LANDING_PAGE_PAGEVIEW_KEY,
    );
  }

  get app() {
    return this.appService.app;
  }

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.fin-overview.title');
  }

  async model() {
    await Promise.all([
      this.loadLiveInboundWorkflowsOverridableByFinSetup(),
      this.loadLiveConversationStartedWorkflowsOverridableByFinSetup(),
      this.usageLimitsService.fetchFinUsageLimits(),
      this.getPersonalityCustomizationSettings(),
      this.loadAiAgentSetupService(),
      this.store.findAll('template'),
      this.loadOnboardingGuide(),
      this.getPreviewConfiguration(),
      this.loadLastQuestionGroupsRunDetails(),
      this.fetchConversationExtractionSettings(),
    ]);

    let liveProfilesWithAiAnswersEnabled: boolean =
      this.aiAgentSetupService.liveResolutionBotBehaviors.contentWrappers?.some(
        (contentWrapper: ContentWrapper) => {
          let content = contentWrapper.contents.firstObject;
          return content?.contentData?.use_ai_answers;
        },
      );

    let liveProfilesWithCustomAnswersEnabled: boolean =
      this.aiAgentSetupService.liveResolutionBotBehaviors.contentWrappers?.some(
        (contentWrapper: ContentWrapper) => {
          let content = contentWrapper.contents.firstObject;
          return content?.contentData?.use_custom_answers;
        },
      );

    let liveWorkflowsOverridableByFinSetup = [
      ...this.liveInboundWorkflowsOverridableByFinSetup,
      ...this.liveConversationStartedWorkflowsOverridableByFinSetup,
    ];

    this.maybeTriggerFirstSetupIngestion();

    let actions = await this.store.query('workflow-connector/setup-action', {});

    let totalLiveFinActions = actions.filterBy('usage', 'fin').filterBy('state', 'live').length;

    return RSVP.hash({
      liveProfilesWithAiAnswersEnabled,
      liveProfilesWithCustomAnswersEnabled,
      liveWorkflowsOverridableByFinSetup,
      liveFinProfilesCount: this.aiAgentSetupService.finStats.liveFinProfilesCount,
      hardLimitBreached: this.usageLimitsService.finHardLimitBreached,
      conversationContentSettings: this.contentImportService.finConversationContentSettings,
      hasFinEmailWorkflows: this.hasFinEmailWorkflows,
      hasFinChatWorkflows: this.hasFinChatWorkflows,
      liveFinWorkflowsCount: this.liveFinWorkflowsCount,
      liveFinEmailWorkflowsCount: this.liveFinEmailWorkflowsCount,
      liveFinChatWorkflowsCount: this.liveFinChatWorkflowsCount,
      finSetupRuleset: this.finSetupRuleset,
      emailSetupRuleset: this.emailSetupRuleset,
      finIngestionState: this.contentImportService.fetchContentIngestionState(),
      previewConfiguration: this.previewConfiguration,
      latestQuestionExtractionRunId: this.latestQuestionExtractionRunId,
      shouldShowUpdatedLabel: this.shouldShowUpdatedLabel,
      totalLiveFinActions,
      personalityCustomizationSettings: this.personalityCustomizationSettings,
    });
  }

  setupController(
    controller: SetupController,
    model: {
      liveProfilesWithAiAnswersEnabled: boolean;
      liveProfilesWithCustomAnswersEnabled: boolean;
      liveWorkflowsOverridableByFinSetup: ContentWrapper[];
      liveConversationStartedWorkflowsOverridableByFinSetup: ContentWrapper[];
      liveFinProfilesCount: number;
      hardLimitBreached: boolean;
      conversationContentSettings?: ConversationContentSettings;
      hasFinEmailWorkflows: boolean;
      hasFinChatWorkflows: boolean;
      liveFinWorkflowsCount: number;
      liveFinEmailWorkflowsCount: number;
      liveFinChatWorkflowsCount: number;
      finSetupRuleset: $TSFixMe;
      emailSetupRuleset: $TSFixMe;
      previewConfiguration: $TSFixMe;
      latestQuestionExtractionRunId: number;
      shouldShowUpdatedLabel: boolean;
      totalLiveFinActions: number;
      personalityCustomizationSettings: $TSFixMe;
      savedTovSetting: string | null;
    },
  ) {
    controller.setProperties({
      liveProfilesWithAiAnswersEnabled: model.liveProfilesWithAiAnswersEnabled,
      liveProfilesWithCustomAnswersEnabled: model.liveProfilesWithCustomAnswersEnabled,
      liveWorkflowsOverridableByFinSetup: model.liveWorkflowsOverridableByFinSetup,
      liveFinProfilesCount: model.liveFinProfilesCount,
      hardLimitBreached: model.hardLimitBreached,
      conversationContentSettings: model.conversationContentSettings,
      hasFinEmailWorkflows: model.hasFinEmailWorkflows,
      hasFinChatWorkflows: model.hasFinChatWorkflows,
      liveFinWorkflowsCount: model.liveFinWorkflowsCount,
      liveFinEmailWorkflowsCount: model.liveFinEmailWorkflowsCount,
      liveFinChatWorkflowsCount: model.liveFinChatWorkflowsCount,
      finSetupRuleset: model.finSetupRuleset,
      emailSetupRuleset: model.emailSetupRuleset,
      previewConfiguration: model.previewConfiguration,
      latestQuestionExtractionRunId: model.latestQuestionExtractionRunId,
      shouldShowUpdatedLabel: model.shouldShowUpdatedLabel,
      hasSeenFinLandingPageWithPreview: this.hasSeenFinLandingPageWithPreview,
      personalityCustomizationSettings: model.personalityCustomizationSettings,
      savedTovSetting: model.personalityCustomizationSettings?.aiToneOfVoice || 'neutral',
    });
  }

  activate() {
    this.guideLibraryService.addWorkflowSetLiveListener();
  }

  resetController(controller: SetupController, isExiting: boolean) {
    if (isExiting) {
      controller.set('previewFin', false);
    }
  }

  async loadOnboardingGuide() {
    await this.onboardingHomeService.findOrLoadGuide(false);
  }

  async fetchConversationExtractionSettings() {
    if (this.appService.app.canUseConversationExtractionExperiment) {
      await this.conversationExtractionService.fetchConversationExtractionSettings();
    }
  }

  async loadLiveInboundWorkflowsOverridableByFinSetup() {
    if (!this.appService.app.canUseFeature('bot_workflows')) {
      return;
    }

    let workflows = await this.outboundHomeService.contentSearchWithFilters({
      object_types: [objectTypes.inboundCustomBot],
      app_id: this.appService.app.id,
      additionalSearchableData: {
        has_fin: true,
      },
    });

    this.liveInboundWorkflowsOverridableByFinSetup = (workflows.contentWrappers || []).filter(
      (wrapper: ContentWrapper) => wrapper.contents.firstObject?.state === states.live,
    );
  }

  async loadLiveConversationStartedWorkflowsOverridableByFinSetup() {
    if (!this.appService.app.canUseFeature('bot_workflows')) {
      return;
    }

    let workflows = await this.outboundHomeService.contentSearchWithFilters({
      object_types: [objectTypes.triggerableCustomBot],
      app_id: this.appService.app.id,
      additionalSearchableData: {
        target: CONVERSATION_STARTED_TARGET,
        has_fin: true,
      },
    });

    this.liveConversationStartedWorkflowsOverridableByFinSetup = (
      workflows.contentWrappers || []
    ).filter((wrapper: ContentWrapper) => wrapper.contents.firstObject?.state === states.live);
  }

  getFinWorkflowsCount() {
    this.liveFinChatWorkflowsCount =
      this.aiAgentSetupService.finStats.workflowsCount.liveFinChatWorkflowsCount;
    this.liveFinEmailWorkflowsCount =
      this.aiAgentSetupService.finStats.workflowsCount.liveFinEmailWorkflowsCount;
    this.liveFinWorkflowsCount =
      this.aiAgentSetupService.finStats.workflowsCount.liveFinWorkflowsCount;
    this.hasFinChatWorkflows = this.aiAgentSetupService.finStats.workflowsCount.hasFinChatWorkflows;
    this.hasFinEmailWorkflows =
      this.aiAgentSetupService.finStats.workflowsCount.hasFinEmailWorkflows;
  }

  async loadAiAgentSetupService() {
    await this.aiAgentSetupService.load();
    this.finSetupRuleset = this.aiAgentSetupService.setupRuleset.finSetupRuleset;
    this.emailSetupRuleset = this.aiAgentSetupService.setupRuleset.emailSetupRuleset;
    this.getFinWorkflowsCount();
  }

  async maybeTriggerFirstSetupIngestion() {
    try {
      await post('/ember/fin_initial_setup_ingestion', {
        app_id: this.appService.app.id,
      });
    } catch {
      // Errors in this request shouldn't block the page from loading
    }
  }

  async getPersonalityCustomizationSettings(): Promise<$TSFixMe> {
    this.personalityCustomizationSettings = this.store.findRecord(
      'ai-agent/tone-of-voice-settings',
      this.appService.app.id,
    );
  }

  async getPreviewConfiguration(): Promise<$TSFixMe> {
    let settings = await this.store.findRecord('messenger-settings/all', this.appService.app.id);
    this.previewConfiguration = settings.previewConfiguration;
  }

  async loadLastQuestionGroupsRunDetails(): Promise<$TSFixMe> {
    let response = await get(
      '/ember/ai_agent/question_groups/latest_question_extraction_run_details',
      {
        app_id: this.appService.app.id,
      },
    );
    this.latestQuestionExtractionRunId = response.id;
    this.shouldShowUpdatedLabel =
      !!this.latestQuestionExtractionRunId &&
      this.latestQuestionExtractionRunId.toString() !==
        localStorage.getItem(LAST_VIEWED_QUESTION_EXTRACTION_RUN_ID);
  }

  @action
  didTransition() {
    this.intercomEventService.trackEvent('fin-automation-page-visited');
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
