/* import __COLOCATED_TEMPLATE__ from './question-filters-loading-state.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const QuestionFiltersLoadingState = templateOnlyComponent<Signature>();
export default QuestionFiltersLoadingState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::LoadingStates::QuestionFiltersLoadingState': typeof QuestionFiltersLoadingState;
  }
}
