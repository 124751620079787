/* import __COLOCATED_TEMPLATE__ from './section-menu.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  appService: service(),
  appstoreService: service(),

  isMobile: false,
  classNames: ['appstore__section-menu'],
  trackingEventDataForAllLink: {
    object: 'all',
    section: 'side_menu',
  },
  appCapabilities: readOnly('appstoreService.appCapabilities'),

  actions: {
    scrollToTop() {
      document.getElementsByClassName('appstore__public-top-nav')[0].scrollIntoView();
    },
  },
});
