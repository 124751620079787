/* import __COLOCATED_TEMPLATE__ from './messenger-call-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import type VideoCallService from 'embercom/services/video-call-service';
import type IntlService from 'embercom/services/intl';
import moment from 'moment-timezone';

export default class MessengerCallModal extends Component {
  @service declare videoCallService: VideoCallService;
  @service declare intl: IntlService;

  get titleLine() {
    return this.intl.t('calling.modal.title.messenger', {
      name: this.videoCallService.user?.name || this.videoCallService.user?.pseudonym || '',
    });
  }

  @action
  async endCall() {
    await this.videoCallService.endCall();
  }

  @action
  toggleMicrophone() {
    this.videoCallService.toggleAudio(!this.videoCallService.isMicrophoneOn);
  }

  @action
  toggleCamera() {
    debounce(this, this._toggleCamera, 1000);
  }

  _toggleCamera() {
    this.videoCallService.toggleVideo(!this.videoCallService.isCameraOn);
  }

  @action
  toggleExpandedView() {
    this.videoCallService.toggleExpandedView();
  }

  @action
  toggleRecording() {
    this.videoCallService.toggleRecording();
  }

  get startTime() {
    return moment().valueOf();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::MessengerCallModal': typeof MessengerCallModal;
    'inbox2/messenger-call-modal': typeof MessengerCallModal;
  }
}
