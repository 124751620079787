/* import __COLOCATED_TEMPLATE__ from './zendesk-warning.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {};
}

const ZendeskWarning = templateOnlyComponent<Signature>();
export default ZendeskWarning;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup::ZendeskWarning': typeof ZendeskWarning;
    'standalone/intercom-messenger/setup/zendesk-warning': typeof ZendeskWarning;
  }
}
