/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';

export interface Args {
  email: string;
}

interface Signature {
  Args: Args;
  Blocks: any;
}

export default class Preview extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;

  get senderPreview() {
    return this.intl.t(
      'new-settings.channels.email.connect-email.email-setup.add-address-step.sender-preview',
      {
        adminName: this.adminName,
        appName: this.appName,
        email: this.args.email,
        htmlSafe: true,
      },
    );
  }

  private get adminName() {
    return this.appService.app.currentAdmin.firstName;
  }

  private get appName() {
    return this.appService.app.name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::AddEmailAddress::Preview': typeof Preview;
    'new-settings/channels/email/domains-and-addresses/add-email-address/preview': typeof Preview;
  }
}
