/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { Channel } from 'embercom/models/data/inbox/channels';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

const IconMap: Record<Channel, InterfaceIconName | undefined> = {
  [Channel.Unknown]: undefined,
  [Channel.Desktop]: 'messenger',
  [Channel.Android]: 'android',
  [Channel.IOS]: 'ios',
  [Channel.Email]: 'email',
  [Channel.Twitter]: 'twitter',
  [Channel.Facebook]: 'facebook',
  [Channel.FacebookMessenger]: 'facebook',
  [Channel.Whatsapp]: 'whatsapp',
  [Channel.Instagram]: 'instagram',
  [Channel.PhoneSwitch]: undefined,
  [Channel.SMS]: 'sms',
  [Channel.PhoneCall]: 'phone',
  [Channel.ZendeskTicket]: 'ticket',
  [Channel.ZendeskSunshineConversation]: 'messenger',
  [Channel.SalesforceCase]: 'ticket',
};

const FilledIconMap: Record<Channel, InterfaceIconName | undefined> = {
  [Channel.Unknown]: undefined,
  [Channel.Desktop]: 'chat-filled',
  [Channel.Android]: 'android',
  [Channel.IOS]: 'ios',
  [Channel.Email]: 'email-filled',
  [Channel.Twitter]: 'twitter',
  [Channel.Facebook]: 'facebook',
  [Channel.FacebookMessenger]: 'facebook',
  [Channel.Whatsapp]: 'whatsapp',
  [Channel.Instagram]: 'instagram',
  [Channel.PhoneSwitch]: undefined,
  [Channel.SMS]: 'sms',
  [Channel.PhoneCall]: 'phone',
  [Channel.ZendeskTicket]: 'ticket',
  [Channel.ZendeskSunshineConversation]: 'chat-filled',
  [Channel.SalesforceCase]: 'ticket',
};

export default class LookupChannelIcon extends Helper<{
  Args: {
    Positional: [Channel | undefined];
    Named: { filled?: boolean };
  };
  Return: InterfaceIconName | undefined;
}> {
  compute(params: [Channel], namedArgs?: { filled?: boolean }) {
    let [channel] = params;

    if (namedArgs?.filled) {
      return FilledIconMap[channel];
    }

    return IconMap[channel];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'lookup-channel-icon': typeof LookupChannelIcon;
  }
}
