/* import __COLOCATED_TEMPLATE__ from './integration-app.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { IntegrationAppClient } from '@integration-app/sdk';
import type IntlService from 'ember-intl/services/intl';
import { get } from 'embercom/lib/ajax';
import { SourceType, SyncBehaviorType } from 'embercom/models/import-service/import-source';
import type ImportSource from 'embercom/models/import-service/import-source';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  modal: any;
  title?: string;
  onModalClose: () => void;
  connectionType: string;
}

type AccessTokenResponse = {
  access_token: string;
};

type CreateConnectionResponse = {
  authOptionKey: string;
  createdAt: string;
  disconnected: boolean;
  id: string;
  integrationId: string;
  isDeactivated: boolean;
  isTest: boolean;
  lastActiveAt: string;
  name: string;
  updatedAt: string;
  userId: string;
};

const CONNECTION_MAP: Record<string, string> = {
  'salesforce-src': 'salesforce',
};

export default class IntegrationAppSyncModal extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: any;

  @tracked importSource: ImportSource | undefined;
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Sync;
  @tracked isConnecting = false;

  get connectionType(): string {
    return CONNECTION_MAP[this.args.connectionType];
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    setTimeout(() => {
      this.initiateConnection();
    }, 500);
  }

  async fetchAccessToken(): Promise<string> {
    let response = await get('/ember/import_service/import_sources/integration_app/access_token', {
      app_id: this.appService.app.id,
    });

    let json = response as unknown as AccessTokenResponse;
    return json.access_token;
  }

  @action
  async initiateConnection() {
    if (this.isConnecting) {
      return;
    }

    this.isConnecting = true;
    try {
      this.args.onModalClose();
      await this.createConnection();
    } catch (error) {
      this.isConnecting = false;
      throw error;
    }
  }

  @action
  async createConnection() {
    let accessToken = await this.fetchAccessToken();
    let integrationApp = new IntegrationAppClient({
      token: accessToken,
    });
    let connectionResponse = (await integrationApp
      .integration(this.connectionType)
      .openNewConnection()) as CreateConnectionResponse;
    let importSourceParams = {
      sourceType: SourceType.Salesforce,
      connectionId: connectionResponse.id,
      connectionName: this.connectionType,
    };
    this.importSource = await this.knowledgeHubService.createImportSource(importSourceParams);
    this.knowledgeHubService.startImport(this.importSource.id, this.syncBehavior);

    let notificationMessage =
      this.syncBehavior === SyncBehaviorType.Sync
        ? this.intl.t(`knowledge-hub.sync-modal.${this.connectionType}.sync-started`)
        : this.intl.t(`knowledge-hub.sync-modal.${this.connectionType}.import-started`);

    this.knowledgeHubService.notifySourceStateChangeConfirmation(
      notificationMessage,
      this.importSource.id,
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'import-content',
      object: `import-content.${this.connectionType}`,
      section: `knowledge-hub.new-content.${this.connectionType}`,
      place: 'knowledge-hub.new-content',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::IntegrationApp': typeof IntegrationAppSyncModal;
    'knowledge-hub/setup-modal/sync-modal/integration-app': typeof IntegrationAppSyncModal;
  }
}
