/* import __COLOCATED_TEMPLATE__ from './brands.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ApplicationInstance from '@ember/application/instance';
import { tracked } from '@glimmer/tracking';
import { request } from 'embercom/lib/inbox/requests';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type { Brand, BrandWireFormat } from './brands/types/brand';
import type { HelpCenter, HelpCenterWireFormat } from './brands/types/help-center';
import type {
  SenderEmailAddressSettings,
  SenderEmailAddressSettingsWireFormat,
} from './brands/types/sender-email-address-settings';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { groupBy } from 'underscore';
import type BrandService from 'embercom/services/brand-service';

interface Args {
  model: any;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class Brands extends Component<Signature> {
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare brandService: BrandService;
  @service declare intercomEventService: $TSFixMe;

  @tracked brands: Brand[] = [];
  @tracked helpCenters: HelpCenter[] = [];
  @tracked senderEmailAddressSettings: SenderEmailAddressSettings[] = [];

  @tracked _isNewSidebarOpen = false;
  @tracked _isEditSidebarOpen = false;
  @tracked editingBrand?: Brand;

  @tracked successModalBrand?: Brand;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    taskFor(this.loadHelpCenters).perform();
    taskFor(this.loadSenderEmailAddressSettings).perform();
    taskFor(this.loadBrands).perform();
  }

  get isLoadBrandsRunning() {
    return taskFor(this.loadBrands).isRunning;
  }

  get isLoadHelpCentersRunning() {
    return taskFor(this.loadHelpCenters).isRunning;
  }

  get isLoadSenderEmailAddressSettingsRunning() {
    return taskFor(this.loadSenderEmailAddressSettings).isRunning;
  }

  get brandRows(): Brand[][] {
    let row: Brand[] = [];
    let rows: Brand[][] = [];

    this.brands.forEach((brand) => {
      row.push(brand);
      if (row.length === 3) {
        rows.push(row);
        row = [];
      }
    });

    if (row.length > 0) {
      rows.push(row);
    }

    return rows;
  }

  get app() {
    return this.appService.app;
  }

  get domains() {
    let customBounceSettings = this.app.customBounceSettings || [];
    let bounceSettingsGrouped = groupBy(customBounceSettings.toArray(), 'rootDomain');
    let dkimSettings = this.app.dkim_settings || [];
    let dkimSettingsGrouped = groupBy(dkimSettings.toArray(), 'domain');

    let allDomains = [
      ...new Set([...Object.keys(bounceSettingsGrouped), ...Object.keys(dkimSettingsGrouped)]),
    ];

    return allDomains.map((domain) => ({
      name: domain,
      isAuthenticated:
        bounceSettingsGrouped[domain]?.[0]?.validRecordExists &&
        dkimSettingsGrouped[domain]?.[0]?.validRecordExists &&
        dkimSettingsGrouped[domain]?.[0]?.validDmarcExists,
    }));
  }

  @dropTask
  *loadBrands(): TaskGenerator<Brand[]> {
    try {
      let response = (yield request(`/ember/brands?app_id=${this.appService.app.id}`).then((r) =>
        r.json(),
      )) as BrandWireFormat[];
      this.brands = response.map((wireFormat) => ({
        id: wireFormat.id,
        name: wireFormat.name,
        logoId: wireFormat.logo_id,
        logoUrl: wireFormat.logo_url,
        helpCenterId: wireFormat.help_center_id,
        senderEmailAddressSettingsId: wireFormat.sender_email_address_settings_id,
        isDefault: wireFormat.is_default,
      }));

      if (!this.brandService.isAppOnRightMultibrandPlan) {
        this.brands = this.brands.filter((brand) => brand.isDefault);
      }

      return this.brands;
    } catch (error) {
      console.error('loadBrands() error', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-load-brands'),
      });
      return [];
    }
  }

  @dropTask
  *loadHelpCenters(): TaskGenerator<HelpCenter[]> {
    try {
      let response = (yield request(
        `/ember/help_center_sites?app_id=${this.appService.app.id}`,
      ).then((r) => r.json())) as HelpCenterWireFormat[];
      this.helpCenters = response.map((wireFormat) => ({
        id: parseInt(wireFormat.id, 10),
        name: wireFormat.name,
      }));
      return this.helpCenters;
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-load-brands'),
      });
      return [];
    }
  }

  @dropTask
  *loadSenderEmailAddressSettings(): TaskGenerator<SenderEmailAddressSettings[]> {
    try {
      let response = (yield request(
        `/ember/sender_email_address_settings?app_id=${this.appService.app.id}`,
      ).then((r) => r.json())) as SenderEmailAddressSettingsWireFormat[];
      this.senderEmailAddressSettings = response.map((wireFormat) => ({
        id: wireFormat.id,
        email: wireFormat.email,
        brandId: wireFormat.brand_id,
        verified: wireFormat.verified,
      }));
      return this.senderEmailAddressSettings;
    } catch (error) {
      console.error('loadSenderEmailAddressSettings() error', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-load-brands'),
      });
      return [];
    }
  }

  @action
  startNewBrand() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `create_new_brand_button`,
      place: 'brands',
      metadata: {
        brand_type: 'non-default',
      },
    });
    this._isNewSidebarOpen = true;
  }

  get isNewSidebarOpen() {
    return this._isNewSidebarOpen;
  }

  @action
  closeNewSidebar(brand: Brand | undefined) {
    this._isNewSidebarOpen = false;

    if (brand) {
      this.brands.push(brand);
      this.brands = this.brands; // force refresh
      this.successModalBrand = brand;
    }
  }

  @action
  startEditBrand(brand: Brand) {
    this._isEditSidebarOpen = true;
    this.editingBrand = brand;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `edit_brand_button`,
      place: 'brands',
      metadata: {
        brand,
        is_default_brand: brand.isDefault,
        brand_type: brand.isDefault ? 'default' : 'non-default',
      },
    });
  }

  get isEditSidebarOpen() {
    return this._isEditSidebarOpen;
  }

  @action
  closeEditSidebar(brand: Brand | undefined) {
    this._isEditSidebarOpen = false;
    this.editingBrand = undefined;

    if (brand) {
      let index = this.brands.findIndex((b) => b.id === brand.id);
      this.brands[index] = brand;
      this.brands = this.brands; // force refresh
    }
  }

  @action
  handleNewHelpCenter(helpCenter: HelpCenter) {
    this.helpCenters = [...this.helpCenters, helpCenter];
  }

  @action
  handleNewSenderEmailAddressSettings(senderEmailAddressSettings: SenderEmailAddressSettings) {
    this.senderEmailAddressSettings = [
      ...this.senderEmailAddressSettings,
      senderEmailAddressSettings,
    ]; // force refresh
  }

  @action
  handleCloseSuccessModal() {
    this.successModalBrand = undefined;
  }

  @action brandDeleted() {
    this._isEditSidebarOpen = false;
    taskFor(this.loadBrands).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands': typeof Brands;
  }
}
