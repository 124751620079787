/* import __COLOCATED_TEMPLATE__ from './brand-color-input.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}
interface Args {
  colorIndex: number;
  value: string;
  site: HelpCenterSite;
}

export default class BrandColorInput extends Component<Signature> {
  @tracked currentColor =
    this.args.site.customizationOptions.global.brand.colors[this.args.colorIndex];

  @action
  setBrandColor(color: string) {
    // to prevent an infinite loop when hex value is updated via text input
    if (this.currentColor === color) {
      return;
    }
    this.currentColor = color;
    this.args.site.customizationOptions.global.brand.colors[this.args.colorIndex] = color;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Appearance::BrandColorInput': typeof BrandColorInput;
    'settings/appearance/brand-color-input': typeof BrandColorInput;
  }
}
