/* RESPONSIBLE TEAM: team-actions */
export default (appModel, router) => {
  if (!appModel) {
    return false;
  }

  let currentRouteName = router.get('currentRouteName');
  if (currentRouteName && currentRouteName.includes('apps.app.developer-hub')) {
    return false;
  }

  return appModel.get('isTestApp') || appModel.get('isDeveloperWorkspace');
};
