/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr } from '@ember-data/model';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import generateUUID from 'embercom/lib/uuid-generator';
import { fragment } from 'ember-data-model-fragments/attributes';
import type ActionOutputParameterDescriptor from 'embercom/models/workflow-connector/action-output-parameter-descriptor';
import { buildValidations, validator } from 'ember-cp-validations';

export const DEFAULT_REQUEST = `{\n  "data": \n    {\n      "example_key": "example value (please edit)"\n    }\n}`;
const Validations = buildValidations({
  name: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.wait-for-callback.name',
    }),
  ],
  exampleRequest: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.wait-for-callback.example-request',
    }),
  ],
});

export default class WaitForCallback extends Step.extend(Validations) {
  @attr('string') declare name: string;
  @attr('string') declare externalStepId: string;
  @attr('string') declare exampleRequest: string;
  @attr('string') declare webhookUrlParams: string;
  @attr('string') declare asyncCallbackAttributeDescriptorIdentifier: string;
  @fragment('workflow-connector/action-output-parameter-descriptor')
  declare asyncCallbackOutputParameterDescriptor: ActionOutputParameterDescriptor;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.waitForCallback;
  }

  static get isStepGroupEnding() {
    return false;
  }

  static createNewStep(store: Store): WaitForCallback {
    return store.createRecord('operator/visual-builder/step/wait-for-callback', {
      type: 'operator/visual-builder/step/wait-for-callback',
      externalStepId: generateUUID(),
    });
  }
}
