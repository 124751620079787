/* import __COLOCATED_TEMPLATE__ from './styles.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import PALETTE from '@intercom/pulse/lib/palette';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  containerSelector: null,
  hasCustomBaseColor: notEmpty('customBaseColor'),
  baseColor: ternaryToProperty(
    'hasCustomBaseColor',
    'customBaseColor',
    'app.application_colors.base',
  ),
  hasCustomHoverColor: notEmpty('customHoverColor'),
  hoverColor: ternaryToProperty(
    'hasCustomHoverColor',
    'customHoverColor',
    'app.application_colors.hover',
  ),
  hasCustomDarkerColor: notEmpty('customDarkerColor'),
  darkerColor: ternaryToProperty(
    'hasCustomDarkerColor',
    'customDarkerColor',
    'app.application_colors.darker',
  ),
  paletteBlue: PALETTE['text-link'],
});
