/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { Channel } from 'embercom/models/data/inbox/channels';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

const NameMap: Record<Channel, string> = {
  [Channel.Unknown]: '',
  [Channel.Desktop]: 'inbox.helpers.channel-name.chat',
  [Channel.Android]: 'inbox.helpers.channel-name.android',
  [Channel.IOS]: 'inbox.helpers.channel-name.ios',
  [Channel.Email]: 'inbox.helpers.channel-name.email',
  [Channel.Twitter]: 'inbox.helpers.channel-name.twitter',
  [Channel.Facebook]: 'inbox.helpers.channel-name.facebook',
  [Channel.FacebookMessenger]: 'inbox.helpers.channel-name.facebook',
  [Channel.Whatsapp]: 'inbox.helpers.channel-name.whatsapp',
  [Channel.Instagram]: 'inbox.helpers.channel-name.instagram',
  [Channel.PhoneSwitch]: 'inbox.helpers.channel-name.phone-switch',
  [Channel.SMS]: 'inbox.helpers.channel-name.sms',
  [Channel.PhoneCall]: 'inbox.helpers.channel-name.phone',
  [Channel.ZendeskTicket]: 'inbox.helpers.channel-name.zendesk-ticket',
  [Channel.ZendeskSunshineConversation]: 'inbox.helpers.channel-name.zendesk-sunshine-conversation',
  [Channel.SalesforceCase]: 'inbox.helpers.channel-name.salesforce-case',
};

export default class LookupChannelName extends Helper<{
  Args: {
    Positional: [Channel | undefined];
  };
  Return: string;
}> {
  @service declare intl: IntlService;

  compute(params: [Channel]) {
    let [channel] = params;

    return NameMap[channel] ? this.intl.t(NameMap[channel]) : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'lookup-channel-name': typeof LookupChannelName;
  }
}
