/* import __COLOCATED_TEMPLATE__ from './app-store-option.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  icon: InterfaceIconName;
  type: 'card' | 'menu-item';
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class AppStoreOption extends Component<Signature> {
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  get isActive() {
    let regex = new RegExp(
      String.raw`/apps\/${this.appService.app.id}/settings/app-settings/app-store`,
    );
    return Boolean(this.router.currentURL.match(regex));
  }

  get canUseMigratedAppStore() {
    return this.appService.app.canUseMigratedAppStore;
  }

  @action
  onClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      // eslint-disable-next-line @intercom/intercom/require-snake-case-analytics
      object: 'browse-all-apps',
      place: 'settings',
      section: 'settings',
      context: this.args.type,
    });
    if (this.canUseMigratedAppStore) {
      this.router.transitionTo('apps.app.settings.app-settings.app-store', this.appService.app.id);
    } else {
      let url = this.router.urlFor('apps.app.appstore');
      safeWindowOpen(url, '_blank');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::AppSettings::AppStoreOption': typeof AppStoreOption;
    'new-settings/app-settings/app-store-option': typeof AppStoreOption;
  }
}
