/* import __COLOCATED_TEMPLATE__ from './lite-seat-to-full-seat-warning.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type Plan from 'embercom/models/plan';

export interface Args {
  plan: Plan;
  liteSeatNumber: number;
}
interface Signature {
  Args: Args;
  Blocks: any;
}

const LiteSeatToFullSeatWarning = templateOnlyComponent<Signature>();
export default LiteSeatToFullSeatWarning;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::LiteSeatToFullSeatWarning': typeof LiteSeatToFullSeatWarning;
    'signup/teams/pricing5/lite-seat-to-full-seat-warning': typeof LiteSeatToFullSeatWarning;
  }
}
