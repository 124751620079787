/* import __COLOCATED_TEMPLATE__ from './email-settings.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  model: $TSFixMe;
}
export default class EmailSettings extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  place = 'email-settings-tab';

  get inboxEmailAddress() {
    return this.appService.app.inbox_email;
  }

  @action
  trackCopyButtonClick() {
    this.trackClickEvent('copy_intercom_email_address');
  }

  @action
  trackClickEvent(event: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: event,
      place: this.place,
      context: 'settings-email',
    });
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::EmailSettings': typeof EmailSettings;
  }
}
