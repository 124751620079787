/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { INCLUDED_TIERS_FROM_PRICING_ENDPOINT } from 'embercom/lib/billing';
import { perform } from 'ember-concurrency-ts';

import { type TaskGenerator } from 'ember-concurrency';
import { type Router } from '@ember/routing';
import type Store from '@ember-data/store';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type Contract from 'embercom/models/billing/contract';

export type BillingSummaryRouteModel = Contract | undefined;

export default class BillingSummaryRoute extends Route {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: Router;

  get app() {
    return this.appService.app;
  }

  get subscription() {
    return this.customerService.customer.subscription;
  }

  beforeModel() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'billing_summary_page',
    });
  }

  get customerInEarlyStageRejectedState() {
    return (
      (this.cardlessTrialService.canUseEarlyStageDetection || this.app.onPricing5) &&
      this.app.earlyStageApplicationRejected &&
      (this.customerService.customer?.inSelfServeTrial ||
        (this.customerService.customer?.inExpiredSelfServeTrial &&
          this.app.hasNoActiveSubscription))
    );
  }

  redirect() {
    if (this.customerInEarlyStageRejectedState) {
      return this.router.transitionTo('apps.app.billing.index');
    }
    if (
      (!this.app.isOnValueBasedPricing &&
        !this.app.onPricing5 &&
        !this.app.isSalesforceContracted &&
        !this.customerService.get('earlyStageGraduation.vbpGraduation') &&
        !(
          this.app.canUsePerProductPricingFlow &&
          !this.customerService.customer.currentEarlyStageCustomer
        ) &&
        !this.subscription?.isOnTprPricingModel) ||
      this.customerService.customer.hasCustomPricing
    ) {
      return this.router.transitionTo('apps.app.settings.subscription.billing.details');
    }

    return false;
  }

  async model(): Promise<BillingSummaryRouteModel> {
    if (this.app.isSalesforceContracted || this.customerService.isSelfServeAnnualCustomer) {
      return await this.store.findRecord('billing/contract', this.app.id);
    }

    return;
  }

  afterModel() {
    let loadTask = perform(this.loadData);
    this.customerService.seatUsageUpdated = false;
    return loadTask;
  }

  @task
  *loadData(): TaskGenerator<void> {
    yield this.customerService.loadData({
      customer: this.customerService.customer,
      reusePricesIfPossible: !this.customerService.seatUsageUpdated,
      fetchPrices: this._shouldFetchPrices,
      fetchCurrentPricesOnly: this._shouldFetchCurrentPricesOnly,
      includeTiers: this._shouldFetchPrices && INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
      overrideCanSeePrice: this.appService.app.isSalesforceContracted,
      includePlanCombinationValidation: this._shouldFetchPrices,
      source: 'billing-summary-route',
      useBillingCycleMetricVariations:
        this.customerService.customer.showBillingCycleMetricsInBillingSummary,
      useShiftedCycleMetricVariations: this.customerService.customer.shiftedCycleMetricsAvailable,
    });

    yield this.customerService.getStripeMigration();
  }

  get _shouldFetchPrices() {
    return (
      !this.customerService.customer.hasCustomPricing || this.appService.app.isSalesforceContracted
    );
  }

  get _shouldFetchCurrentPricesOnly() {
    return this.appService.app.onPricing5;
  }
}
