/* import __COLOCATED_TEMPLATE__ from './recommendations.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';

export default Component.extend({
  tagName: '',
  appstoreService: service(),
  appService: service(),
  router: service(),
  intercomEventService: service(),
  isBootedInPublicAppStoreMode: EmbercomBootDataModule.isBootedInPublicAppStoreMode(),

  init() {
    this._super(...arguments);
    if (!EmbercomBootDataModule.isBootedInPublicAppStoreMode()) {
      let recentlyViewedAppPackageIds = this.recentlyViewed || [];
      let recentlyViewedAppPackages = recentlyViewedAppPackageIds.map((id) =>
        this.appstoreService.peekOrFindAppPackage(id),
      );
      this.set('recentlyViewedApps', recentlyViewedAppPackages);
      recentlyViewedAppPackageIds
        .slice(0, 2)
        .forEach((id) => this._fetchRecommendations.perform(id));
    }
  },

  _fetchRecommendations: task(function* (appPackageId) {
    let recommendationIds = yield ajax({
      url: '/ember/app_package_recommendations',
      type: 'GET',
      data: {
        app_package_code: appPackageId,
        app_id: this.get('appService.app.id'),
        count: 10,
      },
    });
    let recommendations = recommendationIds.map((id) =>
      this.appstoreService.peekOrFindAppPackage(id),
    );
    let historyBasedRecommendations = this.historyBasedRecommendations || [];
    historyBasedRecommendations.push({
      sourceAppPackage: this.appstoreService.peekOrFindAppPackage(appPackageId),
      recommendations,
    });
    this.set('historyBasedRecommendations', historyBasedRecommendations);
  }),

  onRecommendationAppPackageClick: action(function (sourceAppPackageName, appPackage) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: appPackage,
      category_name: 'history_recommendations',
      source_app_package: sourceAppPackageName,
    });
    this.router.transitionTo({ queryParams: { app_package_code: appPackage.get('id') } });
  }),
});
