/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { DEFAULT_RESOLUTIONS_REMINDER_THRESHOLD } from 'embercom/routes/apps/app/settings/subscription/billing/manage-usage';

export default class ManageUsageController extends Controller {
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  queryParams = [];

  declare model: {
    usageReminder: $TSFixMe;
    finUsageLimit: $TSFixMe;
    currentUsage: $TSFixMe;
    contract: $TSFixMe;
  };

  get getUsageReminder() {
    return this.model.usageReminder;
  }

  get getFinUsageLimit() {
    return this.model.finUsageLimit;
  }

  @tracked usageReminder = this.getUsageReminder;
  @tracked finUsageLimit = this.getFinUsageLimit;
  @tracked onToggleOnNewUsageReminder = null;

  get app() {
    return this.appService.app;
  }

  @action
  onDestroyUsageReminder() {
    this._createDefaultUsageReminder();
  }

  _createDefaultUsageReminder() {
    this.usageReminder.unloadRecord();

    this.usageReminder = this.store.createRecord('usage-reminder', {
      threshold: this._defaultThreshold,
      usageType: 'resolutions',
    });
  }

  get _defaultThreshold() {
    if (this.app.isSalesforceContracted) {
      if (this.app.usesResolutionsWithCustomAnswers) {
        return this.model.contract.contractUsageLimits.resolutions_with_custom_answers;
      }

      return this.model.contract.contractUsageLimits.resolutions;
    } else {
      return DEFAULT_RESOLUTIONS_REMINDER_THRESHOLD;
    }
  }
}
