/* import __COLOCATED_TEMPLATE__ from './phone-regulatory-bundle.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { get, post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { PhoneNumberTypeToTwilioString } from 'embercom/models/settings/calling';
import { type RegulatoryBundleArgs } from './phone-regulatory-bundle/phone-number-type-section';
import ENV from 'embercom/config/environment';
import { type UploadedSupportingDocument } from './phone-regulatory-bundle/supporting-document-modal';
import type RouterService from '@ember/routing/router-service';

interface PhoneRegulatoryBundleArgs {
  regulatoryBundle: RegulatoryBundleArgs;
  endUser: any;
  supportingDocuments: any;
  readOnly?: boolean;
  isEditing?: boolean;
}

interface Signature {
  Element: Element;
  Args: PhoneRegulatoryBundleArgs;
}
export interface Stamp {
  text: string;
  color: string;
}

export const STAMP_COLORS: any = {
  Draft: 'yellow-light',
  Submitted: 'sky',
  Approved: 'green',
  Rejected: 'red',
};

export default class PhoneRegulatoryBundle extends Component<Signature> {
  @service declare appService: any;
  @service notificationsService: any;
  @service intl: any;
  @service declare router: RouterService;
  @service panelActions: any;

  @tracked requirements: any;

  @tracked bundleSid?: string = this.args.regulatoryBundle.sid;
  @tracked endUserSid?: string = this.args.endUser.sid;
  @tracked openSectionId = 'phone_number_type_section';
  @tracked phoneNumberTypeStamp: Stamp = this.sectionStamps.incomplete;
  @tracked endUserStamp: Stamp = this.sectionStamps.incomplete;
  @tracked supportingDocumentsStamp: Stamp = this.sectionStamps.incomplete;
  @tracked satisfiedRequirements: any = {};
  @tracked supportingDocumentsOption = 0;
  @tracked submitErrors: string[] = [];
  @tracked fileUploadNeeded = true;

  @tracked showDeleteModal = false;

  constructor(owner: unknown, args: PhoneRegulatoryBundleArgs) {
    super(owner, args);

    if (this.bundleSid) {
      this.phoneNumberTypeStamp = this.sectionStamps.complete;
      taskFor(this.fetchRegulatoryRequirements).perform();
    }

    if (this.endUserSid) {
      this.endUserStamp = this.sectionStamps.complete;
    }

    if (this.args.readOnly) {
      setTimeout(() => {
        this.showAll();
      });
    }

    if (this.args.isEditing) {
      this.openSectionId = 'end_user_section';
    }
  }

  get bundleStatus() {
    return (
      this.args.regulatoryBundle.status ||
      this.intl.t('calling.settings.phone-regulatory-bundle.draft')
    );
  }

  get bundleColor() {
    return STAMP_COLORS[this.bundleStatus];
  }

  get endUserNeeded() {
    if (!this.requirements) {
      return true;
    }

    return !!this.requirements.end_user?.firstObject;
  }

  get supportingDocumentsNeeded() {
    if (!this.requirements) {
      return true;
    }

    return this.transformedSupportingDocumentRequirements.firstObject?.length > 0;
  }

  get sectionStamps() {
    return {
      incomplete: {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.incomplete'),
        color: 'gray',
      },
      complete: {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.complete'),
        color: 'green',
      },
    };
  }

  get readyToSubmit() {
    return (
      this.phoneNumberTypeStamp.text === this.sectionStamps.complete.text &&
      this.endUserStamp.text === this.sectionStamps.complete.text &&
      this.supportingDocumentsStamp.text === this.sectionStamps.complete.text
    );
  }

  get rejectionText() {
    return `${this.intl.t('calling.settings.phone-regulatory-bundle.rejected-text')}\r\n${
      this.args.regulatoryBundle.failureReason
    }`;
  }

  computeSatisfiedRequirements() {
    this.satisfiedRequirements = {};
    this.args.supportingDocuments.forEach((uploadedDocument: UploadedSupportingDocument) => {
      this.requirementsSatisfiedByUploadedDocument(uploadedDocument).forEach(
        (requirementName: string) => {
          this.satisfiedRequirements[requirementName] = true;
        },
      );
    });
    set(this, 'satisfiedRequirements', this.satisfiedRequirements);
    if (this.sectionComplete) {
      set(this, 'supportingDocumentsStamp', this.sectionStamps.complete);
    } else {
      set(this, 'supportingDocumentsStamp', this.sectionStamps.incomplete);
    }
  }

  requirementsSatisfiedByUploadedDocument(uploadedDocument: any) {
    let requirementNames: string[] = [];

    this.transformedSupportingDocumentRequirements[this.supportingDocumentsOption]?.forEach(
      (document: any) => {
        document.accepted_documents.forEach((acceptedDocument: any) => {
          if (acceptedDocument.type === uploadedDocument.type) {
            if (acceptedDocument.fields.includes('address_sids')) {
              if (this.doesUploadedDocumentHaveAddress(uploadedDocument)) {
                requirementNames.addObject(document.requirement_name);
              }
            } else {
              requirementNames.addObject(document.requirement_name);
            }
          }
        });
      },
    );

    return requirementNames;
  }

  doesUploadedDocumentHaveAddress(document: UploadedSupportingDocument) {
    return document.attributes.address_sids?.length > 0;
  }

  @action
  onSupportingDocumentCreated(uploadedSupportingDocument: UploadedSupportingDocument) {
    this.args.supportingDocuments.addObject(uploadedSupportingDocument);
    this.computeSatisfiedRequirements();
  }

  @action
  onSupportingDocumentDeleted(supportingDocument: UploadedSupportingDocument) {
    let index = this.args.supportingDocuments.findIndex(
      (doc: UploadedSupportingDocument) => doc.sid === supportingDocument.sid,
    );
    this.args.supportingDocuments.removeAt(index);
    this.computeSatisfiedRequirements();
  }

  get sectionComplete() {
    return this.transformedSupportingDocumentRequirements[this.supportingDocumentsOption]?.every(
      (requirement: any) => {
        return this.satisfiedRequirements[requirement.requirement_name];
      },
    );
  }

  @restartableTask *fetchRegulatoryRequirements(): TaskGenerator<void> {
    this.requirements = yield get('/ember/calling_settings/fetch_regulatory_requirements', {
      country_code: this.args.regulatoryBundle.countryCode,
      number_type: PhoneNumberTypeToTwilioString.get(this.args.regulatoryBundle.phoneNumberType),
      app_id: this.appService.app.id,
    });
    if (!this.endUserNeeded) {
      this.endUserStamp = this.sectionStamps.complete;
    }
    if (!this.supportingDocumentsNeeded) {
      this.supportingDocumentsStamp = this.sectionStamps.complete;
    }
    this.setIsFileUploadNeeded();
    this.computeSatisfiedRequirements();
  }

  // This is a bit of a hack to get the correct requirements for the selected supporting documents option
  // We need to filter out the proof_of_identity requirement for UK:CRN
  get transformedSupportingDocumentRequirements() {
    if (!this.fileUploadNeeded) {
      return [
        this.requirements.supporting_document[this.supportingDocumentsOption].filter(
          (doc: any) => doc.requirement_name !== 'proof_of_identity',
        ),
      ];
    } else {
      return this.requirements.supporting_document;
    }
  }

  get isSms() {
    return this.router.currentRouteName.includes('settings.channels.sms.phone-regulatory-bundles');
  }

  @dropTask *submitBundle(): TaskGenerator<void> {
    try {
      this.submitErrors = [];
      let response = yield post('/ember/calling_settings/submit_regulatory_bundle', {
        bundle_sid: this.bundleSid,
        app_id: this.appService.app.id,
        for_sms: this.isSms,
      });
      if (response.success) {
        this.notificationsService.notifyConfirmation(
          `${this.args.regulatoryBundle.friendlyName} ${this.intl.t(
            'calling.settings.phone-regulatory-bundle.submit-success',
          )}`,
          ENV.APP._30000MS,
        );
        this.back();
      } else {
        this.submitErrors = response.error.split('\n');
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.phone-regulatory-bundle.submit-error'),
      );
    }
  }

  @dropTask *deleteBundle(): TaskGenerator<void> {
    try {
      yield post('/ember/calling_settings/delete_regulatory_bundle', {
        bundle_sid: this.bundleSid,
        app_id: this.appService.app.id,
        for_sms: this.isSms,
      });
      this.showDeleteModal = false;
      this.notificationsService.notifyConfirmation(
        `${this.args.regulatoryBundle.friendlyName} ${this.intl.t(
          'calling.settings.phone-regulatory-bundle.delete-success',
        )}`,
      );
      this.back();
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.phone-regulatory-bundle.delete-error'),
      );
    }
  }

  @dropTask
  *discardBundle(): TaskGenerator<void> {
    if (this.bundleSid) {
      yield taskFor(this.deleteBundle).perform();
    }

    this.back();
  }

  @action
  back() {
    let route = 'apps.app.settings.channels.phone.phone-regulatory-bundles.index';
    if (this.isSms) {
      route = 'apps.app.settings.channels.sms.phone-regulatory-bundles.index';
    }

    this.router.transitionTo(route);
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action
  onBundleCreated(bundleSid: string) {
    this.bundleSid = bundleSid;
    set(this, 'phoneNumberTypeStamp', this.sectionStamps.complete);
    taskFor(this.fetchRegulatoryRequirements).perform();
    this.onOpenSectionChange('end_user_section');
  }

  @action
  onEndUserCreated(endUserSid: string) {
    this.endUserSid = endUserSid;
    this.setIsFileUploadNeeded();
    set(this, 'endUserStamp', this.sectionStamps.complete);
    this.onOpenSectionChange('supporting_documents_section');
  }

  @action
  onEndUserCreationFailed() {
    set(this, 'endUserStamp', this.sectionStamps.incomplete);
  }

  @action
  onBundleCreationFailed() {
    set(this, 'phoneNumberTypeStamp', this.sectionStamps.incomplete);
  }

  @action
  showAll() {
    this.panelActions.openAll('phone-regulatory-bundle-accordion');
  }

  /*
    We don't need to upload any documents for UK:CRN
    https://help.twilio.com/articles/21038555454875
  */
  setIsFileUploadNeeded() {
    this.fileUploadNeeded = this.args.endUser['business_registration_identifier'] !== 'UK:CRN';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneRegulatoryBundle': typeof PhoneRegulatoryBundle;
    'calling/settings/phone-regulatory-bundle': typeof PhoneRegulatoryBundle;
  }
}
