/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import type ArticleContent from 'embercom/models/articles/article-content';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';

interface Args {
  activeContent: ArticleContent;
  editActiveContent: () => void;
  unpublishActiveContent: () => void;
  showSidePanel: boolean;
  toggleSidePanel: () => void;
  publishActiveContent: () => Promise<void>;
}

export default class Header extends Component<Args> {
  closeSidePanelOnResize = true;

  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare permissionsService: any;
  @tracked showDeleteConfirmModal = false;

  @action closeSidePanel(header: HTMLElement) {
    let headerHasScroll = header.offsetWidth < header.scrollWidth;
    let closeSidePanel = headerHasScroll && this.closeSidePanelOnResize && this.args.showSidePanel;
    this.closeSidePanelOnResize = !headerHasScroll && this.args.showSidePanel;
    if (closeSidePanel) {
      // schedule the side panel to close after it finishes opening, to avoid a race condition
      schedule('afterRender', () => {
        this.args.toggleSidePanel();
      });
    }
  }

  @action async showDeleteConfirmationModal() {
    let activeContent = this.knowledgeHubEditorService.activeContentModel;

    try {
      await this.permissionsService.checkPermission(
        activeContent.requiredDeletePermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }
    this.showDeleteConfirmModal = true;
  }

  @dropTask
  *deleteActiveContent() {
    this.showDeleteConfirmModal = false;
    yield this.knowledgeHubEditorService.deleteActiveContent();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::View::Header': typeof Header;
    'knowledge-hub/content-editor/article-content/view/header': typeof Header;
  }
}
