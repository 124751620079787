/* RESPONSIBLE TEAM: team-ai-agent */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { inject as service } from '@ember/service';

export default ContentEditorRoute.extend({
  router: service(),

  redirect(model) {
    this.router.replaceWith('apps.app.fin-ai-agent.profiles.edit', model.id);
  },
});
