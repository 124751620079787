/* import __COLOCATED_TEMPLATE__ from './search-input.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ajax from 'embercom/lib/ajax';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';

const DEBOUNCE_MS = 250;

export default class SearchInput extends Component {
  @tracked searchSuggestions = [];
  @service appService;
  @service intercomEventService;

  get target() {
    return this.args?.targetedJump;
  }

  @task({ restartable: true })
  *updateSearchSuggestions(searchTerm) {
    yield timeout(DEBOUNCE_MS);
    this.searchSuggestions = yield this.fetchSearchSuggestions.perform(searchTerm);
    this.jumpToTarget();
  }

  @task({ restartable: true })
  *fetchSearchSuggestions(searchTerm) {
    let response = yield ajax({
      url: `${EmbercomBootDataModule.getApiUrl()}/appstore/search_terms?query=${searchTerm}`,
      dataType: 'json',
    });
    return this.parseItemsFromSuggestionsResponse(response);
  }

  parseItemsFromSuggestionsResponse(response) {
    if (response) {
      return response.map((item) => EmberObject.create({ name: item }));
    }
    return [];
  }

  jumpToTarget() {
    if (this.target) {
      document.querySelector(this.target).scrollIntoView();
    }
  }

  trackAnalyticClickEvent(item) {
    if (!item.get('isStandardSearch')) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'search_suggestion',
        search_term: item.name,
      });
    }
  }

  @action
  doSearch(item) {
    this.searchSuggestions = [];
    this.trackAnalyticClickEvent(item);
    this.args.handleSearch(item.name);
  }
  @action
  resetSearch() {
    this.args.handleSearch(null);
    this.searchSuggestions = [];
  }
  @action
  searchStringChanged(searchTerm) {
    if (searchTerm && searchTerm.length >= 2) {
      this.updateSearchSuggestions.perform(searchTerm);
    } else {
      this.searchSuggestions = [];
    }
  }
}
