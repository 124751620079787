/* import __COLOCATED_TEMPLATE__ from './phone-regulatory-bundle-selector.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { get } from 'embercom/lib/ajax';
import {
  PhoneNumberTypeToTwilioString,
  PHONE_NUMBER_COUNTRY_DETAILS,
  type PhoneNumberType,
} from 'embercom/models/settings/calling';
import type IntlService from 'embercom/services/intl';
import { type Stamp } from './phone-regulatory-bundle';

enum RegulatoryBundleStatus {
  Draft = 'draft',
  PendingReview = 'pending-review',
  Rejected = 'rejected',
  Approved = 'approved',
}

export interface RegulatoryBundle {
  sid: string;
  country_code: string;
  number_type: string;
  friendly_name: string;
  status: RegulatoryBundleStatus;
}

export interface StampedItem {
  text: string;
  value: string;
  component: string;
}

interface Args {
  setRegulatoryBundle: (regulatoryBundleSid: string) => void;
  selectedRegulatoryBundleSid: string | undefined;
  selectedCountry: string | undefined;
  selectedPhoneNumberType: PhoneNumberType | undefined;
}

interface Signature {
  Args: Args;
}

export default class PhoneRegulatoryBundleSelector extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare router: Router;

  @tracked allRegulatoryBundles?: RegulatoryBundle[];

  get selectedBundleId() {
    return this.args.selectedRegulatoryBundleSid || undefined;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.fetchRegulatoryBundles).perform();
  }

  statusToStamp(status: RegulatoryBundleStatus) {
    return {
      draft: {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.draft'),
        color: 'yellow-light',
      } as Stamp,
      'pending-review': {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.pending-review'),
        color: 'sky',
      } as Stamp,
      approved: {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.approved'),
        color: 'green',
      } as Stamp,
      rejected: {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.rejected'),
        color: 'red',
      } as Stamp,
    }[status];
  }

  @restartableTask *fetchRegulatoryBundles(): TaskGenerator<void> {
    this.allRegulatoryBundles = yield get(`/ember/calling_settings/fetch_regulatory_bundles`, {
      app_id: this.appService.app.id,
    });
  }

  get regulatoryBundles() {
    return this.allRegulatoryBundles
      ?.filter((bundle: RegulatoryBundle) => {
        if (bundle.status !== RegulatoryBundleStatus.Approved) {
          return false;
        }

        if (bundle.country_code !== this.args.selectedCountry) {
          return false;
        }

        if (
          bundle.number_type !==
          PhoneNumberTypeToTwilioString.get(this.args.selectedPhoneNumberType as PhoneNumberType)
        ) {
          return false;
        }

        return true;
      })
      .map((bundle: RegulatoryBundle) => {
        let flag = PHONE_NUMBER_COUNTRY_DETAILS.get(bundle.country_code)?.flag;
        let stamp = this.statusToStamp(bundle.status);

        return {
          text: bundle.friendly_name,
          value: bundle.sid,
          component: 'calling/settings/phone-number-provisioning/stamped-select-item',
          componentAttrs: {
            showFlag: true,
            flag,
            stampText: stamp?.text,
            stampColor: stamp?.color,
          },
        };
      });
  }

  @action
  onNewRegulatoryBundleClicked() {
    let route = 'apps.app.settings.channels.phone.phone-regulatory-bundles.new';
    this.router.transitionTo(route, {
      queryParams: {
        countryCode: this.args.selectedCountry,
        phoneNumberType: this.args.selectedPhoneNumberType,
      },
    });
  }

  @action
  onSelectedRegulatoryBundle(value: string) {
    this.args.setRegulatoryBundle(value);
  }

  get fetchingRegulatoryBundles() {
    return taskFor(this.fetchRegulatoryBundles).isRunning;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneRegulatoryBundleSelector': typeof PhoneRegulatoryBundleSelector;
    'calling/settings/phone-regulatory-bundle-selector': typeof PhoneRegulatoryBundleSelector;
  }
}
