/* import __COLOCATED_TEMPLATE__ from './zendesk-integration.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnly from '@ember/component/template-only';

export interface Args {
  selectedAccordion: string;
  onAccordionChange: (accordion: string) => void;
}

let ZendeskIntegration = templateOnly<Args>();

export default ZendeskIntegration;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup::ZendeskIntegration': typeof ZendeskIntegration;
  }
}
