/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import ajax from 'embercom/lib/ajax';

// The data in this model is permanently saved on the server side. This model acts as a container for retrieving
// the dynamic portion of the data for the integrations hub.
export default Model.extend({
  appId: attr('string'),
  createdAt: attr('date'),
  integration: belongsTo('integration'),
}).reopenClass({
  hasIntegrations(app, integrationCodes) {
    return ajax({
      url: `/ember/connected_integrations/integration_status`,
      type: 'GET',
      data: {
        app_id: app.get('id'),
        codes: integrationCodes,
      },
    });
  },

  hasIntegration(app, integrationCode) {
    return this.hasIntegrations(app, [integrationCode]).then((result) => result[integrationCode]);
  },
});
