/* import __COLOCATED_TEMPLATE__ from './basic-javascript.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
interface Args {
  userSource: string;
  apiSecret: string;
  selectedProgrammingLanguage: string;
  highlightLanguage: string;
  userIdentifier: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class BasicJavascript extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  get segmentExamples() {
    let apiSecret = this.args.apiSecret;
    return {
      rails: {
        userId: '<%= current_user.id %>',
        userHash: `<%= OpenSSL::HMAC.hexdigest("sha256", "${apiSecret}", current_user.id.to_s) %>`,
      },
      django: {
        userId: '{{ request.user.id|escapejs }}',
        userHash: `{{ hmac.new(b'${apiSecret}', bytes(request.user.id, encoding='utf-8'), digestmod=hashlib.sha256).hexdigest() }}`,
      },
      php: {
        userId: '<?php echo json_encode($user->id); ?>',
        userHash: `<?php echo hash_hmac("sha256", $user->id, "${apiSecret}"); ?>`,
      },
      node: {
        userId: 'user.id',
        userHash: `crypto.createHmac('sha256', '${apiSecret}').update(user.id).digest('hex')`,
      },
    };
  }

  get selectedExample() {
    return this.segmentExamples[
      this.args.selectedProgrammingLanguage as keyof typeof this.segmentExamples
    ];
  }

  @action
  onCopyCode() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'copy_code_snippet',
      metadata: {
        is_idv: true,
        identifier: this.args.userIdentifier,
        app_type: 'basic_javascript',
        programming_language: this.args.selectedProgrammingLanguage,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::GenerateHmac::BasicJavascript': typeof BasicJavascript;
    'installation-new/generate-hmac/basic-javascript': typeof BasicJavascript;
  }
}
