/* import __COLOCATED_TEMPLATE__ from './phone-number-type-section.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import Countries from 'embercom/lib/countries';
import { countrySelect } from 'embercom/helpers/country-select-helper';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import { type TaskGenerator } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import {
  PHONE_NUMBER_COUNTRY_DETAILS,
  type PhoneNumberType,
  PhoneNumberTypeToTwilioString,
} from 'embercom/models/settings/calling';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import type RouterService from '@ember/routing/router-service';

export interface RegulatoryBundleArgs {
  status?: string;
  sid?: string;
  id?: number;
  countryCode: string;
  phoneNumberType: PhoneNumberType;
  friendlyName: string;
  failureReason?: string;
}

export interface PhoneNumberTypeSectionArgs {
  regulatoryBundle: RegulatoryBundleArgs;
  readOnly?: boolean;
  onBundleCreated: (bundleSid: string) => void;
  onBundleCreationFailed: () => void;
}

export default class PhoneNumberTypeSection extends Component<PhoneNumberTypeSectionArgs> {
  @service intl: any;
  @service declare appService: any;
  @service notificationsService: any;
  @service declare router: RouterService;

  @tracked selectedCountry = this.args.regulatoryBundle.countryCode;

  @action
  selectCountry(country: string) {
    this.selectedCountry = country;
    this.args.regulatoryBundle.countryCode = this.selectedCountry;
  }

  get countries() {
    return countrySelect(Countries.allCountries);
  }

  get countriesList() {
    return Array.from(PHONE_NUMBER_COUNTRY_DETAILS.entries())
      .filter(([_, details]) => !!details.requiringBundleTypes.length)
      .map(([countryCode]) => ({
        text: this.intl.t(`calling.settings.phone-calls.countries.${countryCode.toUpperCase()}`),
        value: countryCode,
      }));
  }

  get phoneNumberTypesSupportedBySelectedCountry() {
    if (!this.selectedCountry) {
      return [];
    }

    return PHONE_NUMBER_COUNTRY_DETAILS.get(this.selectedCountry)?.requiringBundleTypes || [];
  }

  get isSms() {
    return this.router.currentRouteName.includes('settings.channels.sms.phone-regulatory-bundles');
  }

  @restartableTask
  *nextSection(): TaskGenerator<void> {
    try {
      let response = yield post('/ember/calling_settings/create_regulatory_bundle', {
        app_id: this.appService.app.id,
        country_code: this.selectedCountry,
        number_type: PhoneNumberTypeToTwilioString.get(this.args.regulatoryBundle.phoneNumberType),
        friendly_name: this.args.regulatoryBundle.friendlyName,
        for_sms: this.isSms,
      });
      this.args.onBundleCreated(response.sid);
    } catch (e) {
      this.args.onBundleCreationFailed();
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.phone-regulatory-bundle.create-bundle-error'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneRegulatoryBundle::PhoneNumberTypeSection': typeof PhoneNumberTypeSection;
    'calling/settings/phone-regulatory-bundle/phone-number-type-section': typeof PhoneNumberTypeSection;
  }
}
