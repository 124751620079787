/* import __COLOCATED_TEMPLATE__ from './npm-options.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { INSTALLATION_TYPE } from 'embercom/components/installation-new/constants';
import type { INSTALLATION_METHOD } from 'embercom/components/installation-new/constants';

interface Args {
  selectedInstallationType: INSTALLATION_TYPE;
  onSelectInstallationType: (installationType: INSTALLATION_TYPE) => void;
  selectedInstallationMethod: INSTALLATION_METHOD;
  disabled?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class CodeSnippetOptions extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;

  get installationForUsers() {
    return this.args.selectedInstallationType === INSTALLATION_TYPE.LOGGED_IN_USERS;
  }

  get installationTypes() {
    return [
      {
        id: INSTALLATION_TYPE.LOGGED_IN_USERS,
        title: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.logged-in-users.title',
        ),
        subTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.logged-in-users.sub-title',
        ),
        recommended: true,
      },
      {
        id: INSTALLATION_TYPE.VISITORS,
        title: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.visitors.title',
        ),
        subTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.visitors.sub-title',
        ),
      },
    ];
  }

  get appTypes() {
    let setupOptions = [
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.basic-javascript',
        ),
        value: 'basic-javascript',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.single-page-app',
        ),
        value: 'spa',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.rails-gem',
        ),
        value: 'rails-gem',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.npm',
        ),
        value: 'npm',
      },
    ];
    return setupOptions;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::NpmOptions': typeof CodeSnippetOptions;
    'installation-new/web/initial-setup/npm-options': typeof CodeSnippetOptions;
  }
}
