/* RESPONSIBLE TEAM: team-data-foundations */
import Model, { attr, belongsTo } from '@ember-data/model';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';

export function buildDefaultScimEndpointUrl(app_id) {
  return `${TEAMMATE_APP_URL}/api/scim/apps/${app_id}`;
}

export default class ScimSetting extends Model {
  @attr('array') immuneAdminIds;
  @attr('string') newMessageOwnerId;
  @attr('string') newArticleAuthorId;
  @attr('string') newOwnerId;
  @attr('string') newAssigneeId;
  @attr('string') newReportOwnerId;
  @attr('string') scimEndpointUrl;
  @belongsTo('developer-hub/app-package-install', { async: false }) appPackageInstall;
}
