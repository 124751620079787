/* import __COLOCATED_TEMPLATE__ from './api-connection.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import type ApiConfiguration from 'embercom/models/standalone/api-configuration';
import { action } from '@ember/object';
import { type PulseAccordion } from 'glint/pulse';

interface Args {
  accordion: PulseAccordion;
  apiConfig: ApiConfiguration;
}

export default class StandaloneApiConnection extends Component<Args> {
  @action async save() {
    this.args.apiConfig.save();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Api::ApiConnection': typeof StandaloneApiConnection;
    'standalone/api/api-connection': typeof StandaloneApiConnection;
  }
}
