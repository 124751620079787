/* import __COLOCATED_TEMPLATE__ from './view.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type Model from '@ember-data/model';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type Folder from 'embercom/models/content-service/folder';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import { EntityType } from 'embercom/models/data/entity-types';
import type ArticleContent from 'embercom/models/articles/article-content';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import { DRAWER_EDITOR_SHOW_SIDE_PANEL } from 'embercom/lib/knowledge-hub/constants';
import storage from 'embercom/vendor/intercom/storage';

interface Args {
  activeContent: LocalizedKnowledgeContent & Model;
}

export default class View extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare helpCenterService: any;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;

  @trackedInLocalStorage({
    keyName: DRAWER_EDITOR_SHOW_SIDE_PANEL,
  })
  showSidePanel: boolean;

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.showSidePanel = storage.get(DRAWER_EDITOR_SHOW_SIDE_PANEL) ?? true;
  }

  get editorConfig(): KnowledgeHubEditorConfig | undefined {
    return this.args.activeContent && this.args.activeContent.editorConfig
      ? this.args.activeContent.editorConfig
      : undefined;
  }

  get paywallConfig() {
    return this.knowledgeHubEditorService.paywallConfig;
  }

  get getActiveContentParent(): Folder | undefined {
    return this.args.activeContent.parentContent.parent;
  }

  get shouldShowUnsupportedContentBanner() {
    if (!this.appService.app.canUseFeature('group-ps-handle-unsupported-elements')) {
      return false;
    }

    return !!this.args.activeContent.needsReview;
  }

  get isInHelpCenter() {
    return this.router.currentRouteName.startsWith(
      'apps.app.settings.helpcenter.workspace-helpcenter.collections',
    );
  }

  @action
  async onClickSetHelpCenterLive() {
    let site = this.helpCenterService.sites?.findBy('id', this.selectedHelpCenterId);
    site.websiteTurnedOn = true;
    await site.save();
    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.helpcenter.banner.live', {
        helpCenterName: this.truncatedSiteName,
      }),
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'set_live',
      context: 'after_publish',
      object: 'set_help_center_live_modal',
      place: 'hc_collections', // this option is only shown in help center
    });
    this.knowledgeHubEditorService.closeSetHelpCenterLiveModal();
  }

  get truncatedSiteName() {
    let name = this.helpCenterService.sites?.findBy('id', this.selectedHelpCenterId).name;
    return name.length > 100 ? name.substring(0, 50).concat('...') : name;
  }

  @action
  toggleSidePanel(): void {
    this.showSidePanel = !this.showSidePanel;
    this.knowledgeHubEditorService.trackAnalyticsEvent(
      `${this.showSidePanel ? 'show' : 'hide'}_details_side_panel`,
    );
  }

  // The Help Center we are referring to in this modal is the one in the articleContent, not the one that is selected in the HelpCenterService.site
  // that's why we need to pick it like how we do when navigating to the Help Center page
  get selectedHelpCenterId() {
    let helpCenterId;
    if (this.args.activeContent.entityType === EntityType.ArticleContent) {
      let helpCenterIds = (this.args.activeContent as unknown as ArticleContent).article
        .helpCenterIds;
      helpCenterId = helpCenterIds.firstObject;
    }

    // if unlisted, default help center
    if (!helpCenterId) {
      helpCenterId = this.helpCenterService.defaultSite.id;
    }
    return helpCenterId;
  }

  @action navigateToHelpCenter(): void {
    this.knowledgeHubEditorService.closeSetHelpCenterLiveModal();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'after_publish',
      location: 'set_help_center_live_modal',
      object: `go_to_help_center_button`,
    });

    this.router.transitionTo(
      'apps.app.settings.helpcenter.workspace-helpcenter.collections',
      this.appService.app.id,
      this.selectedHelpCenterId,
    );
  }

  @action async editActiveContent() {
    try {
      await this.permissionsService.checkPermission(
        this.args.activeContent.requiredEditPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }
    await this.knowledgeHubEditorService.editActiveContent();
  }

  @dropTask
  *unpublishActiveContent(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(
        this.args.activeContent.requiredPublishPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    yield this.knowledgeHubEditorService.unpublishActiveContent();
  }

  @dropTask
  *publishActiveContent(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(
        this.args.activeContent.requiredPublishPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    yield this.knowledgeHubEditorService.publishActiveContent();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::View': typeof View;
    'knowledge-hub/content-editor/view': typeof View;
  }
}
