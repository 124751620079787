/* import __COLOCATED_TEMPLATE__ from './handoff-to-salesforce-chat.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';
import type HandoffToSalesforceChatStepType from 'embercom/models/operator/visual-builder/step/handoff-to-salesforce-chat';
import type HandoffToSalesforceChatConfiguration from 'embercom/objects/visual-builder/configuration/step/handoff-to-salesforce-chat';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

export interface HandoffToSalesforceChatArgs {
  step: HandoffToSalesforceChatStepType;
  stepConfig: HandoffToSalesforceChatConfiguration;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: HandoffToSalesforceChatArgs;
}

const HandoffToSalesforceChat = templateOnlyComponent<Signature>();

export default HandoffToSalesforceChat;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffToSalesforceChat': typeof HandoffToSalesforceChat;
    'workflows/graph-editor/node-items/steps/handoff-to-salesforce-chat': typeof HandoffToSalesforceChat;
  }
}
