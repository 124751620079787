/* import __COLOCATED_TEMPLATE__ from './show-or-hide-messenger-card.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import { taskFor } from 'ember-concurrency-ts';
import type { TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';

export default class ShowOrHideMessengerCard extends Component {
  @service declare messengerInstallation: MessengerInstallation;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @tracked showConfirmModal = false;
  @tracked settings: any;
  @tracked isMessengerVisible = true;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    taskFor(this.loadSettings).perform();
  }

  @task({
    drop: true,
  })
  *loadSettings(): TaskGenerator<void> {
    this.settings = yield this.store.peekRecord('messenger-settings/all', this.app.id);

    if (!this.settings) {
      this.settings = yield this.store.findRecord('messenger-settings/all', this.app.id);
    }

    // After settings have been loaded, override the default value of `this.isMessengerVisible` to the real value
    this.isMessengerVisible =
      this.settings?.visibility?.messengerEnabledForUsers &&
      this.settings?.visibility?.messengerEnabledForVisitors;
  }

  get app() {
    return this.appService.app;
  }

  get showOrHideMessengerCardInfo() {
    return {
      id: 'show-hide-messenger',
      analyticsObjectName: 'show-hide-messenger-link',
      iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/eye.svg'),
      label: this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.show-hide-messenger',
      ),
      route: 'apps.app.messenger.web',
      routeQueryParams: {
        tab: 'content',
        section: 'show-launcher',
      },
      explanation: this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.show-hide-messenger-explanation',
      ),
    };
  }

  get confirmationModalTitle() {
    if (this.isMessengerVisible) {
      return this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.modal-title-hide-messenger',
      );
    }

    return this.intl.t(
      'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.modal-title-show-messenger',
    );
  }

  get confirmationModalDescription() {
    if (this.isMessengerVisible) {
      return this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.modal-explanation-hide',
      );
    }

    return this.intl.t(
      'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.modal-explanation-show',
    );
  }

  get confirmationModalSubmitButtonText() {
    if (this.isMessengerVisible) {
      return this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.modal-confirmation-hide',
      );
    }

    return this.intl.t(
      'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.modal-confirmation-show',
    );
  }

  @action
  showConfirmationModal(): void {
    this.showConfirmModal = true;
  }

  @action
  async submit() {
    if (taskFor(this.messengerInstallation.updateMessengerVisibility).isRunning) {
      return;
    }

    await taskFor(this.messengerInstallation.updateMessengerVisibility).perform(
      this.app.id,
      !this.isMessengerVisible,
    );
    this.isMessengerVisible = !this.isMessengerVisible;
    taskFor(this.loadSettings).perform();

    this.showConfirmModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::LookAndFeelCards::ShowOrHideMessengerCard': typeof ShowOrHideMessengerCard;
    'installation-new/web/initial-setup/look-and-feel-cards/show-or-hide-messenger-card': typeof ShowOrHideMessengerCard;
  }
}
