/* import __COLOCATED_TEMPLATE__ from './customize-messenger-card.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default class CustomizeMessengerCard extends Component {
  @service declare intl: IntlService;

  get customizeMessengerCardInfo() {
    return {
      id: 'customize-messenger',
      analyticsObjectName: 'customize-messenger-link',
      iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/paint-brush.svg'),
      label: this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.customize-messenger',
      ),
      route: 'apps.app.messenger.web',
      routeQueryParams: {
        tab: 'content',
        section: 'layout-and-spaces',
      },
      explanation: this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-messenger.link-cards.customize-messenger-explanation',
      ),
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::LookAndFeelCards::CustomizeMessengerCard': typeof CustomizeMessengerCard;
    'installation-new/web/initial-setup/look-and-feel-cards/customize-messenger-card': typeof CustomizeMessengerCard;
  }
}
