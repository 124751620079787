/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import type ArticleMultilingual from 'embercom/models/article-multilingual';

export interface Node {
  depth: number;
}

export interface Content {
  order: number;
  read_only: boolean;
}

export interface PositionMap {
  [key: string]: number;
}
export interface Collection extends Content {
  id: string;
  children: Array<Collection>;
  parent: Collection | undefined;
  name: string;
  count: number;
  isHome: boolean;
  icon: string;
  helpCenterId: string;
  get: <T extends keyof this>(property: T) => this[T];
  save: () => Promise<void>;
  destroyRecord: () => void;
  set: (property: string, value: any) => void;
  reorder(newIndex: number, positionMap: PositionMap, helpCenterId: string): Promise<void>;
}

export interface ArticleSummaryLocale {
  title: string;
  summary: string;
  state: string;
  site_provider?: string;
}

export interface ArticleSummary extends Content {
  id: string;
  public: boolean;
  collection: Collection;
  article_group_id: string;
  order: number;
  locales: { [locale: string]: ArticleSummaryLocale };
  parent_collections: Array<number>;
}

export interface ArticleModel extends ArticleSummary {
  reorder: (newIndex: number, helpCenterId: string) => Promise<void>;
  save: () => Promise<void>;
  set: (property: string, value: any) => void;
}

export enum DataTypes {
  article,
  collection,
  placeholder,
}

export interface DataObject {
  content: ArticleSummary | Collection;
  type: DataTypes;
  level: number;
  isRecentlyAdded?: boolean;
  locale: () => string;
  defaultLocale?: () => string;
  addCollection?: () => Promise<Collection>;
  listAddableArticles?: () => Promise<Array<ArticleMultilingual>>;
  addArticlesToCollection?: (articles: Array<any>) => Promise<Collection>;
  deleteCollection?: () => Promise<void>;
  saveCollection?: () => Promise<void>;
  removeArticleFromCollection?: () => Promise<void>;
}
