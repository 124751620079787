/* RESPONSIBLE TEAM: team-knowledge-interop */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  type SortDirection,
  type Predicate,
  type TagOperator,
} from 'embercom/lib/knowledge-hub/constants';
import { type EntityType } from 'embercom/models/data/entity-types';
import {
  type SyncIssuesFilterOptions,
  type FolderFilterOption,
} from 'embercom/lib/content-service/search-api';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { inject as service } from '@ember/service';
import { IN_OPERATOR } from 'embercom/lib/outbound/constants';
import { DRAWER_EDITOR_QUERY_PARAMS } from 'embercom/services/knowledge-hub-drawer-editor-service';
import { type EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import { type ActiveContent, getSupportContentId } from 'embercom/lib/knowledge-hub/constants';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { type OnContentUpdateCallbackFunctionArgs } from 'embercom/services/knowledge-hub-editor-service';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';

export default class AllContent extends Controller {
  @service declare knowledgeHubService: KnowledgeHubService;

  queryParams = [
    { searchTerm: 'search' },
    { linkedUrls: 'linkedUrls' },
    'filtersApplied',
    'activeFilter',
    { selectedTypes: 'types' },
    'urlSourceId',
    { selectedHelpCenterIds: 'helpCenterIds' },
    'predicates',
    'locale',
    'status',
    'chatbotState',
    'copilotState',
    'createdBy',
    'lastUpdatedBy',
    { selectedFolderFilterOption: 'folder_filter_option' },
    { selectedSyncIssueOption: 'needs_review' },
    { sortByParam: 'sort_by' },
    { sortDirectionParam: 'sort_direction' },
    'tagIds',
    'tagOperator',
    'collectionIds',
    'writtenByIds',
    ...DRAWER_EDITOR_QUERY_PARAMS,
  ];

  @tracked activeFilter?: string;
  @tracked declare filtersApplied?: string;
  @tracked declare searchTerm?: string;
  @tracked declare linkedUrls?: string;
  @tracked declare locale: string | null;
  @tracked declare predicates?: string;
  @tracked declare status: string | null;
  @tracked declare chatbotState?: string;
  @tracked declare copilotState?: string;
  @tracked declare createdBy?: string;
  @tracked declare selectedTypes?: string;
  @tracked declare urlSourceId?: string;
  @tracked declare selectedHelpCenterIds?: string;
  @tracked declare lastUpdatedBy?: string;
  @tracked sortDirectionParam?: SortDirection;
  @tracked sortByParam?: string;
  @tracked selectedFolderFilterOption?: FolderFilterOption;
  @tracked selectedSyncIssueOption?: SyncIssuesFilterOptions;
  @tracked declare tagIds?: string;
  @tracked tagOperator: TagOperator = IN_OPERATOR;
  @tracked collectionIds?: string;
  @tracked writtenByIds?: string;

  // Drawer Editor query params
  @tracked activeContentId?: number;
  @tracked activeContentType?: string;
  @tracked editorMode?: EditorMode;
  @tracked collectionId?: string;
  @tracked contentLocale?: string;
  @tracked folderId?: string;

  get convertedSelectedTypes(): number[] | undefined {
    return this.selectedTypes?.split(',').map((type) => Number(type));
  }

  get convertedSelectedHelpCenterIds(): string[] | undefined {
    return this.selectedHelpCenterIds?.split(',').map((id) => String(id));
  }

  get convertedTagIds(): string[] | undefined {
    return this.tagIds?.split(',').map((id) => String(id));
  }

  get convertedPredicates(): Array<Predicate> | undefined {
    return this.predicates && this.predicates.length > 0 ? JSON.parse(this.predicates) : undefined;
  }

  get convertedFiltersApplied(): string[] | undefined {
    return this.filtersApplied && this.filtersApplied.length > 0
      ? JSON.parse(this.filtersApplied)
      : undefined;
  }

  get convertedCollectionIds(): string[] | undefined {
    return this.collectionIds?.split(',').map((id) => String(id));
  }

  get convertedWrittenByIds(): string[] | undefined {
    return this.writtenByIds?.split(',').map((id) => String(id));
  }

  get sortBy(): string | undefined {
    return this.sortByParam || this.knowledgeHubService.api?.defaultSearchParams.sort_by;
  }

  get sortDirection(): SortDirection | undefined {
    return (
      this.sortDirectionParam || this.knowledgeHubService.api?.defaultSearchParams.sort_direction
    );
  }

  get getLinkedUrls(): string[] | undefined {
    return this.linkedUrls?.split(',');
  }

  @action setActiveFilter(filter?: string) {
    this.activeFilter = filter;
  }

  @action setFiltersApplied(filters: string[]) {
    this.filtersApplied = filters && filters.length > 0 ? JSON.stringify(filters) : undefined;
  }

  @action
  setSearchTerm(searchTerm?: string): void {
    this.searchTerm = searchTerm;
  }

  @action
  setLinkedUrls(linkedUrls?: string[]): void {
    this.linkedUrls = linkedUrls && linkedUrls?.length > 0 ? linkedUrls?.join(',') : undefined;
  }

  @action
  setLocale(locale: string | null): void {
    this.locale = locale;
  }

  @action
  setPredicates(predicates: Array<Predicate>): void {
    this.predicates = predicates && predicates.length > 0 ? JSON.stringify(predicates) : undefined;
  }

  @action
  setStatus(status: string | null): void {
    this.status = status;
  }

  @action
  setChatbotState(chatbotState?: string): void {
    this.chatbotState = chatbotState;
  }

  @action
  setCopilotState(copilotState?: string): void {
    this.copilotState = copilotState;
  }

  @action
  setCreatedBy(createdBy?: string): void {
    this.createdBy = createdBy;
  }

  @action
  setSelectedTypes(selectedTypes?: Array<EntityType>): void {
    this.selectedTypes =
      selectedTypes && selectedTypes?.length > 0 ? selectedTypes?.join(',') : undefined;
  }

  @action
  setUrlSourceId(urlSourceId?: string): void {
    this.urlSourceId = urlSourceId;
  }

  @action
  setSelectedHelpCenterIds(selectedHelpCenterIds?: Array<string>): void {
    this.selectedHelpCenterIds =
      selectedHelpCenterIds && selectedHelpCenterIds?.length > 0
        ? selectedHelpCenterIds?.join(',')
        : undefined;
  }

  @action
  setTagIds(tagIds?: string[]): void {
    this.tagIds = tagIds && tagIds?.length > 0 ? tagIds?.join(',') : undefined;
  }

  @action
  setTagOperator(tagOperator: TagOperator): void {
    this.tagOperator = tagOperator;
  }

  @action
  setLastUpdatedBy(lastUpdatedBy?: string): void {
    this.lastUpdatedBy = lastUpdatedBy;
  }

  @action
  setSortBy(sortBy: string): void {
    this.sortByParam = sortBy;
  }

  @action
  setSortDirection(sortDirection: SortDirection): void {
    this.sortDirectionParam = sortDirection;
  }

  @action
  setFolderFilterOption(folderFilterOption?: FolderFilterOption): void {
    this.selectedFolderFilterOption = folderFilterOption;
  }

  @action
  setSyncIssueFilterOption(syncIssueOption?: SyncIssuesFilterOptions): void {
    this.selectedSyncIssueOption = syncIssueOption;
  }

  @action
  setCollectionIds(collectionIds?: string[]): void {
    this.collectionIds =
      collectionIds && collectionIds?.length > 0 ? collectionIds?.join(',') : undefined;
  }

  @action
  setWrittenByIds(writtenByIds?: string[]): void {
    this.writtenByIds =
      writtenByIds && writtenByIds?.length > 0 ? writtenByIds?.join(',') : undefined;
  }

  @action
  resetFilters(): void {
    this.activeFilter = undefined;
    this.filtersApplied = undefined;
    this.searchTerm = undefined;
    this.locale = null;
    this.predicates = undefined;
    this.status = null;
    this.copilotState = undefined;
    this.chatbotState = undefined;
    this.createdBy = undefined;
    this.selectedTypes = undefined;
    this.selectedHelpCenterIds = undefined;
    this.lastUpdatedBy = undefined;
    this.selectedFolderFilterOption = undefined;
    this.selectedSyncIssueOption = undefined;
    this.urlSourceId = undefined;
    this.tagIds = undefined;
    this.tagOperator = IN_OPERATOR;
    this.collectionIds = undefined;
    this.writtenByIds = undefined;
    this.linkedUrls = undefined;
  }

  @restartableTask *addRowForContent() {
    // Delay to allow the ES index to update
    yield timeout(ENV.APP._1000MS);
    yield this.knowledgeHubService.api?.refreshPageAfterDelay(0);
  }

  @restartableTask *reloadRowForContent(updatedContent: ActiveContent) {
    // Debounce in case there are multiple updates in succession
    yield timeout(ENV.APP._500MS);
    let contentWrapperId = `${ContentWrapperType.SUPPORT_CONTENT}_${getSupportContentId(updatedContent)}`;
    this.knowledgeHubService.api?.updateContentWrapper(contentWrapperId);
  }

  @action
  removeRowForContent(entityType: EntityType, entityId: string) {
    let contentWrapperId = `${ContentWrapperType.SUPPORT_CONTENT}_${entityType}_${entityId}`;
    this.knowledgeHubService.api?.removeContentWrapper(contentWrapperId);
  }

  @action
  handleContentUpdate(update: OnContentUpdateCallbackFunctionArgs) {
    switch (update.type) {
      case 'add':
      case 'move-folder':
        taskFor(this.addRowForContent).perform();
        break;
      case 'edit':
        taskFor(this.reloadRowForContent).perform(update.content);
        break;
      case 'delete':
        this.removeRowForContent(update.entityType, update.entityId);
        break;
    }
  }
}
