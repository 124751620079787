/* import __COLOCATED_TEMPLATE__ from './content-review.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type ContentVersion } from 'embercom/services/knowledge-hub-editor-service';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import { DRAWER_EDITOR_SHOW_SIDE_PANEL } from 'embercom/lib/knowledge-hub/constants';
import storage from 'embercom/vendor/intercom/storage';
import type IntlService from 'ember-intl/services/intl';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import type ContentReviewRequest from 'embercom/models/content-service/content-review-request';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import type ArticleContent from 'embercom/models/articles/article-content';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import { tracked } from '@glimmer/tracking';

interface Args {
  suggestedVersion: ContentVersion;
  latestVersion?: ContentVersion;
  activeContent: LocalizedKnowledgeContent & Model;
}

export default class ContentReview extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare intl: IntlService;

  @trackedInLocalStorage({
    keyName: DRAWER_EDITOR_SHOW_SIDE_PANEL,
  })
  showSidePanel = true;

  @tracked showEditComposer = false;

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.showSidePanel = storage.get(DRAWER_EDITOR_SHOW_SIDE_PANEL) ?? true;
  }

  get editorConfig(): KnowledgeHubEditorConfig | undefined {
    return this.args.activeContent && this.args.activeContent.editorConfig
      ? this.args.activeContent.editorConfig
      : undefined;
  }

  get reviewReason() {
    return this.contentReviewRequest().get('reviewReason');
  }

  get reviewRequestCreatedAt() {
    return this.intl.t('fin-ai-agent.knowledge.content-suggestions.created', {
      date: this.intl.formatRelative(new Date(this.contentReviewRequest().get('createdAt'))),
    });
  }

  get isEditReviewType() {
    return this.contentReviewRequest().get('reviewType') === ReviewType.EDIT_CONTENT;
  }

  private contentReviewRequest(): ContentReviewRequest {
    return (this.args.activeContent as ArticleContent | ContentSnippet).contentReviewRequest;
  }

  @action
  toggleSidePanel(): void {
    this.showSidePanel = !this.showSidePanel;
    this.knowledgeHubEditorService.trackAnalyticsEvent(
      `${this.showSidePanel ? 'show' : 'hide'}_details_side_panel`,
    );
  }

  @action
  switchToEditMode(show: boolean) {
    this.showEditComposer = show;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview': typeof ContentReview;
    'knowledge-hub/content-editor/content-review': typeof ContentReview;
  }
}
