/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    groups: { embedded: 'always' },
    edges: { embedded: 'always' },
    attributeDescriptors: { embedded: 'always' },
  },

  normalize(_model, hash) {
    hash.original_payload = hash;
    return this._super(...arguments);
  },

  serialize(snapshot, option) {
    let serialized = this._super(...arguments);
    // Remove the original payload (only used for rolling back)
    delete serialized.original_payload;

    // Filter out archived attribute descriptors
    if (serialized.attribute_descriptors) {
      serialized.attribute_descriptors = serialized.attribute_descriptors.filter(
        (descriptor) => !descriptor.archived,
      );
    }

    return serialized;
  },
});
