/* import __COLOCATED_TEMPLATE__ from './blocks-as-text.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import {
  type BlockList,
  type Block,
  type Image,
  type AttachmentList,
  type MessengerCardBlock,
  type List,
  type Html,
} from '@intercom/interblocks.ts';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';
import { translateBlock } from 'embercom/helpers/render-blocks';
import { translateHtmlBlock } from 'embercom/components/inbox2/conversation-stream/interblocks-component';
import type Session from 'embercom/services/session';

interface Args {
  blocks: BlockList;
  checkForTruncation?: boolean;
  highlights?: string[];
}

interface Signature {
  Args: Args;
}

const MAX_LENGTH = 500;

export default class BlocksAsText extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;
  @service declare session: Session;

  get text() {
    if (!this.args.blocks) {
      return '';
    }

    let blocks = excludeReapeatedBlockTypes(this.args.blocks);
    let totalTextLength = 0;
    let textEntries = [];

    for (let i = 0; i < blocks.length && totalTextLength < MAX_LENGTH; i++) {
      let block = blocks[i];
      let blockText = blockToText(
        block,
        this.intl,
        this.conversationTranslationSettings.showTranslation,
        this.session.workspace.locale,
      );
      totalTextLength += blockText.length;
      textEntries.push(blockText);
    }

    let text = textEntries.join(' ');

    // replace <br> with spaces, as they'll be entirely stripped out when using `htmlToTextContent`
    // and if the only HTML the string contains is <br> then this avoids the expensive `htmlToTextContent` call
    if (text.includes('<br>')) {
      text = text.replace(/<br>/g, ' ');
    }

    if (text.match(/<|>|&/g)) {
      // If the summary text contains any HTML content, we strip out the HTML using `htmlToTextContent`
      return htmlToTextContent(text);
    } else {
      // If it does not contain HTML characters we skip the HTML to text step as it's relatively expensive.
      return text;
    }
  }

  get plainTextBlocks() {
    return [
      {
        class: 'no-margin',
        type: 'paragraph',
        text: this.text,
      },
    ];
  }
}

export function blockToText(
  block: Block,
  intl: IntlService,
  toTranslate = false,
  lang?: string,
): string {
  switch (block.type) {
    case 'image':
      block = block as Image;
      if (!block.url) {
        return intl.t('inbox.common.block-as-text.sent-image');
      }
      if (block.url.includes('gifs.intercomcdn.com')) {
        return intl.t('inbox.common.block-as-text.sent-gif');
      }
      return `${intl.t('inbox.common.block-as-text.sent-image')} "${basename(block.url)}"`;
    case 'attachmentList': {
      block = block as AttachmentList;
      let attachments = block.attachments
        .map((attachment: any) => `"${attachment.name}"`)
        .join(', ');

      return `${intl.t('inbox.common.block-as-text.sent-attachment', {
        count: block.attachments.length,
      })} ${attachments}`;
    }
    case 'messengerCard':
      block = block as MessengerCardBlock;
      return `[${block.text}]`;
    case 'html':
      if (toTranslate && lang) {
        block = translateHtmlBlock(block, toTranslate, lang);
      }
      block = block as Html;
      return block.content;

    case 'unorderedList':
    case 'orderedList':
      block = block as List;
      return block.items.map((item) => item).join(' ');

    default:
      if (toTranslate && lang) {
        block = translateBlock(block, toTranslate, lang);
      }
      if ('text' in block) {
        return block.text || '';
      } else {
        return '';
      }
  }
}

function basename(str: string) {
  return new URL(str).pathname.split('/').pop();
}

function excludeReapeatedBlockTypes(blocks: Block[]) {
  return blocks.reduce((blocks: Block[], currentBlock) => {
    // If first block is paragraph we do not render image or attachmentList.
    // We do not render image or attachmentList if previous block was the same type.
    let previousBlock = blocks[blocks.length - 1 <= 0 ? 0 : blocks.length - 1];
    let nonDuplicatedBlocks = ['image', 'attachmentList'];
    let hasPreviousBlockPriority = [...nonDuplicatedBlocks, 'paragraph'].includes(
      previousBlock?.type,
    );
    let canRemoveCurrentBlock = nonDuplicatedBlocks.includes(currentBlock?.type);

    if (hasPreviousBlockPriority && canRemoveCurrentBlock) {
      return blocks;
    }

    return [...blocks, currentBlock];
  }, []);
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::BlocksAsText': typeof BlocksAsText;
    'inbox2/common/blocks-as-text': typeof BlocksAsText;
  }
}
