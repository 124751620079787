/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/use-brace-expansion */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import diffCalculator from 'embercom/lib/developer-hub/diff-calculator';
import { computed } from '@ember/object';
import { collect, filter, readOnly, or } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations(
  {
    submitUrl: [
      validator('presence', { presence: true, message: 'This field is required' }),
      validator('format', { type: 'url' }),
      validator('https-except-in-development'),
    ],
    initializeUrl: [
      validator('presence', { presence: true, message: 'This field is required' }),
      validator('format', { type: 'url' }),
      validator('https-except-in-development'),
    ],
    configureUrl: {
      disabled: computed('model.validationsDisabled', 'model.configureUrl', function () {
        return this.get('model.validationsDisabled') || !this.get('model.configureUrl');
      }),
      validators: [validator('format', { type: 'url' }), validator('https-except-in-development')],
    },
    submitSheetUrl: {
      disabled: computed('model.validationsDisabled', 'model.submitSheetUrl', function () {
        return this.get('model.validationsDisabled') || !this.get('model.submitSheetUrl');
      }),
      validators: [validator('format', { type: 'url' }), validator('https-except-in-development')],
    },
  },
  {
    disabled: readOnly('model.validationsDisabled'),
  },
);

export default Fragment.extend(Validations, {
  submitUrl: attr('string', { humanisedName: 'Submit URL' }),
  initializeUrl: attr('string', { humanisedName: 'Initialize URL' }),
  configureUrl: attr('string', { humanisedName: 'Configure URL' }),
  submitSheetUrl: attr('string', { humanisedName: 'Sheet submit URL' }),
  availableInOperator: attr('boolean', { humanisedName: 'Works with Operator' }),
  availableInConversations: attr('boolean', { humanisedName: 'Works with Conversations' }),
  availableInMessengerHome: attr('boolean', { humanisedName: 'Works with Messenger' }),
  availableInMessages: attr('boolean', { humanisedName: 'Works with Outbound' }),

  availableCapabilites: collect(
    'availableInOperator',
    'availableInConversations',
    'availableInMessengerHome',
    'availableInMessages',
  ),

  enabledMessengerApps: or(
    'availableInConversations',
    'availableInMessengerHome',
    'availableInOperator',
    'availableInMessages',
  ),

  enabledCapabilities: filter('availableCapabilites', Boolean),

  validationsDisabled: true,

  capabilitesErrors: readOnly('validations.attrs.enabledCapabilities.length.errors'),
  submitUrlErrors: readOnly('validations.attrs.submitUrl.errors'),
  initializeUrlErrors: readOnly('validations.attrs.initializeUrl.errors'),
  configureUrlErrors: readOnly('validations.attrs.configureUrl.errors'),
  submitSheetsUrlErrors: readOnly('validations.attrs.submitSheetUrl.errors'),

  compareWith(modelB) {
    return diffCalculator(this, modelB);
  },

  validate() {
    this.set('validationsDisabled', false);
    return this._super(...arguments);
  },

  rollbackAttributes() {
    this.set('validationsDisabled', true);
    return this._super(...arguments);
  },
});
