/* import __COLOCATED_TEMPLATE__ from './manage-source-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { SyncBehavior } from 'embercom/models/knowledge-hub/sync-source-wrapper';
import { taskFor } from 'ember-concurrency-ts';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import ConnectSourceDropdownOption from './connect-source-dropdown-option';

import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import { type SourceRowActionOption, type SourceRowActionsGroupList } from '../sections/section';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { type ButtonType } from '@intercom/pulse/template-registry';

export interface Signature {
  Args: {
    syncSource?: SyncSourceWrapper;
    type?: ButtonType;
    sectionDisabled?: boolean;
    actionButtonLabel?: string;
    actionOptions?: SourceRowActionsGroupList;
    includeIcon?: boolean;
    includeAddNew?: boolean;
    onAddSyncSource?: (sourceType: string) => void;
    onClickButton?: () => void;
  };
}

export default class ManageSourceDropdown extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare store: Store;

  @tracked sourceToRemove?: SyncSourceWrapper | null;
  @tracked sourceToEdit?: ContentImportSource | null;
  @tracked sourceEditWrapper?: SyncSourceWrapper | null;
  @tracked showRemoveSourceModal = false;
  @tracked showSourceSettingsModal = false;

  @action async requestSourceSettings(syncSource: SyncSourceWrapper) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (error) {
      return;
    }
    this.sourceToEdit = await syncSource.show();
    this.sourceEditWrapper = syncSource;
    this.setShowSourceSettingsModal(true);
  }

  get isSectionDisabled(): boolean {
    return this.args.sectionDisabled ?? false;
  }

  get actionButtonLabel(): string {
    return (
      this.args.actionButtonLabel ??
      this.intl.t('knowledge-hub.overview.sections.syncs.rows.active.action.label')
    );
  }

  get icon(): InterfaceIconName | undefined {
    return this.args.includeIcon ? 'settings' : undefined;
  }

  @action onClickActionButton() {
    return this.args.onClickButton?.();
  }

  get activeSyncOptions(): SourceRowActionsGroupList {
    if (this.args.actionOptions) {
      return this.args.actionOptions;
    }
    let actions: SourceRowActionOption[] = [];
    let syncSource = this.args.syncSource as SyncSourceWrapper;
    if (syncSource.canResync) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.resync',
        ),
        description: this.intl.t('knowledge-hub.overview.sections.syncs.rows.active.last-synced', {
          date: moment(syncSource.lastSyncedAt).fromNow(),
        }),
        icon: 'sync',
        onSelectItem: syncSource.isSyncing ? () => {} : () => taskFor(syncSource.resync).perform(),
        isDestructive: false,
        isDisabled: syncSource.isSyncing || syncSource.syncBehavior === SyncBehavior.API,
        tooltipText: this.fetchErrorTooltip('resync', syncSource),
        tooltip: { isDelayed: false },
      });

      if (syncSource.sourceType === 'external_content') {
        actions.push({
          text: this.intl.t(
            'knowledge-hub.overview.sections.syncs.rows.active.action.options.open-settings',
          ),
          icon: 'settings',
          onSelectItem: syncSource.isSyncing
            ? () => {}
            : () => this.requestSourceSettings(syncSource),
          isDestructive: false,
          isDisabled: syncSource.isSyncing || syncSource.syncBehavior === SyncBehavior.API,
          tooltipText: this.fetchErrorTooltip('settings', syncSource),
          tooltip: { isDelayed: false },
        });
      }
    }

    if (syncSource.canImport) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.import',
        ),
        icon: 'cloud-upload',
        onSelectItem: syncSource.isSyncing ? () => {} : () => syncSource.import(),
        isDestructive: false,
        isDisabled: syncSource.isSyncing,
        tooltipText: syncSource.isSyncing
          ? this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-import-tooltip',
            )
          : undefined,
        tooltip: { isDelayed: false },
      });
    }

    if (syncSource.canRemove) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.remove',
        ),
        icon: 'trash',
        onSelectItem: syncSource.isSyncing ? () => {} : () => this.requestRemoveSource(syncSource),
        isDestructive: true,
        isDisabled: syncSource.isSyncing,
        tooltipText: syncSource.isSyncing
          ? this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-remove-tooltip',
            )
          : undefined,
        tooltip: { isDelayed: false },
      });
    }

    let options = [{ items: actions }];
    if (this.args.includeAddNew && syncSource.canAddNew) {
      options.push({
        items: [
          {
            text: this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.connect',
              {
                type: this.intl.t(
                  `knowledge-hub.overview.sections.syncs.types.${syncSource.sourceType}`,
                ),
              },
            ),
            icon: 'new',
            onSelectItem: () => this.args.onAddSyncSource?.(syncSource.sourceType),
            isDestructive: false,
            component: ConnectSourceDropdownOption,
          },
        ],
      });
    }
    return options;
  }

  setShowSourceSettingsModal(state = false) {
    this.showSourceSettingsModal = state;
  }

  @action async requestRemoveSource(syncSource: SyncSourceWrapper) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (error) {
      return;
    }

    this.sourceToRemove = syncSource;
    this.setShowRemoveSourceModal(true);
  }

  @action closeRemoveModal() {
    this.setShowRemoveSourceModal(false);
    this.sourceToRemove = undefined;
  }

  @action async removeSource() {
    let sourceToRemove = this.sourceToRemove;
    if (!sourceToRemove) {
      return;
    }

    await sourceToRemove.remove();
    sourceToRemove = null;
    this.closeRemoveModal();
  }

  @action closeSourceSettingsModal() {
    this.setShowSourceSettingsModal(false);
    this.sourceToEdit = undefined;
  }

  setShowRemoveSourceModal(state = false) {
    this.showRemoveSourceModal = state;
  }

  private fetchErrorTooltip(type: 'resync' | 'settings', syncSource: SyncSourceWrapper) {
    if (syncSource.syncBehavior === SyncBehavior.API) {
      return this.intl.t(
        `knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-${type}-tooltip-api`,
      );
    }
    return this.intl.t(
      `knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-${type}-tooltip`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Shared::ManageSourceDropdown': typeof ManageSourceDropdown;
    'knowledge-hub/overview/shared/manage-source-dropdown': typeof ManageSourceDropdown;
  }
}
