/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import BillingSettingsRoute from 'embercom/routes/apps/app/settings/base/billing-settings-route';
import { hash } from 'rsvp';
import { task } from 'ember-concurrency-decorators';
import { INCLUDED_TIERS_FROM_PRICING_ENDPOINT } from 'embercom/lib/billing';
import type Transition from '@ember/routing/transition';
import type Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'embercom/services/intl';

export default class BillingDetailsRoute extends BillingSettingsRoute {
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('apps.app.billing.subscription');
  }

  get app() {
    return this.appService.app;
  }

  get subscription() {
    return this.customerService.customer.subscription;
  }

  redirect(_model: $TSFixMe, transition: Transition) {
    if (transition.to.name.includes('cancel')) {
      return this.transitionTo('apps.app.settings.subscription.billing.cancel');
    }
    if (
      this.app.isOnValueBasedPricing ||
      (this.app.isSalesforceContracted && !this.customerService.customer.hasCustomPricing) ||
      this.customerService.get('earlyStageGraduation.vbpGraduation') ||
      (this.app.canUsePerProductPricingFlow &&
        !this.customerService.customer.currentEarlyStageCustomer) ||
      this.subscription?.isOnTprPricingModel ||
      this.app.onPricing5
    ) {
      return this.transitionTo('apps.app.settings.subscription.billing.summary');
    }
    return;
  }

  model() {
    return hash({
      titleToken: this.titleToken,
      contract: this.app.isSalesforceContracted
        ? this.store.findRecord('billing/contract', this.app.id)
        : undefined,
    });
  }

  afterModel(_model: $TSFixMe, _transition: Transition) {
    return taskFor(this.loadData).perform();
  }

  @task *loadData() {
    yield Promise.all([
      this.customerService.loadData({
        customer: this.customerService.customer,
        reusePricesIfPossible: true,
        fetchPrices: this._shouldFetchPrices,
        includeTiers: this._shouldFetchPrices && INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
        overrideCanSeePrice: this.appService.app.isSalesforceContracted,
        includePlanCombinationValidation: this._shouldFetchPrices,
        source: 'billing-details-route',
        useBillingCycleMetricVariations:
          this.customerService.customer.showBillingCycleMetricsInBillingSummary,
        useShiftedCycleMetricVariations: this.customerService.customer.shiftedCycleMetricsAvailable,
      }),
    ]);
  }

  get _shouldFetchPrices() {
    return (
      !this.customerService.customer.hasCustomPricing || this.appService.app.isSalesforceContracted
    );
  }
}
