/* import __COLOCATED_TEMPLATE__ from './phone-pricing-plan-paywall.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';

interface Args {
  onClose: () => void;
}

export default class PhonePricingPlanPaywall extends Component<Args> {
  @service declare intl: IntlService;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare modelDataCacheService: $TSFixMe;
  @service declare redirectService: $TSFixMe;

  get benefits() {
    return [
      {
        description: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-1'),
        descriptionTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-1',
        hasIcIllustrativeIcon: true,
        icon: 'people',
        title: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-1-title'),
        titleTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-1-title',
      },
      {
        description: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-2'),
        descriptionTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-2',
        hasIcIllustrativeIcon: true,
        icon: 'messenger',
        title: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-2-title'),
        titleTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-2-title',
      },
      {
        description: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-3'),
        descriptionTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-3',
        hasIcIllustrativeIcon: true,
        icon: 'rule',
        title: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-3-title'),
        titleTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-3-title',
      },
      {
        description: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-4'),
        descriptionTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-4',
        hasIcIllustrativeIcon: true,
        icon: 'goal',
        title: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-4-title'),
        titleTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-4-title',
      },
    ];
  }

  get features() {
    return [
      {
        key: 'switch',
      },
      {
        key: 'voicemail',
      },
      {
        key: 'customized_greetings',
      },
      {
        key: 'call_monitoring',
      },
      {
        key: 'callbacks',
      },
      {
        key: 'call_csat',
      },
      {
        key: 'call_overflow',
      },
      {
        key: 'outbound_dialler',
      },
      {
        key: 'call_transfers',
      },
      {
        key: 'call_wrap_up',
      },
    ];
  }

  @action
  postTaskAndTrackAnalyticsEvent() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'chat_to_us',
      context: 'phone_pricing_plan_paywall',
    });
  }

  @action
  async redirectToBilling() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'choose_plan',
      context: 'phone_pricing_plan_paywall',
    });
    await post('/ember/calling_settings/enroll_app_for_pricing5_migration', {
      app_id: this.appService.app.id,
    });
    this.modelDataCacheService.clear();
    this.redirectService.redirect(
      `/a/apps/${this.appService.app.id}/settings/subscription/migration`,
    );
  }

  get paywallUrl() {
    return `${window.location.protocol}//${window.location.host}/a/apps/${this.appService.app.id}/settings/channels/phone?paywall=true`;
  }

  get hasBillingPermission() {
    return this.permissionsService.currentAdminCan('can_access_billing_settings');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhonePricingPlanPaywall': typeof PhonePricingPlanPaywall;
    'calling/settings/phone-pricing-plan-paywall': typeof PhonePricingPlanPaywall;
  }
}
