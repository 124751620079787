/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CheckoutType } from 'embercom/routes/apps/app/checkout/success';
import {
  BILLING_PERIODS,
  PLAN_DATA,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  findSolution,
  percentDiscountForSolution,
} from 'embercom/lib/billing';
import moment from 'moment-timezone';
import { type Router } from '@ember/routing';
import { action } from '@ember/object';

export interface Args {
  checkoutType: CheckoutType;
  planId?: string;
  firstInvoicedOnDate: moment.Moment;
  billingPeriod: BILLING_PERIODS;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

interface BodyItem {
  heading: string;
  content: string;
}

export default class Success extends Component<Signature> {
  @service declare router: Router;
  @service declare appService: any;
  @service declare intl: any;
  @service declare customerService: $TSFixMe;

  get heading() {
    switch (this.args.checkoutType) {
      case CheckoutType.EarlyStagePostPurchase:
      case CheckoutType.EarlyStageFreePostPurchase:
        return this.intl.t('checkout.success.early_stage_post_purchase.heading', {
          trialLengthInDays: this.trialLengthInDays,
        });
      case CheckoutType.ACHPostPurchase:
        return this.intl.t('checkout.success.ach_post_purchase.heading', {
          planName: this.planName,
        });
      case CheckoutType.ACHPostAnnualMigration:
        return this.intl.t('checkout.success.ach_post_annual_migration.heading', {
          billingPeriod: this.billingPeriod,
        });
      case CheckoutType.DirectCheckoutPostPurchase:
        return this.intl.t('checkout.success.direct_checkout_post_purchase.heading', {
          planName: this.planName,
        });
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return this.intl.t('checkout.success.direct_checkout_post_annual_migration.heading', {
          billingPeriod: this.billingPeriod,
        });
      case CheckoutType.OneDollarTrialPostStart:
        return this.intl.t('checkout.success.one_dollar_trial_post_start.heading', {
          trialLengthInDays: this.trialLengthInDays,
        });
    }
  }

  get subheading() {
    switch (this.args.checkoutType) {
      case CheckoutType.EarlyStagePostPurchase:
      case CheckoutType.EarlyStageFreePostPurchase:
        return this.intl.t('checkout.success.early_stage_post_purchase.subheading');
      case CheckoutType.ACHPostPurchase:
        return this.intl.t('checkout.success.ach_post_purchase.subheading');
      case CheckoutType.ACHPostAnnualMigration:
        return this.intl.t('checkout.success.ach_post_annual_migration.subheading');
      case CheckoutType.DirectCheckoutPostPurchase:
        return this.intl.t('checkout.success.direct_checkout_post_purchase.subheading');
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return this.intl.t('checkout.success.direct_checkout_post_annual_migration.subheading');
      case CheckoutType.OneDollarTrialPostStart:
        return this.intl.t('checkout.success.one_dollar_trial_post_start.subheading');
    }
  }

  get body() {
    switch (this.args.checkoutType) {
      case CheckoutType.EarlyStagePostPurchase:
      case CheckoutType.EarlyStageFreePostPurchase:
        return this.earlyStagePostPurchaseBody;
      case CheckoutType.ACHPostPurchase:
        return this.achPostPurchaseBody;
      case CheckoutType.ACHPostAnnualMigration:
        return this.achPostAnnualMigrationBody;
      case CheckoutType.DirectCheckoutPostPurchase:
        return this.directCheckoutPostPurchaseBody;
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return this.directCheckoutPostAnnualMigrationBody;
      case CheckoutType.OneDollarTrialPostStart:
        return this.oneDollarTrialPostStartBody;
    }
  }

  private get earlyStagePostPurchaseBody(): Array<BodyItem> {
    let years = [0, 1, 2];
    return [
      {
        heading: this.intl.t('checkout.success.early_stage_post_purchase.body.now.heading'),
        content: this.intl.t('checkout.success.early_stage_post_purchase.body.now.content', {
          earlyStageProgramName: this.earlyStageProgramName,
        }),
      },
      ...years.map((year) => ({
        heading: this.intl.t(
          'checkout.success.early_stage_post_purchase.body.subscription_continues.heading',
          {
            subscriptionContinuesOnDate: this.formatDate(this.subscriptionContinuesOnDate(year)),
          },
        ),
        content: this.intl.t(
          'checkout.success.early_stage_post_purchase.body.subscription_continues.content',
          {
            year,
            discountPercentage: this.discountPercentage(year),
          },
        ),
      })),
    ];
  }

  private discountPercentage(year: number) {
    return this.earlyStageSolutionId
      ? percentDiscountForSolution(this.earlyStageSolutionId, year + 1)
      : undefined;
  }

  private get earlyStageSolutionId() {
    switch (this.args.checkoutType) {
      case CheckoutType.EarlyStagePostPurchase:
        return PRICING_5_X_EARLY_STAGE_SOLUTION_ID;
      case CheckoutType.EarlyStageFreePostPurchase:
        return PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID;
      default:
        return undefined;
    }
  }

  private get earlyStageSolution(): { marketingNameTranslationKey?: string } | undefined {
    return this.earlyStageSolutionId ? findSolution(this.earlyStageSolutionId) : undefined;
  }

  private get achPostPurchaseBody(): Array<BodyItem> {
    return [
      {
        heading: this.intl.t('checkout.success.ach_post_purchase.body.now.heading'),
        content: this.intl.t('checkout.success.ach_post_purchase.body.now.content'),
      },
      {
        heading: this.intl.t(
          'checkout.success.ach_post_purchase.body.within_four_business_days.heading',
        ),
        content: this.intl.t(
          'checkout.success.ach_post_purchase.body.within_four_business_days.content',
        ),
      },
      {
        heading: this.intl.t(
          'checkout.success.ach_post_purchase.body.subscription_renews.heading',
          {
            subscriptionRenewsOnDate: this.formatDate(this.subscriptionRenewsOnDate),
          },
        ),
        content: this.intl.t(
          'checkout.success.ach_post_purchase.body.subscription_renews.content',
          {
            billingPeriod: this.billingPeriod,
          },
        ),
      },
    ];
  }

  private get achPostAnnualMigrationBody(): Array<BodyItem> {
    return [
      {
        heading: this.intl.t('checkout.success.ach_post_annual_migration.body.now.heading'),
        content: this.intl.t('checkout.success.ach_post_annual_migration.body.now.content', {
          billingPeriod: this.billingPeriod,
        }),
      },
      {
        heading: this.intl.t(
          'checkout.success.ach_post_annual_migration.body.within_four_business_days.heading',
        ),
        content: this.intl.t(
          'checkout.success.ach_post_annual_migration.body.within_four_business_days.content',
        ),
      },
      {
        heading: this.intl.t(
          'checkout.success.ach_post_annual_migration.body.subscription_renews.heading',
          { subscriptionRenewsOnDate: this.formatDate(this.subscriptionRenewsOnDate) },
        ),
        content: this.intl.t(
          'checkout.success.ach_post_annual_migration.body.subscription_renews.content',
          { billingPeriod: this.billingPeriod },
        ),
      },
    ];
  }

  private get directCheckoutPostPurchaseBody(): Array<BodyItem> {
    return [
      {
        heading: this.intl.t('checkout.success.direct_checkout_post_purchase.body.now.heading'),
        content: this.intl.t('checkout.success.direct_checkout_post_purchase.body.now.content'),
      },
      {
        heading: this.intl.t(
          'checkout.success.direct_checkout_post_purchase.body.in_one_hour.heading',
        ),
        content: this.intl.t(
          'checkout.success.direct_checkout_post_purchase.body.in_one_hour.content',
        ),
      },
      {
        heading: this.intl.t(
          'checkout.success.direct_checkout_post_purchase.body.subscription_renews.heading',
          { subscriptionRenewsOnDate: this.formatDate(this.subscriptionRenewsOnDate) },
        ),
        content: this.intl.t(
          'checkout.success.direct_checkout_post_purchase.body.subscription_renews.content',
          { billingPeriod: this.billingPeriod },
        ),
      },
    ];
  }

  private get directCheckoutPostAnnualMigrationBody(): Array<BodyItem> {
    return [
      {
        heading: this.intl.t(
          'checkout.success.direct_checkout_post_annual_migration.body.now.heading',
        ),
        content: this.intl.t(
          'checkout.success.direct_checkout_post_annual_migration.body.now.content',
        ),
      },
      {
        heading: this.intl.t(
          'checkout.success.direct_checkout_post_annual_migration.body.in_one_hour.heading',
        ),
        content: this.intl.t(
          'checkout.success.direct_checkout_post_annual_migration.body.in_one_hour.content',
        ),
      },
      {
        heading: this.intl.t(
          'checkout.success.direct_checkout_post_annual_migration.body.subscription_renews.heading',
          {
            subscriptionRenewsOnDate: this.formatDate(this.subscriptionRenewsOnDate),
          },
        ),
        content: this.intl.t(
          'checkout.success.direct_checkout_post_annual_migration.body.subscription_renews.content',
          {
            billingPeriod: this.billingPeriod,
          },
        ),
      },
    ];
  }

  private get oneDollarTrialPostStartBody(): Array<BodyItem> {
    return [
      {
        heading: this.intl.t('checkout.success.one_dollar_trial_post_start.body.now.heading'),
        content: this.intl.t('checkout.success.one_dollar_trial_post_start.body.now.content', {
          planName: this.planName,
        }),
      },
      {
        heading: this.intl.t(
          'checkout.success.one_dollar_trial_post_start.body.trial_ends.heading',
          {
            trialEndsDate: this.formatDate(moment().add(this.trialLengthInDays, 'days')),
          },
        ),
        content: this.intl.t(
          'checkout.success.one_dollar_trial_post_start.body.trial_ends.content',
        ),
      },
    ];
  }

  @action
  continue() {
    this.router.transitionTo(this.nextPage, this.appService.app.id);
  }

  private get nextPage() {
    switch (this.args.checkoutType) {
      case CheckoutType.ACHPostAnnualMigration:
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return 'apps.app.billing.summary';
      default:
        return 'apps.app.index';
    }
  }

  get plan() {
    return this.args.planId ? PLAN_DATA[this.args.planId] : undefined;
  }

  get planName() {
    return this.plan?.name;
  }

  get billingPeriod() {
    return this.args.billingPeriod;
  }

  get trialLengthInDays() {
    return this.appService.app.trialLengthInDays;
  }

  get earlyStageProgramName() {
    return this.intl.t(this.earlyStageSolution?.marketingNameTranslationKey);
  }

  private get firstInvoicedOnDate() {
    return this.args.firstInvoicedOnDate;
  }

  private get subscriptionRenewsOnDate() {
    return moment(this.firstInvoicedOnDate).add(
      1,
      this.billingPeriod === BILLING_PERIODS.Annual ? 'year' : 'month',
    );
  }

  private subscriptionContinuesOnDate(endOfYear: number) {
    return moment(this.firstInvoicedOnDate).add(endOfYear, 'year');
  }

  private get isEarlyStage() {
    return this.args.checkoutType === CheckoutType.EarlyStagePostPurchase;
  }

  private get isEarlyStageFree(): boolean {
    return this.args.checkoutType === CheckoutType.EarlyStageFreePostPurchase;
  }

  private dateFormat() {
    if (
      this.isEarlyStage ||
      this.isEarlyStageFree ||
      this.billingPeriod === BILLING_PERIODS.Annual
    ) {
      return {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
    }
    return {
      day: 'numeric',
      month: 'long',
    };
  }

  private formatDate(date: moment.Moment) {
    return this.intl.formatDate(date, this.dateFormat());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checkout::Success': typeof Success;
    'checkout/success': typeof Success;
  }
}
