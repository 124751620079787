/* RESPONSIBLE TEAM: team-ai-agent */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class ResolutionBotBehaviorsIndexRoute extends IntercomRoute {
  @service declare router: RouterService;

  analytics = {
    section: 'resolution-bot',
    place: 'behaviors-index',
  };

  redirect() {
    this.router.transitionTo('apps.app.fin-ai-agent.custom-answers.index');
  }
}
