/* import __COLOCATED_TEMPLATE__ from './title.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { isBlank } from '@ember/utils';
import { EntityType } from 'embercom/models/data/entity-types';
import { KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES } from 'embercom/lib/knowledge-hub/constants';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { type HighlightedResult } from 'embercom/objects/inbox/knowledge-base/search-result';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';

const FOLDER_ROUTE = 'apps.app.knowledge-hub.folder';
const MACRO_ROUTE = 'apps.app.settings.helpdesk.macros';
export interface Args {
  contentFragment: ContentFragment;
  isSibling?: boolean;
  hasSibling?: boolean;
  isSiblingContentVisible?: boolean;
  toggleSiblingsVisibility?: () => void;
  locales: string[];
  highlightedTitleArray?: HighlightedResult[];
}

type RouteInfo = {
  route: string;
  model1?: string | number;
  model2?: string | number;
  queryParams?: {
    activeContentId: number | string;
    activeContentType: string;
  };
  linkIdentifier: string;
};

export default class Title extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  get title(): string {
    if (this.isFolder && this.contentModel && this.contentModel.title) {
      // If the folder is renamed, we want to use the new title rather than have to refresh the list.
      return this.contentModel.title;
    } else if (this.args.contentFragment.title && !isBlank(this.args.contentFragment.title)) {
      return this.args.contentFragment.title;
    } else {
      return this.intl.t(`knowledge-hub.content-editor.untitled.${objectNames[this.contentType]}`);
    }
  }

  get contentType(): EntityType {
    return this.args.contentFragment.contentType;
  }

  get contentId(): number {
    return this.args.contentFragment.contentId;
  }

  get isFolder() {
    return this.contentType === EntityType.ContentLibraryFolder;
  }

  get isMacro() {
    return this.contentType === EntityType.SavedReply;
  }

  get contentModel() {
    return this.store.peekRecord(
      KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[this.contentType],
      this.contentId,
    );
  }

  @action toggleSiblingsVisibility() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'sibling_locale_visibility_stamp',
      section: 'knowledge_hub',
      context: 'all_content',
      place: 'all_content',
    });

    if (this.args.toggleSiblingsVisibility) {
      this.args.toggleSiblingsVisibility();
    }
  }

  get entityType() {
    return this.contentType;
  }

  get entityId() {
    return this.contentId;
  }

  get entityTypeName(): string {
    return objectNames[this.entityType];
  }

  trackClickEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: this.titleLinkIdentifier,
      section: 'knowledge_hub',
    });
  }

  get titleLinkIdentifier() {
    return this.routeInfo.linkIdentifier;
  }

  get routeInfo(): RouteInfo {
    switch (this.contentType) {
      case EntityType.ContentLibraryFolder:
        return {
          route: FOLDER_ROUTE,
          model1: this.entityId,
          linkIdentifier: 'folder_content_link',
        };
      case EntityType.SavedReply:
        return { route: MACRO_ROUTE, linkIdentifier: 'macro_content_link' };
      default: {
        return {
          route: this.router.currentRouteName,
          queryParams: {
            activeContentId: this.entityId,
            activeContentType: this.entityTypeName,
          },
          linkIdentifier: 'view_content_link',
        };
      }
    }
  }

  get transitionTarget() {
    let { route, model1, model2, queryParams } = this.routeInfo;
    if (model1 && model2) {
      return this.router.urlFor(route, model1, model2, { queryParams });
    } else if (model1) {
      return this.router.urlFor(route, model1, { queryParams });
    } else {
      return this.router.urlFor(route, { queryParams });
    }
  }

  @action transitionToContent() {
    let { route, model1, model2, queryParams } = this.routeInfo;
    if (model1 && model2) {
      this.router.transitionTo(route, model1, model2, { queryParams });
    } else if (model1) {
      this.router.transitionTo(route, model1, { queryParams });
    } else if (queryParams) {
      this.knowledgeHubDrawerEditorService.openViewDrawer(queryParams);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::Title': typeof Title;
    'knowledge-hub/filterable-list/table/cells/title': typeof Title;
  }
}
