/* RESPONSIBLE TEAM: team-proactive-support */

import AllOutboundListController from 'embercom/controllers/apps/app/outbound/all';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class OutboundViewsController extends AllOutboundListController {
  @service outboundHomeService;
  @service intercomEventService;
  @tracked showVideoModal = false;

  queryParams = [];
  wistiaId = 'kdm24m3zm5';

  get bannerData() {
    let bannerData = super.bannerData;

    if (this.outboundHomeService.view) {
      let savedViewData = this.outboundHomeService.view.savedViewData;

      if (savedViewData.translation) {
        bannerData.pageTitle = this.intl.t(
          `content-service.saved-views.${savedViewData.translation}`,
        );
      } else {
        bannerData.pageTitle = this.outboundHomeService.view.name;
      }
    }
    return bannerData;
  }

  @action openVideoModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'video_opened',
      object: 'all_banner',
      context: 'outbound_home',
      linked_to: this.wistiaId,
    });
    this.showVideoModal = true;
  }

  _setQueryParams() {
    this.set('hideSeries', null);
    this.set('selectedObjectTypes', null);
  }
}
