/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import { buildFiltersForDataConfig } from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import { first, flatten, groupBy, intersection, rest } from 'underscore';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type ReportingChartService from './reporting-chart-service';
import type { Value, ViewConfig } from './reporting-chart-service';
import type LogService from './log-service';
import type Report from 'embercom/models/reporting/custom/report';
import type Chart from 'embercom/models/reporting/custom/chart';
import ajax from 'embercom/lib/ajax';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { task } from 'ember-concurrency-decorators';
import { isEmpty, isPresent } from '@ember/utils';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import ViewConfigBuilder from 'embercom/lib/reporting/custom/view-config-builder';
import { getOwner } from '@ember/application';
import moment from 'moment-timezone';
import { formatCsvValue } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { type TaskGenerator } from 'ember-concurrency';
import DataConfigBuilder from 'embercom/lib/reporting/custom/data-config-builder';
import { taskFor } from 'ember-concurrency-ts';
import _ from 'underscore';
import TableDataConfigBuilder from 'embercom/lib/reporting/custom/table-data-config-builder';
import { updateDataConfig } from 'embercom/lib/reporting/flexible/table-datarequest-builder';
import type IntlService from 'embercom/services/intl';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import { type LogicalFilter } from 'embercom/components/reporting/custom/filters';
import { type RawChartData } from './reporting-chart-service';
import { zip } from 'underscore';
import { requestNameFor } from 'embercom/lib/reporting/chart-data-resource-compatible-helper';
import type CsvService from './csv';
import Axis from 'embercom/lib/reporting/flexible/axis';

export interface FlexibleQueryTimeRange {
  start: number;
  end: number;
  interval: 'hour' | 'day' | 'week' | 'month';
  property: string;
}

type SortDirection = 'asc' | 'desc';

type Point = number[]; //[x,y]
type AxisType = 'y' | 'x';

const DATE_WITHOUT_TIME = 'YYYY-MM-DD';
const DATE_WITH_TIME = 'YYYY-MM-DD HH:00';
const CSV_EMPTY_LINE = [[]];

export default class ReportingCsvExport extends Service {
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare reportingChartService: ReportingChartService;
  @service declare intercomEventService: $TSFixMe;
  @service declare logService: LogService;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare csv: CsvService;

  exportCsv(
    renderableChart: RenderableChart,
    chartSeries: ChartSeries,
    filters: LogicalFilter,
    columnsToExport: string[],
    time: FlexibleQueryTimeRange,
    sortBy: string,
    sortDirection: SortDirection,
    includeReportLevelFilters: boolean,
    analyticsEvent: any = {},
    timezone: string,
    sendViaMail: boolean,
  ) {
    if (this.permissionsService.currentAdminCan('can_export_csv')) {
      let metric = chartSeries.metric;
      let supportedAttributeIds = metric.attributesForDataset.map(({ id }) => id);
      // ensure we only export supported attributes, ignoring "time", etc.
      let attributeIdsToExport = intersection(columnsToExport, supportedAttributeIds);
      let filter = buildFiltersForDataConfig(
        renderableChart,
        metric,
        undefined, // parentMetric
        filters,
        includeReportLevelFilters, // include report level filters or not
        metric.datasetId !== 'admin_status_change', // includeMetricAttributeFilter or not
      );
      let params = {
        app_id: this.appService.app.id,
        dataset_id: metric.datasetId,
        filter: JSON.stringify(filter),
        time: JSON.stringify(time),
        sort_by_attribute_id: sortBy,
        sort_direction: sortDirection,
        attribute_ids: JSON.stringify(attributeIdsToExport),
        use_synthetic_data: this.reportingChartService.useSyntheticData,
        time_zone: timezone,
        send_via_mail: JSON.stringify(sendViaMail),
      };
      let url = `/ember/reporting/documents/csv_export?${new URLSearchParams(params).toString()}`;

      this.logService.log({
        messages: 'Exporting reporting CSV', // eslint-disable-line @intercom/intercom/no-bare-strings
        params,
        url,
        admin_id: this.appService.app.currentAdmin.id,
      });

      if (sendViaMail) {
        ajax({ url, type: 'GET' });
        this.notificationsService.notifyConfirmation(
          this.intl.t('reporting.custom-reports.chart.explore-data.export-email-notification', {
            emailAddress: this.appService.app.currentAdmin.email,
          }),
        );
      } else {
        this.downloadFile(url);
      }

      this.intercomEventService.trackAnalyticsEvent({
        ...analyticsEvent,
        action: 'export_csv',
        columns: attributeIdsToExport,
        dataset_id: metric.datasetId,
        date_range_days: Math.ceil((time.end - time.start) / (1000 * 60 * 60 * 24)),
        sort_by: sortBy,
        sort_direction: sortDirection,
        filters: filter,
        report_level: includeReportLevelFilters,
      });
    } else {
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_export_csv');
    }
  }

  downloadFile(url: string) {
    window.location.assign(url);
  }

  //export chart data
  async exportChartData(chart: Chart, report: Report | null) {
    if (this.permissionsService.currentAdminCan('can_export_csv')) {
      // TODO update this to use only the filters from a chosen chartSeries
      // Once the backend stops expecting filters on the chart, this can be removed
      // and we can find a better way to set the filters that should be applied
      let serializedChart = {
        ...chart.serialize(),
        filters: chart.chartSeries.firstObject.filters,
        chart_series: [chart.chartSeries.firstObject.serialize()],
      };
      let payload = {
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        chart: serializedChart,
        report: undefined as any,
      };
      if (report) {
        payload.report = report.serialize();
      }
      await ajax({
        url: '/ember/reporting/conversations/export_csv',
        type: 'POST',
        data: JSON.stringify(payload),
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.explore-data.export-email-notification', {
          emailAddress: this.appService.app.currentAdmin.email,
        }),
      );
    } else {
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_export_csv');
    }
  }

  //export Chart Data Points
  async exportChartDataPoints(
    renderableChart: RenderableChart,
    chart: Chart,
    report: Report | null,
  ) {
    let config = new DataConfigBuilder(renderableChart).buildDataConfig();
    // config.series[0].xAxis.data.applyLimitOnServer = true;
    let dataConfig = config;
    let viewConfig = new ViewConfigBuilder(renderableChart, getOwner(this)).buildViewConfig();

    let tableDataConfig = new TableDataConfigBuilder(renderableChart).buildDataConfig();
    let updatedTableDataConfig = updateDataConfig({
      dataConfig: tableDataConfig,
      timeRange: renderableChart.dateRange,
    });
    let tableViewConfig = renderableChart.buildViewConfigForTable();

    let dataResource = useResource(this, ChartDataResourceCompatible, () => ({
      dataConfig: renderableChart.isTable ? updatedTableDataConfig : dataConfig,
      viewConfig: renderableChart.isTable ? tableViewConfig : viewConfig,
      renderableChart,
    }));

    this.intercomEventService.trackAnalyticsEvent({
      action: 'download_csv',
      object: 'custom_chart',
      custom_chart_id: chart.id || undefined,
      custom_chart_name: chart.title || 'untitled',
      custom_report_id: report?.id || undefined,
      custom_report_name: report?.title || 'untitled',
      currentMetricId: chart.chartSeries.firstObject.metric.id || undefined,
    });
    await taskFor(this.exportCSVTask).perform(renderableChart, chart, dataResource, viewConfig);
  }

  @task({ drop: true }) *exportCSVTask(
    renderableChart: RenderableChart,
    chart: Chart,
    dataResource: any,
    viewConfig: any,
  ): TaskGenerator<any> {
    let filename = `custom_chart_${this._dateFormatter(
      renderableChart.dateRange.startMoment,
      DATE_WITHOUT_TIME,
      renderableChart,
    )}_${this._dateFormatter(
      renderableChart.dateRange.endMoment,
      DATE_WITHOUT_TIME,
      renderableChart,
    )}.csv`;

    while (dataResource.isLoading) {
      yield timeout(ENV.APP._100MS);
    }

    let responses = dataResource.rawChartData.reject((response: any) =>
      response.name?.includes('-previous'),
    );

    let data;
    if (chart.isMultimetric) {
      data = this.handleMultimetricAggregation(responses, renderableChart, chart, viewConfig);
    } else {
      data = this.handleSingleMetricAgreggation(responses, renderableChart, chart, viewConfig);
    }
    yield this.csv.export(data, {
      fileName: filename,
      withSeparator: false,
    });
  }

  private handleSingleMetricAgreggation(
    responses: RawChartData[],
    renderableChart: RenderableChart,
    chart: Chart,
    viewConfig: any,
  ) {
    let mappedResponse = this._mapToSameAxis(responses);
    let groupingTransformation = viewConfig.grouping?.dataTransformation;
    if (groupingTransformation) {
      mappedResponse = groupingTransformation(mappedResponse);
    }

    let rowData = this._buildRowData(mappedResponse, renderableChart);
    let heading = this.buildHeader(rowData, renderableChart, viewConfig);

    if (renderableChart.showTableSummaryRow) {
      let summary = this.buildSummaryRow(rowData, responses[1], renderableChart);
      return this._addMetadata(chart.title, renderableChart)
        .concat(CSV_EMPTY_LINE)
        .concat([heading])
        .concat([summary])
        .concat(this.formatDataForCSVWithoutHeading(rowData, renderableChart, viewConfig));
    } else {
      return this._addMetadata(chart.title, renderableChart)
        .concat(CSV_EMPTY_LINE)
        .concat([heading])
        .concat(this.formatDataForCSVWithoutHeading(rowData, renderableChart, viewConfig));
    }
  }

  private getValueOnAxis(value: Point, axis: AxisType) {
    if (axis === 'y') {
      return value[1];
    }
    return value[0];
  }

  private isSummaryRow(chartData: RawChartData) {
    return chartData.aggregations?.[0]?.name === 'row-summary';
  }

  private buildResponseForSeries(
    name: string,
    indexedAggregation: Record<Value, number>,
    seriesKeys: Value[],
  ): RawChartData {
    return {
      groups: [
        {
          name: '0',
          type: 'time',
          aggregations: [
            { name: '0', values: seriesKeys.map((key) => indexedAggregation[key] ?? undefined) },
          ],
          values: seriesKeys,
        },
      ],
      aggregations: [],
      name,
    };
  }

  private getSeriesKeys(responses: RawChartData[]) {
    return flatten(responses.map((response) => response.groups[0].values))
      .map((value) => value.toString())
      .uniq();
  }

  private formatMultimetricData(
    responses: RawChartData[],
    renderableChart: RenderableChart,
    viewConfig: ViewConfig,
  ) {
    if (!renderableChart.isBrokenDownByTime) {
      let seriesKeys = this.getSeriesKeys(responses);

      // for each metric after the first we want to index the aggregation value by the corresponding value e.g Teammate(1) :40
      // e.g [{key1_metric_2: aggregation1_value_metric_2, key2_metric_2: aggregation2_value_metric_2},
      //       {key1_metric_3: aggregation1_value_metric_3, key2_metric_3: aggregation2_value_metric_3}
      // ]
      // https://github.com/intercom/intercom/issues/345965#issuecomment-2468422511
      let indexedAggregations = responses.map((response) =>
        response.groups[0].values.reduce(
          (acc, current, idx) => {
            acc[current] = response.groups[0].aggregations[0].values[idx];
            return acc;
          },
          {} as { [key in Value]: number },
        ),
      );

      let formattedResponses = indexedAggregations.map((indexedAggregation, idx) =>
        this.buildResponseForSeries(responses[idx].name, indexedAggregation, seriesKeys),
      );

      return this.processMultimetricRawChartData(formattedResponses, renderableChart, viewConfig);
    }
    return this.processMultimetricRawChartData(responses, renderableChart, viewConfig);
  }

  private processMultimetricRawChartData(
    responses: RawChartData[],
    renderableChart: RenderableChart,
    viewConfig: ViewConfig,
  ) {
    let chartSeriesUnit = renderableChart.chartSeries.map(
      (series: ChartSeries) => series.metric.unit,
    );
    let seriesData: Point[][] = responses.map((mappedResponse) => {
      return this._buildRowData(mappedResponse, renderableChart);
    });

    let formattedSeriesData: Point[][] = seriesData.map((seriesPoints: Point[], index) =>
      this._mapViewByValuesToLabels(
        seriesPoints,
        renderableChart,
        viewConfig,
        chartSeriesUnit[index],
      ),
    );

    // get all values on  Y axis per metric
    let valuesOnYAxis = formattedSeriesData.map((series) =>
      this.getValuesOnFromSeries(series, 'y'),
    );

    let valuesOnXAxis =
      formattedSeriesData.firstObject?.map((series) => this.getValueOnAxis(series, 'x')) ?? [];

    let zippedSeriesData = zip(valuesOnXAxis, ...valuesOnYAxis);
    return zippedSeriesData;
  }

  private handleMultimetricAggregation(
    responses: RawChartData[],
    renderableChart: RenderableChart,
    chart: Chart,
    viewConfig: ViewConfig,
  ) {
    let header: string[] = this.multimetricHeaderRow(renderableChart);
    let result = this._addMetadata(chart.title, renderableChart)
      .concat(CSV_EMPTY_LINE)
      .concat([header]);

    if (renderableChart.showTableSummaryRow) {
      let nonSummaryRowChartData = responses.reject((response) => this.isSummaryRow(response));
      let summaryRowChartData = responses.filter((response) => this.isSummaryRow(response));

      let orderedNonSummaryRowChartData = this.orderRawChartData(nonSummaryRowChartData, chart);

      let chartSeriesUnit = renderableChart.chartSeries.map(
        (series: ChartSeries) => series.metric.unit,
      );

      let values: (number | undefined)[] = this.orderRawChartData(
        summaryRowChartData,
        chart,
      ).flatMap((row: RawChartData) => row.aggregations?.[0]?.values);
      let summary = [
        this.intl.t('reporting.custom-reports.chart.summary'),
        ...values.map((value, index) => formatCsvValue(value, chartSeriesUnit[index])),
      ];

      return result
        .concat([summary])
        .concat(
          this.formatMultimetricData(orderedNonSummaryRowChartData, renderableChart, viewConfig),
        );
    } else {
      return result.concat(this.formatMultimetricData(responses, renderableChart, viewConfig));
    }
  }

  getValuesOnFromSeries(series: Point[], axis: AxisType) {
    return series.map((point: Point) => this.getValueOnAxis(point, axis));
  }

  buildSummaryRow(
    rows: Point[][],
    summaryResponse: RawChartData,
    renderableChart: RenderableChart,
  ) {
    if (summaryResponse?.aggregations?.[0]) {
      let value = summaryResponse.aggregations[0].values[0];
      // we want to transform [summary, 21450] => [summary, 2,1450] only format everything after first value
      return [
        this.intl.t('reporting.custom-reports.chart.summary'),
        formatCsvValue(value, renderableChart.metricUnit),
      ];
    }

    let group = summaryResponse.groups[0];
    let aggregation = group.aggregations[0];

    let summaryRow = [
      this.intl.t('reporting.custom-reports.chart.summary'),
      ...Array(rows[0].length - 1).fill('-'),
    ];
    group.values.forEach((gv: any, index: any) => {
      let x = gv;
      let y = isEmpty(aggregation.values[index]) ? '-' : aggregation.values[index];
      let headerIndex = rows[0].findIndex((header: any) => header === x);
      summaryRow[headerIndex] = y;
    });
    // we want to transform [summary, 21450, 9000] => [summary, 2,1450, 9,000] only format everything after first value
    return [
      first(summaryRow),
      ...rest(summaryRow).map((value) => formatCsvValue(value, renderableChart.metricUnit)),
    ];
  }

  _dateFormatter(moment: any, format: any, renderableChart: RenderableChart) {
    return moment.tz(this._getTimezone(renderableChart)).format(format);
  }

  _getTimezone(renderableChart: RenderableChart) {
    if (isPresent(renderableChart.reportState?.timezone)) {
      return renderableChart.reportState.timezone;
    }
    return this.appService.app.timezone;
  }

  _mapToSameAxis(dataResponses: any) {
    return dataResponses[0];
  }

  _buildRowData(dataResponse: RawChartData, renderableChart: RenderableChart) {
    let group = dataResponse.groups[0];
    if (renderableChart.segmentBy) {
      return this._getSegmentedDataAsRows(group);
    }

    let aggregation = group.aggregations[0];

    return group.values.map((gv: any, index: any) => {
      let x = gv;
      let y = isEmpty(aggregation.values[index]) ? '-' : aggregation.values[index];

      return [x, y];
    });
  }

  getValuesFromInnerGroup(innerGroup: any) {
    let seriesNames = innerGroup.values;
    let seriesYValues = innerGroup.aggregations[0].values;
    return _.zip(seriesNames, seriesYValues);
  }

  _getSegmentedDataAsRows(outerGroup: any) {
    let allRows = [];
    let headers = [outerGroup.type];
    for (let column of outerGroup.values) {
      let x = column.value;
      let data = column.groups.flatMap((innerGroup: any) =>
        this.getValuesFromInnerGroup(innerGroup),
      );
      let row = [x];
      for (let [seriesName, y] of data) {
        if (!headers.includes(seriesName)) {
          headers.push(seriesName);
        }
        let index = headers.indexOf(seriesName);
        row[index] = y;
      }
      allRows.push(row);
    }

    return [headers].concat(
      allRows.map((row) => {
        if (row.length < headers.length) {
          for (let i = 1; i < headers.length; i++) {
            if (isEmpty(row[i])) {
              row[i] = '-';
            }
          }
        }
        return row;
      }),
    );
  }

  _addMetadata(chartTitle: any, renderableChart: RenderableChart) {
    chartTitle = chartTitle || this.intl.t('reporting.custom-reports.report.untitled');
    let metadata = [[chartTitle]]
      .concat(CSV_EMPTY_LINE)
      .concat(this._addDetailMetadata(renderableChart));
    return metadata;
  }

  _addDetailMetadata(renderableChart: RenderableChart) {
    let headers = [
      this.intl.t('reporting.custom-reports.chart.metric'),
      this.intl.t('reporting.custom-reports.chart.view-by'),
      this.intl.t('reporting.custom-reports.chart.segment-by'),
      this.intl.t('reporting.custom-reports.chart.csv-headers.export-date'),
      this.intl.t('reporting.custom-reports.chart.csv-headers.timezone'),
      this.intl.t('reporting.custom-reports.chart.csv-headers.from'),
      this.intl.t('reporting.custom-reports.chart.csv-headers.to'),
    ];
    let metric;
    if (renderableChart.isMultimetric) {
      let numberOfAdditionalMetrics = renderableChart.chartSeries.length - 1;
      metric = renderableChart.metricDisplayName.concat(' ').concat(
        this.intl.t('reporting.chart.multimetric-additional-description', {
          numberOfMetrics: numberOfAdditionalMetrics,
        }),
      );
    } else {
      metric = renderableChart.metricDisplayName;
    }

    let viewBy = renderableChart.viewByDisplayName;
    let segmentBy = renderableChart.segmentByDisplayName;
    let now = this._dateFormatter(moment(), DATE_WITHOUT_TIME, renderableChart);
    let timezone = this._getTimezone(renderableChart);
    let from = this._dateFormatter(
      renderableChart.dateRange.startMoment,
      DATE_WITH_TIME,
      renderableChart,
    );
    let to = this._dateFormatter(
      renderableChart.dateRange.endMoment,
      DATE_WITH_TIME,
      renderableChart,
    );
    return [headers, [metric, viewBy, segmentBy, now, timezone, from, to]];
  }

  _containsOtherColumn(chartData: any) {
    return chartData[0].includes(this.intl.t('reporting.custom-reports.chart.csv-headers.other'));
  }

  _moveOtherColumnToEnd(chartData: any) {
    let headers = chartData[0];
    let otherColumnIndex: number = headers.indexOf(
      this.intl.t('reporting.custom-reports.chart.csv-headers.other'),
    );
    let nextColumnIndex = otherColumnIndex + 1;
    while (otherColumnIndex !== headers.length - 1) {
      chartData.forEach((row: any) => {
        let swap = row[nextColumnIndex];
        row[nextColumnIndex] = row[otherColumnIndex];
        row[otherColumnIndex] = swap;
      });
      otherColumnIndex++;
      nextColumnIndex++;
    }
    return chartData;
  }

  _mapValues(chartData: any, fn: any) {
    return chartData.map((row: any) => {
      return row.map((cell: any, index: any) => {
        if (index !== 0) {
          return fn(cell);
        }
        return cell;
      });
    });
  }

  _mapTableValues(chartData: any, metricUnit: string) {
    return this._mapValues(chartData, (value: any) => {
      return formatCsvValue(value, metricUnit);
    });
  }

  _mapSegmentByValuesToLabels(
    unmappedLabels: any,
    renderableChart: RenderableChart,
    viewConfig: any,
  ) {
    let legendMappingFunction = viewConfig.legendMappingFunction;
    return unmappedLabels.map((label: any, index: any) => {
      if (index === 0) {
        return renderableChart.viewByDisplayName;
      } else if (legendMappingFunction) {
        return legendMappingFunction(label);
      }
      return label;
    });
  }

  _mapTimestampValues(chartData: any, renderableChart: RenderableChart) {
    if (renderableChart.viewByTimeInterval === 'day_of_week') {
      return chartData.map((row: any) => {
        let dayLabels = new Axis['day'!]().labels;
        let [first, ...rest] = row;
        return [dayLabels[first - 1], ...rest];
      });
    } else {
      let timeFormatForInterval =
        renderableChart.viewByTimeInterval === 'hour' ? DATE_WITH_TIME : DATE_WITHOUT_TIME;
      return chartData.map((row: any) => {
        let currentMoment = moment.unix(row[0] / 1000);
        row[0] = this._dateFormatter(currentMoment, timeFormatForInterval, renderableChart);
        return row;
      });
    }
  }

  _mapViewByValuesToLabels(
    chartData: any,
    renderableChart: RenderableChart,
    viewConfig: any,
    metricUnit: string,
  ) {
    chartData = this._mapTableValues(chartData, metricUnit);
    let labelMappingFunction = viewConfig.labelMappingFunction;

    if (renderableChart.isBrokenDownByTime) {
      return this._mapTimestampValues(chartData, renderableChart);
    } else if (labelMappingFunction) {
      return chartData.map((row: any) => {
        row[0] = labelMappingFunction(row[0]);
        return row;
      });
    }
    return chartData;
  }

  private multimetricHeaderRow(renderableChart: RenderableChart): string[] {
    let metricNames = renderableChart.chartSeries.map(
      (series: ChartSeries) => series.metricDisplayName,
    );
    let viewBy: string = renderableChart.viewByDisplayName ?? '';

    return [viewBy].concat(metricNames);
  }

  private buildHeader(
    chartData: RawChartData[][],
    renderableChart: RenderableChart,
    viewConfig: ViewConfig,
  ) {
    if (renderableChart.segmentBy) {
      if (this._containsOtherColumn(chartData)) {
        this._moveOtherColumnToEnd(chartData);
      }

      let unmappedLabels = chartData[0].flat();
      return this._mapSegmentByValuesToLabels(unmappedLabels, renderableChart, viewConfig);
    } else {
      let metricDisplayName = renderableChart.metricDisplayName;
      // [month, new conversation, other metric]
      return [renderableChart.viewByDisplayName, metricDisplayName];
    }
  }

  private formatDataForCSVWithoutHeading(
    chartData: RawChartData[][],
    renderableChart: RenderableChart,
    viewConfig: ViewConfig,
  ) {
    if (renderableChart.segmentBy) {
      // the first row in the response for grouped charts contains headers
      if (this._containsOtherColumn(chartData)) {
        this._moveOtherColumnToEnd(chartData);
      }
      // exclude the first row for segmented data
      return this._mapViewByValuesToLabels(
        rest(chartData),
        renderableChart,
        viewConfig,
        renderableChart.metricUnit,
      );
    }
    return this._mapViewByValuesToLabels(
      chartData,
      renderableChart,
      viewConfig,
      renderableChart.metricUnit,
    );
  }

  private orderRawChartData(chartData: RawChartData[], chart: Chart) {
    //NOTE: this function only works for tables as names are unique for them
    let indexedChartData: Record<string, RawChartData[]> = groupBy(chartData, 'name');
    let expectedMetricOrder = chart.chartSeries.map((series: ChartSeries, idx: number) =>
      requestNameFor(idx, series.metric, true),
    );
    return expectedMetricOrder.map((id: string) => indexedChartData[id][0]);
  }
}

declare module '@ember/service' {
  interface Registry {
    reportingCsvExport: ReportingCsvExport;
    'reporting-csv-export': ReportingCsvExport;
  }
}
