/* import __COLOCATED_TEMPLATE__ from './empty-state-series-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    showVideo: () => void;
    showTour: () => void;
  };
}

const EmptyStateSeriesBanner = templateOnlyComponent<Signature>();
export default EmptyStateSeriesBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::EmptyStateSeriesBanner': typeof EmptyStateSeriesBanner;
    'outbound/empty-state-series-banner': typeof EmptyStateSeriesBanner;
  }
}
