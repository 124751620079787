/* RESPONSIBLE TEAM: team-standalone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { not } from '@ember/object/computed';
import { dependentKeyCompat } from '@ember/object/compat';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

const Validations = buildValidations({
  zendeskApiIsConnected: [
    validator('zendesk-api-is-connected', {
      disabled: not('model.stepConfig.shouldCheckZendeskApiConnection'),
      dependentKeys: ['model.isConnectedToZendeskApi', 'model.shouldCheckZendeskApiConnection'],
      messageKey:
        'operator.workflows.visual-builder.create-zendesk-ticket.requires-api-connection-validation-error-message',
    }),
  ],
});

export default class HandoffToZendeskTicket extends Step.extend(Validations) {
  @service declare finStandaloneService: FinStandaloneService;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.handoffToZendeskTicket;
  }

  @dependentKeyCompat
  get isConnectedToZendeskApi() {
    return this.finStandaloneService.zendeskConfig?.isConnected;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation];
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static createNewStep(store: Store): HandoffToZendeskTicket {
    return store.createRecord('operator/visual-builder/step/handoff-to-zendesk-ticket', {
      type: 'operator/visual-builder/step/handoff-to-zendesk-ticket',
    });
  }
}
