/* import __COLOCATED_TEMPLATE__ from './instagram-card.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class InstagramCard extends Component {
  @service appService;
  @service store;
  @service intl;
  @tracked appPackage;

  constructor() {
    super(...arguments);
    this.loadAppPackage.perform();
  }

  @task({ drop: true })
  *loadAppPackage() {
    this.appPackage = yield this.store.findRecord('appstore/app-package', 'instagram-pv4');
  }

  get isCompact() {
    return this.args.compact;
  }

  get app() {
    return this.appService.app;
  }
}
