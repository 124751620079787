/* import __COLOCATED_TEMPLATE__ from './price-usage-breakdown-cards.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import { isPresent, typeOf } from '@ember/utils';
import {
  ANSWER_BOT_ESSENTIAL_ID,
  PRODUCT_TOURS_ESSENTIAL_ID,
  CORE_ID,
  CORE_STARTER_BASE_ID,
  CORE_SUPPORT_ID,
  CORE_ENGAGE_ID,
  CORE_CONVERT_ID,
  CORE_SUPPORT_PRO_ID,
  CORE_SUPPORT_PREMIUM_ID,
  CORE_ENGAGE_PRO_ID,
  CORE_ENGAGE_PREMIUM_ID,
  CORE_CONVERT_PRO_ID,
  PROACTIVE_SUPPORT_BASE_ID,
  ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID,
  CUSTOM_API_RATE_LIMIT_BASE_ID,
  PEOPLE_REACHED_TIER_1_ID,
  PEOPLE_REACHED_TIER_2_ID,
  PEOPLE_REACHED_TIER_3_ID,
  PREMIER_ONBOARDING_ID,
  PREMIER_SUCCESS_ID,
  PREMIER_ONBOARDING_3_TIERS_ID,
  PREMIER_SUPPORT_ID,
  PREMIER_ONBOARDING_BASE_ID,
  PREMIER_ONBOARDING_PLUS_ID,
  PREMIER_SUCCESS_BASE_ID,
  PREMIER_SUPPORT_TIER_1_ID,
  PREMIER_SUPPORT_TIER_2_ID,
  PREMIER_SUPPORT_TIER_3_ID,
  PREMIER_SUPPORT_TIER_4_ID,
  PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID,
  PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID,
  PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID,
  PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID,
  WHATSAPP_BASE_ID,
  SERVICES_PLANS,
  SMS_BASE_ID,
  SURVEYS_BASE_ID,
  SWITCH_BASE_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_ADDON_PLAN_IDS,
  PRICING_5_X_CORE_PLANS,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import {
  ENGAGE_SEAT_TYPE,
  CONVERT_SEAT_TYPE,
  PROACTIVE_SUPPORT_SEAT_TYPE,
  SUPPORT_SEAT_TYPE,
  VBP2_SEAT_LABELS_KEYS,
} from 'embercom/lib/settings/seats/constants';
import { PRICING_METRIC_GROUPS } from 'embercom/lib/purchase/constants';
import { action } from '@ember/object';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { PlanGroupId } from 'embercom/components/billing/summary/price-usage-breakdown-plan-group-header';

const VBP_2_PLAN_GROUPS = [
  {
    planIds: [
      CORE_SUPPORT_PRO_ID,
      CORE_SUPPORT_PREMIUM_ID,
      PROACTIVE_SUPPORT_BASE_ID,
      FIN_AI_COPILOT_BASE_ID,
      ANSWER_BOT_ESSENTIAL_ID,
      WHATSAPP_BASE_ID,
    ],
    id: PlanGroupId.support,
  },
  {
    planIds: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
    id: PlanGroupId.engage,
  },
  {
    planIds: [CORE_CONVERT_PRO_ID],
    id: PlanGroupId.marketing,
  },
  {
    planIds: [
      PEOPLE_REACHED_TIER_1_ID,
      PEOPLE_REACHED_TIER_2_ID,
      PEOPLE_REACHED_TIER_3_ID,
      PRODUCT_TOURS_ESSENTIAL_ID,
      SMS_BASE_ID,
      SURVEYS_BASE_ID,
      SWITCH_BASE_ID,
      ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID,
      CUSTOM_API_RATE_LIMIT_BASE_ID,
    ],
    showUpdatedMetricBannerWhereAppropriate: true,
    id: PlanGroupId.peopleReachedAndAddons,
  },
  {
    planIds: [
      PREMIER_ONBOARDING_BASE_ID,
      PREMIER_ONBOARDING_PLUS_ID,
      PREMIER_SUCCESS_BASE_ID,
      PREMIER_SUPPORT_TIER_1_ID,
      PREMIER_SUPPORT_TIER_2_ID,
      PREMIER_SUPPORT_TIER_3_ID,
      PREMIER_SUPPORT_TIER_4_ID,
      PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID,
      PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID,
      PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID,
      PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID,
    ],
    id: PlanGroupId.additionalServices,
  },
];

const PRICING_5_PLAN_GROUPS = [
  { id: PlanGroupId.pricing5_coreEssential, planIds: [PRICING_5_X_CORE_ESSENTIAL_ID] },
  { id: PlanGroupId.pricing5_coreAdvanced, planIds: [PRICING_5_X_CORE_ADVANCED_ID] },
  { id: PlanGroupId.pricing5_coreExpert, planIds: [PRICING_5_X_CORE_EXPERT_ID] },
  { id: PlanGroupId.addons, planIds: PRICING_5_X_ADDON_PLAN_IDS },
  { id: PlanGroupId.additionalServices, planIds: SERVICES_PLANS },
];

export default class PriceUsageBreakdownCards extends Component {
  @service appService;
  @service customerService;
  @service intercomEventService;
  @service intl;
  @service router;
  @tracked hoveredRow;
  @service purchaseAnalyticsService;
  @service finOptInService;

  get app() {
    return this.appService.app;
  }

  get priceToDisplay() {
    if (this.overridePrice) {
      return this.overridePrice;
    }
    if (this.contract?.currentPrice) {
      return this.contract.currentPrice;
    }
    return this.customerService.originalPrice || this.customerService.currentPrice;
  }

  get customer() {
    return this.customerService.customer;
  }

  get mockContractForVBP2EarlyStageWorkspaces() {
    return this.customer.currentlyOnValueBasedPricingMar2021EarlyStage;
  }

  get contract() {
    return this.args.contract;
  }

  get overridePrice() {
    return this.args.overridePrice;
  }

  get totalText() {
    return this.args.totalText
      ? this.args.totalText
      : this.intl.t('billing.summary.price-usage-breakdown-card.total');
  }

  get plans() {
    return this.overridePrice
      ? this.overridePrice.planIds.map((planId) => this.plan(planId))
      : this.customerService.activePlans;
  }

  get isVbp2SalesLed() {
    if (this.isVbp1) {
      return false;
    }
    let plans = this.plans;

    // We want to display the planGroups only for sales-led VBP 2 products
    if (
      !plans.some((plan) => CORE_STARTER_BASE_ID === plan.id) &&
      plans.some((plan) =>
        [CORE_SUPPORT_ID, CORE_ENGAGE_ID, CORE_CONVERT_ID].includes(plan.product.id),
      )
    ) {
      return true;
    }
    return false;
  }

  get isVbp2() {
    if (
      this.customerService.activePlans.some((plan) => CORE_STARTER_BASE_ID === plan.id) ||
      this.customerService.activePlans.some((plan) =>
        [CORE_SUPPORT_ID, CORE_ENGAGE_ID, CORE_CONVERT_ID].includes(plan.product.id),
      )
    ) {
      return true;
    }
    return false;
  }

  get isVbp1() {
    return this.customerService.activePlans.some((plan) => CORE_ID === plan.product.id);
  }

  get onPricing5_XPlan() {
    return (
      this.customerService.onPricing5_X ||
      this.priceToDisplay?.planIds?.any((planId) =>
        PRICING_5_X_CORE_PLANS.includes(planId.toString()),
      )
    );
  }

  get planGroups() {
    // VBP2 Sales Led
    if (this.isVbp2SalesLed) {
      return this.groupPlansByDefinition(VBP_2_PLAN_GROUPS);
    } else if (this.onPricing5_XPlan) {
      return this.groupPlansByDefinition(PRICING_5_PLAN_GROUPS);
    }
    // VBP2 Self-Serve, VBP1, TPR and PPP
    return [
      {
        showUpdatedMetricBannerWhereAppropriate: true,
        planCards: this.planCards(this.priceToDisplay.breakdown),
      },
    ];
  }

  groupPlansByDefinition(definition) {
    let planGroups = [];
    definition.forEach((planGroupInfo) => {
      let plansInGroup = this.priceToDisplay.breakdown.filter((breakdown) => {
        return breakdown.plan_id && planGroupInfo.planIds.includes(breakdown.plan_id.toString());
      });

      if (plansInGroup.length > 0) {
        let planGroup = {
          showUpdatedMetricBannerWhereAppropriate:
            planGroupInfo.showUpdatedMetricBannerWhereAppropriate,
          planCards: this.planCards(plansInGroup),
          planGroupId: planGroupInfo.id,
          planId: planGroupInfo.planIds?.[0],
        };
        planGroups.push(planGroup);
      }
    });
    if (this.appService.app.canUseFinStandalone) {
      return planGroups.reject((planGroup) => planGroup.planGroupId === PlanGroupId.addons);
    }
    return planGroups;
  }

  planCards(plansInPlanGroup) {
    let planCards = [];
    plansInPlanGroup.forEach(({ amount, charges, plan_id }) => {
      if (typeOf(plan_id) !== 'number') {
        return;
      }

      charges = charges.toArray();

      let planCard = {};
      let planCardRowsByCharge = {};
      if (plan_id) {
        planCard.planId = plan_id;
        planCard.amount = amount;
        planCard.charges = charges;

        if (!this.onPricing5_XPlan) {
          // Deprecated - we're trying to avoid using a top-level, row-based approach. Instead, generate content within the relevant child component.
          this.productBreakdownFromCharges(charges, plan_id).forEach(
            ({
              charge,
              pricingMetric,
              metricIdentifier,
              metricDisplayName,
              additionalPriceUnderContract,
              overagePrice,
              includedUsage,
              actualUsage,
              actualUsageAsInt,
              seatFilterValue,
              seatLabel,
              filterType,
              isSeatMetric,
              perWorkspaceUsage,
              additionalContractedTooltipData,
              overageTooltipData,
              groupMetric,
            }) => {
              let chargeRows = [];
              let shouldUseBillingCycleUI =
                // This condition is temporarily duplicated here and in the ChargeBreakdown component, while we refactor this component away from the row-based structure.
                // It's necessary to avoid showing the billing cycle metric twice, as we don't want to generate any rows for these charges.
                this.customer.showBillingCycleMetricsInBillingSummary &&
                (this.contract?.isPrimarySubscription || !this.app.isSalesforceContracted) &&
                pricingMetric?.usesBillingCycleMetric &&
                String(plan_id) !== SMS_BASE_ID;
              if (metricDisplayName && !shouldUseBillingCycleUI) {
                // this row shows the included usage for given metric
                // - for non-contracted workspaces it shows usage that's included for free and no price
                // - for contracted workspaces it shows the usage that's included for free plus any additional
                //   contractually included usage; it only shows the price if there is additional usage included
                //   under contract
                chargeRows.push({
                  name: this.metricIncludedText(
                    metricDisplayName,
                    plan_id,
                    metricIdentifier,
                    isSeatMetric,
                    groupMetric,
                  ),
                  groupedMetricsComponent: groupMetric ? 'group-included-usage-row' : null,
                  price:
                    (this.contract || this.mockContractForVBP2EarlyStageWorkspaces) &&
                    additionalPriceUnderContract
                      ? this.formatPriceFromCents(additionalPriceUnderContract, 2)
                      : null,
                  additionalContractedTooltipData,
                  usage: includedUsage,
                  rowType: 'pricing-metric',
                  testIdentifier: `included-usage-${metricIdentifier}`,
                  metricIdentifier,
                  isSalesforceContracted: this.app.isSalesforceContracted,
                  showLine:
                    (this.contract || this.mockContractForVBP2EarlyStageWorkspaces) &&
                    additionalPriceUnderContract &&
                    !this.shouldHidePrices,
                  actualUsageAsInt,
                  isSecondarySubscription: this.contract?.isSecondarySubscription,
                });

                if (plan_id.toString() !== ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID) {
                  // this row shows the current usage for given metric
                  // - for non-contracted workspaces it shows the actual usage and the price for the overage if any
                  // - for contracted workspaces it shows the actual usage in relation to the included usage and the price
                  //   for the overage if any
                  if (actualUsage) {
                    chargeRows.push({
                      metricDisplayName,
                      name: this.intl.t('billing.summary.price-usage-breakdown-card.current-usage'),
                      price: overagePrice
                        ? this.intl.t('billing.summary.price-usage-breakdown-card.plus-price', {
                            price: this.formatPriceFromCents(overagePrice, 2),
                          })
                        : null,
                      usage: actualUsage,
                      includeLinkToTeammatesPage:
                        isSeatMetric && this.shouldLinkToTeammatesPageOnCurrentUsageRow,
                      seatFilterValue,
                      seatLabel,
                      groupedMetricsComponent: groupMetric ? 'group-current-usage-row' : null,
                      filterType,
                      overageTooltipData,
                      rowType: 'usage-detail',
                      testIdentifier: `actual-usage-${metricIdentifier}`,
                      showLine: overagePrice && !this.shouldHidePrices,
                      metricIdentifier,
                      isSalesforceContracted: this.app.isSalesforceContracted,
                      actualUsageAsInt,
                      isSecondarySubscription: this.contract?.isSecondarySubscription,
                      hasSingleWorkspace: this.contract?.numberOfSecondarySubscriptions === 0,
                      charge,
                    });
                  }

                  // this row shows the per workspace usage (only for contracted workspaces)
                  if (this.contract?.isPrimarySubscription && perWorkspaceUsage?.length > 1) {
                    perWorkspaceUsage?.forEach(({ name, usage, isPrimaryWorkspace }) => {
                      chargeRows.push({
                        name,
                        usage,
                        includeLinkToTeammatesPage: isSeatMetric && isPrimaryWorkspace,
                        seatFilterValue,
                        seatLabel,
                        filterType,
                        rowType: 'usage-detail',
                        textStyle: 'muted',
                        testIdentifier: `workspace-usage-${name}-${metricIdentifier}`,
                        charge,
                      });
                    });
                  }
                }
              }
              planCardRowsByCharge[charge.pricing_metric] = chargeRows;
            },
          );
        }

        planCard.planCardRowsByCharge = planCardRowsByCharge;
        planCards.push(planCard);
      }
    });
    return planCards;
  }

  get shouldLinkToTeammatesPageOnCurrentUsageRow() {
    return (
      !this.app.isSalesforceContracted ||
      (this.contract?.isPrimarySubscription &&
        this.contract.numberOfSecondarySubscriptions === 0) ||
      this.contract?.isSecondarySubscription
    );
  }

  metricIncludedText(metricDisplayName, planId, metricIdentifier, isSeatMetric, groupMetric) {
    if (
      (ANSWER_BOT_ESSENTIAL_ID === planId.toString() || WHATSAPP_BASE_ID === planId.toString()) &&
      metricIdentifier === 'support_seat_count'
    ) {
      return this.intl.t(
        'billing.summary.metric-included-text.answer-bot-or-whats-app-support-seat',
      );
    } else if (ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID === planId.toString()) {
      return this.intl.t('billing.summary.metric-included-text.advanced-workspace-management');
    } else if (
      (this.contract?.isPrimarySubscription || this.contract?.isSecondarySubscription) &&
      groupMetric
    ) {
      return this.intl.t(
        'billing.summary.metric-included-text.is-primary-subscription-inside-metric-group',
        {
          metricDisplayName,
        },
      );
    } else if (this.customerService.isSelfServeAnnualCustomer && isSeatMetric) {
      return this.intl.t(
        'billing.summary.metric-included-text.is-self-serve-annual-plan-usage-display-seats-metric',
        {
          metricDisplayName,
          htmlSafe: true,
        },
      );
    }

    return this.intl.t('billing.summary.metric-included-text.default', {
      metricDisplayName,
      htmlSafe: true,
    });
  }

  isAddon(planId) {
    return this.plan(planId).product.addon;
  }

  isPPPSelfServe() {
    return this.appService.app.canUsePerProductPricingFlow;
  }

  plan(planId) {
    return this.allPlansForPricingModel.find((plan) => plan.idAsNumber === planId);
  }

  get shouldHidePrices() {
    return this.contract?.isSecondarySubscription;
  }

  get shouldShowDiscounts() {
    return (
      (!this.app.isSalesforceContracted &&
        !(this.customer.currentlyOnEarlyStage && this.args.hasPendingStripeMigration)) ||
      this.contract?.isPrimarySubscription
    );
  }

  get discountRows() {
    if (this.shouldShowDiscounts) {
      let discountRows = [];
      this.priceToDisplay.breakdown.forEach(({ amount, plan_id }) => {
        // no plan id means this is a discount
        if (!plan_id) {
          discountRows.push({
            name: this.discountInfo,
            price: this.formatPriceFromCents(amount, 2),
          });
        }
      });
      if (this.shouldShowLegacyDiscount()) {
        discountRows.push({
          name: this.intl.t('billing.summary.price-usage-breakdown-card.discounts.bundle-discount'),
          price: this.formatPriceFromCents(-this.legacyDiscountTotal(), 2),
        });
      }
      return discountRows;
    }
  }

  //start of code for shared base display
  displayProductTotals() {
    if (!this.customerService.prices) {
      return '';
    }
    return this.customerService.products.filterBy('active').map((product) => {
      let planId = product.activePlan.idAsNumber;
      if (!planId) {
        planId = this.product.plans.firstObject.idAsNumber;
      }
      return this.customerService.prices.find((price) => price.hasSamePlans([planId]))
        .preDiscountAmount;
    });
  }

  displayProductTotal() {
    return this.displayProductTotals().reduce((partialSum, a) => partialSum + a, 0);
  }

  legacyDiscountTotal() {
    return this.displayProductTotal() - this.customerService.currentPrice.preDiscountAmount;
  }

  shouldShowLegacyDiscount() {
    if (!this.isSharedBase()) {
      return false;
    }

    if (this.customerService.currentPrice.breakdown && !this.customer.currentlyOnEarlyStage) {
      return this.legacyDiscountTotal() > 0;
    }
    return false;
  }

  isSharedBase() {
    return this.customerService.currentPrice?.breakdown.any((breakdown) => {
      return this.isABaseCharge(breakdown.plan_id);
    });
  }

  isABaseCharge(plan_id) {
    if (typeOf(plan_id) !== 'string') {
      return false;
    }

    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    return plan_id?.includes('base') || plan_id?.includes('Base');
  }

  //end of code for shared base display

  get showSelectAddonCard() {
    return (
      !this.customerService.onPricing5_X &&
      !this.contract?.isSecondarySubscription &&
      !this.customerService.products.any((product) => product.content.active && product.addon)
    );
  }

  get showServicesInfoAddonCard() {
    return (
      this.app.isSalesforceContracted &&
      !this.customerService.products.any(
        (product) =>
          product.active &&
          [
            PREMIER_ONBOARDING_ID,
            PREMIER_SUCCESS_ID,
            PREMIER_SUPPORT_ID,
            PREMIER_ONBOARDING_3_TIERS_ID,
          ].includes(product.id),
      )
    );
  }

  formatPriceFromCents(priceInCents, minimumFractionDigits = 0) {
    return this.formatPrice(priceInCents / 100, minimumFractionDigits);
  }

  formatPrice(price, minimumFractionDigits = 0) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
      maximumFractionDigits: 2,
    });
  }

  get allPlansForPricingModel() {
    return this.args.allPlansForGraduationPricingModel
      ? this.args.allPlansForGraduationPricingModel
      : this.customerService.plans;
  }

  get discountInfo() {
    let { hasPercentOffCoupon, hasFixedPriceCoupon, couponPercentOff, couponFixedAmountOff } =
      this.priceToDisplay;
    let couponExpiryDate = this.customer.couponExpiresAt
      ? this.intl.formatDate(this.customer.couponExpiresAt, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
      : null;

    if (hasPercentOffCoupon) {
      if (this.contract) {
        return this.intl.t('billing.summary.price-usage-breakdown-card.discounts.percentage-off', {
          couponPercentOff,
        });
      } else if (couponExpiryDate) {
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.discounts.percentage-off-expires-on-date',
          {
            couponPercentOff,
            couponExpiryDate,
          },
        );
      } else {
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.discounts.percentage-off-never-expires',
          { couponPercentOff },
        );
      }
    } else if (hasFixedPriceCoupon) {
      let couponFixedPriceOff = this.formatPriceFromCents(couponFixedAmountOff, 2);
      if (this.contract) {
        return this.intl.t('billing.summary.price-usage-breakdown-card.discounts.fixed-price-off', {
          couponFixedPriceOff,
        });
      } else if (couponExpiryDate) {
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.discounts.fixed-price-off-expires-on-date',
          {
            couponFixedPriceOff,
            couponExpiryDate,
          },
        );
      } else {
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.discounts.fixed-price-off-never-expires',
          {
            couponFixedPriceOff,
          },
        );
      }
    }
  }

  productBreakdownFromCharges(charges, plan_id) {
    if (!charges || this.isFixedTermCharge(charges.firstObject)) {
      return [];
    }

    let breakdown = [];

    // For SMS we want to display metrics in groups based on active phone numbers vs SMS segments sent in regions
    let groupedCharges = PRICING_METRIC_GROUPS.map((metricGroup) => {
      return {
        metricGroup,
        charges: charges
          .filter((charge) => charge.pricing_metric.startsWith(metricGroup))
          .sortBy('pricing_metric'),
      };
    }).filter((group) => {
      return isPresent(group.charges);
    });

    let groupedPricingMetrics = groupedCharges.flatMap((groupCharge) => {
      return groupCharge.charges.map((charge) => {
        return charge.pricing_metric;
      });
    });

    // Charges that aren't accounted for as part of a group
    let ungroupedCharges = charges.filter((charge) => {
      return !groupedPricingMetrics.includes(charge.pricing_metric);
    });
    breakdown.push(...this.createBreakdownFromCharges(ungroupedCharges, plan_id));

    // As of May 2022, grouped charges should only be present for SMS where charges will be grouped by different regions
    // Active phone numbers in the US, UK, Canada and Australia
    // SMS segments sent / received in the US, UK, Canada and Australia
    groupedCharges.map((group) => {
      breakdown.push(...this.createBreakdownFromCharges(group.charges, plan_id, group.metricGroup));
    });

    return breakdown;
  }

  createBreakdownFromCharges(charges, plan_id, groupMetric = null) {
    return charges.map((charge) => {
      let pricingMetric = new PricingMetric(charge, this.intl);
      let metricDisplayName = capitalize(pricingMetric.pluralize(0));
      let seatFilterValue = this.seatFilterValue(charge.pricing_metric);
      let seatLabelKey =
        pricingMetric.metric === 'inbox_seats'
          ? 'settings.seats.inbox'
          : VBP2_SEAT_LABELS_KEYS[seatFilterValue];
      let seatLabel = seatLabelKey ? this.intl.t(seatLabelKey) : null;
      let filterType = seatFilterValue ? 'seat' : null;
      let isSeatMetric = pricingMetric.isSeatMetric;

      return {
        charge,
        pricingMetric,
        metricIdentifier: `${pricingMetric.metric}`,
        metricDisplayName,
        seatFilterValue,
        filterType,
        isSeatMetric,
        seatLabel,
        additionalPriceUnderContract: this.additionalPriceUnderContract(charge, pricingMetric),
        overagePrice: this.overagePrice(charge, pricingMetric),
        includedUsage: this.formattedIncludedUsage(pricingMetric),
        actualUsage: this.formattedActualUsage(charge, pricingMetric, false, groupMetric),
        actualUsageAsInt: this.actualUsage(charge, pricingMetric),
        groupMetric,
        perWorkspaceUsage:
          this.app.isSalesforceContracted &&
          this.contract &&
          this.contract.perWorkspaceUsage.map((workspace) => {
            return {
              name: workspace.app_name,
              usage: this.intl.formatNumber(workspace.usage[charge.pricing_metric]),
              isPrimaryWorkspace:
                this.contract.isPrimarySubscription && workspace.app_id === this.contract.appId,
            };
          }),
        additionalContractedTooltipData: this.additionalContractedTooltipData(
          charge,
          pricingMetric,
        ),
        overageTooltipData: this.overageTooltipData(charge, pricingMetric),
      };
    });
  }

  additionalContractedTooltipData(charge, pricingMetric) {
    // This logic should be moved out of this component and the content should be determined within the tooltip itself
    if (!pricingMetric.isSeatMetric) {
      // The contracted usage breakdown has caused more confusion than it has cleared up in the past
      // so we only show the tooltip for seat metrics which are easy to understand.
      // We can revisit this if people are finding it hard to understand their billing.
      return [];
    }

    if (this.customerService.isSelfServeAnnualCustomer) {
      // Ignoring getting the tooltip to work for annual plans for now
      return [];
    }

    let additionalUsage = this.additionalUsagePurchased(charge, pricingMetric);
    if (additionalUsage > 0) {
      let additionalPrice = this.additionalPriceUnderContract(charge, pricingMetric);
      return {
        pricingMetric,
        additionalUsage,
        additionalPrice,
        baseUsageInfo: this.baseUsageInfo(pricingMetric),
        additionalUsageInfoText: this.additionalUsageInfoText(pricingMetric, additionalUsage),
        formattedAdditionalPrice: this.formattedAdditionalPrice(
          pricingMetric,
          additionalUsage,
          additionalPrice,
        ),
      };
    }
  }

  baseUsageInfo(pricingMetric) {
    return pricingMetric.baseUsage > 0 ? [{ label: pricingMetric.baseUsageInfo }] : [];
  }

  tierInfo(pricingMetric) {
    if (pricingMetric.tiers?.length > 0) {
      let includedUsage = this.includedUsage(pricingMetric);
      let lowestTierIdWithoutBaseUsageTier = pricingMetric.baseUsage > 0 ? 2 : 1;
      let includedUsageLimitTierId =
        this.contract || this.mockContractForVBP2EarlyStageWorkspaces
          ? this.tierIdForUsage(pricingMetric, includedUsage)
          : lowestTierIdWithoutBaseUsageTier;

      let highestTierId = pricingMetric.currentTier.id;
      let lowestTierId;
      if (pricingMetric.chargeModel === 'Volume') {
        lowestTierId = highestTierId;
      } else {
        lowestTierId = Math.max(includedUsageLimitTierId, lowestTierIdWithoutBaseUsageTier);
      }

      let tierRows = this.tierRows(pricingMetric, lowestTierId, highestTierId);
      if (tierRows.length === 1 && tierRows[0].value) {
        return [
          {
            label: `${tierRows[0].label} (${tierRows[0].value})`,
            small: tierRows[0].small,
          },
        ];
      }
      return tierRows.filter(Boolean);
    } else {
      // Treat non-tiered metrics as having a single per unit tier
      return [{ label: this.formatPriceFromCents(pricingMetric.perUnitPrice) }];
    }
  }

  tierRows(pricingMetric, lowestTierId, highestTierId) {
    let filteredTiers = pricingMetric.tiers
      ?.filter(({ id }) => id <= highestTierId && id >= lowestTierId)
      ?.filter(
        ({ starting_unit, ending_unit, price }) =>
          !(price === 0 && starting_unit === 0 && ending_unit === 0),
      );

    return filteredTiers.map(({ id, starting_unit, ending_unit, price, price_format }, index) => {
      if (pricingMetric.isSeatMetric) {
        return { label: this.formatPriceFromCents(pricingMetric.perUnitPrice) };
      }
      let value;
      if (price_format === 'PerUnit') {
        value = this.intl.t('billing.summary.price-usage-breakdown-card.tier-price-per-block', {
          price: this.formatPrice(price * pricingMetric.blockSize),
          blockSize: this.intl.formatNumber(pricingMetric.blockSize),
        });
      } else if (price_format === 'FlatFee') {
        value = this.formatPrice(price);
      }

      let label = this.intl.t(
        'billing.summary.price-usage-breakdown-card.tier-price-per-range-label',
        {
          startingUnit: this.intl.formatNumber(starting_unit),
          endingUnit: this.intl.formatNumber(ending_unit),
        },
      );
      if (id === pricingMetric.tiers.lastObject.id) {
        // Don't show upper bound for top tier because the upper bounds in Zuora are arbitrary
        label = this.intl.t('billing.summary.price-usage-breakdown-card.top-tier-price-label', {
          topTierStart: this.intl.formatNumber(starting_unit - 1),
        });
      }

      return {
        label,
        value,
        noPadding: filteredTiers.length - index > 1,
      };
    });
  }

  overageTooltipData(charge, pricingMetric) {
    // This logic should be moved out of this component and the content should be determined within the tooltip itself

    if (this.overageUsage(charge, pricingMetric) > 0) {
      return {
        pricingMetric,
        formattedActualUsage: this.formattedActualUsage(charge, pricingMetric, true),
        costHeading: this.costHeading(pricingMetric),
        tierInfo: this.tierInfo(pricingMetric),
        totalCostOverageFormula: this.totalCostOverageFormula(charge, pricingMetric),
        overagePrice: this.overagePrice(charge, pricingMetric),
      };
    }
  }

  costHeading(pricingMetric) {
    if (pricingMetric.chargeModel === 'Volume') {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.current-tier',
      );
    }
    if (pricingMetric.isSeatMetric) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.additional-seat',
      );
    }
    if (pricingMetric.isResolutionsMetric) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.cost-per-additional-unit',
        { unitName: capitalize(pricingMetric.pluralize(1)) },
      );
    }
    // We only want to show 'Cost per additional x' if every tier above the included is per unit
    // otherwise it does not make sense
    let includedUsage = this.includedUsage(pricingMetric);
    if (
      pricingMetric.allHigherTiersPerUnit(this.tierIdForUsage(pricingMetric, includedUsage + 1))
    ) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.additional-block',
        { blockSize: this.intl.formatNumber(pricingMetric.blockSize) },
      );
    }
    return this.intl.t(
      'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.pricing-metric',
      { pricingMetric: capitalize(pricingMetric.pluralize(2)) },
    );
  }

  // this returns the following:
  // - overage within 1 tier: "overagePerTier x overagePrice"
  // - overage spanning > 1 tiers: "(overagePerTier1 x overagePriceTier1) + (overagePerTier2 x overagePriceTier2)"...
  totalCostOverageFormula(charge, pricingMetric) {
    if (pricingMetric.tiers?.length > 0) {
      let includedUsage = this.includedUsage(pricingMetric);
      let highestTierId = pricingMetric.currentTier.id;
      let includedTierId = this.tierIdForUsage(pricingMetric, includedUsage);
      if (pricingMetric.chargeModel === 'Volume') {
        let includedTier = pricingMetric.tiers.find(({ id }) => id === includedTierId);
        let overageTier = pricingMetric.tiers.find(({ id }) => id === highestTierId);
        let overageTierPrice = this.formatPrice(overageTier.price);
        let includedTierPrice = this.formatPrice(includedTier.price);
        return `${overageTierPrice} - ${includedTierPrice}`;
      } else {
        let lowestTierId = Math.max(includedTierId, 2);

        let formula = pricingMetric.tiers
          ?.filter(({ id }) => id <= highestTierId && id >= lowestTierId)
          .map(({ starting_unit, ending_unit, price, price_format }) => {
            let value;
            if (price_format === 'PerUnit') {
              value = price * pricingMetric.blockSize;
            } else if (price_format === 'FlatFee') {
              return `${this.formatPrice(price)}`;
            }

            let totalUsage = this.actualUsage(charge, pricingMetric);
            let lowerLimit = Math.max(includedUsage, starting_unit - 1);
            let overagePerTier = Math.min(totalUsage, ending_unit) - lowerLimit;
            overagePerTier /= pricingMetric.blockSize;
            return `${this.intl.formatNumber(Math.ceil(overagePerTier))} x ${this.formatPrice(
              value,
            )}`;
          });

        return this.formattedOverageFormula(formula);
      }
    }
    // If there are no tiers, we build the overage formula as a per unit charge
    let overUsage = this.intl.formatNumber(this.overageUsage(charge, pricingMetric));
    let perUnitPrice = this.formatPriceFromCents(pricingMetric.perUnitPrice);
    return `${overUsage} x ${perUnitPrice}`;
  }

  formattedOverageFormula(formula) {
    if (formula.length === 1) {
      return formula[0];
    }
    let finalText = '';
    formula.forEach((textPart, index) => {
      finalText = finalText.concat(`(${textPart})`);
      if (index < formula.length - 1) {
        finalText = finalText.concat(' + ');
      }
    });
    return finalText;
  }

  formattedIncludedUsage(pricingMetric) {
    let usage;
    if (this.customerService.isSelfServeAnnualCustomer) {
      usage = this.addedUsage(pricingMetric);
    } else {
      usage = this.includedUsage(pricingMetric);
    }
    return this.intl.formatNumber(usage);
  }

  addedUsage(pricingMetric) {
    if (pricingMetric.isSeatMetric) {
      return this.contract.contractUsageLimits[pricingMetric.metric] - pricingMetric.minimumUsage;
    }
    return this.contract.contractUsageLimits[pricingMetric.metric];
  }

  includedUsage(pricingMetric) {
    /**
     *
     * For VBP 2 Early Stage users (all are without a contract)
     * */
    if (this.mockContractForVBP2EarlyStageWorkspaces && pricingMetric.potentialZeroBase) {
      return pricingMetric.minimumUsage;
    }

    return this.contract
      ? this.contract.contractUsageLimits[pricingMetric.metric]
      : pricingMetric.baseUsage;
  }

  tierIdForUsage(pricingMetric, includedUsage) {
    return pricingMetric.tiers?.find(
      (tier) => tier.starting_unit <= includedUsage && tier.ending_unit >= includedUsage,
    ).id;
  }

  formattedAdditionalPrice(pricingMetric, additionalUsage, additionalPrice) {
    if (pricingMetric.isSeatMetric) {
      let perUnitPriceFormatted = this.formatPriceFromCents(pricingMetric.perUnitPrice);
      return this.intl.t('billing.summary.price-usage-breakdown-card.additional-usage-breakdown', {
        numberOfUnits: additionalUsage,
        pricePerUnit: perUnitPriceFormatted,
      });
    }

    return this.formatPriceFromCents(additionalPrice);
  }

  additionalUsageInfoText(pricingMetric, additionalUsage) {
    if (pricingMetric.baseUsage > 0) {
      return this.intl.t('billing.summary.price-usage-breakdown-card.additional-usage', {
        additionalUsage: this.intl.formatNumber(additionalUsage),
        metricName: pricingMetric.pluralize(additionalUsage),
      });
    }
    if (this.contract) {
      return this.intl.t('billing.summary.price-usage-breakdown-card.additional-usage-contract', {
        additionalUsage: this.intl.formatNumber(additionalUsage),
        metricName: pricingMetric.pluralize(additionalUsage),
      });
    }
    // For VBP 2 early users, don't reference the contract as there isn't specifically one
    return this.intl.t('billing.summary.price-usage-breakdown-card.additional-usage-no-contract', {
      additionalUsage: this.intl.formatNumber(additionalUsage),
      metricName: pricingMetric.pluralize(additionalUsage),
    });
  }

  //   pricingMetric.baseUsage (can be either mandatory minimum or free included usage)
  // + additionalUsagePurchased (only for contracted apps)
  // + overageUsage
  // ------------------------------
  // = actualUsage
  additionalUsagePurchased(charge, pricingMetric) {
    // For VBP 2 Early Stage users (all are without a contract)
    if (this.mockContractForVBP2EarlyStageWorkspaces && pricingMetric.potentialZeroBase) {
      return pricingMetric.minimumUsage;
    }

    return this.contract
      ? this.contract.contractUsageLimits[charge.pricing_metric] - pricingMetric.baseUsage
      : 0;
  }

  overageUsage(charge, pricingMetric) {
    return (
      this.actualUsage(charge, pricingMetric) -
      this.additionalUsagePurchased(charge, pricingMetric) -
      pricingMetric.baseUsage
    );
  }

  //   plan base price (base fee + the cost of mandatory minimum usage (when present))
  // + additionalPriceUnderContract (cost of any extra usage purchased outside of what is included in the plan base price)
  // + overage price
  // ------------------------------
  // = full price
  additionalPriceUnderContract(charge, pricingMetric) {
    if (this.additionalUsagePurchased(charge, pricingMetric) > 0) {
      let contractedUsageLimit = this.includedUsage(pricingMetric);

      if (pricingMetric.tiers?.length > 0) {
        let contractedUsageLimitTierId = this.tierIdForUsage(pricingMetric, contractedUsageLimit);

        let tiersWithContractedUsage = charge.tiers.filter((tier) => {
          // When the charge model is volume, only the matching tier should be used
          if (pricingMetric.chargeModel === 'Volume') {
            return tier.id === contractedUsageLimitTierId;
          }
          // Otherwise, all tiers up to the matching tier should be summed
          return tier.id <= contractedUsageLimitTierId;
        });

        let total = 0;
        tiersWithContractedUsage.forEach((tier) => {
          if (tier.price_format === 'FlatFee') {
            total += tier.price;
          } else if (tier.price_format === 'PerUnit') {
            let tierUsageSize;
            if (tier.id < contractedUsageLimitTierId) {
              tierUsageSize = tier.ending_unit - tier.starting_unit + 1;
            } else {
              tierUsageSize = contractedUsageLimit - tier.starting_unit + 1;
            }
            total += tierUsageSize * tier.price;
          }
        });
        return total * 100 - pricingMetric.basePrice;
      } else {
        // Charges with no tiers are charged per unit
        return (contractedUsageLimit - pricingMetric.baseUsage) * charge.per_unit_price;
      }
    }
    return 0;
  }

  overagePrice(charge, pricingMetric) {
    if (this.contract || this.mockContractForVBP2EarlyStageWorkspaces) {
      return (
        pricingMetric.price -
        pricingMetric.basePrice -
        this.additionalPriceUnderContract(charge, pricingMetric)
      );
    }

    return pricingMetric.additionalPrice;
  }

  actualUsage(charge, pricingMetric) {
    return this.contract ? this.contract.totalUsage[charge.pricing_metric] : pricingMetric.usage;
  }

  /**
   *
   * The following code is used to display the actual usage for the charge.
   * It does this by checking if the charge is a group metric, and if so, it will display the total usage for the group.
   * If the charge is not a group metric, it will display the usage for the workspace.
   * Depending on the workspace's contract, it will display the usage for the workspace, or the usage for the workspace's group.
   */
  formattedActualUsage(charge, pricingMetric, tooltip = false, groupMetric = null) {
    let usageMetric = pricingMetric.pluralize(charge.billed_usage);

    if (!usageMetric) {
      // articles - fixed price
      return;
    } else if (this.contract && !this.contract.isSecondarySubscription && groupMetric) {
      return this.intl.formatNumber(this.actualUsage(charge, pricingMetric));
    } else if (
      (this.contract && !this.contract.isSecondarySubscription) ||
      this.mockContractForVBP2EarlyStageWorkspaces
    ) {
      return this.actualUsageTextForIncludedUsage(charge, pricingMetric);
    } else if (this.contract?.isSecondarySubscription) {
      let usageAmount = this.contract.perWorkspaceUsage.find(
        (workspace) => workspace.app_id === this.app.id,
      ).usage[charge.pricing_metric];
      return this.intl.formatNumber(usageAmount);
    } else {
      // uncontracted
      if (tooltip) {
        return this.actualUsageTextForIncludedUsage(charge, pricingMetric);
      }
      return pricingMetric.formattedUsage;
    }
  }

  actualUsageTextForIncludedUsage(charge, pricingMetric) {
    let actualUsage = this.actualUsage(charge, pricingMetric);
    if (!actualUsage) {
      return this.intl.formatNumber(0);
    }
    let includedUsage = this.includedUsage(pricingMetric);
    let actualUsageFormatted = this.intl.formatNumber(actualUsage);
    let includedUsageFormatted = this.intl.formatNumber(includedUsage);
    if (includedUsage === 0) {
      return actualUsageFormatted;
    } else if (charge.isSeatMetric) {
      return this.intl.t('billing.summary.price-usage-breakdown-card.actual-of-included-usage', {
        actualUsage: actualUsageFormatted,
        includedUsage: includedUsageFormatted,
      });
    } else {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.actual-of-included-usage-percent',
        {
          percentUsed: this.intl.formatNumber(actualUsage / includedUsage, {
            style: 'percent',
            maximumFractionDigits: 0,
          }),
          actualUsage: actualUsageFormatted,
          includedUsage: includedUsageFormatted,
        },
      );
    }
  }

  seatFilterValue(pricing_metric) {
    return {
      engage_seat_count: ENGAGE_SEAT_TYPE,
      marketing_seat_count: CONVERT_SEAT_TYPE,
      proactive_support_seat_count: PROACTIVE_SUPPORT_SEAT_TYPE,
      support_seat_count: SUPPORT_SEAT_TYPE,
    }[pricing_metric];
  }

  isFixedTermCharge(charge) {
    return charge?.fixed_term_charge;
  }

  get hasBillingCycleMetrics() {
    if (!this.customer.showBillingCycleMetricsInBillingSummary) {
      return false;
    }
    // If the customer hasn't opted-in to Fin, we should only show the banner if there are other billing cycle metrics
    let allPricingMetrics = this.priceToDisplay.breakdown
      .map((breakdownItem) => breakdownItem.charges.map((charge) => charge.pricingMetricModel))
      .flat()
      .compact();
    let billingCyclePricingMetrics = allPricingMetrics.filter(
      (pricingMetric) => pricingMetric.usesBillingCycleMetric,
    );
    return (
      billingCyclePricingMetrics.length > 1 ||
      (billingCyclePricingMetrics.length === 1 &&
        billingCyclePricingMetrics[0].metric !== Metric.resolutions)
    );
  }

  @action transistionToIntershop(planId) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      place: 'billing_summary',
      object: 'free_trial_card',
      plan_id: planId,
    });

    this.router.transitionTo(
      'apps.app.intershop.add-ons.add-on.pricing',
      this.plan(planId).product.get('slug'),
    );
  }
}
