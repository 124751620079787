/* import __COLOCATED_TEMPLATE__ from './recipient-manager.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { ReplyChannelType, type ReplyChannel } from 'embercom/objects/inbox/composer-pane';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type { NewConversation } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import { Channel } from 'embercom/models/data/inbox/channels';
import { trackedReset } from 'tracked-toolbox';
import { ReplyRecipientsModel } from 'embercom/lib/composer/reply-recipients';

interface RecipientManagerArgs {
  onBlur?: () => void;
  conversation: Conversation | NewConversation;
  recipients: ReplyRecipientsModel;
  originalRecipients: ReplyRecipientsModel;
  setRecipientErrors: (
    recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[],
  ) => void;
  isCreatingConversation?: boolean;
  replyChannel: ReplyChannel;
  replyToData?: Array<UserSummary>;
  isSideConversationComposer?: boolean;
  onRecipientChange: (recipientsList: ReplyRecipientsModel) => void;
}

export default class RecipientManagerComponent extends Component<RecipientManagerArgs> {
  @service declare session: Session;
  @service declare intercomEventService: $TSFixMe;
  @trackedReset('args.recipients') ccRecipients = this.args.recipients?.cc || [];
  @trackedReset('args.recipients') toRecipients = this.args.recipients?.to || [];
  @trackedReset('args.conversation') showCc = this.showCcByDefault();
  @tracked focusStates = {
    cc: false,
  };
  originalToRecipients = this.args.originalRecipients?.to || [];
  originalCcRecipients = this.args.originalRecipients?.cc || [];

  get ccEnabled(): boolean {
    return (
      this.isEmailCurrentChannel && this.session.workspace.isFeatureEnabled('team-channels-cc')
    );
  }

  showCcByDefault() {
    let anyExistingCcRecipients = this.ccRecipients.length > 0;
    let anyOriginalCcRecipients = this.originalCcRecipients.length > 0;
    return this.isEmailCurrentChannel && (anyExistingCcRecipients || anyOriginalCcRecipients);
  }

  sortEmailOnlyLast(a: UserSummary, b: UserSummary) {
    if (a.id && !b.id) {
      return -1;
    }
    if (!a.id && b.id) {
      return 1;
    }
    return 0;
  }

  updateRecipients() {
    if (!this.args.onRecipientChange) {
      return;
    }
    let sortedToRecipients = this.toRecipients.sort(this.sortEmailOnlyLast);
    let sortedCCRecipients = this.ccRecipients.sort(this.sortEmailOnlyLast);
    let updatedRecipients = new ReplyRecipientsModel(sortedToRecipients, sortedCCRecipients);

    this.args.onRecipientChange(updatedRecipients);
  }

  enableFocusState(field: keyof typeof this.focusStates) {
    this.focusStates[field] = true;
    Object.keys(this.focusStates).forEach((key) => {
      if (key !== field) {
        this.focusStates[key as keyof typeof this.focusStates] = false;
      }
    });
  }

  get isEmailCurrentChannel() {
    let currentChannel = (this.args.conversation as Conversation)?.channel.current;
    return currentChannel
      ? currentChannel === Channel.Email
      : this.args.replyChannel.type === ReplyChannelType.Email;
  }

  //TODO Can this and the below method be cleaned up? Do we need to manually call updateRecipients?
  @action
  onToRecipientChange(recipientsList: UserSummary[]) {
    this.toRecipients = recipientsList;
    this.updateRecipients();
  }

  @action
  onCcRecipientChange(recipientsList: UserSummary[]) {
    this.ccRecipients = recipientsList;
    this.updateRecipients();
  }

  @action
  triggerCc() {
    this.enableFocusState('cc');
    this.showCc = true;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'cc_label',
      section: 'composer',
      conversation_id: this.args.conversation?.id ?? null,
      is_side_conversation: this.args.isSideConversationComposer,
    });
  }

  @action
  handleBlur(): void {
    if (this.args.onBlur) {
      this.args.onBlur();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::RecipientManager': typeof RecipientManagerComponent;
    'inbox2/composer/recipient-manager': typeof RecipientManagerComponent;
  }
}
