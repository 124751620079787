/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { type Block } from 'embercom/models/common/blocks/block';
import ComposerConfig from 'embercom/objects/workflows/graph-editor/chat-message/composer-config';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import type FinCustomConfiguration from 'embercom/objects/visual-builder/configuration/step/fin-custom';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

interface Arguments {
  step: FinCustom;
  stepConfig: FinCustomConfiguration;
  editorState: EditorState;
  isReadOnly: boolean;
  onAddOutputParam: () => void;
  onRemoveOutputParam: (outputParam: OutputParam) => void;
  outputParams: OutputParam[];
}
export default class Settings extends Component<Signature> {
  blocksDoc: BlocksDocument = new BlocksDocument([]);
  @service intl: any;
  @service appService: any;
  @service attributeService: any;
  @service store: any;
  @service contentEditorService: any;

  @tracked composerApi: any;
  @tracked openInserters: any;
  @tracked selectedTab = 'configure_section';
  @tracked allowMultiTurn = this.args.step.allowMultiTurn;
  @tracked allowContentSearch = this.args.step.allowContentSearch;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    // @ts-ignore - SyncHasMany is compatible with BlocksDocument but types don't match
    this.blocksDoc = new BlocksDocument(this.args.step.blocks);

    // Initialize allowMultiTurn based on feature flag if not already set
    if (
      this.args.step.allowMultiTurn === undefined &&
      this.app.canUseFeature('answerbot-fin-agentic-step')
    ) {
      this.args.step.set('allowMultiTurn', true);
      this.allowMultiTurn = true;
    }
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    let config = new ComposerConfig({
      app: this.app,
      resolver: this.args.stepConfig.attributeInfoResolver,
      placeholder: this.intl.t(
        'operator.workflows.visual-builder.fin-custom-settings.prompt-placeholder',
      ),
      allowMentions: false,
    });

    config.isInteractivityDisabled = this.args.isReadOnly;
    return config;
  }

  get canShowAgenticStepToggle() {
    return this.app.canUseFeature('answerbot-fin-agentic-step');
  }

  get canShowContentSearchToggle() {
    return this.app.canUseFeature('answerbot-fin-agentic-content-search');
  }

  get shouldShowAgenticSettings() {
    return this.canShowAgenticStepToggle && this.canShowContentSearchToggle;
  }

  @action
  onSelectionChange(sectionId: string) {
    this.selectedTab = sectionId;
  }

  @action
  updateBlocks(blocksDoc: BlocksDocument) {
    let store = this.store;
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(store, block),
    );
    this.args.step.set('blocks', blockFragments);
    this.args.step.notifyPropertyChange('blocks');
  }

  @action
  toggleAllowMultiTurn() {
    this.allowMultiTurn = !this.allowMultiTurn;
    this.args.step.set('allowMultiTurn', this.allowMultiTurn);
  }

  @action
  toggleAllowContentSearch() {
    this.allowContentSearch = !this.allowContentSearch;
    this.args.step.set('allowContentSearch', this.allowContentSearch);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/fin-custom/settings': typeof Settings;
  }
}
