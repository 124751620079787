/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type Token from 'embercom/models/custom-authentication/token';
import type Router from '@ember/routing/router-service';

interface Args {
  customAuthenticationToken: Token;
}

export default class Editor extends Component<Args> {
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare store: Store;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;
  @service declare appService: any;

  @tracked customAuthenticationToken = this.args.customAuthenticationToken;

  get getIndexRoute() {
    return 'apps.app.settings.app-settings.authentication.index';
  }

  get app() {
    return this.appService.app;
  }

  @dropTask
  *saveChanges() {
    let editTokenRoute = 'apps.app.settings.app-settings.authentication.edit';
    try {
      // Typescript throws an error when directly using the model's computed attribute, so we need to convert it to a boolean
      // eslint-disable-next-line no-extra-boolean-cast
      let wasNew = !!this.customAuthenticationToken.isNew;
      yield this.customAuthenticationToken.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.custom-authentication-tokens.new.save-success'),
      );
      if (wasNew) {
        this.router.transitionTo(editTokenRoute, this.customAuthenticationToken.id);
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('settings.custom-authentication-tokens.new.save-error'),
      );
    }
  }

  get isSaveDisabled() {
    return !this._tokenIsValid() || !this._sufficientlyTested();
  }

  _tokenIsValid() {
    // Typescript throws an error when directly using the model's computed attribute, so we need to convert it to a boolean
    // eslint-disable-next-line no-extra-boolean-cast
    return !!this.customAuthenticationToken.isNew
      ? this.customAuthenticationToken.isValidForCreate
      : this.customAuthenticationToken.isValidForUpdate;
  }

  _sufficientlyTested() {
    if (
      this.customAuthenticationToken.tokenType !== 'http_request' &&
      this.customAuthenticationToken.tokenType !== 'oauth'
    ) {
      return true;
    }

    if (!this.customAuthenticationToken.isNew && this._requestParamsUnchanged()) {
      return true;
    }

    if (this.customAuthenticationToken.tokenType === 'oauth') {
      return this.customAuthenticationToken.oauthTokenConfiguration.sufficientlyTested;
    }

    if (this.customAuthenticationToken.tokenType === 'http_request') {
      return this.customAuthenticationToken.refreshTokenConfiguration.sufficientlyTested;
    }
  }

  _requestParamsUnchanged() {
    if (this.customAuthenticationToken.tokenType === 'oauth') {
      return !Object.keys(this.customAuthenticationToken.changedAttributes()).includes(
        'oauthTokenConfiguration',
      );
    }

    if (this.customAuthenticationToken.tokenType === 'http_request') {
      return !Object.keys(this.customAuthenticationToken.changedAttributes()).includes(
        'refreshTokenConfiguration',
      );
    }

    return false;
  }

  get supportedTypes() {
    let canUseOauthTokenForCustomActions = this.app.canUseOauthTokenForCustomActions;
    let supportedTypes = [
      { text: this.intl.t('settings.custom-authentication-tokens.new.type.text'), value: 'text' },
      {
        text: this.intl.t('settings.custom-authentication-tokens.new.type.http_request'),
        value: 'http_request',
      },
    ];
    if (canUseOauthTokenForCustomActions) {
      supportedTypes.push({
        text: this.intl.t('settings.custom-authentication-tokens.new.type.oauth'),
        value: 'oauth',
      });
    }
    return supportedTypes;
  }

  @action
  setTokenType(tokenType: string) {
    this.customAuthenticationToken.tokenType = tokenType;
    if (tokenType === 'http_request') {
      this.customAuthenticationToken.refreshTokenConfiguration = this.store.createFragment(
        'custom-authentication/refresh-token-configuration',
      );
      this.customAuthenticationToken.oauthTokenConfiguration = null;
    } else if (tokenType === 'text') {
      this.customAuthenticationToken.refreshTokenConfiguration = null;
      this.customAuthenticationToken.oauthTokenConfiguration = null;
    } else if (tokenType === 'oauth') {
      this.customAuthenticationToken.refreshTokenConfiguration = null;
      this.customAuthenticationToken.oauthTokenConfiguration = this.store.createFragment(
        'custom-authentication/oauth-token-configuration',
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::CustomAuthentication::Editor': typeof Editor;
    'settings/custom-authentication/editor': typeof Editor;
  }
}
