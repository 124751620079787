/* import __COLOCATED_TEMPLATE__ from './generic-handoff.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type GenericHandoff from 'embercom/objects/inbox/renderable/generic-handoff';
import type Conversation from 'embercom/objects/inbox/conversation';

export interface GenericHandoffSignature {
  Args: {
    conversation: Conversation;
    part: RenderablePart & { renderableData: GenericHandoff };
  };
}

const GenericHandoffPartComponent = templateOnlyComponent<GenericHandoffSignature>();
export default GenericHandoffPartComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::GenericHandoff': typeof GenericHandoffPartComponent;
    'inbox2/conversation-stream/event-parts/generic-handoff': typeof GenericHandoffPartComponent;
  }
}
