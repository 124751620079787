/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-empty-glimmer-component-classes */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import CustomBotListData from 'embercom/objects/operator/configuration/custom-bots/custom-bot-list-data';
import { inject as service } from '@ember/service';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import {
  AI_AGENT_WORKFLOW_TARGET,
  AI_AGENT_WORKFLOW_TRIGGER,
  CHANNEL_ZENDESK_TICKET,
  CHANNEL_ZENDESK_SUNSHINE_CONVERSATION,
  CHANNEL_SALESFORCE_CASE,
  EVERYONE_PREDICATE,
  TRIGGERABLE_BOT_TYPE,
  CHANNEL_WEB,
} from 'embercom/lib/operator/custom-bots/constants';
import { taskFor } from 'ember-concurrency-ts';
import Ruleset from 'embercom/models/matching-system/ruleset';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import { ajaxDelete } from 'embercom/lib/ajax';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Args {}

export default class StandaloneProcessesList extends Component<Args> {
  listData: CustomBotListData;

  @service declare appService: any;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intercomConfirmService: IntercomConfirmService;

  states = states;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.listData = new CustomBotListData(
      owner,
      this.appService.app.id,
      AI_AGENT_WORKFLOW_TARGET,
      objectTypes.triggerableCustomBot,
    );

    taskFor(this.listData.setupInitialState).perform();
  }

  get isLoadingInitialData() {
    return taskFor(this.listData.setupInitialState).isRunning;
  }

  get processes() {
    return (
      this.listData.bots ??
      ([] as Array<{
        id: string;
        contentWrapperId: string;
        title: string;
        contents: Array<{ contentData: any }>;
      }>)
    );
  }

  @action async createProcess() {
    let targetChannels = [CHANNEL_ZENDESK_SUNSHINE_CONVERSATION, CHANNEL_ZENDESK_TICKET];

    if (this.appService.app.canUseStandaloneSalesforce) {
      targetChannels = [CHANNEL_SALESFORCE_CASE];
    }

    if (this.appService.app.canUseStandaloneIntercomMessenger) {
      targetChannels.pushObject(CHANNEL_WEB);
    }

    let ruleset = await Ruleset.createForType(this.store, {
      app_id: this.appService.app.id,
      match_behavior: matchBehaviors.transient,
      role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
      object_type: objectTypes.triggerableCustomBot,
      object_data: {
        target_channels: targetChannels,
        trigger_type: AI_AGENT_WORKFLOW_TRIGGER,
        type: TRIGGERABLE_BOT_TYPE,
      },
    });
    this.router.transitionTo('apps.app.standalone.processes.workflow', ruleset.id);
  }

  @action async deleteProcess(contentWrapper: { contentWrapperId: string; title: string }) {
    let confirmed = await this.intercomConfirmService.confirm({
      title: `Delete ${contentWrapper.title || 'Untitled process'}`,
      body: 'Are you sure you want to delete this process? This action cannot be undone.',
      primaryButtonType: 'primary-destructive',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    });

    if (!confirmed) {
      return;
    }

    await ajaxDelete(`/ember/matching_system/rulesets/${contentWrapper.contentWrapperId}`, {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
    });

    this.listData.bots = this.listData.bots.filter(
      (bot) => bot.id !== contentWrapper.contentWrapperId,
    );

    taskFor(this.listData.reload).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Processes::List': typeof StandaloneProcessesList;
    'standalone/processes/list': typeof StandaloneProcessesList;
  }
}
