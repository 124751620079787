/* import __COLOCATED_TEMPLATE__ from './suggestion-box.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

type SuggestionBoxSignature = {
  Args: {
    icon: 'fin' | 'hand' | 'rules' | 'language' | 'new' | 'article' | 'long-text';
    title: string;
    description: string;
    buttonLabel: string;
    onClick: () => void;
  };
};

const SuggestionBox = templateOnlyComponent<SuggestionBoxSignature>();
export default SuggestionBox;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::SuggestionBox': typeof SuggestionBox;
  }
}
