/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import Route from '@ember/routing/route';
import $ from 'jquery';
import { bind } from '@ember/runloop';

export default Route.extend({
  activate() {
    $('body').addClass('responsive');
    document.title = 'Canvas Kit Builder';
  },

  deactivate() {
    $('body').removeClass('responsive');
  },

  importModule() {
    import('@intercom/canvas-kit-schema-linter').then((module) => module.default());
  },

  beforeModel() {
    $(document).on('codemirrorloaded', bind(this, this.importModule));
  },

  willDestroy() {
    this._super(...arguments);
    $(document).off('codemirrorloaded');
  },
});
