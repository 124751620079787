/* RESPONSIBLE TEAM: team-knowledge-interop */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const PEOPLE_CHAT_GETS_ICONS = [
  'hand-thumb-up',
  'face-frown',
  'device-tablet',
  'face-smile',
  'hand-thumb-down',
  'user-circle',
  'chat-bubble-bottom-center-text',
  'users',
  'chat-bubble-left-ellipsis',
];

export const PEOPLE_CHAT_GETS_KEYWORDS = {
  [PEOPLE_CHAT_GETS_ICONS[0]]: [
    'thumb up',
    'like',
    'approval',
    'positive',
    'support',
    'good',
    'vote',
    'praise',
    'reaction',
  ],
  [PEOPLE_CHAT_GETS_ICONS[1]]: [
    'face frown',
    'sad',
    'unhappy',
    'emotion',
    'displeasure',
    'mood',
    'frown',
    'negative',
    'expression',
    'vote',
    'reaction',
  ],
  [PEOPLE_CHAT_GETS_ICONS[2]]: [
    'tablet',
    'device',
    'technology',
    'touchscreen',
    'mobile',
    'electronics',
    'gadget',
    'screen',
    'portable',
    'media',
    'smartphone',
  ],
  [PEOPLE_CHAT_GETS_ICONS[3]]: [
    'face smile',
    'happy',
    'positive',
    'emotion',
    'pleasure',
    'mood',
    'joy',
    'expression',
    'friendly',
    'reaction',
  ],
  [PEOPLE_CHAT_GETS_ICONS[4]]: [
    'thumb down',
    'dislike',
    'disapproval',
    'negative',
    'gesture',
    'rejection',
    'bad',
    'vote',
    'criticism',
    'reaction',
  ],
  [PEOPLE_CHAT_GETS_ICONS[5]]: [
    'user',
    'profile',
    'account',
    'person',
    'circle',
    'identity',
    'avatar',
    'social',
  ],
  [PEOPLE_CHAT_GETS_ICONS[6]]: [
    'chat',
    'message',
    'text',
    'communication',
    'conversation',
    'speech',
    'dialogue',
  ],
  [PEOPLE_CHAT_GETS_ICONS[7]]: [
    'user',
    'profile',
    'account',
    'users',
    'people',
    'group',
    'social',
    'team',
    'community',
    'crowd',
    'network',
    'members',
    'msn',
    'people',
  ],
  [PEOPLE_CHAT_GETS_ICONS[8]]: [
    'chat',
    'message',
    'text',
    'communication',
    'conversation',
    'ellipsis',
    'chat',
    'speech',
    'dialogue',
    'bubble',
    'silence',
    'answering',
  ],
};
