/* import __COLOCATED_TEMPLATE__ from './success-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import type { Brand } from './types/brand';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';

interface Args {
  onClose: () => void;
  brand: Brand;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class SuccessModal extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  @action
  handleClose() {
    this.args.onClose();
  }

  @action
  handleClickMessengerStyles() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `messenger_styles_button`,
      place: 'brands',
      brand_id: this.args.brand?.id,
    });

    safeWindowOpen(
      '/a/apps/_/settings/channels/messenger/web?section=general&tab=styling',
      '_blank',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::SuccessModal': typeof SuccessModal;
  }
}
