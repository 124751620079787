/* import __COLOCATED_TEMPLATE__ from './playground.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import {
  deleteRequest,
  postRequest,
  putRequest,
  request,
  ResponseError,
} from 'embercom/lib/inbox/requests';
import { dropTask } from 'ember-concurrency-decorators';
import {
  Playground,
  PlaygroundQuestion,
  Status,
  type CustomerAnswerRating,
  type CustomerRatingReason,
  type QuestionGenerationUpdateEvent,
  QUESTION_GENERATION_UPDATE_EVENT_NAME,
  QuestionGenerationStatus,
  type PlaygroundQuestionWireFormat,
  type PlaygroundQuestionGenerationJobWireFormat,
  PlaygroundQuestionGeneration,
} from 'embercom/lib/fin-playground';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { taskFor } from 'ember-concurrency-ts';
import { cached } from 'tracked-toolbox';
import { use } from 'ember-resources/util/function-resource';
import FinPlaygroundExport from 'embercom/lib/fin-playground-test-export';
import type RouterService from '@ember/routing/router-service';
import type CsvService from 'embercom/services/csv';
import { AI_CHATBOT_CONTENT_CHANGED_ID } from 'embercom/services/real-time-event-service';
import { FinContentStatus } from 'embercom/lib/fin-content-status';

const NEXUS_TOPICS = ['ai-chatbot-content-changed', 'fin-playground'];

const NexusSubscriptionEvents = [
  { eventName: AI_CHATBOT_CONTENT_CHANGED_ID, handler: '_handleContentChangedEvent' },
  { eventName: 'FinPlaygroundQuestionResponse', handler: 'handleAnswerGenerated' },
  {
    eventName: QUESTION_GENERATION_UPDATE_EVENT_NAME,
    handler: '_handleQuestionGenerationResponse',
  },
];

interface ContentChangedEvent {
  content_available_changed_at: Date | undefined;
}

export default class AiAgentPlayground extends Component {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare realTimeEventService: $TSFixMe;
  @service declare csv: CsvService;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  @tracked newQuestion = '';
  @tracked selectedQuestionIndex = 0;
  @tracked isAddingQuestions = false;
  @tracked showResetModal = false;
  @tracked showUploadCSVModal = false;
  @tracked isUpdatingAnswerRating = false;
  @tracked showSuggestionsSideSheet = false;

  generateAnswersTask = taskFor(this._generateAnswers);
  generateQuestionsTask = taskFor(this._generateQuestionsTask);

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this._subscribeToContentChanges();
  }

  willDestroy() {
    super.willDestroy();

    NexusSubscriptionEvents.forEach(({ eventName, handler }) => {
      this.realTimeEventService.off(eventName, this, handler);
    });

    this.realTimeEventService.unsubscribeTopics(NEXUS_TOPICS);
  }

  _subscribeToContentChanges() {
    this.realTimeEventService.subscribeTopics(NEXUS_TOPICS);

    NexusSubscriptionEvents.forEach(({ eventName, handler }) => {
      this.realTimeEventService.on(eventName, this, handler);
    });
  }

  _handleContentChangedEvent(event: ContentChangedEvent) {
    this.finContentStatusLoader.update(FinContentStatus.deserialize(event));
  }

  @cached
  get playground() {
    return (
      this.playgroundLoader.value ??
      new Playground([], new PlaygroundQuestionGeneration(false, undefined, undefined))
    );
  }

  get playgroundIsLoading() {
    return this.playgroundLoader.isLoading;
  }

  get finContentStatus() {
    return this.finContentStatusLoader.value;
  }

  get selectedQuestion() {
    return this.playground.filteredQuestions[this.selectedQuestionIndex];
  }

  get hasQuestions() {
    return this.playground.questions.length > 0;
  }

  get hasQuestionsRunning() {
    return this.playground.questions.some((q) => q.status === Status.Running);
  }

  get isEmptyStateAndGeneratingQuestions(): boolean {
    return !this.hasQuestions && this.playground.questionGeneration.isRunning;
  }

  get deductionRequiredToAddQuestion(): number {
    if (!this.playground.hasReachedQuestionLimit) {
      return 0;
    }

    return 1 + this.playground.questions.length - this.playground.questionLimit;
  }

  get didAnyQuestionRunBeforeLastContentChange(): boolean {
    if (
      this.playgroundLoader.isLoading ||
      this.finContentStatusLoader.isLoading ||
      !this.generateAnswersTask.isIdle
    ) {
      return false;
    }

    let finContentChangedAt = this.finContentStatus?.contentAvailableChangedAt;
    if (!finContentChangedAt) {
      return false;
    }

    return this.playground.questions.some((question) => {
      // Technically the best way would be to compare with start of answer generation rather than `answerLastGeneratedAt`.
      if (!question.answerLastGeneratedAt) {
        return false;
      }

      // finContentChangedAt is already checked for undefined above, so we can safely assert it's presence here.
      return question.answerLastGeneratedAt <= finContentChangedAt!;
    });
  }

  get canShowGenerateAnswerButton(): boolean {
    return (
      this.selectedQuestion &&
      !this.playground.isPending &&
      !this.didAnyQuestionRunBeforeLastContentChange
    );
  }

  get isModalOpen(): boolean {
    return this.showResetModal || this.showUploadCSVModal || this.isAddingQuestions;
  }

  get addQuestionDropdownOptions() {
    let items = [];

    if (
      this.appService.app.canUseFeature('answerbot-fin-playground-auto-bootstrap') &&
      this.playground.questionGeneration.isPossible
    ) {
      items.push({
        text: this.intl.t('ai-agent.playground.add-questions-dropdown.generate-questions'),
        value: 'generate-questions',
      });
    }

    items.push(
      {
        text: this.intl.t('ai-agent.playground.add-questions-dropdown.upload-csv'),
        value: 'upload-csv',
      },
      {
        text: this.intl.t('ai-agent.playground.add-questions-dropdown.add-questions-manually'),
        value: 'add-question',
      },
    );

    return [{ items }];
  }

  @use private finContentStatusLoader = AsyncData<FinContentStatus>(async () => {
    let response = await request(`/ember/fin_content_status?app_id=${this.appService.app.id}`);
    return response.ok ? FinContentStatus.deserialize(await response.json()) : null;
  });

  get isStandaloneApp() {
    return this.appService.app.canUseFinStandalone;
  }

  @use private playgroundLoader = AsyncData<Playground>(async () => {
    let response = await request(`/ember/fin_playground?app_id=${this.appService.app.id}`);
    if (!response.ok) {
      return;
    }

    let playground = Playground.deserialize(await response.json());

    if (playground.pendingQuestions.length > 0) {
      this.generateAnswersTask.perform(playground.pendingQuestions);
    }

    return playground;
  });

  @action
  clearPlayground() {
    taskFor(this._clearPlaygroundTask).perform();
  }

  saveQuestions(list: string[], source: string) {
    let questions = list
      .uniq()
      .filter((q) => q.trim().length > 0 && !this.playground.hasQuestion(q))
      .map((q) => new PlaygroundQuestion(undefined, q));

    if (questions.length === 0) {
      return;
    }

    this.playground.addQuestions(questions);
    taskFor(this._addQuestionsTask).perform(questions);

    this.trackAddingNewQuestions(questions.length, source);
  }

  @action
  addQuestionsFromCsvFile(list: string[]) {
    return this.saveQuestions(list, 'upload_csv_modal');
  }

  @action
  addQuestionsUsingForm(list: string[]) {
    return this.saveQuestions(list, 'add_questions_form');
  }

  @action
  runQuestions() {
    this.playground.questions.forEach(function (question) {
      // We change the status for immediate UI feedback that questions are running
      question.status = Status.Running;
    });

    this.generateAnswersTask.perform(this.playground.pendingQuestions);
  }

  @action
  runSelectedQuestion() {
    if (!this.selectedQuestion?.id) {
      return;
    }

    this.playground.setQuestionState(this.selectedQuestion.id, Status.Running);

    this.generateAnswersTask.perform([this.selectedQuestion]);
  }

  @action
  removeQuestion(question_id?: number) {
    if (!question_id) {
      return;
    }

    taskFor(this._removeQuestionTask).perform(question_id);

    if (this.selectedQuestionIndex === question_id) {
      this.selectedQuestionIndex = 0;
    }
  }

  @action
  generateQuestions() {
    this.generateQuestionsTask.perform();
  }

  @action
  updateQuestionGenerationJob(json: PlaygroundQuestionGenerationJobWireFormat) {
    this.playground.questionGeneration.updateLatestJobData(json);
  }

  @action
  resetQuestionGenerationJob() {
    this.playground.questionGeneration.resetLatestJobData();
  }

  @action
  selectQuestion(questionText: string) {
    this.selectedQuestionIndex =
      this.playground.filteredQuestions.findIndex((q) => q.questionText === questionText) || 0;
  }

  @action incrementSelectedQuestionIndex(e?: Event) {
    e?.preventDefault();
    if (this.selectedQuestionIndex === this.playground.filteredQuestions.length - 1) {
      return;
    }
    this.selectedQuestionIndex++;
  }

  @action decrementSelectedQuestionIndex(e?: Event) {
    e?.preventDefault();
    if (this.selectedQuestionIndex === 0) {
      return;
    }
    this.selectedQuestionIndex--;
  }

  @action setQuestionIndex(index: number) {
    this.selectedQuestionIndex = index;
  }

  @action
  async handleAnswerGenerated(eventData: { question: PlaygroundQuestionWireFormat }) {
    let question = PlaygroundQuestion.deserialize(eventData.question);
    this.playground.replaceQuestionByText(question);
  }

  @action
  updateQuestionRating(
    questionId: number,
    args: { rating?: CustomerAnswerRating; reason?: CustomerRatingReason; note?: string },
  ) {
    let { rating, reason, note } = args;

    this.isUpdatingAnswerRating = true;

    taskFor(this._updateQuestionTask).perform(questionId, rating, reason, note);
  }

  @action
  downloadResults() {
    let { data, fileName } = new FinPlaygroundExport(
      this.appService.app,
      this.intl,
      this.playground,
      this.router,
    );

    this.csv.export(data, {
      fileName,
      withSeparator: false,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'downloaded_results',
      object: 'playground',
    });
  }

  @action
  _handleQuestionGenerationResponse(e: QuestionGenerationUpdateEvent) {
    this.playground.questionGeneration.updateLatestJobData(e);

    if (e.status !== QuestionGenerationStatus.Completed) {
      return;
    }

    if (!e.questions || e.questions.length === 0) {
      return;
    }

    let questions = e.questions.map((question) => PlaygroundQuestion.deserialize(question));

    this.playground.addQuestions(questions || []);
    this.generateAnswersTask.perform(this.playground.pendingQuestions);

    this.trackAddingNewQuestions(e.questions.length, 'ai_bootstrap');
  }

  @dropTask
  *_addQuestionsTask(questions: PlaygroundQuestion[]) {
    if (questions.length === 0) {
      return;
    }

    this.playground.setRunningStatus(questions);

    let response = (yield postRequest(
      `/ember/fin_playground/questions?app_id=${this.appService.app.id}`,
      {
        questions: questions.map((q) => ({ question_text: q.questionText })),
      },
    )) as Response;

    let json = (yield response.json()) as { questions: Array<PlaygroundQuestionWireFormat> };
    for (let question of json.questions) {
      this.playground.replaceQuestionByText(PlaygroundQuestion.deserialize(question));
    }
  }

  @dropTask
  *_removeQuestionTask(question_id: number) {
    yield deleteRequest(
      `/ember/fin_playground/question/${question_id}?app_id=${this.appService.app.id}`,
    );

    this.playground.removeQuestionById(question_id);
  }

  @dropTask
  *_clearPlaygroundTask() {
    let response = (yield postRequest(
      `/ember/fin_playground/clear?app_id=${this.appService.app.id}`,
    )) as Response;
    if (response.ok) {
      this.playgroundLoader.reload();
      this.showResetModal = false;
      this.selectedQuestionIndex = 0;
    }
  }

  @dropTask
  *_updateQuestionTask(
    questionId: number,
    customerAnswerRating?: CustomerAnswerRating,
    customerAnswerRatingReason?: CustomerRatingReason,
    customerAnswerRatingNote?: string,
  ) {
    try {
      let response = (yield putRequest(
        `/ember/fin_playground/question/${questionId}?app_id=${this.appService.app.id}`,
        {
          customer_answer_rating: customerAnswerRating,
          customer_answer_rating_reason: customerAnswerRatingReason,
          customer_answer_rating_note: customerAnswerRatingNote,
        },
      )) as Response;

      if (response.ok) {
        let rawQuestion = (yield response.json()) as PlaygroundQuestionWireFormat;
        let updatedQuestion = PlaygroundQuestion.deserialize(rawQuestion);
        this.playground.replaceQuestionByText(updatedQuestion);
      }
    } catch (err) {
      if (err instanceof ResponseError && err.response.status === 404) {
        this.notificationsService.notifyError(
          this.intl.t('ai-agent.playground.question-deleted-error-message'),
        );
      }
      throw err;
    } finally {
      this.isUpdatingAnswerRating = false;
    }
  }

  @dropTask
  *_generateAnswers(questions?: PlaygroundQuestion[]) {
    this.playground.setRunningStatus(questions);

    let response = (yield postRequest(
      `/ember/fin_playground/generate_answers?app_id=${this.appService.app.id}`,
      {
        question_ids: questions?.map((q) => q.id),
      },
    )) as Response;

    let json = (yield response.json()) as { questions: Array<PlaygroundQuestionWireFormat> };
    let newQuestions = json.questions.map(PlaygroundQuestion.deserialize);
    for (let newQuestion of newQuestions) {
      this.playground.replaceQuestionByText(newQuestion);
    }
  }

  @dropTask
  *_generateQuestionsTask() {
    try {
      let response = (yield postRequest(
        `/ember/fin_playground/generate_questions?app_id=${this.appService.app.id}`,
      )) as Response;

      let json = (yield response.json()) as PlaygroundQuestionGenerationJobWireFormat;
      this.playground.questionGeneration.updateLatestJobData(json);
    } catch (error) {
      // If there is a job in progress we will receive 409
      if (error instanceof ResponseError && error.response.status === 409) {
        let json = (yield error.response.json()) as PlaygroundQuestionGenerationJobWireFormat;
        this.playground.questionGeneration.updateLatestJobData(json);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('ai-agent.playground.banners.generating-questions.error'),
        );
      }
    }
  }

  trackAddingNewQuestions(new_question_count: number, source: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'added_questions',
      object: 'playground',
      new_question_count,
      source,
    });
  }

  @action instrumentButtonClick(buttonType: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `${buttonType}_button`,
      context: 'playground',
    });
  }

  @action instrumentIngestionBannerRender() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'rendered',
      object: 'ingestion_banner',
      context: 'playground',
    });
  }

  @action
  toggleSuggestionsSideSheet() {
    this.showSuggestionsSideSheet = !this.showSuggestionsSideSheet;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'view_suggestions_button',
      context: 'playground',
      is_open: this.showSuggestionsSideSheet,
      rating_reason: this.selectedQuestion?.customerAnswerRatingReason,
    });
  }

  @action
  selectAddQuestionDropdownOption(option: string) {
    switch (option) {
      case 'generate-questions':
        this.generateQuestions();
        break;
      case 'upload-csv':
        this.showUploadCSVModal = true;
        break;
      case 'add-question':
        this.isAddingQuestions = true;
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground': typeof AiAgentPlayground;
  }
}
