/* RESPONSIBLE TEAM: team-actions */

import Model, { attr, belongsTo } from '@ember-data/model';
import type Form from './form';

export default class Configuration extends Model {
  @attr('string') declare subdomain: string;
  @attr('boolean') declare shouldCreateHandover: boolean;
  @belongsTo('side-by-side/handover/zendesk/form', { async: false })
  declare zendeskForm: Form | null;

  @attr('number') declare createdByAdminId: number;
}
