/* import __COLOCATED_TEMPLATE__ from './add-article-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type Collection, type DataObject } from './types';
import { type TreeItem } from 'embercom/objects/tree-list';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import { EntityType } from 'embercom/models/data/entity-types';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import type Owner from '@ember/owner';

interface Args {
  item: TreeItem;
  closeArticlePicker: () => void;
  openArticlePicker: boolean;
}

export default class AddArticleModal extends Component<Args> {
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: any;
  @service declare router: RouterService;

  @tracked loading = false;
  @tracked selectedArticles: Array<ArticleMultilingual> = [];
  @tracked addableArticles: Array<ArticleMultilingual> = [];

  constructor(owner: Owner, args: Args) {
    super(owner, args);
    this.loadAddableArticles();
  }

  @action
  async loadAddableArticles() {
    this.loading = true;
    let addableArticles = await this.collectionDataObject.listAddableArticles?.();
    this.addableArticles =
      addableArticles?.reject((article: ArticleMultilingual) => article.readOnly) || [];
    this.selectedArticles = [];
    this.loading = false;
  }

  get collection() {
    return this.collectionDataObject.content as Collection;
  }

  get app() {
    return this.appService.app;
  }

  get locale() {
    return this.collectionDataObject.locale();
  }

  get collectionDataObject(): DataObject {
    return this.args.item.dataObject;
  }

  @action
  createArticleInCollection() {
    this.knowledgeHubService.createContent(EntityType.Article, {
      collectionId: this.collection.id,
      contentLocale: this.collectionDataObject.locale?.(),
    });
    this.args.closeArticlePicker();
  }

  get addToCollectionButtonLabel() {
    return this.selectedArticles.length
      ? this.intl.t('articles.collections.add_article.button', {
          count: this.selectedArticles.length,
        })
      : this.intl.t('articles.collections.add_article.default_button');
  }

  @action
  async addSelectedArticleToCollection() {
    this.loading = true;
    if (this.collectionDataObject.addArticlesToCollection) {
      await this.collectionDataObject.addArticlesToCollection(this.selectedArticles);
    }
    this.loading = false;
    this.args.closeArticlePicker();
  }

  get isAddToCollectionButtonDisabled() {
    return this.loading || !this.selectedArticles.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::AddArticleModal': typeof AddArticleModal;
    'articles/site/collections/tree/add-article-modal': typeof AddArticleModal;
  }
}
