/* RESPONSIBLE TEAM: team-growth-opportunities */

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import {
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type QuoteService from 'embercom/services/quote-service';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';

const DISMISSAL_ONCE_KEY = 'cardless_conversion_modal_dismissed_when_trial_ended';

export default class CardlessConversionModalService extends Service {
  @tracked showModal = false;
  @tracked modalDismissedWhenTrialExpiredNonStorage = false;
  @tracked afterCloseModal: (() => void) | undefined;
  @tracked customerWantsToExtendTrial = false;

  @service declare quoteService: QuoteService;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare appService: any;
  @service declare experimentOptInService: any;
  @service declare customerService: any;

  setModalDismissedWhenTrialExpired() {
    if (!this.cardlessTrialService.trialHasEnded) {
      return;
    }

    this.modalDismissedWhenTrialExpiredNonStorage = true;
    localStorage.setItem(DISMISSAL_ONCE_KEY, true.toString());
  }

  get shouldShowModalForAppWithExpiredTrial() {
    return this.cardlessTrialService.trialHasEnded && this.modalNotDismissedWhenTrialExpired;
  }

  @action
  setCustomerWantsToExtendTrial(value: boolean) {
    this.customerWantsToExtendTrial = value;
  }

  async toggle(afterCloseModal?: () => void) {
    this.afterCloseModal = afterCloseModal;
    if (!this.showModal) {
      taskFor(this.getQuote).perform();
    }
    this.showModal = !this.showModal;
    if (!this.showModal) {
      this.afterCloseModal?.();
      this.afterCloseModal = undefined;
    }
  }

  get customerCanExtendTrial() {
    return !!(
      (this.customerService.customer.inCardlessTrial ||
        this.customerService.customer.inExpiredCardlessTrial) &&
      !this.customerService.customer.inTrialExtension &&
      !this.customerService.customer.inExpiredTrialExtension
    );
  }

  get showExtendedTrialState() {
    return !!(
      this.customerCanExtendTrial &&
      this.customerWantsToExtendTrial &&
      this.appService.app?.teamPurchaseExperienceTrialExtensionsVariant
    );
  }

  get isEligibleForAnnualPlan() {
    return this.customerService.isEligibleForAnnualPlan;
  }

  get planIdsForQuoteRetrieval() {
    return [
      Number(PRICING_5_X_CORE_ESSENTIAL_ID),
      Number(PRICING_5_X_CORE_ADVANCED_ID),
      Number(PRICING_5_X_CORE_EXPERT_ID),
      Number(PROACTIVE_SUPPORT_PLUS_BASE_ID),
      Number(FIN_AI_COPILOT_BASE_ID),
    ];
  }

  @keepLatestTask
  *getQuote() {
    let params = [
      {
        ...DEFAULT_REQUEST_PARAMS,
        planIds: this.planIdsForQuoteRetrieval,
        source: 'pricing-five-conversion-modal',
        coreSeatCount: 5,
        includePlanCombinationValidation: false,
      },
    ];
    yield taskFor(this.quoteService.getQuotes).perform(params);
  }

  hide() {
    this.setModalDismissedWhenTrialExpired();
    this.showModal = false;
    this.afterCloseModal?.();
    this.afterCloseModal = undefined;
  }

  private get modalDismissedOnceStorage() {
    return localStorage.getItem(DISMISSAL_ONCE_KEY) === 'true';
  }

  private get modalNotDismissedWhenTrialExpired() {
    return !this.modalDismissedWhenTrialExpiredNonStorage && !this.modalDismissedOnceStorage;
  }
}

declare module '@ember/service' {
  interface Registry {
    cardlessConversionModalService: CardlessConversionModalService;
    'cardless-conversion-modal-service': CardlessConversionModalService;
  }
}
