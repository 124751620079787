/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { type EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import { DRAWER_EDITOR_QUERY_PARAMS } from 'embercom/services/knowledge-hub-drawer-editor-service';
import { ARTICLE_IMPORT_COMPLETED } from 'embercom/services/knowledge-hub-service';
import { type OnContentUpdateCallbackFunctionArgs } from 'embercom/services/knowledge-hub-editor-service';

export default class CollectionsController extends Controller {
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare router: RouterService;
  @service declare realTimeEventService: $TSFixMe;
  containerReference: any;

  @tracked createNewCollectionAction: any;

  constructor() {
    super(...arguments);
    this.realTimeEventService.on(ARTICLE_IMPORT_COMPLETED, this, this.refreshData);
  }

  // Code to get the preview appearing alongside collections
  get uploadHelper() {
    return this.model.uploadHelper;
  }

  get groupList() {
    let items = this.helpCenterService.site.selectedLocalesOrdered
      .filter((loc: $TSFixMe) => loc)
      .map((locale: $TSFixMe) => locale.data);

    items.forEach((locale: $TSFixMe) => {
      locale.text = locale.name;
    });

    return [{ items }];
  }

  @action
  updateLocale(locale: $TSFixMe) {
    this.helpCenterService.updateLocale(locale.localeId);
  }

  declare model: {
    allCollections: any;
    allArticlesSummaries: any;
    uploadHelper: any;
  };

  queryParams = [...DRAWER_EDITOR_QUERY_PARAMS];

  @tracked activeContentId?: number;
  @tracked activeContentType?: string;
  @tracked editorMode?: EditorMode;
  @tracked collectionId?: string;
  @tracked contentLocale?: string;
  @tracked folderId?: string;

  @action
  getContainerRef() {
    return this.containerReference;
  }

  get app() {
    return this.appService.app;
  }

  get helpCenterSite() {
    return this.helpCenterService.site;
  }

  get collectionsWithoutHome() {
    return this.allCollections.rejectBy('isHome', true);
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get defaultLocaleId() {
    return this.helpCenterSite.locale;
  }

  get selectedLocaleName() {
    let localeCount = '';
    if (this.helpCenterService.site.selectedLocalesOrdered.length > 1) {
      localeCount = ` (${this.helpCenterService.site.selectedLocalesOrdered.length})`;
    }
    return `${this.helpCenterService.getLocaleNameFromLocaleCode(this.selectedLocaleId)}${localeCount}`;
  }

  get allCollections() {
    return this.model.allCollections;
  }

  get allArticlesSummaries() {
    return this.model.allArticlesSummaries;
  }

  get selectedCollection() {
    if (!this.collectionId) {
      return null;
    }

    return this.collectionsWithoutHome.findBy('id', this.collectionId);
  }

  @action
  handleContentUpdate(update: OnContentUpdateCallbackFunctionArgs) {
    if (update.type === 'move-folder') {
      // Knowledge hub's folder info are not shown within collections page
      return;
    }

    this.refreshData();
  }

  @action
  refreshData() {
    this.send('refreshModel');
  }

  @action
  goToAllArticles() {
    let url = this.router.urlFor('apps.app.articles');
    safeWindowOpen(url, '_blank');
  }

  @action
  createNewCollection() {
    if (this.createNewCollectionAction) {
      this.createNewCollectionAction();
    }
  }
}
