/* RESPONSIBLE TEAM: team-ai-agent */
import moment from 'moment-timezone';
import type IntlService from 'embercom/services/intl';
import {
  type PlaygroundQuestionSource,
  type PlaygroundQuestion,
  type Playground,
} from './fin-playground';
import { blocksToText } from './open-ai-prompt';
import type RouterService from '@ember/routing/router-service';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from './knowledge-hub/constants';

export default class FinPlaygroundExport {
  app: $TSFixMe;
  intl: IntlService;
  playground: Playground;
  router: RouterService;

  constructor(app: $TSFixMe, intl: IntlService, playground: Playground, router: RouterService) {
    this.app = app;
    this.playground = playground;
    this.intl = intl;
    this.router = router;
  }

  get fileName() {
    return this.intl.t('ai-agent.playground.download-csv.filename', {
      date: moment.tz(this.app.timezone).format('YYYY-MM-DD'),
    });
  }

  get data() {
    let data = this.playground.questions.map((question) => this.row(question));
    data.unshift(this.headerColumn);

    return data;
  }

  row(question: PlaygroundQuestion) {
    let rowArray = [
      question.questionText,
      this.responseText(question),
      this.sourcesText(question),
      this.personalityApplied(question),
    ];

    if (this.app.canUseFinGuidance) {
      rowArray.push(this.guidancesApplied(question));
    }

    rowArray.push(
      this.answeredState(question),
      this.ratingReason(question),
      question.customerAnswerRatingNote,
    );

    return rowArray;
  }

  responseText(question: PlaygroundQuestion) {
    return blocksToText(question.responseText);
  }

  sourcesText(question: PlaygroundQuestion) {
    let sourcesArray = question.sources.map((source) => {
      let entityName = this.entityName(source.entityType);

      return `${source.title} (${this.sourceIntercomUrl(source, entityName)})`;
    });

    return sourcesArray.join('\n');
  }

  sourceIntercomUrl(source: PlaygroundQuestionSource, entityName: string) {
    let route = this.router.urlFor(
      'apps.app.knowledge-hub.view',
      this.app.id,
      entityName,
      source.entityId,
    );

    return `${window.location.origin}${route}`;
  }

  answeredState(question: PlaygroundQuestion) {
    if (question.customerAnswerRating === 'positive') {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.yes-manually-set');
    } else if (question.customerAnswerRating === 'negative') {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.no-manually-set');
    } else if (question.status === 'completed') {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.unrated');
    } else {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.pending');
    }
  }

  ratingReason(question: PlaygroundQuestion) {
    if (!question.customerAnswerRatingReason) {
      return '';
    }

    let reason = question.customerAnswerRatingReason.replace(/_/g, '-');

    return this.intl.t(`ai-agent.playground.answer-rating-reason.${reason}`);
  }

  personalityApplied(question: PlaygroundQuestion) {
    let toneOfVoiceSettingText = `${this.intl.t(`ai-agent.playground.tone-of-voice.${question.aiToneOfVoice}`)} (${this.intl.t('ai-agent.playground.download-csv.tone-of-voice')})`;
    let answerLengthSettingText = `${this.intl.t(`ai-agent.playground.answer-length.${question.aiAnswerLength}`)} (${this.intl.t('ai-agent.playground.download-csv.answer-length')})`;

    return [toneOfVoiceSettingText, answerLengthSettingText].join('\n');
  }

  guidancesApplied(question: PlaygroundQuestion) {
    return question.appliedGuidelines.map((guidance) => `"${guidance.text}"`).join('\n');
  }

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  get headerColumn() {
    return [
      this.intl.t('ai-agent.playground.download-csv.headings.question'),
      this.intl.t('ai-agent.playground.download-csv.headings.response'),
      this.intl.t('ai-agent.playground.download-csv.headings.sources'),
      this.intl.t('ai-agent.playground.download-csv.headings.personality-applied'),
      this.intl.t('ai-agent.playground.download-csv.headings.guidance-applied'),
      this.intl.t('ai-agent.playground.download-csv.headings.response-rating'),
      this.intl.t('ai-agent.playground.download-csv.headings.rating-reason'),
      this.intl.t('ai-agent.playground.download-csv.headings.rating-note'),
    ];
  }
}
