/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  identifier: attr('string'),
  name: attr('string'),
  embercomIllustrativeIconName: attr('string'),
  shortDescription: attr('string'),
  longDescription: attr('string'),
  scopes: attr('array'),
});
