/* RESPONSIBLE TEAM: team-workflows */
import Fragment from 'ember-data-model-fragments/fragment';
import { type SyncHasMany, attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { buildValidations } from 'ember-cp-validations';
import { type Block } from 'embercom/models/common/blocks/block';

const Validations = buildValidations({});

export default class Localization extends Fragment.extend(Validations) {
  @attr('string') declare locale: string;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;

  get jsonBlocks() {
    return this.blocks.serialize();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'operator/localizable-step/localization': Localization;
  }
}
