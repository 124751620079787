/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import RSVP from 'rsvp';
import { IntegrationState } from 'embercom/objects/standalone/constants';

const API_ROOT = '/ember/standalone/intercom_messenger_configurations';

export default class IntercomMessengerConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('number') declare workflowRulesetId: number;
  @attr('number', { defaultValue: 0 }) declare contentPlaceholderCount: number;

  get integrationState() {
    if (!this.finStandaloneService.app?.hasAnyInstalledAtDate) {
      return IntegrationState.NotConfigured;
    }

    // Sunshine integration is optional, so if it is not configured, we can still enable the messenger
    let sunshineState =
      this.finStandaloneService.zendeskConfig.sunshineIntegration.intercomMessengerState;
    if (sunshineState === IntegrationState.Created || sunshineState === IntegrationState.Paused) {
      return sunshineState;
    }

    if (
      this.finStandaloneService.messengerSettings?.visibility?.messengerEnabledForUsers &&
      this.finStandaloneService.messengerSettings?.visibility?.messengerEnabledForVisitors
    ) {
      return IntegrationState.Enabled;
    }

    return IntegrationState.Paused;
  }

  get isMessengerEnabled() {
    return this.integrationState === IntegrationState.Enabled;
  }

  get isMessengerNotConfigured() {
    return this.integrationState === IntegrationState.NotConfigured;
  }

  async activate() {
    await post(`${API_ROOT}/${this.id}/activate`);
    await RSVP.all([
      this.finStandaloneService.messengerSettings.reload(),
      this.finStandaloneService.zendeskConfig.reload(),
    ]);
  }

  async deactivate() {
    await post(`${API_ROOT}/${this.id}/deactivate`);
    await RSVP.all([
      this.finStandaloneService.messengerSettings.reload(),
      this.finStandaloneService.zendeskConfig.reload(),
    ]);
  }
}
