/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type Model from '@ember-data/model';
import { INSTALLATION_PLATFORMS } from 'embercom/components/installation-new/constants';
import type SdkAppService from 'embercom/services/sdk-app-service';
import type { PlatformId } from 'embercom/components/standalone/intercom-messenger/setup/messenger-settings/platform-selector';
import type LookAndFeelModel from 'embercom/models/messenger-settings/look-and-feel';
import type Security from 'embercom/models/messenger-settings/security';

export interface Args {
  operatorIdentity: Model;
  messengerSettings: Model & {
    hasUnsavedChanges: boolean;
    lookAndFeel: LookAndFeelModel;
    security: Security;
  };
}

export default class StandaloneIntercomMessengerSetup extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare sdkAppService: SdkAppService;

  @tracked selectedAccordion = '';
  @tracked selectedTab: INSTALLATION_PLATFORMS = INSTALLATION_PLATFORMS.WEB;
  @tracked selectedPlatform: PlatformId = 'web';

  @action onAccordionChange(accordion: string) {
    this.selectedAccordion = accordion;
  }

  get intercomMessengerConfig() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  @action
  setSelectedTab(tab: INSTALLATION_PLATFORMS) {
    this.selectedTab = tab;
  }

  get workflowRulesetId() {
    return this.intercomMessengerConfig?.workflowRulesetId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup': typeof StandaloneIntercomMessengerSetup;
    'standalone/intercom-messenger/setup': typeof StandaloneIntercomMessengerSetup;
  }
}
