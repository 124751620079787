/* RESPONSIBLE TEAM: team-workflows */
import type HandoffToZendeskTicket from 'embercom/models/operator/visual-builder/step/handoff-to-zendesk-ticket';
import StepConfig, { type StepConfigParams } from '../step';

export default class HandoffToZendeskTicketConfiguration extends StepConfig {
  declare step: HandoffToZendeskTicket;
  shouldCheckZendeskApiConnection?: boolean;

  constructor(
    params: Omit<StepConfigParams, 'component'> & { shouldCheckZendeskApiConnection?: boolean },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/handoff-to-zendesk-ticket',
      ...params,
    });
    this.shouldCheckZendeskApiConnection = params.shouldCheckZendeskApiConnection ?? false;
  }

  get alignment() {
    return 'left' as const;
  }
}
