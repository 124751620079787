/* import __COLOCATED_TEMPLATE__ from './drawer-controls.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
}

export default class DrawerControls extends Component<Signature> {
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare media: $TSFixMe;

  get isForcedFullScreen() {
    return this.media.isDrawerEditorFullScreen;
  }

  @action
  async closeDrawer() {
    await this.knowledgeHubDrawerEditorService.closeDrawer();
  }

  get isFullScreen() {
    return this.knowledgeHubDrawerEditorService.isFullScreen;
  }

  @action
  toggleFullScreen() {
    this.knowledgeHubDrawerEditorService.toggleFullScreen();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Header::DrawerControls': typeof DrawerControls;
    'knowledge-hub/content-editor/shared/header/drawer-controls': typeof DrawerControls;
  }
}
