/* RESPONSIBLE TEAM: team-actions */

import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class Field extends Model {
  @service declare intl: IntlService;

  @attr('string') declare title: string;
  @attr('string') declare type: string;
  @attr('string') declare internalType: string | null;
  @attr('string') declare description: string;
  @attr('string') declare titleInPortal: string;
  @attr('string') declare agentDescription: string;
  @attr('boolean') declare required: boolean;
  @attr('boolean') declare active: boolean;
  @attr('boolean') declare visibleInPortal: boolean;
  @attr('boolean') declare editableInPortal: boolean;
  @attr('boolean') declare requiredInPortal: boolean;

  @tracked checked: boolean = this.isChecked;

  get tooltip() {
    return this.agentDescription;
  }

  get disabled() {
    return !this.active || this.requiredInPortal || !this.editableInPortal || !this.internalType;
  }

  get hint() {
    if (this.type === 'subject') {
      return this.intl.t('side-by-side.zendesk.subject-hint');
    } else if (this.type === 'description') {
      return this.intl.t('side-by-side.zendesk.description-hint');
    } else if (!this.active) {
      return this.intl.t('side-by-side.zendesk.field-disabled');
    } else if (!this.editableInPortal) {
      return this.intl.t('side-by-side.zendesk.field-uneditable');
    } else if (this.requiredInPortal) {
      return this.intl.t('side-by-side.zendesk.field-required');
    } else if (!this.internalType) {
      return this.intl.t('side-by-side.zendesk.field-unsupported');
    }

    return null;
  }

  get isChecked(): boolean {
    return this.active && this.editableInPortal && isPresent(this.internalType);
  }
}
