/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  appstoreMetaTagsService: service(),

  headTags() {
    // Only override the head tags if an app_package_code is provided
    let appPackage = this.controller ? this.controller.get('selectedAppPackage') : null;
    if (appPackage) {
      return this.appstoreMetaTagsService.getMetaTags({ appPackage });
    } else {
      return [];
    }
  },
});
