/* import __COLOCATED_TEMPLATE__ from './embercom-revision-banner.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import move from 'ember-animated/motions/move';
import { easeOut, easeIn } from 'ember-animated/easings/cosine';

export default class EmbercomRevisionBanner extends Component {
  @tracked isCollapsed = false;
  get isValidRevision() {
    let currentlyLoadedRevision = Ember.libraries._registry
      .find((library) => library.name === 'Embercom')
      .version.replace('0.0.0+', '');
    return this.args.embercomRevision.startsWith(currentlyLoadedRevision);
  }
  get isExperimentalBuild() {
    return this.args.embercomRevision.includes('-experimental');
  }
  get embercomRevisionProcessed() {
    return this.args.embercomRevision.replace('-experimental', '');
  }
  get bannerType() {
    return this.isValidRevision ? 'warning' : 'error';
  }
  @action toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  // eslint-disable-next-line require-yield
  *transition({ insertedSprites, keptSprites, removedSprites }) {
    for (let sprite of insertedSprites) {
      sprite.startAtPixel({ x: -window.innerWidth });
      move(sprite, { easing: easeOut });
    }

    for (let sprite of keptSprites) {
      move(sprite);
    }

    for (let sprite of removedSprites) {
      sprite.endAtPixel({ x: -window.innerWidth });
      move(sprite, { easing: easeIn });
    }
  }
}
