/* import __COLOCATED_TEMPLATE__ from './teammate-app-island.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import type Owner from '@ember/owner';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { next } from '@ember/runloop';
// @ts-ignore
import { globalRef } from 'ember-ref-bucket';
import type ReactTeammateAppIslandService from 'embercom/services/react-teammate-app-island';
import { registerDestructor } from '@ember/destroyable';
import { isReactRoute } from 'embercom/lib/react-route';
import { captureException } from 'embercom/lib/sentry';

const BOOT_TIMEOUT = 30000;

export default class ReactTeammateAppIsland extends Component {
  @tracked currentPath: string;
  @tracked isLoaded = false;
  @tracked isError = false;
  @tracked bootTimeout: ReturnType<typeof setTimeout> | null = null;

  @service declare router: RouterService;
  @service declare reactTeammateAppIsland: ReactTeammateAppIslandService;

  @globalRef('main-content-well') declare mainContentWell: HTMLElement;

  constructor(owner: Owner, args: any) {
    super(owner, args);
    this.currentPath = this.router.currentURL;

    next(() => {
      if (isReactRoute(this.router.currentRoute)) {
        this.showReactContent();
      }
    });

    let routeChangeHandler = (transition: Transition) => {
      this.currentPath = this.router.currentURL;
      if (isReactRoute(transition.to)) {
        this.showReactContent();
      } else {
        this.hideReactContent();
      }

      this.reactTeammateAppIsland.dispatchRouteNavigationRequestEvent(this.reactPath);
    };

    this.router.on('routeDidChange', routeChangeHandler);

    registerDestructor(this, () => {
      this.router.off('routeDidChange', routeChangeHandler);
    });

    this.bootTimeout = setTimeout(() => {
      this.onError(new Error('React island boot timed out'));
    }, BOOT_TIMEOUT);

    registerDestructor(this, () => {
      this.clearBootTimeout();
    });
  }

  get reactPath() {
    return this.isCurrentRouteReact ? this.currentPath : '/empty';
  }

  get isCurrentRouteReact() {
    return isReactRoute(this.router.currentRoute);
  }

  clearBootTimeout() {
    if (this.bootTimeout) {
      clearTimeout(this.bootTimeout);
    }
  }

  @action
  onAppReady() {
    this.clearBootTimeout();
    this.isLoaded = true;
    this.reactTeammateAppIsland.onAppReady();
  }

  @action
  onError(error: Error) {
    this.clearBootTimeout();

    this.isLoaded = false;
    this.isError = true;
    captureException(error, {
      tags: {
        'react-teammate-app-island-error': true,
      },
    });
  }

  @action
  showReactContent() {
    if (this.mainContentWell) {
      this.mainContentWell.style.display = 'none';
    }
  }

  @action
  hideReactContent() {
    if (this.mainContentWell) {
      this.mainContentWell.style.display = 'flex';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'React::TeammateAppIsland': typeof ReactTeammateAppIsland;
    'react/teammate-app-island': typeof ReactTeammateAppIsland;
  }
}
