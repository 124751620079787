/* import __COLOCATED_TEMPLATE__ from './handoff-to-salesforce-team.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type HandoffToSalesforceTeamType from 'embercom/models/operator/visual-builder/step/handoff-to-salesforce-team';
import templateOnlyComponent from '@ember/component/template-only';

export interface HandoffToSalesforceTeamArgs {
  step: HandoffToSalesforceTeamType;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Args: HandoffToSalesforceTeamArgs;
  Element: HTMLElement;
}

const HandoffToSalesforceTeam = templateOnlyComponent<Signature>();
export default HandoffToSalesforceTeam;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffToSalesforceTeam': typeof HandoffToSalesforceTeam;
    'workflows/graph-editor/node-items/steps/handoff-to-salesforce-team': typeof HandoffToSalesforceTeam;
  }
}
