/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import AppstoreRoute from 'embercom/routes/base/appstore';

export default AppstoreRoute.extend({
  intercomEventService: service(),
  appService: service(),
  permissionsService: service(),

  app: readOnly('appService.app'),
  analytics: {
    section: 'app_store',
  },

  queryParams: {
    category: {
      refreshModel: true,
    },
    search: {
      refreshModel: true,
    },
    installed: {
      refreshModel: true,
    },
    capability: {
      refreshModel: true,
    },
  },

  willTransition() {
    this.refresh();
  },

  actions: {
    didTransition() {
      this._super();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'visited',
        object: 'app_store',
      });

      if (!this.permissionsService.currentAdminCan('can_manage_apps_and_integrations')) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'visited_without_install_permission',
          object: 'app_store',
        });
      }
    },
  },
});
