/* RESPONSIBLE TEAM: team-reporting */
import intercomMessengerColors from '@intercom/intercom-messenger-colors';
import { tracked } from '@glimmer/tracking';
import { renderBlocksV1 } from 'embercom/helpers/render-blocks';
import { surveyFormats } from 'embercom/models/data/survey/constants';

const SNIPPET_STYLES = ({ backgroundColor, format }) => {
  let LIGHT_TEXT_COLOR = '#FFFFFF';
  let DARK_TEXT_COLOR = '#000000';
  let BORDER_COLOR = '#F1F1F1';

  let messengerColors = intercomMessengerColors({
    primaryColor: backgroundColor,
  });

  let textColor =
    messengerColors?.primary_type === 'dark' && format === surveyFormats.banner
      ? LIGHT_TEXT_COLOR
      : DARK_TEXT_COLOR;

  let borderColor =
    messengerColors?.primary_type === 'dark' && format === surveyFormats.banner
      ? LIGHT_TEXT_COLOR
      : BORDER_COLOR;

  let cellBackground = format === surveyFormats.banner ? backgroundColor : '#FFF';

  return {
    smallFormatContainer: `border-collapse: separate; border-spacing: 5px 15px; box-sizing: border-box; max-width: 640px; width: 100%; margin: 0 auto; background-color: ${backgroundColor}; border-radius: 12px; padding: 5px; font-family: sans-serif; font-size: 16px; color: ${textColor};`,
    postFormatContainer: `border-collapse: separate; border-spacing: 5px 15px; box-sizing: border-box; display: block; max-width: 680px; width: 100%; margin: 0 auto; background-color: ${backgroundColor}; border: 5px solid ${backgroundColor}; border-radius: 12px; padding: 8px; font-family: sans-serif; font-size: 16px; color: ${DARK_TEXT_COLOR};`,
    postFormatBodyContainer: `display: block; max-width: 100%; background-color: #FFFFFF; border-radius: 12px; border: 5px solid #FFFFFF; overflow-x: auto; -webkit-overflow-scrolling: touch; scroll-behavior: smooth;`,
    bannerFormatBodyContainer: `display: table; border-collapse: separate; border-spacing: 0px 10px; max-width: 100%; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; scroll-behavior: smooth;`,
    numericElement: {
      tableCell: `display: table-cell; box-sizing: border-box; width: 26px; height: 26px; text-align: center; margin: 2px; padding: 0; background: ${cellBackground};`,
      link: `display: block; width: 22px; height: 22px; font-size: 14px; white-space: nowrap; color: #222222; line-height: 22px; text-decoration: none; border: 2px solid ${borderColor}; border-radius: 6px; background-color: white; text-align: center; margin: 0;`,
      starLink: `display: block; width: 22px; height: 22px; font-size: 22px; white-space: nowrap; text-align: center; text-decoration: none;`,
      emojiLink: `display: block; font-size: 26px; line-height: 1; text-decoration: none; text-align: center`,
      scaleLabel: `font-size: 13px; color: ${textColor}; background: ${cellBackground}`,
    },
    multiSelect: {
      list: `list-style-type: none; padding: 0; margin: 0;`,
      listItem: `margin-bottom: 8px;`,
      link: `display: inline-block; border: 1px solid #E8E8E8; background-color: #FFFFFF; text-decoration: none; border-radius: 5px; padding: 9px 20px 8px; color: #222222;`,
    },
    questionTitle: `background: ${cellBackground}; color: ${textColor};`,
  };
};

const removeEmptyLines = (content) => content.replace(/\\n/g, '');
const removeDoubleSpaces = (content) => content.replace(/\s+/g, ' ');
const removeTagTrailingSpaces = (content) => content.replace(/\ >/g, '>');
const removeTrailingSpacesBetweenTags = (content) => content.replace(/\> </g, '><');
export const cleanedUpString = (content) =>
  removeTagTrailingSpaces(
    removeTrailingSpacesBetweenTags(removeDoubleSpaces(removeEmptyLines(content))),
  );

export const createEmbeddedLinks = ({ baseUrl, searchParams }) => {
  return baseUrl.includes('?')
    ? `${baseUrl}&${new URLSearchParams(searchParams).toString()}`
    : `${baseUrl}?${new URLSearchParams(searchParams).toString()}`;
};

export default class SurveyEmbeddedTemplate {
  @tracked baseUrl;
  @tracked survey;
  @tracked surveyId = this.survey.id;
  @tracked currentStep = this.survey.firstStep;
  @tracked format = this.survey.format;
  @tracked firstQuestion = this.survey.firstStep.nonDeletedQuestions.firstObject;
  @tracked customizationOptions = this.survey.customizationOptions;

  constructor({ baseUrl, survey }) {
    this.baseUrl = baseUrl;
    this.survey = survey;
  }

  get _maxColSpan() {
    let hasOddOptionsNumber = Boolean(this.firstQuestion.data.ratingScaleRange.length % 2);

    return hasOddOptionsNumber ? 13 : 12;
  }

  get _snippetStyles() {
    return SNIPPET_STYLES({
      backgroundColor: this.customizationOptions.backgroundColor,
      format: this.format,
    });
  }

  get _marginalCellsOutlookTemplate() {
    let marginalCellsCount = [
      ...Array((this._maxColSpan - this.firstQuestion.data.ratingScaleRange.length) / 2).keys(),
    ];

    return marginalCellsCount.map(() => `<td />`);
  }

  get _createLabelsRowOutlookTemplate() {
    let { scaleStart, lowerLabel, scaleEnd, upperLabel } = this.firstQuestion.data;

    if (lowerLabel || upperLabel) {
      return `
    <tr>
      <td colspan="6">${lowerLabel ? `${scaleStart} - ${lowerLabel}` : ''}
      </td>
      <td colspan="6">${upperLabel ? `${scaleEnd} - ${upperLabel}` : ''}
      </td>
    </tr>
    `;
    } else {
      return '';
    }
  }

  get _bannerQuestionOptionsCellsOutlookTemplate() {
    let questionOptionsCells = this.firstQuestion.data.ratingScaleRange.map((questionOption) => {
      let emojiItem = this.firstQuestion.data.options[questionOption - 1];
      let optionValue = questionOption;

      if (this.firstQuestion.isEmojiScale) {
        optionValue = emojiItem.unicodeEmoticon;
      }

      if (this.firstQuestion.isStarScale) {
        optionValue = '⭐️';
      }

      let redirectUrl = this._createRedirectUrl(questionOption);

      return `<td><a href="${redirectUrl}" target="_blank">${optionValue}</a></td>`;
    });

    return `
      <tr>
        ${[
          ...this._marginalCellsOutlookTemplate,
          ...questionOptionsCells,
          ...this._marginalCellsOutlookTemplate,
        ].join('')}
      </tr>
    `;
  }

  get _questionTitleOutlookTemplate() {
    return `
    <tr cellspacing="${this._maxColSpan}">
      <td colspan="${this._maxColSpan}">${this.firstQuestion.questionTitle}</td>
    </tr>
  `;
  }

  get _postFormatQuestionOutlookTemplate() {
    if (this.firstQuestion.isRatingScale) {
      return `
        ${this._bannerQuestionOptionsCellsOutlookTemplate}
        ${this._createLabelsRow}
      `;
    }

    if (this.firstQuestion.isSingleMultiSelect) {
      let multiselectOptionsTemplate = this.firstQuestion.data.options.map((option) => {
        let redirectUrl = this._createRedirectUrl(option);

        return `<li><a href="${redirectUrl}" target="_blank">${option}</a></li>`;
      });

      return `
      <tr cellspacing="${this._maxColSpan}">
        <td>
          <ul>${multiselectOptionsTemplate.join('')}</ul>
        </td>
      </tr>`;
    }

    return '';
  }

  get _surveyIntroOutlookTemplate() {
    let hasStepIntro = Boolean(this.currentStep.blocks.length);
    if (!hasStepIntro) {
      return '';
    }

    let htmlBlocks = renderBlocksV1(
      this.currentStep.serializedBlocks.filter((block) => block.type !== 'image'),
    ).outerHTML;
    return `<tr>${htmlBlocks}</tr>`;
  }

  get _marginalCellsTemplate() {
    let marginalCellsCount = [
      ...Array((this._maxColSpan - this.firstQuestion.data.ratingScaleRange.length) / 2).keys(),
    ];

    return marginalCellsCount.map(
      () => `<td width="26" height=26" style="${this._snippetStyles.numericElement.tableCell}" />`,
    );
  }

  get _createLabelsRow() {
    let { scaleStart, lowerLabel, scaleEnd, upperLabel } = this.firstQuestion.data;

    if (lowerLabel || upperLabel) {
      return `
    <tr>
    <td colspan="6" style="${this._snippetStyles.numericElement.scaleLabel}">${
      lowerLabel ? `${scaleStart} - ${lowerLabel}` : ''
    }
      </td>
      <td colspan="6" style="text-align: right; ${this._snippetStyles.numericElement.scaleLabel}">${
        upperLabel ? `${scaleEnd} - ${upperLabel}` : ''
      }
      </td>
    </tr>
    `;
    } else {
      return '';
    }
  }

  get _bannerQuestionOptionsCellsTemplate() {
    let questionOptionsCells = this.firstQuestion.data.ratingScaleRange.map((questionOption) => {
      let emojiItem = this.firstQuestion.data.options[questionOption - 1];
      let optionValue = questionOption;
      let optionStyles = this._snippetStyles.numericElement.link;

      if (this.firstQuestion.isEmojiScale) {
        optionValue = emojiItem.unicodeEmoticon;
        optionStyles = this._snippetStyles.numericElement.emojiLink;
      }

      if (this.firstQuestion.isStarScale) {
        optionValue = '⭐️';
        optionStyles = this._snippetStyles.numericElement.emojiLink;
      }

      let redirectUrl = this._createRedirectUrl(questionOption);

      return `<td width="26" height="26" style="${this._snippetStyles.numericElement.tableCell}"><a href="${redirectUrl}" target="_blank" width="26" height="26" style="${optionStyles}">${optionValue}</a></td>`;
    });

    return `
      <tr>
        ${[
          ...this._marginalCellsTemplate,
          ...questionOptionsCells,
          ...this._marginalCellsTemplate,
        ].join('')}
      </tr>
    `;
  }

  get _questionTitleTemplate() {
    return `
    <tr cellspacing="${this._maxColSpan}">
      <td colspan="${this._maxColSpan}" style="${this._snippetStyles.questionTitle}">${this.firstQuestion.questionTitle}</td>
    </tr>
  `;
  }

  get _postFormatQuestionTemplate() {
    if (this.firstQuestion.isRatingScale) {
      return `
        ${this._bannerQuestionOptionsCellsTemplate}
        ${this._createLabelsRow}
      `;
    }

    if (this.firstQuestion.isSingleMultiSelect) {
      let multiselectOptionsTemplate = this.firstQuestion.data.options.map((option) => {
        let redirectUrl = this._createRedirectUrl(option);

        return `<li style="${this._snippetStyles.multiSelect.listItem}">
          <a href="${redirectUrl}" target="_blank" style="${this._snippetStyles.multiSelect.link}">${option}</a>
        </li>`;
      });

      return `
      <tr cellspacing="${this._maxColSpan}">
        <td>
          <ul style="${this._snippetStyles.multiSelect.list}">${multiselectOptionsTemplate.join(
            '',
          )}</ul>
        </td>
      </tr>`;
    }

    return '';
  }

  get _surveyIntroTemplate() {
    let hasStepIntro = Boolean(
      this.currentStep.blocks.length && this.currentStep.blocks.firstObject.text !== '',
    );

    if (!hasStepIntro) {
      return '';
    }

    let htmlBlocks = renderBlocksV1(this.currentStep.serializedBlocks)
      .outerHTML.split('<img')
      .join('<img style="max-width: 100%; height: auto"');

    return `<tr><td colspan="${this._maxColSpan}" style="padding-bottom: 35px;">${htmlBlocks}</td></tr>`;
  }

  get _bannerFormatSurveyOutlookTemplate() {
    let bannerTemplate = `
    <!--[if mso]>
    <table
      class="intercom-embedded-survey"
      cellspacing="${this._maxColSpan}"
    >
      <tbody>
        ${this._questionTitleOutlookTemplate}
        ${this._bannerQuestionOptionsCellsOutlookTemplate}
        ${this._createLabelsRowOutlookTemplate}
      </tbody>
    </table>
    <![endif]-->
    `;

    return cleanedUpString(bannerTemplate);
  }

  get bannerFormatSurvey() {
    let bannerTemplate = `
    <!--[if !mso]><!-->
    <table
      class="intercom-embedded-survey"
      cellspacing="${this._maxColSpan}"
      style="${this._snippetStyles.smallFormatContainer}"
    >
      <tbody style="${this._snippetStyles.bannerFormatBodyContainer}">
        ${this._questionTitleTemplate}
        ${this._bannerQuestionOptionsCellsTemplate}
        ${this._createLabelsRow}
      </tbody>
    </table>
    <!--<![endif]-->`;

    return `
      ${cleanedUpString(bannerTemplate)}
      ${this._bannerFormatSurveyOutlookTemplate}
    `;
  }

  get postFormatSurvey() {
    let postTemplate = `
    <!--[if !mso]><!-->
    <table
      class="intercom-embedded-survey"
      cellspacing="${this._maxColSpan}"
      style="${this._snippetStyles.postFormatContainer}"
    >
      <tbody bgcolor="#FFFFFF" style="${this._snippetStyles.postFormatBodyContainer}">
        ${this._surveyIntroTemplate}
        ${this._questionTitleTemplate}
        ${this._postFormatQuestionTemplate}
      </tbody>
    </table>
    <!--<![endif]-->`;

    return `
      ${cleanedUpString(postTemplate)}
      ${this._postFormatSurveyOutlookTemplate}
    `;
  }

  get _postFormatSurveyOutlookTemplate() {
    let postTemplate = `
    <!--[if mso]>
    <table class="intercom-embedded-survey">
      <tbody>
        ${this._surveyIntroOutlookTemplate}
        ${this._questionTitleOutlookTemplate}
        ${this._postFormatQuestionOutlookTemplate}
      </tbody>
    </table>
    <![endif]-->`;

    return cleanedUpString(postTemplate);
  }

  _createRedirectUrl(questionOption) {
    return createEmbeddedLinks({
      baseUrl: this.baseUrl,
      searchParams: {
        intercom_email_survey_id: this.surveyId,
        intercom_email_survey_question_id: this.firstQuestion.id,
        intercom_email_survey_question_response: questionOption,
      },
    });
  }
}
