/* import __COLOCATED_TEMPLATE__ from './conversation-page.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import { ComposerLocation, InboxEvents } from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import { isEmpty } from '@ember/utils';
import { type BlockList } from '@intercom/interblocks.ts';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { type Hotkey, type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

import SmartReplyResource from './smart-reply-resource';
import { type DurationObject } from 'embercom/objects/inbox/duration';
import { type TagActionType } from 'embercom/objects/inbox/tag';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type ConversationStreamSettings from 'embercom/services/conversation-stream-settings';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import type InboxApi from 'embercom/services/inbox-api';
import FormForDownload from 'embercom/lib/form-for-download';
import type User from 'embercom/objects/inbox/user';
import {
  canExportConversationTranscriptsInbox2,
  exportConversationTranscriptsPermission,
} from 'embercom/lib/settings/permissions/inline-permission-enforcement';
import { type SendAndCloseFn } from 'embercom/objects/inbox/types/composer';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type EmojiService from 'embercom/services/emoji-service';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { emptyRequiredAttributesForConversation } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { type ConversationResource } from './conversation-resource';
import TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import type FinConversationContent from 'embercom/services/fin-conversation-content';
import { type ConversationContentSettings } from 'embercom/controllers/apps/app/fin-ai-agent/setup';
import type CommandKService from 'embercom/services/command-k';
import { DisplayContext } from 'embercom/services/command-k';
import { registerDestructor } from '@ember/destroyable';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';
import { type PublicAPI } from './conversation-reply-composer';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import type ConversationResolveAndCloseService from 'embercom/services/conversation-resolve-and-close-service';
import ConditionalAttributesEvaluator from 'embercom/services/conditional-attributes-evaluator';
import { getOwner } from '@ember/application';

interface Args {
  conversationResource: ConversationResource;
  toggleSidebarVisibility?: () => void;
  canResizeSidebar?: boolean;
  canOpenInNewTab?: boolean;
  isFullConversationPanel?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class ConversationPageComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare permissionsService: any;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare conversationStreamSettings: ConversationStreamSettings;
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;
  @service declare twilioService: any;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare emojiService: EmojiService;
  @service declare finConversationContent: FinConversationContent;
  @service declare commandK: CommandKService;
  @service declare lbaMetricsService: LbaMetricsService;
  @service declare ticketStateService: TicketStateService;
  @service declare conversationResolveAndCloseService: ConversationResolveAndCloseService;

  readonly ComposerLocation = ComposerLocation;
  readonly ComposerPaneType = ComposerPaneType;

  @tracked ticketTypeId?: number;
  @tracked ticketTypeToLinkId?: number;
  @tracked ticketTypeToConvertId?: number;
  @tracked displayRequiredAttributesPanel = false;
  @tracked selectedTab = 'conversation';
  @tracked isHoveringOnHeader = false;
  @tracked user?: User | undefined;
  @tracked canShowSendAndCloseHint = false;
  @trackedReset('args.conversationResource.conversation.id') displayLinkTicketPanel = false;
  @trackedReset('args.conversationResource.conversation.id') displayConvertTicketPanel = false;
  @trackedReset('args.conversationResource.conversation.id') showConversationTabs = false;
  @tracked tagActionType: TagActionType = null;
  @tracked conversationWithRequiredAttributes?: ConversationRecord;
  @tracked selectedConversationStreamTab: 'activity' | 'details' | 'thread' = 'thread';
  @tracked displayExportModal = false;
  @tracked composerApi?: PublicAPI;

  smartReplyResource = SmartReplyResource.from(this, () => ({
    conversation: this.conversation,
  }));

  readonly hotkeys: HotkeysMap;
  readonly handleNotEditorHotkey;
  readonly closeHotkey: Hotkey;
  readonly toggleConversationDetailsHotkey: Hotkey;
  readonly openCopilotHotkey: Hotkey;
  readonly openConversationDetailsHotkey: Hotkey;

  get conversation(): Conversation {
    return this.args.conversationResource.conversation;
  }

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(this.conversation?.ticketCustomStateId);
  }

  get isConversationReplyable(): boolean {
    return this.conversation.isReplyable;
  }

  @action registerComposerApi(composerApi: PublicAPI) {
    this.composerApi = composerApi;
  }

  @action insertBlocks(
    paneType: ComposerPaneType.Reply | ComposerPaneType.Note,
    blocks: BlockList,
    conversationId?: number,
    actions?: MacroAction[],
  ) {
    let prevBlocks =
      paneType === ComposerPaneType.Reply
        ? this.composerApi?.setActiveReplyPane()
        : this.composerApi?.setActiveNotePane();
    if (prevBlocks) {
      this.composerApi?.api.composer.commands.replaceAllWithBlocks(prevBlocks);
    }
    this.composerApi?.focus();
    this.composerApi?.insertBlocks(blocks, conversationId);
    if (actions) {
      this.composerApi?.insertMacroActions(actions);
    }
  }

  get shouldShowEvenlySplitPanels() {
    return (
      (this.args.isFullConversationPanel && this.isPreviewPanelOpen) ||
      this.inboxSidebarService.isPreviewingLinkedItem
    );
  }

  get isPreviewPanelOpen() {
    return (
      this.inboxState.isConversationListHidden ||
      this.inboxSidebarService.isViewingSideConversation ||
      this.inboxSidebarService.isViewingNewSideConversationPanel ||
      this.inboxSidebarService.isPreviewingConversation ||
      this.inboxSidebarService.isViewingStartLinkedConversation ||
      this.inboxSidebarService.isViewingKnowledgeBase
    );
  }

  get hasTicketTypeToAddToConversation() {
    return this.ticketTypeId !== undefined;
  }

  get enableFullScroll() {
    return !this.conversation.isReplyable;
  }

  get descriptionDescriptor() {
    return this.conversation.ticketType?.descriptors?.find(
      (descriptor) => descriptor.name === '_default_description_',
    );
  }

  get conversationAttributesById() {
    return this.conversation.attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  get descriptionAttribute() {
    if (!this.descriptionDescriptor) {
      return;
    }

    return (
      this.conversationAttributesById[this.descriptionDescriptor.id] ??
      new TicketAttributeSummary('', this.descriptionDescriptor)
    );
  }

  get isConversationStreamTabVisible() {
    return (
      this.displayLinkTicketPanel ||
      this.showConversationTabs ||
      this.showSideConversationPanel ||
      this.showKnowledgeBasePanel ||
      this.inboxSidebarService.isViewingStartLinkedConversation ||
      this.inboxSidebarService.isPreviewingLinkedItem
    );
  }

  get showSideConversationPanel() {
    return (
      this.inboxSidebarService.isViewingNewSideConversationPanel ||
      this.inboxSidebarService.isViewingSideConversation
    );
  }

  get showKnowledgeBasePanel() {
    return this.inboxSidebarService.isViewingKnowledgeBase;
  }

  get showConvertTicketPanel() {
    return this.displayConvertTicketPanel;
  }

  get tabs() {
    return ['activity', 'details'];
  }

  @action updateActivationContext() {
    if (this.commandK.currentActivationContext !== DisplayContext.Global) {
      this.commandK.setActivationContext(DisplayContext.Global);
    }
  }

  @action setTicketTypeToAddToConversation(ticketTypeId?: number) {
    this.ticketTypeId = ticketTypeId;
    this.selectedTab = 'ticketAttributes';
  }

  @action setTicketTypeToLinkToConversation(ticketTypeId?: number) {
    if (this.ticketTypeToConvertId) {
      this.hideConvertTicketPanel();
    }

    this.ticketTypeToLinkId = ticketTypeId;
    this.displayLinkTicketPanel = true;
    this.inboxSidebarService.isDisplayingLinkTicketPanel = true;
    this.inboxState.hideConversationList();
    this.inboxState.expandRHSBtoHalfScreen();
  }

  @action hideLinkTicketPanel() {
    this.ticketTypeToLinkId = undefined;
    this.displayLinkTicketPanel = false;

    this.inboxState.showConversationList();
    this.inboxState.revertRHSBToOriginalSize();
    this.resetConversationStreamTab();
    this.inboxSidebarService.isDisplayingLinkTicketPanel = false;
    this.inboxSidebarService.isViewingSideConversation = false;
    this.inboxSidebarService.isPreviewingConversation = false;
    this.inboxSidebarService.isPreviewingLinkedItem = false;
    this.inboxSidebarService.closeNewSideConversationPanel();
  }

  @action verifyAndCloseTicketPanel() {
    if (this.displayLinkTicketPanel) {
      return this.hideLinkTicketPanel();
    }

    if (this.showConvertTicketPanel) {
      return this.hideConvertTicketPanel();
    }
  }

  @action resetConversationStreamTab() {
    this.selectedConversationStreamTab = 'activity';
  }

  @action handleClosingConversationDetails() {
    this.hideLinkTicketPanel();
  }

  @action openCopilot() {
    this.inboxSidebarService.openCopilot();
  }

  @action openConversationDetails() {
    this.inboxSidebarService.openConversationDetails();
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.session.workspace.fetchConversationAttributeDescriptors();

    this.inboxState.on(InboxEvents.ShowRequiredAttributesPanel, this.showRequiredAttributesPanel);
    this.inboxState.on(InboxEvents.ConversationClosed, this.afterClosingCallback);
    this.hotkeys = this.inboxHotkeys.hotkeysMap;
    this.handleNotEditorHotkey = this.inboxHotkeys.handleNotEditorHotkey;
    this.closeHotkey = this.hotkeys[HotkeyID.Close];
    this.toggleConversationDetailsHotkey = this.hotkeys[HotkeyID.ToggleConversationDetailsSidebar];
    this.openCopilotHotkey = this.hotkeys[HotkeyID.OpenCopilot];
    this.openConversationDetailsHotkey = this.hotkeys[HotkeyID.OpenConversationDetails];
    this.selectedConversationStreamTab = 'activity';

    registerDestructor(this, () => {
      this.inboxState.off(
        InboxEvents.ShowRequiredAttributesPanel,
        this.showRequiredAttributesPanel,
      );
    });
  }

  get finConversationContentSettings(): ConversationContentSettings | undefined {
    return this.finConversationContent.settings.value;
  }

  @action showRequiredAttributesPanel(conversation: ConversationRecord) {
    if (!this.args.isFullConversationPanel) {
      // When in the full conversation panel it is triggered by the conversation table
      this.conversationWithRequiredAttributes = conversation;
    }
  }

  get exportAction() {
    if (this.conversation.isTicket) {
      return this.showExportModal;
    }

    return this.exportConversation;
  }

  @action showExportModal() {
    this.displayExportModal = true;
  }

  @action closeExportModal() {
    this.displayExportModal = false;
  }

  @action exportConversation(exportNotesAndHiddenAttributes = false) {
    if (!canExportConversationTranscriptsInbox2(this.session)) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        exportConversationTranscriptsPermission,
      );
      return;
    }

    let url = `ember/conversations/download_transcript?v=${Math.floor(
      Math.random() * 1e8,
    )}&user_visible_only=${!exportNotesAndHiddenAttributes}`;
    FormForDownload.postNewTab(url, {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
    });
    this.displayExportModal = false;
  }

  @action onUserUpdated(updatedUser: any) {
    this.user = updatedUser;
  }

  @action setTicketTypeToConvertToTicket(ticketTypeId?: number) {
    if (this.ticketTypeToLinkId) {
      this.hideLinkTicketPanel();
    }
    this.ticketTypeToConvertId = ticketTypeId;
    this.displayConvertTicketPanel = true;
    this.showConversationTabs = true;
    this.inboxState.hideConversationList();
    this.inboxState.expandRHSBtoHalfScreen();
  }

  @action hideConvertTicketPanel() {
    this.ticketTypeToConvertId = undefined;
    this.displayConvertTicketPanel = false;
    this.showConversationTabs = false;
    this.inboxState.showConversationList();
    this.inboxState.revertRHSBToOriginalSize();
  }

  get descriptors() {
    return this.session.workspace.conversationAttributeDescriptors ?? [];
  }

  get isLivePhoneCall() {
    return (
      this.twilioService.isActiveCall &&
      this.twilioService.activeCall?.conversation?.id === this.conversation.id
    );
  }

  get showTicketCreationPanel() {
    return (
      this.selectedTab === 'ticketAttributes' &&
      (this.conversation.isTicket || this.hasTicketTypeToAddToConversation)
    );
  }

  @action trackOpenConversation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      section: 'conversation_header',
      object: 'conversation',
    });
  }

  @action trackSnoozeConversation(duration: DurationObject) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'snoozed',
      section: 'conversation_header',
      object: 'conversation',
      snoozed_until: duration.type,
    });
  }

  @action async verifyAndCloseWith2ndStep() {
    return this.conversationResolveAndCloseService.startResolveAndCloseProcess(this.conversation);
  }

  @action async afterClosingCallback(closedConversation: Conversation) {
    let closedWithin5Seconds =
      closedConversation.isLastPart(RenderableType.AdminComment) &&
      closedConversation.isLastPartCreatedWithin({ seconds: 5 });

    if (closedWithin5Seconds) {
      this.canShowSendAndCloseHint = true;
    }

    this.trackCloseConversation({ closedWithin5Seconds });
    this.verifyAndCloseTicketPanel();
    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(LbaTriggerEvent.CLOSE);
  }

  sendAndClose: SendAndCloseFn = (
    blocks,
    macroActions,
    channel,
    replyData,
    participantData,
    emailHistoryMetadataId?: number,
  ) => {
    if (this.hasEmptyRequiredConversationAttributes()) {
      this.conversationWithRequiredAttributes = this.conversation;

      return this.args.conversationResource.sendReplyOrNote(
        ComposerPaneType.Reply,
        blocks,
        macroActions,
        channel,
        replyData,
        participantData,
        false,
        emailHistoryMetadataId,
      );
    }
    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
      LbaTriggerEvent.SEND_AND_CLOSE,
    );
    return this.args.conversationResource.sendAndClose(
      blocks,
      macroActions,
      channel,
      replyData,
      participantData,
      emailHistoryMetadataId,
    );
  };

  @action async updateAttributesAndCloseConversation(
    conversation: ConversationRecord,
    attributes: ConversationAttributeSummary[],
  ) {
    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
      LbaTriggerEvent.UPDATE_ATTR_AND_CLOSE,
    );
    return this.inboxState.updateAttributesAndClose(conversation, attributes);
  }

  private hasEmptyRequiredConversationAttributes() {
    let visibleAttributes = this.descriptors;

    if (this.session.workspace.canUseConditionalAttributes) {
      visibleAttributes = new ConditionalAttributesEvaluator({
        conversation: this.conversation,
        descriptors: this.descriptors,
        owner: getOwner(this),
      }).visibleAttributes() as ConversationAttributeDescriptor[];
    }

    return !isEmpty(emptyRequiredAttributesForConversation(this.conversation, visibleAttributes));
  }

  @action trackSidebarToggle(options = { keyboardShortcutUsed: false }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: this.inboxSidebarService.isSidebarCollapsed ? 'hidden' : 'shown',
      object: 'conversation_details_sidebar',
      section: options.keyboardShortcutUsed ? 'shortcuts' : undefined,
      shortcut_key: options.keyboardShortcutUsed,
    });
  }

  get isNotePane() {
    if (this.session.showLightInbox) {
      return true;
    }

    if (this.conversation.isLoading) {
      return false;
    }

    if (
      this.conversation.isTicket &&
      this.conversation.isCustomerTicket &&
      this.conversation.commentOrNoteParts.length === 0
    ) {
      return false;
    }

    return (
      this.conversation.commentOrNoteParts.length === 0 || this.conversation.lastCommentIsANote
    );
  }

  get classNameIfSideBarIsCollapsed() {
    if (this.inboxSidebarService.isSidebarCollapsed && !this.inboxState.isTableView) {
      return 'has-collapsed-sidebar';
    }

    return '';
  }

  get hideEvents() {
    return this.conversationStreamSettings.hideEvents;
  }

  @action toggleEvents() {
    this.conversationStreamSettings.toggleEvents();
  }

  @action dismissSendAndCloseHint() {
    this.canShowSendAndCloseHint = false;
  }

  private trackCloseConversation(metadata: { closedWithin5Seconds?: boolean } = {}) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      section: 'conversation_header',
      object: 'conversation',
      closed_within_5_seconds_of_reply: metadata.closedWithin5Seconds ?? false,
    });
  }

  @action
  toggleSidebarVisibility() {
    if (this.args.toggleSidebarVisibility) {
      this.args.toggleSidebarVisibility();
    } else {
      this.inboxSidebarService.toggleSidebarVisibility();
      this.trackSidebarToggle({ keyboardShortcutUsed: true });
    }
  }

  get highlights() {
    return this.args.conversationResource?.highlights;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage': typeof ConversationPageComponent;
    'inbox2/conversation-page': typeof ConversationPageComponent;
  }
}
