/* import __COLOCATED_TEMPLATE__ from './weekly-email-banner.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  numberOfPendingItemsToReview: number;
  buttonType: string;
  justifyValue: string;
  rounded: boolean;
}

export default class WeeklyEmailBanner extends Component<Args> {
  @service declare router: Router;
  @service declare appService: any;
  @service declare store: any;
  @tracked showBanner = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.loadAdminNotificationSettings).perform();
  }

  @action goToAccountNotification(): void {
    this.router.transitionTo('apps.app.account.notifications');
  }

  @task({ drop: true })
  *loadAdminNotificationSettings(): TaskGenerator<void> {
    let adminId = this.appService.app.currentAdmin.id;
    let settings =
      this.store.peekRecord('admin-notification-settings', adminId) ||
      (yield this.store.findRecord('admin-notification-settings', adminId));

    this.showBanner = !settings.emailForContentReviewRequests;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Banners::WeeklyEmailBanner': typeof WeeklyEmailBanner;
  }
}
