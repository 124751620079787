/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MessengerAppInsertionService from 'embercom/services/messenger-app-insertion-service';
import { MessengerAppEvents } from 'embercom/services/messenger-app-insertion-service';
import type Session from 'embercom/services/session';
import { registerDestructor } from '@ember/destroyable';
import { post } from 'embercom/lib/ajax';
import { type MessengerCardBlock } from '@intercom/interblocks.ts';

interface Args {
  conversationId?: number;
  insertApp: (actionData: MessengerCardBlock) => void;
}

interface Signature {
  Args: Args;
}

export default class MessengerAppInserter extends Component<Signature> {
  @service declare session: Session;
  @service declare messengerAppInsertionService: MessengerAppInsertionService;
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.messengerAppInsertionService.on(
      MessengerAppEvents.InitializeMessengerApp,
      this,
      this.initializeMessengerApp,
    );

    registerDestructor(this, () => {
      this.messengerAppInsertionService.off(
        MessengerAppEvents.InitializeMessengerApp,
        this,
        this.initializeMessengerApp,
      );
    });
  }

  async initializeMessengerApp(
    inboxAppId: number,
    canvasCreationOptions: Record<string, unknown>,
    resolve: () => void,
    reject: (error: any) => void,
  ) {
    try {
      let actionData = await post('/ember/inbox_apps/initialize_messenger_app', {
        app_id: this.session.workspace.id,
        inbox_app_id: inboxAppId,
        context: {
          conversation_id: this.args.conversationId,
        },
        card_creation_params: {
          canvas_creation_options: canvasCreationOptions,
        },
      });

      this.args.insertApp(actionData);
      this.messengerAppInsertionService.updateInboxAppCanvas(inboxAppId, resolve, reject);
    } catch (error) {
      this.messengerAppInsertionService.updateInboxAppCanvas(inboxAppId, resolve, reject, error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationReplyComposer::MessengerAppInserter': typeof MessengerAppInserter;
    'inbox2/conversation-reply-composer/messenger-app-inserter': typeof MessengerAppInserter;
  }
}
