/* RESPONSIBLE TEAM: team-messenger */

import Model, { attr } from '@ember-data/model';

export default class TemporaryExpectationsLocalizedMessage extends Model {
  @attr('string') localeId?: string;
  @attr('string') content?: string;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
