/* import __COLOCATED_TEMPLATE__ from './quiet-hours.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import TimesByQuarterHour from 'embercom/models/data/times-by-quarter-hour';
import TimePeriod from 'embercom/models/settings/time-period';
import { DAY_IN_MINUTES } from 'embercom/components/matching-system/matching-timetable-editor';
export default class QuietHours extends Component {
  @tracked smsOutboundSettings = this.args.smsOutboundSettings;
  @tracked quietHoursTimeIntervals = this.smsOutboundSettings.startAndEndInterval;

  get availableStartTimes() {
    return TimesByQuarterHour.slice(0, TimesByQuarterHour.length - 2);
  }

  get availableEndTimes() {
    return TimesByQuarterHour.filter(({ value }) => {
      return value !== this.quietHoursTimeIntervals.startMinute;
    });
  }

  @action
  updateStartTimeSelection(value) {
    this.quietHoursTimeIntervals.startMinute = value;
    this.createTimeIntervals();
  }

  @action
  updateEndTimeSelection(value) {
    this.quietHoursTimeIntervals.endMinute = value;
    this.createTimeIntervals();
  }

  /**
   * Quiet hours is day agnostic at the moment, hence for creating intervals,
   * we loop through each day and add its minutes (referred to as addition) to the original time intervals.
   * Two major situations to be handled are:
   * - when the startMinute < endMinute, e.g: 8am - 9pm.
   *   In such a scenario, we create intervals with the original start and end minutes, since the intervals corresspond to a single day.
   * - when the startMinute > endMinute, e.g: 8pm - 11am.
   *   In such a scenario, we create intervals from 8pm - 11:59pm and then 12am - 11am for each day.
   */
  @action
  createTimeIntervals() {
    let periodValues = TimePeriod.periodValueMapping.everyday;
    let newIntervals = [];
    for (let day = periodValues.startDay; day <= periodValues.endDay; day++) {
      let addition = DAY_IN_MINUTES * day;
      if (this.quietHoursTimeIntervals.startMinute < this.quietHoursTimeIntervals.endMinute) {
        newIntervals.push({
          startMinute: this.quietHoursTimeIntervals.startMinute + addition,
          endMinute: this.quietHoursTimeIntervals.endMinute + addition,
        });
      } else {
        newIntervals.push(
          {
            startMinute: 0 + addition,
            endMinute: this.quietHoursTimeIntervals.endMinute + addition,
          },
          {
            startMinute: this.quietHoursTimeIntervals.startMinute + addition,
            endMinute: 48 * 30 - 1 + addition,
          },
        );
      }
    }

    this.updateQuietHours(newIntervals);
  }

  @action
  updateQuietHours(quietHoursTimeIntervals) {
    this.smsOutboundSettings.quietHoursTimeIntervals = quietHoursTimeIntervals;
    this.smsOutboundSettings.save();
  }
}
