/* RESPONSIBLE TEAM: team-data-foundations */
import { attr } from '@ember-data/model';
import Step from 'embercom/models/operator/visual-builder/step';
import { type SyncHasMany } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';

import { buildValidations, validator } from 'ember-cp-validations';
import type Store from '@ember-data/store';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';

import { type Block } from 'embercom/models/common/blocks/block';
import type ConnectionPoint from '../connection-point';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  outwardConnectionPoints: [validator('has-many')],
});

export default class GithubCreateIssue extends Step.extend(Validations) {
  @attr('string') declare repositoryName?: string;
  @attr('string', { defaultValue: '_none_' }) declare templatePath: string;
  @attr('boolean', { defaultValue: true }) declare useMainRepository: boolean;

  @service declare store: Store;
  declare action?: InsertableAction;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;
  @service router: any;
  @service intl: any;

  get supportedChannels() {
    return ALL_CHANNELS;
  }

  static get supportedContexts() {
    return [
      TargetContext.Conversation,
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
      TargetContext.TrackerTicket,
    ];
  }

  constructor() {
    super(...arguments);
    let action = this.store.createRecord('workflow-connector/insertable-action', {
      name: this.intl.t('operator.workflows.visual-builder.github-create-issue.action-name'),
      description: this.intl.t(
        'operator.workflows.visual-builder.github-create-issue.action-description',
      ),
      request_attribute_identifiers: 'ticket.title,ticket.description',
      response_attribute_identifiers: 'conversation.custom_attribute.external_id',
    });
    this.set('action', action);
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get sidebar() {
    return {
      header: 'operator.workflows.visual-builder.github-create-issue.settings.header',
      component: 'workflows/graph-editor/node-items/steps/github-integration/create-settings',
    };
  }

  static createNewStep(store: Store): GithubCreateIssue {
    let outwardConnectionPoints = [
      store.createRecord('operator/visual-builder/connection-point', { type: 'fallback' }),
    ] as ConnectionPoint[];

    return store.createRecord('operator/visual-builder/step/github-create-issue', {
      outwardConnectionPoints,
      type: 'operator/visual-builder/step/github-create-issue',
    });
  }
}
