/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';
import { captureException } from 'embercom/lib/sentry';
import type Store from '@ember-data/store';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';

export default class BillingIndexController extends Controller {
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare router: Router;
  @service declare intl: IntlService;

  // Initialized in setupController.
  @tracked currentSelection: $TSFixMe;
  @tracked currentQuestion: $TSFixMe;
  @tracked questionQueue: $TSFixMe;
  @tracked freeTextAnswer = '';

  queryParams = [];

  responses: $TSFixMe[] = [];

  get allQuestions() {
    return this.model as $TSFixMe;
  }

  get chosenAnswers() {
    let { cancellationReasons, multiSelect } = this.currentQuestion;

    if (multiSelect) {
      return cancellationReasons.filter(({ isChecked }: { isChecked: $TSFixMe }) => isChecked);
    }
    return cancellationReasons.filter(({ id }: { id: $TSFixMe }) => id === this.currentSelection);
  }

  get followUpQuestions() {
    let followUpQuestionIds = this.chosenAnswers
      .flatMap(
        ({ followUpQuestionIds }: { followUpQuestionIds: $TSFixMe }) => followUpQuestionIds || [],
      )
      .uniq();
    return this.allQuestions.filter(({ id }: { id: $TSFixMe }) =>
      followUpQuestionIds.includes(parseInt(id, 10)),
    );
  }

  get hasNoMoreQuestions() {
    return this.questionQueue.length === 0 && this.followUpQuestions.length === 0;
  }

  get hasSelectedAnswer() {
    return this.chosenAnswers.length > 0;
  }

  get enteredValidInput() {
    return this.chosenAnswers.every(
      (answer: $TSFixMe) => !answer.freeTextResponse || this.freeTextAnswer.length > 0,
    );
  }

  get hasValidAnswer() {
    return this.hasSelectedAnswer && this.enteredValidInput;
  }

  get freeTextOnlyQuestion() {
    let { cancellationReasons } = this.currentQuestion;
    return cancellationReasons.length === 1 && cancellationReasons.firstObject.freeTextResponse;
  }

  get buttonLabel() {
    return this.hasNoMoreQuestions
      ? this.intl.t('new-settings.workspace.billing.cancel.index.submit')
      : this.intl.t('new-settings.workspace.billing.cancel.index.continue');
  }

  @action nextQuestion() {
    this.responses.push({
      reasonIds: this.chosenAnswers.map(({ id }: { id: $TSFixMe }) => id),
      comment: this.freeTextAnswer,
    } as $TSFixMe);

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'next_question_button',
      context: 'billing-cancel',
      place: 'billing.cancel.index',
      reasonIds: this.chosenAnswers.map(({ id }: { id: $TSFixMe }) => id),
      comment: this.freeTextAnswer,
    });

    this.questionQueue = [...this.followUpQuestions, ...this.questionQueue];
    this.currentQuestion = this.questionQueue.shift();
    if (this.currentQuestion.multiSelect) {
      this.currentQuestion.cancellationReasons.forEach((reason: $TSFixMe) => reason.reset());
    }
    this.freeTextAnswer = '';
    this.currentSelection = this.currentQuestion.cancellationReasons.firstObject.id;
  }

  @restartableTask
  *submitResponse() {
    this.responses.push({
      reasonIds: this.chosenAnswers.map(({ id }: { id: $TSFixMe }) => id),
      comment: this.freeTextAnswer,
    });

    if (this.responses.length === 0) {
      captureException(
        new Error(this.intl.t('new-settings.workspace.billing.cancel.index.error'), {
          extra: {
            allQuestionIds: this.allQuestions.map(({ id }: { id: $TSFixMe }) => id),
            app_id: this.appService.app.id,
          },
        } as unknown as ErrorOptions),
      );
    }

    let response = this.store.createRecord('cancellation-feedback-response', {
      cancellationQuestionResponses: this.responses,
    });

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'submit_survey_button',
      context: 'billing-cancel',
      place: 'billing.cancel.index',
      responseIds: this.responses.reduce((all, { reasonIds }) => [...all, ...reasonIds], []),
      comment: this.freeTextAnswer,
    });

    yield response.save();
    this.router.transitionTo(`apps.app.settings.subscription.billing.cancel.confirm`);
  }

  get isSubmitResponseRunning() {
    return taskFor(this.submitResponse).isRunning;
  }

  set isSubmitResponseRunning(_value: $TSFixMe) {
    // no-op
  }

  @action submitAndGoToCancel() {
    taskFor(this.submitResponse).perform();
  }
}
