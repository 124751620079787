/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type Store from '@ember-data/store';
import type ApiConfiguration from 'embercom/models/standalone/api-configuration';

export default class StandaloneChannelsApiRoute extends IntercomRoute {
  @service declare appService: any;
  @service declare store: Store;

  async model(): Promise<ApiConfiguration> {
    let apiConfig = await this.store.findRecord(
      'standalone/api-configuration',
      this.appService.app.id,
    );

    if (apiConfig) {
      return apiConfig;
    }

    return this.store.createRecord('standalone/api-configuration', {
      id: this.appService.app.id,
    });
  }
}
