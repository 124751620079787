/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type IntlService from 'embercom/services/intl';
import type BillingIndexController from 'embercom/controllers/apps/app/settings/subscription/billing/index';

export default class BillingIndexRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare cardlessTrialService: CardlessTrialService;

  get titleToken() {
    return this.intl.t('apps.app.billing.subscription');
  }

  get app() {
    return this.appService.app;
  }

  get subscription() {
    return this.customerService.customer?.subscription;
  }

  get showBillingEarlyStageRejection() {
    return (
      (this.cardlessTrialService.canUseEarlyStageDetection || this.app.onPricing5) &&
      this.app.earlyStageApplicationRejected &&
      (this.customerService.customer?.inSelfServeTrial ||
        (this.customerService.customer?.inExpiredSelfServeTrial &&
          this.app.hasNoActiveSubscription))
    );
  }

  redirect(model: $TSFixMe, _transition: Transition) {
    this.customerService.resetAll();
    if (model.hasNoActiveSubscription) {
      return;
    }
    if (this.showBillingEarlyStageRejection) {
      return;
    }
    if (this.app.canUseBillingSummaryRedesign) {
      return this.transitionTo(
        'apps.app.settings.subscription.billing.current-billing-period-charges',
      );
    } else if (
      this.app.isOnValueBasedPricing ||
      this.app.onPricing5 ||
      ((this.model as $TSFixMe).isSalesforceContracted &&
        !(this.model as $TSFixMe).hasCustomPricing) ||
      this.customerService.get('earlyStageGraduation.vbpGraduation') ||
      (this.app.canUsePerProductPricingFlow &&
        !this.customerService.customer?.currentEarlyStageCustomer) ||
      this.subscription?.isOnTprPricingModel
    ) {
      return this.transitionTo('apps.app.settings.subscription.billing.summary');
    }
    return this.transitionTo('apps.app.settings.subscription.billing.details');
  }

  setupController(controller: BillingIndexController, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);
    this.customerService.syncPlanIds();
  }

  resetController(
    _controller: BillingIndexController,
    _isExiting: boolean,
    _transition: Transition,
  ) {
    this.customerService.resetAll();
  }
}
