/* import __COLOCATED_TEMPLATE__ from './edit-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    editActiveContent: () => void;
    tooltipKey: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const EditButton = templateOnlyComponent<Signature>();
export default EditButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::EditButton': typeof EditButton;
  }
}
