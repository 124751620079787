/* import __COLOCATED_TEMPLATE__ from './signature.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dropTask, enqueueTask, restartableTask } from 'ember-concurrency-decorators';
import AttributeInfoResolver, {
  AUTHOR_NAME_ATTRIBUTE,
  AUTHOR_FULL_NAME_ATTRIBUTE,
  APP_NAME_ATTRIBUTE,
  AUTHOR_JOB_TITLE_ATTRIBUTE,
  AUTHOR_PHONE_NUMBER_ATTRIBUTE,
  AUTHOR_DEPARTMENT_ATTRIBUTE,
  AUTHOR_EMAIL_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';
import {
  BaseConfig,
  BlocksDocument,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  type TextDirection,
} from '@intercom/embercom-prosemirror-composer';
import { post } from 'embercom/lib/ajax';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { timeout } from 'ember-concurrency';
import imageSize from 'embercom/lib/image-size';
import { INLINE_CONTROL_ALIGNMENT } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type Brand from 'embercom/models/brand';
import type BrandService from 'embercom/services/brand-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';

interface Args {}

class ComposerConfig extends BaseConfig {
  placeholder = '';
  autoFocus = false;
  customStylesConfig = {
    allowCustomStyles: true,
    formattingSidebarClass: 'email-composer-formatting-side-bar',
  };
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = true;
  hideFromBlockFormatter = [INLINE_CONTROL_ALIGNMENT];
  hideFromTextFormatter = [INLINE_CONTROL_ALIGNMENT];
  textDirection = 'ltr' as TextDirection;
  allowedBlocks = [
    'paragraph',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
    'html',
  ];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];

  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'media-inserter' },
    { name: 'html/edit-button' },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor({ resolver }: { resolver: AttributeInfoResolver }) {
    super();

    this.templating = {
      picker: 'common/attribute-picker',
      resolver,
    };
  }
}

const EMAIL_SIGNATURE_ATTRIBUTES = [
  AUTHOR_NAME_ATTRIBUTE,
  AUTHOR_FULL_NAME_ATTRIBUTE,
  AUTHOR_JOB_TITLE_ATTRIBUTE,
  AUTHOR_PHONE_NUMBER_ATTRIBUTE,
  AUTHOR_DEPARTMENT_ATTRIBUTE,
  AUTHOR_EMAIL_ATTRIBUTE,
  APP_NAME_ATTRIBUTE,
];

const DEBOUNCE_MS = 250;

const DEFAULT_WIDTH = 100;

export default class Signature extends Component<Args> {
  @service declare attributeService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare brandService: BrandService;

  private DEFAULT_BRAND_ID = -1;
  @tracked signature: $TSFixMe;
  @tracked blocksDoc: BlocksDocument;
  @tracked previewHtml: $TSFixMe;
  @tracked showSideDrawer = false;
  @tracked brands: Brand[] = [];
  @tracked selectedBrandId = this.DEFAULT_BRAND_ID;
  @tracked isEditing = false;
  @tracked originalSignature: $TSFixMe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument([]);
    taskFor(this.loadSignature).perform();
    taskFor(this.loadBrands).perform();
  }

  get composerConfig() {
    return new ComposerConfig({ resolver: this.resolver });
  }

  get resolver() {
    let attributes = [...EMAIL_SIGNATURE_ATTRIBUTES];

    // Modify APP_NAME_ATTRIBUTE display name when in multi-brand context
    if (this.selectedBrandId !== this.DEFAULT_BRAND_ID) {
      attributes = attributes.map((attr) => {
        if (attr === APP_NAME_ATTRIBUTE) {
          return {
            ...APP_NAME_ATTRIBUTE,
            name: this.intl.t('channels.email.settings.signature.brand_name'),
          };
        }
        return attr;
      });
    }

    return new AttributeInfoResolver({
      attributes: [],
      includeAppAttributes: true,
      manualAppAttributes: attributes,
      hideAppAllowedAttributes: true,
    });
  }

  get serializedBlocks() {
    return this.signature?.blocks || [];
  }

  get logoMaxSize() {
    return 9999;
  }

  get logoMinSize() {
    return 1;
  }

  get loadSignatureRunning() {
    return taskFor(this.loadSignature).isRunning;
  }

  get brandsList() {
    let defaultBrandOption = {
      text: this.appService.app.name,
      value: this.DEFAULT_BRAND_ID,
    };

    return [
      defaultBrandOption,
      ...this.customBrands
        .map((brand: Brand) => ({
          text: brand.name,
          value: brand.id,
        }))
        .sort((a: any, b: any) => {
          return a.text.localeCompare(b.text);
        }),
    ];
  }

  get canShowBrandsDropdown() {
    return this.canUseMultiBrandEmailOpenBeta;
  }

  get canUseMultiBrandEmailOpenBeta() {
    return this.appService.app.canUseMultiBrandEmailOpenBeta;
  }

  get customBrands() {
    return this.brands.filter((brand) => !brand.isDefault);
  }

  async LogoDimensionRatio(dimensions: { width: number; height: number }) {
    return dimensions.width / dimensions.height;
  }

  async getLogoDimensions(src: string) {
    return await imageSize(src);
  }

  logoDefaultHeight(ratio: number) {
    return Math.round(DEFAULT_WIDTH / ratio);
  }

  private updateOriginalSignature() {
    if (this.signature) {
      this.originalSignature = {
        ...this.signature,
        blocks: JSON.parse(JSON.stringify(this.signature.blocks)),
        logoUrl: this.signature.logoUrl,
        logoPosition: this.signature.logoPosition,
        width: this.signature.width,
        height: this.signature.height,
      };
    } else {
      this.originalSignature = null;
    }
  }

  @enqueueTask
  *updateSignature() {
    try {
      yield this.signature.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
      this.updateOriginalSignature();
      this.stopEditing();
      if (!this.brands.length) {
        this.closeSideDrawer();
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'email_signature',
        section: 'settings',
        place: 'email_settings',
        brand_id:
          this.selectedBrandId === this.DEFAULT_BRAND_ID ? 'default_brand' : this.selectedBrandId,
        is_default_brand: this.selectedBrandId === this.DEFAULT_BRAND_ID,
      });
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.signature.save-error'),
      });
    }
  }

  @dropTask
  *loadSignature(): TaskGenerator<any> {
    try {
      this.signature = yield this.store.queryRecord('email-signature', {
        app_id: this.appService.app.id,
        brand_id: this.selectedBrandId === -1 ? undefined : this.selectedBrandId,
      });
      this.blocksDoc = new BlocksDocument(this.signature.blocks);
      taskFor(this.updateSignaturePreview).perform();
      if (this.signature.logoUrl) {
        let dimensions = yield this.getLogoDimensions(this.signature.logoUrl);
        let ratio = yield this.LogoDimensionRatio(dimensions);
        this.signature.width = this.signature.width || DEFAULT_WIDTH;
        this.signature.height = this.signature.height || this.logoDefaultHeight(ratio);
      }

      this.updateOriginalSignature();
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.signature.load-error'),
      });
    }
  }

  @dropTask
  *loadBrands(): TaskGenerator<any> {
    if (
      this.appService.app.canUseMultiBrandEmailOpenBeta &&
      this.brandService.isAppOnRightMultibrandPlan
    ) {
      try {
        let brands = yield this.store.findAll('brand').then((brands) => {
          return brands.map((brand) => ({
            id: brand.get('id'),
            name: brand.get('name'),
            isDefault: brand.get('isDefault'),
          }));
        });
        this.brands = brands;
      } catch (error) {
        console.error(error);
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('channels.email.settings.signature.load-error'),
        });
      }
    }
  }

  @action
  updateBlocks(blocksDoc: BlocksDocument) {
    this.blocksDoc = blocksDoc;
    this.updateSignatureBlocks();
    taskFor(this.updateSignaturePreview).perform({ blocks: this.blocksDoc.blocks });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'text',
    });
  }

  @action
  async onImageChange(logoUrl: string) {
    if (logoUrl) {
      let dimensions = await this.getLogoDimensions(logoUrl);
      let ratio = await this.LogoDimensionRatio(dimensions);
      this.signature.width = DEFAULT_WIDTH;
      this.signature.height = this.logoDefaultHeight(ratio);
      if (!this.signature.logoPosition) {
        this.signature.logoPosition = 'left';
      }
    } else {
      this.signature.width = null;
      this.signature.height = null;
      this.signature.logoPosition = null;
    }
    this.signature.logoUrl = logoUrl;
    taskFor(this.updateSignaturePreview).perform({ logo_url: logoUrl });

    this.intercomEventService.trackAnalyticsEvent({
      action: logoUrl ? 'updated_image' : 'removed_image',
      object: 'logo',
    });
  }

  @action
  onLogoPositionChange(position: string) {
    this.signature.logoPosition = position;
    taskFor(this.updateSignaturePreview).perform({ position });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated_position',
      object: 'logo',
    });
  }

  @action
  async onLogoHeightChange(height: number) {
    this.signature.height = height;
    if (this.signature.height > this.logoMaxSize) {
      height = this.logoMaxSize;
    }
    if (this.signature.height < this.logoMinSize) {
      height = this.logoMinSize;
    }
    let dimensions = await this.getLogoDimensions(this.signature.logoUrl);
    let ratio = await this.LogoDimensionRatio(dimensions);
    let width = Math.round(height * ratio);
    this.signature.width = width;
    if (height === this.logoMinSize) {
      this.signature.width = this.logoMinSize;
    }
    this.signature.height = height;
    taskFor(this.updateSignaturePreview).perform({
      width: this.signature.width,
      height: this.signature.height,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated_height',
      object: 'logo',
    });
  }

  @action
  async onLogoWidthChange(width: number) {
    this.signature.width = width;
    if (width > this.logoMaxSize) {
      width = this.logoMaxSize;
    }
    if (width < this.logoMinSize) {
      width = this.logoMinSize;
    }
    let dimensions = await this.getLogoDimensions(this.signature.logoUrl);
    let ratio = await this.LogoDimensionRatio(dimensions);
    let height = Math.round(width / ratio);
    this.signature.width = width;
    this.signature.height = height;
    if (width === this.logoMinSize) {
      this.signature.height = this.logoMinSize;
    }
    taskFor(this.updateSignaturePreview).perform({
      width: this.signature.width,
      height: this.signature.height,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated_width',
      object: 'logo',
    });
  }

  @action
  saveSignature() {
    taskFor(this.updateSignature).perform();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved_changes',
      object: 'signature',
      brand_id:
        this.selectedBrandId === this.DEFAULT_BRAND_ID ? 'default_brand' : this.selectedBrandId,
      is_default_brand: this.selectedBrandId === this.DEFAULT_BRAND_ID,
    });
  }

  @action
  onBrandSelect(brandId: number) {
    this.selectedBrandId = brandId;
    taskFor(this.loadSignature).perform();
  }

  @action
  openBrandsSettingsPage() {
    let url = this.router.urlFor('apps.app.settings.workspace.brands', this.appService.app.id);
    safeWindowOpen(url, '_blank');

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_signature',
      section: 'settings',
      place: 'email_settings',
      value: 'brands_settings_page',
    });
  }

  updateSignatureBlocks() {
    if (this.signature) {
      this.signature.blocks = this.blocksDoc.blocks;
    }
  }

  get signatureAttributes(): {
    blocks: BlocksDocument;
    logo_url: string;
    logo_position: string;
    width: number;
    height: number;
    brand_id?: number;
  } {
    return {
      blocks: this.signature.blocks,
      logo_url: this.signature.logoUrl,
      logo_position: this.signature.logoPosition,
      width: this.signature.width,
      height: this.signature.height,
      brand_id: this.selectedBrandId === this.DEFAULT_BRAND_ID ? undefined : this.selectedBrandId,
    };
  }

  @restartableTask
  *updateSignaturePreview(updatedAttributes = {}): TaskGenerator<any> {
    try {
      yield timeout(DEBOUNCE_MS);
      let params = {
        app_id: this.appService.app.id,
        ...this.signatureAttributes,
        ...updatedAttributes,
      };

      if (this.selectedBrandId !== this.DEFAULT_BRAND_ID) {
        params.brand_id = this.selectedBrandId;
      }
      let response = yield post('/ember/email_signature_preview', params);
      this.previewHtml = sanitizeHtml(response.html);
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.signature.update-preview-error'),
      });
    }
  }

  @action
  openSideDrawer() {
    this.selectedBrandId = this.DEFAULT_BRAND_ID;
    taskFor(this.loadSignature).perform();
    this.showSideDrawer = true;
  }

  @action
  closeSideDrawer() {
    this.isEditing = false;
    this.showSideDrawer = false;

    if (this.originalSignature) {
      this.signature.blocks = this.originalSignature.blocks;
      this.signature.logoUrl = this.originalSignature.logoUrl;
      this.signature.logoPosition = this.originalSignature.logoPosition;
      this.signature.width = this.originalSignature.width;
      this.signature.height = this.originalSignature.height;

      this.blocksDoc = new BlocksDocument(this.originalSignature.blocks);
      taskFor(this.updateSignaturePreview).perform();
    }

    this.originalSignature = null;
  }

  @action
  startEditing() {
    this.isEditing = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_signature',
      section: 'settings',
      place: 'email_settings',
      value: 'signature_start_editing',
      brand_id:
        this.selectedBrandId === this.DEFAULT_BRAND_ID ? 'default_brand' : this.selectedBrandId,
      is_default_brand: this.selectedBrandId === this.DEFAULT_BRAND_ID,
    });
  }

  @action
  stopEditing() {
    this.isEditing = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_signature',
      section: 'settings',
      place: 'email_settings',
      value: 'signature_stop_editing',
      brand_id:
        this.selectedBrandId === this.DEFAULT_BRAND_ID ? 'default_brand' : this.selectedBrandId,
      is_default_brand: this.selectedBrandId === this.DEFAULT_BRAND_ID,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::Signature': typeof Signature;
  }
}
