/* RESPONSIBLE TEAM: team-knowledge-interop */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const OTHER_DEVICES_ICONS = [
  'computer-desktop',
  'tv',
  'radio',
  'device-phone-mobile',
  'camera',
  'printer',
  'video-camera',
  'calculator',
];
const otherDevicesCommonKeywords = ['device', 'technology', 'hardware', 'electronics', 'imaging'];
export const OTHER_DEVICES_KEYWORDS = {
  [OTHER_DEVICES_ICONS[0]]: [
    ...otherDevicesCommonKeywords,
    'computer-desktop',
    'monitor',
    'screen',
    'workstation',
    'PC',
  ],
  [OTHER_DEVICES_ICONS[1]]: [
    ...otherDevicesCommonKeywords,
    'TV',
    'television',
    'screen',
    'broadcast',
    'entertainment',
    'display',
    'home',
  ],
  [OTHER_DEVICES_ICONS[2]]: [
    ...otherDevicesCommonKeywords,
    'radio',
    'broadcast',
    'audio',
    'communication',
    'media',
    'sound',
    'transmission',
    'station',
  ],
  [OTHER_DEVICES_ICONS[3]]: [
    ...otherDevicesCommonKeywords,
    'phone',
    'mobile',
    'smartphone',
    'calls',
    'media',
    'gadget',
  ],
  [OTHER_DEVICES_ICONS[4]]: [
    ...otherDevicesCommonKeywords,
    'camera',
    'photography',
    'photo',
    'recording',
    'capture',
    'lens',
  ],
  [OTHER_DEVICES_ICONS[5]]: [
    ...otherDevicesCommonKeywords,
    'printer',
    'printing',
    'paper',
    'copy',
    'fax',
  ],
  [OTHER_DEVICES_ICONS[6]]: [
    ...otherDevicesCommonKeywords,
    'recording',
    'video',
    'filming',
    'camera',
    'capture',
  ],
  [OTHER_DEVICES_ICONS[7]]: [
    ...otherDevicesCommonKeywords,
    'calculator',
    'math',
    'numbers',
    'calculations',
    'arithmetic',
    'tool',
  ],
};
