/* import __COLOCATED_TEMPLATE__ from './string-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { task } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { all } from 'ember-concurrency';
import { mapValueToLabel } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { isPresent } from '@ember/utils';
import { union } from 'underscore';

const REGEX_FILTERS = ['starts_with', 'ends_with', 'contains', 'not_contains'];
const CATEGORY_FILTERS = ['category', 'not_in_category'];
export default class StringFilter extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service intl;
  @service reportingMetrics;
  @tracked label = this.currentLabel;
  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked stagedValues = this.selectedValues;
  @tracked operator = this.startingOperator;

  @tracked knownValues = null;
  @tracked lastFilterValue = null;

  previousOperator = this.operator;
  regexFilters = REGEX_FILTERS;

  // when drop down closes value becomes blank
  @tracked value = this.selectedValues[0] || '';

  regexFiltersEnabled = this.args.regexFiltersEnabled || false;

  constructor() {
    super(...arguments);
    this.initializeValues.perform();
  }

  get operatorLabelMappings() {
    return {
      category: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.is',
      ),
      not_in_category: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.is-not',
      ),
      exists: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.exists',
      ),
      not_exists: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.not-exists',
      ),
      contains: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.contains',
      ),
      starts_with: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.starts_with',
      ),
      ends_with: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.ends_with',
      ),
      not_contains: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.not_contains',
      ),
    };
  }

  get selectedValues() {
    let values = this.args.selected.values || [];
    return values[0] === REPORTING_FILTER_SELECT_ALL ? [] : values;
  }

  get startingOperator() {
    return this.args.selected.operator || 'category';
  }

  formatted(values) {
    return values.map((value) => mapValueToLabel(this.args.type, value) || '(blank)');
  }

  labelFor(values) {
    switch (this.operator) {
      case 'category':
      case 'not_in_category': {
        let label =
          this.formatted(values).join(', ') ||
          this.intl.t(
            'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.any',
          );
        return `${this.operatorLabelMappings[this.operator]} ${label}`;
      }
      case 'exists':
      case 'not_exists':
        return this.operatorLabelMappings[this.operator];
      case 'contains':
      case 'starts_with':
      case 'ends_with':
      case 'not_contains':
        return `${this.operatorLabelMappings[this.operator]} ${this.value}`;
    }
  }

  get currentLabel() {
    return this.labelFor(this.stagedValues);
  }

  get openerLabel() {
    return (
      this.currentLabel ||
      this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.add',
      )
    );
  }

  _makeRegexFilter() {
    return [this.value];
  }

  _makeCategoryFilter() {
    return this.stagedValues.length ? this.stagedValues : [REPORTING_FILTER_SELECT_ALL];
  }

  get valuesForFilter() {
    if (REGEX_FILTERS.includes(this.operator)) {
      return this._makeRegexFilter();
    } else if (CATEGORY_FILTERS.includes(this.operator)) {
      return this._makeCategoryFilter();
    } else {
      return [];
    }
  }

  @task({ restartable: true }) *initializeValues(filter) {
    yield this.loadFilteredKnownValues.perform(filter);
  }

  @task({ restartable: true }) *loadFilteredKnownValues(filter) {
    let knownValues = Array.from(yield this.fetchKnownValues.perform(filter));

    this.lastFilterValue = filter;
    this.knownValues = knownValues;
  }

  @task({ restartable: true }) *fetchKnownValues(filter) {
    let sources = this.args.loadKnownValuesSources || ['conversation'];
    let childTasks = sources.map((source) => this.fetchData.perform(source, filter));
    let responses = yield all(childTasks);
    return new Set(responses.flat());
  }

  @task({ enqueue: true, maxConcurrency: 12 }) *fetchData(source, filter) {
    let options = {
      app_id: this.appService.app.id,
      source,
      property: this.property,
    };
    if (filter && filter.length) {
      options['filter'] = filter;
    }
    try {
      return yield get('/ember/reporting/known_values', options);
    } catch (e) {
      if (e.errorThrown === 'Forbidden') {
        // If we lack permissions to fetch known values, we should just return any existing selection.
        console.warn(
          `Forbidden from fetching known values for source=${source}, and property=${this.property}`,
        );
        return this.selectedValues;
      }
    }
  }

  get property() {
    // Before migrating to attributes this.args.type used to be the field value.
    let attribute = this.reportingMetrics.attributesById[this.args.type];
    return attribute ? attribute.field : this.args.type;
  }

  get items() {
    return [
      {
        items: [this.unknownSearchItem, ...this.allItems].compact(),
      },
    ];
  }

  get unknownSearchItem() {
    if (
      isPresent(this.lastFilterValue) &&
      this.allItems.find((item) => item.value === this.lastFilterValue) === undefined
    ) {
      return {
        text: this.lastFilterValue,
        value: this.lastFilterValue,
        isSelected: this.stagedValues.includes(this.lastFilterValue),
        component: 'reporting/custom/chart-builder/filter-bar/string-filter-unknown-item',
      };
    } else {
      return null;
    }
  }

  get allItems() {
    let knownValues = this.knownValues || [];
    let valuesToMap = union(this.stagedValues, knownValues);

    return valuesToMap.map((knownValue) => ({
      text: mapValueToLabel(this.args.type, knownValue) || '(blank)',
      value: knownValue,
      isSelected: this.stagedValues.includes(knownValue),
    }));
  }

  @action
  onClose() {
    if (!this.valuesForFilter.length && !this.operator) {
      return;
    }

    let analyticsObject;

    analyticsObject = {
      action: 'filtered_custom_field',
      object: this.args.analyticsObject,
      filter_name: this.args.type,
      custom_field_type: this.args.attributeType || 'string',
      operator: this.operator,
    };
    this.intercomEventService.trackAnalyticsEvent(analyticsObject);

    this.args.setSelected(this.valuesForFilter, this.operator);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.stagedValues = [];
    this.value = null;
    this.operator = null;
    this.args.removeFilter();
  }

  @action
  onOperatorChange() {
    if (!REGEX_FILTERS.includes(this.operator) && REGEX_FILTERS.includes(this.previousOperator)) {
      this.stagedValues = this.selectedValues;
    }

    this.previousOperator = this.operator;
  }
}
