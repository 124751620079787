/* RESPONSIBLE TEAM: team-data-foundations */
/* eslint-disable no-restricted-imports */
import {
  TRIGGERABLE_BOT_TYPE,
  WEBHOOK_RECEIVED_TARGET,
  WEBHOOK_RECEIVED_TRIGGER,
  DURING_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import WebhookReceivedEditorConfig from 'embercom/objects/visual-builder/configuration/editor/webhook-received';

import CustomBotConfig from '../custom-bot';
import type { BotConfigTarget } from '../configuration';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class WebhookReceivedCustomBotConfig extends CustomBotConfig {
  constructor(public customBot?: any) {
    super();
  }

  get target(): BotConfigTarget {
    return WEBHOOK_RECEIVED_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return WEBHOOK_RECEIVED_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.webhook-received.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.webhook-received.description';
  }

  get icon(): InterfaceIconName {
    return 'webhook';
  }

  get editorConfig(): WebhookReceivedEditorConfig {
    return new this.EditorConfigClass({
      areStepPaywallsActive: this.areStepPaywallsActive,
      inboundTrigger: this.selectedInboundTrigger,
    });
  }

  get EditorConfigClass() {
    return WebhookReceivedEditorConfig;
  }

  visibleToApp(app: any) {
    return app.canUseJiraInboundWorkflows as boolean;
  }

  get selectableCustomBotType() {
    return false;
  }

  get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get triggerContextType() {
    return DURING_CONVERSATION;
  }

  get selectedInboundTrigger() {
    let inboundTriggerUUID =
      this.customBot?.triggerPredicateGroup?.predicates?.firstObject?.predicates?.firstObject
        ?.value;
    return getEmberDataStore()
      .peekAll('workflow-connector/inbound-trigger')
      .findBy('uuid', inboundTriggerUUID);
  }

  get isTitleModifiedByWorkflowsSetup(): boolean {
    return false;
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION];
  }
}
