/* import __COLOCATED_TEMPLATE__ from './cocoapods.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {}
interface Signature {
  Args: Args;
  Element: any;
}

const Cocoapods = templateOnlyComponent<Signature>();
export default Cocoapods;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Ios::Steps::Cocoapods': typeof Cocoapods;
    'installation-new/mobile/initial-setup/ios/steps/cocoapods': typeof Cocoapods;
  }
}
