/* import __COLOCATED_TEMPLATE__ from './addresses-table.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type BrandService from 'embercom/services/brand-service';
import type { Domain } from 'embercom/services/domain-service';
import type EntriConnectService from 'embercom/services/entri-connect-service';
import type SenderEmailVerificationService from 'embercom/services/sender-email-verification-service';
import { type reasonData } from '../addresses/address-sidebar';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { type DomainEmailAddress } from 'embercom/services/domain-service';

interface Args {
  domains: Domain[];
  changeStep: (step: string | null, origin: string) => void;
  setEmail: (email: string) => Promise<void>;
  setDomainName: (domainName: string) => void;
  domain: Domain | null;
  email: string | null;
}

interface Signature {
  Args: Args;
}

export default class AddressesTable extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare brandService: BrandService;
  @service declare appService: $TSFixMe;
  @service declare entriConnectService: EntriConnectService;
  @service declare senderEmailVerificationService: SenderEmailVerificationService;
  @tracked domainCanBeAutoConfigured = false;
  @tracked reasonsEntityCantBeDeleted: reasonData[] = [];
  @tracked dependencyCheckStatus = false;

  place = 'addresses-table';

  @action
  updateDependencyCheckStatus(status: boolean) {
    this.dependencyCheckStatus = status;
  }

  @action
  async deleteEmailAddress(email: DomainEmailAddress): Promise<void> {
    this.trackEvent('clicked', 'delete_email_address');
    this.args.setEmail(email.email);
    return taskFor(this.deleteTask).perform(email);
  }

  @dropTask
  *deleteTask(email: DomainEmailAddress): TaskGenerator<void> {
    this.updateDependencyCheckStatus(false);
    try {
      let settings = email.senderEmailAddressSettings;

      if (settings) {
        yield settings.destroyRecord();
        this._pruneAddresses(settings);

        if (this.reasonsEntityCantBeDeleted.length === 0) {
          this._handleEmailDeletionSuccess();
          // refresh company email addresses
          // This is because the backend deletes the company email address if present
          yield this.appService.app.refreshCompanyEmailAddresses();
        }
      }
    } catch (error) {
      let response = error.jqXHR.responseJSON;
      if (response.statusCode === 428) {
        this.trackEvent('address_not_deletable', 'delete_email_address');
        this.reasonsEntityCantBeDeleted = response.reasons;
      } else {
        this.trackEvent('error', 'delete_email_address');
        console.error('Error deleting the record:', error);
        this.updateDependencyCheckStatus(true);
      }
      return;
    }
  }

  _handleEmailDeletionSuccess() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.channels.email.addresses.sidebar.deleted-email-successfully'),
    );
    this.trackEvent('success', 'delete_email_address');
    this.args.changeStep(null, this.place);
  }

  _pruneAddresses(settings: SenderEmailAddressSettings | undefined) {
    if (!settings) {
      return;
    }
    // seems to be a bug in our version of ember-data where we end up with both the
    // saved & unsaved records here, need to look at whether we can fix this with a local ID?
    // https://github.com/emberjs/data/issues/7354
    let unsaved = settings.senderEmailAddresses.filter((address: $TSFixMe) => address.isNew);
    settings.senderEmailAddresses.removeObjects(unsaved);
  }

  get showBrands() {
    return this.brandService.isAppOnRightMultibrandPlan;
  }

  get columns() {
    let columns = [
      {
        label: this.intl.t(
          'new-settings.channels.email.connect-email.addresses-table.columns.email',
        ),
        valuePath: 'email',
        width: '40%',
      },
    ];
    if (this.showBrands) {
      columns.push({
        label: this.intl.t('new-settings.channels.email.addresses.table.brand'),
        valuePath: 'brandName',
        width: '25%',
      });
    }
    columns.push({
      label: this.intl.t(
        'new-settings.channels.email.connect-email.addresses-table.columns.automatic-forwarding',
      ),
      valuePath: 'inboundStatus',
      width: '20%',
    });
    columns.push({
      label: this.intl.t(
        'new-settings.channels.email.connect-email.addresses-table.columns.outbound',
      ),
      valuePath: 'outboundStatus',
      width: '10%',
    });
    columns.push({ label: '', valuePath: 'edit', width: '5%' });
    columns.push({ label: '', valuePath: 'delete', width: '5%' });
    return columns;
  }

  get emptyRowData() {
    return [{ email: '', brandName: '', outboundStatus: '' }];
  }

  get hasNoVerifiedAddresses() {
    return this.args.domain?.addresses?.every((address) => !address.verified);
  }

  get canUseAutomaticStep() {
    return this.appService.app.canUseEntriConnect && this.domainCanBeAutoConfigured;
  }

  get dkimSettings() {
    return this.args.domain?.dkimSettings;
  }

  get customBounceSettings() {
    return this.args.domain?.bounceSettings;
  }

  hasNoSenderEmailAddresses(senderEmailAddresses: CustomEmailAddress[]) {
    return senderEmailAddresses?.length === 0;
  }

  @action
  async checkDomainAndLoadEntri(): Promise<void> {
    this.domainCanBeAutoConfigured = await taskFor(this.entriConnectService.checkDomain).perform(
      Number(this.dkimSettings?.id) ?? 0,
      Number(this.customBounceSettings?.id) ?? 0,
    );
    if (this.domainCanBeAutoConfigured) {
      taskFor(this.entriConnectService.loadEntriLibrary).perform();
    }
  }

  @action
  async navigateToSetupDomain(domainName: string) {
    this.args.setDomainName(domainName);
    if (this.args.domain) {
      if (this.hasNoVerifiedAddresses && !this.args.domain.dkimSettings?.hasVerifiedAdminEmails) {
        this.args.changeStep('access-setup-domain', this.place);
      } else {
        await this.checkDomainAndLoadEntri();
        if (this.canUseAutomaticStep) {
          this.args.changeStep('setup-domain', this.place);
        } else {
          this.args.changeStep('setup-domain-manually', this.place);
        }
      }
    }
  }

  @action
  async openSetupSidebar(emailAddress: string) {
    await this.args.setEmail(emailAddress);
    this.args.changeStep('edit-address', this.place);
  }

  @action
  async onVerifyEmailClick(emailAddressId: string) {
    await this.senderEmailVerificationService.resendVerificationEmail({
      senderEmailAddressSettingsId: emailAddressId,
      appId: this.appService.app.id,
      adminId: this.appService.app.currentAdmin.id,
    });
    this.trackEvent('clicked', 'send_verification_email');
  }

  @action
  async finishSetup(emailAddress: string) {
    this.args.changeStep('overview', this.place);
    this.args.setEmail(emailAddress);
    this.trackEvent('clicked', 'finish_setup');
  }

  @action
  trackEvent(action: string, object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: this.place,
      context: 'email-setup',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::AddressesTable': typeof AddressesTable;
  }
}
