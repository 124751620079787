/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import Validations from 'embercom/validations/custom-authentication/oauth-token-configuration';
import { tracked } from '@glimmer/tracking';

export default class OauthTokenConfiguration extends Fragment.extend(Validations) {
  @attr('string') declare url: string;
  @attr('string') declare clientId: string;
  @attr('string') declare clientSecret: string;
  @attr('string') declare scope: string;

  @tracked mostRecentTestPassed = false;
  @tracked declare latestTestRequestParams: OauthTokenConfiguration;

  get sufficientlyTested() {
    if (this.latestTestRequestParams === undefined) {
      return false;
    }

    if (!this.mostRecentTestPassed) {
      return false;
    }

    return this._testSinceLastEdit();
  }

  _testSinceLastEdit() {
    return this._configsMatch(this, this.latestTestRequestParams);
  }

  _configsMatch(currentConfig: OauthTokenConfiguration, testedConfig: OauthTokenConfiguration) {
    return (
      currentConfig.url === testedConfig.url &&
      currentConfig.clientId === testedConfig.clientId &&
      currentConfig.clientSecret === testedConfig.clientSecret &&
      currentConfig.scope === testedConfig.scope
    );
  }
}
