/* import __COLOCATED_TEMPLATE__ from './seat-requirement-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { getRequiredSeatTypesForPermission } from 'embercom/lib/seats-permissions-map';
import {
  VBP2_SEAT_LABELS,
  PRICING_5_X_SEAT_LABELS,
  ALL_SEAT_LABELS,
  type SeatType,
} from 'embercom/lib/settings/seats/constants';
import { inject as service } from '@ember/service';

interface Args {
  key: string;
  somePermissions?: boolean;
  noTooltipText?: boolean;
}

interface Signature {
  Args: Args;
  Blocks: any;
}

export default class SeatRequirementTooltip extends Component<Signature> {
  @service permissionsHierarchyService: $TSFixMe;
  @service appService: $TSFixMe;

  get requiredSeatLabels() {
    let requiredSeatTypes =
      getRequiredSeatTypesForPermission(
        this.args.key,
        this.permissionsHierarchyService.seatPermissionMap,
      ) || [];

    if (this.appService.app.onPricing5) {
      requiredSeatTypes = requiredSeatTypes.filter(
        (seatType: SeatType) => PRICING_5_X_SEAT_LABELS[seatType],
      );
    } else {
      requiredSeatTypes = requiredSeatTypes.filter(
        (seatType: SeatType) => VBP2_SEAT_LABELS[seatType],
      );
    }

    return requiredSeatTypes.map((seatType: SeatType) => ALL_SEAT_LABELS[seatType]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Permissions::SeatRequirementTooltip': typeof SeatRequirementTooltip;
  }
}
