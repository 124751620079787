/* import __COLOCATED_TEMPLATE__ from './collection.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default class Collection extends Component {
  backgroundUrls = {
    apps_by_intercom: assetUrl('/assets/images/appstore/collections/apps-by-intercom-vertical.png'),
    hottest_apps: assetUrl('/assets/images/appstore/collections/hottest-apps-vertical.png'),
    measure_nps: assetUrl('/assets/images/appstore/collections/measure-nps-vertical.png'),
    schedule_sales_calls: assetUrl(
      '/assets/images/appstore/collections/schedule-sales-calls-vertical.png',
    ),
    super_charge_data: assetUrl(
      '/assets/images/appstore/collections/super-charge-data-vertical.png',
    ),
    survey: assetUrl('/assets/images/appstore/collections/survey-vertical.png'),
  };
}
