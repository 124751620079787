/* import __COLOCATED_TEMPLATE__ from './grid-layout.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  intercomEventService: service(),
  appstoreService: service(),

  appPackages: null,
  searchString: null,
  shouldUseResponsiveGridItems: true,
  noSearchResultsDisplayString: computed('searchString', function () {
    if (this.searchString) {
      return `No results for "${this.searchString}"`;
    } else {
      return 'No results';
    }
  }),

  didInsertElement() {
    this._super(...arguments);
    if (this.searchString) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'used',
        object: 'search_bar',
        search_term: this.searchString,
        search_results: this.get('appPackages.length'),
      });
    }
  },
});
