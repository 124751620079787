/* import __COLOCATED_TEMPLATE__ from './available-languages-hint.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';

type SimpleLocale = { localeId: string; isPermitted: boolean; name: string };

export interface Args {
  languages: {
    supportedLocales: Array<SimpleLocale>;
    availableLocales: Array<SimpleLocale>;
    defaultLocale: string;
  };
}

export default class AvailableLanguagesHint extends Component<Args> {
  get languages() {
    return this.args.languages;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Content::AvailableLanguagesHint': typeof AvailableLanguagesHint;
    'messenger-settings/content/available-languages-hint': typeof AvailableLanguagesHint;
  }
}
