/* import __COLOCATED_TEMPLATE__ from './rating-reason-input.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface RatingReasonInputArgs {
  customerAnswerRatingNote: string;
  updateRatingNote: (note: string) => void;
  updateIsReasonInputFocused: (isFocused: boolean) => void;
}

type RatingReasonInputSignature = {
  Args: RatingReasonInputArgs;
  Element: HTMLDivElement;
};

export default class RatingReasonInput extends Component<RatingReasonInputSignature> {
  @action onInputKeydown(event: KeyboardEvent) {
    let target = event.target as HTMLTextAreaElement;

    if (event.key === 'Enter') {
      target.blur();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::RatingReasonInput': typeof RatingReasonInput;
  }
}
