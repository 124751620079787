/* import __COLOCATED_TEMPLATE__ from './ping-domains-table.hbs'; */
/* RESPONSIBLE TEAM: team-messenger (originally team-actions) */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type PingDomain from 'embercom/models/ping-domain';

interface Args {
  pingDomains: PingDomain[];
  openUserHashChecker: () => void;
}

interface Signature {
  Args: Args;
}

export default class PingDomainsTable extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  @tracked currentPingDomain: any;
  get sortedPingDomains() {
    return this.args.pingDomains.sortBy('hasError', 'domain');
  }

  get columns() {
    let columns = [
      { label: 'Domain', valuePath: 'domain' },
      { label: 'Status', valuePath: 'status' },
      { label: 'Last seen', valuePath: 'lastModified' },
      { label: 'Errors', valuePath: 'errors' },
    ];
    return columns;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::IdvSetup::PingDomainsTable': typeof PingDomainsTable;
    'installation-new/idv-setup/ping-domains-table': typeof PingDomainsTable;
  }
}
