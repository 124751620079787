/* import __COLOCATED_TEMPLATE__ from './assignment.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import {
  AssignableEntity,
  AssigningEntity,
  CombinedAssigneeType,
} from 'embercom/models/data/inbox/assignment-enums';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Assignment from 'embercom/objects/inbox/renderable/assignment';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import LinkWithText from 'embercom/helpers/link-with-text';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import { type WorkflowObject } from 'embercom/objects/inbox/interfaces/workflow';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import { captureException } from 'embercom/lib/sentry';
import type CustomBotSummary from 'embercom/objects/inbox/custom-bot-summary';
import type MessageSummary from 'embercom/objects/inbox/message-summary';
import type IntlService from 'embercom/services/intl';

const OPERATOR_NAME = 'Operator';

interface AssignmentPart extends RenderablePart {
  renderableData: Assignment;
}

interface Args {
  part: AssignmentPart;
  isTicket: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamEventPartsAssignment extends Component<Signature> {
  @service session!: Session;
  @service intl!: IntlService;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get assignedToIsSelf(): boolean {
    let assignedTeammate = this.args.part.renderableData.assigneeMap[AssignableEntity.Teammate];
    return (
      assignedTeammate?.entityType === AssignableEntity.Teammate &&
      assignedTeammate?.entity?.id === this.session.teammate.id
    );
  }

  get assignerIsSelf(): boolean {
    let creatingEntity = this.args.part.renderableData.creatingEntity;
    return (
      creatingEntity.type === AssigningEntity.Teammate &&
      creatingEntity.object?.id === this.session.teammate.id
    );
  }

  get isSelfAssigned(): boolean {
    return this.assignerIsSelf && this.assignedToIsSelf;
  }

  get assignerIsAssignee(): boolean {
    let assignedTeammate = this.args.part.renderableData.assigneeMap[AssignableEntity.Teammate];
    return (
      assignedTeammate?.entityType === AssignableEntity.Teammate &&
      assignedTeammate?.entity?.id === this.args.part.renderableData.creatingEntity.id
    );
  }

  get assignedFromIsSelf(): boolean {
    return this.args.part.renderableData.assignedFrom.some((assignedFrom) => {
      return (
        assignedFrom.entityType === AssignableEntity.Teammate &&
        assignedFrom.entity?.id === this.session.teammate.id
      );
    });
  }

  get assignmentTextData() {
    let assignment = this.args.part.renderableData;
    switch (assignment.assigningEnitiyType) {
      case AssigningEntity.Teammate:
        return this.textForTeammateAssigner;
      case AssigningEntity.AwayMode:
        return this.textForAwayMode;
      case AssigningEntity.DefaultAssignment:
        return this.textForDefaultAssignment;
      case AssigningEntity.Workflow:
        return this.textForWorkflowAssignment;
      case AssigningEntity.Bot:
        return this.textForBotAssignment;
      case AssigningEntity.AssignmentStrategy:
        return this.textForAssignmentStrategy;
      case AssigningEntity.BulkReassignment:
        return this.textForBulkReassignment;
      case AssigningEntity.CustomBotSnapshot:
        return this.textForCustomBotSnapshot;
      case AssigningEntity.LegacyMessage:
        return this.textForLegacyMessage;
      default: {
        return this.unsupportedCase();
      }
    }
  }

  get textForTeammateAssigner() {
    let assignment = this.args.part.renderableData;
    let part = this.args.isTicket
      ? this.intl.t('inbox.assignment.ticket')
      : this.intl.t('inbox.assignment.conversation');

    switch (assignment.combinedAssigneeType) {
      case CombinedAssigneeType.UnassignedTeammateAndTeam: {
        if (this.assignerIsSelf) {
          return {
            key: 'you-unassigned-the-teammate-and-team',
            data: {},
          };
        } else {
          return {
            key: 'something-unassigned-the-teammate-and-team',
            data: {
              unassigner: this.teammateAssignedByLink,
            },
          };
        }
      }
      case CombinedAssigneeType.AssignedTeammate: {
        if (this.isSelfAssigned) {
          return { key: 'you-assigned-this-part-to-yourself', data: { part } };
        } else if (this.assignedToIsSelf) {
          return {
            key: 'something-assigned-this-part-to-you',
            data: { assigner: this.teammateAssignedByLink, part },
          };
        } else if (this.assignerIsSelf) {
          return {
            key: 'you-assigned-this-part-to-something',
            data: { assignee: this.teammateAssignedToLink, part },
          };
        } else if (this.assignerIsAssignee) {
          return {
            key: 'something-assigned-this-part-to-themselves',
            data: { assigner: this.teammateAssignedToLink, part },
          };
        } else {
          return {
            key: 'something-assigned-this-part-to-something',
            data: {
              assigner: this.teammateAssignedByLink,
              assignee: this.teammateAssignedToLink,
              part,
            },
          };
        }
      }
      case CombinedAssigneeType.AssignedTeam: {
        if (this.assignerIsSelf) {
          return {
            key: 'you-assigned-this-part-to-something',
            data: {
              assignee: this.teamAssignedToLink,
              part,
            },
          };
        } else {
          return {
            key: 'something-assigned-this-part-to-something',
            data: {
              assigner: this.teammateAssignedByLink,
              assignee: this.teamAssignedToLink,
              part,
            },
          };
        }
      }
      case CombinedAssigneeType.AssignedTeammateAndTeam: {
        if (this.assignerIsSelf) {
          return {
            key: 'you-assigned-this-part-to-teammate-and-team',
            data: {
              assignee: this.teamAssignedToLink,
              teammateAssignee: this.teammateAssignedToLink,
              teamAssignee: this.teamAssignedToLink,
              part,
            },
          };
        } else {
          return {
            key: 'something-assigned-this-part-to-teammate-and-team',
            data: {
              assigner: this.teammateAssignedByLink,
              teammateAssignee: this.teammateAssignedToLink,
              teamAssignee: this.teamAssignedToLink,
              part,
            },
          };
        }
      }
      case CombinedAssigneeType.UnassignedTeam: {
        if (this.assignerIsSelf) {
          return {
            key: 'you-unassigned-the-team',
            data: {
              unassigner: this.teammateAssignedByLink,
            },
          };
        } else {
          return {
            key: 'something-unassigned-the-team',
            data: {
              unassigner: this.teammateAssignedByLink,
            },
          };
        }
      }
      case CombinedAssigneeType.UnassignedTeammate: {
        if (this.assignerIsSelf) {
          return { key: 'you-unassigned-the-teammate', data: {} };
        } else {
          return {
            key: 'something-unassigned-the-teammate',
            data: {
              unassigner: this.teammateAssignedByLink,
            },
          };
        }
      }
      case CombinedAssigneeType.UnassignedTeammateAndAssignedTeam: {
        if (this.assignerIsSelf) {
          return {
            key: 'you-unassigned-the-teammate-and-assigned-the-team',
            data: {
              teamAssignee: this.teamAssignedToLink,
              part,
            },
          };
        } else {
          return {
            key: 'something-unassigned-the-teammate-and-assigned-the-team',
            data: {
              assigner: this.teammateAssignedByLink,
              teamAssignee: this.teamAssignedToLink,
              part,
            },
          };
        }
      }
      case CombinedAssigneeType.UnassignedTeamAndAssignedTeammate: {
        if (this.assignerIsSelf) {
          return {
            key: 'you-unassigned-the-team-and-assigned-the-teammate',
            data: {
              teammateAssignee: this.teammateAssignedToLink,
              part,
            },
          };
        } else {
          return {
            key: 'something-unassigned-the-team-and-assigned-the-teammate',
            data: {
              assigner: this.teammateAssignedByLink,
              teammateAssignee: this.teammateAssignedToLink,
              part,
            },
          };
        }
      }
      default:
        return this.unsupportedCase();
    }
  }

  get textForAwayMode() {
    let assignment = this.args.part.renderableData;
    switch (assignment.combinedAssigneeType) {
      case CombinedAssigneeType.UnassignedTeammate:
      case CombinedAssigneeType.AssignedTeam:
      case CombinedAssigneeType.UnassignedTeammateAndAssignedTeam: {
        if (this.assignedFromIsSelf) {
          return { key: 'you-were-unassigned-due-to-your-away-mode-settings', data: {} };
        } else {
          return {
            key: 'someone-was-unassigned-due-to-their-away-mode-settings',
            data: {
              unassignee: this.unassignedAwayModeTeammateLink,
            },
          };
        }
      }
      default:
        return this.unsupportedCase();
    }
  }

  get textForDefaultAssignment() {
    let assignment = this.args.part.renderableData;

    switch (assignment.combinedAssigneeType) {
      case CombinedAssigneeType.UnassignedTeam:
      case CombinedAssigneeType.UnassignedTeammate:
      case CombinedAssigneeType.UnassignedTeammateAndTeam: {
        return { key: 'unassigned-with-default-assignment', data: {} };
      }
      case CombinedAssigneeType.AssignedBot: {
        return { key: 'assigned-operator-as-default-assignee', data: {} };
      }
      case CombinedAssigneeType.AssignedTeammate: {
        return {
          key: 'assigned-with-default-assignment',
          data: {
            assignee: this.teammateAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeammateAndAssignedTeam: {
        return {
          key: 'unassigned-the-teammate-and-assigned-the-team-with-default-assignment',
          data: {
            assignee: this.teamAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeamAndAssignedTeammate: {
        return {
          key: 'unassigned-the-team-and-assigned-the-teammate-with-default-assignment',
          data: {
            assignee: this.teammateAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.AssignedTeam: {
        return {
          key: 'assigned-with-default-assignment',
          data: {
            assignee: this.teamAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.AssignedTeammateAndTeam: {
        return {
          key: 'assigned-teammate-and-team-with-default-assignment',
          data: {
            teammateAssignee: this.teammateAssignedToLink,
            teamAssignee: this.teamAssignedToLink,
          },
        };
      }
      default:
        return this.unsupportedCase();
    }
  }

  get textForBulkReassignment() {
    let assignment = this.args.part.renderableData;

    switch (assignment.combinedAssigneeType) {
      case CombinedAssigneeType.UnassignedTeam:
      case CombinedAssigneeType.UnassignedTeammate:
      case CombinedAssigneeType.UnassignedTeammateAndTeam: {
        return { key: 'unassigned-with-bulk-reassignment', data: {} };
      }
      case CombinedAssigneeType.AssignedBot: {
        return { key: 'assigned-operator-with-bulk-reassignment', data: {} };
      }
      case CombinedAssigneeType.AssignedTeammate: {
        return {
          key: 'assigned-with-bulk-reassignment',
          data: {
            assignee: this.teammateAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeammateAndAssignedTeam: {
        return {
          key: 'unassigned-the-teammate-and-assigned-the-team-with-bulk-reassignment',
          data: {
            assignee: this.teamAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeamAndAssignedTeammate: {
        return {
          key: 'unassigned-the-team-and-assigned-the-teammate-with-bulk-reassignment',
          data: {
            assignee: this.teammateAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.AssignedTeam: {
        return {
          key: 'assigned-with-bulk-reassignment',
          data: {
            assignee: this.teamAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.AssignedTeammateAndTeam: {
        return {
          key: 'assigned-teammate-and-team-with-bulk-reassignment',
          data: {
            teammateAssignee: this.teammateAssignedToLink,
            teamAssignee: this.teamAssignedToLink,
          },
        };
      }
      default:
        return this.unsupportedCase();
    }
  }

  get textForWorkflowAssignment() {
    let assignment = this.args.part.renderableData;
    let workflow = assignment.creatingEntity.object as WorkflowObject;
    let part = this.args.isTicket ? 'ticket' : 'conversation';

    switch (assignment.combinedAssigneeType) {
      case CombinedAssigneeType.UnassignedTeammateAndTeam: {
        return {
          key: 'workflow-unassigned-teammate-team',
          data: {
            workflow: workflow.label,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeam: {
        return {
          key: 'workflow-unassigned-the-team',
          data: {
            workflow: workflow.label,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeammate: {
        return {
          key: 'workflow-unassigned-the-teammate',
          data: {
            workflow: workflow.label,
          },
        };
      }
      case CombinedAssigneeType.AssignedTeammate: {
        if (this.assignedToIsSelf) {
          return {
            key: 'workflow-assigned-this-part-to-you',
            data: { workflow: workflow.label, part },
          };
        } else {
          return {
            key: 'workflow-assigned-this-part-to-something',
            data: {
              workflow: workflow.label,
              assignee: this.teammateAssignedToLink,
              part,
            },
          };
        }
      }
      case CombinedAssigneeType.AssignedTeam: {
        return {
          key: 'workflow-assigned-this-part-to-something',
          data: {
            workflow: workflow.label,
            assignee: this.teamAssignedToLink,
            part,
          },
        };
      }
      case CombinedAssigneeType.AssignedTeammateAndTeam: {
        return {
          key: 'workflow-assigned-this-part-to-teammate-and-team',
          data: {
            workflow: workflow.label,
            teammateAssignee: this.teammateAssignedToLink,
            teamAssignee: this.teamAssignedToLink,
            part,
          },
        };
      }
      case CombinedAssigneeType.AssignedBot: {
        return {
          key: 'workflow-assigned-this-part-to-something',
          data: {
            workflow: workflow.label,
            assignee: OPERATOR_NAME,
            part,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeammateAndAssignedTeam: {
        return {
          key: 'workflow-unassigned-teammate-and-assigned-something',
          data: {
            workflow: workflow.label,
            assignee: this.teamAssignedToLink,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeamAndAssignedTeammate: {
        return {
          key: 'workflow-unassigned-team-and-assigned-something',
          data: {
            workflow: workflow.label,
            assignee: this.teammateAssignedToLink,
          },
        };
      }
      default:
        return this.unsupportedCase();
    }
  }

  get textForBotAssignment() {
    let assignment = this.args.part.renderableData;
    let part = this.args.isTicket ? 'ticket' : 'conversation';

    switch (assignment.combinedAssigneeType) {
      case CombinedAssigneeType.UnassignedTeammateAndTeam: {
        return {
          key: 'something-unassigned-the-teammate-and-team',
          data: {
            unassigner: this.teammateAssignedByLinkWithOperatorCallback,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeam: {
        return {
          key: 'something-unassigned-the-team',
          data: {
            unassigner: this.teammateAssignedByLinkWithOperatorCallback,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeammate: {
        return {
          key: 'something-unassigned-the-teammate',
          data: {
            unassigner: this.teammateAssignedByLinkWithOperatorCallback,
          },
        };
      }
      case CombinedAssigneeType.AssignedTeammate: {
        if (this.assignedToIsSelf) {
          return {
            key: 'something-assigned-this-part-to-you',
            data: { assigner: this.teammateAssignedByLinkWithOperatorCallback, part },
          };
        } else {
          return {
            key: 'something-assigned-this-part-to-something',
            data: {
              assigner: this.teammateAssignedByLinkWithOperatorCallback,
              assignee: this.teammateAssignedToLink,
              part,
            },
          };
        }
      }
      case CombinedAssigneeType.AssignedTeam: {
        return {
          key: 'something-assigned-this-part-to-something',
          data: {
            assigner: this.teammateAssignedByLinkWithOperatorCallback,
            assignee: this.teamAssignedToLink,
            part,
          },
        };
      }
      case CombinedAssigneeType.AssignedTeammateAndTeam: {
        return {
          key: 'something-assigned-this-part-to-teammate-and-team',
          data: {
            assigner: this.teammateAssignedByLinkWithOperatorCallback,
            teammateAssignee: this.teammateAssignedToLink,
            teamAssignee: this.teamAssignedToLink,
            part,
          },
        };
      }
      case CombinedAssigneeType.AssignedBot: {
        return {
          key: 'something-assigned-this-part-to-something',
          data: {
            assigner: this.teammateAssignedByLinkWithOperatorCallback,
            assignee: OPERATOR_NAME,
            part,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeammateAndAssignedTeam: {
        return {
          key: 'something-unassigned-the-teammate-and-assigned-the-team',
          data: {
            assigner: this.teammateAssignedByLinkWithOperatorCallback,
            teamAssignee: this.teamAssignedToLink,
            part,
          },
        };
      }
      case CombinedAssigneeType.UnassignedTeamAndAssignedTeammate: {
        return {
          key: 'something-unassigned-the-team-and-assigned-the-teammate',
          data: {
            assigner: this.teammateAssignedByLinkWithOperatorCallback,
            teammateAssignee: this.teammateAssignedToLink,
            part,
          },
        };
      }
      default:
        return this.unsupportedCase();
    }
  }

  get textForAssignmentStrategy() {
    let assignment = this.args.part.renderableData;
    let data = assignment.assigningEntityData;
    if (!data || !data.strategy) {
      return this.unsupportedCase();
    }

    switch (data.strategy.id.split(' ').shift()) {
      case 'round_robin':
        if (assignment.assigneeMap[AssignableEntity.Teammate]) {
          return {
            key: 'assigned-with-round-robin',
            data: { assignee: this.teammateAssignedToLink },
          };
        } else if (assignment.assigneeMap[AssignableEntity.Team]) {
          return {
            key: 'assigned-with-round-robin',
            data: { assignee: this.teamAssignedToLink },
          };
        } else {
          return this.unsupportedCase();
        }
      case 'balanced_assignment':
        if (assignment.assigneeMap[AssignableEntity.Teammate]) {
          return {
            key: 'assigned-with-balanced-assignment',
            data: { assignee: this.teammateAssignedToLink },
          };
        } else if (assignment.assigneeMap[AssignableEntity.Team]) {
          return {
            key: 'assigned-with-balanced-assignment',
            data: { assignee: this.teamAssignedToLink },
          };
        } else {
          return this.unsupportedCase();
        }
      default: {
        return this.unsupportedCase();
      }
    }
  }

  get textForCustomBotSnapshot() {
    let assignment = this.args.part.renderableData;

    if (assignment.combinedAssigneeType === CombinedAssigneeType.UnassignedTeammateAndTeam) {
      let summary = assignment.creatingEntity.object as CustomBotSummary;
      return {
        key: 'unassigned-the-teammate-and-the-team-as-assignee-of-the-custom-bot',
        data: { title: summary.title },
      };
    } else {
      return this.unsupportedCase();
    }
  }

  get textForLegacyMessage() {
    let assignment = this.args.part.renderableData;

    switch (assignment.combinedAssigneeType) {
      case CombinedAssigneeType.UnassignedTeammateAndTeam: {
        let summary = assignment.creatingEntity.object as MessageSummary;
        return {
          key: 'unassigned-the-teammate-and-the-team-as-assignee-of-the-message',
          data: { title: summary.title },
        };
      }
      default: {
        return this.unsupportedCase();
      }
    }
  }

  get teammateAssignedByLink() {
    let assignment = this.args.part.renderableData;
    let teammate = assignment.creatingEntity.object as AdminSummary;
    return this.linkWithTextHelper.compute(['apps.app.admins.admin', teammate.id, teammate.name]);
  }

  get teammateAssignedByLinkWithOperatorCallback() {
    let assignment = this.args.part.renderableData;
    let teammate = assignment.creatingEntity.object as AdminSummary;
    let customBotSummary = assignment.customBotSummary;
    if (customBotSummary) {
      return this.intl.t('inbox.assignment.custom-bot-title', {
        title: this.linkWithTextHelper.compute([
          'apps.app.operator.custom-bots.custom-bot.show',
          customBotSummary.id,
          customBotSummary.title,
        ]),
        htmlSafe: true,
      });
    } else if (teammate) {
      return this.linkWithTextHelper.compute(['apps.app.admins.admin', teammate.id, teammate.name]);
    } else {
      return OPERATOR_NAME;
    }
  }

  get teammateAssignedToLink() {
    let assignment = this.args.part.renderableData;
    let teammate = assignment.assigneeMap[AssignableEntity.Teammate].entity as AdminSummary;
    return this.linkWithTextHelper.compute(['apps.app.admins.admin', teammate.id, teammate.name]);
  }

  get teamAssignedToLink() {
    let assignment = this.args.part.renderableData;
    let team = assignment.assigneeMap[AssignableEntity.Team].entity as TeamSummary;
    return team.name;
  }

  get unassignedAwayModeTeammateLink() {
    let teammate = this.args.part.renderableData.assignedFrom.find(
      (assignedFrom) => assignedFrom.entityType === AssignableEntity.Teammate,
    )?.entity as AdminSummary;
    return this.linkWithTextHelper.compute(['apps.app.admins.admin', teammate.id, teammate.name]);
  }

  unsupportedCase() {
    let assignment = this.args.part.renderableData;
    let part = this.args.isTicket ? 'ticket' : 'conversation';

    console.error(
      `Unsupported assignment case. Assigner Type: ${assignment.assigningEnitiyType}. Combined Assignee Type: ${assignment.combinedAssigneeType}`,
    );
    captureException(new Error('Unsupported assignment case'), {
      extra: {
        assigningEnitiyType: assignment.assigningEnitiyType,
        combinedAssigneeType: assignment.combinedAssigneeType,
      },
    });

    switch (assignment.combinedAssigneeType) {
      case CombinedAssigneeType.UnassignedTeammateAndTeam: {
        try {
          return {
            key: 'not-implemented-case-unassigned-teammate-and-team',
            data: {
              teammate: this.teammateAssignedToLink,
              team: this.teamAssignedToLink,
            },
          };
        } catch (e) {
          return {
            key: 'not-implemented-anonymous-case-unassigned-teammate-and-team',
            data: {},
          };
        }
      }
      case CombinedAssigneeType.AssignedTeammate: {
        try {
          return {
            key: 'not-implemented-case-assigned-teammate',
            data: {
              teammate: this.teammateAssignedToLink,
              part,
            },
          };
        } catch (e) {
          return {
            key: 'not-implemented-anonymous-case-assigned-teammate',
            data: { part },
          };
        }
      }
      case CombinedAssigneeType.AssignedTeam: {
        try {
          return {
            key: 'not-implemented-case-assigned-team',
            data: {
              team: this.teamAssignedToLink,
              part,
            },
          };
        } catch (e) {
          return {
            key: 'not-implemented-anonymous-case-assigned-team',
            data: {},
          };
        }
      }
      case CombinedAssigneeType.AssignedTeammateAndTeam: {
        try {
          return {
            key: 'not-implemented-case-assigned-teammate-and-team',
            data: {
              teammate: this.teammateAssignedToLink,
              team: this.teamAssignedToLink,
              part,
            },
          };
        } catch (e) {
          return {
            key: 'not-implemented-anonymous-case-assigned-teammate-and-team',
            data: { part },
          };
        }
      }
      case CombinedAssigneeType.UnassignedTeam: {
        try {
          return {
            key: 'not-implemented-case-unassigned-team',
            data: {
              team: this.teamAssignedToLink,
            },
          };
        } catch (e) {
          return {
            key: 'not-implemented-anonymous-case-unassigned-team',
            data: {},
          };
        }
      }
      case CombinedAssigneeType.UnassignedTeammate: {
        try {
          return {
            key: 'not-implemented-case-unassigned-teammate',
            data: {
              teammate: this.teammateAssignedToLink,
            },
          };
        } catch (e) {
          return {
            key: 'not-implemented-anonymous-case-unassigned-teammate',
            data: {},
          };
        }
      }
      case CombinedAssigneeType.UnassignedTeammateAndAssignedTeam: {
        try {
          return {
            key: 'not-implemented-case-unassigned-teammate-assigned-team',
            data: {
              teammate: this.teammateAssignedToLink,
              team: this.teamAssignedToLink,
              part,
            },
          };
        } catch (e) {
          return {
            key: 'not-implemented-anonymous-case-unassigned-teammate-assigned-team',
            data: { part },
          };
        }
      }
      case CombinedAssigneeType.UnassignedTeamAndAssignedTeammate: {
        try {
          return {
            key: 'not-implemented-case-unassigned-team-assigned-teammate',
            data: {
              teammate: this.teammateAssignedToLink,
              team: this.teamAssignedToLink,
              part,
            },
          };
        } catch (e) {
          return {
            key: 'not-implemented-anonymous-case-unassigned-team-assigned-teammate',
            data: { part },
          };
        }
      }
      default:
        return { key: 'assignment-case-not-implemented', data: {} };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::Assignment': typeof ConversationStreamEventPartsAssignment;
    'inbox2/conversation-stream/event-parts/assignment': typeof ConversationStreamEventPartsAssignment;
  }
}
