/* RESPONSIBLE TEAM: team-actions */
import Model, { attr } from '@ember-data/model';
import Admin from 'embercom/models/admin';
import TokenValidations from 'embercom/validations/custom-authentication/token';
import { fragment } from 'ember-data-model-fragments/attributes';

export default class Token extends Model.extend(TokenValidations) {
  @attr('string') name;
  @attr('string') description;
  @attr('string') tokenValue;
  @attr('string') tokenPrefix;
  @attr('string', { defaultValue: 'text' }) tokenType;
  @attr('string') requestHeaderKey;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('number') createdByAdminId;
  @attr('number') updatedByAdminId;
  @fragment('custom-authentication/refresh-token-configuration') refreshTokenConfiguration;
  @fragment('custom-authentication/oauth-token-configuration') oauthTokenConfiguration;

  get createdByAdmin() {
    return Admin.peekAndMaybeLoad(this.store, this.createdByAdminId);
  }

  get updatedByAdmin() {
    return Admin.peekAndMaybeLoad(this.store, this.updatedByAdminId);
  }

  get isValidForCreate() {
    return this.validations.attrs.tokenValue.isValid && this._commonAttrsAreValid();
  }

  get isValidForUpdate() {
    return this._commonAttrsAreValid();
  }

  _commonAttrsAreValid() {
    let isValid = this.hasDirtyAttributes && this.validations.attrs.name.isValid;

    if (this.tokenType === 'text') {
      isValid = isValid && this.validations.attrs.requestHeaderKey.isValid;
    } else if (this.tokenType === 'http_request') {
      isValid =
        isValid &&
        this.validations.attrs.requestHeaderKey.isValid &&
        this.refreshTokenConfiguration.validations.isValid;
    } else if (this.tokenType === 'oauth') {
      isValid = isValid && this.oauthTokenConfiguration.validations.isValid;
    }
    return isValid;
  }
}
