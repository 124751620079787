/* import __COLOCATED_TEMPLATE__ from './conversation-parts-quick-action-item.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';
import BaseConversationPartsQuickActionItem from 'embercom/components/integrations/base/conversation-parts-quick-action-item';

export default BaseConversationPartsQuickActionItem.extend({
  notificationsService: service(),
  intercomEventService: service(),
  classes: ['test__create-github-issue-dropdown-item'].join(' '),

  icon: 'github',
  message: 'New GitHub issue',

  createGithubIssue: task(function* () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'github_issue',
      place: 'inbox',
    });

    yield ajax({
      url: `/ember/github_integrations/create_issue?app_id=${this.get('app.id')}`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify(this.body),
    })
      .then((res) => {
        let githubPageWindow = safeWindowOpen(res.issue_url, '_blank');

        if (!githubPageWindow) {
          // We failed to open the window. Likely an issue with popup blocking settings
          this.notificationsService.notifyError(
            'Looks like popups are blocked! Please enable popups in your browser settings.',
            6000,
          );
        }
      })
      .catch(() => {
        this.notificationsService.notifyError(`Could not create github issue`);
      });
  }),

  actions: {
    createGithubIssue() {
      this.createGithubIssue.perform();
    },
  },
});
