/* RESPONSIBLE TEAM: team-frontend-tech */
// Note: Don't forget to update the screen size mixins in app/styles/_mixins.scss
// if you change these dimensions
export default {
  mobile: '(max-width: 480px)',
  tablet: '(min-width: 768px) and (max-width: 1024px)',
  desktop: '(min-width: 1025px)',
  veryNarrowDesktop: '(min-width: 961px) and (max-width: 1152px)',
  narrowDesktop: '(min-width: 1153px) and (max-width: 1440px)',
  largeDesktop: '(min-width: 1441px)',
  leftNavSmall: '(max-height: 590px)',
  leftNavMedium: '(max-height: 638px)',
  leftNavLarge: '(max-height: 686px)',
  drawerEditorFullScreen: '(max-width: 1350px)', // Note: update .../_drawer-editor.scss if you change this
};
