/* import __COLOCATED_TEMPLATE__ from './delete-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';

export default class DeleteButton extends Component {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare permissionsService: any;
  @tracked showDeleteConfirmModal = false;

  @action async showDeleteConfirmationModal() {
    let activeContent = this.knowledgeHubEditorService.activeContentModel;

    try {
      await this.permissionsService.checkPermission(
        activeContent.requiredDeletePermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }
    this.showDeleteConfirmModal = true;
  }

  @dropTask
  *deleteActiveContent(): TaskGenerator<void> {
    this.showDeleteConfirmModal = false;
    yield this.knowledgeHubEditorService.deleteActiveContent();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Header::DeleteButton': typeof DeleteButton;
    'knowledge-hub/content-editor/shared/header/delete-button': typeof DeleteButton;
  }
}
