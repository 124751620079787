/* import __COLOCATED_TEMPLATE__ from './for-leads.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type TaskBots from 'embercom/models/operator-settings/task-bots';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  settings: TaskBots;
  updateDelayEnabled: () => void;
  openSectionId: string | null;
  openSectionChange: (sectionId: string) => void;
  upfrontEmailCollection: any;
}

export default class ForLeads extends Component<Args> {
  @service declare appService: any;

  @tracked currentlyOpenSection = 'open-messenger';

  get app(): any {
    return this.appService.app;
  }

  get hasBotWorkflows(): boolean {
    return this.app?.canUseFeature('bot_workflows');
  }

  @action
  toggleUpfrontEmailCollection() {
    if (this.args.upfrontEmailCollection.frequency === 'never') {
      this.args.upfrontEmailCollection.frequency = 'always';
      return;
    }

    this.args.upfrontEmailCollection.frequency = 'never';
  }

  @action
  setCurrentlyOpenSection(sectionId: string) {
    this.currentlyOpenSection = sectionId;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::SimpleAutomations::ForLeads': typeof ForLeads;
  }
}
