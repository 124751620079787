/* import __COLOCATED_TEMPLATE__ from './edit-display-names.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { type SyncHasMany } from '@ember-data/model';
import templateOnlyComponent from '@ember/component/template-only';
import type CustomEmailAddress from 'embercom/models/custom-email-address';

interface Signature {
  Args: {
    senderEmailAddresses: SyncHasMany<CustomEmailAddress> | never[];
    removeName: (index: number) => void;
    updateAndSaveName: (index: number) => void;
    addNewName: () => void;
  };
}

const EditDisplayNames = templateOnlyComponent<Signature>();
export default EditDisplayNames;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::EditDisplayNames': typeof EditDisplayNames;
    'new-settings/channels/email/domains-and-addresses/edit-display-names': typeof EditDisplayNames;
  }
}
