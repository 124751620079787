/* import __COLOCATED_TEMPLATE__ from './fin.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import type ContentImportService from 'embercom/services/content-import-service';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { SideSheetKeys } from 'embercom/objects/workflows/graph-editor/editor-state';
import Fin from 'embercom/models/operator/visual-builder/step/fin';
import type AiAgentQuickReplies from 'embercom/models/operator-settings/ai-agent-quick-replies';
import { type ComponentLike } from '@glint/template';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import { QUICK_REPLY_ID } from 'embercom/lib/operator/resolution-bot/constants';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import type FinTrialService from 'embercom/services/fin-trial-service';
import { AiAnswersState } from 'embercom/lib/operator/fin/types';
import type Router from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { action } from '@ember/object';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type AiAgentQuickRepliesService from 'embercom/services/ai-agent-quick-replies-service';
import type FinStepConfig from 'embercom/objects/visual-builder/configuration/step/fin';

interface Arguments {
  step: Fin;
  readOnly: boolean;
  editorState: EditorState;
  stepConfig?: FinStepConfig;
  connectionPointComponent: ComponentLike<{ connectionPoint: ConnectionPoint }>;
}

export default class FinNodeItem extends Component<Arguments> {
  @service declare appService: {
    app: {
      id: string;
      usesResolutionsWithCustomAnswers: boolean;
      hasAnswerBot: boolean;
      aiAnswersState: AiAnswersState;
    };
  };
  @service declare contentImportService: ContentImportService;
  @service declare intl: IntlService;
  @service declare AiAgentQuickRepliesService: AiAgentQuickRepliesService;
  @service declare store: Store;
  @service declare usageLimitsService: any;
  @service declare finTrialService: FinTrialService;
  @service declare router: Router;
  @service declare aiAgentSetupService: AiAgentSetupService;

  @tracked customAnswerCount = 0;
  @tracked aiAgentQuickReplies?: AiAgentQuickReplies;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this.intl.maybeLoadMessengerTranslations();

    this._setAiContentSummary();
    taskFor(this.loadAnswerCount).perform();
    taskFor(this.loadAiAgentQuickReplies).perform();
    taskFor(this.loadFinUsageLimit).perform();
  }

  get sideSheetKeys() {
    return SideSheetKeys;
  }

  get canUseAiAnswers() {
    return Fin.canUseAiAnswers(this.appService.app);
  }

  get canUseCustomAnswers(): boolean {
    return this.appService.app.hasAnswerBot;
  }

  get finDisabledAfterTrial() {
    return (
      !this.args.step.useAiAnswers &&
      this.finTrialService.aiAnswersState === AiAnswersState.trialExpired &&
      !this.canUseCustomAnswers
    );
  }

  get showStepInvalidState() {
    return (
      this.args.step.validations.attrs.hasAiContentReadyForFin.isInvalid ||
      this.args.step.validations.attrs.inactivityTimer.isInvalid ||
      this.args.step.validations.attrs.supportedContexts.isInvalid ||
      this.args.step.validations.attrs.emailSetupValid.isInvalid ||
      this.args.step.validations.attrs.hasConsentToExternalAiDataProcessing.isInvalid
    );
  }

  get showAiBorder() {
    return this.args.step.useAiAnswers && !this.showStepInvalidState;
  }

  get isOneTimeMode() {
    return this.args.step.botMode === 'one_time';
  }

  get triageBranch() {
    return this.args.step.outwardConnectionPoints.find((cp) => cp.type === 'fin_triage')!;
  }

  get triageButtonLabel() {
    if (this.args.step.botMode === 'bot_only') {
      return this.AiAgentQuickRepliesService.getLabel(
        QUICK_REPLY_ID.GET_MORE_HELP,
        null,
        this.intl.locale,
      );
    } else {
      let labelKey = this.aiAgentQuickReplies?.waitForTheTeamLabel || 'talk_to_person';
      return this.AiAgentQuickRepliesService.getLabel(
        QUICK_REPLY_ID.WAIT_FOR_THE_TEAM,
        labelKey,
        this.intl.locale,
      );
    }
  }

  get thatHelpedButtonLabel() {
    let labelKey = this.aiAgentQuickReplies?.allDoneLabel || 'all_done';
    return this.AiAgentQuickRepliesService.getLabel(
      QUICK_REPLY_ID.ALL_DONE,
      labelKey,
      this.intl.locale,
    );
  }

  get buttonsLoaded(): boolean {
    return taskFor(this.loadAiAgentQuickReplies).isIdle;
  }

  get isSafari() {
    return UserAgentDetector.isSafari();
  }

  get usageLimitBreached() {
    return this.usageLimitsService.finHardLimitBreached;
  }

  get emailSetupValidationResponse() {
    return this.aiAgentSetupService.validateEmailSetup;
  }

  get hasSideSheet() {
    return true;
  }

  @task({ restartable: true })
  *loadAnswerCount(): TaskGenerator<void> {
    let customAnswers = yield this.store.findAll('custom-answers/custom-answer', { reload: true });
    this.customAnswerCount = customAnswers.filter((answer: any) => answer.status === 'live').length;
    this.args.step.customAnswerCount = this.customAnswerCount;
  }

  @task({ restartable: true })
  *loadAiAgentQuickReplies(): TaskGenerator<void> {
    this.aiAgentQuickReplies = yield this.store.findRecord(
      'operator-settings/ai-agent-quick-replies',
      this.appService.app.id,
    );
  }

  @task({ restartable: true })
  *loadFinUsageLimit(): TaskGenerator<void> {
    yield this.usageLimitsService.fetchFinUsageLimits();
  }

  @action
  openLinkInNewTab(urlName: string, event: Event) {
    event.stopPropagation();
    let url = this.router.urlFor(urlName);
    safeWindowOpen(url, '_blank');
  }

  async _setAiContentSummary() {
    if (!Object.values(this.contentImportService.aiContentLibrarySummary).length) {
      await this.contentImportService.fetchAiContentLibrarySummary();
    }
    this.args.step.aiContentLibrarySummary = this.contentImportService.aiContentLibrarySummary;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin': typeof FinNodeItem;
  }
}
