/* import __COLOCATED_TEMPLATE__ from './temporary-attribute-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import generateUUID from 'embercom/lib/uuid-generator';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { Type } from 'embercom/models/operator/visual-builder/attribute-descriptor';

interface Args {
  attributeDescriptor: AttributeDescriptor;
  onModalClose: () => void;
  onUpdateAttribute: (attributeDescriptor: AttributeDescriptor) => void;
  deleteAttribute: (attributeDescriptor: AttributeDescriptor) => void;
  mode: 'edit' | 'create';
}

const LIST_OPTIONS_LIMIT = 250;
const LIST_OPTIONS_INCREASED_LIMIT = 500;
const MIN_LIST_OPTION_DESCRIPTION_LENGTH = 10;
const MAX_LIST_OPTION_DESCRIPTION_LENGTH = 1000;
const NAME_MAX_LENGTH = 191;
const DESCRIPTION_MAX_LENGTH = 255;

export default class TemporaryAttributeModal extends Component<Args> {
  @service appService: any;
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked currentAttribute: AttributeDescriptor = this.args.attributeDescriptor;
  @tracked name: string = this.currentAttribute.name;
  @tracked description: string = this.currentAttribute.description;
  @tracked dataType: string = this.currentAttribute.type;
  @tracked listOptions: any = this.currentAttribute.listOptions;

  get availableTypes() {
    let types = [
      {
        text: this.intl.t('workflows.graph-editor.temporary-attribute-modal.types.text'),
        value: Type.string,
      },
      {
        text: this.intl.t('workflows.graph-editor.temporary-attribute-modal.types.list'),
        value: Type.workflow_attribute_descriptor_list,
      },
      {
        text: this.intl.t('workflows.graph-editor.temporary-attribute-modal.types.number'),
        value: Type.integer,
      },
      {
        text: this.intl.t('workflows.graph-editor.temporary-attribute-modal.types.decimal'),
        value: Type.float,
      },
      {
        text: this.intl.t('workflows.graph-editor.temporary-attribute-modal.types.boolean'),
        value: Type.boolean,
      },
      {
        text: this.intl.t('workflows.graph-editor.temporary-attribute-modal.types.date-time'),
        value: Type.date,
      },
    ];

    if (this.dataType === Type.array) {
      types.pushObject({
        text: this.intl.t('workflows.graph-editor.temporary-attribute-modal.types.array'),
        value: Type.array,
      });
    }

    if (this.dataType === Type.object) {
      types.pushObject({
        text: this.intl.t('workflows.graph-editor.temporary-attribute-modal.types.object'),
        value: Type.object,
      });
    }

    return types;
  }

  get isEditMode() {
    return this.args.mode === 'edit';
  }

  get isStringType() {
    return this.dataType === Type.string;
  }

  get isListType() {
    return this.dataType === Type.workflow_attribute_descriptor_list;
  }

  get optionsLimit() {
    return this.appService.app.canUseIncreasedListOptionsLimit
      ? LIST_OPTIONS_INCREASED_LIMIT
      : LIST_OPTIONS_LIMIT;
  }

  get optionLimitReached() {
    return this.listOptions.length >= this.optionsLimit;
  }

  createListOptionObject(label = '') {
    return this.store.createRecord('operator/visual-builder/attribute-descriptor-list-option', {
      id: generateUUID(),
      label,
      descriptor: this.currentAttribute,
    });
  }

  get attributeLimitWarning() {
    return this.intl.t('workflows.graph-editor.temporary-attribute-modal.attribute-limit-warning', {
      limit: this.optionsLimit,
    });
  }

  get listOptionColumns() {
    return [
      {
        label: this.intl.t(
          'workflows.graph-editor.temporary-attribute-modal.list-option-name-column',
        ),
        valuePath: 'label',
      },
      { label: '', valuePath: 'actionButtons', width: 0 },
    ];
  }

  get canDeleteAttribute() {
    return this.args.deleteAttribute !== undefined && !this.currentAttribute.sourceObject;
  }

  @action
  addListOption() {
    let newListOption = this.createListOptionObject();
    this.listOptions.pushObject(newListOption);
  }

  @action
  removeListOption(index: any) {
    if (this.listOptions.length > 2) {
      let option = this.listOptions.objectAt(index);
      this.listOptions.removeAt(index);
      this.isEditMode ? option.set('archived', true) : option.deleteRecord();
    }
  }

  @action
  updateType(type: string) {
    this.dataType = type;

    if (this.isListType) {
      if (this.listOptions.length === 0) {
        this.addListOption();
        this.addListOption();
      }
    }
  }

  validateListOptions() {
    if (!this.isListType) {
      return true;
    }

    if (this.listOptions.any((option: any) => isEmpty(option.label))) {
      this.notificationsService.notifyError(
        this.intl.t(
          'workflows.graph-editor.temporary-attribute-modal.errors.all-options-must-have-values',
        ),
      );
      return false;
    }

    if (
      this.listOptions.any(
        (option: any) =>
          isPresent(option.description) &&
          (option.description.length < MIN_LIST_OPTION_DESCRIPTION_LENGTH ||
            option.description.length > MAX_LIST_OPTION_DESCRIPTION_LENGTH),
      )
    ) {
      this.notificationsService.notifyError(
        this.intl.t(
          'workflows.graph-editor.temporary-attribute-modal.errors.descriptions-between-range',
        ),
      );
      return false;
    }

    return true;
  }

  validateName() {
    if (!isPresent(this.name)) {
      this.notificationsService.notifyError(
        this.intl.t('workflows.graph-editor.temporary-attribute-modal.errors.name-missing'),
      );
      return false;
    }

    return true;
  }

  validateType() {
    if (!isPresent(this.dataType)) {
      this.notificationsService.notifyError(
        this.intl.t('workflows.graph-editor.temporary-attribute-modal.errors.type-missing'),
      );
      return false;
    }

    return true;
  }

  validateNameMaxLength() {
    if (!isPresent(this.name)) {
      return true;
    }

    return this.name.length <= NAME_MAX_LENGTH;
  }

  validateDescriptionMaxLength() {
    if (!isPresent(this.description)) {
      return true;
    }

    return this.description.length <= DESCRIPTION_MAX_LENGTH;
  }

  @action
  reorderOptions(reordered: any) {
    this.listOptions = reordered;
  }

  @action
  updateAttribute() {
    if (
      !this.validateListOptions() ||
      !this.validateName() ||
      !this.validateType() ||
      !this.validateNameMaxLength() ||
      !this.validateDescriptionMaxLength()
    ) {
      return;
    }

    this.currentAttribute.set('type', this.dataType);
    this.currentAttribute.set('name', this.name);
    this.currentAttribute.set('description', this.description);
    if (!this.isListType) {
      if (this.listOptions) {
        this.listOptions.toArray().forEach((option: any) => option.deleteRecord());
      }
    } else {
      this.currentAttribute.set('listOptions', this.listOptions);
    }

    this.args.onUpdateAttribute(this.currentAttribute);
    this.args.onModalClose();
  }

  @action closeModal() {
    if (!this.isEditMode) {
      this.args.deleteAttribute(this.currentAttribute);
    }
    this.args.onModalClose();
  }

  @action deleteAttributeAndCloseModal() {
    this.args.deleteAttribute(this.currentAttribute);
    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TemporaryAttributeModal': typeof TemporaryAttributeModal;
    'workflows/graph-editor/temporary-attribute-modal': typeof TemporaryAttributeModal;
  }
}
