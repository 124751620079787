/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { ReactRoute } from 'embercom/lib/react-route';
import { inject as service } from '@ember/service';

class BillingCurrentBillingPeriodChargesRoute extends Route {
  @service declare appService: $TSFixMe;

  reactEnabled() {
    return this.appService.app.canUseBillingSummaryRedesign;
  }
}

export default ReactRoute(BillingCurrentBillingPeriodChargesRoute);
