/* import __COLOCATED_TEMPLATE__ from './app-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  store: service(),
  appstoreService: service(),
  appService: service(),
  tagName: '',
  app: readOnly('appService.app'),
  isOpen: false,
  initiallyOpened: false,
  showAppStoreModal: false,
  initializeType: 'card',
  messengerApps: [],
  shouldInitializeCanvas: true,
  selectedApp: null,
  initiallySelectedApp: null,
  allowEditing: false,
  backButtonText: 'Back to apps',

  init() {
    this._super(...arguments);
    if (this.appstoreService.hasAppPackageCode()) {
      this.set('showAppStoreModal', true);
    }
    if (this.showAppStoreModal || this.openInserter) {
      this.openInitially();
    }
    this.fetchMessengerApps.perform();
  },

  openInitially() {
    this.setProperties({
      initiallyOpened: true,
      isOpen: true,
    });
  },

  fetchMessengerApps: task(function* () {
    if (this.get('messengerApps.length')) {
      return;
    }
    let messengerApps = yield this.store.query('messenger-app', {
      restrict_to: this.restrictTo,
    });
    this.set('messengerApps', messengerApps);
    if (this.allowEditing) {
      let messengerAppId = this.messengerAppId;
      let messengerAppToEdit = messengerApps.find((app) => app.id === messengerAppId.toString());
      this.setProperties({
        selectedApp: messengerAppToEdit,
        backButtonText: 'Cancel',
        contextualData: { location: 'home-edit' },
      });
    }
  }),

  onSelectedAppUpdate: action(function () {
    this.set('initiallySelectedApp', this.allowEditing ? this.selectedApp : null);
  }),

  openerId: computed('id', 'callerId', function () {
    let id = this.id;
    if (this.callerId) {
      return `messenger-app-opener-${this.callerId}`;
    }
    if (id >= 0 || id) {
      return `messenger-app-opener-${id}`;
    }
    if (this.allowEditing) {
      let slotId = this.slotId;
      return `messenger-app-opener-${slotId}`;
    }

    return 'messenger-app-opener';
  }),

  onPopoverOpenAction: action(function () {
    this.set('isOpen', true);
    this.appstoreService.dismissAppInstallNotifications.perform(this.restrictTo);
    if (this.onPopoverOpen) {
      this.onPopoverOpen();
    }
  }),

  onPopoverCloseAction: action(function () {
    this.set('showAppStoreModal', false);
    this.set('isOpen', false);
    if (this.onPopoverClose) {
      this.onPopoverClose();
    }
  }),
});
