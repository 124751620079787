/* import __COLOCATED_TEMPLATE__ from './two-fa-authentication-config.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import ajax from 'embercom/lib/ajax';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Admin from 'embercom/models/admin';

interface Args {}

interface Signature {
  Args: Args;
  Element: never;
}

export default class TwoFaAuthenticationConfig extends Component<Signature> {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare intercomConfirmService: $TSFixMe;

  @tracked isTwoFaConfigDrawerOpen = false;
  @tracked validationCode = '';

  constructor(owner: $TSFixMe, args: Args) {
    super(owner, args);
  }

  get admin() {
    return this.appService.app.currentAdmin as Admin;
  }

  get isTwoFaEnabled() {
    return this.admin.has_enabled_2fa;
  }

  get hasSixDigitValidationCode() {
    return this.validationCode?.length === 6;
  }

  get isAuthEnableTaskRunning() {
    return taskFor(this.enable2FAuth).isRunning;
  }

  get isSideDrawerSaveBtnDisabled() {
    return !this.hasSixDigitValidationCode || this.isAuthEnableTaskRunning;
  }

  @action
  learnDropdownSelectItem(item: { label: string; text: string }) {
    if (item.label === 'google-auth') {
      safeWindowOpen('https://support.google.com/accounts/answer/1066447?hl=en');
    }
  }

  private toggleConfigDrawer(isOpen: boolean) {
    this.isTwoFaConfigDrawerOpen = isOpen;
    if (isOpen) {
      this.admin.getTwoFactorQRCodeSVG();
    }
  }

  @action
  toggle2FAConfiguration() {
    if (this.admin.has_enabled_2fa) {
      taskFor(this.disable2FA).perform();
    } else {
      this.toggleConfigDrawer(true);
    }
  }

  @action
  toggle2FAConfigDrawer(isOpen: boolean) {
    this.toggleConfigDrawer(isOpen);
  }

  @dropTask
  *enable2FAuth() {
    if (this.hasSixDigitValidationCode) {
      try {
        yield ajax({
          type: 'PUT',
          url: '/ember/admins/enable_two_factor_auth',
          data: JSON.stringify({
            id: this.admin.id,
            app_id: this.admin.current_app_id,
            validation_code: this.validationCode,
          }),
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('apps.app.account.two-factor-auth.verification-enabled'),
        );
        this.admin.set('has_enabled_2fa', true);
        this.validationCode = '';
        this.toggleConfigDrawer(false);
      } catch (err) {
        this._handleErrorResponse(err);
        this.validationCode = '';
      }
    } else if (this.validationCode && !this.hasSixDigitValidationCode) {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.account.two-factor-auth.six-digit-length'),
      );
    } else {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.account.two-factor-auth.code-empty'),
      );
    }
  }

  @dropTask
  *disable2FA() {
    let confirmOptions = {
      title: this.intl.t('account.general.disable-2fa'),
      body: this.intl.t('account.general.are-you-sure-disable-2fa'),
      confirmButtonText: this.intl.t('account.general.disable-2fa'),
    };
    let isConfirmed: boolean = yield this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }
    try {
      yield ajax({
        type: 'PUT',
        url: '/ember/admins/disable_two_factor_auth',
        data: JSON.stringify({
          id: this.admin.id,
          app_id: this.admin.current_app_id,
        }),
      });
      this.notificationsService.notifyConfirmation(this.intl.t('account.general.2fa-was-disabled'));
      this.admin.set('has_enabled_2fa', false);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('account.general.cannot-update-2fa-try-again'),
      );
    }
  }

  _handleErrorResponse(error: $TSFixMe) {
    if (error.jqXHR.status === 403) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.two-factor-auth.could-not-validate'),
      );
    } else {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.two-factor-auth.try-again'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Personal::Preferences::TwoFaAuthenticationConfig': typeof TwoFaAuthenticationConfig;
    'new-settings/personal/preferences/two-fa-authentication-config': typeof TwoFaAuthenticationConfig;
  }
}
