/* RESPONSIBLE TEAM: team-workflows */
import { Type } from 'embercom/models/operator/visual-builder/attribute-descriptor';

export function iconByType(type: any) {
  switch (type as Type) {
    case Type.string:
      return 'recipient-data';
    case Type.integer:
      return 'count';
    case Type.float:
      return 'decimal';
    case Type.boolean:
      return 'boolean';
    case Type.date:
      return 'calendar';
    case Type.workflow_attribute_descriptor_list:
      return 'list';
    default:
      return 'insert-data';
  }
}

export function isJsonValid(json: string) {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
}
