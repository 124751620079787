/* import __COLOCATED_TEMPLATE__ from './schedules.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { taskFor } from 'ember-concurrency-ts';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';

interface Args {}

export default class StandaloneZendeskSchedules extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.finStandaloneService.loadZendeskSchedules).perform();
  }

  get isLoadingZendeskSchedules() {
    return this.finStandaloneService.isLoadingZendeskSchedules;
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig;
  }

  get zendeskSchedules(): Array<OfficeHoursSchedule> {
    return this.finStandaloneService.zendeskSchedules ?? [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Schedules': typeof StandaloneZendeskSchedules;
    'standalone/Zendesk/Schedules': typeof StandaloneZendeskSchedules;
  }
}
