/* import __COLOCATED_TEMPLATE__ from './connected-accounts.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnly from '@ember/component/template-only';

interface Signature {
  Args: {
    connectedAccounts: any[];
    isFirefox: boolean;
    removePage: (account: any) => void;
    addFacebookAccount: () => void;
    confirmFacebookAccount: (account: any) => void;
    isLoading: boolean;
    isRemovingPage: boolean;
    reAuthFacebookAccount: () => void;
    showWarning: boolean;
  };
}

const FacebookConnectedAccounts = templateOnly<Signature>();

export default FacebookConnectedAccounts;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Facebook::ConnectedAccounts': typeof FacebookConnectedAccounts;
  }
}
