/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Invokable } from '@glint/template/-private/integration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
export interface SourcesSectionArgs {
  iconName: InterfaceIconName;
  heading: string;
  subheading: string;
  rows: SourceRow[];
  maxRows?: number;
  sectionId: string;
  additionalAnalyticsMetadata?: Record<string, any>;
  showMoreTranslationKey?: string;
  sectionButtonLabel?: string;
  onSectionButtonClick?: () => void;
  stampText?: string;
  sectionDisabled?: boolean;
}

export type SourceRow = {
  icon?: InterfaceIconName;
  iconUrl?: string;
  title: string;
  onTitleClick?: () => void;
  status: string;
  statusTooltip?: string;
  statusIcon?: InterfaceIconName;
  actionButtonLabel?: string;
  onActionButtonClick?: () => void;
  actionOptions?: SourceRowActionsGroupList;
  folderId?: number;
  paywallFeatureKey?: string;
  onAddSyncSource?: (sourceType: string) => void;
  syncSource?: SyncSourceWrapper;
  hasOptions?: boolean;
};

export type SourceRowActionsGroupList = Array<{ heading?: string; items: SourceRowActionOption[] }>;

export type SourceRowActionOption = {
  text: string;
  description?: string;
  icon: InterfaceIconName;
  onSelectItem: () => void;
  isDestructive: boolean;
  component?: Invokable<any>;
  isDisabled?: boolean;
  tooltipText?: string;
  tooltip?: { isDelayed: boolean };
};

export default class Section extends Component<SourcesSectionArgs> {
  @service declare intercomEventService: $TSFixMe;
  @service declare router: Router;

  @tracked showAllRows = false;

  get isSectionDisabled(): boolean {
    return this.args.sectionDisabled ?? false;
  }

  get visibleRows(): SourceRow[] {
    let hideRows = this.args.maxRows && !this.showAllRows;
    return hideRows ? this.args.rows.slice(0, this.args.maxRows ?? 3) : this.args.rows;
  }

  get rowsOverflow(): boolean {
    return !!this.args.maxRows && this.args.rows.length > this.args.maxRows;
  }

  get hiddenRowCount(): number {
    return this.args.rows.length - this.visibleRows.length;
  }

  @action
  trackAnalyticsEvent(action: string, object: string, metadata?: Record<string, any>) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'knowledge_hub',
      place: 'sources',
      section_id: this.args.sectionId,
      ...metadata,
      ...this.args.additionalAnalyticsMetadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::Section': typeof Section;
    'knowledge-hub/overview/sections/Section': typeof Section;
  }
}
