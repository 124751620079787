/* import __COLOCATED_TEMPLATE__ from './credit-card-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CreditCardComponent extends Component {
  @service customerService;
  @service cardlessTrialService;
  @service cardlessConversionModalService;
  @tracked cardModalIsOpen = false;

  get customer() {
    return this.customerService.customer;
  }

  @action
  openModal() {
    if (this.cardlessTrialService.isInSelfServeTrial) {
      this.cardlessConversionModalService.toggle();
    } else {
      this.openCardModal();
    }
  }

  openCardModal() {
    this.cardModalIsOpen = true;
  }
}
