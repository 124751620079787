/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { hideIntercomWidget, showIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import ModelCacheHelper from 'embercom/lib/model-cache/helper';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import AppRefreshing from 'embercom/routes/mixins/app-refresher';
import storage from 'embercom/vendor/intercom/storage';
import moment from 'moment-timezone';
import Metrics from 'embercom/models/metrics';
import getTransitionParams from 'embercom/lib/router/get-transition-params';
import RouteRegexes from 'embercom/lib/route-regexes';

const EMAIL_VERIFICATION_REQUIRED_ROUTE_NAMES = [
  'apps.app.fin-ai-agent',
  'apps.app.outbound',
  'apps.app.home',
  'apps.app.guide-library',
  'apps.app.getting-started',
  'inbox.workspace',
  'apps.app.articles',
];
const FORCE_EMAIL_VERIFICATION_METRIC_NAME = 'force_email_verification_required';

export default IntercomRoute.extend(AppRefreshing, {
  shortcuts: service(),
  bootOptionsService: service(),
  intl: service(),
  router: service(),
  intercomEventService: service(),
  mouseTrackingService: service(),
  resourceUsage: service(),
  session: service(),

  init() {
    this._super(...arguments);

    this.router.on('routeDidChange', (transition) => {
      if (transition.isAborted) {
        return;
      }
      if (this.shouldRedirectToEmailVerifyPage(transition.to.name)) {
        Metrics.sendInteractionMetrics([
          {
            name: FORCE_EMAIL_VERIFICATION_METRIC_NAME,
          },
        ]);
        this.transitionTo('apps.app.teams-checkout.verify', this.getAppId(transition));
        return;
      }
      if (this.shouldRedirectToExpiredSubscriptionPage(transition)) {
        this.router.transitionTo('apps.app.expired-subscription', this.getAppId(transition));
        return;
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'navigated',
        object: transition.to.name,
        from_route: transition.from?.name,
        section: 'global',
        place: 'global',
        embercom_revision_id: document.querySelector('meta[name="embercom_revision_id"]')?.content,
      });
    });

    this.router.on('routeWillChange', (transition) => {
      this.refreshAppIfAppropriate(transition);
      this.mouseTrackingService.report();
    });

    this.resourceUsage.startTracking();
  },

  title(tokens) {
    if (isEmpty(tokens)) {
      return document.title;
    } else {
      let appName = this.modelFor('apps.app').get('name');
      return `${tokens.reverse().join(' | ')} | ${appName} | Intercom`;
    }
  },
  async beforeModel(transition) {
    let queryParams = transition.to.queryParams;
    this.bootOptionsService.initFromQueryParams(queryParams);

    let locale = this.getLocale(queryParams.locale || 'en');

    try {
      if (queryParams.locale || !storage.get('locale')) {
        storage.set('locale', {
          key: locale,
        });
      }

      await this.intl.switchLocale(storage.get('locale').key);
    } catch (e) {
      await this.intl.switchLocale(locale);
    }
    moment.locale(this.intl.primaryLocale);

    if (window.location.href.includes('translation_active=yes')) {
      window.PHRASEAPP_CONFIG = {
        projectId: '63a73a3130c6af471a590a82bd120986',
        prefix: '[[__',
        suffix: '__]]',
      };
      let phraseapp = document.createElement('script');
      phraseapp.type = 'text/javascript';
      phraseapp.async = true;
      phraseapp.src = [
        'https://',
        'phraseapp.com/assets/in-context-editor/2.0/app.js?',
        new Date().getTime(),
      ].join('');
      let s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(phraseapp, s);
    }

    // When local model caching is enabled we essentially don't have any asnyc promises
    // in the `apps.app` route. This causes the initial loading screen to never show.
    // The actual hydration of the models takes a few hundred milliseconds so to force
    // the loading screen to show for this process we return a NOOP timeout here.
    if (!ModelCacheHelper.isDisabled()) {
      return timeout(1);
    }
  },

  updateRelativeTime() {
    if (!ENV.APP.relative_time_update_interval) {
      return;
    }
    let self = this;
    later(
      this,
      function () {
        self.send('refreshRelativeTime');
        self.updateRelativeTime();
      },
      ENV.APP.relative_time_update_interval,
    );
  },
  activate() {
    this.registerShortcuts();
    this.updateRelativeTime();
    this.setLastRefreshedAt();
  },
  registerShortcuts() {
    this.shortcuts.register('global-application', this, [
      { key: 'escape', textBox: true, method: this.escapeShortcut },
      { key: '/', method: this.shortcutKeyShortcut, shiftKey: true },
    ]);
  },

  shortcutKeyShortcut() {
    this.controller.send('toggleKeyboardShortcutsModal');
  },

  escapeShortcut() {
    let simpleComposerModals = [
      'companies/company/modals/user-message',
      'app/components/stats-system/modals/bulk-message',
      'segments/segment/modals/message',
    ];
    if (!simpleComposerModals.includes(this.controller.modalService.modalComponent)) {
      this.controller.modalService.closeModal();
    }
  },

  getLocale(locale) {
    let locales = new Map([
      ['en', 'en'],
      ['en-us', 'en'],
      ['en-US', 'en'],
      ['es-MX', 'es-MX'],
      ['es-mx', 'es-MX'],
      ['de-DE', 'de-DE'],
      ['de-de', 'de-DE'],
      ['fr-FR', 'fr-FR'],
      ['fr-fr', 'fr-FR'],
      ['pt-BR', 'pt-BR'],
      ['pt-br', 'pt-BR'],
      ['ja', 'ja'],
    ]);

    return locales.get(locale) || 'en';
  },

  isBackNavigation(transition) {
    return transition.intent.url && window.history;
  },

  shouldRedirectToExpiredSubscriptionPage(transition) {
    if (
      transition.to.name.includes('apps.app.expired-subscription') ||
      transition.to.name.match(RouteRegexes.excludedFromExpiredSubscriptionRedirect)
    ) {
      return false;
    }

    let app =
      this.modelFor('apps.app') ||
      (this.session.isWorkspaceLoaded ? this.session.workspace : undefined);

    return !!app?.canDisplayExpiredSubscriptionPage;
  },

  shouldRedirectToEmailVerifyPage(routeName) {
    let app = this.modelFor('apps.app');
    return (
      app &&
      this.routeRequiresEmailVerification(routeName) &&
      this.appRequiresEmailVerification(app)
    );
  },

  appRequiresEmailVerification(app) {
    return app.currentAdmin?.enforced_email_verification_required;
  },

  routeRequiresEmailVerification(routeName) {
    let emailVerificationRequiredRoute = EMAIL_VERIFICATION_REQUIRED_ROUTE_NAMES.find((name) => {
      return routeName.startsWith(name) || routeName === name;
    });

    return !!emailVerificationRequiredRoute;
  },

  getAppId(transition) {
    let app_id;
    let app = this.modelFor('apps.app');
    if (app && app.get('id')) {
      app_id = app.get('id');
    } else {
      let routeParams = getTransitionParams(transition);
      if (routeParams['inbox.workspace'] && routeParams['inbox.workspace'].workspace_id) {
        app_id = routeParams['inbox.workspace'].workspace_id;
      }
    }
    return app_id;
  },

  actions: {
    warnOnNavigation(shouldWarnOnNavigation) {
      this.controller.set('shouldWarnOnNavigation', shouldWarnOnNavigation);
    },

    openModal(componentPath, model, options, controllerProps, callbacks = {}) {
      hideIntercomWidget();
      if (callbacks.onOpen) {
        this.controller.set('onOpen', callbacks.onOpen);
      }
      if (callbacks.onClose) {
        this.controller.set('onClose', callbacks.onClose);
      }

      if (this.controller.get('onOpen')) {
        this.controller.get('onOpen')();
        this.controller.set('onOpen', null);
      }
    },

    closeModal(results) {
      showIntercomWidget();

      if (this.controller.get('onClose')) {
        this.controller.get('onClose')(results);
        this.controller.set('onClose', null);
      }
    },

    toggleModal(componentPath, model, options) {
      if (this.controller.modalService.modalComponent === componentPath) {
        this.controller.modalService.closeModal();
      } else {
        // eslint-disable-next-line @intercom/intercom/no-legacy-modal
        this.controller.modalService.openModal(componentPath, model, options);
      }
    },

    refreshRelativeTime() {
      let applicationComponent = this.controller.get('applicationComponent');
      applicationComponent.send('refreshRelativeTime');
    },
    activateTransitionSuppression() {
      this.set('shouldSuppressTransitions', true);
    },
    deactivateTransitionSuppression() {
      this.set('shouldSuppressTransitions', false);
    },
    willTransition(transition) {
      if (this.shouldSuppressTransitions && this.isBackNavigation(transition)) {
        transition.abort();
        window.history.forward();
      }
    },
    setDocumentTitle(title) {
      document.title = `${title} | Intercom`;
    },
    updatePageTitle() {
      // Updating the window title. Calling collectTitleToken in turn calls titleToken, which is responsible for setting the title
      // Please see this for reference: https://github.com/mike-north/ember-cli-document-title-northm/blob/d76e73edef14d10cc291ca00e6dc1afcd094c140/vendor/document-title/document-title.js#L41
      this.send('collectTitleTokens', []);
    },
  },
});
