/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type { Type as AttributeDescriptorType } from 'embercom/models/operator/visual-builder/attribute-descriptor';

export default class ActionOutputParameterDescriptor extends Fragment {
  @attr('string') declare type: AttributeDescriptorType;
  @attr('string') declare name: string;
  @attr('string') declare sourcePath: string;
  @attr('string') declare exampleValue: string;
  @fragmentArray('workflow-connector/action-output-parameter-descriptor')
  declare childDescriptors: Array<ActionOutputParameterDescriptor>;
}
