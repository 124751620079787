/* import __COLOCATED_TEMPLATE__ from './http-request-editor.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { HTTP_METHODS } from 'embercom/lib/custom-authentication/request-helper';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import resolveObjectPath from 'embercom/lib/resolve-object-path';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type Token from 'embercom/models/custom-authentication/token';
import { taskFor } from 'ember-concurrency-ts';
// @ts-ignore
import type NotificationsService from 'embercom/services/notifications-service';

interface Args {
  customAuthenticationToken: Token;
  form: any;
}

export default class HttpRequestEditor extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;

  @tracked customAuthenticationToken = this.args.customAuthenticationToken;
  @tracked declare testResponse: any;
  @tracked bannerText = '';
  @tracked declare bannerType: 'confirmation' | 'error';
  @tracked declare displayableResponseBody: string | null;
  @tracked selectedPreviewTab = 'headers';

  @dropTask
  *testRequest() {
    let params = {
      app_id: this.appService.app.id,
      refresh_token_configuration: this.customAuthenticationToken.refreshTokenConfiguration,
    };
    try {
      this._setLatestTestRequestParams();
      this.customAuthenticationToken.refreshTokenConfiguration.mostRecentTestPassed = false; // to invalidate previous result
      // @ts-ignore
      this.testResponse = yield post('/ember/custom_authentication/tokens/test', params);
      this.displayableResponseBody = JSON.stringify(this.responseBody, null, 2);
      this._setBannerParams();
    } catch (exception) {
      this.displayableResponseBody = null;
      this._setBannerOnException(exception);
    }
  }

  get isTestRequestRunning() {
    return taskFor(this.testRequest).isRunning;
  }

  _setLatestTestRequestParams() {
    this.customAuthenticationToken.refreshTokenConfiguration.latestTestRequestParams =
      this.store.createRecord('custom-authentication/token', {
        httpMethod: this.customAuthenticationToken.refreshTokenConfiguration.httpMethod,
        url: this.customAuthenticationToken.refreshTokenConfiguration.url,
        body: this.customAuthenticationToken.refreshTokenConfiguration.body,
        headers: this.customAuthenticationToken.refreshTokenConfiguration.headers,
      });
  }

  _setBannerParams() {
    this.bannerText = '';
    if (this.testResponse.refresh_response.success) {
      this.customAuthenticationToken.refreshTokenConfiguration.mostRecentTestPassed = true;
      this.bannerType = 'confirmation';
      this.bannerText += `${this.testResponse.refresh_response.status} ${this.testResponse.refresh_response.return_code.toUpperCase()}`;
    } else {
      this.customAuthenticationToken.refreshTokenConfiguration.mostRecentTestPassed = false;
      this.bannerType = 'error';
      this.bannerText +=
        this.testResponse.refresh_response.human_readable_error ||
        this.intl.t('settings.custom-authentication-tokens.new.response.error');
    }
  }

  _setBannerOnException(exception: any) {
    this.bannerType = 'error';
    this.bannerText = exception.errorThrown;
  }

  get responseBody() {
    return this.testResponse?.refresh_response.body;
  }

  get supportedHttpMethods() {
    return HTTP_METHODS.filter((method) => method.value !== 'delete');
  }

  get isBodyAvailable() {
    if (!this.customAuthenticationToken.refreshTokenConfiguration) {
      return false;
    }
    return ['post', 'put'].includes(
      this.customAuthenticationToken.refreshTokenConfiguration.httpMethod,
    );
  }

  get responsePaths() {
    let items = this.testResponse ? this.pathItemsFromResponse : [];
    if (this.customAuthenticationToken.refreshTokenConfiguration.tokenResponsePath) {
      items.pushObject(this.tokenPathItem);
    }
    return [
      {
        items: items.uniqBy('value'),
      },
    ];
  }

  get tokenPathItem() {
    return {
      value: this.customAuthenticationToken.refreshTokenConfiguration.tokenResponsePath,
      text: this.customAuthenticationToken.refreshTokenConfiguration.tokenResponsePath,
    };
  }

  get pathItemsFromResponse() {
    return this.testResponse.possible_response_paths.map((path: string) => {
      return {
        value: path,
        text: path,
      };
    });
  }

  @action
  setPathAndTokenValue(path: string) {
    this.customAuthenticationToken.refreshTokenConfiguration.tokenResponsePath = path;
    this.customAuthenticationToken.tokenValue = resolveObjectPath(this.responseBody, path);
  }

  @action
  setRefreshConfigHttpMethod(httpMethod: string) {
    this.customAuthenticationToken.refreshTokenConfiguration.httpMethod = httpMethod;
    if (!this.isBodyAvailable) {
      this.selectedPreviewTab = 'headers';
    }
  }

  @action
  changePreviewTab(selectedTab: string) {
    this.selectedPreviewTab = selectedTab;
  }

  get refreshTokenUrlValidation() {
    return this.customAuthenticationToken.refreshTokenConfiguration.validations.attrs.url;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::CustomAuthentication::HttpRequestEditor': typeof HttpRequestEditor;
    'settings/custom-authentication/http-request-editor': typeof HttpRequestEditor;
  }
}
