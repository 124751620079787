/* import __COLOCATED_TEMPLATE__ from './content-type-stamp.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';
import { type InterfaceIconName, type InterfaceIconSet } from '@intercom/pulse/lib/interface-icons';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';

interface Args {
  entityType: EntityType;
  iconOnly?: boolean;
  icon?: InterfaceIconName;
}

export default class ContentTypeStamp extends Component<Args> {
  get computedStyles(): {
    icon: InterfaceIconName;
    iconSet: InterfaceIconSet;
    classNames: string;
  } {
    if (this.args.icon) {
      return {
        icon: this.args.icon,
        iconSet: 'tiny',
        classNames: 'bg-neutral-container text-muted',
      };
    }

    switch (this.args.entityType) {
      case EntityType.InternalArticle:
        return {
          icon: 'locked',
          iconSet: 'tiny',
          classNames: 'bg-notice-container text-defaultest',
        };
      default:
        return {
          icon: objectIcons[this.args.entityType],
          iconSet: 'tiny',
          classNames: 'bg-neutral-container text-muted',
        };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentTypeStamp': typeof ContentTypeStamp;
    'knowledge-hub/content-type-stamp': typeof ContentTypeStamp;
  }
}
