/* import __COLOCATED_TEMPLATE__ from './timeline-step.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class TimelineStep extends Component {
  get containerClass() {
    // ⬇️ class tours__editor__timeline-component__${this.args.step.style}-step-container result to ⬇️
    // tours__editor__timeline-component__pointer-step-container
    // tours__editor__timeline-component__post-step-container
    // tours__editor__timeline-component__video-step-container
    return `tours__editor__timeline-component__step-container tours__editor__timeline-component__${this.args.step.style}-step-container`;
  }

  get blocksClass() {
    if (this.args.step.isPointer || this.args.step.style === 'pointer') {
      return 'intercom-embed-tour-step-pointer';
    } else if (this.args.step.isPost || this.args.step.style === 'post') {
      return 'intercom-embed-tour-step-post';
    } else {
      return 'tours__editor__timeline-component__video-step-container__inner';
    }
  }

  get isNotLast() {
    return !this.args.isLast;
  }

  get showAddNewStep() {
    return this.args.isEditing && this.args.isLast;
  }

  get showProgressIcon() {
    return this.showAddNewStep || this.isNotLast;
  }

  @action
  openTourBuilder() {
    if (this.args.stepClickAction) {
      this.args.stepClickAction();
    }
  }
}
