/* import __COLOCATED_TEMPLATE__ from './tour-font-picker.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import type IntlService from 'ember-intl/services/intl';
import { type FontPickerItemType } from './font-picker-item';

export default class TourFontPicker extends Component {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare contentEditorService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get tour(): $TSFixMe {
    return this.contentEditorService.activeObject;
  }

  get availableFonts() {
    // FONT_FAMILIES_DATA in @intercom/embercom-prosemirror-composer/addon/lib/blocks/constants is not exported
    let options: FontPickerItemType[] = [
      {
        text: this.intl.t('outbound.tour.editor.content-panel.use-custom-font.default-font'),
        value: '',
      },
      {
        text: 'Serif',
        value: 'serif',
      },
      {
        text: 'Sans Serif',
        value: 'sans-serif',
      },
      {
        text: 'Monospace',
        value: 'monospace',
      },
    ];

    if (this.app.canUseTourFontProximaNova && this.tour?.fontProximaNova) {
      options.push(this.tour.fontProximaNova as FontPickerItemType);
    }
    return options.map((option) => ({
      ...option,
      component: 'tours/editor/content-panel/tour-styles/font-picker-item',
    }));
  }

  get selectedFont(): string {
    return this.tour.customFont ?? '';
  }

  @action
  setSelectedFont(font: string) {
    if (font === '') {
      this.tour.customFont = null;
    } else {
      this.tour.customFont = font;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tours::Editor::ContentPanel::TourStyles::TourFontPicker': typeof TourFontPicker;
    'tours/editor/content-panel/tour-styles/tour-font-picker': typeof TourFontPicker;
  }
}
