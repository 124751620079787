/* RESPONSIBLE TEAM: team-standalone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr } from '@ember-data/model';
import { type AllChannels } from 'embercom/lib/operator/custom-bots/constants';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { buildValidations } from 'ember-cp-validations';
import { validator } from 'ember-cp-validations';

const Validations = buildValidations({
  handoffToSalesforceOwnerId: [
    validator('handoff-to-salesforce-owner-id-valid', {
      dependentKeys: ['model.handoffToSalesforceOwnerId'],
      messageKey:
        'operator.workflows.visual-builder.handoff-to-salesforce-case.missing-handoff-owner-id-error-message',
    }),
  ],
});

export default class HandoffToSalesforceCase extends Step.extend(Validations) {
  @attr('string') declare handoffToSalesforceOwnerId?: string;

  get supportedChannels(): Readonly<AllChannels[]> {
    return SUPPORTED_CHANNELS.handoffToSalesforceCase;
  }

  static get supportedContexts(): TargetContext[] {
    return [TargetContext.Conversation];
  }

  static get isStepGroupEnding(): boolean {
    return true;
  }

  static get isStepTerminal(): boolean {
    return true;
  }

  static createNewStep(store: Store): HandoffToSalesforceCase {
    return store.createRecord('operator/visual-builder/step/handoff-to-salesforce-case', {
      type: 'operator/visual-builder/step/handoff-to-salesforce-case',
    });
  }
}
