/* import __COLOCATED_TEMPLATE__ from './segment.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    disabled?: boolean;
  };
}

const Segment = templateOnlyComponent<Signature>();
export default Segment;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::Segment': typeof Segment;
  }
}
