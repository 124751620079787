/* import __COLOCATED_TEMPLATE__ from './font-picker-item.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export type FontPickerItemType = {
  text: string;
  value: string;
};

type Args = {
  item: FontPickerItemType;
};

export default class FontPickerItem extends Component<Args> {
  get fontName(): string {
    return this.args.item.value;
  }

  get className(): string {
    return this.fontName.toLowerCase().replace(/\s/g, '-');
  }

  get shouldRenderStyleTag(): boolean {
    return !!this.className && !!this.fontName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tours::Editor::ContentPanel::TourStyles::FontPickerItem': typeof FontPickerItem;
    'tours/editor/content-panel/tour-styles/font-picker-item': typeof FontPickerItem;
  }
}
