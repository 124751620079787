/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'alert'
  | 'danger'
  | 'success'
  | 'onFill'
  | 'call'
  | 'activeCall'
  | 'secondaryText';

type ButtonSize = 'base' | 'sm' | 'narrow' | 'xs' | 'lg' | 'fill' | 'call';

export interface Inbox2ButtonArgs {
  type: ButtonType;
  size?: ButtonSize;
  forceActive?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  grayscale?: boolean;
}

interface Signature {
  Element: HTMLButtonElement;
  Args: Inbox2ButtonArgs;
  Blocks: {
    default: [];
  };
}

export default class Inbox2CommonButtonComponent extends Component<Signature> {
  get size() {
    return this.args.size ?? 'base';
  }

  get type() {
    return this.args.type ?? 'primary';
  }

  get sizeClasses() {
    if (this.args.type === 'link' && !this.args.iconOnly) {
      return 'px-1';
    }

    let classes = {
      base: this.args.iconOnly ? 'w-8 h-8 p-2' : 'py-2 px-3',
      sm: this.args.iconOnly ? 'p-1 inbox2__button--small' : 'py-1 px-2 inbox2__button--small',
      narrow: 'py-1 px-0 inbox2__button--small',
      xs: 'p-0 inbox2__button--xs',
      lg: this.args.iconOnly ? 'p-4 inbox2__button--large' : 'px-6 py-3 inbox2__button--large',
      fill: 'px-3 py-2 inbox2__button--fill',
      call: this.args.iconOnly ? 'inbox2__button--call' : 'px-4 inbox2__button--call',
    }[this.size];

    return `inbox2__button ${classes}`;
  }

  get colorClasses(): string | void {
    return {
      primary: this.primaryClasses,
      secondary: this.secondaryClasses,
      tertiary: this.tertiaryClasses,
      alert: this.alertClasses,
      link: this.linkClasses,
      danger: this.dangerClasses,
      success: this.successClasses,
      call: this.callClasses,
      activeCall: this.activeCallClasses,
      secondaryText: this.secondaryTextClasses,
      onFill: this.onFillClasses,
    }[this.type].trim();
  }

  private get primaryClasses(): string {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-neutral-fill-emphasis text-on-neutral border-neutral-fill guide-library-el-active'
          : 'bg-neutral-fill text-on-neutral'
      }
      hover:bg-neutral-fill-emphasis
      active:bg-neutral-fill-emphasis active:border-neutral-fill
      focus:bg-neutral-fill-emphasis focus:border-neutral-fill focus:ring-2 focus:ring-neutral-border-emphasis
    `;
  }

  private get onFillClasses(): string {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-base-module text-default border-accent-border-emphasis guide-library-el-active'
          : 'bg-base-module text-default'
      }
      hover:bg-base-module hover:border-neutral-border-emphasis
      active:bg-base-module active:border-neutral-border-emphasis
      focus:bg-base-module focus:border-neutral-border-emphasis focus:ring-1 focus:ring-neutral-border-emphasis
    `;
  }

  private get secondaryClasses() {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-neutral-container-emphasis text-default border-neutral-border-emphasis guide-library-el-active'
          : 'bg-neutral-container text-default'
      }
      hover:bg-neutral-container-emphasis
      active:bg-neutral-container-emphasis active:border-neutral-border-emphasis
      focus:bg-neutral-container-emphasis focus:border-neutral-border-emphasis focus:ring-2 focus:ring-neutral-border-emphasis
    `;
  }

  private get secondaryTextClasses() {
    if (this.args.disabled) {
      return `text-disabled pointer-events-none`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-neutral-container text-default border-neutral-border-emphasis guide-library-el-active'
          : 'bg-neutral-container text-default'
      }
      hover:bg-neutral-container hover:text-default
      active:bg-neutral-container active:text-default active:border-neutral-border-emphasis
      focus:bg-neutral-container focus:text-default focus:border-neutral-border-emphasis focus:ring-2 focus:ring-neutral-border-emphasis
    `;
  }

  private get tertiaryClasses() {
    if (this.args.disabled) {
      return `text-disabled pointer-events-none`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-neutral-container text-default guide-library-el-active'
          : 'bg-transparent text-default'
      }
      hover:bg-neutral-container-emphasis hover:text-default
      active:bg-neutral-container-emphasis active:text-default
      focus:bg-neutral-container-emphasis focus:text-default focus:ring-2 focus:ring-neutral-border
    `;
  }

  private get linkClasses() {
    if (this.args.disabled) {
      return `text-disabled pointer-events-none`;
    }
    let colour = this.args.grayscale ? 'text-muted' : 'text-default';
    return `
      ${this.args.forceActive ? 'text-accent' : `bg-transparent ${colour}`}
      hover:text-accent
      active:text-accent
      focus:text-accent focus:ring-2 focus:ring-neutral-border
    `;
  }

  private get alertClasses() {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-error-container text-error border-error-border'
          : 'bg-error-container text-error'
      }
      active:border-error-border
      focus:border-error-border focus:ring-2 focus:ring-error-border
    `;
  }

  private get dangerClasses() {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      text-on-error
      ${this.args.forceActive ? 'bg-error-fill-emphasis' : 'bg-error-fill'}
      hover:bg-error-fill-emphasis
      active:bg-error-fill-emphasis
      focus:bg-error-fill-emphasis focus:ring-2 focus:ring-error-border
    `;
  }

  private get successClasses() {
    if (this.args.disabled) {
      return `bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      text-on-success
      ${this.args.forceActive ? 'bg-success-fill-emphasis' : 'bg-success-fill'}
      hover:bg-success-fill-emphasis
      active:bg-success-fill-emphasis
      focus:bg-success-fill-emphasis focus:ring-2 focus:ring-success-border
    `;
  }

  private get callClasses() {
    if (this.args.disabled) {
      return `rounded-full bg-neutral-fill text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      rounded-full
      ${
        this.args.forceActive
          ? 'bg-neutral-container-emphasis text-default'
          : 'bg-neutral-container text-default'
      }
      hover:bg-neutral-container-emphasis hover:text-default
      active:bg-neutral-container-emphasis active:text-default
      focus:border-neutral-border-emphasis focus:ring-2 focus:ring-neutral-border-emphasis
    `;
  }

  private get activeCallClasses() {
    if (this.args.disabled) {
      return `rounded-full bg-neutral-container text-disabled pointer-events-none pointer-not-allowed`;
    }

    return `
      rounded-full
      ${
        this.args.forceActive
          ? 'bg-neutral-fill-emphasis text-on-neutral guide-library-el-active'
          : 'bg-neutral-fill text-on-neutral'
      }
      hover:bg-neutral-container-emphasis hover:text-on-neutral
      active:bg-neutral-container-emphasis active:text-on-neutral
      focus:border-neutral-border-emphasis focus:ring-2 focus:ring-neutral-border-emphasis
    `;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Button': typeof Inbox2CommonButtonComponent;
  }
}
