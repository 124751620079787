/* import __COLOCATED_TEMPLATE__ from './primary-nav.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { runTask } from 'ember-lifeline';
import { get } from 'embercom/lib/ajax';
import type Inbox2OnboardingHomeService from 'embercom/services/inbox2-onboarding-home-service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import ENV from 'embercom/config/environment';
import { showUnderConstructionBanner } from 'embercom/lib/under-construction-banner';
import { DEFAULT_GUIDE_LIBRARY_V2, GUIDES_WITH_VIDEO_HEADER } from '../../lib/home/guides';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type InboxState from 'embercom/services/inbox-state';
import { SidebarViewType } from 'embercom/services/inbox-state';

/* eslint-disable @intercom/intercom/no-bare-strings */
const INBOX_LABEL = 'Inbox';

interface Args {}

interface Signature {
  Args: Args;
}

export default class PrimaryNav extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare inbox2OnboardingHomeService: Inbox2OnboardingHomeService;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare inboxState: InboxState;

  @tracked leftNavBottomContainerHeight!: number;
  @tracked showUnreadProductChangesIndicator = false;
  @tracked navItemInFocus: string | undefined = INBOX_LABEL;

  windowResize: () => void;

  @ref('left-nav-bottom-container') declare leftNavBottomContainer: HTMLElement;
  @ref('left-nav-overflow-opener') declare leftNavOverflowOpener: HTMLElement;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.windowResize = this.debounceResizeHandler.bind(this);

    window.addEventListener('resize', this.windowResize);

    taskFor(this.loadProductChanges).perform();
    runTask(this, () => this.updateLeftNavBottomContainerHeight(), ENV.APP._1000MS);
    runTask(this, () => this.updateLeftNavBottomContainerHeight(), ENV.APP._3000MS);
  }

  willDestroy() {
    super.willDestroy();

    window.removeEventListener('resize', this.windowResize);
  }

  updateLeftNavBottomContainerHeight() {
    let offset = 0;
    if (this.hasOverflowBottomNavEntries) {
      offset = this.leftNavOverflowOpener.clientHeight;
    }
    this.leftNavBottomContainerHeight = this.leftNavBottomContainer.clientHeight + offset;
  }

  debounceResizeHandler() {
    debounce(this, this.updateLeftNavBottomContainerHeight, ENV.APP._100MS);
  }

  get tooltipAnimationDuration() {
    return 0;
  }

  get workspaceID() {
    return this.session.workspace.id;
  }

  get locale() {
    return this.intl.locale;
  }

  get canUseFree() {
    return this.session.workspace.isFeatureEnabled('ember-purchase-free');
  }

  get canUseInbox() {
    return this.session.workspace.isFeatureEnabled('inbox');
  }

  get inboxIsActive() {
    return !!(this.canUseFree || this.canUseInbox);
  }

  get messagesIsActive() {
    return this.session.workspace.isFeatureEnabled('auto_messaging');
  }

  get articlesIsActive() {
    return this.session.workspace.isFeatureEnabled('help_center');
  }

  get isChannelSwitcherActive() {
    let features = this.session.workspace.features ?? [];
    return features.includes('inbound-phone-call');
  }

  get canSeeOHNav() {
    if (this.inbox2OnboardingHomeService.guide?.migrated_from_guide_id) {
      return false;
    }
    let guides = [...GUIDES_WITH_VIDEO_HEADER, DEFAULT_GUIDE_LIBRARY_V2];
    return guides.includes(this.inbox2OnboardingHomeService.guide?.identifier);
  }

  canAccessIntershop() {
    return this.session.workspace.canAccessIntershop;
  }

  get logoAnalyticsMetadata() {
    return {};
  }

  get automationEntry() {
    return {
      id: '7',
      productName: this.intl.t('components.primary-nav.automation'),
      route: 'apps.app.automation',
      activeRoute: 'apps.app.automation',
      svgId: 'trigger',
      className: 'o__operator',
      active: true,
      analyticsEventObjectName: 'automation_side_bar_link',
    };
  }

  get platformEntry() {
    let platformRoutes =
      'apps.app.users apps.app.companies apps.app.feed apps.app.platform apps.app.accounts';
    return {
      id: 'platform',
      productName: this.platformPageName,
      route: 'apps.app.users',
      activeRoute: platformRoutes,
      svgId: 'multiple-people',
      className: 'o__platform',
    };
  }

  get helpCenterEntry() {
    return {
      svgId: 'article',
      productName: this.intl.t('components.primary-nav.help-center'),
      route: 'apps.app.articles',
      activeRoute: 'apps.app.articles',
      id: 'articles',
      active: this.articlesIsActive,
      className: 'o__help-center',
    };
  }

  get knowledgeBaseEntry() {
    return {
      svgId: 'campaign',
      productName: this.intl.t('components.primary-nav.knowledge-base'),
      route: 'apps.app.knowledge-hub',
      activeRoute: 'apps.app.knowledge-hub',
      className: 'o__knowledge-base',
      active: true,
    };
  }

  get reportsEntry() {
    return {
      svgId: 'bar-charts',
      productName: this.intl.t('components.primary-nav.reports'),
      route: 'apps.app.reports',
      activeRoute: 'apps.app.reports',
      className: 'o__reporting',
      analyticsEventObjectName: 'reporting_side_bar_link',
      active: true,
    };
  }

  get topNavEntries() {
    let inboxEntry = {
      svgId: 'inbox',
      productName: this.intl.t('components.primary-nav.help-desk'),
      route: 'apps.app.inbox',
      activeRoute: 'apps.app.inbox',
      id: '1',
      active: this.inboxIsActive,
    };

    let finAIAgentEntry = {
      id: 'ai-chatbot',
      svgId: 'fin',
      productName: this.intl.t('components.primary-nav.ai-chatbot'),
      route: 'apps.app.fin-ai-agent',
      activeRoute: 'apps.app.fin-ai-agent',
      active: true,
      analyticsEventObjectName: 'ai_chatbot_side_bar_link',
    };

    let navEntries = [
      inboxEntry,
      finAIAgentEntry,
      {
        svgId: 'outbound-filled',
        productName: this.intl.t('components.primary-nav.outbound'),
        route: 'apps.app.outbound',
        activeRoute: 'apps.app.outbound',
        id: '2',
        active: this.messagesIsActive,
      },
    ];

    return navEntries;
  }

  get platformPageName() {
    return this.intl.t('components.primary-nav.contacts');
  }

  get channelsEntry() {
    return [];
  }

  get bottomNavEntries() {
    let intershopEntry = {
      productName: this.intl.t('components.primary-nav.discover-more'),
      route: 'apps.app.intershop',
      activeRoute: 'apps.app.intershop',
      className: 'o__intershop',
      analyticsEventObjectName: 'intershop_side_bar_link',
      svgId: 'shopping-cart',
    };

    let navEntries = [
      ...[this.automationEntry],
      ...[this.knowledgeBaseEntry],
      ...[this.reportsEntry, this.platformEntry],
      ...this.channelsEntry,
      ...[this.helpCenterEntry],
      ...(this.canAccessIntershop() ? [intershopEntry] : []),
      {
        productName: this.intl.t('components.primary-nav.app-store'),
        route: 'apps.app.appstore',
        activeRoute: 'apps.app.appstore',
        className: 'o__appstore',
        svgId: 'app-store',
        analyticsEventObjectName: 'app_store_side_bar_link',
      },
    ];

    return navEntries;
  }

  get visibleBottomNavEntries() {
    if (this.hasOverflowBottomNavEntries) {
      return this.bottomNavEntries.slice(0, this.availableVisibleBottomNavEntrySlots());
    } else {
      return this.bottomNavEntries;
    }
  }

  get hasOverflowBottomNavEntries() {
    return this.bottomNavEntries.length > this.availableVisibleBottomNavEntrySlots();
  }

  get hasAccessToProductIaNav(): boolean {
    return Boolean(this.session.hasOptedInForProductIa);
  }

  availableVisibleBottomNavEntrySlots() {
    let BOTTOM_NAV_ENTRY_HEIGHT_PX = 44;
    let availableVisibleBottomNavEntrySlots = Math.floor(
      this.leftNavBottomContainerHeight / BOTTOM_NAV_ENTRY_HEIGHT_PX,
    );

    // if there isn't enough room for all the icons, then
    // remove one from the available slots to take
    // into account the overflow opener
    if (availableVisibleBottomNavEntrySlots < this.bottomNavEntries.length) {
      availableVisibleBottomNavEntrySlots -= 1;
    }
    return availableVisibleBottomNavEntrySlots;
  }

  get overflowBottomNavEntries() {
    if (!this.hasOverflowBottomNavEntries) {
      return [];
    } else {
      return this.bottomNavEntries.slice(this.availableVisibleBottomNavEntrySlots());
    }
  }

  get isNavbarCollapsed() {
    return this.inboxState.activeSidebarView === SidebarViewType.Hidden;
  }

  @task *loadProductChanges(): TaskGenerator<void> {
    let response = yield get('/ember/public/changes/get_admin_unread_count');
    this.showUnreadProductChangesIndicator = response.count > 0;
  }

  @action productChangesClicked() {
    this.showUnreadProductChangesIndicator = false;
    window.Intercom('showSpace', 'news');
  }

  @action navItemFocused(productName?: string) {
    this.navItemInFocus = productName;
  }

  get inboxInFocus() {
    return this.navItemInFocus === INBOX_LABEL || this.navItemInFocus === undefined;
  }

  get showUnderConstructionBanner() {
    return showUnderConstructionBanner(this.session.workspace);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PrimaryNav': typeof PrimaryNav;
    'inbox2/primary-nav': typeof PrimaryNav;
  }
}
