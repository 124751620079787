/* import __COLOCATED_TEMPLATE__ from './conversation-funnel.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type {
  Options,
  SeriesOptionsType,
  SeriesSankeyOptions,
  SeriesSankeyPointOptionsObject,
} from 'highcharts';
import { ConversationFunnelConfig } from './conversation-funnel/series-config';
import PALETTE from '@intercom/pulse/lib/palette';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export const SANKEY_COLORS = {
  conversations: PALETTE['vis-slateblue-20'],
  fin: PALETTE['vis-magenta-40'],
  teammate: PALETTE['vis-yellow-50'],
  chatbot: PALETTE['vis-azure-50'],
  green: PALETTE['vis-green-50'],
  gray: PALETTE['text-muted'],
};

interface Signature {
  renderableChart: RenderableChart;
}

type OptionsWithSankey = Options & { plotOptions: { sankey: { borderRadius: number } } };

function stripZeroWeights(
  data: SeriesSankeyPointOptionsObject[],
): SeriesSankeyPointOptionsObject[] {
  return data.filter((point) => point.weight !== 0);
}

function nodeDataLabelIsHidden(node: any) {
  return node.dataLabel.element.attributes.opacity?.value === '0';
}

const increasedPadding = 35;

function adjustPaddingIfDataLabelsOverlap(series: any) {
  if (series.nodes.some(nodeDataLabelIsHidden)) {
    series.update({ nodePadding: increasedPadding }, false);
  }
}

function verticallyAlignTeammateAndClosedNodes(series: any) {
  let node = series.nodes.find((node: any) => node.id === 'automationAndTeammateAndClosed');
  if (!node) {
    return;
  }
  let nodeTops = node.linksTo.map((link: any) => link.fromNode.shapeArgs.y);
  if (nodeTops.length > 0) {
    let offsetVertical = Math.min(...nodeTops);
    node.update({ offsetVertical }, false);
  }
}

function safelyAdjustLayout(series: any, adjust: (series: any) => void) {
  if (!series.adjustingLayout) {
    series.adjustingLayout = true;
    // because layout adjustments can trigger a redraw, we need to prevent infinite recursion
    adjust(series);
    series.redraw();
    series.adjustingLayout = false;
  }
}

function adjustNodeLayout(event: Highcharts.PointerEventObject) {
  let chart = event.target as any;
  let series = chart.series[0];

  safelyAdjustLayout(series, () => {
    verticallyAlignTeammateAndClosedNodes(series);
    // it's important to adjust the padding last because the other changes
    // can influence the data label positions
    adjustPaddingIfDataLabelsOverlap(series);
  });
}

export default class ConversationFunnel extends Component<Signature> {
  @service declare intl: IntlService;

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  get highchartsSeries(): SeriesOptionsType[] {
    return [this.sankeySeries];
  }

  highchartsOptions: OptionsWithSankey = {
    // a sankey chart doesn't have axes, or a legend
    // we are adding some dummy series so we can show stuff in the chart legend
    // but having other series will enable the axes, so we need to hide them
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      marginTop: 20, // we need to avoid the labels from being cut off at the top
      marginRight: 150, // we need the space for the data labels on the rightmost column of nodes
      animation: false, // disables animations on redraw (but not initial draw)
      events: {
        redraw: adjustNodeLayout.bind(this),
      },
    },
    plotOptions: {
      series: {
        // a rectangle is rendered as a circle by default because of the legend marker's default borderRadius setting
        legendSymbol: 'rectangle',
        states: {
          inactive: {
            // this is really for the node data labels. The opacity for the links and nodes cannot be set separately
            // here, but they can be controlled via the styles defined in the hbs file
            opacity: 1,
          },
        },
      },
      sankey: {
        borderRadius: 0, // we want the nodes to be rectangles with no rounded edges
        nodePadding: 20, // start off with 20, we'll increase it if we detect overlapping data labels
        nodeAlignment: 'top',
        dataLabels: {
          padding: 2,
          color: 'black',
          useHTML: true,
          align: 'left',
          // crop: false & overflow: allow tell highcharts that it's okay to render the labels in the margins
          crop: false,
          overflow: 'allow',
          allowOverlap: false, // we detect if labels are hidden and increase padding
          x: 25,
          enabled: true,
          // see https://www.highcharts.com/docs/chart-concepts/templating
          nodeFormat: `
            <div class="text-default">
              <div class="font-semibold leading-4">
                {point.sum:,.0f}
                ({(divide (multiply 100 point.sum) series.options.custom.total):.0f}%)
              </div>
              <div class="text-11px font-normal leading-4">
                {point.name}
              </div>
            </div>
          `,
        },
      },
    },
  };

  get metrics() {
    return ConversationFunnelConfig.getMetricsFromChartDataRequest(this.dataResource.chartData);
  }

  get hasData() {
    let topOfFunnel = this.metrics.conversations;
    return !!topOfFunnel;
  }

  get sankeySeries(): SeriesSankeyOptions {
    let metrics = this.metrics;
    return {
      type: 'sankey',
      linkColorMode: 'gradient',
      animation: false,
      custom: {
        // this is used in the data label template (plotOptions.sankey.nodeFormat)
        total: metrics.conversations,
      },
      nodes: [
        {
          id: 'conversations',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.conversations'),
          color: SANKEY_COLORS.conversations,
        },
        {
          id: 'fin',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.fin'),
          color: SANKEY_COLORS.fin,
        },
        {
          id: 'finAndNotResolved',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.finAndNotResolved'),
          color: SANKEY_COLORS.gray,
        },
        {
          id: 'finAndResolved',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.finAndResolved'),
          color: SANKEY_COLORS.green,
        },
        {
          id: 'finAndTeammate',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.finAndTeammate'),
          color: SANKEY_COLORS.teammate,
        },
        {
          id: 'automationAndTeammateAndClosed',
          name: this.intl.t(
            'reporting.overview.charts.funnel.sankey.nodes.automationAndTeammateAndClosed',
          ),
          color: SANKEY_COLORS.green,
        },
        {
          id: 'chatbot',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.chatbot'),
          color: SANKEY_COLORS.chatbot,
        },
        {
          id: 'chatbotOnlyAndClosed',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.chatbotOnlyAndClosed'),
          color: SANKEY_COLORS.green,
        },
        {
          id: 'chatbotAndTeammate',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.chatbotAndTeammate'),
          color: SANKEY_COLORS.teammate,
        },
        {
          id: 'teammate',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.teammate'),
          color: SANKEY_COLORS.teammate,
        },
        {
          id: 'teammateAndClosed',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.teammateAndClosed'),
          color: SANKEY_COLORS.green,
        },
        {
          id: 'noReplyAndClosed',
          name: this.intl.t('reporting.overview.charts.funnel.sankey.nodes.noReplyAndClosed'),
          color: SANKEY_COLORS.gray,
        },
      ],
      data: stripZeroWeights([
        // the Fin flows
        {
          from: 'conversations',
          to: 'fin',
          weight: metrics.fin,
        },
        {
          from: 'fin',
          to: 'finAndNotResolved',
          weight: metrics.finAndNotResolved,
        },
        {
          from: 'fin',
          to: 'finAndResolved',
          weight: metrics.finAndResolved,
        },
        {
          from: 'fin',
          to: 'finAndTeammate',
          weight: metrics.finAndTeammate,
        },
        {
          from: 'finAndTeammate',
          to: 'automationAndTeammateAndClosed',
          weight: metrics.finAndTeammateAndClosed,
        },

        // the Chatbot flows
        {
          from: 'conversations',
          to: 'chatbot',
          weight: metrics.chatbot,
        },
        {
          from: 'chatbot',
          to: 'chatbotOnlyAndClosed',
          weight: metrics.chatbotOnlyAndClosed,
        },
        {
          from: 'chatbot',
          to: 'chatbotAndTeammate',
          weight: metrics.chatbotAndTeammate,
        },
        {
          from: 'chatbotAndTeammate',
          to: 'automationAndTeammateAndClosed',
          weight: metrics.chatbotAndTeammateAndClosed,
        },

        // the Teammate-only flows
        {
          from: 'conversations',
          to: 'teammate',
          weight: metrics.teammate,
        },
        {
          from: 'teammate',
          to: 'teammateAndClosed',
          weight: metrics.teammateAndClosed,
        },

        // no replies
        {
          from: 'conversations',
          to: 'noReplyAndClosed',
          weight: metrics.noReplyAndClosed,
        },
        {
          from: 'conversations',
          to: undefined,
          weight: metrics.noReplyAndNotClosed,
        },
      ]),
    };
  }

  get legendNames() {
    return {
      finAiAgent: this.intl.t('reporting.overview.charts.funnel.sankey.legend.finAiAgent'),
      chatbot: this.intl.t('reporting.overview.charts.funnel.sankey.legend.chatbot'),
      teammate: this.intl.t('reporting.overview.charts.funnel.sankey.legend.teammate'),
      closedConversations: this.intl.t(
        'reporting.overview.charts.funnel.sankey.legend.closedConversations',
      ),
      inProgressConversations: this.intl.t(
        'reporting.overview.charts.funnel.sankey.legend.inProgressConversations',
      ),
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::Overview::ConversationFunnel': typeof ConversationFunnel;
    'reporting/bespoke/overview/conversation-funnel': typeof ConversationFunnel;
  }
}
