/* import __COLOCATED_TEMPLATE__ from './platform-selector.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type Model from '@ember-data/model';

export type PlatformId = 'web' | 'mobile';

interface Args {
  messengerSettings: Model & {
    lookAndFeel: Model & { activeBrand: Model; defaultBrand: Model; mobileBrand: Model };
  };
  onSelectPlatform: (platform: PlatformId) => void;
  selectedPlatform: PlatformId;
}

interface Platform {
  id: PlatformId;
  icon: InterfaceIconName;
  label: string;
}

export default class PlatformSelector extends Component<Args> {
  @service declare messengerSettingsService: MessengerSettingsService;

  get platforms(): Array<Platform> {
    return [
      {
        id: 'web',
        icon: 'computer',
        label: 'standalone.intercom-messenger.setup.platform-selector.web',
      },
      {
        id: 'mobile',
        icon: 'mobile',
        label: 'standalone.intercom-messenger.setup.platform-selector.mobile',
      },
    ];
  }

  get messengerSettingsTargetUserProvider() {
    return this.messengerSettingsService.messengerSettingsTargetUserProvider;
  }

  @action
  selectPlatform(platform: PlatformId) {
    let lookAndFeel = this.args.messengerSettings.lookAndFeel;
    if (platform === 'mobile') {
      // Based on app/routes/apps/app/messenger/mobile-sdk.js
      this.messengerSettingsTargetUserProvider.setMobileTarget();
      this.messengerSettingsService.preview.onPlatformChange('ios');
      lookAndFeel.activeBrand = lookAndFeel.mobileBrand;
    } else {
      // Based on app/routes/apps/app/messenger/web.js
      this.messengerSettingsTargetUserProvider.setVisitorTarget();
      this.messengerSettingsService.preview.onPlatformChange('web');
      lookAndFeel.activeBrand = lookAndFeel.defaultBrand;
    }
    this.args.onSelectPlatform(platform);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup::MessengerSettings::PlatformSelector': typeof PlatformSelector;
    'standalone/intercom-messenger/setup/messenger-settings/platform-selector': typeof PlatformSelector;
  }
}
