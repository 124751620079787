/* import __COLOCATED_TEMPLATE__ from './code-snippet-options.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { INSTALLATION_TYPE } from 'embercom/components/installation-new/constants';

interface Args {
  selectedAppType: string;
  onSelectAppType: (appType: string) => void;
  selectedProgrammingLanguage: string;
  onSelectProgrammingLanguage: (language: string) => void;
  selectedInstallationType: INSTALLATION_TYPE;
  onSelectInstallationType: (installationType: INSTALLATION_TYPE) => void;
  disabled: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class CodeSnippetOptions extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;

  constructor(owner: never, args: Args) {
    super(owner, args);
  }

  get installationTypes() {
    return [
      {
        id: INSTALLATION_TYPE.LOGGED_IN_USERS,
        title: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.logged-in-users.title',
        ),
        subTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.logged-in-users.sub-title',
        ),
        recommended: true,
      },
      {
        id: INSTALLATION_TYPE.VISITORS,
        title: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.visitors.title',
        ),
        subTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-type.visitors.sub-title',
        ),
      },
    ];
  }

  get programmingLanguages() {
    return [
      { text: 'Node.js', value: 'javascript' },
      { text: 'Rails (Ruby)', value: 'rails' },
      { text: 'Django (Python 3)', value: 'django' },
      { text: 'PHP', value: 'php' },
    ];
  }

  get appTypes() {
    let setupOptions = [
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.npm',
        ),
        value: 'npm',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.basic-javascript',
        ),
        value: 'basic-javascript',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.single-page-app',
        ),
        value: 'spa',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.rails-gem',
        ),
        value: 'rails-gem',
      },
    ];
    return setupOptions;
  }

  get hideProgrammingLanguages() {
    return (
      this.args.selectedAppType === 'npm' ||
      this.args.selectedAppType === 'rails-gem' ||
      this.args.selectedInstallationType === INSTALLATION_TYPE.VISITORS
    );
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::CodeSnippetOptions': typeof CodeSnippetOptions;
    'installation-new/web/initial-setup/code-snippet-options': typeof CodeSnippetOptions;
  }
}
