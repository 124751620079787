/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Validations from 'embercom/validations/custom-authentication/refresh-token-configuration';
import { tracked } from '@glimmer/tracking';

export default class RefreshTokenConfiguration extends Fragment.extend(Validations) {
  @attr('string', { defaultValue: 'get' }) httpMethod;
  @attr('string') url;
  @attr('string', { defaultValue: '' }) body;
  @attr('string') tokenResponsePath;
  @fragmentArray('custom-authentication/refresh-token-configuration-header', {
    defaultValue: () => [],
  })
  headers;

  @tracked mostRecentTestPassed = false;
  @tracked latestTestRequestParams = undefined;

  get sufficientlyTested() {
    if (this.latestTestRequestParams === undefined) {
      return false;
    }

    if (this.mostRecentTestPassed === false) {
      return false;
    }

    return this._testSinceLastEdit();
  }

  _testSinceLastEdit() {
    return this._configsMatch(this, this.latestTestRequestParams);
  }

  _configsMatch(currentConfig, testedConfig) {
    return (
      currentConfig.httpMethod === testedConfig.httpMethod &&
      currentConfig.url === testedConfig.url &&
      currentConfig.body === testedConfig.body &&
      currentConfig.headers === testedConfig.headers
    );
  }
}
