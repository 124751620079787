/* import __COLOCATED_TEMPLATE__ from './custom-answers-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class CustomAnswersBanner extends Component {
  @service navbarCollapsingService;

  @action
  toggleSidebar() {
    this.navbarCollapsingService.toggleNavbarCollapsed();
    let focusIsOnToggleButton = Boolean(
      document.activeElement?.closest('[data-submenu-collapse-toggle-button]'),
    );
    // we don't want the sidebar button to stay focused after click
    if (focusIsOnToggleButton) {
      document.activeElement?.blur();
    }
  }

  get newHeroBannerActions() {
    return [
      {
        type: 'link',
        label: 'operator.resolution-bot-answers.new-banner.actions.article-create-answer',
        icon: 'article',
        analyticsId: 'create-answer-button-article',
        onClick: () => {
          window.Intercom('showArticle', 7860253); // https://www.intercom.com/help/en/articles/7860253-creating-custom-answers
        },
      },
      {
        type: 'link',
        label:
          'operator.resolution-bot-answers.new-banner.actions.video-build-answers-for-fin-ai-agent',
        icon: 'video',
        analyticsId: 'build-answers-for-fin-ai-agent-video',
        onClick: () => {
          safeWindowOpen('https://www.youtube.com/watch?v=CtqeVXyEaLQ', '_blank');
        },
      },
      {
        type: 'link',
        label:
          'operator.resolution-bot-answers.new-banner.actions.article-custom-answers-reporting',
        icon: 'article',
        analyticsId: 'custom-answers-reporting-article',
        onClick: () => {
          window.Intercom('showArticle', 8194342); // https://www.intercom.com/help/en/articles/8194342-custom-answers-reporting
        },
      },
      {
        type: 'link',
        label:
          'operator.resolution-bot-answers.new-banner.actions.article-custom-answers-best-practices',
        icon: 'article',
        analyticsId: 'custom-answers-best-practices-article',
        onClick: () => {
          window.Intercom('showArticle', 8194457); // https://www.intercom.com/help/en/articles/8194457-custom-answers-best-practices
        },
      },
    ];
  }

  get learnDropdownList() {
    return [
      {
        items: [
          {
            text: this.openVideoModal,
            component: 'operator/fin/learn-dropdown',
            componentShouldReplaceItem: true,
          },
        ],
      },
    ];
  }
}
