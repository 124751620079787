/* import __COLOCATED_TEMPLATE__ from './in-subscription-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Charge from 'embercom/models/billing/price/charge';
import type Contract from 'embercom/models/billing/contract';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { formatPriceFromCents } from 'embercom/components/billing/usage/tooltips/format-price';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips/tooltip-item';

interface Args {
  testIdentifier: string;
  charge: Charge;
  contract?: Contract;
  pricingMetric: PricingMetric;
  includedUsage: number;
}

export default class InSubscriptionDetails extends Component<Args> {
  @service declare intl: IntlService;

  get tooltipRows(): TooltipRow[] {
    return [
      {
        label: this.intl.t(
          'billing.summary.price-usage-breakdown-card.overage-tooltip.in-your-subscription',
        ),
        muted: true,
        noPadding: true,
      },
      {
        label: this.formattedContractedUsage,
        value: formatPriceFromCents(this.inYourSubscriptionPrice(this.args.charge), this.intl),
        noPadding: true,
      },
      ...this.displayDiscountDetails(this.args.charge),
    ];
  }

  get formattedContractedUsage() {
    if (!this.args.charge.isValidUsageMetric) {
      return;
    }
    return this.intl.formatNumber(this.args.includedUsage);
  }

  inYourSubscriptionPrice(charge: Charge): number {
    let { pricingMetric } = this.args;

    if (
      pricingMetric.metric === Metric.messages_sent &&
      charge.first_tier_price !== null &&
      charge.first_tier_price !== undefined
    ) {
      return charge.base_price - charge.first_tier_price * 100;
    }
    return charge.base_price;
  }

  displayDiscountDetails(charge: Charge) {
    return this.displayDiscountRow(charge)
      ? [
          {
            label: this.intl.t(
              'billing.summary.price-usage-breakdown-card.overage-tooltip.discount',
            ),
            muted: true,
            small: true,
            value: '-'.concat(
              formatPriceFromCents(charge.discount_item?.amount_in_cents || 0, this.intl),
            ),
          },
        ]
      : [];
  }

  displayDiscountRow(charge: Charge) {
    return this.args.contract && charge.discount_item && charge.discount_item.amount_in_cents > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::Tooltips::InSubscriptionDetails': typeof InSubscriptionDetails;
  }
}
