/* import __COLOCATED_TEMPLATE__ from './fin-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { trackedFunction } from 'ember-resources/util/function';
import {
  CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  isAIAgentEntityType,
  isAICopilotEntityType,
} from 'embercom/lib/knowledge-hub/constants';
import { action } from '@ember/object';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { EntityType } from 'embercom/models/data/entity-types';
import type { LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

interface Args {
  activeContent: LocalizedKnowledgeContent;
  setCopilotAvailability?: (available: boolean) => any;
  setChatbotAvailability?: (available: boolean) => any;
}

const ARTICLE_LINK =
  'https://www.intercom.com/help/en/articles/9459957-enable-or-disable-content-for-fin-ai-agent-and-ai-copilot';

export default class FinSection extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare intl: IntlService;

  get sectionDescription() {
    if (
      isAICopilotEntityType(this.args.activeContent.entityType) &&
      !isAIAgentEntityType(this.args.activeContent.entityType)
    ) {
      return this.intl.t(
        'knowledge-hub.content-editor.shared.audience-section.fin-audience-description.copilot',
      );
    }
    // Show copy that applies to both Fin AI Agent and AI Copilot
    return this.intl.t(
      'knowledge-hub.content-editor.shared.audience-section.fin-audience-description.all',
    );
  }

  @action trackSegmentChangeAnalytics() {
    this.knowledgeHubEditorService.trackAnalyticsEvent('updated_fin_audience');
  }

  isEligibleForCopilot = trackedFunction(this, async () => {
    return this.args.activeContent.isEligibleForCopilot();
  });

  isEligibleForChatbot = trackedFunction(this, async () => {
    return this.args.activeContent.isEligibleForChatbot();
  });

  get isEnabledForChatbot() {
    return this.args.activeContent?.isEligibleAndAvailableToChatbot.value ?? false;
  }

  get isEnabledForCopilot() {
    return this.args.activeContent?.isEligibleAndAvailableToCopilot.value ?? false;
  }

  get isDisabledForChatbot() {
    return !this.isEligibleForChatbot.value ?? true;
  }

  get isDisabledForCopilot() {
    return !this.isEligibleForCopilot.value ?? true;
  }

  get chatbotStampText() {
    return this.isEnabledForChatbot
      ? this.intl.t('knowledge-hub.content-editor.shared.visibility-section.enabled')
      : this.intl.t('knowledge-hub.content-editor.shared.visibility-section.disabled');
  }

  get copilotStampText() {
    return this.isEnabledForCopilot
      ? this.intl.t('knowledge-hub.content-editor.shared.visibility-section.enabled')
      : this.intl.t('knowledge-hub.content-editor.shared.visibility-section.disabled');
  }

  get isInternalArticle() {
    return this.args.activeContent.entityType === EntityType.InternalArticle;
  }

  get isArticleContent() {
    return this.args.activeContent.entityType === EntityType.ArticleContent;
  }

  get chatbotStampColor(): 'sky' | 'gray' {
    return this.isEnabledForChatbot ? 'sky' : 'gray';
  }

  get copilotStampColor(): 'sky' | 'gray' {
    return this.isEnabledForCopilot ? 'sky' : 'gray';
  }

  get showTooltipForChatbot() {
    return this.isArticleContent || this.isInternalArticle;
  }

  get showTooltipForCopilot() {
    return this.isArticleContent;
  }

  get chatbotTooltipContent() {
    if (this.isInternalArticle) {
      return this.intl.t(
        'knowledge-hub.content-editor.shared.visibility-section.chatbot-disabled-for-internal-article',
      );
    } else {
      return this.intl.t('knowledge-hub.content-editor.shared.visibility-section.toggle-tooltip', {
        link: ARTICLE_LINK,
        htmlSafe: true,
      });
    }
  }

  get copilotTooltipContent() {
    return this.intl.t('knowledge-hub.content-editor.shared.visibility-section.toggle-tooltip', {
      link: ARTICLE_LINK,
      htmlSafe: true,
    });
  }

  async checkPermissionWithPopup() {
    await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
  }

  @action
  async toggleChatbotVisibility() {
    try {
      await this.checkPermissionWithPopup();
    } catch (e) {
      return;
    }

    try {
      this.knowledgeHubEditorService.trackAnalyticsEvent('toggled_chatbot_visibility');
      this.knowledgeHubEditorService.trackAnalyticsEvent(
        this.isEnabledForChatbot ? 'disabled_chatbot_availability' : 'enabled_chatbot_availability',
      );
      await this.args.activeContent.toggleChatbotAvailability();
      await this.knowledgeHubEditorService.saveAndEditActiveContent();
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.content-editor.shared.visibility-section.chatbot-error'),
      );
    }
  }

  @action
  async toggleCopilotVisibility() {
    try {
      await this.checkPermissionWithPopup();
    } catch (e) {
      return;
    }

    try {
      this.knowledgeHubEditorService.trackAnalyticsEvent('toggled_copilot_visibility');
      this.knowledgeHubEditorService.trackAnalyticsEvent(
        this.isEnabledForCopilot ? 'disabled_copilot_availability' : 'enabled_copilot_availability',
      );
      await this.args.activeContent.toggleCopilotAvailability();
      await this.knowledgeHubEditorService.saveAndEditActiveContent();
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.content-editor.shared.visibility-section.copilot-error'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::FinSection': typeof FinSection;
    'knowledge-hub/content-editor/shared/fin-section': typeof FinSection;
  }
}
