/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
const SECURITY_CERTIFICATES = [
  { name: 'CCPA', value: 'ccpa' },
  { name: 'GDPR', value: 'gdpr' },
  { name: 'HIPAA', value: 'hipaa' },
  { name: 'ISO27001', value: 'iso27001' },
  { name: 'SOC2', value: 'soc2' },
  { name: 'PCI', value: 'pci' },
  { name: 'COPPA', value: 'coppa' },
  { name: 'Privacy Shield', value: 'privacy_shield' },
];

const CUSTOMERS_PRIMARILY_SERVED = [
  { name: 'Customer Support', value: 'customer_support' },
  { name: 'Customer Engagement', value: 'customer_engagement' },
  { name: 'Marketing', value: 'marketing' },
  { name: 'Sales', value: 'sales' },
  { name: 'Product Analytics', value: 'product_analytics' },
];

const TEAM_SIZE_OPTIONS = [
  { name: '0', value: '0' },
  { name: '1-10', value: '1-10' },
  { name: '11-49', value: '11-49' },
  { name: '50-99', value: '50-99' },
  { name: '100+', value: '100+' },
];

const CUSTOMERS_SIZE_OPTIONS = [
  { name: '1-9', value: '1-9' },
  { name: '10-99', value: '10-99' },
  { name: '100-499', value: '100-499' },
  { name: '500-999', value: '500-999' },
  { name: '1000+', value: '1000+' },
];

const TIER = [
  { name: 'Not an App Partner', value: 'not_a_partner' },
  { name: 'App Partner', value: 'app_partner_basic' },
  { name: 'App Partner Plus', value: 'app_partner_plus' },
  { name: 'Premier App Partner', value: 'app_partner_premier' },
];

const getTeamSizeOptions = () => {
  return TEAM_SIZE_OPTIONS;
};

const getCustomersSizeOptions = () => {
  return CUSTOMERS_SIZE_OPTIONS;
};

const getSecurityCertificates = () => {
  return SECURITY_CERTIFICATES;
};

const getCustomersPrimarilyServed = () => {
  return CUSTOMERS_PRIMARILY_SERVED;
};

const getAppPartnerOptionsData = (key, area) => {
  switch (area) {
    case 'securityCertificates':
      return SECURITY_CERTIFICATES.find((option) => option.value === key);
    case 'customersPrimarilyServed':
      return CUSTOMERS_PRIMARILY_SERVED.find((option) => option.value === key);
  }
};

const getHumanReadableTier = (key) => {
  let tier = TIER.find((tier) => tier.value === key);
  return tier !== null ? tier.name : '';
};

export {
  getTeamSizeOptions,
  getCustomersSizeOptions,
  getSecurityCertificates,
  getCustomersPrimarilyServed,
  getAppPartnerOptionsData,
  getHumanReadableTier,
};
