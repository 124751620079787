/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo } from '@ember-data/model';
import { fragmentArray, fragment } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';
import { type ContentObject } from './content-object';
import type Metadata from './outbound/metadata';
import { FIN_WORKFLOWS } from 'embercom/lib/operator/custom-bots/constants';

export default class Template extends Model {
  @attr('string') declare title: string;
  @attr('string') declare description: string;
  @attr('array') declare contentObjects: ContentObject[];
  @fragmentArray('common/predicate') declare legacyPredicates: any[];
  @belongsTo('matching-system/ruleset', { async: false }) declare ruleset: any;
  @attr('string') declare thumbnail: string;
  @attr('string') declare thumbnailAsset: string;
  @attr('string') declare preview: string;
  @attr('number') declare objective: number;
  @fragment('outbound/metadata') declare metadata: Metadata;

  @service declare appService: any;

  get objectType() {
    return this.contentObjects.firstObject.type;
  }

  get isAvailable() {
    return true;
  }

  get replyType() {
    return this.contentObjects.firstObject.reply_type;
  }

  get reactions() {
    return this.contentObjects.firstObject.reactions;
  }

  get isFinTemplate() {
    return this.objective === FIN_WORKFLOWS.id;
  }
}
