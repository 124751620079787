/* import __COLOCATED_TEMPLATE__ from './invite-developer-modal.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { post } from 'embercom/lib/ajax';
import { PRICING_5_X_CORE_ESSENTIAL_ID } from 'embercom/lib/billing';
import { isValidEmail } from 'embercom/lib/email';
import { LIGHT_SEAT_TYPE_FEATURE_KEY } from 'embercom/lib/settings/seats/constants';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Plan from 'embercom/models/plan';
import type QuoteService from 'embercom/services/quote-service';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';

const GET_QUOTE_PARAMS = [
  {
    ...DEFAULT_REQUEST_PARAMS,
    planIds: [Number(PRICING_5_X_CORE_ESSENTIAL_ID)],
    source: 'invite-developer-card-component',
  },
];
interface Signature {
  Args: {
    onModalClose: () => void;
  };
  Element: never;
}

export default class InviteDeveloperModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare quoteService: QuoteService;
  @service declare intercomEventService: $TSFixMe;

  defaultEmailErrorMessagePath =
    'onboarding.home.developer-invite.invite-modal-email-validation-error-bad-syntax';
  @tracked emailToInvite = '';
  @tracked emailInvalid = true;
  @tracked emailErrorMessagePath = this.defaultEmailErrorMessagePath;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.loadInvitedAdmins();

    // Pricing information is only displayed under these conditions
    // No need to load the prices if not in use
    if (this.isEssentialAndBillable) {
      taskFor(this.quoteService.getQuotes).perform(GET_QUOTE_PARAMS);
    }
  }

  @action onEmailChange(event: KeyboardEvent) {
    let adminEmails = new Set(this.app.admins.map((admin: any) => admin.email));
    let alreadyInvitedTeammateEmails = new Set(
      this.store
        .peekAll('invited-admin')
        .filter((invite: any) => invite.active)
        .map((invite: any) => invite.email),
    );

    let target = event.target as HTMLInputElement;
    this.emailToInvite = target.value.trim();

    let invalidEmailFormat = !isValidEmail(this.emailToInvite);
    let emailBelongsToTeammate = adminEmails.has(this.emailToInvite);
    let emailAlreadyInvited = alreadyInvitedTeammateEmails.has(this.emailToInvite);

    if (invalidEmailFormat) {
      this.emailErrorMessagePath = this.defaultEmailErrorMessagePath;
    } else if (emailBelongsToTeammate) {
      this.emailErrorMessagePath =
        'onboarding.home.developer-invite.invite-modal-email-validation-error-email-in-use';
    } else if (emailAlreadyInvited) {
      this.emailErrorMessagePath =
        'onboarding.home.developer-invite.invite-modal-email-validation-error-email-invited';
    }

    this.emailInvalid = invalidEmailFormat || emailBelongsToTeammate || emailAlreadyInvited;
  }

  async loadInvitedAdmins() {
    await this.store.findAll('invited-admin');
  }

  get app() {
    return this.appService.app;
  }

  get inviteDisabled() {
    return (
      this.emailInvalid ||
      this.inviteRunning ||
      !this.app.invitingPermitted ||
      (this.isEssentialAndBillable && this.getQuotesRunning)
    );
  }

  get inviteRunning() {
    return taskFor(this.inviteDeveloper).isRunning;
  }

  get getQuotesRunning() {
    return this.quoteService.loading;
  }

  get isEssentialAndBillable() {
    let essentialPlan: Plan = this.store.peekRecord('plan', PRICING_5_X_CORE_ESSENTIAL_ID);

    return essentialPlan.billableCustomerPlan;
  }

  get formattedPrice() {
    let quote = this.quoteService.getQuoteById([Number(PRICING_5_X_CORE_ESSENTIAL_ID)]);

    let price = quote?.fullSeatPrice(Number(PRICING_5_X_CORE_ESSENTIAL_ID), Metric.core_seat_count);

    if (!price) {
      return '';
    }

    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  @action async performInvite() {
    try {
      await taskFor(this.inviteDeveloper).perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('onboarding.home.developer-invite.success', { email: this.emailToInvite }),
      );
      this.emailToInvite = '';

      this.args.onModalClose();

      await this.loadInvitedAdmins();

      // track dev invite only after successful invite
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'invite_eng_button',
      });
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('onboarding.home.developer-invite.error'));
    }
  }

  @task({ drop: true }) *inviteDeveloper() {
    let permissions = this.createPermissionsObject();

    let invitees = [
      {
        email: this.emailToInvite,
        app_id: this.app.id,
        seat_types: [this.app.canUseFeature(LIGHT_SEAT_TYPE_FEATURE_KEY) ? 'light' : 'core'],
        for_messenger_installation: true,
        ...permissions,
      },
    ];

    yield post('/ember/invite_admin_groups', {
      invitees,
      app_id: this.appService.app.id,
    });
  }

  private createPermissionsObject() {
    let permissionsObject = this.store.createRecord('permission', {
      app_id: this.app.id,
    });

    permissionsObject.setAllPermissions(false);

    permissionsObject.accessType = 'only_them';
    permissionsObject.can_access_product_settings = true;
    permissionsObject.can_access_workspace_settings = true;
    permissionsObject.can_manage_messenger_settings = true;

    return permissionsObject.serialize();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::Home::InviteDeveloperModal': typeof InviteDeveloperModal;
  }
}
