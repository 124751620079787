/* import __COLOCATED_TEMPLATE__ from './empty-state-banner-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  containerClass: string;
  dismissalKey: string;
  paywallFeatureKey?: string;
  bodyContainerClass?: string;
  title?: string;
  description?: string;
  onPrimaryCtaClick?: () => void;
  primaryCtaLabel?: string;
  primaryCtaIcon?: InterfaceIconName;
  hideDismissButton?: boolean;
  imageSrc: string;
}

interface Signature {
  Element: any;
  Args: Args;
  Blocks: {
    extras: [];
  };
}

const EmptyStateBannerWrapper = templateOnlyComponent<Signature>();
export default EmptyStateBannerWrapper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GettingStarted::Common::EmptyStateBannerWrapper': typeof EmptyStateBannerWrapper;
    'onboarding/getting-started/common/empty-state-banner-wrapper': typeof EmptyStateBannerWrapper;
  }
}
