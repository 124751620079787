/* import __COLOCATED_TEMPLATE__ from './popover-menu-item.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { type InserterMenuItemType } from 'embercom/objects/visual-builder/configuration/inserter-menu-items-map';
import {
  CHANNEL_METADATA_MAPPING,
  type AllChannels,
} from 'embercom/lib/operator/custom-bots/constants';

interface Args {
  item: InserterMenuItemType;
  shouldShowStepPaywalls?: boolean;
  onSelectItem: (value: InserterMenuItemType['value'], groupId?: string) => void;
  groupId?: string;
  disabled?: boolean;
  unsupportedSelectedChannels?: string[];
}

export default class PopoverMenuItem extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get requiredBillingFeatureForItem() {
    if (typeof this.args.item.value !== 'string') {
      return this.args.item.value.ModelClass?.requiredBillingFeature;
    }

    return false;
  }

  get shouldShowPaywallIcon() {
    return (
      this.args.shouldShowStepPaywalls &&
      this.requiredBillingFeatureForItem &&
      !this.appService.app.canUseFeature(this.requiredBillingFeatureForItem)
    );
  }

  get canUseWorkflowsSetup() {
    return this.appService.app.canUseWorkflowsSetup;
  }

  get itemDisabled() {
    return this.args.disabled;
  }

  transformChannelName(channel: AllChannels) {
    if (CHANNEL_METADATA_MAPPING[channel]) {
      return CHANNEL_METADATA_MAPPING[channel].channelTypeValue;
    }
    return channel.charAt(0).toUpperCase() + channel.slice(1);
  }

  get tooltipText() {
    if (this.args.unsupportedSelectedChannels?.length) {
      if (this.args.unsupportedSelectedChannels.length === 1) {
        return this.intl.t(
          'operator.workflows.visual-builder.step-menu.action-not-available-single-channel',
          {
            channel: this.transformChannelName(
              this.args.unsupportedSelectedChannels[0] as AllChannels,
            ),
          },
        );
      }

      if (this.args.unsupportedSelectedChannels.length > 1) {
        let totalChannels = this.args.unsupportedSelectedChannels.length;
        let channels = this.args.unsupportedSelectedChannels.reduce((prev, curr, index) => {
          let channel = this.transformChannelName(curr as AllChannels);
          if (index === totalChannels - 1) {
            return `${prev} ${this.intl.t('operator.workflows.visual-builder.step-menu.and')} ${channel}`;
          }
          if (!prev) {
            return channel;
          }
          return `${prev}, ${channel}`;
        }, '');

        return this.intl.t(
          'operator.workflows.visual-builder.step-menu.action-not-available-multiple-channels',
          {
            channels,
          },
        );
      }
    }

    return undefined;
  }

  get disableTooltip() {
    return !this.tooltipText;
  }

  @action
  handleClick() {
    if (!this.itemDisabled) {
      this.args.onSelectItem(this.args.item.value, this.args.groupId);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PopoverMenu::PopoverMenuItem': typeof PopoverMenuItem;
  }
}
