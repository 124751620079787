/* import __COLOCATED_TEMPLATE__ from './phone-number-porting.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
// eslint-disable-next-line @intercom/intercom/max-file-length
import type Store from '@ember-data/store';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Task, type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { ref } from 'ember-ref-bucket';
import ajax, { get, post } from 'embercom/lib/ajax';
import {
  PhoneNumberType,
  PHONE_NUMBER_COUNTRY_DETAILS,
  type PhoneNumber,
  COUNTRIES_LIST,
  countryNeedsBundleForNumberType,
  countryNeedAddressForNumberType,
  countrySupportsNumberType,
} from 'embercom/models/settings/calling';
import type RegulatoryAddress from 'embercom/models/settings/calling/regulatory_address';
import type IntlService from 'embercom/services/intl';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment-timezone';
import { SURVEY_ID } from './phone-number-provisioning';
import { type SuggestedAddress } from './phone-number-provisioning/regulatory-address-section';
import { type Stamp } from './phone-regulatory-bundle';
import type IntercomCallService from 'embercom/services/intercom-call-service';

export enum PortingRequestSteps {
  Disclaimer = 'disclaimer',
  PortingForm = 'porting-form',
  NextSteps = 'next-steps',
  CheckPortability = 'check-portability',
  AutomaticPortingForm = 'automatic-porting-form',
}

interface Args {
  activateProvisioningModal: () => any;
  peekPhoneNumbersTask: Task<Array<PhoneNumber>, any>;
  isNumberProvisioningPrimary: boolean;
}

interface Signature {
  Args: Args;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png'];

export default class PhoneNumberPorting extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare router: any;
  @service declare store: Store;
  @service declare intercomCallService: IntercomCallService;

  @tracked showPortingModal = false;
  @tracked currentStep = PortingRequestSteps.Disclaimer;
  @tracked selectedCountry?: string;
  @tracked phoneNumber?: string;
  @tracked currentProvider?: string;
  @tracked selectedPortingDate?: string = 'asap';
  @tracked selectedDate: Date = new Date();
  @tracked selectedPhoneNumberType: PhoneNumberType = PhoneNumberType.Local;
  @tracked ticketId?: string;
  @tracked selectedRegulatoryBundle?: string;
  @tracked regulatoryAddressSid?: string;
  @tracked regulatoryAddressValidationError?: string;
  @tracked regulatoryAddressSuggestedFields?: SuggestedAddress;
  @tracked newRegulatoryAddress?: RegulatoryAddress;
  @tracked isPortableByAPI?: boolean;
  @tracked selectedFile?: File | null;
  @tracked supportingDocumentSid?: string;
  @tracked customerName = '';
  @tracked accountNumber?: string;
  @tracked street?: string;
  @tracked streetSecondary?: string;
  @tracked city?: string;
  @tracked region?: string;
  @tracked postalCode?: string;
  @tracked accountTelephoneNumber?: string;
  @tracked pin?: string;
  @tracked authorizedRepresentative = '';
  @tracked authorizedRepresentativeEmail = '';
  @ref('inputElement') inputElement: any;

  today = moment();

  get countriesList() {
    return COUNTRIES_LIST.map((countryCode: string) => ({
      text: this.intl.t(`calling.settings.phone-calls.countries.${countryCode.toUpperCase()}`),
      value: countryCode,
    }));
  }

  get countryCodePrefix() {
    return PHONE_NUMBER_COUNTRY_DETAILS.get(this.selectedCountry!)?.prefix;
  }

  get phoneNumberTypesSupportedBySelectedCountry() {
    if (!this.selectedCountry) {
      return [];
    }

    return PHONE_NUMBER_COUNTRY_DETAILS.get(this.selectedCountry)?.supportedNumberTypes;
  }

  get isPortingRequestFormValid() {
    if (this.selectedCountry === undefined || this.currentProvider === undefined) {
      return false;
    }

    if (!this.isPhoneNumberValid) {
      return false;
    }

    if (this.isRegulatoryAddressRequired) {
      if (!this.regulatoryAddressSid && !this.newRegulatoryAddress?.validations.isValid) {
        return false;
      }
    }

    if (this.needsRegulatoryBundle && !this.selectedRegulatoryBundle) {
      return false;
    }

    return true;
  }

  get isPhoneNumberValid() {
    return parsePhoneNumberFromString(this.getCleanedNumber)?.isValid() || false;
  }

  get disableCheckPortabilityRequest() {
    return !this.isPhoneNumberValid;
  }

  get disableCreatePortingRequest() {
    return !this.isPortingRequestFormValid;
  }

  get isLosingCarrierInfoValid() {
    return this.customerName && this.authorizedRepresentative && this.authorizedRepresentativeEmail;
  }

  get isLosingCarrierAddressValid() {
    return this.street && this.city && this.region && this.postalCode;
  }

  get disableSubmitPortingRequest() {
    return (
      !this.isPortingRequestFormValid ||
      !this.supportingDocumentSid ||
      !this.isLosingCarrierInfoValid ||
      !this.isLosingCarrierAddressValid
    );
  }

  get portingDateString() {
    return this.selectedPortingDate === 'asap' ? 'asap' : this.selectedDate.toISOString();
  }

  get getCleanedNumber() {
    let number = `${this.countryCodePrefix}${this.phoneNumber}`;
    let cleanedNumber = number.replace(/\D/g, '');
    return `+${cleanedNumber}`;
  }

  @action async changeDate(data: any) {
    this.selectedDate = data.date;
  }

  @action async onSelectCountry(countryIsoCode: string) {
    this.selectedCountry = countryIsoCode;
    this.onSelectPhoneType();
  }

  @action async onSelectPhoneType(phoneType?: PhoneNumberType) {
    this.resetAll();
    if (phoneType !== undefined) {
      this.selectedPhoneNumberType = phoneType;
    }
  }

  @action selectRegulatoryBundle(regulatoryBundleSid: string) {
    this.selectedRegulatoryBundle = regulatoryBundleSid;
  }

  @action openPortingModal() {
    this.showPortingModal = true;
    this.intercomCallService.registerCallEvent({
      action: 'clicked',
      object: 'phone_number_porting',
      place: 'calling_settings',
      section: 'settings',
    });
  }

  @action closePortingModal() {
    this.resetAll();
    this.currentStep = PortingRequestSteps.Disclaimer;
    this.selectedCountry = undefined;
    this.showPortingModal = false;
  }

  @action openPortingTicket() {
    if (this.ticketId) {
      window.Intercom('showTicket', this.ticketId);
    }
  }

  @action closeAndProvisionPhoneNumber() {
    this.args.activateProvisioningModal();
    this.closePortingModal();
  }

  @action continueToPortingForm() {
    if (this.appService.app.canUseFeature('automatic-phone-porting')) {
      this.currentStep = PortingRequestSteps.CheckPortability;
    } else {
      this.currentStep = PortingRequestSteps.PortingForm;
    }
  }

  @action continueToCheckPortability() {
    this.currentStep = PortingRequestSteps.CheckPortability;
  }

  @task({ restartable: true })
  *createPortingRequest(): TaskGenerator<void> {
    try {
      let requestData: any = {
        app_id: this.appService.app.id,
        phone_number: this.getCleanedNumber,
        current_provider: this.currentProvider,
        porting_date: this.portingDateString,
        phone_type: this.selectedPhoneNumberType,
        country_iso_code: this.selectedCountry,
      };
      if (this.isRegulatoryAddressRequired) {
        if (this.regulatoryAddressSid) {
          requestData = {
            ...requestData,
            regulatory_address_sid: this.regulatoryAddressSid,
          };
        } else {
          requestData = {
            ...requestData,
            new_regulatory_address: this.newRegulatoryAddress!.serialize(),
          };
        }
      }

      if (this.needsRegulatoryBundle) {
        requestData = {
          ...requestData,
          regulatory_bundle_sid: this.selectedRegulatoryBundle,
        };
      }

      let response = yield post('/ember/calling_phone_numbers/create_porting_request', requestData);
      this.ticketId = response['porting_ticket_id'];
      this.store.pushPayload({ 'calling-phone-number': response });
      this.currentStep = PortingRequestSteps.NextSteps;
      this.args.peekPhoneNumbersTask.perform();
    } catch (response) {
      if (
        response.jqXHR.status === 422 &&
        response.jqXHR.responseJSON.errors.address_validation_error
      ) {
        this.regulatoryAddressValidationError =
          response.jqXHR.responseJSON.errors.address_validation_error;
        this.regulatoryAddressSuggestedFields =
          response.jqXHR.responseJSON.errors.suggested_address;
      } else {
        this.notificationsService.notifyError(response.jqXHR.responseJSON.errors);
      }
    }
  }

  @task({ restartable: true })
  *checkPortabilityRequest(): TaskGenerator<void> {
    try {
      let requestData: any = {
        app_id: this.appService.app.id,
        phone_number: this.getCleanedNumber,
      };
      let response = yield get('/ember/calling_phone_numbers/check_portability', requestData);
      this.isPortableByAPI = response['portability'];
      if (this.isPortableByAPI) {
        this.currentStep = PortingRequestSteps.AutomaticPortingForm;
      } else {
        this.currentStep = PortingRequestSteps.PortingForm;
      }
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.errors);
    }
  }

  @task({ restartable: true })
  *createSupportingDocument(file: File): TaskGenerator<void> {
    let formData = new FormData();
    formData.append('app_id', this.appService.app.id);
    formData.append('file', file);
    try {
      let response: { supporting_document_sid: string } = yield ajax({
        type: 'POST',
        url: '/ember/calling_phone_numbers/upload_document_for_porting',
        data: formData,
        processData: false,
        contentType: false,
      });
      if (!response.supporting_document_sid) {
        throw new Error('No supporting document sid returned');
      }
      this.supportingDocumentSid = response.supporting_document_sid;
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.phone-regulatory-bundle.error-uploading-supporting-document'),
      );
    }
  }

  @task({ restartable: true })
  *createAutomatedPortingRequest(): TaskGenerator<void> {
    try {
      let requestData: any = {
        app_id: this.appService.app.id,
        phone_number: this.getCleanedNumber,
        current_provider: this.currentProvider,
        porting_date: this.portingDateString,
        phone_type: this.selectedPhoneNumberType,
        country_iso_code: this.selectedCountry,
        document_sid: this.supportingDocumentSid,
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
        customer_type: 'Business',
        customer_name: this.customerName,
        account_number: this.accountNumber,
        account_telephone_number: this.accountTelephoneNumber,
        authorized_representative: this.authorizedRepresentative,
        authorized_representative_email: this.authorizedRepresentativeEmail,
        street: this.street,
        street_2: this.streetSecondary,
        city: this.city,
        state: this.region,
        zip: this.postalCode,
        country: this.selectedCountry,
        is_automatic_porting: true,
      };

      if (this.pin) {
        requestData['pin'] = this.pin;
      }

      yield post('/ember/calling_phone_numbers/create_porting_request', requestData);
      this.currentStep = PortingRequestSteps.NextSteps;
      this.args.peekPhoneNumbersTask.perform();
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.errors);
    }
  }

  @action
  showPhoneSurvey() {
    window.Intercom('startSurvey', SURVEY_ID);
  }

  @action
  pickFile() {
    this.clearFileInput();
    this.inputElement.click();
  }

  clearFileInput() {
    this.inputElement.value = '';
    this.selectedFile = null;
  }

  @action
  onFileSelect(file: File) {
    if (file.size > MAX_FILE_SIZE) {
      this.notificationsService.notifyError(
        `${this.intl.t(
          'calling.settings.phone-regulatory-bundle.file-size-error',
        )} ${this.humanReadableFileSize(MAX_FILE_SIZE)}`,
      );
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.phone-regulatory-bundle.file-type-error'),
      );
      return;
    }

    this.selectedFile = file;
    if (file) {
      taskFor(this.createSupportingDocument).perform(file);
    }
  }

  humanReadableFileSize(bytes: number) {
    if (bytes === 0) {
      return '';
    }

    let i = Math.floor(Math.log(bytes) / Math.log(1024));
    let sizes = ['B', 'KB', 'MB', 'GB'];
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  }

  @action
  onInputChange(event: any) {
    this.onFileSelect(event.target.files[0]);
  }

  get needsRegulatoryBundle() {
    return countryNeedsBundleForNumberType(this.selectedCountry!, this.selectedPhoneNumberType);
  }

  statusToStamp(status: string) {
    return {
      draft: {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.draft'),
        color: 'yellow-light',
      } as Stamp,
      'pending-review': {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.pending-review'),
        color: 'sky',
      } as Stamp,
      approved: {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.approved'),
        color: 'green',
      } as Stamp,
      rejected: {
        text: this.intl.t('calling.settings.phone-regulatory-bundle.rejected'),
        color: 'red',
      } as Stamp,
    }[status];
  }

  get isRegulatoryAddressRequired() {
    if (!this.selectedCountry) {
      return false;
    }

    return countryNeedAddressForNumberType(this.selectedCountry, this.selectedPhoneNumberType);
  }

  @action
  onAddressSelected(addressSid: string) {
    this.regulatoryAddressValidationError = undefined;
    this.regulatoryAddressSuggestedFields = undefined;
    this.regulatoryAddressSid = addressSid;
  }

  @action
  onAddressCreated(address: RegulatoryAddress) {
    this.regulatoryAddressValidationError = undefined;
    this.regulatoryAddressSuggestedFields = undefined;
    this.newRegulatoryAddress = address;
  }

  @action resetAll() {
    this.phoneNumber = undefined;
    this.currentProvider = undefined;
    this.selectedPortingDate = 'asap';
    if (!countrySupportsNumberType(this.selectedCountry!, this.selectedPhoneNumberType)) {
      this.selectedPhoneNumberType = PhoneNumberType.Local;
    }
    this.resetRegulatoryAddress();
  }

  @action resetRegulatoryAddress() {
    this.regulatoryAddressSid = undefined;
    set(this, 'regulatoryAddressSid', undefined);
    this.regulatoryAddressValidationError = undefined;
    this.regulatoryAddressSuggestedFields = undefined;
    if (this.newRegulatoryAddress && !this.newRegulatoryAddress.isDeleted) {
      this.newRegulatoryAddress.deleteRecord();
    }
    this.newRegulatoryAddress = undefined;
  }

  @action isNumberPortable() {
    if (this.selectedPhoneNumberType === PhoneNumberType.Local && this.selectedCountry === 'US') {
      taskFor(this.checkPortabilityRequest).perform();
    } else {
      this.currentStep = PortingRequestSteps.PortingForm;
    }
  }

  get allowed_file_types() {
    return ALLOWED_FILE_TYPES.join(',');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneNumberPorting': typeof PhoneNumberPorting;
    'calling/settings/phone-number-porting': typeof PhoneNumberPorting;
  }
}
