/* import __COLOCATED_TEMPLATE__ from './ticket-attributes.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type InboxApi from 'embercom/services/inbox-api';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { useResource } from 'ember-resources';
import { DescriptorUtils, type TicketType } from 'embercom/objects/inbox/ticket';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import ConditionalAttributesEvaluator from 'embercom/services/conditional-attributes-evaluator';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { getOwner } from '@ember/application';

interface Args {
  conversation: Conversation;
}

interface Signature {
  Args: Args;
}

export default class TicketAttributes extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare intl: IntlService;

  @trackedReset('args.conversation.id') ticketTypeId: number | undefined;
  @tracked ticketAttributes: TicketAttributeSummary[] | undefined;
  @tracked ticketTypes: TicketType[] | undefined;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadTicketTypes();
  }

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: this.args.conversation.ticketType?.id,
    conversation: this.args.conversation,
  }));

  get visibleTicketAttributeDescriptors() {
    if (this.session.workspace.canUseTicketsConditionalAttributes) {
      return this.conditionalTicketAttributes?.map(({ descriptor }) => descriptor);
    } else {
      return this.args.conversation.ticketType?.descriptors?.filter(
        (descriptor) =>
          descriptor.isListedAsAttribute &&
          (!descriptor.archived || this.conversationAttributesById[descriptor.id]?.value),
      );
    }
  }

  get conversationAttributesById() {
    return this.args.conversation.attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  get emojiUrl(): string {
    return intermoji.twemojiSVGUri(this.args.conversation?.ticketType?.emoji ?? '');
  }

  get createdAtTranslationKey() {
    let translationPrefix = 'inbox.conversation-details';
    let translationSuffix = 'submission-date';

    return `${translationPrefix}.${translationSuffix}`;
  }

  async loadTicketTypes() {
    if (!this.ticketTypes) {
      this.ticketTypes = await this.session.workspace.fetchTicketTypes();
    }
  }

  get conditionalTicketAttributes() {
    let ticketTypeId = this.args.conversation.ticketType?.id;

    return DescriptorUtils.getVisibleAttributes(
      ticketTypeId,
      this.ticketTypes,
      this.ticketTypeResource,
      this.conditionalAttributesEvaluator,
      this.conversationAttributesById,
      this.session.workspace.canUseTicketsConditionalAttributes,
    );
  }

  get conditionalAttributesEvaluator() {
    let ticketType = this.ticketTypes?.filter(
      (ticketType: any) => ticketType.id === this.args.conversation.ticketType?.id,
    )[0];

    if (ticketType?.descriptors) {
      return new ConditionalAttributesEvaluator({
        conversation: this.args.conversation,
        descriptors: ticketType.descriptors,
        owner: getOwner(this),
      });
    }

    return undefined;
  }

  @action async onUpdateTicketAttribute(attribute: ConversationAttributeSummary) {
    if (this.ticketTypeId || !attribute.isUpdated) {
      return;
    }

    if (this.session.workspace.canUseTicketsConditionalAttributes) {
      await this.conditionalAttributesEvaluator?.updateAttribute(
        attribute as unknown as ConversationAttributeSummary,
        this.args.conversation,
      );
    } else {
      return this.inboxApi.updateAttribute(this.args.conversation?.id, attribute);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::TicketAttributes': typeof TicketAttributes;
    'inbox2/conversation-details-sidebar/ticket-attributes': typeof TicketAttributes;
  }
}
