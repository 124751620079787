/* import __COLOCATED_TEMPLATE__ from './configure-link-branding.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { isBlank, isPresent } from '@ember/utils';
import ajax from 'embercom/lib/ajax';

interface Args {
  customAssetsDomain: $TSFixMe;
}

export default class ConfigureLinkBranding extends Component<Args> {
  @tracked showSideDrawer = false;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @tracked assetsDomain;
  @tracked model;
  @tracked showModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.assetsDomain = this.args.customAssetsDomain?.assetsDomain;
    this.model = this.args.customAssetsDomain;
  }

  get isSaveButtonDisabled() {
    if (isBlank(this.assetsDomain)) {
      return true;
    }

    return this.model?.assetsDomain === this.assetsDomain;
  }

  get isRemoveButtonDisabled() {
    return isBlank(this.model?.assetsDomain);
  }

  get validRecordExists() {
    return this.model?.validRecordExists;
  }

  @action
  async save() {
    try {
      if (isPresent(this.assetsDomain)) {
        let model =
          this.args.customAssetsDomain ||
          this.store.createRecord('settings/custom-email-assets-domain');
        model.assetsDomain = this.assetsDomain;
        await model.save();
        this.model = model;
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.email.custom-assets-domain.save-success'),
        );
        this.intercomEventService.trackAnalyticsEvent({
          action: 'updated',
          object: 'email_link_branding',
          section: 'settings',
          place: 'email_advanced_settings',
          status: 'success',
          domain: this.assetsDomain,
        });
      }
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'email_link_branding',
        section: 'settings',
        place: 'email_advanced_settings',
        status: 'error',
        domain: this.assetsDomain,
        error_message: error?.message,
      });
    }
  }

  @action
  async remove() {
    try {
      await this.args.customAssetsDomain.destroyRecord();
      this.model = null;
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.email.custom-assets-domain.remove-success'),
      );
      this.showModal = false;
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
    }
  }

  @action
  async checkStatus() {
    try {
      await ajax({
        url: `/ember/settings/custom_email_assets_domains/${this.model.id}/verify`,
        type: 'PUT',
        data: JSON.stringify({
          app_id: this.appService.app.id,
        }),
      });
      this.model.validRecordExists = true;
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.email.custom-assets-domain.check-success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
    }
  }

  @action
  openSideDrawer() {
    this.showSideDrawer = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_link_branding',
      section: 'settings',
      place: 'email_advanced_settings',
      value: 'side_drawer_opened',
    });
  }

  @action
  closeSideDrawer() {
    this.showSideDrawer = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_link_branding',
      section: 'settings',
      place: 'email_advanced_settings',
      value: 'side_drawer_closed',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::ConfigureLinkBranding': typeof ConfigureLinkBranding;
  }
}
