/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
import EmbercomRouter from './lib/router/embercom-router';

export default class Router extends EmbercomRouter {}

Router.map(function () {
  this.route('load_time_baseline');
  this.route('video');
  this.route('saml');

  this.route('not-found', { path: '/*path' });

  this.route('start');
  this.route('developer-signup', function () {});
  this.route('early-stage', function () {});
  this.route('early-stage-partner', { path: 'early-stage/:partner' });

  this.route('invite');
  this.route('invites', function () {
    this.route('expired');
    this.route('invalid');
  });

  this.route('signup', function () {
    this.route('new');
    this.route('teams', function () {});
  });

  this.route('sign-in', { path: '/sign-in/:app_id' });

  this.route('oauth', function () {
    this.route('connect');
  });

  this.route('preview-fin', { queryParams: ['token', 'source'] });

  this.route('external-link-shield', {
    path: '/external-link',
    queryParams: ['href', 'malicious', 'blocked', 'app_id'],
  });

  this.route('apps', function () {
    this.route('new');
    this.route('teams-checkout-redirect');

    this.route('app', { path: ':app_id' }, function () {
      this.route('standalone', function () {
        this.route('setup', function () {
          this.route('workflow', { path: 'workflow/:id' });
        });
        this.route('channels', function () {
          this.route('overview');
          this.route('api', function () {
            this.route('workflow', { path: 'workflow/:id' });
          });
          this.route('zendesk', function () {
            this.route('tickets', function () {
              this.route('workflow', { path: 'workflow/:id' });
            });
            this.route('messaging', function () {
              this.route('workflow', { path: 'workflow/:id' });
            });
          });
          this.route('salesforce', function () {
            this.route('cases', function () {
              this.route('workflow', { path: 'workflow/:id' });
            });
          });
          this.route('intercom', function () {
            this.route('messenger', function () {
              this.route('workflow', { path: 'workflow/:id' });
            });
          });
        });
        this.route('processes', function () {
          this.route('workflow', { path: 'workflow/:id' });
        });
        this.route('knowledge', function () {
          this.route('audiences');
          this.route('custom-answers', function () {
            this.route('answer', { path: ':answer_id' }, function () {
              this.route('show', { path: '/' }, function () {
                this.route('conversations');
              });
              this.route('edit');
            });
            this.route('new');
            this.route('unanswered-questions');
          });
        });
        this.route('reports', function () {
          this.route('overview');
          this.route('report', function () {
            this.route('show', { path: '/:report_id' }, function () {
              this.route('chart', function () {
                this.route('new');
                this.route('edit', { path: '/:chart_id/edit' });
              });
            });
          });
        });
        this.route('personality');
        this.route('content-gaps');
        this.route('guidance');
        this.route('testing');
      });

      this.route('appstore', function () {
        this.route('app-package', { path: 'apps/:id' });
        this.route('old-app-package', { path: ':id' });
      });

      this.route('developer-hub', function () {
        this.route('app-packages', function () {
          this.route('app-package', { path: ':id' }, function () {
            this.route('basic-info', function () {
              this.route('edit');
            });
            this.route('oauth', function () {
              this.route('edit');
            });
            this.route('api-version', function () {
              this.route('edit');
            });
            this.route('app-store-listing', function () {
              this.route('edit');
            });
            this.route('getting-started-guide');
            this.route('customer-workspaces');
            this.route('review', function () {
              this.route('edit');
              this.route('no-oauth');
              this.route('no-app-partner');
            });
            this.route('workspaces');
            this.route('webhooks', function () {
              this.route('edit');
            });
            this.route('canvas-kit');
            this.route('reports', function () {
              this.route('installs-and-uninstalls');
              this.route('history-of-events');
            });
            this.route('app-partner', function () {
              this.route('edit');
            });
          });
        });
      });
      this.route('teams-checkout', function () {
        this.route('confirm');
        this.route('early-stage-application');
        this.route('invite-teammates');
        this.route('plan');
        this.route('usage');
        this.route('verify');
      });
      this.route('checkout', function () {
        this.route('success');
      });
      this.route('expired-subscription');
      this.route('load_time_baseline');
      this.route('model-benchmark');
      this.route('video');
      this.route('welcome-helpdesk');

      this.route('trial');
      this.route('mobile-welcome');

      this.route('conversations', { path: 'conversations' });
      this.route('conversation', { path: 'conversations/:conversation_id' });
      this.route('created');
      this.route('engage-redirect', { path: '/engage' });
      this.route('engage-wildcard-redirect', { path: '/engage/*wildcard' });

      this.route('educate-redirect', { path: '/educate' });
      this.route('educate-wildcard-redirect', { path: '/educate/*wildcard' });

      this.route('respond-redirect', { path: '/respond' });
      this.route('respond-wildcard-redirect', { path: '/respond/*wildcard' });

      this.route('impersonate');

      this.route('inbox-insights-redirect', { path: '/inbox/insights' });

      this.route('outbound', function () {
        this.route('all');
        this.route('in-apps');
        this.route('search');
        this.route('views', { path: 'views/:id' });

        this.route('match-check', function () {
          this.route('ruleset', { path: ':id' });
        });

        this.route('banners', function () {
          this.route('new');
          this.route('edit', { path: ':id' });
        });

        this.route('news', function () {
          this.route('news-items', function () {
            this.route('new');
            this.route('edit', { path: ':id' });
          });

          this.route('newsfeeds', function () {
            this.route('new');
            this.route('edit', { path: ':id' });
          });
          this.route('labels', function () {
            this.route('edit', { path: ':id' });
          });
        });

        this.route('message', { path: ':id' });

        this.route('about');
        this.route('insights');
        this.route('push', function () {
          this.route('edit', { path: ':id' });
        });
        this.route('tour', function () {
          this.route('edit', { path: ':id' });
          this.route('new');
        });
        this.route('sms', function () {
          this.route('edit', { path: ':id' });
        });
        this.route('whatsapp', function () {
          this.route('edit', { path: ':id' });
        });
        this.route('chat', function () {
          this.route('edit', { path: ':id' });
        });
        this.route('custom-bot', function () {
          this.route('edit', { path: ':id' });
        });
        this.route('post', function () {
          this.route('edit', { path: ':id' });
        });
        this.route('email', function () {
          this.route('edit', { path: ':id' });
        });
        this.route('survey', function () {
          this.route('edit', { path: ':id' });
        });
        this.route('series', function () {
          this.route('series', { path: ':id' });
          this.route('new');

          this.route('redirect-to', function () {
            this.route('series', { path: ':id' });
          });
        });
        this.route('carousel', function () {
          this.route('edit', { path: ':id' });
        });

        this.route('settings', function () {
          this.route('email-templates', function () {
            this.route('index', { path: '/' });
            this.route('new-template', { path: 'new' });
            this.route('new-visual-template', { path: 'new-visual-template' });
            this.route('edit-template', { path: ':id' });
          });
          this.route('messaging');
          this.route('subscription-types', { path: '/subscriptions' }, function () {
            this.route('index', { path: '/' });
            this.route('edit', { path: ':id' });
            this.route('new');
          });
          this.route('sms-keyword-responses', function () {
            this.route('new');
            this.route('edit', { path: ':id' });
          });
          this.route('sms', function () {
            this.route('toll-free-verification', {
              path: 'toll-free-verification/:id',
            });
          });
          this.route('universal-linking');
        });

        this.route('tooltips', function () {
          this.route('edit', { path: ':id' });
        });

        this.route('checklists', function () {
          this.route('edit', { path: ':id' });
        });

        this.route('find', { path: '/find/:ruleset_id' });
        this.route('selection-set', function () {
          this.route('show', { path: ':id' });
        });
      });

      this.route('inbox', function () {
        this.route('redirect', { path: 'a/:assignee_identifier' }, function () {
          this.route('conversation', { path: 'conversations/:conversation_id' });
        });
        this.route('conversations', { path: ':assignee_identifier' }, function () {
          this.route('conversation', { path: 'conversations/:conversation_id' });
        });
        this.route('conversation', { path: 'conversation/:conversation_id' });

        this.route('rules', function () {});
        this.route('automation', function () {
          this.route('assignment');
          this.route('priority');
          this.route('sla');
          this.route('tag');
          this.route('workload-management');
          this.route('workflows', { path: '/rules' }, function () {
            this.route('list');
            this.route('new');
            this.route('edit', { path: ':id' });
          });
        });
        this.route('inbox', function () {
          this.route('redirect', { path: 'a/:assignee_identifier' }, function () {
            this.route('conversation', { path: 'conversations/:conversation_id' });
          });
          this.route('conversations', { path: ':assignee_identifier' }, function () {
            this.route('conversation', { path: 'conversations/:conversation_id' });
          });
          this.route('conversation', { path: 'conversation/:conversation_id' });
        });
        this.route('reporting', function () {
          this.route('summary', { path: '/' }, function () {
            // Nolaneo – Error seems genuine. Disabled as part of rolling out the new ESLinter Aug 2022
            // eslint-disable-next-line ember/no-shadow-route-definition
            this.route('conversations', { path: '/' });
            this.route('sales', function () {
              this.route('closed-won');
              this.route('emails-captured');
              this.route('new-leads');
              this.route('opportunities');
              this.route('pipeline');
            });
          });
          this.route('leads');
          this.route('performance', function () {
            this.route('volume', { path: '/' });
            this.route('responsiveness');
            this.route('responsiveness-flexible');
            this.route('happiness');
            this.route('sales', function () {
              this.route('opportunities');
              this.route('pipeline');
              this.route('closed-won');
            });
          });
          this.route('conversations');
          this.route('effectiveness');
          this.route('slas');
          this.route('export');
        });
        this.route('new-conversation');
        this.route('spam');
        this.route('requests', function () {
          this.route('request', { path: ':request_id' });
        });
        this.route('dashboard');
      });
      this.route('skip-trial'), this.route('resolve-redirect', { path: '/resolve' });
      this.route('resolve-wildcard-redirect', { path: '/resolve/*wildcard' });

      this.route('import', function () {
        this.route('csv', function () {
          this.route('mapping', { path: ':id/mapping' });
          this.route('finish', { path: ':id/finish' });
          this.route('tag', { path: ':id/tag' });
          this.route('summary', { path: ':id/summary' });
        });

        this.route('mailchimp', function () {
          this.route('lists', { path: ':id/lists' });
          this.route('tag', { path: ':id/tag' });
          this.route('finish', { path: ':id/finish' });
        });

        this.route('mixpanel', function () {
          this.route('instructions');
          this.route('tag', { path: ':id/tag' });
          this.route('finish', { path: ':id/finish' });
        });

        this.route('zendesk-chat', function () {
          this.route('authorize');
          this.route('import', { path: ':id/import' });
        });
      });

      this.route('admins', function () {
        this.route('admin', { path: ':admin_id' }, function () {});
        this.route('me');
      });

      this.route('users', function () {
        this.route('user-not-found');
        this.route('user', { path: ':user_id' }, function () {
          this.route('conversations');
          this.route('all-conversations');
        });

        this.route('segments', { path: 'segments' }, function () {
          this.route('segment', { path: ':segment_identifier' }, function () {});
        });
      });

      this.route('companies', function () {
        this.route('company-not-found');
        this.route('company', { path: ':company_id' }, function () {
          this.route('users');
          this.route('conversations');
          this.route('tickets');
        });
        this.route('segments', function () {
          this.route('segment', { path: ':segment_identifier' });
        });
      });

      this.route('billing', function () {
        this.route('invoices', function () {
          this.route('invoice', { path: ':invoice_id' });
        });
        this.route('details', function () {});
        this.route('summary', function () {});
        this.route('cancel', function () {
          this.route('confirm');
          this.route('complete');
        });
        this.route('settings', function () {});
        this.route('usage', function () {});
        this.route('migration', function () {});
        this.route('manage-usage', function () {});
        this.route('manage-subscription', function () {});
      });

      this.route('settings', function () {
        this.route('standalone', function () {
          this.route('data');
          this.route('integration');
        });
        this.route('tickets-onboarding');
        this.route('audiences');
        this.route('tags-new');
        this.route('imports');
        this.route('people-segments');
        this.route('company-segments');
        this.route('people-data');
        this.route('company-data');
        this.route('conversation-data');
        this.route('custom-object-data', function () {
          this.route('index', { path: '/' });
          this.route('edit', { path: ':object_type_identifier' });
        });
        this.route('ticket-states', function () {
          this.route('index', { path: '/' });
        });
        this.route('ticket-data', function () {
          this.route('detail', { path: ':ticket_type_id' });
        });
        this.route('qualification-settings');
        this.route('saved-replies');
        this.route('saved-replies', { path: 'macros' });
        this.route('events');
        this.route('roles', function () {
          this.route('index', { path: '/' });
          this.route('new');
          this.route('edit', { path: ':role_id' });
        });
        this.route('team');
        this.route('teams');
        this.route('teammates', function () {
          this.route('index', { path: '/' });
          this.route('invite', function () {
            this.route('invite', { path: '/' });
            this.route('edit', { path: '/:id/edit' });
            this.route('csv-import');
            this.route('permissions');
          });
          this.route('teammate', { path: '/:id' }, function () {
            this.route('permissions');
            this.route('remove');
          });
          this.route('permissions', { path: '/permissions/edit' });
          this.route('remove');
        });
        this.route('general');
        this.route('messenger', { queryParams: ['tab', 'section'] }, function () {
          this.route('web');
          this.route('mobile-sdk');
          this.route('settings');
          this.route('conversations');
          this.route('conversational');
        });
        this.route('workload-management');
        this.route('automatic-away-mode');
        this.route('assignment-preferences');
        this.route('inbox-rules', { path: '/rules' }, function () {
          this.route('list');
          this.route('new');
          this.route('edit', { path: ':id' });
        });
        this.route('teammate-presence');
        this.route('ai-assist');
        this.route('calling-old', { path: 'calling' });
        this.route('calling', { path: 'phone' }, function () {
          this.route('index', { path: '/' });
          this.route('phone-regulatory-bundles', function () {
            this.route('index', { path: '/' });
            this.route('new');
            this.route('show', { path: '/:id' });
            this.route('edit', { path: '/:id/edit' });
          });
        });
        this.route('senders');
        this.route('domains');
        this.route('slas');
        this.route('email-custom-assets-domains');
        this.route('email-dedicated-ips');
        this.route('email-forwarding');
        this.route('email-notifications');
        this.route('email-auto-reply');
        this.route('email-spam-settings');
        this.route('strip-inbound-email-links-settings');
        this.route('email-conversation-history-settings');
        this.route('email-signature');
        this.route('email-other-settings');
        this.route('appearance');
        this.route('logs');
        this.route('blocked-users');
        this.route('web');
        this.route('installation');
        this.route('installation-new');
        this.route('ios');
        this.route('android');
        this.route('identity-verification', function () {
          this.route('identity-verification', { path: '/' });
          this.route('web');
          this.route('ios');
          this.route('android');
        });
        this.route('delete-user');
        this.route('data-export');
        this.route('migrate-from-zendesk');
        this.route('webhooks', function () {});
        this.route('workflow-connector-actions', { path: 'custom-actions' }, function () {
          this.route('custom-action', { path: ':id' });
        });
        this.route('security');
        this.route('compliance-documents');
        this.route('switch', function () {
          this.route('setup');
          this.route('monitor');
        });
        this.route('attachment-settings');
        this.route('office-hours');
        this.route('custom-authentication-tokens', function () {
          this.route('index', { path: '/' });
          this.route('edit', { path: ':id' });
          this.route('new');
        });
        // New Settings routes start here, moving them new-settings route to get rid of the lint ignore code smell
        this.route('index', { path: '/' });
        this.route('workspace', function () {
          this.route('general');
          this.route('referrals');
          this.route('teammates', function () {
            this.route('index', { path: '/' });
            this.route('permissions');
            this.route('invite', function () {
              this.route('new');
              this.route('edit', { path: '/:id/edit' });
              this.route('permissions');
              this.route('csv-import');
            });
            this.route('roles', function () {
              this.route('new');
              this.route('edit', { path: ':role_id' });
            });
            this.route('remove');
            this.route('teammate', { path: '/:id' }, function () {
              this.route('permissions');
              this.route('remove');
            });
          });
          this.route('office-hours');
          this.route('brands');
          this.route('security');
        });
        this.route('subscription', function () {
          this.route('migration');
          this.route('usage');
          this.route('billing', function () {
            this.route('invoices', function () {
              this.route('invoice', { path: ':invoice_id' });
            });
            this.route('current-billing-period-charges', function () {});
            this.route('details', function () {});
            this.route('summary', function () {});
            this.route('cancel', function () {
              this.route('confirm');
              this.route('complete');
            });
            this.route('settings', function () {});
            this.route('manage-usage', function () {});
            this.route('manage-subscription', function () {});
            this.route('update-subscription', function () {});
          });
        });
        this.route('ai-automation', function () {
          this.route('fin-ai-agent');
          this.route('helpdesk-ai');
          this.route('automation');
          this.route('external-ai');
        });
        this.route('data', function () {
          this.route('tags');
          this.route('people');
          this.route('audiences');
          this.route('companies');
          this.route('conversation-tickets');
          this.route('custom-objects', function () {
            this.route('index', { path: '/' });
            this.route('edit', { path: ':object_type_identifier' });
          });
          this.route('imports-exports', function () {
            this.route('index', { path: '/' });
            this.route('import-zendesk');
          });
        });
        this.route('app-settings', function () {
          this.route('custom-actions', function () {
            this.route('index', { path: '/' });
            this.route('custom-action', { path: ':id' });
          });
          this.route('legacy-webhooks');
          this.route('authentication', function () {
            this.route('index', { path: '/' });
            this.route('edit', { path: ':id' });
            this.route('new');
          });
          this.route('developer-hub');
          this.route('app-store', function () {
            this.route('index', { path: '/' });
          });
        });
        this.route('helpcenter', function () {
          this.route('workspace-helpcenter', { path: '/:id' }, function () {
            this.route('collections');
            this.route('settings');
          });
          this.route('all');
        });
        this.route('proactive-support', function () {
          this.route('subscriptions', function () {
            this.route('index', { path: '/' });
            this.route('edit', { path: ':id' });
            this.route('new');
          });
          this.route('newsfeeds');
          this.route('news-labels');
          this.route('customization');
        });
        this.route('channels', function () {
          this.route('messenger', function () {
            this.route('web');
            this.route('mobile-sdk');
            this.route('conversations');
            this.route('general');
            this.route('install');
            this.route('opt-in');
            this.route('switch-messenger');
            this.route('conversational', function () {
              this.route('web');
              this.route('mobile-sdk');
              this.route('greeting');
              this.route('general');
            });
          });
          this.route('email', function () {
            this.route('index', { path: '/' });
            this.route('new-template', { path: 'new' });
            this.route('new-visual-template', { path: 'new-visual-template' });
            this.route('edit-template', { path: ':id' });
          });
          this.route('phone', function () {
            this.route('index', { path: '/' });
            this.route('phone-regulatory-bundles', function () {
              this.route('index', { path: '/' });
              this.route('new');
              this.route('show', { path: '/:id' });
              this.route('edit', { path: '/:id/edit' });
            });
          });
          this.route('switch', { queryParams: ['tab'] });
          this.route('sms', function () {
            this.route('index', { path: '/' });
            this.route('toll-free-verification', {
              path: 'toll-free-verification/:id',
            });
            this.route('phone-regulatory-bundles', function () {
              this.route('index', { path: '/' });
              this.route('new');
              this.route('show', { path: '/:id' });
              this.route('edit', { path: '/:id/edit' });
            });
          });
          this.route('social-channels');
          this.route('whatsapp');
          this.route('all');
        });
        this.route('helpdesk', function () {
          this.route('teams');
          this.route('assignments');
          this.route('macros');
          this.route('tickets', function () {
            this.route('index', { path: '/' });
            this.route('ticket-types', function () {
              this.route('detail', { path: ':ticket_type_id' });
            });
            this.route('tickets-onboarding');
          });
          this.route('sla');
          this.route('rules', { path: '/rules' }, function () {
            this.route('index', { path: '/' });
            this.route('new');
            this.route('edit', { path: ':id' });
          });
        });
        this.route('personal', function () {
          this.route('details', function () {
            this.route('index', { path: '/' });
          });
          this.route('preferences');
          this.route('notifications');
          this.route('visible-to-you', function () {
            this.route('tags');
            this.route('user-segments');
            this.route('company-segments');
          });
          this.route('authentication');
          this.route('account-access');
        });
      });
      this.route('messenger', { queryParams: ['tab', 'section'] }, function () {
        this.route('web');
        this.route('mobile-sdk');
        this.route('settings');
        this.route('conversations');
        this.route('conversational');
      });

      this.route('channels');

      this.route('account', function () {
        this.route('general', { path: '/' });
        this.route('notifications');
        this.route('password');
        this.route('two_factor_auth');
        this.route('oauth-tokens');
        this.route('account_access');
        this.route('visibility', function () {
          this.route('tags');
          this.route('user-segments');
          this.route('company-segments');
          this.route('events');
          this.route('user-data');
          this.route('company-data');
        });
      });

      this.route('inbound-email', { path: '/inbound-email/:email_id' });

      this.route('articles', function () {
        this.route('articles', function () {
          this.route('new');
          this.route('list', function () {
            this.route('all');
            this.route('finished'); // redirects to published
            this.route('published');
            this.route('draft');
            this.route('missing');
            this.route('views', { path: 'views/:id' });
          });
          this.route('article-content', { path: '/article-contents/:content_id' }, function () {
            this.route('edit');
          });
          this.route('article', { path: ':id' }, function () {
            this.route('edit');
            this.route('show', function () {
              this.route('stats');
            });
          });
        });
        this.route('site', function () {
          this.route('collections', function () {
            this.route('collection', { path: ':identifier' });
            this.route('site-collection', { path: '/site-collection/:id' });
          });
          this.route('settings', function () {
            this.route('setting', { path: ':id' });
          });
        });
        this.route('insights');
        this.route('about');
        this.route('onboarding');
        this.route('reviews');
        this.route('suggestions');
      });

      // Nolaneo – Error seems genuine. Disabled as part of rolling out the new ESLinter Aug 2022
      // eslint-disable-next-line ember/no-shadow-route-definition
      this.route('feed', { path: 'conversations' }, function () {
        this.route('conversation', { path: ':conversation_id' });
      });

      this.route('accounts');

      this.route('platform', function () {
        this.route('guide', function () {});
      });

      this.route('guide');

      this.route('user_profile');

      this.route('operator', function () {
        this.route('fin');

        this.route('task-bots', function () {
          this.route('users');
          this.route('leads');
          this.route('teammates');
        });
        this.route('basics');
        this.route('custom-bots-overview');
        this.route('custom-bots', function () {
          this.route('index', { path: '/' });
          this.route('new', { path: '/new' });
          this.route('graph-demo');
          this.route('bot-auto-message', { path: 'bot-auto-message' }, function () {
            this.route('show', { path: '/:id' });
          });

          this.route('outbound', { path: 'outbound' }, function () {
            this.route('edit', { path: ':id' });
          });

          this.route('custom-bot', { path: '/:id' }, function () {
            this.route('show', { path: '/' });
            this.route('edit');
          });

          this.route('inbound-custom-bot', function () {
            this.route('edit', { path: ':id' });
          });

          this.route('button-custom-bot', function () {
            this.route('edit', { path: ':id' });
          });

          this.route('triggerable-custom-bot', function () {
            this.route('edit', { path: ':id' });
          });

          this.route('reporting', function () {
            this.route('sent');
            this.route('engaged');
            this.route('completed');
          });
        });
        this.route('resolution-bot', function () {
          this.route('intro');
          this.route('setup');
          this.route('answers', { path: '/' }, function () {
            this.route('answer', { path: ':answer_id' }, function () {
              this.route('show', { path: '/' }, function () {
                this.route('conversations');
              });
              this.route('edit');
            });
            this.route('new');
          });
          this.route('behaviors', function () {
            this.route('index', { path: '/' });
            this.route('edit', { path: ':id' });
          });
          this.route('clusters', { path: '/unanswered-questions' });
          this.route('reporting');
          this.route('fin-content');
          this.route('fin-content-reviews', { path: '/fin-content/reviews' });
        });
        this.route('settings');
        this.route('self-solve-onboarding');
      });

      this.route('automation', function () {
        this.route('fin');
        this.route('basics');
        this.route('workflows-overview');
        this.route('workflows', function () {
          this.route('graph-demo');
          this.route('edit', { path: '/:id' });
        });
        this.route('resolution-bot', function () {
          this.route('setup');
          this.route('answers', { path: '/' }, function () {
            this.route('answer', { path: ':answer_id' }, function () {
              this.route('show', { path: '/' }, function () {
                this.route('conversations');
              });
              this.route('edit');
            });
            this.route('new');
          });
          this.route('behaviors', function () {
            this.route('index', { path: '/' });
            this.route('edit', { path: ':id' });
          });
          this.route('clusters', { path: '/unanswered-questions' });
          this.route('fin-content');
          this.route('fin-content-reviews', { path: '/fin-content/reviews' });
        });
        this.route('settings');
        this.route('self-solve-onboarding');
        this.route('fin-ai-agent', function () {
          this.route('setup');
          this.route('guidance');
          this.route('playground');
          this.route('content');
          this.route('content-suggestions');
          this.route('custom-answers', function () {
            this.route('answer', { path: ':answer_id' }, function () {
              this.route('show', { path: '/' }, function () {
                this.route('conversations');
              });
              this.route('edit');
            });
            this.route('new');
            this.route('unanswered-questions');
          });
          this.route('profiles', function () {
            this.route('index', { path: '/' });
            this.route('edit', { path: ':id' });
          });
          this.route('ai-categories');
        });
        this.route('insights');
      });

      this.route('fin-ai-agent', function () {
        this.route('setup');
        this.route('playground');
        this.route('content-suggestions');
        this.route('custom-answers', function () {
          this.route('answer', { path: ':answer_id' }, function () {
            this.route('show', { path: '/' }, function () {
              this.route('conversations');
            });
            this.route('edit');
          });
          this.route('new');
          this.route('unanswered-questions');
        });
        this.route('profiles', function () {
          this.route('index', { path: '/' });
          this.route('edit', { path: ':id' });
        });
        this.route('settings');
        this.route('ai-categories');
      });

      this.route('ai-chatbot', { path: '/ai-chatbot/*path' });

      this.route('home', function () {});
      this.route('guide-library', function () {});
      this.route('getting-started', function () {});
      this.route('wizard', function () {});
      this.route('demo-messenger', function () {});
      this.route('intersection');
      this.route('test-conversation');
      this.route('tours', function () {
        this.route('new');
        this.route('edit', { path: ':id' });
      });
      this.route('pre-tour');

      this.route('reports', function () {
        this.route('overview');
        this.route('old-overview');
        this.route('custom-reports', function () {
          this.route('report', function () {
            this.route('new', function () {
              this.route('chart', function () {
                this.route('new');
                this.route('edit', { path: '/:chart_id/edit' });
              });
            });
            this.route('show', { path: '/:report_id' }, function () {
              this.route('chart', function () {
                this.route('new');
                this.route('edit', { path: '/:chart_id/edit' });
              });
            });
          });
        });
        this.route('messages');
        this.route('lead-generation', function () {
          this.route('leads', { path: '/' });
          this.route('sales', function () {
            this.route('opportunities');
            this.route('pipeline');
            this.route('closed-won');
          });
          this.route('custom-bots', function () {
            this.route('sent');
            this.route('engaged');
            this.route('completed');
          });
        });
        this.route('outbound-engagement', function () {
          this.route('overview', { path: '/' });
          this.route('email-deliverability', function () {
            this.route('opened');
            this.route('unsubscribed');
            this.route('bounced');
            this.route('marked-as-spam');
          });
        });
        this.route('customer-support', function () {
          this.route('overview', { path: '/' });
          this.route('calls');
          this.route('fin-ai-copilot');
          this.route('conversational-support-funnel');
          this.route('customer-satisfaction');
          this.route('customer-satisfaction-v2');
          this.route('responsiveness');
          this.route('conversations');
          this.route('conversations-flexible');
          this.route('tickets');
          this.route('team-performance');
          this.route('team-inbox-performance-report');
          this.route('teammate-performance-report');
          this.route('conversation-tags');
          this.route('effectiveness');
          this.route('slas');
          this.route('resolution-bot');
          this.route('fin');
          this.route('fin-ai-agent');
          this.route('custom-bots', function () {
            this.route('sent');
            this.route('engaged');
            this.route('completed');
          });
          this.route('articles', function () {
            this.route('closed-by-operator');
          });
          this.route('export');
        });
        this.route('conversational-insights', function () {
          this.route('topics', function () {
            this.route('topic', { path: ':topic_id' }, function () {
              this.route('show', { path: '/' });
              this.route('edit');
            });
            this.route('new');
          });
          this.route('suggested-topics', function () {
            this.route('topic', { path: '/:topic_ids' }, function () {});
          });
        });
        this.route('metrics-playground');
        this.route('views', function () {
          this.route('view', { path: ':reporting_view_id' });
        });
      });

      this.route('editor-demo');
      this.route('feature-sync');

      this.route('seatless-experience', function () {
        this.route('request-confirmed');
        this.route('request-seat');
        this.route('select-seat');
        this.route('welcome');
      });

      this.route('content', function () {
        // eslint-disable-next-line ember/routes-segments-snake-case
        this.route('new', { path: '/new/:creationParams' });
      });
      this.route('sms-demo');
      this.route('email-editor-demo');

      this.route('intershop', function () {
        this.route('add-ons', function () {
          this.route('add-on', { path: ':product_slug' }, function () {
            this.route('pricing');
          });
        });
        this.route('products', function () {
          this.route('product', { path: ':product_slug' }, function () {
            this.route('plans', function () {
              this.route('plan', { path: ':plan_slug' });
            });
          });
        });
      });

      this.route('side-by-side', function () {
        this.route('zendesk', function () {
          this.route('index', { path: '/' });
        });
      });

      this.route('knowledge-hub', function () {
        this.route('overview', function () {
          this.route('index', { path: '/' });
        });
        this.route('all-content');
        this.route('article-reviews');
        this.route('folder', { path: '/folder/:folder_id' });
        this.route('new', { path: '/:content_type/new' });
        this.route('view', { path: '/:content_type/:content_id' });
        this.route('edit', { path: '/:content_type/:content_id/edit' });
        this.route('internal-note', function () {
          this.route('internal-note', { path: ':id' });
          this.route('new');
          this.route('edit', { path: '/edit/:content_id' });
        });
      });
    });
  });

  this.route('changes', function () {
    this.route('change', { path: ':id' });
  });

  this.route('meeting-scheduler', { path: '/meeting-scheduler/calendar/:id' });

  this.route('app-builder');
  this.route('canvas-kit-builder', function () {
    this.route('preview');
  });
  this.route('amazon-connect-cti');
  this.route('graph-demo');
  this.route('tree-demo');
  this.route('article-navigation-stack-demo');
  this.route('folder-selector-demo');
  this.route('inbox', function () {
    this.route('workspace', { path: ':workspace_id' }, function () {
      this.route('inbox-requires-seat');
      this.route('onboarding');
      this.route('practice-shortcuts');
      this.route('spam');

      this.route('inbox', function () {
        this.route('new-conversation');
        this.route('conversation', function () {
          this.route('conversation', { path: ':conversation_id' });
        });
        this.route('fin-view-redirect', { path: '/fin' });
        this.route('inbox', { path: '/:inbox_category/:inbox_id' }, function () {
          this.route('conversation', function () {
            this.route('conversation', { path: ':conversation_id' });
          });
        });

        // new search
        this.route('search', function () {
          this.route('conversation', function () {
            this.route('conversation', { path: ':conversation_id' });
          });
        });

        this.route(
          'linked-conversations',
          { path: 'tickets/:ticket_id/linked-conversations' },
          function () {
            this.route('conversation', { path: ':conversation_id' });
          },
        );
      });
      this.route('search', function () {
        this.route('conversation', function () {
          this.route('conversation', { path: ':conversation_id' });
        });
      });
      this.route('dashboard');
    });
  });
});
