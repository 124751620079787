/* import __COLOCATED_TEMPLATE__ from './google-tag-manager.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Args: {
    disabled?: boolean;
  };
}

export default class GoogleTagManager extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get appID() {
    return this.appService.app.id;
  }

  @action
  onCopyButtonClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'copy_workspace_id_button',
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::GoogleTagManager': typeof GoogleTagManager;
    'installation-new/web/initial-setup/google-tag-manager': typeof GoogleTagManager;
  }
}
