/* RESPONSIBLE TEAM: team-product-exploration */

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Admin from 'embercom/models/admin';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { ReactRoute } from 'embercom/lib/react-route';

class NotificationsRoute extends IntercomRoute {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: $TSFixMe;

  analytics = {
    section: 'settings',
    place: 'personal.notifications',
  };

  reactEnabled() {
    return true;
  }

  activate() {
    this.store
      .createRecord('admin-pageview', {
        pageKey: 'notifications_settings',
      })
      .save();
  }

  model() {
    let admin: Admin = this.modelFor('apps') as Admin;
    return this.store.findRecord('admin-notification-settings', admin.get('id'));
  }

  get titleToken() {
    return this.intl.t('new-settings.submenu.personal.notifications');
  }
}

export type NotificationsRouteType = NotificationsRoute;

export default ReactRoute(NotificationsRoute);
