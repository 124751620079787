/* import __COLOCATED_TEMPLATE__ from './card-component-paywall.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  paywallFeatureKey: string;
  label: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

const CardComponentPaywall = templateOnlyComponent<Signature>();
export default CardComponentPaywall;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teams::CardComponentPaywall': typeof CardComponentPaywall;
    'settings/teams/card-component-paywall': typeof CardComponentPaywall;
  }
}
