/* import __COLOCATED_TEMPLATE__ from './article-placeholder-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type TreeItem } from 'embercom/objects/tree-list';
import { type DataObject } from './types';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type RouterService from '@ember/routing/router-service';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  item: TreeItem;
}

export default class ArticlePlaceholderItem extends Component<Args> {
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare router: RouterService;

  get app() {
    return this.appService.app;
  }

  get itemDataObject(): DataObject {
    return this.args.item.dataObject;
  }

  get parentItem(): TreeItem {
    return this.args.item.parent as TreeItem;
  }

  get collection() {
    return this.parentItem.dataObject.content;
  }

  @action
  createArticleInCollection() {
    this.knowledgeHubService.createContent(EntityType.Article, {
      collectionId: this.collection.id,
      contentLocale: this.parentItem.dataObject.locale?.(),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::ArticlePlaceholdefItem': typeof ArticlePlaceholderItem;
    'articles/site/collections/tree/article-placeholder-item': typeof ArticlePlaceholderItem;
  }
}
