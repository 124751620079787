/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { or, match } from '@ember/object/computed';
import { isEmpty, isEqual } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import diffCalculator from 'embercom/lib/developer-hub/diff-calculator';
import RouteRegexes from 'embercom/lib/route-regexes';

export default Service.extend({
  router: service(),
  appPackage: computed(function () {
    return getOwner(this)
      .lookup('route:apps/app/developer-hub/app-packages/app-package')
      .modelFor('apps/app/developer-hub/app-packages/app-package');
  }),
  oauthScopes: computed(function () {
    return getOwner(this).lookup('route:apps/app/developer-hub').modelFor('apps/app/developer-hub')
      .oauthScopes;
  }),
  appStoreListingHasChanges: computed('appPackage', 'appPackage.changeRequest', function () {
    return this.hasDiff(
      this.get('appPackage.appStoreListing'),
      this.get('appPackage.changeRequest.appPackageConfig.appStoreListing'),
    );
  }),
  oauthClientHasChanges: computed('appPackage', 'appPackage.changeRequest', function () {
    return this.hasDiff(
      this.get('appPackage.oauthClient'),
      this.get('appPackage.changeRequest.appPackageConfig.oauthClient'),
    );
  }),
  messengerAppHasChanges: computed('appPackage', 'appPackage.changeRequest', function () {
    return this.hasDiff(
      this.get('appPackage.messengerApp'),
      this.get('appPackage.changeRequest.appPackageConfig.messengerApp'),
    );
  }),
  inboxAppHasChanges: computed('appPackage', 'appPackage.changeRequest', function () {
    return this.hasDiff(
      this.get('appPackage.inboxApp'),
      this.get('appPackage.changeRequest.appPackageConfig.inboxApp'),
    );
  }),
  basicInformationHasChanges: computed('appPackage', 'appPackage.changeRequest', function () {
    return this.hasDiff(this.appPackage, this.get('appPackage.changeRequest.appPackageConfig'));
  }),
  appPackageHasChanges: or(
    'basicInformationHasChanges',
    'messengerAppHasChanges',
    'inboxAppHasChanges',
    'oauthClientHasChanges',
    'appStoreListingHasChanges',
  ),
  isPublicApp: computed('appPackage.state', function () {
    return isEqual(this.get('appPackage.state'), 'public_app');
  }),
  hasInvalidOauthScopesWithoutCanvasKitCapabilities: computed(
    'appPackage.changeRequest.appPackageConfig.{oauthClient.scopes,inboxApp,messenger_app}',
    function () {
      let inboxApp = this.get('appPackage.changeRequest.appPackageConfig.inboxApp');
      let messengerApp = this.get('appPackage.changeRequest.appPackageConfig.messenger_app');

      if (inboxApp === null && messengerApp === null) {
        let scopes = this.get('appPackage.changeRequest.appPackageConfig.oauthClient.scopes');
        if (scopes.includes('gather_app_data')) {
          return true;
        }
      }
      return false;
    },
  ),
  fillEmptyModel(path, value) {
    if (isEmpty(this.get(path))) {
      this.set(path, value);
    }
  },
  hasDiff(modelA, modelB) {
    if (isEmpty(modelA) && isEmpty(modelB)) {
      return false;
    }
    if (isEmpty(modelA) && !isEmpty(modelB)) {
      return true;
    }
    if (!isEmpty(modelA) && isEmpty(modelB)) {
      return true;
    }
    return !isEmpty(diffCalculator(modelA, modelB));
  },

  isOnBasicInfo: match('router.currentRouteName', RouteRegexes.developerHub.basicInfo),
  isOnApiVersion: match('router.currentRouteName', RouteRegexes.developerHub.apiVersion),
  isOnAuthentication: match('router.currentRouteName', RouteRegexes.developerHub.authentication),
  isOnCanvasKit: match('router.currentRouteName', RouteRegexes.developerHub.canvasKit),
  isOnWebhooks: match('router.currentRouteName', RouteRegexes.developerHub.webhooks),

  isOnReview: match('router.currentRouteName', RouteRegexes.developerHub.review),
  isOnWorkspaces: match('router.currentRouteName', RouteRegexes.developerHub.workspaces),
  isOnAppStoreListing: match('router.currentRouteName', RouteRegexes.developerHub.appStoreListing),
  isOnGettingStartedGuide: match(
    'router.currentRouteName',
    RouteRegexes.developerHub.gettingStartedGuide,
  ),
  isOnCustomerWorkspaces: match(
    'router.currentRouteName',
    RouteRegexes.developerHub.customerWorkspaces,
  ),
  isOnAppPartner: match('router.currentRouteName', RouteRegexes.developerHub.appPartner),

  isOnReports: match('router.currentRouteName', RouteRegexes.developerHub.reports.base),

  isOnEditPage: match('router.currentRouteName', RouteRegexes.developerHub.edit),

  isOnNoAppPartner: match('router.currentRouteName', RouteRegexes.developerHub.noAppPartner),
});
