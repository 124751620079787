/* import __COLOCATED_TEMPLATE__ from './knowledge-summary.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ContentImportService from 'embercom/services/content-import-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ArticleSyncSetting from 'embercom/models/articles/article-sync-setting';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import { EntityType } from 'embercom/models/data/entity-types';
import { type TaskGenerator } from 'ember-concurrency';

interface Args {}

export default class KnowledgeSummary extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;
  @service declare contentImportService: ContentImportService;
  @service declare store: Store;
  @service declare realTimeEventService: any;

  @tracked showAddKnowledgeModal = false;
  @tracked syncSettings: ArticleSyncSetting[] = [];
  @tracked isSyncingArticles = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadArticleSyncSetting).perform();
    this.realTimeEventService.on('ImportStarted', this, 'handleArticleSyncStarted');
    this.realTimeEventService.on('ImportCompleted', this, 'handleArticleSyncCompleted');
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off('ImportStarted', this, 'handleArticleSyncStarted');
    this.realTimeEventService.off('ImportCompleted', this, 'handleArticleSyncCompleted');
  }

  get knowledgeSourcesSummary() {
    let sources = [
      { count: this.articleCount, singular: 'article', plural: 'articles' },
      { count: this.snippetCount, singular: 'snippet', plural: 'snippets' },
      { count: this.pdfCount, singular: 'PDF', plural: 'PDFs' },
      { count: this.customAnswersCount, singular: 'custom answer', plural: 'custom answers' },
    ];

    let parts = sources
      .filter((source) => source.count > 0)
      .map((source) => {
        return `${source.count} ${source.count === 1 ? source.singular : source.plural}`;
      });

    if (this.contentImportSourcesSummary) {
      // contentImportSummary will be at pos 2
      if (parts.length && parts[0].includes('article')) {
        parts.splice(1, 0, this.contentImportSourcesSummary);
      } else {
        // insert contentImportSummary at pos 1
        parts.unshift(this.contentImportSourcesSummary);
      }
    }

    return parts.join(', ');
  }

  get contentImportSourcesSummary() {
    let activeSources = this.contentImportSources.filter((source) => source.isInUse);
    let pageCount = activeSources.reduce((sum, source) => sum + (source.contentCount ?? 0), 0);

    if (pageCount === 0) {
      return '';
    }

    let pageLabel = pageCount === 1 ? 'page' : 'pages';
    let sourceLabel = activeSources.length === 1 ? 'website' : 'websites';

    return `${pageCount} ${pageLabel} from ${activeSources.length} ${sourceLabel}`;
  }

  @action async reloadContentSources() {
    this.contentImportService.contentImportSources =
      await this.contentImportService.forceFetchContentImportSources();
  }

  @action
  toggleAddKnowledgeModal(value: boolean) {
    this.showAddKnowledgeModal = value;
  }

  @action
  async reloadSources() {
    await this.reloadContentSources();
    await taskFor(this.loadArticleSyncSetting).perform();
    taskFor(this.finStandaloneService.fetchLibrarySummary).perform();
  }

  @task({ drop: true })
  *loadArticleSyncSetting(): TaskGenerator<void> {
    let syncSettings = yield this.store.findAll('articles/article-sync-setting');
    this.syncSettings = syncSettings;
  }

  get articleSyncSetting(): ArticleSyncSetting | undefined {
    return this.syncSettings.find(
      (setting: ArticleSyncSetting) =>
        setting.provider === 'zendesk' && setting.status === 'active',
    );
  }

  get isLoading() {
    return (
      (this.contentImportService.isLoadingSources && this.contentImportSources.length === 0) ||
      (!this.articleSyncSetting && taskFor(this.loadArticleSyncSetting).isRunning)
    );
  }

  get showNoKnowledgePlaceholder() {
    return (
      !this.articleSyncSetting && this.contentImportSources.length === 0 && !this.hasSnippetsOrPdfs
    );
  }

  get contentImportSources() {
    return this.contentImportService.contentImportSources || [];
  }

  get isContentImportSourcesSyncing() {
    return this.contentImportSources.any((source) => source.latestImportRun?.isInProgress || false);
  }

  get isKnowledgeSourcesSyncing() {
    return this.isSyncingArticles || this.isContentImportSourcesSyncing;
  }

  get knowledgeSourceSummary() {
    return this.finStandaloneService.knowledgeSourceSummary;
  }

  get articleCount() {
    return this.knowledgeSourceSummary[EntityType.ArticleContent]?.total_count || 0;
  }

  get snippetCount() {
    return this.knowledgeSourceSummary[EntityType.ContentSnippet]?.total_count || 0;
  }

  get pdfCount() {
    return this.knowledgeSourceSummary[EntityType.FileSourceContent]?.total_count || 0;
  }

  get customAnswersCount() {
    return this.knowledgeSourceSummary[EntityType.Answer]?.total_count || 0;
  }

  get hasSnippetsOrPdfs() {
    return this.snippetCount > 0 || this.pdfCount > 0;
  }

  get zendeskSyncStatusClass() {
    return this.isKnowledgeSourcesSyncing ? 'text-default' : 'text-success';
  }

  handleArticleSyncStarted() {
    this.isSyncingArticles = true;
  }

  handleArticleSyncCompleted() {
    this.isSyncingArticles = false;
    taskFor(this.finStandaloneService.fetchLibrarySummary).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::KnowledgeSummary': typeof KnowledgeSummary;
    'standalone/channels/knowledge-summary': typeof KnowledgeSummary;
  }
}
