/* import __COLOCATED_TEMPLATE__ from './body-content.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import type ApplicationInstance from '@ember/application/instance';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type Calling from 'embercom/models/settings/calling';
import type PhoneNumber from 'embercom/models/calling-phone-number';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type RouterService from '@ember/routing/router-service';

type CallingBodyContentResponse = {
  success: boolean;
  errorMessage: string;
};

interface Args {
  settings: Calling;
  isCallingSupported: boolean;
  openUpgradeModal: () => void;
}

export default class CallingBodyContent extends Component<Args> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare intercomCallService: IntercomCallService;
  @service declare router: RouterService;

  @tracked canUseCalling = false;
  @tracked hasAcceptedTerms = false;
  @tracked openSectionId = 'phone_calls';
  @tracked showProvisioningModal = false;
  @tracked phoneNumbers: PhoneNumber[] = [];
  @tracked recordingWarningMessage: string;
  @tracked recordingWarningMessageOutbound: string;
  @tracked recordingWarningMessageMessenger: string;
  @tracked recordingEnabled: boolean;
  @tracked recordingEnabledOutbound: boolean;
  @tracked recordingEnabledMessenger: boolean;
  @tracked inboundCallEnabled = false;
  @tracked outboundCallEnabled = false;
  @tracked isTeamEnabled = false;
  @tracked listOfAssignableTeams: any[];
  @tracked wrapUpTimeUnit = 'seconds';
  @tracked wrapUpTimeEnabled = false;
  @tracked wrapUpTimeAmount: number;
  @tracked deleteRecordingAndTranscriptionEnabled = false;
  @tracked retentionPeriod = 0;
  @tracked matchUserByPhoneNumber: boolean;
  @tracked provisionedPhoneNumber: PhoneNumber | null = null;
  @tracked transcriptionsEnabled: boolean;
  @tracked transcriptionSummaryEnabled: boolean;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    this.recordingWarningMessage = args.settings.recordingWarningMessage;
    this.recordingWarningMessageOutbound = args.settings.recordingWarningMessageOutbound;
    this.recordingWarningMessageMessenger = args.settings.recordingWarningMessageMessenger;
    this.recordingEnabled = args.settings.recordingEnabled;
    this.recordingEnabledOutbound = args.settings.recordingEnabledOutbound;
    this.recordingEnabledMessenger = args.settings.recordingEnabledMessenger;
    this.canUseCalling = this.appService.app.canUseCalling;
    this.hasAcceptedTerms = this.canUseCalling;
    this.inboundCallEnabled = args.settings.inboundEnabled;
    this.outboundCallEnabled = args.settings.outboundEnabled;
    this.listOfAssignableTeams = this.noTeamsForApp ? [] : this.mapTeamsToDropdownItems;
    this.wrapUpTimeUnit = args.settings.wrapUpTimeUnit;
    this.wrapUpTimeEnabled = args.settings.wrapUpTimeEnabled;
    this.transcriptionsEnabled = args.settings.transcriptionsEnabled;
    this.transcriptionSummaryEnabled = args.settings.transcriptionSummaryEnabled;
    this.wrapUpTimeAmount =
      args.settings.wrapUpTimeUnit === 'seconds'
        ? args.settings.wrapUpTimeAmount
        : Math.floor(args.settings.wrapUpTimeAmount / 60);
    this.deleteRecordingAndTranscriptionEnabled =
      args.settings.deleteRecordingAndTranscriptionEnabled || false;
    this.retentionPeriod = args.settings.recordingAndTranscriptionRetentionPeriodInDays;
    this.matchUserByPhoneNumber = this.appService.app.canMatchUserByPhoneNumber;
    if (!this.settings.callingAllowed) {
      this.openSectionId = '';
    }
  }

  get settings() {
    return this.args.settings;
  }

  get phoneCallsEnabled() {
    return this.settings.phoneNumbers.some((number) => number.status !== 'deleted');
  }

  get noTeamsForApp() {
    return this.appService.app.assignableTeams.length === 0;
  }

  get isSaveButtonDisabled() {
    return this.noTeamsForApp && !this.hasWorkflowsBillingFeature;
  }

  get isUpdateRecordingButtonDisabled() {
    return !this.isRetentionPeriodValid;
  }

  get isRetentionPeriodValid() {
    if (this.deleteRecordingAndTranscriptionEnabled) {
      return this.retentionPeriod > 0 && this.retentionPeriod < 999;
    }
    return true;
  }

  get mapTeamsToDropdownItems() {
    let teams = this.appService.app.assignableTeams;
    let options = teams.map((team: any) => {
      return {
        text: team.get('name'),
        value: team.get('id'),
        component: 'common/avatar-and-name-dropdown-item',
        componentAttrs: {
          model: team,
          modelDisplayName: team.get('name'),
          avatarSize: 'xs',
        },
      };
    });

    return options;
  }

  get recordingAndTranscriptsString() {
    return this.intl.t('channels.video-call.settings.recordings.recordings-and-transcripts');
  }

  get messengerCallingString() {
    return this.intl.t('channels.video-call.settings.messenger-call-settings.heading');
  }

  get hasWorkflowsBillingFeature() {
    return this.appService.app.canUseFeature('workflows_core_billing_feature');
  }

  get supportedWrapUpTimeUnits() {
    return [
      {
        text: this.intl.t('channels.video-call.settings.phone-call-settings.inbound.seconds'),
        value: 'seconds',
      },
      {
        text: this.intl.t('channels.video-call.settings.phone-call-settings.inbound.minutes'),
        value: 'minutes',
      },
    ];
  }

  get wrapUpAmountSeconds() {
    return this.wrapUpTimeUnit === 'seconds' ? this.wrapUpTimeAmount : this.wrapUpTimeAmount * 60;
  }

  get invalidWrapUpTimeAmount() {
    if (this.wrapUpTimeUnit === 'seconds') {
      return this.wrapUpTimeAmount <= 0 || this.wrapUpTimeAmount > 900;
    }
    return this.wrapUpTimeAmount <= 0 || this.wrapUpTimeAmount > 15;
  }

  get wrapUpTimeAmountMax() {
    return this.wrapUpTimeUnit === 'seconds' ? 900 : 15;
  }

  get wrapUpTimeAmountValidationMessage() {
    return this.intl.t(
      'channels.video-call.settings.phone-call-settings.inbound.wrap-up-time-invalid',
      { max: this.wrapUpTimeAmountMax, unit: this.wrapUpTimeUnit },
    );
  }

  get hasPhoneDemoFeatureFlag() {
    return this.appService.app.canUseFeature('ae-phone-demo');
  }

  get currentAdminId() {
    return this.appService.app.currentAdmin.id;
  }

  @action toggleTermsCheckbox() {
    this.hasAcceptedTerms = !this.hasAcceptedTerms;
  }

  @action async enableCalling() {
    try {
      let response = await taskFor(this.updateCallingBodyContent).perform();
      this._recordEvent('accept_messenger_calling_terms');
      if (response.success) {
        this.canUseCalling = true;
        this.notificationsService.notifyConfirmation(
          this.intl.t('channels.video-call.settings.calling-enabled'),
        );
      } else {
        throw response.errorMessage;
      }
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.calling-enablement-error'),
      );
      throw err;
    }
  }

  @action async saveInboundSettings() {
    let settingsModel = this.args.settings;
    settingsModel.wrapUpTimeEnabled = this.wrapUpTimeEnabled;
    if (this.wrapUpTimeEnabled) {
      if (this.wrapUpAmountSeconds > 900) {
        this.notificationsService.notifyError(
          this.intl.t(
            'channels.video-call.settings.phone-call-settings.inbound.failed-notification',
          ),
        );
        return;
      }
      settingsModel.wrapUpTimeUnit = this.wrapUpTimeUnit;
      settingsModel.wrapUpTimeAmount = this.wrapUpAmountSeconds;
    }
    settingsModel.save();
    this.notificationsService.notifyConfirmation(
      this.intl.t('channels.video-call.settings.phone-call-settings.inbound.updated-notification'),
    );
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action activateProvisioningModal() {
    this.showProvisioningModal = true;
    this.intercomCallService.registerCallEvent({
      action: 'clicked',
      object: 'phone_number_purchase',
      place: 'calling_settings',
      section: 'settings',
    });
  }

  @action closeProvisioningModal() {
    this.showProvisioningModal = false;
  }

  @action onPhoneNumberProvisioned(phoneNumber: PhoneNumber) {
    this.provisionedPhoneNumber = phoneNumber;
  }

  @action
  closePhoneNumberEditModal() {
    if (this.provisionedPhoneNumber) {
      this.provisionedPhoneNumber.rollbackAttributes();
      this.provisionedPhoneNumber = null;
    }
  }

  @action enableOutboundCalling() {
    this.outboundCallEnabled = true;
  }

  @action onSelectTimeUnit(unit: string) {
    this.wrapUpTimeUnit = unit;
  }

  @action toggleWrapUpTime(unit: any) {
    this.wrapUpTimeEnabled = unit.target.checked;
  }

  @action async toggleMessengerCalling() {
    try {
      this.settings.toggleProperty('messengerCallingEnabled');
      let response = await taskFor(this.updateMessengerCalling).perform();
      if (!response.success) {
        this.settings.toggleProperty('messengerCallingEnabled');
        throw response.errorMessage;
      }
      this.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.video-call.settings.recordings.updated-save-notification'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.phone-call-settings.inbound.error'),
      );
      throw err;
    }
  }

  @action async toggleCallTranscriptionFeature() {
    this.transcriptionsEnabled = !this.transcriptionsEnabled;
    await this.updateRecording();
  }

  @action async toggleCallTranscriptionSummaryFeature() {
    this.transcriptionSummaryEnabled = !this.transcriptionSummaryEnabled;
    await this.updateRecording();
  }

  @action async savePhoneCallSettings() {
    try {
      let response = await taskFor(this.updateMatchUserByPhoneNumber).perform();
      if (!response.success) {
        this.notificationsService.notifyError(
          this.intl.t(
            'channels.video-call.settings.phone-call-settings.inbound.failed-notification',
          ),
        );
        return;
      }
      this.settings.wrapUpTimeEnabled = this.wrapUpTimeEnabled;
      if (this.wrapUpTimeEnabled) {
        if (this.wrapUpAmountSeconds <= 0 || this.wrapUpAmountSeconds > 900) {
          this.notificationsService.notifyError(
            this.intl.t(
              'channels.video-call.settings.phone-call-settings.inbound.failed-notification',
            ),
          );
          return;
        }
        this.settings.wrapUpTimeUnit = this.wrapUpTimeUnit;
        this.settings.wrapUpTimeAmount = this.wrapUpAmountSeconds;
      }
      this.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'channels.video-call.settings.phone-call-settings.inbound.updated-notification',
        ),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.phone-call-settings.inbound.error'),
      );
      throw err;
    }
  }

  @action async toggleRecordingFeature() {
    this.recordingEnabled = !this.recordingEnabled;

    await this.updateRecording();
  }

  @action async toggleRecordingOutboundFeature() {
    this.recordingEnabledOutbound = !this.recordingEnabledOutbound;

    await this.updateRecording();
  }

  @action async toggleRecordingMessengerFeature() {
    this.recordingEnabledMessenger = !this.recordingEnabledMessenger;

    await this.updateRecording();
  }

  @action async handleMessageChange() {
    await this.updateRecording();
  }

  @action async toggleRecordingDeletion() {
    this.deleteRecordingAndTranscriptionEnabled = !this.deleteRecordingAndTranscriptionEnabled;

    await this.updateRecording();
  }

  @action async updateRecording() {
    this.notificationsService.clear();

    let settingsModel = this.args.settings;
    settingsModel.recordingWarningMessage = this.recordingWarningMessage;
    settingsModel.recordingWarningMessageOutbound = this.recordingWarningMessageOutbound;
    settingsModel.recordingWarningMessageMessenger = this.recordingWarningMessageMessenger;
    settingsModel.recordingEnabled = this.recordingEnabled;
    settingsModel.recordingEnabledOutbound = this.recordingEnabledOutbound;
    settingsModel.recordingEnabledMessenger = this.recordingEnabledMessenger;
    settingsModel.deleteRecordingAndTranscriptionEnabled =
      this.deleteRecordingAndTranscriptionEnabled;
    settingsModel.recordingAndTranscriptionRetentionPeriodInDays = this.retentionPeriod;
    settingsModel.transcriptionsEnabled = this.transcriptionsEnabled;
    settingsModel.transcriptionSummaryEnabled = this.transcriptionSummaryEnabled;
    settingsModel.save();

    let analyticsEventAction = 'disabled';
    if (this.recordingEnabled || this.recordingEnabledOutbound || this.recordingEnabledMessenger) {
      analyticsEventAction = 'enabled';
    }

    this.intercomCallService.registerCallEvent({
      action: analyticsEventAction,
      object: 'transcriptions_and_recordings',
      place: 'calling_settings',
      section: 'settings',
    });

    this.notificationsService.notifyConfirmation(
      this.intl.t('channels.video-call.settings.recordings.updated-save-notification'),
    );
  }

  @dropTask *updateCallingBodyContent(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings`, { app_id: this.appService.app.id });
  }

  @action async toggleInboundCalls() {
    try {
      this.inboundCallEnabled = !this.inboundCallEnabled;
      let response = await taskFor(this.updateInboundCalls).perform();
      if (!response.success) {
        this.inboundCallEnabled = !this.inboundCallEnabled;
      }
    } catch (err) {
      this.inboundCallEnabled = !this.inboundCallEnabled;
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.phone-call-settings.inbound.error'),
      );
      throw err;
    }
  }

  @action async toggleOutboundCalls() {
    try {
      this.outboundCallEnabled = !this.outboundCallEnabled;
      let response = await taskFor(this.updateOutboundCalls).perform();
      if (!response.success) {
        this.outboundCallEnabled = !this.outboundCallEnabled;
      }
    } catch (err) {
      this.outboundCallEnabled = !this.outboundCallEnabled;
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.phone-call-settings.outbound.error'),
      );
      throw err;
    }
  }

  @action
  displayArticle(articleId: number, clickedLink: string) {
    window.Intercom('showArticle', articleId);
    this.intercomCallService.registerCallEvent({
      action: 'clicked',
      object: clickedLink,
      context: 'discovery-banner',
      place: 'calling.banner',
      section: 'discovery-banner',
    });
  }

  @dropTask
  *fetchPhoneNumbersTask(): TaskGenerator<Array<PhoneNumber>> {
    this.phoneNumbers = yield this.store.query('calling-phone-number', {});
    return this.phoneNumbers;
  }

  @dropTask
  *peekPhoneNumbersTask(): TaskGenerator<Array<PhoneNumber>> {
    this.phoneNumbers = yield this.store.peekAll('calling-phone-number');
    return this.phoneNumbers;
  }

  @dropTask *updateInboundCalls(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings/update_inbound_calling`, {
      app_id: this.appService.app.id,
      inbound_enabled: this.inboundCallEnabled,
    });
  }

  @dropTask *updateOutboundCalls(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings/update_outbound_calling`, {
      app_id: this.appService.app.id,
      outbound_enabled: this.outboundCallEnabled,
    });
  }

  @dropTask *updateMessengerCalling(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings/update_messenger_calling`, {
      app_id: this.appService.app.id,
      messenger_calling_enabled: this.settings.messengerCallingEnabled,
    });
  }

  @dropTask *updateMatchUserByPhoneNumber(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings/update_match_user_by_phone_number`, {
      app_id: this.appService.app.id,
      match_user_by_phone_number: this.matchUserByPhoneNumber,
    });
  }

  _recordEvent(name: string) {
    this.intercomEventService.trackEvent(name, {
      app_id_code: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }

  @action
  async startFreeUsageExperience(location: string) {
    try {
      await this.intercomCallService.setupFreeUsageExperience();
      this.intercomCallService.nextCallIsTestCall = true;
      this.intercomCallService.registerCallEvent({
        action: 'clicked',
        object: 'start_free_usage_experience',
        place: 'calling_settings',
        section: location,
      });
      this.intercomCallService.openDialler();
      this.router.transitionTo('inbox.workspace.inbox', this.appService.app.id);
    } catch (e) {
      console.error(e);
    }
  }

  get hasNonFreePhoneNumber() {
    return this.phoneNumbers.any((number) => !number.isActiveFreeUsageNumber);
  }

  get isNumberProvisioningPrimary() {
    return !this.hasNonFreePhoneNumber && !this.settings.isFullTrialCredit;
  }

  get hasPhoneNumber() {
    return this.phoneNumbers.length > 0;
  }

  get settingsDisabled() {
    if (!this.args.settings.callingAllowed) {
      return true;
    }
    if (!this.args.isCallingSupported && !this.args.settings.legacyCustomerHasFreeUsageTrial) {
      return true;
    }
    return false;
  }

  get anyRecordingEnabled() {
    return this.recordingEnabled || this.recordingEnabledOutbound || this.recordingEnabledMessenger;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::BodyContent': typeof CallingBodyContent;
    'calling/settings/body-content': typeof CallingBodyContent;
  }
}
