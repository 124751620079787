/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import diffCalculator from 'embercom/lib/developer-hub/diff-calculator';

export default Fragment.extend({
  content: attr('', {
    defaultValue: () => [],
    containsBlocks: true,
    renderingComponentPath:
      'developer-hub/app-package/review/review-changes/renderable-starting-guide-blocks-value',
    humanisedName: 'Start guide content',
  }),
  isEqual(other) {
    return true;
  },
  compareWith(modelB) {
    return diffCalculator(this, modelB);
  },
});
