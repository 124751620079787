/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { empty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { valueOrDefault } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  tagName: '',
  store: service(),
  intercomEventService: service(),
  componentsJson: '[]',
  selectedPreviewStyle: valueOrDefault('previewStyle', 'messenger'),
  showPreviewStyleSelector: empty('previewStyle'),

  previewCanvas: computed('componentsJson', function () {
    let content = { components: this.parseComponentsJson(this.componentsJson) };
    return { content };
  }),

  parseComponentsJson(componentsJson) {
    try {
      let parsedComponentsJson = JSON.parse(componentsJson);
      if (!Array.isArray(parsedComponentsJson)) {
        parsedComponentsJson = [parsedComponentsJson];
      }
      return parsedComponentsJson;
    } catch (e) {
      return [];
    }
  },

  changePreviewTab: action(function (value) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed',
      object: 'canvas-kit-builder',
      selected_tab: value,
    });
    this.set('selectedPreviewStyle', value);
  }),
});
