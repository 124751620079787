/* import __COLOCATED_TEMPLATE__ from './alias-email-address-warning.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  toggleDisplayCustomWarningModal: () => void;
  showAliasEmailAddressWarning: boolean;
  emailAddress: string;
  toggleDisplayCustomSenderModal: () => void;
}

export default class AliasEmailAddressWarning extends Component<Args> {
  @service intercomEventService: $TSFixMe;

  @tracked showAddCustomSenderModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_alias_email_address_warning',
      });
    }
  }

  @action
  redirectToArticle() {
    safeWindowOpen('https://www.intercom.com/help/en/', '_blank');
  }

  @action
  closeAliasEmailAddressWarning() {
    this.args.toggleDisplayCustomWarningModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Senders::Modals::AliasEmailAddressWarning': typeof AliasEmailAddressWarning;
    'settings/senders/modals/alias-email-address-warning': typeof AliasEmailAddressWarning;
  }
}
