/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { sort, gt, readOnly, filterBy } from '@ember/object/computed';

export default Component.extend({
  store: service(),
  appService: service(),
  appId: readOnly('appService.app.id'),
  adminId: readOnly('app.currentAdmin.id'),
  notificationsService: service(),
  redirectService: service(),
  intercomConfirmService: service(),
  regionService: service(),

  subscriptions: sort('settings.subscriptions', function (subscriptionA, subscriptionB) {
    if (subscriptionA.primaryAccount) {
      return -1;
    } else if (subscriptionB.primaryAccount) {
      return 1;
    } else {
      return 0;
    }
  }),

  subscriptionsPresent: computed('subscriptions.[]', function () {
    return this.subscriptions && this.subscriptions.length > 0;
  }),

  moreThanOneSubscription: gt('subscriptions.length', 1),

  writeUnauthorisedSubscriptions: computed('subscriptions.[]', function () {
    return this.subscriptions.filter((subscription) => !subscription.stripeWriteAuthorised);
  }),

  writeAuthorisedSubscriptions: computed('subscriptions.[]', function () {
    return this.subscriptions.filter((subscription) => subscription.stripeWriteAuthorised);
  }),

  importFailedSubscriptions: filterBy('subscriptions', 'stripeImportFailed', true),

  syncedSubscriptions: computed('subscriptions.[]', function () {
    return this.subscriptions.filter((subscription) => subscription.syncEnabled);
  }),

  primaryAccount: computed('subscriptions.[]', function () {
    return this.subscriptions.find((subscription) => subscription.primaryAccount);
  }),

  writeAuthorisedForPrimary: computed('primaryAccount', function () {
    if (this.primaryAccount === undefined) {
      return false;
    }
    return this.primaryAccount.stripeWriteAuthorised;
  }),

  subscriptionsEnabledForPrimary: computed('primaryAccount', function () {
    if (this.primaryAccount === undefined) {
      return false;
    }
    return this.primaryAccount.subscriptionsEnabled;
  }),

  hasCandidatesForPrimaryAccount: gt('writeAuthorisedSubscriptions.length', 1),

  candidatesForPrimaryAccount: computed('writeAuthorisedSubscriptions', function () {
    return this.writeAuthorisedSubscriptions.map((subscription) => {
      return {
        text: subscription.stripeAccountName,
        value: subscription.stripeAccountId,
        isSelected: subscription.primaryAccount,
      };
    });
  }),

  redirectUrl: computed('adminId', 'appId', 'regionService.messengerAppsBaseURL', function () {
    return `${this.regionService.messengerAppsBaseURL}/stripe/add_account?app_id=${this.appId}&admin_id=${this.adminId}`;
  }),

  addAccount: task(function* () {
    try {
      let signedRedirectUrl = yield this.generateRedirectUrl.perform(this.redirectUrl, true);
      this.redirectService.redirect(signedRedirectUrl);
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),

  toggleSubscriptions: task(function* () {
    this.subscriptions.map((subscription) => {
      subscription.toggleProperty('subscriptionsEnabled');
    });
    try {
      yield this.settings.save();
      this.notificationsService.notifyConfirmation('Succesfully updated subscriptions settings');
    } catch (response) {
      let message;
      if (response.jqXHR.responseJSON !== undefined) {
        message = response.jqXHR.responseJSON.message;
      } else {
        message = 'Something went wrong updating subscriptions settings. Please try again.';
      }
      this.notificationsService.notifyError(message);
    }
  }).drop(),

  triggerImport: task(function* (subscription) {
    try {
      yield ajax({
        url: '/ember/stripe_settings/trigger_import',
        type: 'POST',
        data: JSON.stringify({ app_id: this.appId, stripe_user_id: subscription.stripeAccountId }),
      });
      this.notificationsService.notifyConfirmation('Import successfully retriggered');
    } catch (response) {
      let message;
      if (response.jqXHR && response.jqXHR.responseJSON) {
        message = response.jqXHR.responseJSON.message;
      } else {
        message =
          'There was a problem retriggering your import. Please try again. If the problem persists please contact support.';
      }
      this.notificationsService.notifyError(message);
    }
  }).drop(),

  removeAccount: task(function* (subscription) {
    try {
      let confirmed = yield this.intercomConfirmService.confirm({
        title: 'Remove Account',
        body: 'Are you sure you want to remove this account?',
        confirmButtonText: 'Remove',
      });

      if (confirmed) {
        yield this.settings.deleteSubscription(subscription);
        this.notificationsService.notifyConfirmation('Account removed successfully');
      }
    } catch (response) {
      let message;
      if (response.jqXHR && response.jqXHR.responseJSON) {
        message = response.jqXHR.responseJSON.message;
      } else {
        message = 'Something went wrong removing that account, try again';
      }
      this.notificationsService.notifyError(message);
    }
  }).drop(),

  init() {
    this._super(...arguments);
    this.fetchStripeSettings.perform();
  },

  fetchStripeSettings: task(function* () {
    let stripeSettings = yield this.store.queryRecord(
      'appstore/app-package/stripe/app-settings',
      {},
    );
    this.set('settings', stripeSettings);
  }).drop(),

  updatePrimary: action(function (primary) {
    this.subscriptions.map((subscription) => {
      subscription.set('primaryAccount', subscription.stripeAccountId === primary);
    });
    try {
      this.settings.save();
      this.notificationsService.notifyConfirmation('Primary account updated successfully');
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }),

  toggleSync: action(function (subscription) {
    subscription.toggleProperty('syncEnabled');
    try {
      this.settings.save();
      this.notificationsService.notifyConfirmation(
        `Successfully updated automatic sync for ${subscription.stripeAccountName}`,
      );
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }),
});
