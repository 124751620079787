/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { VBP2_PLAN_IDS } from 'embercom/lib/billing';

export default class UpgradeModal extends Component {
  @service customerService;
  @service permissionsService;
  @service paywallService;
  @service selfServeTrialsService;
  @service quoteService;
  @service appService;
  @service intercomEventService;
  @service router;

  constructor() {
    super(...arguments);
    this._captureIntercomEvent();
  }

  get app() {
    return this.appService.app;
  }

  get showSingleProductVersion() {
    return (
      this.app.isSalesforceContracted ||
      this.args.plan.selfServe ||
      !VBP2_PLAN_IDS.includes(this.args.plan.id)
    );
  }

  get hasBillingPermission() {
    return this.permissionsService.currentAdminCan('can_access_billing_settings');
  }

  get fakePlanPrice() {
    if (this.paywallService.shouldUseQuoteService(this.args.fakePlan.id)) {
      return this.quoteService.getQuoteById([this.args.fakePlan.idAsNumber]);
    }
    return this.customerService.getPriceFromPlanIds([this.args.fakePlan.idAsNumber]);
  }

  get planPrice() {
    // planPrice is only used for VBP2 and older pricing models
    return this.customerService.getPriceFromPlanIds([this.args.plan.idAsNumber]);
  }

  _captureIntercomEvent() {
    let featureKey = this.args.feature?.key;
    if (!featureKey) {
      return;
    }

    this.intercomEventService.trackEvent('paywall-seen', {
      featureKey,
      featureName: this.args.feature.name,
      url: this.router.currentURL,
    });
  }
}
