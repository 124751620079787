/* import __COLOCATED_TEMPLATE__ from './attribute-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { Type } from 'embercom/models/operator/visual-builder/attribute-descriptor';

export default class AttributeSelector extends Component {
  @service appService;
  @service attributeService;
  @service modalService;
  @service permissionsService;
  @tracked showAttributeCreationTypeModal = false;
  @tracked showConversationAttributeCreationModal = false;
  @tracked showTemporaryAttributeCreationModal = false;
  @tracked newTemporaryAttribute;
  @service intl;

  get footerActions() {
    // default to showing the footer action (if no arg is passed)
    if (this.args.showNewAttributeButton === undefined || this.args.showNewAttributeButton) {
      return [
        {
          text: this.intl.t('components.operator.attribute-selector.new-attribute'),
          icon: 'new',
          action: this.toggleAttributeCreationTypeModal,
        },
      ];
    }

    return null;
  }

  @action
  toggleAttributeCreationTypeModal() {
    // For Standalone apps we don't have the concept of creating attributes for Users, Companies, or Conversations
    // We do however want to support creating temporary attributes for Fin Processes. In these cases skip opening
    // the attribtue creation modal and go straight to creating a temporary attribute.
    if (this.appService.app.canUseStandalone) {
      this.showAttributeCreationTypeModal = false;
      return this.openAttributeModal('temporary');
    }

    this.showAttributeCreationTypeModal = !this.showAttributeCreationTypeModal;
  }

  @action
  openAttributeModal(type) {
    this.showAttributeCreationTypeModal = false;
    this.permissionsService.checkPermission('can_access_product_settings').then(() => {
      if (type === 'conversation') {
        this.showConversationAttributeCreationModal = true;
      } else if (type === 'temporary') {
        this.newTemporaryAttribute = this.args.createTemporaryAttribute('', Type.string);
        this.showTemporaryAttributeCreationModal = true;
      } else {
        this.modalService.openModal('settings/modals/attribute-details', {
          allowArchival: false,
          typeToCreate: type,
          availableTypes: this.args.availableTypes,
          showPeopleAndCompanyToggleTab: false,
          onSave: this.attributeCreated,
        });
      }
    });
  }

  @action
  attributeCreated(attribute) {
    this.attributeCreatedCallbacks(attribute);
  }

  @action
  conversationAttributeCreated(descriptor) {
    let attribute = this.attributeService.findByIdentifier(descriptor.identifier);
    this.attributeCreatedCallbacks(attribute);
  }

  attributeCreatedCallbacks(attribute) {
    this.args.onSelectAttribute(attribute);
    if (this.args.onAttributeCreated) {
      this.args.onAttributeCreated(attribute);
    }
  }
}
