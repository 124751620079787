/* RESPONSIBLE TEAM: team-frontend-tech */
/* eslint-disable promise/prefer-await-to-then */
import { modifier } from 'ember-modifier';
import ENV from 'embercom/config/environment';

const CONTAINER_ID = 'react-teammate-app-island-container';

export interface RenderReactTeammateAppProps {
  currentPath: string;
}

let reactRefreshPreambleLoaded = false;

const ensureReactRefreshPreambleLoaded = async () => {
  if (reactRefreshPreambleLoaded) {
    return;
  }

  reactRefreshPreambleLoaded = true;

  let reactRefreshPreamble = new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.src = 'http://embercom.test:4200/a/react-refresh.js';
    script.type = 'module';
    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });

  let viteClient = new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.src = 'http://localhost:5173/r/@vite/client';
    script.type = 'module';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });

  await Promise.all([reactRefreshPreamble, viteClient]);
};

const ensureReactScriptLoaded = () => {
  return new Promise((resolve, reject) => {
    if (window.ReactTeammateApp) {
      return resolve(undefined);
    }

    let script = document.createElement('script');
    script.src = ENV.APP.teammateAppIslandEntryUrl;
    script.type = 'module';
    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const createStyleLink = (shadowRoot: ShadowRoot) => {
  return new Promise((resolve, reject) => {
    let styleLink = document.createElement('link');
    styleLink.rel = 'stylesheet';
    styleLink.href = ENV.APP.teammateAppIslandStylesUrl;
    styleLink.onload = resolve;
    styleLink.onerror = reject;
    shadowRoot.appendChild(styleLink);
  });
};

const findOrCreateReactShadowRoot = async (element: Element) => {
  if (element.shadowRoot) {
    return element.shadowRoot;
  }

  let shadowRoot = element.attachShadow({ mode: 'open' });
  await createStyleLink(shadowRoot);

  return shadowRoot;
};

const renderReactApp = async (element: Element, props: RenderReactTeammateAppProps) => {
  if (ENV.environment === 'development') {
    await ensureReactRefreshPreambleLoaded();
  }

  await ensureReactScriptLoaded();

  if (!window.ReactTeammateApp) {
    throw new Error('ReactTeammateApp not found after loading script');
  }

  let shadowRoot = await findOrCreateReactShadowRoot(element);

  let container = shadowRoot.getElementById(CONTAINER_ID);
  if (container) {
    return;
  }

  container = document.createElement('div');
  container.id = CONTAINER_ID;
  container.className = 'h-full w-full';

  shadowRoot.appendChild(container);

  window.ReactTeammateApp.render(container, props);
};

const RenderReactTeammateAppIsland = modifier<
  Element,
  [RenderReactTeammateAppProps],
  { onAppReady: () => void; onError: (error: Error) => void }
>(
  (element, [props], { onAppReady, onError }) => {
    renderReactApp(element, props)
      .then(() => {
        onAppReady?.();
      })
      .catch((error) => {
        onError?.(error);
      });
  },
  {
    eager: false,
  },
);

export default RenderReactTeammateAppIsland;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'render-react-teammate-app-island': typeof RenderReactTeammateAppIsland;
  }
}
