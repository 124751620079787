/* RESPONSIBLE TEAM: team-ml */

import { type BlockList } from '@intercom/interblocks.ts';
import { tracked } from '@glimmer/tracking';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import { type NexusEvent } from 'embercom/services/nexus';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';

export const QUESTION_GENERATION_UPDATE_EVENT_NAME = 'FinPlaygroundQuestionGenerationUpdate';
import { type AnswerLength, type Tone } from 'embercom/components/operator/fin/setup/personality';

export enum AnswerState {
  Answered = 'answered',
  Unanswered = 'unanswered',
}

export const ANSWER_STATE_ANSWER_TYPE_MAP = {
  [AnswerState.Answered]: ['inline_answer'],
  [AnswerState.Unanswered]: ['clarification', 'bot_reply', 'inline_answer_with_disambiguation'],
};

export type PlaygroundWireFormat = Omit<
  KeysToSnakeCase<Playground>,
  'questions' | 'question_generation'
> & {
  questions: PlaygroundQuestionWireFormat[];
  question_generation: PlaygroundQuestionGenerationWireFormat;
};

export interface QuestionGenerationUpdateEvent extends NexusEvent {
  status: QuestionGenerationStatus;
  requires_user_acknowledgement: boolean;
  questions?: PlaygroundQuestionWireFormat[];
}

export type PlaygroundQuestionWireFormat = Omit<KeysToSnakeCase<PlaygroundQuestion>, 'sources'> & {
  sources: KeysToSnakeCase<PlaygroundQuestionSource>[];
};

export type PlaygroundQuestionGenerationJobWireFormat = {
  status: QuestionGenerationStatus;
  requires_user_acknowledgement: boolean;
};

export type PlaygroundQuestionGenerationWireFormat = {
  is_possible: boolean;
  job?: PlaygroundQuestionGenerationJobWireFormat;
};

export enum Status {
  Pending = 'pending',
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
}

export enum CustomerAnswerRating {
  Positive = 'positive',
  Negative = 'negative',
}

export enum CustomerRatingReason {
  DidNotUseRightContentSources = 'did_not_use_right_content_sources',
  DidNotClarifyCustomerQuestion = 'did_not_clarify_customer_question',
  ToneWasNotRight = 'tone_was_not_right',
  AnswerTooLong = 'answer_too_long',
  DidNotSpeakInRightLanguage = 'did_not_speak_in_right_language',
  Other = 'other',
}

export enum QuestionGenerationStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  EmptyResult = 'empty_result',
  Failed = 'failed',
  Running = 'running',
}

export interface AppliedGuideline {
  id: string;
  text: string;
}

export class PlaygroundQuestionSource {
  constructor(
    public entityId: number,
    public entityType: number,
    public entityData: {
      title: string;
    },
  ) {}

  get title() {
    return this.entityData.title;
  }

  get humanizedEntityTypeName() {
    return humanReadableObjectNames[this.entityType];
  }
}

export class PlaygroundQuestionGeneration {
  @tracked isPossible: boolean;
  @tracked latestJobStatus: QuestionGenerationStatus | undefined;
  @tracked latestJobRequiresUserAcknowledgement: boolean | undefined;

  constructor(
    isPossible: boolean,
    latestJobStatus: QuestionGenerationStatus | undefined,
    latestJobRequiresUserAcknowledgement: boolean | undefined,
  ) {
    this.isPossible = isPossible;
    this.latestJobStatus = latestJobStatus;
    this.latestJobRequiresUserAcknowledgement = latestJobRequiresUserAcknowledgement;
  }

  static deserialize(data: PlaygroundQuestionGenerationWireFormat): PlaygroundQuestionGeneration {
    return new PlaygroundQuestionGeneration(
      data.is_possible,
      data.job?.status,
      data.job?.requires_user_acknowledgement,
    );
  }

  updateLatestJobData(data: PlaygroundQuestionGenerationJobWireFormat) {
    this.latestJobStatus = data.status;
    this.latestJobRequiresUserAcknowledgement = data.requires_user_acknowledgement;
  }

  resetLatestJobData() {
    this.latestJobStatus = undefined;
    this.latestJobRequiresUserAcknowledgement = undefined;
  }

  get isRunning(): boolean {
    return this.latestJobStatus === QuestionGenerationStatus.Running;
  }
}

export class PlaygroundQuestion {
  @tracked questionText: string;
  @tracked status: Status;
  @tracked customerAnswerRatingNote: string;

  id: number | undefined;
  responseText: BlockList = [];
  sources: PlaygroundQuestionSource[] = [];
  customerAnswerRating?: CustomerAnswerRating;
  customerAnswerRatingReason?: CustomerRatingReason;
  answerType?:
    | 'inline_answer'
    | 'clarification'
    | 'bot_reply'
    | 'inline_answer_with_disambiguation';
  answerLastGeneratedAt: Date | undefined;
  aiToneOfVoice?: Tone;
  aiAnswerLength?: AnswerLength;
  appliedGuidelines: AppliedGuideline[];
  fallbackSearchLocale: string | undefined;

  constructor(
    id: number | undefined,
    questionText: string,
    aiToneOfVoice?: Tone,
    aiAnswerLength?: AnswerLength,
    appliedGuidelines?: AppliedGuideline[],
    responseText?: BlockList,
    sources?: PlaygroundQuestionSource[],
    status?: Status,
    answerType?: PlaygroundQuestion['answerType'],
    customerAnswerRating?: CustomerAnswerRating,
    answerLastGeneratedAt?: Date,
    customerAnswerRatingReason?: CustomerRatingReason,
    customerAnswerRatingNote?: string,
    fallbackSearchLocale?: string,
  ) {
    this.id = id;
    this.questionText = questionText;
    this.status = status ?? Status.Pending;
    this.responseText = responseText ?? [];
    this.sources = sources ?? [];
    this.answerType = answerType;
    this.customerAnswerRating = customerAnswerRating;
    this.answerLastGeneratedAt = answerLastGeneratedAt;
    this.customerAnswerRatingReason = customerAnswerRatingReason;
    this.aiToneOfVoice = aiToneOfVoice;
    this.aiAnswerLength = aiAnswerLength;
    this.appliedGuidelines = appliedGuidelines ?? [];
    this.customerAnswerRatingNote = customerAnswerRatingNote ?? '';
    this.fallbackSearchLocale = fallbackSearchLocale;
  }

  static deserialize(data: PlaygroundQuestionWireFormat): PlaygroundQuestion {
    let answerGeneratedAt: Date | undefined;

    if (data.answer_last_generated_at) {
      answerGeneratedAt = new Date(data.answer_last_generated_at);
    }

    return new PlaygroundQuestion(
      Number(data.id),
      data.question_text,
      data.ai_tone_of_voice,
      data.ai_answer_length,
      data.applied_guidelines,
      data.response_text,
      data.sources?.map(
        (s) => new PlaygroundQuestionSource(s.entity_id, s.entity_type, s.entity_data),
      ),
      data.status,
      data.answer_type,
      data.customer_answer_rating,
      answerGeneratedAt,
      data.customer_answer_rating_reason,
      data.customer_answer_rating_note,
      data.fallback_search_locale,
    );
  }

  get hasSources() {
    return this.sources?.length > 0;
  }

  get hasNoAnswer() {
    return this.answerType !== 'inline_answer';
  }

  get answerHasError() {
    return this.status === Status.Failed;
  }

  run() {
    this.status = Status.Running;
  }
}

export class Playground {
  @tracked questions = new TrackedArray<PlaygroundQuestion>([]);
  @tracked questionGeneration: PlaygroundQuestionGeneration;
  @tracked questionFilters = new TrackedObject<{
    ratingFilter: CustomerAnswerRating | null;
    answerTypeFilter: AnswerState | null;
  }>({
    ratingFilter: null,
    answerTypeFilter: null,
  });

  questionLimit: number;

  constructor(questions: PlaygroundQuestion[], questionGeneration: PlaygroundQuestionGeneration) {
    this.questions = new TrackedArray(questions);
    // eventually this will happen on the backend
    this.questionLimit = 50;
    this.questionGeneration = questionGeneration;
  }

  get filteredQuestions() {
    if (!this.questionFilters.ratingFilter && !this.questionFilters.answerTypeFilter) {
      return this.questions;
    }

    let filteredQuestions = this.questions.filter((question) => {
      if (this.questionFilters.ratingFilter && this.questionFilters.answerTypeFilter) {
        // If both filters are set, return question if it matches both conditions
        return (
          question.customerAnswerRating === this.questionFilters.ratingFilter &&
          question.answerType &&
          ANSWER_STATE_ANSWER_TYPE_MAP[this.questionFilters.answerTypeFilter].includes(
            question.answerType,
          )
        );
      }

      if (this.questionFilters.ratingFilter) {
        // If rating filter is set, return question if ratings match
        return question.customerAnswerRating === this.questionFilters.ratingFilter;
      }

      if (this.questionFilters.answerTypeFilter) {
        // If answerState filter is set, return question if answerStates match
        return (
          question.answerType &&
          ANSWER_STATE_ANSWER_TYPE_MAP[this.questionFilters.answerTypeFilter].includes(
            question.answerType,
          )
        );
      }

      return true;
      // This return is just to appease typescript, it should never be reached
    });

    return filteredQuestions;
  }

  get isPending() {
    return this.questions.every((q) => q.status === Status.Pending);
  }

  get isRunning() {
    return this.questions.some((q) => q.status === Status.Running);
  }

  get hasQuestions() {
    return this.questions.length > 0;
  }

  get isActive(): boolean {
    return this.hasQuestions || this.questionGeneration.isRunning;
  }

  get pendingQuestions() {
    return this.questions.filter((q) => q.status === Status.Pending);
  }

  get hasReachedQuestionLimit(): boolean {
    return this.questions.length >= this.questionLimit;
  }

  get remainingQuestionQuota(): number {
    if (this.hasReachedQuestionLimit) {
      return 0;
    }

    return this.questionLimit - this.questions.length;
  }

  addQuestions(questions: PlaygroundQuestion[]) {
    this.questions.push(...questions);
  }

  removeQuestion(questionText: string) {
    this.questions = new TrackedArray(
      this.questions.filter((q) => q.questionText !== questionText),
    );
  }

  removeQuestionById(id: number) {
    this.questions = new TrackedArray(this.questions.filter((q) => q.id !== id));
  }

  replaceQuestionByText(newQuestion: PlaygroundQuestion) {
    let index = this.questions.findIndex((q) => q.questionText === newQuestion.questionText);
    if (index === -1) {
      return;
    }
    this.questions[index] = newQuestion;
  }

  setRunningStatus(questions?: PlaygroundQuestion[]) {
    questions?.forEach((q) => q.run());
  }

  hasQuestion(questionText: string) {
    return this.questions.some((q) => q.questionText === questionText);
  }

  setQuestionState(id: number, status: Status) {
    let question = this.questions.find((q) => q.id === id);

    if (question) {
      question.status = status;
    }
  }

  updateQuestionRatingFilter(rating: CustomerAnswerRating | null) {
    this.questionFilters.ratingFilter = rating;
  }

  updateAnswerTypeFilter(filter: AnswerState | null) {
    this.questionFilters.answerTypeFilter = filter;
  }

  static deserialize(data: PlaygroundWireFormat): Playground {
    return new Playground(
      data.questions.map((q) => PlaygroundQuestion.deserialize(q)),
      PlaygroundQuestionGeneration.deserialize(data.question_generation),
    );
  }
}
