/* RESPONSIBLE TEAM: team-actions */
import { isEmpty } from '@ember/utils';
import diffCalculator from 'embercom/lib/developer-hub/diff-calculator';

const ROUTE_PREFIX = 'apps.app.developer-hub.app-packages.app-package.review';
const REVIEW_SHOW_ROUTE = `${ROUTE_PREFIX}.index`;
const REVIEW_EDIT_ROUTE = `${ROUTE_PREFIX}.edit`;
const REVIEW_NO_OAUTH_ROUTE = `${ROUTE_PREFIX}.no-oauth`;
const REVIEW_NO_APP_PARTNER_ROUTE = `${ROUTE_PREFIX}.no-app-partner`;

const isDraft = (changeRequest) => {
  return changeRequest.get('state') === 'draft';
};

const isInReview = (changeRequest) => {
  return changeRequest.get('state') === 'in_review';
};

const isActionRequired = (changeRequest) => {
  return changeRequest.get('state') === 'action_required';
};

const isInReviewOrActionRequired = (changeRequest) => {
  let cr = changeRequest;
  return isInReview(cr) || isActionRequired(cr);
};

const modelHasDiff = (model, changeRequestModel) => {
  if (isEmpty(changeRequestModel)) {
    return false;
  }

  if (isEmpty(model)) {
    return true;
  } else {
    return !isEmpty(diffCalculator(model, changeRequestModel));
  }
};

const appPackageHasChanges = (appPackage, changeRequest) => {
  let ap = appPackage;
  let crApc = changeRequest.get('appPackageConfig');
  let appPackageNoDiff = !isEmpty(diffCalculator(ap, crApc));
  let oauthClientNoDiff = !isEmpty(diffCalculator(ap.get('oauthClient'), crApc.get('oauthClient')));
  let appStoreListingNoDiff = modelHasDiff(ap.get('appStoreListing'), crApc.get('appStoreListing'));
  let messengerNoDiff = modelHasDiff(ap.get('messengerApp'), crApc.get('messengerApp'));
  let inboxAppNoDiff = modelHasDiff(ap.get('inboxApp'), crApc.get('inboxApp'));
  let startingGuideNoDiff = modelHasDiff(ap.get('startingGuide'), crApc.get('startingGuide'));
  let conditions = [
    appPackageNoDiff,
    oauthClientNoDiff,
    messengerNoDiff,
    inboxAppNoDiff,
    appStoreListingNoDiff,
    startingGuideNoDiff,
  ];
  return conditions.some((x) => x);
};

const shouldDisplayChangeDiff = (route, appPackage, changeRequest) => {
  let apHasChanges = appPackageHasChanges(appPackage, changeRequest);
  if (route === REVIEW_SHOW_ROUTE) {
    return apHasChanges && isInReviewOrActionRequired(changeRequest);
  } else if (route === REVIEW_EDIT_ROUTE) {
    return apHasChanges;
  }
};

const shouldDisplayDiscardButton = (appPackage, changeRequest) => {
  if (isInReviewOrActionRequired(changeRequest)) {
    return true;
  }
  return appPackageHasChanges(appPackage, changeRequest);
};

const shouldDisplayNoAppPartner = (appPartner, appPackage) => {
  return (
    !appPartner &&
    appPackage.builtBy !== 'Intercom' &&
    appPackage.currentApp.canShowDevHubAppPartner &&
    appPackage.changeRequest.appPackageConfig.appStoreListing
  );
};

export default function (appPackage) {
  let ap = appPackage;
  let cr = appPackage.get('changeRequest');
  let appPartner = appPackage.get('appPartner');
  let crApc = cr.get('appPackageConfig');

  if (!crApc.get('oauthClient.useOAuthFlow')) {
    return {
      routeTransition: REVIEW_NO_OAUTH_ROUTE,
    };
  } else if (shouldDisplayNoAppPartner(appPartner, ap)) {
    return {
      routeTransition: REVIEW_NO_APP_PARTNER_ROUTE,
    };
  } else if (isDraft(cr) || isActionRequired(cr)) {
    return {
      routeTransition: REVIEW_EDIT_ROUTE,
      displayDiscardButton: shouldDisplayDiscardButton(ap, cr),
      displayChangeDiff: shouldDisplayChangeDiff(REVIEW_EDIT_ROUTE, ap, cr),
    };
  }

  return {
    routeTransition: REVIEW_SHOW_ROUTE,
    displayDiscardButton: shouldDisplayDiscardButton(ap, cr),
    displayChangeDiff: shouldDisplayChangeDiff(REVIEW_SHOW_ROUTE, ap, cr),
  };
}
