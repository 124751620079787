/* import __COLOCATED_TEMPLATE__ from './setup-email-forwarding.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { type DomainEmailAddress } from 'embercom/services/domain-service';
import type { TaskGenerator } from 'ember-concurrency';
import type Owner from '@ember/owner';

interface Signature {
  Args: {
    emailAddress: DomainEmailAddress | undefined;
    changeStep: (step: string | null, place: string) => void;
    reloadModels: () => TaskGenerator<void>;
  };
}

export default class SetupEmailForwarding extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare mailForwardingValidationService: any;
  @service declare realTimeEventService: $TSFixMe;

  place = 'setup-email-forwarding';

  @tracked forwardingTaskFailed = false;

  constructor(owner: Owner, args: Signature['Args']) {
    super(owner, args);
    this.realTimeEventService.on('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
  }

  willDestroy() {
    this.realTimeEventService.off('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
    super.willDestroy();
  }

  get intercomEmailAddress() {
    return this.appService.app.inbox_email;
  }

  get verificationTask() {
    return taskFor(this.mailForwardingValidationService.verifyEmailForwarding);
  }

  get verification() {
    return this.mailForwardingValidationService.verification;
  }

  @action
  trackCopyButtonClick() {
    this.trackClickEvent('copy_intercom_email_address');
  }

  @action
  async verifyEmailForwarding() {
    if (!this.args.emailAddress) {
      return;
    }

    await this.mailForwardingValidationService.verifyEmailForwarding.perform(
      this.args.emailAddress.email,
      'domains-and-addresses',
    );
    if (!this.verification) {
      this.forwardingTaskFailed = true;
    }
    this.mailForwardingValidationService.unset();
  }

  @action
  requestForwardingVerification() {
    this.verifyEmailForwarding();
    this.trackClickEvent('send_test_email');
  }

  @action
  retryForwardingVerification() {
    // Keeping this seperated as we might want to add some logic here in the future
    this.verifyEmailForwarding();
    this.trackClickEvent('resend_test_email');
  }

  @action
  trackClickEvent(event: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: event,
      place: this.place,
      context: 'email-setup-sidebar',
    });
  }

  _handleForwardMailVerifiedMessage() {
    this.forwardingTaskFailed = false;
    taskFor(this.args.reloadModels).perform();
    this.args.changeStep('overview', this.place);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::SetupEmailForwarding': typeof SetupEmailForwarding;
    'new-settings/channels/email/domains-and-addresses/setup-email-forwarding': typeof SetupEmailForwarding;
  }
}
