/* import __COLOCATED_TEMPLATE__ from './action-button-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import defaultTo from '@intercom/pulse/lib/default-to';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  permissionsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  intl: service(),
  classNames: ['flex-none', 'u__mt__20'],
  beforeSave: defaultTo(() => true),
  afterSave: defaultTo(() => {}),
  feature: defaultTo(null),

  labelText: computed('app', 'enabled', 'intl.locale', function () {
    if (this.enabled) {
      return this.intl.t('operator.task-bots.turn-off');
    } else {
      return this.intl.t('operator.task-bots.turn-on');
    }
  }),

  primaryButtonType: computed('app.canSeeSimpleAutomations', function () {
    return this.app.canSeeSimpleAutomations ? 'primary' : 'primary-live';
  }),

  save: task(function* () {
    this.beforeSave();
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }
    try {
      yield this.model.save();
      this.afterSave();
      this.trackTaskSaved();
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.task-bots.notifications.settings-updated', {
          isFromSimpleAutomation: this.app.canSeeSimpleAutomations,
        }),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('operator.task-bots.notifications.settings-update-failed', {
          isFromSimpleAutomation: this.app.canSeeSimpleAutomations,
        }),
      });
    }
  }).drop(),

  validateAndSave() {
    if (this.get('model.isValid')) {
      return this.save.perform();
    } else {
      this.notificationsService.notifyError(
        this.get('model.currentQualificationType.validations.message'),
      );
    }
  },

  trackTaskSaved() {
    let action = this.enabled ? 'turned_on' : 'turned_off';
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: this.body.accordionAPI.sectionName || this.body.accordionAPI.openSectionId,
      place: 'task_bot_settings',
    });
  },

  actions: {
    toggleStateAndSave() {
      if (this.customToggle) {
        this.customToggle();
      } else {
        this.toggleProperty('enabled');
      }
      return this.validateAndSave();
    },

    enableValidateAndSave() {
      if (this.enabled !== undefined) {
        this.set('enabled', true);
      }
      return this.validateAndSave();
    },
  },
});
