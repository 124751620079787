/* import __COLOCATED_TEMPLATE__ from './read-only-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    tooltipKey: string;
  };
}

const ReadOnlyButton = templateOnlyComponent<Signature>();
export default ReadOnlyButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::ReadOnlyButton': typeof ReadOnlyButton;
  }
}
