/* RESPONSIBLE TEAM: team-growth-opportunities */
import type Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import type Router from '@ember/routing/router-service';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import type GuideLibraryService from './guide-library-service';

export default class MailForwardingValidationService extends Service {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare realTimeEventService: $TSFixMe;
  @service declare guideLibraryService: GuideLibraryService;

  @tracked verification: boolean | null = null;
  @tracked forwardMailSetupAttempt: $TSFixMe | null = null;
  @tracked retryAttempts = 0;
  @tracked tabLocation: string | null = null;

  // retry 3 times every 3 seconds - 12 sec total
  MAX_RETRY_ATTEMPTS = 2;
  RETRY_INTERVAL = 3000; // 3 seconds

  get app() {
    return this.appService.app;
  }

  registerListen() {
    this.realTimeEventService.on('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
  }

  unregisterListen() {
    this.realTimeEventService.off('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
    super.willDestroy();
  }

  unset() {
    this.verification = null;
    this.forwardMailSetupAttempt = null;
    this.retryAttempts = 0;
  }

  unsetVerification() {
    this.verification = null;
  }

  setVerification(status: boolean) {
    this.verification = status;
    this._notify(status);
  }

  setTabLocation(location: string) {
    this.tabLocation = location;
  }

  _notify(status: boolean) {
    if (status) {
      this.notificationsService.notifyConfirmation(
        this.intl.t('onboarding.home.steps.forward-emails.forwarding-validation.success'),
      );
    } else if (this.tabLocation === 'domains-and-addresses_edit-address') {
      this._sendNotificationForCheckForwardingFailed();
    } else {
      this.notificationsService.notifyError(
        sanitizeHtml(
          this.intl.t('onboarding.home.steps.forward-emails.forwarding-validation.failed'),
        ),
        30000, // show the error notification for 30 seconds
      );
    }
  }

  _handleForwardMailVerifiedMessage() {
    this.setVerification(true);
    if (this.guideLibraryService.canUseGuideLibraryService) {
      this.guideLibraryService.markStepCompleted(
        'guide_library_foundational_steps_setup_omnichannel_v2',
      );
    }
    this._trackAnalyticsEvent({
      action: 'completed',
      object: 'send_test_email',
      context: 'nexus_event',
    });
  }

  async _verifyEmailForwarding(email: string) {
    return await ajax({
      url: '/ember/forward_mail_setup_attempts/verify',
      type: 'GET',
      data: {
        app_id: this.app.id,
        forwarding_email_address: email,
      },
    });
  }

  async _createSetupAttempt(email: string) {
    this.forwardMailSetupAttempt = await this.store
      .createRecord('forward-mail-setup-attempt', {
        appId: this.app.id,
        forwardingEmailAddress: email,
      })
      .save();
  }

  async _sendForwardingEmail(email: string) {
    await ajax({
      url: '/ember/forward_emails',
      data: JSON.stringify({
        app_id: this.app.id,
        email,
      }),
      type: 'POST',
    });
  }

  @dropTask
  *verifyEmailForwarding(email: string, tabLocation = 'email-forwarding'): TaskGenerator<void> {
    this.registerListen();
    this.verification = null;
    this.setTabLocation(tabLocation);
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'send_test_email',
      context: 'verify_email_forwarding',
    });

    let verification;

    yield this._createSetupAttempt(email);
    yield this._sendForwardingEmail(email);

    this.retryAttempts = 0;
    verification = yield this._verifyEmailForwarding(email);

    if (!verification) {
      try {
        verification = yield this._retryVerification(email);
      } catch (e) {
        captureException(e, {
          fingerprint: ['forward-emails', 'verify_email_forwarding'],
        });
        this.retryAttempts = 0;
      }
    }

    if (!this.verification) {
      this.setVerification(verification);
    }

    this._publishVerificationAnalytics(this.verification);
    this.unregisterListen();
  }

  async _retryVerification(email: string) {
    let verification = false;
    while (!verification && this.retryAttempts < this.MAX_RETRY_ATTEMPTS) {
      await timeout(this.RETRY_INTERVAL);
      this.retryAttempts += 1;
      verification = await this._verifyEmailForwarding(email);
      if (verification) {
        break;
      }
    }
    return verification;
  }

  _publishVerificationAnalytics(verification: boolean | null) {
    this._trackAnalyticsEvent({
      action: verification ? 'completed' : 'failed',
      context: 'verify_endpoint',
      object: 'send_test_email',
    });
  }

  _trackAnalyticsEvent(event: $TSFixMe) {
    this.intercomEventService.trackAnalyticsEvent(event);
  }

  _sendNotificationForCheckForwardingFailed() {
    this.notificationsService.notifyError(
      this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.edit-address.email-forwarding-enabled.failed-notification',
      ),
    );
  }
}
