/* RESPONSIBLE TEAM: team-standalone */
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { EntityType } from 'embercom/models/data/entity-types';
import type { BlockList } from '@intercom/interblocks.ts';

interface GenericHandoffWireFormat {
  renderable_type: RenderableType;
  blocks: BlockList;
}

export default class GenericHandoff implements RenderableData {
  readonly renderableType = RenderableType.GenericHandoff;
  readonly blocks: BlockList;

  constructor(blocks: BlockList) {
    this.blocks = blocks;
  }

  get adminOnly() {
    return false;
  }

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  static deserialize(json: GenericHandoffWireFormat): GenericHandoff {
    return new GenericHandoff(json.blocks);
  }
}
