/* RESPONSIBLE TEAM: team-standalone */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import type HandoffToZendeskAgent from 'embercom/models/operator/visual-builder/step/handoff-to-zendesk-agent';

export default class ZendeskConversationsApiIsConnectedForIntercomMessenger extends BaseValidator {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;

  async validate(
    _value: unknown,
    options: ValidationOptions & { messageKey: string },
    model: HandoffToZendeskAgent,
  ) {
    await this.waitForZendeskConfiguration();

    if (model.isConnectedForIntercomMessenger) {
      return true;
    }

    return this.intl.t(options.messageKey);
  }

  async waitForZendeskConfiguration() {
    // This works under the assumption that the Zendesk configuration is loaded or loading.
    // As part of initializing the finStandaloneService.
    if (!taskFor(this.finStandaloneService.loadZendeskConfig).isRunning) {
      return;
    }

    return taskFor(this.finStandaloneService.loadZendeskConfig).last!;
  }
}
