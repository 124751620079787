/* import __COLOCATED_TEMPLATE__ from './wordpress.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    disabled?: boolean;
  };
}

const Wordpress = templateOnlyComponent<Signature>();
export default Wordpress;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::Wordpress': typeof Wordpress;
  }
}
