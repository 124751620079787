/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type RouterService from '@ember/routing/router-service';

interface Signature {
  Args: {
    activeContentId: number;
    activeContentType: string;
    editorMode?: 'view' | 'edit';
  };
  Blocks: {
    default: [];
  };
  Element: HTMLAnchorElement;
}

export default class Link extends Component<Signature> {
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare router: RouterService;

  get drawerEditorQueryParams() {
    return {
      activeContentId: this.args.activeContentId,
      activeContentType: this.args.activeContentType,
      editorMode: this.args.editorMode ?? 'view',
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::DrawerEditor::Link': typeof Link;
    'knowledge-hub/content-editor/drawer-editor/link': typeof Link;
  }
}
