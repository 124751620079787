/* RESPONSIBLE TEAM: team-knowledge-interop */
import { type AsyncHasMany, type SyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { FinAvailability } from 'embercom/lib/ai-content-library/constants';
import { put } from 'embercom/lib/ajax';
import { EntityType } from '../data/entity-types';
import type ContentFragment from './content-fragment';
import { State as OutboundState } from 'embercom/models/data/matching-system/matching-constants';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import type ContentReviewRequest from 'embercom/models/content-service/content-review-request';
import type IntlService from 'ember-intl/services/intl';
import { isLocaleSupported } from 'embercom/components/content-service/ai-content-library/helpers';
import { MonolingualKnowledgeContentModel } from 'embercom/objects/knowledge-hub/knowledge-hub-content';
import type Folder from './folder';
import type FolderMembership from './folder-membership';
import { type LocalizedKnowledgeContentVersion } from 'embercom/objects/knowledge-hub/localized-knowledge-content-version';
import { type Taggable } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import type Tag from 'embercom/models/tag';
import type Admin from 'embercom/models/admin';
import { UPDATE_TAGGING_URL } from 'embercom/lib/knowledge-hub/constants';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import type ContentSnippetVersion from './content-snippet-version';
/* eslint-disable-next-line ember/no-computed-properties-in-native-classes */
import { computed, set } from '@ember/object';

export default class ContentSnippet
  extends MonolingualKnowledgeContentModel
  implements LocalizedKnowledgeContentVersion
{
  @attr('number') declare copilotAvailability: FinAvailability;
  @attr('number') declare chatbotAvailability: FinAvailability;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('number') declare lastUpdatedBy: number;
  @attr('number') declare createdBy: number;
  @attr('number') declare contentReviewStatus: number;
  @attr('array') declare aiContentSegmentIds: Array<number> | [];
  @belongsTo('content-service/content-review-request', { async: true })
  declare contentReviewRequest: ContentReviewRequest;
  @attr('string') declare locale: string;
  @belongsTo('content-service/folder', { async: true }) declare folder?: Folder;
  @belongsTo('content-service/folder-membership', { async: false })
  declare folderMembership?: FolderMembership;
  @belongsTo('content-service/content-snippet-version', { async: false })
  declare currentVersion?: ContentSnippetVersion;
  @hasMany('tag', { async: true }) declare tags: AsyncHasMany<Tag>;
  @hasMany('tagging', { async: false }) declare taggings: SyncHasMany<any>;

  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service declare appService: any;

  entityType: number = EntityType.ContentSnippet;

  get titleIsBlank() {
    return isBlank(this.title);
  }

  get title() {
    return this.latestVersion?.title || '';
  }

  set title(title: string) {
    this.newOrCurrentVersion.title = title;
  }

  get jsonBlocks() {
    return this.latestVersion?.jsonBlocks;
  }

  get isPendingReview() {
    return this.contentReviewStatus === Status.Pending;
  }

  get jsonBlocksHasContent() {
    if (this.serializedBlocks === 0) {
      return false;
    }
    return this.latestVersion?.jsonBlocks
      .serialize()
      .any((block: any) => block.hasOwnProperty('text') && !isBlank(block.text));
  }

  // This will eventually be two separate toggles in the agnostic editor - one for chatbot and one for copilot
  // For now we'll switch to using the bulk enabled/disable endpoints so we're being explicit about what we're toggling
  async toggleAllAvailabilitiesAndSave() {
    if (this.isUsedByChatbot) {
      await this.setChatbotAvailability(this.appService.app, FinAvailability.OFF);
      await this.setCopilotAvailability(this.appService.app, FinAvailability.OFF);
    } else {
      await this.setChatbotAvailability(this.appService.app, FinAvailability.ON);
      await this.setCopilotAvailability(this.appService.app, FinAvailability.ON);
    }
  }

  toggleAllAvailabilities() {
    if (this.isUsedByChatbot) {
      this.copilotAvailability = FinAvailability.OFF;
      this.chatbotAvailability = FinAvailability.OFF;
    } else {
      this.copilotAvailability = FinAvailability.ON;
      this.chatbotAvailability = FinAvailability.ON;
    }
  }

  async setChatbotAvailability(app: any, newAvailability: FinAvailability) {
    let endpoint =
      newAvailability === FinAvailability.ON
        ? 'bulk_enable_chatbot_availability'
        : 'bulk_disable_chatbot_availability';
    await put(`/ember/knowledge_base/bulk_actions/${endpoint}`, {
      app_id: app.id,
      contents_to_update: [{ entity_id: this.id, entity_type: EntityType.ContentSnippet }],
    });

    this.chatbotAvailability = newAvailability;
    this.updateListItemFragmentState();
  }

  async setCopilotAvailability(app: any, newAvailability: FinAvailability) {
    let endpoint =
      newAvailability === FinAvailability.ON
        ? 'bulk_enable_copilot_availability'
        : 'bulk_disable_copilot_availability';
    await put(`/ember/knowledge_base/bulk_actions/${endpoint}`, {
      app_id: app.id,
      contents_to_update: [{ entity_id: this.id, entity_type: EntityType.ContentSnippet }],
    });

    this.copilotAvailability = newAvailability;
    this.updateListItemFragmentState();
  }

  private updateListItemFragmentState() {
    let associatedListItemFragment = this.associatedListItemFragment;
    if (associatedListItemFragment) {
      if (this.chatbotAvailability === FinAvailability.ON) {
        associatedListItemFragment.state = OutboundState.Live;
      } else {
        associatedListItemFragment.state = OutboundState.Paused;
      }
    }
  }

  get associatedListItemFragment() {
    return this.store.peekRecord(
      'content-service/content-fragment',
      `${EntityType.ContentSnippet}_${this.id}`,
    ) as ContentFragment | undefined;
  }

  get lastUpdatedByTeammate() {
    return this.store.peekRecord('admin', this.lastUpdatedBy);
  }

  get createdByTeammate() {
    return this.store.peekRecord('admin', this.createdBy);
  }

  get serializedBlocks() {
    return this.latestVersion?.jsonBlocks.serialize();
  }

  get firstJsonBlockAsText(): any | undefined {
    let serializedBlocks = this.serializedBlocks;

    if (!serializedBlocks) {
      return;
    }
    return serializedBlocks[0].text;
  }

  get language(): string | void {
    if (this.locale) {
      return this.intl.languageNameFromCode(this.locale);
    }
  }

  get isUsedByChatbot() {
    return this.chatbotAvailability === FinAvailability.ON && isLocaleSupported(this.locale);
  }

  // start: KnowledgeHubContent / KnowledgeHubItemInterface

  async updateLanguageCode(languageCode: string) {
    this.locale = languageCode;

    try {
      await this.save();

      let associatedListItemFragment = this.associatedListItemFragment;
      if (associatedListItemFragment) {
        associatedListItemFragment.locale = languageCode;
      }

      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.file-content.language-update.success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('ai-content-library.file-content.language-update.error'),
      });
    }
  }

  get editorConfig(): KnowledgeHubEditorConfig {
    let sidePanelConfig = [{ name: 'knowledge-hub/content-editor/snippet/data-section' }];

    if (this.appService.app.canUseExternalAi) {
      sidePanelConfig.push({
        name: 'knowledge-hub/content-editor/shared/fin-section',
      });
    }

    if (!this.appService.app.canUseStandalone) {
      sidePanelConfig.push({ name: 'knowledge-hub/content-editor/shared/tags-section' });
    }

    sidePanelConfig.push({ name: 'knowledge-hub/content-editor/folder-section' });

    return {
      viewMode: {
        headerConfig: { name: 'knowledge-hub/content-editor/snippet/view/header' },
        editorConfig: { name: 'knowledge-hub/content-editor/snippet/view/editor' },
        sidePanelConfig,
      },
      editMode: {
        headerConfig: { name: 'knowledge-hub/content-editor/snippet/edit/header' },
        editorConfig: { name: 'knowledge-hub/content-editor/snippet/edit/editor' },
        sidePanelConfig,
      },
    };
  }

  @computed('hasDirtyAttributes', 'latestVersion.hasDirtyAttributes')
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.latestVersion?.hasDirtyAttributes;
  }

  get chatbotAvailabilityOption(): FinAvailability {
    return this.chatbotAvailability;
  }

  set chatbotAvailabilityOption(availability: FinAvailability) {
    this.chatbotAvailability = availability;
  }

  get copilotAvailabilityOption(): FinAvailability {
    return this.copilotAvailability;
  }

  set copilotAvailabilityOption(value: FinAvailability) {
    this.copilotAvailability = value;
  }

  get parentIdFromRelationship(): string | undefined {
    // @ts-ignore - TS doesn't like the 'folder' key here for some reason
    let folderRelationship = this.belongsTo('folder');
    return folderRelationship?.id();
  }

  get parent() {
    return this.folder;
  }

  set parent(parent: Folder | undefined) {
    this.folder = parent;
    this.onParentChange(parent);
  }

  get newOrCurrentVersion(): ContentSnippetVersion {
    if (!this.latestVersion) {
      // This has to use `set` because of the use of computed properties for saving / unsaved changes
      set(
        this,
        'currentVersion',
        this.store.createRecord('content-service/content-snippet-version', {
          createdBy: this.app.currentAdmin.id,
        }),
      );
    }
    return this.currentVersion!;
  }

  get displayName(): string {
    return (
      this.latestVersion?.title ||
      this.intl.t('ai-content-library.list.cells.title.untitled', {
        objectName: this.humanReadableContentName,
      })
    );
  }

  get isEditable() {
    return true;
  }

  get latestVersion() {
    return this.currentVersion;
  }

  get localization() {
    return this;
  }

  get versionTitle(): string {
    return this.latestVersion?.title || '';
  }

  set versionTitle(value: string) {
    this.newOrCurrentVersion.title = value;
  }

  get creatorId() {
    return this.createdBy;
  }

  get updaterId() {
    return this.lastUpdatedBy;
  }

  get taggable(): Taggable {
    return {
      tags: this.tags,
      taggings: this.taggings,
      type: 'content-snippet',
      updateTaggings: async (
        _admin: Admin,
        addedTags: Tag[],
        removedTags: Tag[],
        _initialTags: Tag[],
      ) => {
        return await this.updateTaggings(addedTags, removedTags);
      },
    };
  }

  // end: KnowledgeHubContent / KnowledgeHubItemInterface

  async updateTaggings(addedTags: Tag[], removedTags: Tag[]) {
    await put(UPDATE_TAGGING_URL, {
      app_id: this.app.id,
      added_tag_ids: addedTags.map((tag) => tag.id),
      removed_tag_ids: removedTags.map((tag) => tag.id),
      content_wrapper_id: this.id,
      content_wrapper_type: ContentWrapperType.SUPPORT_CONTENT,
      entity_type: EntityType.ContentSnippet,
      entity_id: this.id,
    });
  }
}
