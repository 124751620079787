/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { computed } from '@ember/object';
import { map } from '@ember/object/computed';
import diffCalculator from 'embercom/lib/developer-hub/diff-calculator';
import {
  buildVideoUrl,
  buildEmbeddableVideoUrl,
  parseVideoUrl,
} from 'embercom/lib/video-embed-url';

export default Fragment.extend({
  learnMoreUrl: attr('string', { humanisedName: 'Learn more URL' }),
  publiclyAvailable: attr('boolean', { noDiff: true }),
  imageUrls: attr('array', {
    defaultValue: () => [],
    humanisedName: 'Screenshots for your app',
    renderingComponentPath:
      'developer-hub/app-package/review/review-changes/screenshots-list-value',
  }),
  categories: attr('array', {
    defaultValue: () => [],
  }),
  longDescription: attr('', {
    defaultValue: () => [],
    containsBlocks: true,
    renderingComponentPath:
      'developer-hub/app-package/review/review-changes/renderable-blocks-value',
    humanisedName: 'Long description',
  }),

  videoId: attr('string', {
    humanisedName: 'Promotional Video',
    renderingComponentPath: 'developer-hub/app-package/review/review-changes/video-id-value',
  }),
  videoProvider: attr('string', { noDiff: true }),
  videoUrl: computed('videoId', 'videoProvider', {
    get() {
      return buildVideoUrl(this.videoId, this.videoProvider);
    },
    set(_, value) {
      if (value.length > 0) {
        let { provider, id } = parseVideoUrl(value);
        this.setProperties({ videoProvider: provider, videoId: id });
      } else {
        this.setProperties({ videoProvider: undefined, videoId: undefined });
      }
      return value;
    },
  }),
  videoEmbedUrl: computed('videoId', 'videoProvider', function () {
    return buildEmbeddableVideoUrl(this.videoId, this.videoProvider);
  }),

  appStoreCategories: map('categories', function (category) {
    return this.store.peekAll('appstore/category').findBy('slug', category);
  }),

  compareWith(modelB) {
    return diffCalculator(this, modelB);
  },
});
