/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import { assert } from '@ember/debug';
import generateUUID from 'embercom/lib/uuid-generator';

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import type Group from './group';
import type ConnectionPoint from './connection-point';
import type Store from '@ember-data/store';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import { type AllChannels, INTERCOM_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { validator, buildValidations } from 'ember-cp-validations';
import type PathConfig from 'embercom/objects/visual-builder/configuration/path';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const NON_SENSITIVE_STEP_KEYS = ['group', 'id', 'outward_connection_points', 'type', '_uuid'];

export type CreateStepParams = {
  attributeIdentifier?: string;
  createEmptyBlock?: boolean;
  customAction?: InsertableAction;
  listTemplates?: boolean;
};

export enum IsCustomerFacing {
  No = 0,
  Yes = 1,
  Maybe = 2,
}

const Validations = buildValidations({
  supportedChannels: [validator('workflow-step-supported-by-channel')],
  supportedContexts: [validator('workflow-step-supported-by-context')],
  requiredBillingFeature: [validator('workflow-step-has-billing-feature')],
  localVariablesExist: [validator('workflow-local-variables-exist')],
});

export default class Step extends Model.extend(Validations) {
  @service declare appService: any; // We need this to detect changes to feature keys and refresh validations
  @belongsTo('operator/visual-builder/group', { async: false, inverse: 'steps' })
  declare group: Group;
  @attr('string') declare type: string;
  @attr('boolean', { defaultValue: false }) declare useAiAnswers: boolean;

  @hasMany('operator/visual-builder/connection-point', { async: false, inverse: 'step' })
  declare outwardConnectionPoints: SyncHasMany<ConnectionPoint>;

  @readOnly('group.editorConfig') declare editorConfig?: EditorConfig;

  justAdded = false;

  get supportedChannels(): Readonly<AllChannels[]> {
    return INTERCOM_CHANNELS;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation];
  }

  get localVariableIdentifiersUsed(): string[] {
    return [];
  }

  get analyticsData() {
    return {
      ...(this.group?.workflow?.analyticsData ?? {}),
      object: 'visual_builder_step',
      step_id: this.id,
      step_type: this.type,
      is_group_ending_step: this.isGroupEnding,
      is_starting_group: this.group?.isStart,
      num_outward_connection_points: this.outwardConnectionPoints.length,
    };
  }

  @computed('editorConfig', 'group.pathConfig')
  get stepConfig() {
    return this.editorConfig?.generateStepConfig({
      step: this,
      pathConfig: this.group.pathConfig as PathConfig,
    });
  }

  static get isStepGroupEnding() {
    return false;
  }

  get isGroupEnding() {
    // https://github.com/Microsoft/TypeScript/issues/3841
    return (this.constructor as typeof Step).isStepGroupEnding;
  }

  static get isStepTerminal() {
    return false;
  }

  get isTerminal() {
    return (this.constructor as typeof Step).isStepTerminal;
  }

  static get canBeCustomerFacing(): IsCustomerFacing {
    return IsCustomerFacing.No;
  }

  // we need an instance specific accessor as well for steps that require dynamic inference
  get isCustomerFacing() {
    let Class = this.constructor as typeof Step;
    if (Class.canBeCustomerFacing === IsCustomerFacing.Maybe) {
      throw new Error(
        `Step ${Class.name} must implement isCustomerFacing getter when it is maybe customer facing`,
      );
    }
    return !!Class.canBeCustomerFacing;
  }

  get typeKey() {
    return this.type.split('/').pop();
  }

  static get requiredBillingFeature(): string | null {
    return null;
  }

  @computed('hasDirtyAttributes', 'outwardConnectionPoints.@each.hasUnsavedChanges')
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.outwardConnectionPoints.isAny('hasUnsavedChanges');
  }

  rollbackAttributes() {
    let outwardConnectionPoints = this.outwardConnectionPoints.toArray();
    outwardConnectionPoints.forEach((connectionPoint) => connectionPoint.rollbackAttributes());
    super.rollbackAttributes();
  }

  static createNewStep(_store: Store, _params?: CreateStepParams): Step {
    assert(`Model for step type must declare static createNewStep() method`);
  }

  static sanitizePayload(stepPayload: { [key: string]: any }) {
    // keep only keys in NON_SENSITIVE_STEP_KEYS
    Object.keys(stepPayload).forEach((key) => {
      if (!NON_SENSITIVE_STEP_KEYS.includes(key)) {
        delete stepPayload[key];
      }
    });
  }

  // private - only to be used by serializers
  @attr('string', { defaultValue: () => generateUUID() }) declare _uuid: string;
}
