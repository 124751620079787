/* import __COLOCATED_TEMPLATE__ from './team-select.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { generateSafeEmoji } from 'embercom/lib/emoji';

export default class TeamSelect extends Component {
  @service appService;

  get sortedTeams() {
    return this.appService.app.teams.sortBy('name');
  }

  get teamSelectItems() {
    if (this.sortedTeams.length === 0) {
      return [
        {
          value: null,
          isDisabled: true,
          component: 'settings/conversation-attributes/team-select-empty-item',
        },
      ];
    }
    return this.sortedTeams.map(({ name, id, avatar_emoji }) => ({
      text: name,
      value: id,
      isSelected: (this.args.selectedTeams || []).includes(id),
      component: 'settings/conversation-attributes/team-select-item',
      componentAttrs: { teamAvatar: generateSafeEmoji(avatar_emoji) },
    }));
  }

  get openerLabel() {
    let sortedTeamIds = this.sortedTeams.map((team) => team.id);
    let selectedTeamIds = this.args.selectedTeams?.filter((id) => sortedTeamIds.includes(id));

    if (isEmpty(selectedTeamIds)) {
      return 'Select teams';
    }

    // map selected team ids to team emojis and names
    return selectedTeamIds
      .map(
        (id) =>
          `${this.sortedTeams.find((team) => team.id === id).avatar_emoji} ${
            this.sortedTeams.find((team) => team.id === id).name
          }`,
      )
      .join(', ');
  }

  @action selectionChanged(newSelection) {
    this.args.onSelectionChanged(newSelection);
  }
}
