/* RESPONSIBLE TEAM: team-workflows */

import Model, { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import type { EntityType } from 'embercom/models/data/entity-types';
import type AttributeDescriptorListOption from './attribute-descriptor-list-option';
import { iconByType } from 'embercom/components/workflows/graph-editor/helpers/template-helpers';

export enum Type {
  string = 'string',
  integer = 'integer',
  float = 'float',
  date = 'date',
  boolean = 'boolean',
  workflow_attribute_descriptor_list = 'workflow_attribute_descriptor_list',
  object = 'object',
  array = 'array',
}

export type SourceObject = {
  type: EntityType;
  id: string;
  path: string;
};

export type ChildAttributeDescriptor = {
  id: string;
  source_path: string;
};

export const LOCAL_VARIABLE_IDENTIFIER_PREFIX = 'workflow_local_variables';

export default class AttributeDescriptor extends Model {
  @attr('string') declare name: string;
  @attr('string') declare _description: string;
  @attr('string') declare type: Type;
  @attr('boolean') declare referenceOnly: boolean;
  @attr() declare sourceObject: SourceObject;
  @attr('boolean', { defaultValue: false }) declare archived: boolean;

  @hasMany('operator/visual-builder/attribute-descriptor', { async: false, inverse: null })
  declare _childDescriptors: SyncHasMany<AttributeDescriptor>;

  @belongsTo('operator/visual-builder/workflow', {
    async: false,
    inverse: 'attributeDescriptors',
  })
  declare workflow: Workflow;

  @hasMany('operator/visual-builder/attribute-descriptor-list-option', { async: false })
  declare listOptions: AttributeDescriptorListOption[];

  get childDescriptors(): AttributeDescriptor[] {
    return this._childDescriptors.filter((descriptor) => !descriptor.archived).toArray();
  }

  get children(): ChildAttributeDescriptor[] {
    return this.childDescriptors.map((descriptor) => ({
      id: descriptor.id,
      source_path: descriptor.sourceObject?.path || '',
    }));
  }

  get identifier(): string {
    return `${LOCAL_VARIABLE_IDENTIFIER_PREFIX}.${this.id}`;
  }

  get templatable_identifier(): string {
    return this.identifier;
  }

  get description(): string {
    return this._description || '.'; // TODO(dlmiddlecote): This is a horrible hack to make sure the description has at least some value so the tooltip appears.
  }

  set description(value: string) {
    this._description = value;
  }

  get icon() {
    return iconByType(this.type);
  }

  get arrayItemDescriptor(): AttributeDescriptor | null {
    if (this.type !== Type.array) {
      return null;
    }
    return this.childDescriptors.firstObject as AttributeDescriptor;
  }

  get allDescendantDescriptors(): AttributeDescriptor[] {
    let descriptors = [];
    let queue = this._childDescriptors.toArray();
    while (queue.length > 0) {
      let attribute = queue.shift();
      if (attribute) {
        descriptors.push(attribute);
        queue.push(...attribute._childDescriptors.toArray());
      }
    }
    return descriptors;
  }

  get allDescendantDescriptorIdentifiers(): string[] {
    return this.allDescendantDescriptors.map((descriptor) => descriptor.identifier);
  }

  get displayDescription(): string {
    return this.description || this.name;
  }
}
