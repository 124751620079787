/* RESPONSIBLE TEAM: team-knowledge-interop */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const OTHER_ICONS = [
  'scissors',
  'home',
  'globe-alt',
  'home-modern',
  'ticket',
  'map',
  'shopping-bag',
  'globe-asia-australia',
  'key',
  'beaker',
  'globe-europe-africa',
  'musical-note',
  'cake',
  'trophy',
  'truck',
  'receipt-percent',
  'lifebuoy',
  'language',
  'light-bulb',
  'gift',
  'puzzle-piece',
  'globe-americas',
  'rocket-launch',
  'photo',
  'film',
  'academic-cap',
];

const otherCommonKeywords = ['miscellaneous', 'other', 'random'];
const globeCommonKeywords = [
  'globe',
  'world',
  'international',
  'global',
  'earth',
  'geography',
  'map',
  'planet',
];

export const OTHER_KEYWORDS = {
  [OTHER_ICONS[0]]: [
    ...otherCommonKeywords,
    'scissors',
    'cutting',
    'tools',
    'craft',
    'trimming',
    'office',
    'slicing',
    'grooming',
    'utility',
  ],
  [OTHER_ICONS[1]]: [
    ...otherCommonKeywords,
    'home',
    'house',
    'building',
    'residence',
    'living',
    'property',
    'estate',
  ],
  [OTHER_ICONS[2]]: [...otherCommonKeywords, ...globeCommonKeywords, 'network'],
  [OTHER_ICONS[3]]: [...otherCommonKeywords, 'modern', 'house', 'architecture', 'property'],
  [OTHER_ICONS[4]]: [
    ...otherCommonKeywords,
    'ticket',
    'event',
    'admission',
    'pass',
    'entry',
    'voucher',
    'reservation',
  ],
  [OTHER_ICONS[5]]: [
    ...otherCommonKeywords,
    'map',
    'navigation',
    'location',
    'geography',
    'travel',
    'directions',
  ],
  [OTHER_ICONS[6]]: [
    ...otherCommonKeywords,
    'bag',
    'retail',
    'store',
    'purchase',
    'shopping',
    'ecommerce',
    'checkout',
    'buy',
  ],
  [OTHER_ICONS[7]]: [...otherCommonKeywords, ...globeCommonKeywords, 'Asia', 'Australia'],
  [OTHER_ICONS[8]]: [
    ...otherCommonKeywords,
    'key',
    'security',
    'access',
    'unlock',
    'lock',
    'tool',
    'password',
    'authentication',
    'privacy',
  ],
  [OTHER_ICONS[9]]: [
    ...otherCommonKeywords,
    'beaker',
    'science',
    'experiment',
    'laboratory',
    'chemistry',
    'test',
    'research',
  ],
  [OTHER_ICONS[10]]: [...otherCommonKeywords, ...globeCommonKeywords, 'Europe', 'Africa'],
  [OTHER_ICONS[11]]: [
    ...otherCommonKeywords,
    'musical',
    'note',
    'music',
    'sound',
    'melody',
    'rhythm',
    'song',
    'audio',
    'notation',
    'composition',
  ],
  [OTHER_ICONS[12]]: [
    ...otherCommonKeywords,
    'cake',
    'dessert',
    'celebration',
    'birthday',
    'sweets',
    'party',
    'celebrate',
  ],
  [OTHER_ICONS[13]]: [
    ...otherCommonKeywords,
    'trophy',
    'award',
    'achievement',
    'competition',
    'prize',
    'victory',
    'honor',
    'recognition',
    'success',
  ],
  [OTHER_ICONS[14]]: [
    ...otherCommonKeywords,
    'truck',
    'delivery',
    'transport',
    'vehicle',
    'cargo',
    'shipping',
    'freight',
    'logistics',
  ],
  [OTHER_ICONS[15]]: [
    ...otherCommonKeywords,
    'receipt',
    'percent',
    'invoice',
    'transaction',
    'discount',
    'billing',
    'purchase',
    'voucher',
    'ticket',
  ],
  [OTHER_ICONS[16]]: [
    ...otherCommonKeywords,
    'lifebuoy',
    'rescue',
    'safety',
    'aid',
    'emergency',
    'support',
    'help',
    'protection',
    'life',
    'ring',
  ],
  [OTHER_ICONS[17]]: [
    ...otherCommonKeywords,
    'language',
    'translation',
    'communication',
    'linguistics',
    'dialects',
    'speech',
    'words',
    'text',
    'multilingual',
    'google',
  ],
  [OTHER_ICONS[18]]: [
    ...otherCommonKeywords,
    'light',
    'bulb',
    'idea',
    'innovation',
    'brightness',
    'illumination',
    'inspiration',
    'lighting',
    'electricity',
    'concept',
    'energy',
  ],
  [OTHER_ICONS[19]]: [
    ...otherCommonKeywords,
    'gift',
    'present',
    'celebration',
    'occasion',
    'wrapping',
    'surprise',
    'holiday',
    'giveaway',
    'rewards',
  ],
  [OTHER_ICONS[20]]: [
    ...otherCommonKeywords,
    'puzzle',
    'solution',
    'game',
    'strategy',
    'fit',
    'challenge',
    'piece',
  ],
  [OTHER_ICONS[21]]: [
    ...otherCommonKeywords,
    ...globeCommonKeywords,
    'Americas',
    'North',
    'America',
    'South',
  ],
  [OTHER_ICONS[22]]: [
    ...otherCommonKeywords,
    'rocket',
    'launch',
    'space',
    'exploration',
    'innovation',
    'technology',
    'mission',
    'adventure',
  ],
  [OTHER_ICONS[23]]: [
    ...otherCommonKeywords,
    'photo',
    'photography',
    'image',
    'picture',
    'capture',
    'camera',
    'memory',
    'landscape',
    'snapshot',
  ],
  [OTHER_ICONS[24]]: [
    ...otherCommonKeywords,
    'film',
    'movie',
    'cinema',
    'video',
    'reel',
    'media',
    'director',
    'production',
  ],
  [OTHER_ICONS[25]]: [
    ...otherCommonKeywords,
    'academic',
    'cap',
    'graduation',
    'education',
    'degree',
    'diploma',
    'student',
    'scholar',
    'ceremony',
    'learning',
  ],
};
