/* RESPONSIBLE TEAM: team-knowledge-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class ImportSourceContext extends Fragment {
  @attr('boolean') declare unauthorized: boolean;

  // Guru context
  @attr('string') declare collectionId?: string;
  @attr('string') declare collectionName?: string;

  // Confluence context
  @attr('string') declare siteId: string;
  @attr('string') declare spaceId: string;
  @attr('string') declare spaceName: string;

  // Notion context
  @attr('string') declare workspaceName: string;

  // Salesforce context
  @attr('string') declare connectionId: string;
  @attr('string') declare connectionName: string;
}
