/* import __COLOCATED_TEMPLATE__ from './code-snippet.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { INSTALLATION_TYPE } from 'embercom/components/installation-new/constants';

interface Args {
  selectedInstallationType: INSTALLATION_TYPE;
  setSelectedInstallationType: (installationType: INSTALLATION_TYPE) => void;
  disabled: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class CodeSnippet extends Component<Signature> {
  @service declare appService: any;
  @service declare intercomEventService: $TSFixMe;

  @tracked selectedProgrammingLanguage = 'javascript';
  @tracked selectedAppType = 'npm';

  get app() {
    return this.appService.app;
  }

  get installationForUsers() {
    return this.args.selectedInstallationType === INSTALLATION_TYPE.LOGGED_IN_USERS;
  }

  @action
  selectAppType(appType: string) {
    this.selectedAppType = appType;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'messenger_installation_app_type',
      metadata: {
        item: appType.replaceAll('-', '_'),
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  selectProgrammingLanguage(language: string) {
    this.selectedProgrammingLanguage = language;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'code_snippet_language',
      metadata: {
        item: language,
        context: this.installationForUsers ? 'logged_in_users' : 'visitors',
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::CodeSnippet': typeof CodeSnippet;
    'installation-new/web/initial-setup/code-snippet': typeof CodeSnippet;
  }
}
