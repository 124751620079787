/* import __COLOCATED_TEMPLATE__ from './products.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { isEmpty } from '@ember/utils';

export default class Order extends Component {
  @service appService;

  @tracked products = [];
  @tracked productSearchQuery = '';
  @tracked lastProductSearchQuery = null;

  get noResultsFound() {
    return (
      isEmpty(this.products) &&
      !isEmpty(this.productSearchQuery) &&
      this.lastProductSearchQuery === this.productSearchQuery
    );
  }

  @action
  focusOnProductSearchInput() {
    let productSearchInput = document.querySelector('.js__shopify-product-search-input');
    productSearchInput.focus();
  }

  @task({ restartable: true })
  *searchShopifyForProduct() {
    this.products = [];

    if (!this.productSearchQuery) {
      return;
    }

    this.lastProductSearchQuery = this.productSearchQuery;
    try {
      let productResponse = yield ajax({
        url: '/ember/inbox/shopify/search_products',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
          query: this.productSearchQuery,
        },
      });

      this.products = productResponse.products;
    } catch (error) {
      console.error(error);
    }
  }
}
