/* import __COLOCATED_TEMPLATE__ from './trial-badge.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinTrialService from 'embercom/services/fin-trial-service';
import type IntlService from 'ember-intl/services/intl';

interface Arguments {
  canUseCustomAnswers?: boolean;
}

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

export default class TrialBadge extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare finTrialService: FinTrialService;

  get showStamp() {
    return this.finTrialService.showCTAs && !this.args.canUseCustomAnswers;
  }

  get stampText() {
    if (this.showStamp) {
      if (this.finTrialService.freeTrialStarted) {
        return this.intl.t('operator.fin.free-trial-copy.trial-badge.days-free-remaining', {
          remainingDays: this.finTrialService.trialDaysRemaining,
        });
      }

      return this.intl.t('operator.fin.free-trial-copy.trial-badge.free-for-14-days');
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::TrialBadge': typeof TrialBadge;
  }
}
