/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { readOnly } from '@ember/object/computed';

const Validations = buildValidations(
  {
    description: [validator('presence', true), validator('length', { min: 1 })],
    videoUrl: [validator('presence', true), validator('format', { type: 'url' })],
  },
  {
    disabled: readOnly('model.validationsDisabled'),
  },
);

export default Fragment.extend(Validations, {
  state: attr('string'),
  description: attr('string'),
  videoUrl: attr('string'),
  feedbackNotes: attr('array'),
  createdAt: attr('date'),
  updatedAt: attr('date'),
  appPackageConfig: fragment('developer-hub/app-package-config'),
  scopeDescriptions: fragmentArray('developer-hub/scope-descriptions'),
  validationsDisabled: true,
  descriptionErrors: readOnly('validations.attrs.description.errors'),
  videoUrlErrors: readOnly('validations.attrs.videoUrl.errors'),
  validate() {
    this.set('validationsDisabled', false);
    return this._super(...arguments);
  },

  rollbackAttributes() {
    this.set('validationsDisabled', true);
    return this._super(...arguments);
  },
});
