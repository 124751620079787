/* import __COLOCATED_TEMPLATE__ from './customisation.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';

interface Args {
  model: $TSFixMe;
  senderEmailAddressSettings: SenderEmailAddressSettings[];
}

export default class Customisation extends Component<Args> {
  get defaultSenderEmailAddressSettings() {
    return this.args.senderEmailAddressSettings.find(
      (setting) => setting.isDefaultBrandNotificationEmail,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation': typeof Customisation;
  }
}
