/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import type Contract from 'embercom/models/billing/contract';
import { SALES_LED_ONLY_SMS_METRICS } from 'embercom/lib/purchase/constants';
import { inject as service } from '@ember/service';
import type CustomIntlService from 'embercom/services/intl';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Store from '@ember-data/store';
import { TierPriceFormat } from 'embercom/models/data/pricing/tier-types';

export enum ChargeModel {
  FlatFee = 'FlatFee',
  PerUnit = 'PerUnit',
  Tiered = 'Tiered',
  Volume = 'Volume',
}

enum ChargeType {
  OneTime = 'OneTime',
  Recurring = 'Recurring',
}

export interface Tier {
  id: number;
  starting_unit: number;
  ending_unit: number;
  price: number;
  price_format: `${ChargeModel}`;
}

interface DiscountItem {
  amount_in_cents: number;
  attributes: {
    value: number;
  };
}

interface PlanLimit {
  minimum: number | null;
  maximum: number | null;
}

export type WorkspaceUsageInfoForCharge = {
  name: string;
  usage: number;
  isPrimaryWorkspace: boolean;
};

export default class Charge extends Fragment {
  @service declare intl: CustomIntlService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare customerService: any;

  @attr('string') declare pricing_metric_title: string;
  @attr('string') declare pricing_metric: Metric;
  @attr('string') declare ula_field: null | string;
  @attr('number') declare price: number;
  @attr('number') declare per_unit_price: number;
  @attr('number') declare actual_usage: number;
  @attr('number') declare billed_usage: number;
  @attr() declare current_tier: Tier | null;
  @attr() declare discount_item: DiscountItem | null;
  @attr() declare overage_discount_item: DiscountItem | null;
  @attr('number') declare block_size: number;
  @attr('number') declare per_block_price: number;
  @attr() declare plan_limit: PlanLimit;
  @attr('string') declare charge_model: `${ChargeModel}`;
  @attr('string') declare charge_type: `${ChargeType}`;
  @attr('boolean') declare fixed_term_charge: boolean;
  @attr('string') declare charge_id: string;
  @attr() declare tiers: Tier[];
  @attr() declare base_usage: any;
  @attr('number') declare base_price: number;
  @attr() declare per_block_pricing_by_billing_period: any;
  @attr() declare per_unit_pricing_by_billing_period: any;
  @attr('number') declare first_tier_price: number | null;
  @attr('string') declare planId: string;

  get customer() {
    return this.customerService.customer;
  }

  get app() {
    return this.appService.app;
  }

  get contract(): Contract {
    // Should be available in the Billing Summary where this is used
    // However, we should cater for null
    return this.store.peekRecord('billing/contract', this.app.id);
  }

  get isSalesforceContracted() {
    return this.app.isSalesforceContracted;
  }

  get isAnnualOrSalesforceContracted() {
    return this.app.isSalesforceContracted || this.customerService.isSelfServeAnnualCustomer;
  }

  get pricingMetricModel(): PricingMetric {
    return new PricingMetric(this, this.intl);
  }

  get isValidUsageMetric() {
    let usageMetric = this.pricingMetricModel.pluralize(this.billed_usage);

    return !!usageMetric;
  }

  get actualUsage(): number {
    return this.isAnnualOrSalesforceContracted && this.contract
      ? this.contract.totalUsage[this.pricing_metric]!
      : this.actual_usage;
  }

  get mockContractForVBP2EarlyStageWorkspaces() {
    return this.customer.currentlyOnValueBasedPricingMar2021EarlyStage;
  }

  /**
   * baseUsage is the usage that is included for free in the plan starting price
   * or the minimum mandatory usage that needs to be purchased with the plan
   *
   * For VBP 2 Early Stage users (all are without a contract, we want to mock contract usage)
   *   https://github.com/intercom/embercom/pull/51839
   */
  get baseUsage() {
    if (this.pricingMetricModel.potentialZeroBase) {
      return 0;
    }

    return this.minimumUsage;
  }

  get includedUsage(): number {
    let pricingMetric = this.pricingMetricModel;
    /**
     *
     * For VBP 2 Early Stage users (all are without a contract)
     * */
    if (this.mockContractForVBP2EarlyStageWorkspaces && pricingMetric.potentialZeroBase) {
      return pricingMetric.minimumUsage;
    }

    return this.isAnnualOrSalesforceContracted && this.contract
      ? this.contract.contractUsageLimits[this.pricing_metric]!
      : this.baseUsage;
  }

  get minimumUsage(): number {
    if (this.plan_limit?.minimum) {
      return this.plan_limit.minimum;
    }

    if (
      this.tiers?.firstObject?.ending_unit &&
      this.tiers.firstObject.price_format === TierPriceFormat.FlatFee
    ) {
      return this.tiers.firstObject.ending_unit;
    }

    return 0;
  }

  //   pricingMetric.baseUsage (can be either mandatory minimum or free included usage)
  // + additionalUsagePurchased (only for contracted apps)
  // + overageUsage
  // ------------------------------
  // = actualUsage
  get additionalUsagePurchased() {
    if (this.mockContractForVBP2EarlyStageWorkspaces && this.pricingMetricModel.potentialZeroBase) {
      return this.minimumUsage;
    }

    let additionalContractUsage =
      this.contract && this.isAnnualOrSalesforceContracted
        ? this.contract.contractUsageLimits[this.pricing_metric]! - this.baseUsage
        : 0;

    return Math.max(additionalContractUsage, 0);
  }

  get overageUsage() {
    return this.actualUsage - this.additionalUsagePurchased - this.baseUsage;
  }

  tierIdBasedOnUsage(usage: number = this.includedUsage): number {
    return (
      this.tiers?.find((tier) => tier.starting_unit <= usage && tier.ending_unit >= usage)?.id || 0
    );
  }

  get lowestTierWithoutBaseUsage(): number {
    if (this.tiers?.[0]?.price_format === TierPriceFormat.FlatFee) {
      return 2;
    }

    return 1;
  }

  get totalCostOverageBreakdown() {
    if (this.tiers?.length > 0) {
      let includedUsage = this.includedUsage;
      let highestTierId = this.pricingMetricModel.currentTier.id;
      let includedTierId = this.tierIdBasedOnUsage(includedUsage);

      if (this.charge_model === 'Volume') {
        let includedTier = this.tiers.find(({ id }) => id === includedTierId);
        let overageTier = this.tiers.find(({ id }) => id === highestTierId);
        let overageTierPrice = this.formatPrice(overageTier?.price);
        let includedTierPrice = this.formatPrice(includedTier?.price);
        return [`${overageTierPrice} - ${includedTierPrice}`];
      } else {
        let lowestTierId = Math.max(includedTierId, this.lowestTierWithoutBaseUsage);

        return this.tiers
          ?.filter(({ id }) => id <= highestTierId && id >= lowestTierId)
          .filter(({ starting_unit, ending_unit }) => {
            return this.calculateTierOverage(includedUsage, starting_unit, ending_unit) > 0;
          })
          .map(({ starting_unit, ending_unit, price, price_format }) => {
            let value;
            if (price_format === TierPriceFormat.PerUnit) {
              value = price * this.block_size;
            } else if (price_format === TierPriceFormat.FlatFee) {
              return `${this.formatPrice(price)}`;
            }

            return `${this.intl.formatNumber(Math.ceil(this.calculateTierOverage(includedUsage, starting_unit, ending_unit)))} x ${this.formatPrice(
              value,
              0,
              4,
            )}`;
          });
      }
    }
    // If there are no tiers, we build the overage formula as a per unit charge
    let overUsage = this.intl.formatNumber(this.overageUsage);
    let perUnitPrice = this.formatPrice(this.per_unit_price / 100);
    return [`${overUsage} x ${perUnitPrice}`];
  }

  get isSeatMetric(): boolean {
    return this.pricingMetricModel.isSeatMetric;
  }

  get overagePrice() {
    if (
      (this.contract && this.isAnnualOrSalesforceContracted) ||
      this.mockContractForVBP2EarlyStageWorkspaces
    ) {
      return (
        this.pricingMetricModel.price -
        this.pricingMetricModel.basePrice -
        this.additionalPriceUnderContract
      );
    }

    return this.pricingMetricModel.additionalPrice;
  }

  //   plan base price (base fee + the cost of mandatory minimum usage (when present))
  // + additionalPriceUnderContract (cost of any extra usage purchased outside of what is included in the plan base price)
  // + overage price
  // ------------------------------
  // = full price
  // https://github.com/intercom/embercom/pull/50205
  get additionalPriceUnderContract() {
    let pricingMetric = this.pricingMetricModel;

    if (this.additionalUsagePurchased > 0) {
      if (pricingMetric.tiers?.length > 0) {
        let contractedUsageLimit = this.includedUsage;

        let contractedUsageLimitTierId = this.tierIdBasedOnUsage(contractedUsageLimit);

        let tiersWithContractedUsage = this.tiers.filter((tier) => {
          // When the charge model is volume, only the matching tier should be used
          if (this.charge_model === 'Volume') {
            return tier.id === contractedUsageLimitTierId;
          }
          // Otherwise, all tiers up to the matching tier should be summed
          return tier.id <= contractedUsageLimitTierId;
        });

        let total = 0;
        tiersWithContractedUsage.forEach((tier) => {
          if (tier.price_format === 'FlatFee') {
            total += tier.price;
          } else if (tier.price_format === 'PerUnit') {
            let tierUsageSize;
            if (tier.id < contractedUsageLimitTierId) {
              tierUsageSize = tier.ending_unit - Math.max(tier.starting_unit, 1) + 1;
            } else {
              tierUsageSize = this.includedUsage - Math.max(tier.starting_unit, 1) + 1;
            }
            total += tierUsageSize * tier.price;
          }
        });
        return total * 100 - pricingMetric.basePrice;
      } else {
        // Charges with no tiers are charged per unit
        return (this.includedUsage - pricingMetric.baseUsage) * this.per_unit_price;
      }
    }
    return 0;
  }

  get perWorkspaceUsage(): WorkspaceUsageInfoForCharge[] | undefined {
    if (
      !this.app.isSalesforceContracted ||
      !this.contract?.perWorkspaceUsage ||
      this.pricing_metric === Metric.fixed
    ) {
      return;
    }
    return this.contract.perWorkspaceUsage.map((workspace) => {
      return {
        name: workspace.app_name,
        usage: workspace.usage[this.pricing_metric]!,
        isPrimaryWorkspace:
          this.contract.isPrimarySubscription && workspace.app_id === this.contract.appId,
      };
    });
  }

  get calculateAdditionalUsagePrice() {
    let hasContractedUsage =
      this.contract &&
      this.isAnnualOrSalesforceContracted &&
      this.baseUsage + this.additionalUsagePurchased > 0;
    let price = this.base_price - (this.first_tier_price || 0) * 100;

    if (hasContractedUsage && this.actualUsage <= this.includedUsage) {
      return price;
    } else if (hasContractedUsage && this.actualUsage > this.includedUsage) {
      return this.overagePrice + price;
    }
    return this.overagePrice;
  }

  get isVisibleToCustomer(): boolean {
    return (
      this.isAnnualOrSalesforceContracted ||
      !SALES_LED_ONLY_SMS_METRICS.includes(this.pricing_metric)
    );
  }

  calculateTierOverage(includedUsage: number, starting_unit: number, ending_unit: number) {
    let lowerLimit = Math.max(includedUsage, starting_unit - 1);
    let overagePerTier = Math.min(this.actualUsage, ending_unit) - lowerLimit;
    overagePerTier /= this.block_size;

    return overagePerTier;
  }

  formatPrice(price = 0, minimumFractionDigits = 0, maximumFractionDigits = 2) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }
}
