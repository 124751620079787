/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Model, { attr, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { dependentKeyCompat } from '@ember/object/compat';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { all } from 'ember-concurrency';
import type BrandSettings from 'embercom/models/brand-settings';
import type Brand from 'embercom/models/brand';
import { type TaskInstance } from 'ember-resources/util/ember-concurrency';

const SHORT_COLOR_HEX_CODE_REGEX = /^#[A-Fa-f0-9]{3}$/;

export default class LookAndFeelModel extends Model {
  @service appService: $TSFixMe;

  @hasMany('brand-settings', { async: false }) declare brands: BrandSettings[];
  @hasMany('brand-settings', { async: false }) declare brandSettings: BrandSettings[];
  @hasMany('brand', { async: false }) declare newBrands: Brand[];
  @attr('boolean') declare widgetAudioEnabled: boolean;
  @attr('string') declare primaryColor: string;
  @attr('string') declare secondaryColor: string;
  @attr('boolean') declare showPoweredBy: boolean;
  @attr('string') declare messengerBackground: string;
  @attr('string') declare logoUrl: string;
  @attr() declare logoId: string;
  @attr() declare launcherLogoUrl: string;
  @attr() declare launcherLogoId: string;
  @attr('string') declare alignment: string;
  @attr('string') declare horizontalPadding: string;
  @attr('string') declare verticalPadding: string;

  // M5 Settings
  @attr('string', { defaultValue: 'colors' }) declare backgroundType: string;
  @attr('string') declare gradientFirstColor: string;
  @attr('string') declare gradientSecondColor: string;
  @attr('string') declare gradientThirdColor: string;
  @attr('string', { defaultValue: 'dark' }) declare textColor: string;
  @attr('boolean', { defaultValue: true }) declare fadeToWhite: boolean;
  @attr('boolean', { defaultValue: true }) declare showAvatars: boolean;
  @attr('string') declare backgroundImageUrl: string;
  @attr('number') declare backgroundImageId: number;
  @attr('number') declare helpCenterId: number;

  //Conversational settings
  @attr('boolean', { defaultValue: true }) declare showConversationBackground: boolean;
  @attr('string', { defaultValue: 'colors' }) declare conversationalBackgroundType: string;
  @attr('string') declare backgroundSolidColor: string;
  @attr('number') declare brandIdentityLogoId: number;
  @attr('string') declare brandIdentityLogoUrl: string;
  @attr('string', { defaultValue: 'ai_agent' }) declare conversationalMessengerIdentity: string;

  get primaryColorHex() {
    return this.primaryColor.slice(1);
  }
  get secondaryColorHex() {
    return this.secondaryColor.slice(1);
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      // @ts-ignore Typescript incorrectly infers that hasDirtyAttributes is a function
      (this.hasDirtyAttributes || this.brands.any((brand) => brand.hasUnsavedChanges)) &&
      this.validPaddings
    );
  }
  get defaultBrand() {
    return this.brands.findBy('isDefault');
  }
  get defaultMobileBrand() {
    return this.brands.findBy('defaultMobileBrand');
  }
  get mobileBrand() {
    return this.brands.findBy('isMobile');
  }
  get webBrands() {
    return this.brands.filterBy('isWeb');
  }

  @tracked activeBrand = this.defaultBrand;

  get backgroundId() {
    let messengerBackground = this.activeBrand!.messengerWallpaper;
    return isPresent(messengerBackground) ? messengerBackground.replace('background-', '') : null;
  }

  get previewConfigurationParams() {
    return {
      logoURL: this.logoUrl,
      backgroundPatternNumber: this.backgroundId,
      appPrimaryColor: this.primaryColor,
      appSecondaryColor: this.secondaryColor,
      showWeRunOnIntercom: this.showPoweredBy,
    };
  }

  paddingMin = 20;
  paddingMax = 32767;
  paddingMinAndroid = 16;
  iosHorizontalPadding = 20;
  androidHorizontalPadding = 16;

  get validPaddings() {
    return (
      this.paddingWithinBoundaries(this.horizontalPadding) &&
      this.paddingWithinBoundaries(this.verticalPadding)
    );
  }

  paddingWithinBoundaries(padding: string) {
    return Number(padding) >= this.paddingMin && Number(padding) <= this.paddingMax;
  }
  paddingWithinBoundariesAndroid(padding: string) {
    return Number(padding) >= this.paddingMinAndroid && Number(padding) <= this.paddingMax;
  }

  expandShortColorCode(colorProperty: 'primaryColor' | 'secondaryColor') {
    let color = this[colorProperty];
    if (SHORT_COLOR_HEX_CODE_REGEX.exec(color) !== null) {
      let expandedColor = color.replace(/([A-Fa-f0-9])/g, '$1$1');
      this[colorProperty] = expandedColor;
    }
  }

  expandColors() {
    this.expandShortColorCode('primaryColor');
    this.expandShortColorCode('secondaryColor');
  }

  refresh() {
    this.rollbackAttributes();
    this.brands.forEach((brand) => {
      // @ts-ignore Typescript incorrectly infers that hasDirtyAttributes is a function
      if (brand.hasDirtyAttributes) {
        brand.rollbackAttributes();
      }
    });
    this.activeBrand = this.defaultBrand;
  }

  async beforeSave() {
    let defaultBrand = this.brands.find((brand) => brand.isDefault);
    if (defaultBrand?.hasUnsavedChanges) {
      defaultBrand.copyM5SettingsToLookAndFeel();
    }
    await taskFor(this.saveBrandsWithChanges).perform();

    if (this.hasUnsavedChanges) {
      this.expandColors();
    }
  }

  save(_options?: { adapterOptions?: object | undefined }) {
    return super.save().then(() => {
      this.appService.app.base_color = this.primaryColor;
      return this;
    });
  }

  @task({ enqueue: true, maxConcurrency: 1 })
  *saveBrand(brand: BrandSettings): Generator<Promise<BrandSettings>, unknown> {
    return yield brand.save() as Promise<BrandSettings>;
  }

  @task
  *saveBrandsWithChanges() {
    let saveBrands: TaskInstance<unknown>[] = [];
    this.brands
      .reject((brand) => brand.isDefault)
      .filter((brand) => brand.hasUnsavedChanges)
      .forEach((brand) => {
        brand.expandColors();
        saveBrands.push(taskFor(this.saveBrand).perform(brand));
      });

    yield all(saveBrands);
  }
}
