/* import __COLOCATED_TEMPLATE__ from './submenu.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import Component from '@glimmer/component';
import { SETTINGS_NAV_ITEMS, type NavSection } from './submenu-items';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface SubmenuArgs {}

interface Signature {
  Element: HTMLDivElement;
  Args: SubmenuArgs;
}

export default class Submenu extends Component<Signature> {
  @tracked activeSubmenu = '';
  @service declare store: any;
  @service declare helpCenterService: any;
  @service declare appService: any;
  @service declare customerService: any;

  get submenuItems() {
    return SETTINGS_NAV_ITEMS;
  }

  @action
  shouldShowSection(section: NavSection) {
    if (section.showOnlyOnFeatureFlag) {
      return this.appService.app.canUseFeature(section.showOnlyOnFeatureFlag);
    }
    if (section.customDisplayCondition) {
      return section.customDisplayCondition(this.appService.app, this.customerService.customer);
    }
    return true;
  }

  @action
  changeSubmenu(keyword: string) {
    this.activeSubmenu = keyword !== this.activeSubmenu ? keyword : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Navigation::Submenu': typeof Submenu;
    'new-settings/navigation/submenu': typeof Submenu;
  }
}
