/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type HandoffToSalesforceCase from 'embercom/models/operator/visual-builder/step/handoff-to-salesforce-case';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type IntlService from 'ember-intl/services/intl';
import type {
  HandoffOption,
  HandoffOptionGroup,
} from 'embercom/components/workflows/graph-editor/node-items/steps/handoff-to-salesforce-case';

interface Arguments {
  step: HandoffToSalesforceCase;
  handoverOptions: HandoffOptionGroup[];
  selectedHandoverOption: HandoffOption | undefined;
  isReadOnly: boolean;
  isLoading: boolean;
  className?: string;
}

export default class Settings extends Component<Arguments> {
  @service declare intl: IntlService;
  @service declare finStandaloneService: FinStandaloneService;

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig;
  }

  @action setHandoffToSalesforceOwnerId(handoffId: string) {
    this.args.step.handoffToSalesforceOwnerId = handoffId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffToSalesforceCase::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/handoff-to-salesforce-case/settings': typeof Settings;
  }
}
