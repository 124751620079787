/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable no-restricted-imports */
import type HandoffToZendeskAgent from 'embercom/models/operator/visual-builder/step/handoff-to-zendesk-agent';
import StepConfig, { type StepConfigParams } from '../step';
import {
  NEW_CONVERSATION_TARGET,
  NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET,
  NEW_ZENDESK_TICKET_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';
import { type BotConfigTarget } from 'embercom/objects/operator/configuration/configuration';

export default class HandoffToZendeskAgentConfiguration extends StepConfig {
  declare step: HandoffToZendeskAgent;
  tooltipCopy?: string;
  workflowTarget?: BotConfigTarget;

  constructor(
    params: Omit<StepConfigParams, 'component'> & {
      tooltipCopy?: string;
      workflowTarget?: BotConfigTarget;
    },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/handoff-to-zendesk-agent',
      ...params,
    });
    this.tooltipCopy = params.tooltipCopy;
    this.workflowTarget = params.workflowTarget;
  }

  get alignment() {
    return 'left' as const;
  }

  get shouldCheckApiConnectionForIntercomMessenger() {
    return this.workflowTarget === NEW_CONVERSATION_TARGET;
  }

  get shouldCheckApiConnectionForZendeskSunshine() {
    return this.workflowTarget === NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET;
  }

  get shouldCheckApiConnectionForZendeskTicket() {
    return this.workflowTarget === NEW_ZENDESK_TICKET_TARGET;
  }
}
