/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import { INSTALLATION_DOCS_URL } from 'embercom/lib/installation-docs-url';
import {
  type InstallationPlatform,
  INSTALLATION_PLATFORMS,
} from 'embercom/components/installation-new/constants';
import type Store from '@ember-data/store';
import type SdkAppService from 'embercom/services/sdk-app-service';

export interface Args {
  selectedTab: InstallationPlatform;
  selectedAccordion: 'messenger-setup' | 'idv-setup' | 'push-notifications' | string;
  iosApiSecret: string;
  iosApiKey: string;
  iosGcmKey: string;
  androidApiKey: string;
  androidGcmKey: string;
  androidApiSecret: string;
  androidSdkApp: $TSFixMe;
  iosSdkApp: $TSFixMe;

  hasPadding: boolean;

  setSelectedTab: (tab: INSTALLATION_PLATFORMS) => void;
  setSelectedAccordion: (accordion: 'messenger-setup' | 'idv-setup' | '') => void;
  deleteSdkApp?: (type: 'ios' | 'android') => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class MainComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: $TSFixMe;
  @service declare messengerInstallation: MessengerInstallation;
  @service declare store: Store;
  @service declare sdkAppService: SdkAppService;
  @service declare permissionsService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    // for when accordion is set through query param
    if (this.selectedAccordion === 'idv-setup' && !this.canAccessWorkspaceSettings) {
      this.args.setSelectedAccordion('messenger-setup');
    }
  }
  get selectedTab() {
    return this.args.selectedTab || 'web';
  }

  get selectedAccordion() {
    return this.args.selectedAccordion;
  }

  get app() {
    return this.appService.app;
  }

  get docsUrl() {
    return INSTALLATION_DOCS_URL;
  }

  get canAccessWorkspaceSettings() {
    return this.appService.app.currentAdmin.canAccessWorkSpaceSettings;
  }

  @action
  setSelectedAccordion(accordion: 'messenger-setup' | 'idv-setup' | '') {
    // permission neeeded for idv step, don't let user interact with the accordion section
    if (accordion === 'idv-setup' && !this.canAccessWorkspaceSettings) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_access_workspace_settings',
      );
      return;
    }
    this.args.setSelectedAccordion(accordion);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'accordion_clicked',
      metadata: {
        item: accordion,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  setTabSelection(tab: INSTALLATION_PLATFORMS) {
    this.args.setSelectedTab(tab);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'platform_selector',
      metadata: {
        item: tab,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  get selectedInstallationPlatform() {
    return this.selectedTab === 'web' ? 'web' : 'mobile';
  }

  @action
  setSelectedInstallationPlatform(platform: 'web' | 'mobile') {
    if (platform === 'mobile') {
      this.args.setSelectedTab(INSTALLATION_PLATFORMS.IOS);
    } else {
      this.args.setSelectedTab(INSTALLATION_PLATFORMS.WEB);
    }
  }

  @action
  async deleteSdkApp(type: 'ios' | 'android') {
    let selectedApp = this.args.iosSdkApp;
    if (type === 'android') {
      selectedApp = this.args.androidSdkApp;
    }

    let confirmationMessage = this.intl.t('apps.app.settings.installation.deletion-confirmation');

    if (!confirm(confirmationMessage)) {
      return;
    }

    await selectedApp.destroyRecord();
    if (this.args.deleteSdkApp) {
      this.args.deleteSdkApp(type);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Main': typeof MainComponent;
    'installation-new/main': typeof MainComponent;
  }
}
