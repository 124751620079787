/* RESPONSIBLE TEAM: team-standalone */
import { default as OriginalRoute } from 'embercom/routes/apps/app/automation/workflows/edit';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';

export default class Route extends OriginalRoute {
  @service declare appService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;

  controllerName = 'apps/app/automation/workflows/edit';

  setupController(controller: any, model: any) {
    super.setupController(controller, model);
    let queryParams = this.router.currentRoute?.queryParams || {};
    this.contentEditorService.isFinEnabled = queryParams['fin'] === 'true';
    this.contentEditorService.isOpenInFinStandalone = true;
    this.setWorkflowOpenMode(controller, model);
  }

  resetController(controller: any, _: any, __: any) {
    super.resetController();
    controller.set('mode', null);
  }

  @action
  willTransition(transition: Transition & { isAborted: boolean }) {
    super.willTransition(...arguments);

    if (transition.isAborted) {
      return;
    }

    this.contentEditorService.isOpenInFinStandalone = false;
    this.contentEditorService.isFinEnabled = false;
  }

  setWorkflowOpenMode(controller: any, model: any) {
    let mode = !model.isLive || (this.isInEditMode && model.isLive) ? 'edit' : 'view';
    controller.set('mode', mode);
  }

  get isInEditMode() {
    let queryParams = this.router.currentRoute?.queryParams || {};
    return queryParams['mode'] === 'edit';
  }
}
