/* import __COLOCATED_TEMPLATE__ from './share-button.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ReportAccessService from 'embercom/services/report-access-service';
import type Report from 'embercom/models/reporting/custom/report';
import { isPresent } from '@ember/utils';

interface Args {
  disabled: boolean;
  report: Report;
  onClick?: () => void;
}

export default class ReportingCustomReportShareButton extends Component<Args> {
  @service declare reportAccessService: ReportAccessService;

  get hasWorkspaceAccess(): boolean {
    let accessControlList = this.reportAccessService.localAccessControls;
    return isPresent(accessControlList.find((control) => control.isWorkspaceAccessControl));
  }

  get shareIcon() {
    return this.hasWorkspaceAccess ? 'multiple-people' : 'locked';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ShareButton': typeof ReportingCustomReportShareButton;
    'reporting/custom/report/share-button': typeof ReportingCustomReportShareButton;
  }
}
