/* import __COLOCATED_TEMPLATE__ from './ignored-addresses.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import { dropTask } from 'ember-concurrency-decorators';
import { isEmpty } from '@ember/utils';

interface Args {}

const MANUALLY_ADDED = 0;
const emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

interface CompanyEmailAddress {
  email_address: string;
  source: number;
}
export default class IgnoredAddresses extends Component<Args> {
  @tracked showSideDrawer = false;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;

  @tracked showModal = false;
  @tracked companyAddressList: CompanyEmailAddress[] = [];
  @tracked newCompanyEmailAddress = '';
  @tracked addCompanyEmailAddressFormExpanded = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadCompanyAddressList();
  }

  get hasCompanyEmailAddresses() {
    return this.companyAddressList.length > 0;
  }

  async loadCompanyAddressList() {
    this.companyAddressList = this.appService.app.companyEmailAddresses;
  }

  @action
  openSideDrawer() {
    this.showSideDrawer = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_ignored_addresses',
      section: 'settings',
      place: 'email_advanced_settings',
      value: 'side_drawer_opened',
    });
  }

  @action
  closeSideDrawer() {
    this.showSideDrawer = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_ignored_addresses',
      section: 'settings',
      place: 'email_advanced_settings',
      value: 'side_drawer_closed',
    });
  }

  @action
  expandAddCompanyEmailAddressForm() {
    this.addCompanyEmailAddressFormExpanded = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_ignored_addresses',
      section: 'settings',
      place: 'email_advanced_settings',
      value: 'add_company_email_address_form_expanded',
    });
  }

  @action
  collapseAddCompanyEmailAddressForm() {
    this.addCompanyEmailAddressFormExpanded = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_ignored_addresses',
      section: 'settings',
      place: 'email_advanced_settings',
      value: 'add_company_email_address_form_collapsed',
    });
  }

  duplicateEmailAddress(emailAddress: string): boolean {
    return (
      this.companyAddressList.filter((item) => item['email_address'] === emailAddress).length > 0
    );
  }

  isValidEmailAddress(emailAddress: string) {
    return isEmpty(emailAddress) ? false : emailRegex.test(emailAddress.toLowerCase());
  }

  @dropTask
  *deleteCompanyEmailAddress(companyEmailAddress: any) {
    try {
      yield companyEmailAddress.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.senders.comapny-email-address-component.deleted-address', {
          address: companyEmailAddress.email_address,
        }),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: 'email_ignored_addresses',
        section: 'settings',
        place: 'email_advanced_settings',
        value: companyEmailAddress.email_address,
      });
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('settings.senders.comapny-email-address-component.email-not-deleted'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: 'email_ignored_addresses',
        section: 'settings',
        place: 'email_advanced_settings',
        value: companyEmailAddress.email_address,
        status: 'error',
      });
    }
  }

  @dropTask
  *addNewCompanyEmailAddress(emailAddress: string) {
    if (!this.isValidEmailAddress(emailAddress)) {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.settings.email-forwarding.enter-valid-email'),
      );
    } else if (this.duplicateEmailAddress(emailAddress)) {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.settings.email-forwarding.email-already-added'),
      );
    } else {
      let companyEmailAddress = this.store.createRecord('company-email-address', {
        email_address: emailAddress,
        source: MANUALLY_ADDED,
      });
      this.collapseAddCompanyEmailAddressForm();
      try {
        yield companyEmailAddress.save();
        this.newCompanyEmailAddress = '';
        this.notificationsService.notifyConfirmation(
          this.intl.t('apps.app.settings.email-forwarding.registered'),
        );
        this.intercomEventService.trackAnalyticsEvent({
          action: 'added',
          object: 'email_ignored_addresses',
          section: 'settings',
          place: 'email_advanced_settings',
          value: companyEmailAddress.email_address,
        });
      } catch (e) {
        this.notificationsService.notifyError(
          this.intl.t('apps.app.settings.email-forwarding.email-not-saved'),
        );
        this.expandAddCompanyEmailAddressForm();
        this.store.deleteRecord(companyEmailAddress);
        this.intercomEventService.trackAnalyticsEvent({
          action: 'added',
          object: 'email_ignored_addresses',
          section: 'settings',
          place: 'email_advanced_settings',
          value: companyEmailAddress.email_address,
          status: 'error',
        });
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::IgnoredAddresses': typeof IgnoredAddresses;
  }
}
