/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import type Store from '@ember-data/store';
import type ArrayProxy from '@ember/array/proxy';

const PLATFORM_NAMES = ['iOS', 'Android', 'Chrome', 'Firefox'];

export default class SdkApp extends Model {
  @service declare appService: $TSFixMe;

  @hasMany('push-credential') pushCredentials: $TSFixMe;

  @attr('string') declare app_id: string;
  @attr('string') declare name: string;
  @attr('string') declare platform: string;
  @attr('string') declare api_key: string;
  @attr('string') declare api_secret: string;
  @attr('boolean') declare has_certificate: boolean;
  @attr('string') declare certificate_filename: string;
  @attr('string') declare certificate_expiration_date: string;
  @attr('boolean') declare identity_verified: boolean;

  @attr('string') declare gcm_key: string;
  @attr('boolean') declare is_activated: boolean;
  @attr('boolean') declare is_disabled: boolean;
  @attr('boolean') declare secure_even_if_unidentified: boolean;

  @attr('string') declare apns_key_id: string;
  @attr('string') declare apns_team_id: string;
  @attr('string') declare apns_token_file_name: string;
  @attr('string') declare ios_bundle_id: string;
  @attr('boolean') declare has_apns_token: boolean;

  @attr('boolean') declare enforces_encrypted_mode: boolean;
  @attr('boolean') declare productionCert: boolean;

  get currentAdminId() {
    return this.appService.app.currentAdmin.id;
  }

  get identityVerified() {
    return this.identity_verified;
  }
  set identityVerified(value) {
    this.identity_verified = value;
  }
  get identityUnverified() {
    return !this.identityVerified;
  }

  get hasCertificate() {
    return this.has_certificate;
  }
  get apnsKeyId() {
    return this.apns_key_id;
  }
  get apnsTeamId() {
    return this.apns_team_id;
  }
  get apnsTokenFileName() {
    return this.apns_token_file_name;
  }
  get iosBundleId() {
    return this.ios_bundle_id;
  }
  get hasApnsToken() {
    return this.has_apns_token;
  }
  get isDisabled() {
    return this.is_disabled;
  }

  get disallowManualIdentityVerificationEnable() {
    return this.identityUnverified && this.secure_even_if_unidentified;
  }

  get isIOS() {
    return this.platform === 'iOS';
  }

  get isAndroid() {
    return this.platform === 'Android';
  }

  static async findOrCreateForPlatform(platformName: string) {
    if (!PLATFORM_NAMES.includes(platformName)) {
      throw new Error(`${platformName} is not an allowable platform name`);
    }
    let store = getEmberDataStore() as Store;
    let sdkApps = (await store.query('sdk-app', {})) as ArrayProxy<SdkApp>;
    let sdkAppForPlatform = sdkApps.findBy('platform', platformName);
    if (!sdkAppForPlatform) {
      sdkAppForPlatform = store.createRecord('sdk-app', {
        platform: platformName,
      }) as SdkApp;
      await sdkAppForPlatform.save();
    }
    return sdkAppForPlatform;
  }
}
