/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import { post } from 'embercom/lib/ajax';
import { action } from '@ember/object';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
}

export default class EmailPreview extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  @tracked declare finPreviewAddress: string;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    taskFor(this.fetchFinPreviewAddress).perform();
  }

  @task({ drop: true })
  *fetchFinPreviewAddress(): TaskGenerator<void> {
    let finPreviewSettings = yield this.store.findRecord(
      'ai-agent/preview-settings',
      this.appService.app.id,
    );
    this.finPreviewAddress = finPreviewSettings.previewAddress;
  }

  @action
  recordCopyPreviewEmailAddressButtonClick() {
    // We shouldn't make this function async, and await for the response of this API call.
    // Doing so will disable the button, which is used to place the preview email address in the clipboard.
    // This will make this call fire and forgot, which is sufficient to record the click for a CDA.
    // This means the user can keep clicking the button to copy the email address to the clipboard if needed.
    post('/ember/ai_agent/preview_settings/record_copy_preview_email_address_button_click', {
      app_id: this.appService.app.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::EmailPreview': typeof EmailPreview;
    'operator/fin/setup/email-preview': typeof EmailPreview;
  }
}
