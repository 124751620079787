/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

import {
  CURRENT_EARLY_STAGE_SOLUTION_IDS,
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_PLANS,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
  PRICING_5_X_SOLUTION_IDS_NON_EARLY_STAGE,
  VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID,
  VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID,
  defaultSolutionId,
  earlyStageCouponCodeForSolution,
} from 'embercom/lib/billing';
import parseTextToJson from 'embercom/lib/parse-text-to-json';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import ENV from 'embercom/config/environment';

export default IntercomRoute.extend({
  analytics: {
    section: 'signup',
  },
  redirectService: service(),
  purchaseAnalyticsService: service(),
  boundUnloadEvent: null,
  appService: service(),
  experimentOptInService: service(),
  customerService: service(),
  gtmService: service(),
  router: service(),
  earlyStageService: service(),
  signupService: service(),
  permissionsService: service(),
  cardlessTrialService: service(),
  store: service(),

  queryParams: {
    solutionId: {
      refreshModel: true,
    },
    partner: {},
    addOnPlanIds: {},
    seats: {
      replace: true,
    },
    planId: {
      refreshModel: true,
    },
  },

  resetController(controller) {
    controller.set('extendedTrial', null);
    controller.set('seatNumber', null);
  },

  async beforeModel(transition) {
    await this.appService.app.reload();

    let goingToVerifyEmailRoute = transition?.to?.name === 'apps.app.teams-checkout.verify';

    if (!goingToVerifyEmailRoute && !this.currentAdminHasBillingPermissions()) {
      this.trackPermissionBlockEvent('can_access_billing_settings');
      this.permissionsService.cancelTransitionAndShowPermissionModal(
        'can_access_billing_settings',
        transition,
      );
      return;
    }

    if (!goingToVerifyEmailRoute && !this.currentAdminHasManageTeammatesPermissions()) {
      this.trackPermissionBlockEvent('can_manage_teammates');
      this.permissionsService.cancelTransitionAndShowPermissionModal(
        'can_manage_teammates',
        transition,
      );
      return;
    }

    let customer = await this.customerService.refreshCustomer();
    let correctSolutionId = this.getCorrectSolutionId(transition, customer);
    if (
      ![
        VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID,
        PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
      ].includes(correctSolutionId)
    ) {
      // removes partner source from the local storage
      this.earlyStageService.partnerSource = null;
    }
    if (correctSolutionId !== transition.to.queryParams.solution_id) {
      let queryParams = Object.assign(transition.to.queryParams || {}, {
        solution_id: correctSolutionId,
      });
      if (this.isPartnerProgram(transition, customer)) {
        queryParams.partner = this.earlyStageService.partnerSource;
      }
      return this.replaceWith({ queryParams });
    }
    this.signupService.solutionId = correctSolutionId;

    document.body.classList.add('signup__teams__body');
  },

  async model({ solutionId, partner, seats }) {
    if (solutionId === VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID && partner) {
      this.earlyStageService.partnerSource = partner;
    } else if (solutionId === PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID) {
      this.earlyStageService.partnerSource = partner || true;
    }

    let controller = this.controllerFor('apps.app.teams-checkout');

    controller.set('solutionId', solutionId);
    seats = this.getSeatsValue(seats, solutionId);

    let couponCode = earlyStageCouponCodeForSolution(
      solutionId,
      this.earlyStageService.isPartnerProgram,
    );

    return this.customerService.fetchSolutionPrices(
      solutionId,
      couponCode,
      'teams-checkout-route',
      true,
      seats,
      INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
      controller.usageParameterOverrides,
    );
  },

  trackPermissionBlockEvent(permission) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'blocked',
      object: permission,
      context: 'permissions_denied',
      place: 'plan',
    });
  },

  isPricing5NonEarlyStageSolution(solutionId) {
    return PRICING_5_X_SOLUTION_IDS_NON_EARLY_STAGE.includes(solutionId);
  },

  isPricing5EarlyStageSolution(solutionId) {
    return PRICING_5_X_EARLY_STAGE_SOLUTION_IDS.includes(solutionId);
  },

  onEarlyStageApplicationRoute(transition) {
    return transition.to.name === 'apps.app.teams-checkout.early-stage-application';
  },

  currentAdminHasBillingPermissions() {
    return this.permissionsService.currentAdminCan('can_access_billing_settings');
  },

  currentAdminHasManageTeammatesPermissions() {
    return this.permissionsService.currentAdminCan('can_manage_teammates');
  },

  async setupController(controller, model, transition) {
    this._super(controller, model);
    this.purchaseAnalyticsService.setDefaults();

    this.controllerFor('signup').set('purchaseExperience', 'v3');
    if (!transition.to.queryParams.solution_id) {
      controller.set('solutionId', this.appService.app.signed_up_with_solution_id);
    }
    if (transition.to.queryParams.after_signup_redirect_path) {
      this.redirectService.setRedirectPath(transition.to.queryParams.after_signup_redirect_path);
    }
    controller.hideWidgetIfAppropriate();
    controller.set('prices', model.toArray());
    if (typeof controller.addOnPlanIds === 'string') {
      controller.set('addOnPlanIds', parseTextToJson(controller.addOnPlanIds) || []);
    }
    await this.fetchCheckoutAndSetOnController.perform(controller);
  },

  getCorrectSolutionId(transition, customer) {
    let currentSolutionId =
      transition.to.queryParams.solution_id || this.appService.app.signed_up_with_solution_id;
    let planId = transition.to.queryParams.plan_id;

    if (this.customerService.earlyStageGraduation?.pricing5_X_Graduation) {
      return currentSolutionId;
    }

    if (customer.isStripeCustomer) {
      return this.getSolutionIdForStripeCustomer(transition, customer, currentSolutionId, planId);
    }

    return this.getSolutionIdForNonStripeCustomer(transition, customer);
  },

  getSolutionIdForStripeCustomer(transition, customer, currentSolutionId, planId) {
    if (customer.validEarlyStageApplicant && customer.earlyStageApplicationSolutionId) {
      return this.getEarlyStageSolutionId(customer);
    }

    if (
      this.customerHasNotAppliedForEarlyStage(customer) &&
      this.appSignedUpWithP5EarlyStageSolution()
    ) {
      return this.appService.app.signed_up_with_solution_id.toString();
    }

    if (this.onEarlyStageApplicationRoute(transition)) {
      return this.getEarlyStageApplicationRouteSolutionId(currentSolutionId);
    }

    return this.getNonEarlyStageSolutionId(currentSolutionId, planId);
  },

  getSolutionIdForNonStripeCustomer(transition, customer) {
    if (customer.validEarlyStageApplicant && customer.earlyStageApplicationSolutionId) {
      return VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID;
    }

    if (this.onEarlyStageApplicationRoute(transition)) {
      return VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID;
    }

    return VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID;
  },

  getEarlyStageSolutionId(customer) {
    return this.isPricing5EarlyStageSolution(customer.earlyStageApplicationSolutionId.toString())
      ? customer.earlyStageApplicationSolutionId.toString()
      : PRICING_5_X_EARLY_STAGE_SOLUTION_ID;
  },

  getEarlyStageApplicationRouteSolutionId(currentSolutionId) {
    return this.isPricing5EarlyStageSolution(currentSolutionId)
      ? currentSolutionId
      : PRICING_5_X_EARLY_STAGE_SOLUTION_ID;
  },

  getNonEarlyStageSolutionId(currentSolutionId, planId) {
    if (this.isPricing5NonEarlyStageSolution(currentSolutionId)) {
      return this.getSolutionIdForNonEarlyStagePlan(currentSolutionId, planId);
    }

    if (PRICING_5_X_CORE_PLANS.includes(planId)) {
      return defaultSolutionId(planId);
    }

    if (this.customerService.currentPricing5CorePlanSolutionId) {
      return this.customerService.currentPricing5CorePlanSolutionId;
    }

    return defaultSolutionId(PRICING_5_X_CORE_ESSENTIAL_ID);
  },

  getSolutionIdForNonEarlyStagePlan(currentSolutionId, planId) {
    if (PRICING_5_X_CORE_PLANS.includes(planId)) {
      return defaultSolutionId(planId);
    }

    return currentSolutionId;
  },

  isOnEarlyStage(transition, customer) {
    return (
      this.onEarlyStageApplicationRoute(transition) ||
      customer.validEarlyStageApplicant ||
      this.customerHasNotAppliedForEarlyStage(customer)
    );
  },

  customerHasNotAppliedForEarlyStage(customer) {
    return !customer.hasAppliedForEarlyStage && this.appSignedUpWithEarlyStageSolution();
  },

  appSignedUpWithEarlyStageSolution() {
    let signedUpWithSolutionId = this.appService.app.signed_up_with_solution_id;
    return signedUpWithSolutionId
      ? CURRENT_EARLY_STAGE_SOLUTION_IDS.includes(signedUpWithSolutionId.toString())
      : false;
  },

  appSignedUpWithP5EarlyStageSolution() {
    let signedUpWithSolutionId = this.appService.app.signed_up_with_solution_id;
    return this.isPricing5EarlyStageSolution(signedUpWithSolutionId);
  },

  isPartnerProgram(transition, customer) {
    return this.isOnEarlyStage(transition, customer) && this.earlyStageService.isPartnerProgram;
  },

  getSeatsValue(seats, solutionId) {
    if (!seats && !this.earlyStageService.isEarlyStage(solutionId)) {
      seats = this.appService.defaultSeatQueryParameterValue(solutionId);
    }
    return seats;
  },

  fetchCheckoutAndSetOnController: task(function* () {
    let checkout = yield this.store.findRecord('checkout', this.appService.app.id, {
      reload: true,
    });

    if (checkout.isNotReady) {
      // Timeout is managed server-side. It will fail open and return status = authorized if the app is more than 20 seconds old
      return later(this, () => this.fetchCheckoutAndSetOnController.perform(), ENV.APP._2000MS);
    } else {
      let controller = this.controllerFor('apps.app.teams-checkout');
      controller.set('checkout', checkout);
    }
  }),

  fireUnloadEvent() {
    let controller = this.controllerFor(this.router.currentRouteName);
    this.purchaseAnalyticsService.trackEvent({
      place: controller.place,
      context: controller.context,
      action: 'navigated_away',
      object: controller.place,
      mobile: controller.mobileDevice,
    });

    return 'fired navigated_away'; // need to return otherwise ajax doesn't fire.
  },

  deactivate() {
    this.showPrimaryNav();
    window.removeEventListener('beforeunload', this.boundUnloadEvent);
    this.set('boundUnloadEvent', null);
    document.body.classList.remove('signup__teams__body');
  },

  activate() {
    this.hidePrimaryNav();
    this.set('boundUnloadEvent', this.fireUnloadEvent.bind(this));
    window.addEventListener('beforeunload', this.boundUnloadEvent);
  },
});
