/* import __COLOCATED_TEMPLATE__ from './title-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import {
  humanReadableObjectNames,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { isBlank } from '@ember/utils';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import { action } from '@ember/object';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  contentWrapper: ContentWrapper;
  onClick: () => void;
}

export default class TitleCell extends Component<Args> {
  @service declare intl: IntlService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare appService: $TSFixMe;

  get title(): string {
    if (isBlank(this.args.contentWrapper.title)) {
      return this.defaultTitle;
    } else {
      return this.args.contentWrapper.title;
    }
  }

  get defaultTitle(): string {
    let humanReadableObjectName =
      humanReadableObjectNames[this.args.contentWrapper.contents.firstObject!.contentType];

    return this.intl.t('ai-content-library.list.cells.title.untitled', {
      objectName: humanReadableObjectName,
    });
  }

  get entityTypeName(): string {
    return objectNames[this.args.contentWrapper.contents.firstObject!.contentType];
  }

  @action
  onTitleClick() {
    if (this.appService.app.canUseContextEditorDiffMode) {
      let content = this.args.contentWrapper.contents.firstObject!;
      if (content.contentType === EntityType.ContentSnippet) {
        this.knowledgeHubDrawerEditorService.openViewDrawer({
          activeContentId: content.contentId,
          activeContentType: this.entityTypeName,
        });
      } else {
        this.knowledgeHubDrawerEditorService.openReviewDrawer({
          activeContentId: content.contentId,
          activeContentType: this.entityTypeName,
        });
      }
    } else {
      this.args.onClick();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::TitleCell': typeof TitleCell;
  }
}
