/* RESPONSIBLE TEAM: team-channels */
import type Conversation from 'embercom/objects/inbox/conversation';
import { NewConversation } from 'embercom/objects/inbox/conversation';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { participantObjectToUserSummary } from 'embercom/objects/inbox/user-summary';
import { latestUpdatedRecipientsListStorageKey } from 'embercom/objects/inbox/user-summary';
import type Session from 'embercom/services/session';
import storage from 'embercom/vendor/intercom/storage';
import createReplyRecipients, { ReplyRecipientsModel } from './reply-recipients';

export default class UpdatedRecipientsFactory {
  constructor(
    private session: Session,
    private conversation: Conversation | NewConversation,
  ) {}

  create(): ReplyRecipientsModel {
    if (!this.ccEnabled) {
      return createReplyRecipients(this.conversation);
    }

    if (this.recipientLoadedFromQueryParam) {
      return this.createFromQueryParams();
    }

    let draftRecipients = this.createFromDraft();
    if (draftRecipients) {
      return draftRecipients;
    }

    return createReplyRecipients(this.conversation);
  }

  private get ccEnabled(): boolean {
    return this.session.workspace.isFeatureEnabled('team-channels-cc');
  }

  private get recipients(): UserSummary[] {
    return this.conversation.participantSummaries;
  }

  private get recipientLoadedFromQueryParam(): boolean {
    return (
      this.conversation instanceof NewConversation &&
      this.recipients?.length > 0 &&
      this.conversation.isRecipientLoadedFromQueryParams
    );
  }

  private createFromQueryParams() {
    return new ReplyRecipientsModel(this.recipients);
  }

  private createFromDraft() {
    return this.getDraftRecipients(storage, this.session.workspace.id, this.conversation?.id);
  }

  private getDraftRecipients(
    storage: { get: (arg0: string) => any },
    workspaceId: string,
    conversationId?: number,
  ) {
    let latestUpdatedRecipientsList = storage.get(
      latestUpdatedRecipientsListStorageKey(workspaceId, conversationId),
    );

    if (!latestUpdatedRecipientsList) {
      return;
    }

    let toRecipients = latestUpdatedRecipientsList.to.map((recipient: UserSummary) =>
      participantObjectToUserSummary(recipient),
    );
    let ccRecipients = latestUpdatedRecipientsList.cc.map((recipient: UserSummary) =>
      participantObjectToUserSummary(recipient),
    );

    return new ReplyRecipientsModel(toRecipients, ccRecipients);
  }
}
