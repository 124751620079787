/* import __COLOCATED_TEMPLATE__ from './share-with-colleague-prompt.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ShareWithColleaguePrompt extends Component {
  @service appService;
  @service router;

  get url() {
    if (this.args.paywallUrl) {
      return this.args.paywallUrl;
    }

    let featureKey = this.args.feature?.key;
    let billingUrl = this.router.urlFor(
      'apps.app.settings.subscription.billing.summary.index',
      this.appService.app.id,
    );
    let url = `${window.location.protocol}//${window.location.host}${billingUrl}`;
    if (featureKey) {
      return `${url}?feature=${this.args.feature.key}`;
    }
    return url;
  }

  get limitedAdminList() {
    let admins = this.appService.app.adminsWithBillingPermissions;
    if (admins.length >= 10) {
      admins = admins.slice(0, 10);
    }
    return admins;
  }
}
