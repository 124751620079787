/* RESPONSIBLE TEAM: team-frontend-tech */
/* eslint-disable @intercom/intercom/no-bare-strings */

const times = [
  {
    text: '12:00 am',
    value: 0,
  },
  {
    text: '12:15 am',
    value: 15,
  },
  {
    text: '12:30 am',
    value: 30,
  },
  {
    text: '12:45 am',
    value: 45,
  },
  {
    text: '1:00 am',
    value: 60,
  },
  {
    text: '1:15 am',
    value: 75,
  },
  {
    text: '1:30 am',
    value: 90,
  },
  {
    text: '1:45 am',
    value: 105,
  },
  {
    text: '2:00 am',
    value: 120,
  },
  {
    text: '2:15 am',
    value: 135,
  },
  {
    text: '2:30 am',
    value: 150,
  },
  {
    text: '2:45 am',
    value: 165,
  },
  {
    text: '3:00 am',
    value: 180,
  },
  {
    text: '3:15 am',
    value: 195,
  },
  {
    text: '3:30 am',
    value: 210,
  },
  {
    text: '3:45 am',
    value: 225,
  },
  {
    text: '4:00 am',
    value: 240,
  },
  {
    text: '4:15 am',
    value: 255,
  },
  {
    text: '4:30 am',
    value: 270,
  },
  {
    text: '4:45 am',
    value: 285,
  },
  {
    text: '5:00 am',
    value: 300,
  },
  {
    text: '5:15 am',
    value: 315,
  },
  {
    text: '5:30 am',
    value: 330,
  },
  {
    text: '5:45 am',
    value: 345,
  },
  {
    text: '6:00 am',
    value: 360,
  },
  {
    text: '6:15 am',
    value: 375,
  },
  {
    text: '6:30 am',
    value: 390,
  },
  {
    text: '6:45 am',
    value: 405,
  },
  {
    text: '7:00 am',
    value: 420,
  },
  {
    text: '7:15 am',
    value: 435,
  },
  {
    text: '7:30 am',
    value: 450,
  },
  {
    text: '7:45 am',
    value: 465,
  },
  {
    text: '8:00 am',
    value: 480,
  },
  {
    text: '8:15 am',
    value: 495,
  },
  {
    text: '8:30 am',
    value: 510,
  },
  {
    text: '8:45 am',
    value: 525,
  },
  {
    text: '9:00 am',
    value: 540,
  },
  {
    text: '9:15 am',
    value: 555,
  },
  {
    text: '9:30 am',
    value: 570,
  },
  {
    text: '9:45 am',
    value: 585,
  },
  {
    text: '10:00 am',
    value: 600,
  },
  {
    text: '10:15 am',
    value: 615,
  },
  {
    text: '10:30 am',
    value: 630,
  },
  {
    text: '10:45 am',
    value: 645,
  },
  {
    text: '11:00 am',
    value: 660,
  },
  {
    text: '11:15 am',
    value: 675,
  },
  {
    text: '11:30 am',
    value: 690,
  },
  {
    text: '11:45 am',
    value: 705,
  },
  {
    text: '12:00 pm',
    value: 720,
  },
  {
    text: '12:15 pm',
    value: 735,
  },
  {
    text: '12:30 pm',
    value: 750,
  },
  {
    text: '12:45 pm',
    value: 765,
  },
  {
    text: '1:00 pm',
    value: 780,
  },
  {
    text: '1:15 pm',
    value: 795,
  },
  {
    text: '1:30 pm',
    value: 810,
  },
  {
    text: '1:45 pm',
    value: 825,
  },
  {
    text: '2:00 pm',
    value: 840,
  },
  {
    text: '2:15 pm',
    value: 855,
  },
  {
    text: '2:30 pm',
    value: 870,
  },
  {
    text: '2:45 pm',
    value: 885,
  },
  {
    text: '3:00 pm',
    value: 900,
  },
  {
    text: '3:15 pm',
    value: 915,
  },
  {
    text: '3:30 pm',
    value: 930,
  },
  {
    text: '3:45 pm',
    value: 945,
  },
  {
    text: '4:00 pm',
    value: 960,
  },
  {
    text: '4:15 pm',
    value: 975,
  },
  {
    text: '4:30 pm',
    value: 990,
  },
  {
    text: '4:45 pm',
    value: 1005,
  },
  {
    text: '5:00 pm',
    value: 1020,
  },
  {
    text: '5:15 pm',
    value: 1035,
  },
  {
    text: '5:30 pm',
    value: 1050,
  },
  {
    text: '5:45 pm',
    value: 1065,
  },
  {
    text: '6:00 pm',
    value: 1080,
  },
  {
    text: '6:15 pm',
    value: 1095,
  },
  {
    text: '6:30 pm',
    value: 1110,
  },
  {
    text: '6:45 pm',
    value: 1125,
  },
  {
    text: '7:00 pm',
    value: 1140,
  },
  {
    text: '7:15 pm',
    value: 1155,
  },
  {
    text: '7:30 pm',
    value: 1170,
  },
  {
    text: '7:45 pm',
    value: 1185,
  },
  {
    text: '8:00 pm',
    value: 1200,
  },
  {
    text: '8:15 pm',
    value: 1215,
  },
  {
    text: '8:30 pm',
    value: 1230,
  },
  {
    text: '8:45 pm',
    value: 1245,
  },
  {
    text: '9:00 pm',
    value: 1260,
  },
  {
    text: '9:15 pm',
    value: 1275,
  },
  {
    text: '9:30 pm',
    value: 1290,
  },
  {
    text: '9:45 pm',
    value: 1305,
  },
  {
    text: '10:00 pm',
    value: 1320,
  },
  {
    text: '10:15 pm',
    value: 1335,
  },
  {
    text: '10:30 pm',
    value: 1350,
  },
  {
    text: '10:45 pm',
    value: 1365,
  },
  {
    text: '11:00 pm',
    value: 1380,
  },
  {
    text: '11:15 pm',
    value: 1395,
  },
  {
    text: '11:30 pm',
    value: 1410,
  },
  {
    text: '11:45 pm',
    value: 1425,
  },
  {
    text: '11:59 pm',
    value: 1439,
  },
  {
    text: 'Midnight',
    value: 1440,
  },
];

export default Object.freeze(times);
