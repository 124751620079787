/* RESPONSIBLE TEAM: team-actions */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import { type AvailableFinActionsWireFormat } from 'embercom/objects/inbox/available-fin-actions';

export default class AvailableFinActions implements RenderableData {
  renderableType: RenderableType = RenderableType.AvailableFinActions;
  readonly actions: any[];

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(actions: any[]) {
    this.actions = actions;
  }

  static deserialize(json: AvailableFinActionsWireFormat): AvailableFinActions {
    return new AvailableFinActions(json.actions);
  }
}
