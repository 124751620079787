/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { type ShowRequiredAttributesPanelFct } from 'embercom/components/inbox2/conversations-table';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type Snackbar from 'embercom/services/snackbar';
import type AdminSummary from './admin-summary';
import type ConversationAttributeDescriptor from './conversation-attribute-descriptor';
import { emptyRequiredAttributesForConversation } from './conversation-attribute-descriptor';
import type ConversationTableEntry from './conversation-table-entry';
import { type DurationObject } from './duration';
import type Inbox from './inboxes/inbox';
import TeamSummary from './team-summary';
import { inject as service } from '@ember/service';
import { getOwner, setOwner } from '@ember/application';
import { type ConversationRecord } from './types/conversation-record';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import ConditionalAttributesEvaluator from 'embercom/services/conditional-attributes-evaluator';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type Session from 'embercom/services/session';

interface Args {
  currentInbox?: Inbox;
  showRequiredAttributesPanel: ShowRequiredAttributesPanelFct;
  descriptors: ConversationAttributeDescriptor[];
  owner: unknown;
}

export default class ConversationsTableActions {
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare snackbar: Snackbar;
  @service declare ticketStateService: TicketStateService;
  @service declare session: Session;

  readonly currentInbox?: Inbox;
  readonly showRequiredAttributesPanel: ShowRequiredAttributesPanelFct;
  readonly descriptors: ConversationAttributeDescriptor[];

  constructor({ currentInbox, showRequiredAttributesPanel, descriptors, owner }: Args) {
    setOwner(this, owner);
    this.currentInbox = currentInbox;
    this.showRequiredAttributesPanel = showRequiredAttributesPanel;
    this.descriptors = descriptors;
  }

  conversationAttributesById(attributes: ConversationAttributeSummary[]) {
    return attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  /**
   * Single actions
   */

  @action
  togglePriority(
    conversationEntry: ConversationTableEntry,
    trackingSection: string,
    options?: { keyboardShortcutUsed: boolean },
  ) {
    let currentPriority = conversationEntry.priority;
    let newPriority = !currentPriority;

    this.intercomEventService?.trackAnalyticsEvent({
      action: 'changed',
      object: 'priority',
      place: 'inbox',
      section: trackingSection || 'conversation_details',
      conversation_id: conversationEntry.id,
      marked_as_priority: newPriority,
      inbox_type: this.inboxState.activeInbox?.type,
      layout_type: this.inboxState.activeConversationsView,
      shortcut_key: options?.keyboardShortcutUsed,
    });

    return this.inboxState.changePriority(conversationEntry, newPriority);
  }

  @action
  assign(
    conversationEntry: ConversationTableEntry,
    assignee: AdminSummary | TeamSummary,
    trackingSection: string,
  ) {
    if (assignee instanceof TeamSummary) {
      return this.assignTeam(conversationEntry, assignee, trackingSection);
    } else {
      return this.assignAdmin(conversationEntry, assignee, trackingSection);
    }
  }

  @action
  assignAdmin(
    conversationEntry: ConversationTableEntry,
    newAdmin: AdminSummary,
    trackingSection: string,
  ) {
    return this.inboxState.assignConversationToAdmin(conversationEntry, newAdmin, trackingSection);
  }

  @action
  assignTeam(
    conversationEntry: ConversationTableEntry,
    newTeam: TeamSummary,
    trackingSection: string,
  ) {
    return this.inboxState.assignConversationToTeam(conversationEntry, newTeam, trackingSection);
  }

  @action
  updateTicketState(
    conversationEntry: ConversationTableEntry,
    ticketState: TicketCustomState,
    trackingSection: string,
  ) {
    return this.inboxState.changeTicketStateLocalUpdates(
      conversationEntry,
      ticketState,
      trackingSection,
    );
  }

  open = (conversationEntry: ConversationTableEntry, trackingSection: string) => {
    if (trackingSection) {
      this.trackConversationStateChanged(conversationEntry, 'open', trackingSection);
    }

    return this.inboxState.openConversation(conversationEntry);
  };

  close = async (
    conversationEntry: ConversationTableEntry,
    trackingSection?: string,
    doAfter?: () => void,
  ) => {
    let visibleDescriptors = this.descriptors;

    if (this.session.workspace.canUseConditionalAttributes) {
      visibleDescriptors = new ConditionalAttributesEvaluator({
        conversation: conversationEntry,
        descriptors: this.descriptors,
        owner: getOwner(this),
      }).visibleAttributes() as ConversationAttributeDescriptor[];
    }

    if (emptyRequiredAttributesForConversation(conversationEntry, visibleDescriptors).length) {
      this.showRequiredAttributesPanel(conversationEntry, async (attributes) => {
        await this.inboxState.updateAttributesAndClose(conversationEntry, attributes);
        doAfter?.();
      });
    } else {
      trackingSection &&
        this.trackConversationStateChanged(conversationEntry, 'close', trackingSection);
      await this.inboxState.closeConversation(conversationEntry);
      doAfter?.();
    }
  };

  snooze = (
    conversationEntry: ConversationTableEntry,
    duration: DurationObject,
    trackingSection: string,
  ) => {
    if (trackingSection) {
      this.trackConversationStateChanged(conversationEntry, 'snooze', trackingSection);
    }

    return this.inboxState.snoozeConversation(conversationEntry, duration);
  };

  /**
   * Bulk actions
   */

  async togglePriorityBulk(conversationEntries: ConversationTableEntry[], newPriority: boolean) {
    let conversationIds = conversationEntries.map((entry) => entry.id);
    this.trackBulkPriorityChanged(conversationIds, newPriority);
    return this.inboxState.changeConversationsPriority(conversationIds, newPriority);
  }

  assignBulk(
    conversationEntries: ConversationTableEntry[],
    newAssignee: AdminSummary | TeamSummary,
  ) {
    return this.inboxState.assignConversations(conversationEntries, newAssignee);
  }

  closeBulk(conversationEntries: ConversationTableEntry[], closeAndResolve = false) {
    return this.inboxState.closeConversations(
      conversationEntries.map((entry) => entry.id),
      closeAndResolve,
    );
  }

  openBulk(conversationEntries: ConversationTableEntry[]) {
    return this.inboxState.openConversations(conversationEntries.map((entry) => entry.id));
  }

  /**
   * Tracking
   */

  private trackConversationStateChanged(
    conversation: ConversationRecord,
    trackingAction: string,
    trackingSection: string,
  ) {
    this.intercomEventService.trackAnalyticsEvent({
      action: trackingAction,
      object: 'conversation',
      conversation_id: conversation.id,
      layout_type: this.inboxState.activeConversationsView,
      section: trackingSection,
    });
  }

  private trackBulkPriorityChanged(ids: number[], newPriority: boolean) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed',
      object: 'priority',
      place: 'inbox',
      conversation_ids: ids,
      marked_as_priority: newPriority,
      inbox_type: this.inboxState.activeInbox?.type,
      layout_type: this.inboxState.activeConversationsView,
      count_conversations_selected: ids.length,
    });
  }
}
