/* import __COLOCATED_TEMPLATE__ from './platform-availability-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import { type InstallationPlatform } from './constants';
import type SdkAppService from 'embercom/services/sdk-app-service';

export interface PlatformAvailabilityToggleArgs {
  platform: InstallationPlatform;
  label: string;
  description: string;
}

interface Signature {
  Element: HTMLElement;
  Args: PlatformAvailabilityToggleArgs;
}

export default class PlatformAvailabilityToggle extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: $TSFixMe;
  @service declare messengerInstallation: MessengerInstallation;
  @service declare sdkAppService: SdkAppService;

  @tracked showConfirmModal = false;

  constructor(owner: unknown, args: never) {
    super(owner, args);
  }

  get appForPlatform() {
    switch (this.args.platform) {
      case 'ios':
        return this.sdkAppService.iosSdkApp;
      case 'android':
        return this.sdkAppService.androidSdkApp;
      default:
        return this.appService.app;
    }
  }

  get fieldName(): string {
    switch (this.args.platform) {
      case 'ios':
        return 'is_disabled';
      case 'android':
        return 'is_disabled';
      case 'web':
        return 'is_web_messenger_disabled';
      default:
        return '';
    }
  }

  get isTurnedOn() {
    return !this.appForPlatform.get(this.fieldName);
  }

  @action
  onClickHandler(): void {
    let wasDisabled = this.appForPlatform.get(this.fieldName);
    if (!wasDisabled) {
      this.showConfirmModal = true;
      return;
    }

    taskFor(this.messengerInstallation.togglePlatformApiAvailability).perform(this.args.platform);
  }

  @action
  onConfirm(): void {
    this.showConfirmModal = false;
    taskFor(this.messengerInstallation.togglePlatformApiAvailability).perform(this.args.platform);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::PlatformAvailabilityToggle': typeof PlatformAvailabilityToggle;
    'installation-new/platform-availability-toggle': typeof PlatformAvailabilityToggle;
  }
}
