/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { notEmpty, filterBy, sort } from '@ember/object/computed';
import Component from '@ember/component';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  tagName: '',
  hasMessengerAppInPrefetch: notEmpty('messengerAppInPrefetch'),
  messengerAppInPrefetch: findBy('items', 'isPrefetchingConfiguration'),
  notifiableItems: filterBy('sortedItems', 'notifiable', true),
  nonNotifiableItems: filterBy('sortedItems', 'notifiable', false),
  sortedItems: sort('items', 'sortedItemsSortKey'),
  sortedItemsSortKey: ['name:asc'],

  didInsertElement() {
    this._super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'messenger_app_inserter',
      notifiable_count: this.notifiableItems.length,
    });
  },
});
