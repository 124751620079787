/* RESPONSIBLE TEAM: team-actions */
import { hash } from 'rsvp';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import redirect from 'embercom/lib/redirect';

export default IntercomRoute.extend({
  store: service(),
  intercomEventService: service(),

  afterModel() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      object: 'dev_hub_home',
      place: 'developer_hub',
    });
  },
  redirectTo(url) {
    redirect(url);
  },
  fetchAppPackages() {
    return this.store.findAll('developer-hub/app-package', { reload: true });
  },
  fetchApps() {
    return this.store.findAll('developer-hub/app');
  },
  model() {
    return hash({
      appPackages: this.fetchAppPackages(),
      apps: this.fetchApps(),
    });
  },
});
