/* import __COLOCATED_TEMPLATE__ from './messenger-settings.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type Model from '@ember-data/model';
import { type PlatformId } from 'embercom/components/standalone/intercom-messenger/setup/messenger-settings/platform-selector';
import type LookAndFeelModel from 'embercom/models/messenger-settings/look-and-feel';
import type Security from 'embercom/models/messenger-settings/security';

export interface Args {
  messengerSettings: Model & {
    hasUnsavedChanges: boolean;
    lookAndFeel: LookAndFeelModel;
    security: Security;
  };
  onSelectPlatform: (platform: PlatformId) => void;
  selectedPlatform: PlatformId;
}

export default class StandaloneMessengerSettings extends Component<Args> {
  @service declare intl: IntlService;
  @service declare messengerSettingsService: MessengerSettingsService;
  @service declare notificationsService: $TSFixMe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.messengerSettingsService.preview.selectedScreen = 'conversation';
    this.messengerSettingsService.navigation.selectedTab = 'fin';
  }

  get isSaving(): boolean {
    return taskFor(this.saveSettings).isRunning;
  }

  async save() {
    this.args.messengerSettings.security.beforeSave();
    await this.args.messengerSettings.lookAndFeel.beforeSave();
    await this.args.messengerSettings.save();
  }

  @task({ drop: true })
  *saveSettings(): TaskGenerator<void> {
    try {
      yield this.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('messenger.settings-layout.header.success-notification'),
      );
    } catch (error) {
      if (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('messenger.settings-layout.header.failure-notification'),
        });
      }
      throw error;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup::MessengerSettings': typeof StandaloneMessengerSettings;
    'standalone/intercom-messenger/setup/messenger-settings': typeof StandaloneMessengerSettings;
  }
}
