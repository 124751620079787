/* import __COLOCATED_TEMPLATE__ from './handoff-to-zendesk-ticket.hbs'; */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* RESPONSIBLE TEAM: team-standalone */
import type HandoffToZendeskTicketStepType from 'embercom/models/operator/visual-builder/step/handoff-to-zendesk-ticket';
import type HandoffToZendeskTicketConfiguration from 'embercom/objects/visual-builder/configuration/step/handoff-to-zendesk-ticket';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import FinStandaloneService from 'embercom/services/fin-standalone-service';
import RouterService from '@ember/routing/router-service';
import CustomIntlService from 'embercom/services/intl';

export interface HandoffToZendeskTicketArgs {
  step: HandoffToZendeskTicketStepType;
  stepConfig: HandoffToZendeskTicketConfiguration;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: HandoffToZendeskTicketArgs;
}

export default class HandoffToZendeskTicket extends Component<Signature> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare router: RouterService;
  @service declare intl: CustomIntlService;

  get tooltipContent() {
    if (this.shouldShowError && this.requiresApiConnection) {
      return this.apiConnectionErrorCopy;
    }
    return this.intl.t('operator.workflows.visual-builder.step-menu.create-zendesk-ticket-tooltip');
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }

  get requiresApiConnection(): boolean {
    return this.args.step.validations.attrs.zendeskApiIsConnected.isInvalid;
  }

  get apiConnectionErrorCopy() {
    return this.intl.t(
      'operator.workflows.visual-builder.step-menu.create-zendesk-ticket-api-connection-error-tooltip',
      {
        channelPageUrl: this.channelPageUrl,
        htmlSafe: true,
      },
    );
  }

  get channelPageUrl() {
    return this.router.urlFor('apps.app.settings.standalone.integration');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffToZendeskTicket': typeof HandoffToZendeskTicket;
    'workflows/graph-editor/node-items/steps/handoff-to-zendesk-ticket': typeof HandoffToZendeskTicket;
  }
}
