/* import __COLOCATED_TEMPLATE__ from './salesforce.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';

interface Args {
  modal: any;
  title?: string;
  onModalClose: () => void;
}

export default class SalesforceSyncModal extends Component<Args> {
  connectionType = 'salesforce-src';
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::Salesforce': typeof SalesforceSyncModal;
    'knowledge-hub/setup-modal/sync-modal/salesforce': typeof SalesforceSyncModal;
  }
}
