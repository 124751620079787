/* import __COLOCATED_TEMPLATE__ from './discovery-banner-contents.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';
import type { ContentFilter } from '../overview';
import type { BannerConfig } from './discovery-banner';

interface Signature {
  Args: {
    contentFilter: ContentFilter;
    config: BannerConfig;
  };
}

const DiscoveryBannerContents = templateOnlyComponent<Signature>();
export default DiscoveryBannerContents;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::DiscoveryBannerContents': typeof DiscoveryBannerContents;
    'knowledge-hub/overview/discovery-banner-contents': typeof DiscoveryBannerContents;
  }
}
