/* import __COLOCATED_TEMPLATE__ from './empty-state-guide-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  data: any;
  createContent: () => void;
  templates: any[];
  disableNewSmsButton: boolean | undefined;
  openVideoModal: () => void;
  // optional for now as we support old banner behind FF
  ctaAction?: () => void;
}

export default class EmptyStateGuideBanner extends Component<Args> {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @tracked showContentCreationModal = false;
  @tracked newContentModalEntryPoint: 'demo_outbound_messages' | undefined;

  get app() {
    return this.appService.app;
  }

  @action
  showDemoMessagesModal() {
    this.showContentCreationModal = true;
    this.newContentModalEntryPoint = 'demo_outbound_messages';
  }

  @action
  showViewPricingArticle() {
    window.Intercom('showArticle', 9061614); // https://www.intercom.com/help/en/articles/9061614-intercom-plans-explained
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'view_pricing_link',
      context: 'discovery-banner',
      place: 'outbound.list-header',
      section: 'discovery-banner',
    });
  }

  @action
  showGetStartedWithOutboundArticle() {
    window.Intercom('showArticle', 3292835); // https://www.intercom.com/help/en/articles/3292835-get-started-with-outbound
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'get_started_with_outbound_help_link',
      context: 'discovery-banner',
      place: 'outbound.list-header',
      section: 'discovery-banner',
    });
  }

  @action
  toggleContentCreationModal() {
    this.showContentCreationModal = !this.showContentCreationModal;
    this.intercomEventService.trackAnalyticsEvent({
      action: this.showContentCreationModal ? 'opened' : 'closed',
      object: 'admin',
      context: 'template_modal',
      location: 'discovery_banner',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::EmptyStateGuideBanner': typeof EmptyStateGuideBanner;
    'outbound/empty-state-guide-banner': typeof EmptyStateGuideBanner;
  }
}
