/* RESPONSIBLE TEAM: team-reporting */
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { isEmpty } from 'underscore';
import { isPresent } from '@ember/utils';
import { captureException } from 'embercom/lib/sentry';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import { NO_DATASET } from 'embercom/objects/reporting/unified/metrics/types';

const FILTERED_INTERVAL_SUPPORTED_METRICS = ['time_on_status'];
const rangeFilters = ['gt', 'lt'];
const regexFilters = ['contains', 'not_contains', 'starts_with', 'ends_with'];
export const existsFilters = ['exists', 'not_exists'];
export const categoryFilters = ['category', 'not_in_category'];
const TEAMMATE_ALIAS = ['teammate_assignee_at_close', 'teammate'];
const TEAMMATE_ATTRIBUTE_IDS = ['teammate_id', 'teammate.id', 'comment_admin_assignee_id'];
const TEAM_PROPERTY = 'team';

export const MEMBER_OF_TEAM = 'member_of_team';
export const NOT_MEMBER_OF_TEAM = 'not_member_of_team';
export const TEAM_OPTIONS = [MEMBER_OF_TEAM, NOT_MEMBER_OF_TEAM];

function existsFilter({ type, attribute, property }) {
  return {
    type,
    data: {
      attribute,
      property,
    },
  };
}

function rangeFilter({ type, attribute, property, values }) {
  return {
    type: 'range',
    data: {
      attribute,
      property,
      operators: [type],
      values,
    },
  };
}

function regexFilter({ type, attribute, property, values }) {
  return {
    type: 'regular_expression',
    data: {
      attribute,
      property,
      operators: [type],
      values,
    },
  };
}

function metricUsesAlias(metric, property) {
  return (
    TEAMMATE_ATTRIBUTE_IDS.includes(property) &&
    isPresent(metric) &&
    isPresent(metric.teammateProperties) &&
    metric.teammateProperties[property]
  );
}

function getPropertyForTeammateAndTeamAlias(metric, alias) {
  if (alias === 'teammate') {
    return metric.teammateProperty;
  }
  if (alias === 'team') {
    return metric.teamProperty;
  }
}

function getAttributeIdByField(field, metric, renderableChart) {
  let attributes = renderableChart.reportingMetrics.getAttributesByField(field, metric.datasetId);
  if (attributes.length === 1) {
    return attributes[0].id;
  }

  if (attributes.length === 0) {
    // there are some cases where a metric has a "team" or "teammate" property defined which doesn't
    // have a corresponding attribute in the dataset (e.g. team.id)
    let error = new Error(`Could not find attribute for property ${field} for metric ${metric.id}`);
    console.error(error);
    captureException(error);
    return;
  }
  if (attributes.length > 1) {
    let error = new Error(
      `Found multiple attributes for property ${field} for metric ${metric.id}`,
    );
    console.error(error);
    captureException(error);
    return;
  }
}

function transformDynamicAttributes(filter, metric, renderableChart) {
  let property = filter?.data?.property;
  property = getPropertyForTeammateAndTeamAlias(metric, property);
  if (!property) {
    return filter;
  }

  let attribute =
    getAttributeIdByField(property, metric, renderableChart) || filter?.data?.attribute;
  return {
    ...filter,
    data: {
      ...filter.data,
      attribute,
      property,
    },
  };
}

function transformAnyValueFilter({ type, attribute, property }) {
  // TODO: we shouldn't be persisting ['any'] in the first place
  if (![...categoryFilters, ...TEAM_OPTIONS].includes(type)) {
    throw new Error(
      `Unexpected filter type ${type} supplied with 'Any' value for property ${property}`,
    );
  }
  type = ['category', MEMBER_OF_TEAM].includes(type) ? 'exists' : 'not_exists';
  return existsFilter({ type, attribute, property });
}

export function transformFilter(
  { type = 'category', data: { attribute, property, values } },
  metric,
) {
  if (values?.firstObject === REPORTING_FILTER_SELECT_ALL) {
    return transformAnyValueFilter({ type, attribute, property });
  } else if (rangeFilters.includes(type)) {
    return rangeFilter({ type, attribute, property, values });
  } else if (regexFilters.includes(type)) {
    return regexFilter({ type, attribute, property, values });
  } else if (existsFilters.includes(type)) {
    return existsFilter({ type, attribute, property });
  }

  return {
    type,
    data: {
      attribute,
      property,
      values,
    },
  };
}

function filterIsSupported(filter, metric) {
  let supportedFilters = metric.supportedFilters;

  // TODO: start using attribute identifiers here instead
  return (
    TEAMMATE_ATTRIBUTE_IDS.includes(filter.data.property) ||
    TEAMMATE_ALIAS.includes(filter.data.property) ||
    supportedFilters?.some((supportedFilter) => supportedFilter.field === filter.data.property)
  );
}

function getMetricAttributeFilter(metric) {
  return {
    type: 'exists',
    data: {
      property: metric.property,
    },
  };
}

export function buildFiltersForDataConfig(
  renderableChart,
  metric,
  parentMetric,
  filters,
  includeReportLevelFilters = true,
  includeMetricAttributeFilter = false,
) {
  let reportFilters = renderableChart.reportState?.filters;
  let metricFilters = metric?.filter;

  let result = {
    type: 'and',
    filters: [filters],
  };

  if (includeReportLevelFilters) {
    result.filters.push(reportFilters);
  }

  if (includeMetricAttributeFilter) {
    result.filters.push(getMetricAttributeFilter(metric));
  }

  // a composite metric needs two requests, one for the numerator and one for the denominator
  // if we are currently building the filters for the denominator we need to transform the attributes
  // used in the filters from attributes available in the numerator's dataset to attributes available in the denominator's dataset
  let denominatorMetricId =
    parentMetric?.denominator?.id || renderableChart.firstMetric?.denominator?.id;
  if (denominatorMetricId && metric.id === denominatorMetricId) {
    result = transformFilters(result, (filter) =>
      transformFilterAttributeForDenominators(filter, metric, renderableChart),
    );
  }

  let isCurrentMetricPartOfRatioMetric =
    renderableChart.firstMetric?.type === 'ratio' || parentMetric?.type === 'ratio';
  if (isCurrentMetricPartOfRatioMetric) {
    // remove unsupported filters for ratio metrics
    result = transformFilters(result, (filter) =>
      filterIsSupported(filter, metric) ? filter : null,
    );
  }
  // it's important to add the metric filters AFTER we remove the unsupported filters
  // this is because we often include implicit filters in a metric definition
  // where the attribute being filtered on it not customer-facing (so it's not in supportedFilters)
  // ideally we'd be completely transparent and only ever filter on things the
  // customer could filter on themselves.
  // but since all the filter could have been removed by the previous step we need
  // to add an empty filter.
  if (isEmpty(result)) {
    result = { type: 'and', filters: [] };
  }
  result.filters.push(metricFilters);

  // remove ignored filters
  // these are weird and only used in survey reporting, e.g. https://github.com/intercom/embercom/blob/e7f3e3ef00eabd668005dede19ece735bb37a5a1/app/components/reporting/custom/report/survey-report.js#L104
  // TODO: we should limit their use and remove them if possible
  result = transformFilters(result, (filter) =>
    renderableChart.ignoredFilters.has(filter.data?.property) ? null : filter,
  );

  result = transformFilters(result, (filter) =>
    transformDynamicAttributes(filter, metric, renderableChart),
  );
  result = transformFilters(result, (filter) => transformFilter(filter, metric));
  result = transformFilters(result, (filter) =>
    handleAttributesNotInTheMetricDataset(filter, metric, renderableChart),
  );
  result = transformFilters(result, (filter) => removeUndefinedAttributeProperty(filter));

  return result || { type: 'and', filters: [] };
}

function handleAttributesNotInTheMetricDataset(filter, metric, renderableChart) {
  let attributeId = filter?.data?.attribute;
  if (!attributeId) {
    return filter;
  }

  let dataset = renderableChart.reportingMetrics.getDatasetById(metric.datasetId);
  if (!dataset || dataset.hasAttribute(attributeId)) {
    return filter;
  }

  return {
    ...filter,
    data: {
      ...filter.data,
      property: `non_existent_field.${attributeId.replace('#', '.')}`,
    },
  };
}

function transformFilterAttributeForDenominators(
  numeratorFilter,
  denominatorMetric,
  renderableChart,
) {
  if (!numeratorFilter.data) {
    return numeratorFilter;
  }
  let propertyForAttributeOnNumeratorDataset = numeratorFilter.data.property;
  // TODO: we should reconsider how we define this behaviour and whether we want it at all
  // the UI behaves like the filter is on the numerator and uses the attribute on the numerator's dataset
  // the denominator's metric has a `teammateProperties` object that maps the property on the numerator's dataset to the relevant property on the denominator's dataset
  // TODO: we should be using attribute identifiers here, and the mapping should be on the parent (composite) metric
  let property = denominatorMetric.teammateProperties?.[propertyForAttributeOnNumeratorDataset];
  if (!property) {
    return numeratorFilter;
  }
  let attribute = renderableChart.reportingMetrics.getAttributeByField(
    property,
    denominatorMetric.datasetId,
  )?.id;
  return {
    ...numeratorFilter,
    data: {
      ...numeratorFilter.data,
      attribute,
      property,
    },
  };
}

function removeUndefinedAttributeProperty(filter) {
  if (
    filter.data &&
    filter.data.hasOwnProperty('attribute') &&
    filter.data.attribute === undefined
  ) {
    delete filter.data.attribute;
  }
  return filter;
}

// we recursively apply the transformation function to each filter without any child filters
// (i.e. we don't transform the logical filters - and / or)
function transformFilters(filter, transformFunction) {
  // using isEmpty instead of isBlank here because the default value for filters for the
  // chart and report ember data models is {} which is not a valid filter
  // TODO: change those defaults to null and backfill the data so we don't need to special case {}
  if (isEmpty(filter)) {
    return null;
  }

  let result = {
    ...filter,
  };
  if (filter.filters) {
    result.filters = filter.filters
      .map((child) => transformFilters(child, transformFunction))
      .compact();
    if (result.filters.length === 0) {
      return null;
    }
    return result;
  }
  return transformFunction(result, transformFunction);
}

export function buildAxesForDataConfig(
  renderableChart,
  metric = undefined,
  applyLimitOnServer = true,
) {
  let xAxis = null;
  let yAxis = null;
  let shouldIncludeAttributeIds = isPresent(metric) && !renderableChart.isBespoke;

  if (renderableChart.viewBy) {
    xAxis = renderableChart.isBrokenDownByTime
      ? {
          name: '0',
          type: 'temporal',
          data: {
            property: timeProperty(renderableChart.viewBy, renderableChart.timeProperty, metric),
            ...(shouldIncludeAttributeIds
              ? {
                  attribute_id: timeAttributeId(
                    renderableChart.viewBy,
                    renderableChart.timeProperty,
                    metric,
                    renderableChart,
                  ),
                }
              : {}),
            interval: renderableChart.viewByTimeInterval || renderableChart.dateRange.interval,
          },
        }
      : {
          name: '0',
          type: 'nominal',
          data: {
            applyLimitOnServer,
            limit: renderableChart.viewByDisplayLimit,
            property: termProperty(renderableChart.viewBy, metric),
            ...(shouldIncludeAttributeIds
              ? {
                  attribute_id: termAttributeId(renderableChart.viewBy, metric, renderableChart),
                }
              : {}),
            showOther: renderableChart.visualizationType === VISUALIZATION_TYPES.DONUT,
            ...(!applyLimitOnServer && { term_size: 10000 }),
          },
        };
  }

  if (
    renderableChart.segmentBy &&
    // segmentBy cannot be used with donut charts; see https://github.com/intercom/intercom/issues/324496
    (renderableChart.isCounter ||
      renderableChart.visualizationType === VISUALIZATION_TYPES.DONUT ||
      renderableChart.visualizationType === VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER ||
      renderableChart.visualizationType === VISUALIZATION_TYPES.COLUMN_LINE)
  ) {
    throw new Error('segmentBy cannot be used with this visualization type');
  } else if (renderableChart.segmentBy) {
    yAxis = renderableChart.isSegmentedByTime
      ? {
          name: '0',
          type: 'temporal',
          data: {
            property: timeProperty(renderableChart.segmentBy, renderableChart.timeProperty, metric),
            ...(shouldIncludeAttributeIds
              ? {
                  attribute_id: timeAttributeId(
                    renderableChart.segmentBy,
                    renderableChart.timeProperty,
                    metric,
                    renderableChart,
                  ),
                }
              : {}),
            interval: renderableChart.segmentByTimeInterval || renderableChart.dateRange.interval,
          },
        }
      : {
          name: '0',
          type: 'nominal',
          data: {
            applyLimitOnServer,
            limit: renderableChart.segmentByDisplayLimit,
            property: termProperty(renderableChart.segmentBy, metric),
            ...(shouldIncludeAttributeIds
              ? {
                  attribute_id: termAttributeId(renderableChart.segmentBy, metric, renderableChart),
                }
              : {}),
            showOther: renderableChart.segmentByDisplayOther,
            ...(!applyLimitOnServer && { term_size: 10000 }),
          },
        };
  }
  return { xAxis, yAxis };
}

function timeProperty(groupProperty, defaultTimeProperty, metric) {
  if (groupProperty === 'time') {
    // time is an alias, so we grab the timeProperty from the metric or use the default from the chart
    return metric?.timeProperty || defaultTimeProperty;
  }

  return groupProperty;
}

function timeAttributeId(property, defaultTimeProperty, metric, renderableChart) {
  let field = timeProperty(property, defaultTimeProperty, metric);
  if (isPresent(metric) && metric.datasetId !== NO_DATASET && field !== 'period') {
    return getAttributeIdByField(field, metric, renderableChart);
  } else {
    return null;
  }
}

function termProperty(defaultProperty, metric) {
  let property = defaultProperty;
  // if it is a teammate property we find the Field name for property according to the metric
  if (
    isPresent(metric) &&
    (TEAMMATE_ALIAS.includes(property) || metricUsesAlias(metric, property))
  ) {
    if (isPresent(metric.teammateProperties) && property in metric.teammateProperties) {
      property = metric.teammateProperties[defaultProperty];
    } else {
      property = metric.teammateProperty;
    }
  }

  if (property === TEAM_PROPERTY && isPresent(metric) && metric.teamProperty) {
    property = metric.teamProperty;
  }
  return property;
}

function termAttributeId(property, metric, renderableChart) {
  if (isPresent(metric) && metric.datasetId !== NO_DATASET) {
    let field = termProperty(property, metric);
    return getAttributeIdByField(field, metric, renderableChart);
  } else {
    return null;
  }
}

export function timeDataModel(property) {
  if (FILTERED_INTERVAL_SUPPORTED_METRICS.includes(property)) {
    return 'filtered_interval';
  }
  return undefined;
}

export function getApplyLimitOnServer(parentMetric, allowUnlimitedBuckets = false) {
  if (allowUnlimitedBuckets || ['ratio', 'percentage'].includes(parentMetric?.type)) {
    return false;
  }
  return true;
}
