/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { IntegrationState } from 'embercom/objects/standalone/constants';
import { tracked } from '@glimmer/tracking';

type UsageCounts = { involvement_count: number; last_active?: string };
interface Args {
  title: string;
  description: string;
  route: string;
  image: string;
  status: IntegrationState;
  getUsageCounts?: () => Promise<UsageCounts>;
  externalTypeName: string;
  emptyStateDescription?: string;
}

export default class StandaloneChannelsOverviewCard extends Component<Args> {
  @service declare appService: any;

  @tracked usageCounts?: UsageCounts;
  @tracked isLoadingUsageCounts = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.isEnabled || this.isPaused) {
      this.loadUsageCounts();
    }
  }

  async loadUsageCounts() {
    try {
      if (this.args.getUsageCounts) {
        this.isLoadingUsageCounts = true;
        this.usageCounts = await this.args.getUsageCounts();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingUsageCounts = false;
    }
  }

  get isEnabled() {
    return this.args.status === IntegrationState.Enabled;
  }

  get isPaused() {
    return this.args.status === IntegrationState.Paused;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::Overview::Card': typeof StandaloneChannelsOverviewCard;
    'standalone/channels/overview/card': typeof StandaloneChannelsOverviewCard;
  }
}
