/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import isValidUrl from 'embercom/lib/url-validator';

export default class SignInController extends Controller {
  @service redirectService;
  @service notificationsService;

  queryParams = [{ redirectUrlQueryParam: 'redirect_url' }];

  get showSaml() {
    return this.model.authorizedSignInMethods.includes('saml');
  }

  get showGoogleSso() {
    return this.model.authorizedSignInMethods.includes('google_oauth2');
  }

  get showEmailPassword() {
    return (
      this.model.authorizedSignInMethods.includes('email_password') ||
      this.model.authorizedSignInMethods.includes('email_password_otp')
    );
  }

  get redirectUrl() {
    if (this.redirectUrlQueryParam && isValidUrl(this.redirectUrlQueryParam)) {
      return this.redirectUrlQueryParam;
    } else {
      return `/a/apps/${this.model.id}`;
    }
  }

  @action signInWithSaml() {
    this.redirectService.redirect(`/saml/${this.model.samlAuthId}`);
  }

  @action signInWithGoogleSso() {
    this.redirectService.redirect(`/auth/google_oauth2${window.location.search}`);
  }

  @task
  *signInEmailPassword(email, password, keepMeSignedIn) {
    let formData = {};
    let resourceName = 'admin';

    formData[resourceName] = {
      email,
      password,
      remember_me: keepMeSignedIn === true ? '1' : '0',
    };

    try {
      yield ajax({
        type: 'POST',
        url: '/admins/sign_in',
        data: JSON.stringify(formData),
        cache: false,
        noRedirectOn401: true,
      });
      this.redirectService.redirect(this.redirectUrl);
    } catch (e) {
      let response = e.jqXHR;
      if (response.status === 200) {
        this.redirectService.redirect(this.redirectUrl);
      } else if (response.status === 401) {
        if (response.responseText !== undefined && response.responseText !== '') {
          this.notificationsService.notifyError(response.responseText);
        } else {
          this.redirectService.redirect('/admins/two_factor_authentication');
        }
      } else if (
        response.status === 403 &&
        response.responseText === '{"message":"Two factor authentication is required"}'
      ) {
        this.redirectService.redirect('/admins/generate_otp');
      } else {
        this.notificationsService.notifyError('Something went wrong. Please try again');
      }
    }
  }
}
