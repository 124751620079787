/* import __COLOCATED_TEMPLATE__ from './domain-records.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type DkimSettings from 'embercom/models/dkim-settings';
import type CustomBounceSettings from 'embercom/models/custom-bounce-settings';

interface Args {
  dkimSettings: DkimSettings;
  bounceSettings: CustomBounceSettings;
  place: string;
  context: string;
  verifying: boolean;
}

export default class DomainRecords extends Component<Args> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @action
  stampText(record: any) {
    if (this.args?.verifying) {
      return this.intl.t(
        'new-settings.channels.email.addresses.domain-sidebar.checking-authentication',
      );
    } else {
      return record.status
        ? this.intl.t('new-settings.channels.email.addresses.domain-sidebar.authenticated')
        : this.intl.t('new-settings.channels.email.addresses.domain-sidebar.unauthenticated');
    }
  }

  @action
  stampColor(record: any) {
    if (this.args?.verifying) {
      return 'gray';
    } else {
      return record.status ? 'green' : 'yellow-light';
    }
  }

  removeDomainName(address: string) {
    return address.split('.').slice(0, -2).join('.');
  }

  get domainSettingRecords() {
    let rows = [
      {
        name: this.removeDomainName(this.args.dkimSettings?.domainForCustomerDnsRecord),
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname'),
        value: `${this.args.dkimSettings?.domainForIntercomDnsRecord}`,
        status: this.args.dkimSettings?.validRecordExists,
        id: 'dkim-cname',
      },
      {
        name: this.removeDomainName(this.args.bounceSettings?.host),
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname'),
        value: `${this.args.bounceSettings?.value}`,
        status: this.args.bounceSettings?.validRecordExists,
        id: 'bounce-settings-cname',
      },
      {
        name: '_dmarc',
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.txt'),
        value: 'v=DMARC1; p=none',
        status: this.args.dkimSettings?.validDmarcExists,
        id: 'dmarc-txt',
      },
    ];
    return rows;
  }

  @action
  trackCopyEvent(name: string, type: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'copied',
      object: `${name}_${type}_domain_record`,
      place: this.args.place,
      context: this.args.context,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::DomainRecords': typeof DomainRecords;
  }
}
