/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import type Model from '@ember-data/model';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type IntlService from 'ember-intl/services/intl';
import { EntityType } from 'embercom/models/data/entity-types';
import type ArticleContent from 'embercom/models/articles/article-content';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { tracked } from '@glimmer/tracking';

interface Args {
  showSidePanel: boolean;
  toggleSidePanel: () => void;
  activeContent: LocalizedKnowledgeContent & Model;
  switchToEditMode: (show: boolean) => void;
}

export default class Header extends Component<Args> {
  closeSidePanelOnResize = true;

  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare permissionsService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  @tracked editingContent = false;

  @action closeSidePanel(header: HTMLElement) {
    let headerHasScroll = header.offsetWidth < header.scrollWidth;
    let closeSidePanel = headerHasScroll && this.closeSidePanelOnResize && this.args.showSidePanel;
    this.closeSidePanelOnResize = !headerHasScroll && this.args.showSidePanel;
    if (closeSidePanel) {
      // schedule the side panel to close after it finishes opening, to avoid a race condition
      schedule('afterRender', () => {
        this.args.toggleSidePanel();
      });
    }
  }

  @action
  approveVersion() {
    this.updateContentReviewRequest(Status.Approved);
  }

  @action
  rejectVersion() {
    this.updateContentReviewRequest(Status.Rejected);
  }

  @action
  switchToEdit() {
    this.editingContent = true;
    this.args.switchToEditMode(true);
  }

  @action
  switchToView() {
    this.editingContent = false;
    this.args.switchToEditMode(false);
  }

  @action
  saveAndApprove() {
    // TODO: Implement save and approve
  }

  private async updateContentReviewRequest(status: Status) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    let contentReviewRequest = await this.contentReviewRequest();
    let message = status === Status.Approved ? 'approve' : 'reject';

    if (contentReviewRequest) {
      contentReviewRequest.status = status;
      try {
        await contentReviewRequest.save();
        this.notificationsService.notifyConfirmation(
          this.intl.t(`knowledge-hub.suggestion-review.${message}`),
        );
      } catch (e) {
        this.notifyErrorAndCloseDrawer(
          this.intl.t(`knowledge-hub.suggestion-review.error.${message}`),
          false,
        );
      }
    } else {
      this.notifyErrorAndCloseDrawer();
    }
  }

  private async contentReviewRequest() {
    let content = this.args.activeContent;
    if (
      content.entityType === EntityType.ArticleContent ||
      content.entityType === EntityType.ContentSnippet
    ) {
      return (content as ArticleContent | ContentSnippet).contentReviewRequest;
    }
    return undefined;
  }

  private async notifyErrorAndCloseDrawer(
    message = this.intl.t('knowledge-hub.suggestion-review.not-found'),
    closeDrawer = true,
  ) {
    this.notificationsService.notifyError(message);
    if (closeDrawer) {
      await this.knowledgeHubDrawerEditorService.closeDrawer();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview::Header': typeof Header;
    'knowledge-hub/content-editor/content-review/header': typeof Header;
  }
}
