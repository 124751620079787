/* import __COLOCATED_TEMPLATE__ from './content-part.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type TaggablePart from 'embercom/objects/inbox/taggable-part';
import { isTaggable } from 'embercom/objects/inbox/taggable-part';
import type Tag from 'embercom/objects/inbox/tag';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import type Conversation from 'embercom/objects/inbox/conversation';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { taskFor } from 'ember-concurrency-ts';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type Session from 'embercom/services/session';
import type TracingService from 'embercom/services/tracing';

interface Args {
  classStyling?: string;
  conversation?: Conversation;
  part: RenderablePart;
  index: number;
  partGroup: PartGroup;
}

interface Signature {
  Args: Args;
}

export default class Inbox2TicketStreamContentPartComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare tracing: TracingService;
  @service declare intercomEventService: any;
  @service declare session: Session;
  @tracked isHovered = false;

  get classStyling() {
    let styles =
      this.args.classStyling ||
      'inbox2__renderable-part inbox2__ticket-renderable-part inbox2__break-words';
    return styles;
  }

  get textColor() {
    return this.args.part.renderableData.ticketStyles?.textColor || '';
  }

  get isTaggablePart() {
    return isTaggable(this.args.part);
  }

  get permalinkId() {
    if (this.args.conversation) {
      return this.args.part.generatePermalinkId(this.args.conversation.id);
    }
    return '';
  }

  get conversationPartBackgroundStyles() {
    let renderablePart = this.args.part.renderableData;
    let hoverStyles = renderablePart.ticketStyles?.backgroundColorHover;

    if (this.isHovered && hoverStyles) {
      return hoverStyles;
    }

    return renderablePart.ticketStyles?.backgroundColor;
  }

  @action removeTag(tagToRemove: Tag) {
    this.optimisticRemoveTag(tagToRemove);
  }

  @action legacyRemoveTag(tagToRemove: Tag) {
    if (this.args.conversation && this.isTaggablePart) {
      let renderablePart = this.args.part as TaggablePart;
      let tagsWithoutRemovedTag = renderablePart.renderableData.tags.reject(
        (tag) => tag === tagToRemove,
      );
      taskFor(this.inboxState.updateTags).perform(
        this.args.conversation,
        renderablePart,
        tagsWithoutRemovedTag,
      );
      this.trackTagRemovedEvent(this.args.conversation?.id);
    }
  }

  @action optimisticRemoveTag(tagToRemove: Tag) {
    if (this.args.conversation && this.isTaggablePart) {
      let renderablePart = this.args.part as TaggablePart;
      this.inboxState.removeTag(this.args.conversation, renderablePart, tagToRemove);
    }
  }

  private trackTagRemovedEvent(conversationId: number) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'tag_removed',
      object: 'conversation_part',
      place: 'inbox',
      inbox_type: this.inboxState.activeInbox?.type,
      conversation_id: conversationId,
    });
  }

  @action instrumentImages(element: HTMLElement) {
    let images = element.getElementsByTagName('img');

    for (let i = 0; i < images.length; i++) {
      this.tracing.trackImageLoad(images[i]);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketStream::ContentPart': typeof Inbox2TicketStreamContentPartComponent;
    'inbox2/ticket-stream/content-part': typeof Inbox2TicketStreamContentPartComponent;
  }
}
