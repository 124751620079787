/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import ajax from 'embercom/lib/ajax';
import { readOnly } from '@ember/object/computed';

const OLD_APP_STORE_ROUTE = 'apps.app.appstore';

export default IntercomRoute.extend({
  store: service(),
  iamService: service(),
  appstoreService: service(),
  appService: service(),

  adminIsLoggedIn: readOnly('appService.app.currentAdmin'),

  beforeModel(transition) {
    this.iamService.boot();
    let { category, search, installed, capability, app_package_code } = transition.to.queryParams;
    if (search === '') {
      this.transitionTo({ queryParams: { search: null } });
      return;
    }
    let place = 'home';
    let params = { page: 1 };
    if (category) {
      place = 'category';
      params.category = category;
    } else if (search) {
      place = 'search';
      params.search = search;
    } else if (installed) {
      place = 'installed';
      params.installed = true;
    } else if (capability) {
      place = 'capability';
      params.capability = capability;
    }
    this.setProperties({ 'analytics.place': place, params });

    if (this.appService.app?.canUseMigratedAppStore) {
      if (this.appService.app.canUseNewSocialChannels) {
        let appPackageCode = transition.to.params.id || app_package_code;
        if (appPackageCode === 'whatsapp-fqam') {
          return this.transitionTo('apps.app.settings.channels.whatsapp');
        }

        if (appPackageCode === 'facebook') {
          return this.transitionTo('apps.app.settings.channels.social-channels', {
            queryParams: {
              tab: 'facebook',
            },
          });
        } else if (appPackageCode === 'instagram-pv4') {
          return this.transitionTo('apps.app.settings.channels.social-channels', {
            queryParams: {
              tab: 'instagram',
            },
          });
        }
      }
    }

    if (
      this.appService.app?.canUseMigratedAppStore &&
      transition.to.name.startsWith(OLD_APP_STORE_ROUTE)
    ) {
      this.transitionTo('apps.app.settings.app-settings.app-store', {
        queryParams: {
          ...transition.to.queryParams,
          ...((transition.to.name === 'apps.app.appstore.app-package' ||
            transition.to.name === 'apps.app.appstore.old-app-package') &&
            transition.to.params.id && {
              app_package_code: transition.to.params.id,
            }),
        },
      });
    }
  },

  model() {
    return hash({
      appPackages: this.fetchAppPackages(),
      defaultCategories: this.fetchDefaultCategories(),
      featuredCategories: this.fetchFeaturedCategories(),
      collections: this.fetchCollections(),
      recentlyViewed: this.fetchRecentlyViewed(),
    });
  },

  afterModel(model, transition) {
    let title = this.appstoreService.generateTitle(transition.to.queryParams);
    transition.trigger(false, 'setDocumentTitle', title);
  },

  fetchAppPackages() {
    if (this.get('appService.app.useAppCardApi')) {
      return this.store.query('appstore/app-card', this.params);
    }
    return this.store.query('appstore/app-package', this.params);
  },

  fetchDefaultCategories() {
    return this.store.query('appstore/category', { category_type: 'default' });
  },

  fetchFeaturedCategories() {
    return this.store.query('appstore/category', { category_type: 'featured' });
  },

  fetchCollections() {
    return this.store.query('appstore/category', { category_type: 'collection' });
  },

  fetchRecentlyViewed() {
    if (this.adminIsLoggedIn) {
      return ajax({
        url: '/ember/appstore/app_packages/recently_viewed',
        type: 'GET',
        data: {
          admin_id: this['app,currentAdmin'],
          app_id: this.get('app.id'),
        },
      });
    }
  },

  setupController(controller, model) {
    this._super(...arguments);

    if (controller.displayFeaturedCategoriesGrid === true) {
      controller.set('featuredCategoriesAndApps', this._segregateFeaturedApps(model));
    }
  },

  _segregateFeaturedApps(model) {
    let { appPackages, featuredCategories } = model;

    let getCategoryIdsForAppPackage = (appPackage) =>
      appPackage.categories.map((category) => category.id);

    return featuredCategories.reduce((accum, category) => {
      accum.push({
        category,
        appPackages: appPackages.filter((ap) =>
          getCategoryIdsForAppPackage(ap).includes(category.id),
        ),
      });
      return accum;
    }, []);
  },

  actions: {
    loading(transition) {
      if (!this.controller) {
        return true;
      }

      this.controller.set('currentlyLoading', true);
      transition.promise.finally(() => {
        this.controller.set('currentlyLoading', false);
      });
    },
  },
});
