/* import __COLOCATED_TEMPLATE__ from './sidebar-item-container.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxVersion from 'embercom/services/inbox-version';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export type SidebarSectionId = string | number | undefined;

export interface SidebarItemContainerArgs {
  header?: string;
  icon?: InterfaceIconName;
  iconUrl?: string;
  isOpen: boolean;
  onOpenSectionChange: (value: SidebarSectionId) => (value: SidebarSectionId) => unknown;
  sidebarSectionId: SidebarSectionId;
  pinnedSection?: boolean;
  count?: number;
  bodyClassNames?: string;
}

interface Signature {
  Args: SidebarItemContainerArgs;
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

export default class SidebarItemContainer extends Component<Signature> {
  @service declare inboxVersion: InboxVersion;

  @tracked isHovering = false;

  get isSectionReorderable() {
    return !this.args.pinnedSection;
  }

  @action onOpenSectionChange(openSectionId?: string | number) {
    this.args.onOpenSectionChange(this.args.sidebarSectionId)(openSectionId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarItemContainer': typeof SidebarItemContainer;
    'inbox2/conversation-details-sidebar/sidebar-item-container': typeof SidebarItemContainer;
  }
}
