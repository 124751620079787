/* import __COLOCATED_TEMPLATE__ from './rating-reasons.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import Component from '@glimmer/component';
import { CustomerRatingReason } from 'embercom/lib/fin-playground';

interface RatingReasonsArgs {
  selectedCustomerAnswerRatingReason: CustomerRatingReason | undefined;
  onSelectRatingReason: (reason: CustomerRatingReason) => void;
}

type RatingReasonsSignature = {
  Args: RatingReasonsArgs;
  Element: HTMLDivElement;
};

export default class RatingReasons extends Component<RatingReasonsSignature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get selectedRatingReason() {
    if (!this.args.selectedCustomerAnswerRatingReason) {
      return undefined;
    }

    // As customer may have selected an option that is not in the updated list, this is a fallback
    let availableRatingReasons = this.groupList.map((reason) => reason.value);
    if (!availableRatingReasons.includes(this.args.selectedCustomerAnswerRatingReason)) {
      return undefined;
    }

    return this.args.selectedCustomerAnswerRatingReason;
  }

  get groupList() {
    let translationPrefix = 'ai-agent.playground.answer-rating-reason.';

    return [
      {
        text: this.intl.t(
          `${translationPrefix}${this.replaceUnderscoreWithDash(CustomerRatingReason.DidNotUseRightContentSources)}`,
        ),
        value: CustomerRatingReason.DidNotUseRightContentSources,
      },
      ...(this.appService.app.canUseFinGuidance
        ? [
            {
              text: this.intl.t(
                `${translationPrefix}${this.replaceUnderscoreWithDash(CustomerRatingReason.DidNotClarifyCustomerQuestion)}`,
              ),
              value: CustomerRatingReason.DidNotClarifyCustomerQuestion,
            },
          ]
        : []),
      {
        text: this.intl.t(
          `${translationPrefix}${this.replaceUnderscoreWithDash(CustomerRatingReason.ToneWasNotRight)}`,
        ),
        value: CustomerRatingReason.ToneWasNotRight,
      },
      {
        text: this.intl.t(
          `${translationPrefix}${this.replaceUnderscoreWithDash(CustomerRatingReason.AnswerTooLong)}`,
        ),
        value: CustomerRatingReason.AnswerTooLong,
      },
      {
        text: this.intl.t(
          `${translationPrefix}${this.replaceUnderscoreWithDash(CustomerRatingReason.DidNotSpeakInRightLanguage)}`,
        ),
        value: CustomerRatingReason.DidNotSpeakInRightLanguage,
      },
      {
        text: this.intl.t(
          `${translationPrefix}${this.replaceUnderscoreWithDash(CustomerRatingReason.Other)}`,
        ),
        value: CustomerRatingReason.Other,
      },
    ];
  }

  private replaceUnderscoreWithDash(str: string) {
    return str.replace(/_/g, '-');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::RatingReasons': typeof RatingReasons;
  }
}
