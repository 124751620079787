/* import __COLOCATED_TEMPLATE__ from './code-block.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { computed } from '@ember/object';
import { maybeImportCodeMirror } from 'embercom/lib/import-code-mirror';
import { bind } from '@ember/runloop';
import { typeOf } from '@ember/utils';

export default Component.extend({
  classNames: ['flex-auto', 'custom-action-response-code-block'],
  attributeBindings: ['data-test-workflowAction-test-response'],
  'data-test-workflowAction-test-response': true,

  mode: { name: 'javascript', json: true },
  theme: 'monokai',
  lint: false,
  readOnly: true,
  dynamicReadOnlyRanges: [], // sample: [{firstLines: 3, lastLines: 2}] => first 3 lines are read-only, last 2 lines are read-only
  gutters: [],
  isDev: computed(function () {
    return ENV.environment === 'development';
  }),

  didInsertElement() {
    this._super(...arguments);
    this.loadCodeMirrorTask.perform();
  },

  loadCodeMirrorTask: task(function* () {
    try {
      yield maybeImportCodeMirror();

      this._initializeCodeMirror();
    } catch (error) {
      this.set('hasErrored', true);
      console.error(error);
    }
  }),

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.codeMirrorEditor) {
      this._setCodeMirrorValue(this.value);
    }
  },

  _setCodeMirrorValue(value) {
    this.codeMirrorEditor.setValue(value);
  },

  _sendUpdateAction() {
    let sendUpdate = this.update;
    if (typeOf(sendUpdate) === 'function') {
      let currentValue = this.codeMirrorEditor.getValue();
      sendUpdate(currentValue);
    }
  },

  _makeLinesReadOnly(codeMirrorEditor, ranges) {
    ranges.forEach((range) => {
      for (let i = range.start; i < range.end; i++) {
        codeMirrorEditor.markText(
          { line: i, ch: 0 }, // Start of the line
          { line: i, ch: codeMirrorEditor.getLine(i).length }, // End of the line
          {
            className: 'readonly',
            atomic: true,
            inclusiveLeft: true,
            inclusiveRight: true,
          }, // Mark as read-only
        );
      }
    });
  },

  _generateReadOnlyRanges(codeMirrorEditor) {
    let ranges =
      this.dynamicReadOnlyRanges?.firstObject?.firstLines &&
      this.dynamicReadOnlyRanges?.firstObject?.firstLines > 0
        ? [{ start: 0, end: this.dynamicReadOnlyRanges.firstObject.firstLines }]
        : [];
    if (
      this.dynamicReadOnlyRanges?.firstObject?.lastLines &&
      this.dynamicReadOnlyRanges?.firstObject?.lastLines > 0
    ) {
      for (let i = 0; i < this.dynamicReadOnlyRanges.firstObject.lastLines; i++) {
        let range = {
          start: codeMirrorEditor.lastLine() - i,
          end: codeMirrorEditor.lastLine() - i + 1,
        };
        ranges.push(range);
      }
    }
    this._makeLinesReadOnly(codeMirrorEditor, ranges);
  },

  _initializeCodeMirror() {
    let codeMirrorEditor = window.CodeMirror.fromTextArea($('textarea', this.element).get(0), {
      lineNumbers: true,
      tabSize: 2,
      mode: this.mode,
      theme: this.theme,
      gutters: this.gutters,
      lint: this.lint,
      lineWrapping: true,
      readOnly: this.readOnly,
    });

    if (this.value) {
      codeMirrorEditor.setValue(this.value);
    }

    this.set('codeMirrorEditor', codeMirrorEditor);
    if (!this.readOnly) {
      codeMirrorEditor.on('change', bind(this, this._sendUpdateAction));
    }

    if (this.dynamicReadOnlyRanges?.length > 0) {
      this._generateReadOnlyRanges(codeMirrorEditor);
    }
    $(this.element).trigger('codemirrorloaded');
  },
});
