/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';

export default class FacebookPage extends Model {
  @attr('string') declare name: string;
  @attr('string') declare accessToken: string;
  @attr('string') declare status: string;
  @attr('boolean', { defaultValue: false }) declare subscribed: boolean;
  @attr('boolean') declare added: boolean;
  @attr('string') declare about: string;
}
