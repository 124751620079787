/* import __COLOCATED_TEMPLATE__ from './reporting-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { get } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import type ContentStatistic from 'embercom/models/outbound/content-statistic';
import type Store from '@ember-data/store';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import type { StatisticKeyName } from 'embercom/models/data/outbound/types';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Router from '@ember/routing/router-service';
import { type ActiveContent, getSupportContentId } from 'embercom/lib/knowledge-hub/constants';
interface Signature {
  Element: HTMLDivElement;
  Args: {
    activeContent: ActiveContent;
  };
}

export const STATISTIC_KEY_TO_AGGREGATION_VALUES: Partial<Record<StatisticKeyName, string[]>> = {
  reactions: ['😃', '😐', '😞'],
};

export const STATISTIC_KEY_TO_DESCRIPTION_KEY: Partial<Record<StatisticKeyName, string>> = {
  articleContentView: 'knowledge-hub.content-editor.shared.reporting.types.views',
  replies: 'knowledge-hub.content-editor.shared.reporting.types.conversations',
  reactions: 'knowledge-hub.content-editor.shared.reporting.types.reactions',
};

export default class ReportingSection extends Component<Signature> {
  @service appService: $TSFixMe;
  @service declare store: Store;
  @service declare router: Router;
  @service declare intercomEventService: $TSFixMe;

  @tracked isDrawerOpen = false;
  @tracked selectedStatKey: StatisticKeyName = this.statKeys[0];

  get stats(): ContentStatistic[] {
    return this.statsLoader.value ?? [];
  }

  getReportRoute(reportName: string) {
    switch (reportName) {
      case 'articles':
        return 'apps.app.reports.customer-support.articles';
      case 'fin-ai-agent':
        return 'apps.app.reports.customer-support.fin-ai-agent';
      case 'fin-ai-copilot':
        return 'apps.app.reports.customer-support.fin-ai-copilot';
      default:
        return 'apps.app.reports.customer-support';
    }
  }

  private trackEvent(name: string): void {
    this.intercomEventService.trackEvent(name, {
      action: 'clicked',
      object: 'article',
      place: 'knowledge-hub',
    });
  }

  @action
  openDrawer(name: string) {
    this.isDrawerOpen = true;
    this.trackEvent(name);
  }

  @action
  closeDrawer() {
    this.isDrawerOpen = false;
  }

  get eventNameFromSelectedTab() {
    let eventNames: Partial<Record<StatisticKeyName, string>> = {
      articleContentView: 'views_tab',
      replies: 'conversations_tab',
      reactions: 'reactions_tab',
    };
    return eventNames[this.selectedStatKey] ?? 'views_tab';
  }

  get statKeys(): StatisticKeyName[] {
    return this.args.activeContent.editorConfig.statsConfig?.tabStatisticKeyNames ?? [];
  }

  @action
  setSelectedTab(tabKey: StatisticKeyName) {
    this.selectedStatKey = tabKey;
    this.trackEvent(this.eventNameFromSelectedTab);
  }

  get reportNames() {
    return ['articles', 'fin-ai-agent', 'fin-ai-copilot'];
  }

  @use statsLoader = AsyncData<ContentStatistic[]>(async () => {
    try {
      let supportContentId = getSupportContentId(this.args.activeContent);

      let response: ContentStatistic[][] = await get('/ember/content_service/contents/get_stats', {
        support_content_ids: [supportContentId],
        statistics: this.statKeys.map((name: StatisticKeyName) => statisticKeys[name]),
        app_id: this.appService.app.id,
      });

      let stats: ContentStatistic[] = [];
      // using firstObject as get_stats returns 0 or more Arrays of stats objects
      // since this response is for specific content's page, we'll have 1 or 0
      response.firstObject?.forEach((stat) => {
        this.store.push(this.store.normalize('outbound/content-statistic', stat));
        let contentStat = this.store.peekRecord('outbound/content-statistic', stat.id);
        stats = [...stats, contentStat!];
      });

      return stats;
    } catch (error) {
      console.error(error);
      return [];
    }
  });

  // want to pass both key and stat object to the child components so that
  // even if stat object doesn't exist it renders that statistic,
  // with - or 0 as the value
  get statsEnumerator() {
    return this.statKeys.map((stat: StatisticKeyName) => ({
      key: stat,
      object: this.getStatObject(stat),
    }));
  }

  getStatObject(stat: StatisticKeyName) {
    let key = statisticKeys[stat];
    return this.stats.find((stat) => stat.key === key);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::ReportingSection': typeof ReportingSection;
    'knowledge-hub/content-editor/shared/reporting-section': typeof ReportingSection;
  }
}
