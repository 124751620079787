/* import __COLOCATED_TEMPLATE__ from './list-header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hasFeature } from 'embercom/helpers/has-feature';

export default class ListHeader extends Component {
  @service intercomEventService;
  @service appService;
  @service customerService;
  @service cardlessTrialService;

  get app() {
    return this.appService.app;
  }

  get anyProactiveSupportPlusFeature() {
    // return any feature that is proactive support plus
    return 'event_triggered_messages';
  }

  get appOnTrial() {
    return this.cardlessTrialService.isInSelfServeTrial;
  }

  get hasActiveSubscription() {
    return !!this.app.hasActiveSubscription;
  }

  get shouldShowProactiveSupportPlusUpgradeCTA() {
    return (
      this.app.outboundShowUpgradeToProactiveSupportPlusCTA &&
      !this.appOnTrial &&
      this.hasActiveSubscription &&
      !hasFeature(this.anyProactiveSupportPlusFeature, this.appService)
    );
  }

  get proactiveSupportPlusUpgradeButtonAnalyticsMetadata() {
    return {
      place: 'outbound.all',
      owner: 'team-proactive-support',
      object: 'upgrade_to_proactive_support_plus_cta',
    };
  }

  @action
  showGetStartedWithOutboundArticle() {
    window.Intercom('showArticle', 3292835); // https://www.intercom.com/help/en/articles/3292835-get-started-with-outbound
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'get_started_with_outbound_help_link',
      context: 'discovery-banner',
      place: 'outbound.list-header',
      section: 'discovery-banner',
    });
  }
}
