/* RESPONSIBLE TEAM: team-ai-agent */

import { tracked } from '@glimmer/tracking';

export type FinContentStatusWireFormat = KeysToSnakeCase<FinContentStatus>;

export class FinContentStatus {
  @tracked contentAvailableChangedAt?: Date;

  constructor(contentAvailableChangedAt: Date | undefined) {
    this.contentAvailableChangedAt = contentAvailableChangedAt;
  }

  static deserialize(data: FinContentStatusWireFormat): FinContentStatus {
    let contentChangedAt: Date | undefined;

    if (data.content_available_changed_at) {
      contentChangedAt = new Date(data.content_available_changed_at);
    }

    return new FinContentStatus(contentChangedAt);
  }
}
