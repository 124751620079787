/* RESPONSIBLE TEAM: team-help-desk-experience */
export interface TagSummaryWireFormat {
  id: number;
  name: string;
}

export default class TagSummary {
  readonly id: number;
  readonly name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  static deserialize(json: TagSummaryWireFormat): TagSummary {
    return new TagSummary(json.id, json.name);
  }
}
