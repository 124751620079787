/* import __COLOCATED_TEMPLATE__ from './sunshine-api-connection.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type PulseAccordion } from 'glint/pulse';
import { IntegrationState } from 'embercom/objects/standalone/constants';
import type SunshineConfiguration from 'embercom/models/standalone/sunshine-configuration';
import { tracked } from '@glimmer/tracking';
import { dependentKeyCompat } from '@ember/object/compat';

export interface Args {
  accordion: PulseAccordion;
  setupType: 'intercomMessenger' | 'sunshineMessenger';
}

export default class StandaloneZendeskSunshineApiConnection extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare notificationsService: {
    notifyConfirmation: (message: string) => void;
    notifyResponseError: (error: Error, options: { default: string }) => void;
  };

  @tracked isRevokingAccess = false;

  get setupTypeIsIntercomMessenger() {
    return this.args.setupType === 'intercomMessenger';
  }

  get setupTypeIsSunshineMessenger() {
    return this.args.setupType === 'sunshineMessenger';
  }

  get isPendingSetup() {
    if (!this.sunshineConfig) {
      return true;
    }

    if (this.setupTypeIsSunshineMessenger) {
      return this.sunshineConfig.sunshineMessengerState === IntegrationState.NotConfigured;
    } else {
      return this.sunshineConfig.intercomMessengerState === IntegrationState.NotConfigured;
    }
  }

  @action async save() {
    if (this.setupTypeIsSunshineMessenger) {
      this.sunshineConfig.intercomMessengerState = IntegrationState.NotConfigured;
      this.sunshineConfig.sunshineMessengerState = IntegrationState.Created;
    } else {
      this.sunshineConfig.intercomMessengerState = IntegrationState.Created;
      this.sunshineConfig.sunshineMessengerState = IntegrationState.NotConfigured;
    }
    try {
      return this.finStandaloneService.saveZendeskConfig();
    } catch (e) {
      this.sunshineConfig.sunshineMessengerState = IntegrationState.NotConfigured;
      this.sunshineConfig.intercomMessengerState = IntegrationState.NotConfigured;
    }
  }

  @dependentKeyCompat
  get isSaving() {
    return Boolean(this.zendeskConfig.isSaving);
  }

  @action async revokeAccess() {
    let isConfirmed = await this.intercomConfirmService.confirm({
      body: this.setupTypeIsSunshineMessenger
        ? 'Are you sure you want to revoke access to the Zendesk Conversations API? Fin will immediately stop responding to Messenger conversations.'
        : 'Are you sure you want to revoke access to the Zendesk Conversations API? Fin will no longer be able to handoff conversations to Zendesk.',
      confirmButtonText: 'Revoke Access',
    });

    try {
      if (isConfirmed) {
        this.isRevokingAccess = true;
        await this.zendeskConfig.revokeSunshine();
        this.notificationsService.notifyConfirmation(
          'Access to the Conversations API has been revoked',
        );
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't revoke access to the Conversations API. Please try again`,
      });
    }
    this.isRevokingAccess = false;
  }

  get sunshineConfig(): SunshineConfiguration {
    return this.finStandaloneService.zendeskConfig.sunshineIntegration;
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::SunshineApiConnection': typeof StandaloneZendeskSunshineApiConnection;
    'standalone/zendesk/setup/sunshine-api-connection': typeof StandaloneZendeskSunshineApiConnection;
  }
}
