/* import __COLOCATED_TEMPLATE__ from './template-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import {
  TEMPLATE_GROUPS,
  PROACTIVE_SUPPORT,
  APP_TEMPLATE_WORKFLOWS_ID,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  APP_TEMPLATES,
  APP_TEMPLATES_WITH_WORKFLOWS_SETUP,
} from 'embercom/components/outbound/new-content-modal/workflows/app-templates';
import { inject as service } from '@ember/service';
import {
  WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS,
  WORKFLOWS_SETUP_UPDATES_TEMPLATE_GROUPS,
} from 'embercom/lib/operator/custom-bots/constants-2';

export default class TemplateSelector extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get hasAnyAppTemplateObjectives() {
    return !!this.args.templates.find(
      (template) => template.objective === APP_TEMPLATE_WORKFLOWS_ID,
    );
  }

  get workflowsSetupTemplateGroups() {
    return WORKFLOWS_SETUP_UPDATES_TEMPLATE_GROUPS.filter(
      (group) => group.translationKey !== 'app-template-workflows',
    );
  }

  get workflowSetupAppTemplateGroup() {
    return WORKFLOWS_SETUP_UPDATES_TEMPLATE_GROUPS.find(
      (group) => group.translationKey === 'app-template-workflows',
    );
  }

  get templateGroups() {
    return WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS;
  }

  get appTemplateWorkflowsId() {
    return APP_TEMPLATE_WORKFLOWS_ID;
  }

  get appTemplates() {
    if (this.app.canUseWorkflowsSetup) {
      return APP_TEMPLATES_WITH_WORKFLOWS_SETUP;
    }
    return APP_TEMPLATES;
  }

  get recommendedGroups() {
    return TEMPLATE_GROUPS.filter((group) => {
      return group.id !== PROACTIVE_SUPPORT.id;
    });
  }
}
