/* import __COLOCATED_TEMPLATE__ from './verify-identity.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  idvEnabled: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class VerifyIdentity extends Component<Signature> {
  @tracked isModalOpen = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  @action
  toggleModal() {
    this.isModalOpen = !this.isModalOpen;
  }

  get isMessengerSecured() {
    return this.args.idvEnabled;
  }

  get idvSetupUrl() {
    return 'apps.app.settings.channels.messenger.install';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Request::VerifyIdentity': typeof VerifyIdentity;
    'workflow-connector/builder/body/sections/request/verify-identity': typeof VerifyIdentity;
  }
}
